import {combineReducers} from 'redux';
import EmployerProfile from './employerProfile';
import careerOneStopCompanies from './careerOneStopCompanies';
import EmployerDashboard from './employerDashboard';

export const adminReducer = combineReducers({
  employerProfile: EmployerProfile,
  companyFeed: careerOneStopCompanies,
  employerDashboardMetrics: EmployerDashboard,
});
