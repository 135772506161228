import React from 'react';
import {View, Text} from '@react-pdf/renderer';

import SkillTag from './SkillTag';

export default ({styles, studentProfile}) => {
  const {skills = []} = studentProfile || {};

  const skillsFunc = () => {
    let skillsLearned = <Text style={styles.noSkills}>No skills found</Text>;

    if (skills && Array.isArray(skills) && skills.length > 0) {
      skillsLearned = (
        <View style={styles.SkillsList}>
          {skills.map(({skill, experience}, idx) => (
            <SkillTag key={`skill-${idx}`}>
              <Text key={idx}>{`${skill} (${experience}-${
                experience !== 1 ? 'yrs' : 'yr'
              })`}</Text>
            </SkillTag>
          ))}
        </View>
      );
    }
    return skillsLearned;
  };

  return (
    <>
      <View style={styles.header}>
        <Text>Skills</Text>
      </View>
      <View style={styles.content}>{skillsFunc()}</View>
    </>
  );
};
