import React from 'react';
import {Stats as MUIStats} from 'mui-core';
import {StyledContainer, StatsLabel} from './styles';

const TalentExchange = ({data}) => {
  const prepareTalentExchangeStatsData = () => {
    if (data) {
      const {total_job_seekers} = data;
      const statsData = [
        {
          title: 'Total Job Seekers',
          count: total_job_seekers,
          color: '#3BC4FF',
        },
      ];
      return statsData;
    }
    return [];
  };
  const talentData = prepareTalentExchangeStatsData();
  return (
    <StyledContainer>
      <StatsLabel>Talent Exchange</StatsLabel>
      <MUIStats data={talentData} />
    </StyledContainer>
  );
};

export default TalentExchange;
