import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import * as TYPES from './constants';
import {
  requestStudentProfileDetails,
  receiveStudentProfileDetails,
  handleStudentProfileDetailsError,
  receiveStudentRecommendedPlan,
  requestStudentRecommendedPlan,
  handleStudentRecommendedPlanError,
  clearStudentProfileDetails,
} from './actions';
const initialState = {
  request: null,
  data: {},
  error: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_STUDENT_PROFILE_DETAILS:
      return {...state, request: true};

    case TYPES.RECEIVE_STUDENT_PROFILE_DETAILS:
      return {...state, data: action.payload, request: false, error: false};

    case TYPES.REQUEST_STUDENT_RECOMMENDED_PLAN:
      return {
        ...state,
        data: {
          ...state.data,
          recommendedPlan: {
            ...state.data.recommendedPlan,
            request: true,
            error: false,
          },
        },
      };

    case TYPES.RECEIVE_STUDENT_RECOMMENDED_PLAN:
      return {
        ...state,
        data: {
          ...state.data,
          recommendedPlan: {...action.payload, request: false, error: false},
        },
        request: false,
        error: false,
      };

    case TYPES.STUDENT_RECOMMENDED_PLAN_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          recommendedPlan: {
            ...state.data.recommendedPlan,
            request: false,
            error: true,
          },
        },
      };

    case TYPES.STUDENT_PROFILE_DETAILS_ERROR:
      return {...state, error: true, request: false};

    case TYPES.CLEAR_STUDENT_PROFILE_DETAILS:
      return {...state, data: {}, error: null, request: null};
    default:
      return state;
  }
};

const loadPathWayPlanner = async (
  data,
  institution_id,
  token,
  student_uuid
) => {
  return new Promise(resolve => {
    if (!institution_id || !data || !token || !student_uuid) resolve(null);
    try {
      //const { Data } = data || {};
      const {active_path} = data || {};
      const path_uuid =
        active_path && active_path[0] ? active_path[0].path_uuid : '';
      let valid = false;
      if (Array.isArray(active_path)) {
        valid =
          active_path.filter(itm => itm.institute_uuid === institution_id)
            .length > 0;
      }
      if (valid) {
        const config = {
          method: 'GET',
          mode: 'cors',
          headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
          cache: 'default',
        };
        fetch(
          `${
            API.student.pathway_planner
          }?student_uuid=${student_uuid}&path_uuid=${path_uuid}`,
          config
        )
          .then(res => res.json())
          .then(res => resolve(res && res[0] ? res[0] : {}));
      } else {
        resolve(null);
      }
    } catch (err) {
      console.log(err);
      resolve(null);
    }
  });
};

const loadRecommendedPlan = async (
  data,
  institution_id,
  token,
  student_uuid
) => {
  return new Promise(resolve => {
    if (!institution_id || !data || !token || !student_uuid) resolve(null);
    try {
      // const { Data } = data || {};
      const {active_path} = data || {};
      const path_uuid =
        active_path && active_path[0] ? active_path[0].path_uuid : '';
      let valid = false;
      if (Array.isArray(active_path)) {
        valid =
          active_path.filter(itm => itm.institute_uuid === institution_id)
            .length > 0;
      }
      if (valid) {
        const config = {
          method: 'GET',
          mode: 'cors',
          headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
          cache: 'default',
        };
        fetch(
          `${
            API.student.pathway_planner
          }?student_uuid=${student_uuid}&path_uuid=${path_uuid}&recommended=true`,
          config
        )
          .then(res => res.json())
          .then(res => resolve(res && res[0] ? res[0] : {}));
      } else {
        resolve(null);
      }
    } catch (err) {
      console.log(err);
      resolve(null);
    }
  });
};

const fetchStudentActivePath = async (token, studentID) => {
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  const URI = `${API.student.student_active_paths}?student_uuid=${studentID}`;
  return await fetch(URI, config)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Api Fails');
    })
    .then(json => json)
    .catch(err => {
      console.log(err);
    });
};

const fetchStudentCredentials = async (token, studentID) => {
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  const URI = `${API.student.student_credentials}?student_uuid=${studentID}`;
  return await fetch(URI, config)
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error('Api Fails');
    })
    .then(json => json)
    .catch(err => console.log(err));
};

export const fetchStudentProfileDetails = (
  student_uuid,
  needActivePath = true
) => (dispatch, getState) => {
  const {
    auth: {
      access,
      access: {token},
    },
  } = getState();
  const institution_uuid = access['custom:institution_uuid'];
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestStudentProfileDetails());
  try {
    return fetch(
      `${API.student.student_summery}?student_uuid=${student_uuid}`,
      config
    )
      .then(response => response.json())
      .then(async json => {
        if (needActivePath) {
          const activePath = await fetchStudentActivePath(token, student_uuid);
          if (activePath) json['active_path'] = activePath;
          const pathwayPlanner = await loadPathWayPlanner(
            json,
            institution_uuid,
            token,
            student_uuid
          );
          const recommendedPlan = await loadRecommendedPlan(
            json,
            institution_uuid,
            token,
            student_uuid
          );
          if (pathwayPlanner) json['pathwayPlanner'] = pathwayPlanner;
          if (recommendedPlan)
            json['recommendedPlan'] = {
              ...recommendedPlan,
              request: false,
              error: false,
            };

          const student_credentials = await fetchStudentCredentials(
            token,
            student_uuid
          );
          if (student_credentials)
            json['student_credentials'] = student_credentials;
        }
        dispatch(receiveStudentProfileDetails(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleStudentProfileDetailsError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleStudentProfileDetailsError(err));
  }
};

export const fetchRecommendedPlan = (student_uuid, path_uuid) => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access,
      access: {token},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };

  dispatch(requestStudentRecommendedPlan());
  try {
    return fetch(
      `${
        API.student.pathway_planner
      }?student_uuid=${student_uuid}&path_uuid=${path_uuid}&recommended=true`,
      config
    )
      .then(response => response.json())
      .then(json => {
        dispatch(receiveStudentRecommendedPlan(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleStudentRecommendedPlanError());
      });
  } catch (err) {
    console.log(err);
    dispatch(handleStudentRecommendedPlanError());
  }
};

export const resetStudentProfile = () => dispatch => {
  dispatch(clearStudentProfileDetails());
};
