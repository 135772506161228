import * as TYPES from './constants';
import {
  requestSurveyDetails,
  receiveSurveyDetails,
  handleSurveyDetails,
  requestSurveyStudents,
  receiveSurveyStudents,
  handleSurveyStudents,
  requestInstitutionTerms,
  receiveInstitutionTerms,
  handleInstitutionTerms,
  receiveInstitutions,
  changePermission,
} from './actions';

import {Services} from 'core';
import {API} from 'config/srm.config';

const initialState = {
  firstTime: true,
  surveyDetails: null,
  surveyInstitutions: {
    data: null,
    request: null,
    error: null,
  },
  surveyTerms: {
    data: null,
    request: null,
    error: null,
  },
  request: null,
  error: null,
  surveyStudents: null,
  surveyStudentsRequest: false,
  surveyStudentsError: false,
  UIPermissions: {
    showInstitutionDropdown: false,
  },
};
const services = new Services();
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_SWF_SURVEY_DETAILS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_SWF_SURVEY_DETAILS:
      return {
        ...state,
        surveyDetails: action.payload,
        request: false,
        error: false,
      };
    case TYPES.SWF_SURVEY_DETAILS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.REQUEST_SWF_SURVEY_STUDENTS:
      return {
        ...state,
        surveyStudentsRequest: true,
      };
    case TYPES.RECEIVE_SWF_SURVEY_STUDENTS:
      return {
        ...state,
        surveyStudents: action.payload,
        surveyStudentsRequest: false,
        surveyStudentsError: false,
      };
    case TYPES.SWF_SURVEY_STUDENTS_ERROR:
      return {
        ...state,
        surveyStudentsError: true,
        surveyStudentsRequest: false,
      };
    case TYPES.REQUEST_SWF_INSTITUTION_TERMS:
      return {
        ...state,
        surveyTerms: {
          ...state.surveyTerms,
          request: true,
        },
      };
    case TYPES.CHANGE_SWF_UI_PERMISSIONS:
      return {
        ...state,
        UIPermissions: {
          showInstitutionDropdown: action.payload,
        },
      };
    case TYPES.RECEIVE_SWF_INSTITUTION_TERMS:
      return {
        ...state,
        firstTime: false,
        surveyTerms: {
          data: [...action.payload.terms],
          request: false,
          error: false,
        },
      };
    case TYPES.RECEIVE_SWF_INSTITUTIONS:
      return {
        ...state,
        surveyInstitutions: {
          data: [...action.payload],
          request: false,
          error: false,
        },
      };
    case TYPES.SWF_INSTITUTION_TERMS_ERROR:
      return {
        ...state,
        surveyTerms: {
          ...state.surveyTerms,
          request: false,
          error: true,
        },
      };

    default:
      return state;
  }
};

export const fetchSWFSurveyDetails = () => (dispatch, getState) => {
  const endpoint = `${API.strong_workforce.survey_details}`;
  const {
    auth: {
      access: {token},
    },
  } = getState();

  dispatch(requestSurveyDetails());

  try {
    return services
      .fetchRecords(endpoint, token)
      .then(json => dispatch(receiveSurveyDetails(json)))
      .catch(err => {
        console.log(err);
        dispatch(handleSurveyDetails(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleSurveyDetails(err));
  }
};

export const fetchSurveyStudents = (grad_term, grad_year) => (
  dispatch,
  getState
) => {
  const endpoint = `${
    API.strong_workforce.survey_details
  }?grad_term=${grad_term}&grad_year=${grad_year}`;
  const {
    auth: {
      access: {token},
    },
  } = getState();

  dispatch(requestSurveyStudents());
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(json => dispatch(receiveSurveyStudents(json)))
      .catch(err => {
        console.log(err);
        dispatch(handleSurveyStudents(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleSurveyStudents(err));
  }
};

export const fetchInstitutionTermData = () => (dispatch, getState) => {
  let endpoint = `${API.institution.institution}?is_system_generated=True`;
  const {
    auth: {
      access: {token = null},
      institution_id,
    },
  } = getState();
  dispatch(requestInstitutionTerms());
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(json => {
        if (json && json.length) {
          dispatch(receiveInstitutions(json));
          const institute = json.filter(
            institute => institute.id == institution_id
          )[0];
          if (
            institute &&
            institute.institution_type_name &&
            institute.institution_type_name.name === 'College District'
          ) {
            dispatch(changePermission(true));
          }

          endpoint = `${
            API.strong_workforce.institution_term_data
          }?institution_id=${institute.institution_id}`;
          return services.fetchRecords(endpoint, token).then(json => {
            dispatch(receiveInstitutionTerms(json));
          });
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(handleInstitutionTerms(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleInstitutionTerms(err));
  }
};
