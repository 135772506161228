import * as TYPES from './constants';
import {API, JWT_HEADER} from 'config/srm.config';
import {requestLeadMap, receiveLeadMap} from './actions';

export const leadmapReducer = (state = {}, action) => {
  switch (action.type) {
    case TYPES.REQUEST_LEADMAP:
      return {
        request: true,
        error: false,
        eventId: action.payload,
      };

    case TYPES.RECEIVE_LEAPMAP:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };

    case TYPES.REQUEST_LEADMAP_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };

    default:
      return state;
  }
};

export const leadmap = (token, {uuid}) => (dispatch, getState) => {
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token)),
  };
  dispatch(requestLeadMap(uuid));
  try {
    //?event__uuid=
    return fetch(`${API.outreach.lead_map}${uuid}/`, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveLeadMap(json));
      });
  } catch (err) {
    console.log(err);
  }
};
