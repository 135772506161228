export const SUPER_USER_ROUTES = {
  HOME: {
    DASHBOARD: '/',
  },
  LICENSE_MANAGEMENT: {
    DASHBOARD: '/licenses',
    EDIT: '/licenses/edit',
    CREATE: '/licenses/create',
  },
  ADMIN: {
    PARTNER_MANAGEMENT: '/partners/requests',
    LICENSE_MANAGEMENT: '/licenses/dashboard',
    INSTITUTIONS: '/institutions/dashboard',
    SELECT_INSTITUTE_TYPE: '/select/institute/type',
    CREATE: '/create',
    EDIT: '/edit',
  },
  BANNER_MANAGEMENT: {
    HOME: '/banner-management',
  },
};
