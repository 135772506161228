import * as TYPES from './constants';
import {
  requestInstituteDetails,
  receiveInstituteDetails,
  handleInstituteDetailsError,
} from './actions';

import {Services} from 'core';

import {API} from 'config/reporter.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_INSTITUTE_DETAILS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_INSTITUTE_DETAILS:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.INSTITUTE_DETAILS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchInstituteDetails = ID => (dispatch, getState) => {
  const {
    auth: {access = {}, access: {token = null} = {}},
  } = getState();

  dispatch(requestInstituteDetails());
  let endpoint = `${API.admin.institute}${ID}/`;
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receiveInstituteDetails(res));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleInstituteDetailsError(err));
  }
};
