import * as TYPES from './constants';
import {
  requestDescendentInstitutions,
  receiveDescendentInstitutions,
  handleDescendentInstitutionsError,
} from './actions';

import {Services, isArray} from 'core';

import {API} from 'config/reporter.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_DESCENDENT_INSTITUTIONS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_DESCENDENT_INSTITUTIONS:
      return {
        ...state,
        data: (isArray(action.payload) && [...action.payload]) || null,
        request: false,
        error: false,
      };
    case TYPES.DESCENDENT_INSTITUTIONS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchDescendentInstitutions = instituteId => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestDescendentInstitutions());
  let endpoint = `${API.institution.institution}`;
  try {
    return services
      .fetchDescendantsInstitution(token, endpoint, instituteId)
      .then(res => {
        dispatch(receiveDescendentInstitutions(res));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleDescendentInstitutionsError(err));
  }
};
