import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import {isObject, isString} from 'lodash';
import {styled} from '@mui/material/styles';
import {themeColorMode} from 'mui-core/utils';

const BackgroundPaper = styled(Box)(({theme, background}) => {
  let backgroundColor = theme.palette.background.main;
  if (background && isString(background)) {
    backgroundColor = themeColorMode(
      theme,
      background,
      theme.palette.darkGray.main
    );
  }
  if (
    background &&
    isObject(background) &&
    (background.light || background.dark)
  ) {
    backgroundColor = themeColorMode(
      theme,
      background.light || theme.palette.background.paper,
      background.dark || theme.palette.background.paper
    );
  }
  if (backgroundColor)
    return {
      backgroundColor,
    };
  return {};
});

BackgroundPaper.propTypes = {
  background: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  // if string -> background is applied to light mode and auto color is applied to dark mode
  // if object -> background.light is applied to light mode and background.dark is applied to dark mode
};

export default BackgroundPaper;
