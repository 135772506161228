import * as TYPES from './constants';
import {
  requestLocationSearch,
  receiveLocationSearch,
  handleLocationSearchError,
} from './actions';
import {API} from 'config/businesspartner.config';
import {JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {
  data: null,
  error: null,
  request: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_LOCATION_SEARCH:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_LOCATION_SEARCH:
      return {
        ...state,
        data: action.payload,
        request: false,
      };
    case TYPES.LOCATION_SEARCH_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.LOCATION_SEARCH_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const fetchLocation = key => (dispatch, getState) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestLocationSearch());
  try {
    return fetch(
      `${API.administration.location_details}?location_name=${key}`,
      config
    )
      .then(response => response.json())
      .then(json => {
        dispatch(receiveLocationSearch(json?.location_details));
      })
      .catch(err => {
        dispatch(handleLocationSearchError(err));
      });
  } catch (err) {
    dispatch(handleLocationSearchError(err));
  }
};
