import React from 'react';
import ReactApexChart from 'react-apexcharts';
const LineChart = ({data}) => {
  const {labels, datasets} = data;
  const options = {
    chart: {
      height: 350,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    colors:
      datasets &&
      Array.isArray(datasets) &&
      datasets.map(i => i.backgroundColor),
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    // title: {
    //   text: 'Average High & Low Temperature',
    //   align: 'left',
    // },
    grid: {
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
      //   borderColor: '#e7e7e7',
      //   row: {
      //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      //     opacity: 0.5,
      //   },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: labels,
    },
    yaxis: {
      //   title: {
      //     text: 'Temperature',
      //   },
      //   min: -1,
      //   max: 40,
    },
    tooltip: {
      y: {
        formatter: v => {
          return v;
        },
      },
    },
    legend: {
      showForSingleSeries: true,
      position: 'bottom',
      horizontalAlign: 'center',
      //   floating: true,
      //   offsetY: -25,
      //   offsetX: -5,
    },
  };
  return <ReactApexChart options={options} series={datasets} height={350} />;
};

export default LineChart;
