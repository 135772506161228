import * as TYPES from './constants';
import {
  requestConsortiums,
  receiveConsortiums,
  handleConsortiumsError,
} from './actions';

import {Services} from 'core';

import {API} from 'config/superuser.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_REPORTER_CONSORTIUMS:
      return {...state, request: true};
    case TYPES.RECEIVE_REPORTER_CONSORTIUMS:
      return {
        ...state,
        data: action.payload || null,
        request: false,
        error: false,
      };
    case TYPES.REPORTER_CONSORTIUMS_ERROR:
      return {...state, error: true, request: false};
    default:
      return state;
  }
};

export const fetchConsortiums = (reporterId = null, page = 1, pageSize = 4) => (
  dispatch,
  getState
) => {
  const {
    auth: {access = {}, access: {token} = {}},
  } = getState();
  const parentId = reporterId || access['custom:institution_uuid'];
  if (parentId) {
    dispatch(requestConsortiums());
    let endpoint = `${
      API.administration.institution
    }?parent=${parentId}&show=consortium&page=${page}&page_size=${pageSize}`;
    try {
      return services
        .fetchRecords(endpoint, token)
        .then(res => {
          dispatch(receiveConsortiums(res));
        })
        .catch(err => console.log(err));
    } catch (err) {
      console.log(err);
      dispatch(handleConsortiumsError(err));
    }
  }
};
