import * as TYPES from './constants';
import {
  receiveConsortiumProfile,
  handleReceiveProfileError,
  setToggleConsortiumProfile,
} from './actions';
import {getLoggedInstituteUUID} from 'redux/modules/auth';

import {Services} from 'core';
import {API} from 'config/superuser.config';

const initialState = {
  consortiumProfile: null,
  isConsortiumProfileExist: false,
  editMode: false,
  request: null,
  error: null,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_CONSORTIUM_USER:
      return {
        ...state,
        isConsortiumProfileExist:
          action.payload &&
          action.payload.primary_contact_1 &&
          //action.payload.primary_contact_1.email &&
          //action.payload.primary_contact_1.phone &&
          // action.payload.primary_contact_1.last_name &&
          // action.payload.primary_contact_1.first_name &&
          // action.payload.physical_address_details &&
          // action.payload.physical_address_details.city &&
          // action.payload.physical_address_details.state &&
          // action.payload.physical_address_details.zipcode &&
          // action.payload.physical_address_details.address_line1 &&
          // action.payload.web_url &&
          (action.payload.logo_cloudinary || action.payload.logo),
        error: false,
        request: false,
        consortiumProfile: action.payload,
      };
    case TYPES.TOGGLE_CONSORTIUM_PROFILE:
      return {
        ...state,
        editMode: action.payload,
      };
    case TYPES.REQUEST_CONSORTIUM_USER_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const toggleConsortiumProfile = status => dispatch => {
  dispatch(setToggleConsortiumProfile(status));
};

export const fetchConsortiumProfile = () => (dispatch, getState) => {
  const {
    auth: {access = {}, access: {token} = {}},
  } = getState();
  const uuid = getLoggedInstituteUUID(getState());
  let endpoint = `${API.administration.institution}${uuid}`;
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receiveConsortiumProfile(res));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleReceiveProfileError(err));
  }
};
