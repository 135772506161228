// import {createSelector} from 'reselect';

export const getLicenseSelector = state =>
  state && state.superUserAdmin && state.superUserAdmin.admin;
export const getInstitutionTypesSelector = state =>
  state && state.superUserAdmin && state.superUserAdmin.institutionTypes;
export const getquikFyndDataSelector = state =>
  state && state.superUserAdmin && state.superUserAdmin.quikFyndData;
export const getInstitutionsSelector = state =>
  state && state.superUserAdmin && state.superUserAdmin.institutions;
export const getInstituteDetailsSelector = state =>
  state && state.superUserAdmin && state.superUserAdmin.instituteDetails;
export const getInstituteTimelineSelector = state =>
  state && state.superUserAdmin && state.superUserAdmin.instituteTimeline;
export const getConsortiumInstitutionsSelector = state =>
  state && state.superUserAdmin && state.superUserAdmin.consortiumInstitutions;
export const getPartnersRequestsSelector = state =>
  state && state.superUserAdmin && state.superUserAdmin.partnersRequests;

export const getBannerInstances = state =>
  state && state.superUserAdmin && state.superUserAdmin.bannerInstances;

// export const getSuperUserLicenseTypesSelector = state =>
//   state && state.superUserAdmin && state.superUserAdmin.licenseTypes;
export const getSuperUserInstituteTypesSelector = state =>
  state && state.superUserAdmin && state.superUserAdmin.instituteTypes;
export const getMSALocations = state =>
  state && state.superUserAdmin && state.superUserAdmin.msaLocations;
