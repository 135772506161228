// get jobs List
export const REQUEST_EMPLOYER_JOBS = 'REQUEST_EMPLOYER_JOBS';
export const RECEIVE_EMPLOYER_JOBS = 'RECEIVE_EMPLOYER_JOBS';
export const EMPLOYER_JOBS_ERROR = 'EMPLOYER_JOBS_ERROR';
export const CLEAR_EMPLOYER_JOBS = 'CLEAR_EMPLOYER_JOBS';
export const EMPLOYER_JOB_STATUS_UPDATE = 'EMPLOYER_JOB_STATUS_UPDATE';

// create job - Experience level
export const REQUEST_EMPLOYER_EXPERIENCE_LEVEL =
  'REQUEST_EMPLOYER_EXPERIENCE_LEVEL';
export const RECEIVE_EMPLOYER_EXPERIENCE_LEVEL =
  'RECEIVE_EMPLOYER_EXPERIENCE_LEVEL';
export const EMPLOYER_EXPERIENCE_LEVEL_ERROR =
  'EMPLOYER_EXPERIENCE_LEVEL_ERROR';

// create job - Education level
export const REQUEST_EMPLOYER_EDUCATION_LEVEL =
  'REQUEST_EMPLOYER_EDUCATION_LEVEL';
export const RECEIVE_EMPLOYER_EDUCATION_LEVEL =
  'RECEIVE_EMPLOYER_EDUCATION_LEVEL';
export const EMPLOYER_EDUCATION_LEVEL_ERROR = 'EMPLOYER_EDUCATION_LEVEL_ERROR';

// create job - Qualifying Programs
export const REQUEST_QUALIFYING_PROGRAMS = 'REQUEST_QUALIFYING_PROGRAMS';
export const RECEIVE_QUALIFYING_PROGRAMS = 'RECEIVE_QUALIFYING_PROGRAMS';
export const QUALIFYING_PROGRAMS_ERROR = 'QUALIFYING_PROGRAMS_ERROR';
export const QUALIFYING_PROGRAMS_CLEAR = 'QUALIFYING_PROGRAMS_CLEAR';
