import React, {useEffect} from 'react';
import {Button} from 'antd';
import {getBase} from 'mui-core/domain-services';
import {useLocation, useHistory} from 'react-router';

export default ({
  icon,
  headerTitle,
  message,
  ShowBackButton,
  className,
  isAuthticated,
}) => {
  const base = getBase();
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (base === 'admin' && !isAuthticated && location.pathname === '/') {
      history.push('/superuser');
    }
  }, []);
  return (
    <div className={`no-results-msg ${className}`}>
      <span className={`no-result-icon icon-${icon || 'robot'}`} />
      {headerTitle === null ? (
        <></>
      ) : (
        <span className="head">{headerTitle || 'Oops!'}</span>
      )}
      <span className="sub-head">
        {message || 'No results found. Give it another try !!'}
      </span>
      {ShowBackButton && (
        <Button type="primary" className="margin-t20" onClick={ShowBackButton}>
          Go Back!
        </Button>
      )}
    </div>
  );
};
