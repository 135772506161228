import * as TYPES from './constants';
export const receiveReportObjects = json => ({
  type: TYPES.RECEIVE_REPORT_OBJECTS,
  payload: json,
});

export const requestReportObjects = () => ({
  type: TYPES.REQUEST_REPORT_OBJECTS,
});

export const handleReportObjectsError = err => ({
  type: TYPES.REPORT_OBJECTS_ERROR,
  err,
});

export const receiveReportRun = json => ({
  type: TYPES.RECEIVE_REPORT_RUN,
  payload: json,
});

export const requestReportRun = () => ({
  type: TYPES.REQUEST_REPORT_RUN,
});

export const handleReportRunError = err => ({
  type: TYPES.REPORT_RUN_ERROR,
  err,
});

export const receiveReports = json => ({
  type: TYPES.RECEIVE_REPORTS,
  payload: json,
});

export const requestReports = () => ({
  type: TYPES.REQUEST_REPORTS,
});

export const handleReportsError = err => ({
  type: TYPES.REPORTS_ERROR,
  err,
});
export const cancelReportFirstTimeLoad = () => ({
  type: TYPES.REPORTS_FIRST_LOAD,
});

export const receiveReport = json => ({
  type: TYPES.RECEIVE_REPORT,
  payload: json,
});

export const clearReport = () => ({
  type: TYPES.CLEAR_REPORT,
});

export const requestReport = () => ({
  type: TYPES.REQUEST_REPORT,
});

export const handleReportError = err => ({
  type: TYPES.REPORT_ERROR,
  err,
});
