import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import * as TYPES from './constants';
import {
  requestCourseManagementCourseInstructionType,
  receiveCourseManagementCourseInstructionType,
  handleCourseManagementCourseInstructionTypeError,
} from './actions';
const initialState = {
  request: null,
  data: null,
  error: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_COURSE_MANAGEMENT_COURSE_INSTRUCTION_TYPE:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_COURSE_MANAGEMENT_COURSE_INSTRUCTION_TYPE:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };

    case TYPES.COURSE_MANAGEMENT_COURSE_INSTRUCTION_TYPE_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchCourseInstructionType = () => (dispatch, getState) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestCourseManagementCourseInstructionType());
  try {
    return fetch(`${API.course_management.course_instruction_type}`, config)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Api Fails');
      })
      .then(json => {
        dispatch(receiveCourseManagementCourseInstructionType(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleCourseManagementCourseInstructionTypeError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleCourseManagementCourseInstructionTypeError(err));
  }
};
