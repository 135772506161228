import {styled} from '@mui/material/styles';
import {default as MuiTabList} from '@mui/lab/TabList';
import PropTypes from 'prop-types';

const dashboardStyles = {
  backgroundColor: '#F2F4FA',
  padding: '12px 24px 0px',
};
const TabList = styled(MuiTabList)(props => {
  const {bordered, dashboard} = props;
  return {
    borderBottom: bordered && '1px solid #e8e8e8',
    ...(dashboard ? dashboardStyles : {}),
  };
});

TabList.propTypes = {
  ...TabList.propTypes,
  bordered: PropTypes.bool,
};

export default TabList;
