export const REQUEST_CONSORTIUM_INSTITUTIONS =
  'REQUEST_CONSORTIUM_INSTITUTIONS';
export const RECEIVE_CONSORTIUM_INSTITUTIONS =
  'RECEIVE_CONSORTIUM_INSTITUTIONS';
export const RECEIVE_CONSORTIUM_INSTITUTIONS_LIST_FOR_FILTER =
  'RECEIVE_CONSORTIUM_INSTITUTIONS_LIST_FOR_FILTER';
export const CONSORTIUM_INSTITUTIONS_ERROR = 'CONSORTIUM_INSTITUTIONS_ERROR';

export const REQUEST_CONSORTIUM_INSTITUTION_TYPES =
  'REQUEST_CONSORTIUM_INSTITUTION_TYPES';
export const RECEIVE_CONSORTIUM_INSTITUTION_TYPES =
  'RECEIVE_CONSORTIUM_INSTITUTION_TYPES';
export const REQUEST_CONSORTIUM_USER = 'REQUEST_CONSORTIUM_USER';
export const REQUEST_CONSORTIUM_USER_ERROR = 'REQUEST_CONSORTIUM_USER_ERROR';
export const TOGGLE_CONSORTIUM_PROFILE = 'TOGGLE_CONSORTIUM_PROFILE';
export const CONSORTIUM_INSTITUTION_TYPES_ERROR =
  'CONSORTIUM_INSTITUTION_TYPES_ERROR';
export const REQUEST_CONSORTIUM_INSTITUTE_DETAILS =
  'REQUEST_CONSORTIUM_INSTITUTE_DETAILS';
export const RECEIVE_CONSORTIUM_INSTITUTE_DETAILS =
  'RECEIVE_CONSORTIUM_INSTITUTE_DETAILS';
export const CLEAR_CONSORTIUM_INSTITUTE_DETAILS =
  'CLEAR_CONSORTIUM_INSTITUTE_DETAILS';
export const CONSORTIUM_INSTITUTE_DETAILS_ERROR =
  'CONSORTIUM_INSTITUTE_DETAILS_ERROR';
