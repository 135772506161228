import React from 'react';

import {Button, Form, Select, Input, Row, Col} from 'antd';
import {capitalizeFirstLetter} from 'core';
import ProfileHeader from './ProfileHeader';
import mapworldicon from 'sharedResources/assets/icons/mapworldicon.svg';

const Option = Select.Option;
const FormItem = Form.Item;

const ConnectState = ({
  prevStep,
  onSave,
  form,
  form: {getFieldDecorator, setFieldsValue},
  initialData,
  data,
}) => {
  //ROP Data
  const {consortium_list: selectedList = []} = initialData;
  const ropinitialvalue =
    selectedList && Array.isArray(selectedList) && selectedList.length > 0
      ? selectedList.map(res => {
          return res && res.institution_id;
        })
      : [];

  let filteredStates =
    data && data.data && Array.isArray(data.data) && data.data.length > 0
      ? selectedList.map(item => {
          return data.data.find(
            res =>
              res.consortium_uuid === item ||
              res.consortium_uuid === item.institution_id
          );
        })
      : [];

  let filterData =
    filteredStates &&
    filteredStates !== undefined &&
    Array.isArray(filteredStates) &&
    filteredStates.length > 0
      ? filteredStates.map(res => {
          return res && res.consortium_uuid;
        })
      : [];

  // institution_list
  return (
    <div className="steps-content connect_bp">
      <ProfileHeader data={initialData} />,
      <Form onSubmit={e => onSave(e, form)}>
        <div className="select-path">
          <div className="select-path-type">
            <Row>
              <Col xs={24} sm={24} md={12} lg={12}>
                <img src={mapworldicon} alt="" />
                <p>
                  Connecting to a Regional Opportunity Portal (ROP) will include
                  your institution’s programs in the ROP marketplace as well as
                  the global GoEducate marketplace for increased exposure.
                </p>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                className="table-form float-right margin-auto">
                <div className="select-header">
                  <h4 className="font-size-24 margin-b10">
                    Connect Institution to ROP(s):
                  </h4>
                </div>
                <h3 className="float-left">Choose ROP :</h3>
                <FormItem>
                  {getFieldDecorator('consortium_list', {
                    rules: [
                      {
                        required: false,
                        message: 'Please select ROP',
                      },
                    ],
                    initialValue: ropinitialvalue.includes(undefined)
                      ? filterData
                      : ropinitialvalue,
                  })(
                    <Select
                      placeholder="Select ROP"
                      mode="multiple"
                      showArrow
                      tokenSeparators={[',']}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }>
                      {data &&
                        data.data &&
                        data.data.map(
                          ({
                            city,
                            state_code,
                            consortium_name,
                            consortium_uuid,
                          }) => (
                            <Option
                              key={consortium_uuid}
                              value={consortium_uuid}
                              title={capitalizeFirstLetter(consortium_name)}>
                              {`${capitalizeFirstLetter(
                                consortium_name
                              )} - ${city}, ${state_code}`}
                            </Option>
                          )
                        )}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
          </div>
        </div>
        <FormItem className="margin-muted">
          <div className="steps-action">
            <Button type="primary" onClick={prevStep} className="margin-r20">
              Previous Step
            </Button>
            <Button type="primary" htmlType="submit">
              Next Step
            </Button>
          </div>
        </FormItem>
      </Form>
    </div>
  );
};

export default Form.create()(ConnectState);
