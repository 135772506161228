import React from 'react';
import {Avatar, Button} from 'antd';
import {getCloudinaryImage} from 'core';
import {
  profileHeader,
  FaceBook,
  Instagram,
  Twitter,
  LinkedIn,
  defaultimgcover,
  Ticktok,
  Medium,
} from 'sharedResources/assets/images';

const ProfileHeader = ({data, collegeData = {}, onSendMsg, hideSendMsg}) => {
  const {
    first_name = '',
    last_name = '',
    address = {},
    photo,
    banner,
    show_facebook_link,
    show_twitter_link,
    show_linkedin_link,
    show_instagram_link,
    show_tiktok_link,
    show_medium_link,
    facebook_link,
    twitter_link,
    linkedin_link,
    instagram_link,
    tiktok_link,
    medium_link,
    qr_code,
  } = data || {};
  let {path_details} = collegeData;
  let collegeName = '';
  if (collegeData && collegeData.path_details) {
    if (path_details) {
      collegeName = `${path_details.institute_details &&
        path_details.institute_details.name}`;
      //, ${path_info.institute_details &&
      //   path_info.institute_details.address
      //     .city
      // }, ${
      // path_info.institute_details &&
      //   path_info.institute_details.address.state
      // }
    }
  }
  const qrCodeImage = qr_code && getCloudinaryImage(qr_code);

  return (
    <div
      className="student-profile-header"
      style={{
        backgroundPosition: 'center',
        // backgroundImage: `url(${(path_details &&
        //   getCloudinaryImage(path_details.banner_cloudinary)) ||
        //   profileHeader})`,
        backgroundImage: `url(${(banner && getCloudinaryImage(banner)) ||
          defaultimgcover})`,
      }}>
      <div className="student-details">
        <div className="rounded-container">
          <div className="student-image">
            <Avatar src={getCloudinaryImage(photo, 'logo')} icon="user" />
          </div>
          {data && (
            <div className="details">
              <span className="student-name">{`${first_name} ${last_name}`}</span>
              {address && address.city && address.state && (
                <span className="student-college">{`Lives in: ${
                  address.city
                }, ${address.state}`}</span>
              )}

              <span className="student-actions">
                {show_facebook_link && facebook_link && (
                  <Button href={facebook_link} target="_blank">
                    <img className="social-icons" src={FaceBook} alt="" />
                  </Button>
                )}
                {show_twitter_link && twitter_link && (
                  <Button href={twitter_link} target="_blank">
                    <img className="social-icons" src={Twitter} alt="" />
                  </Button>
                )}
                {show_linkedin_link && linkedin_link && (
                  <Button href={linkedin_link} target="_blank">
                    <img className="social-icons" src={LinkedIn} alt="" />
                  </Button>
                )}
                {show_instagram_link && instagram_link && (
                  <Button href={instagram_link} target="_blank">
                    <img className="social-icons" src={Instagram} alt="" />
                  </Button>
                )}
                {show_tiktok_link && tiktok_link && (
                  <Button href={tiktok_link} target="_blank">
                    <img
                      className="social-icons tiktok-icon"
                      src={Ticktok}
                      alt=""
                    />
                  </Button>
                )}
                {show_medium_link && medium_link && (
                  <Button href={medium_link} target="_blank">
                    <img
                      className="social-icons medium-icon"
                      src={Medium}
                      alt=""
                    />
                  </Button>
                )}
              </span>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="profile-qr-code">
          {qrCodeImage && (
            <img src={qrCodeImage} className="profile-qr-code-img" />
          )}
        </div>
        <div className="actions">
          {!hideSendMsg && (
            <Button type="primary" size="large" onClick={onSendMsg}>
              SEND MESSAGE
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
