import React from 'react';
import PropTypes from 'prop-types';
import {Icon, Button} from 'antd';

import {goEducateLogoColored} from 'sharedResources/assets/images';
import {TERMS, CURRENT_DATE} from 'config/srm.config';

const currentYear = CURRENT_DATE.getFullYear();

const AboutUs = ({closeHandler}) => (
  <div className="profile-dropdown edit-prof-dropdown">
    <h1 className="profile-title">
      <span className="link">
        <Icon onClick={closeHandler} type="left" />
      </span>
      About us
    </h1>
    <div className="clearfix prof-aboutus">
      <div className="pro-logo">
        <img src={goEducateLogoColored} alt="goeducate srm logo" />
        <p className="ver-txt">Version {process.env.REACT_APP_VERSION}</p>
      </div>
      <p className="para-txt">
        &copy; 2016-
        {currentYear} GoEducate, Inc. All rights reserved.
      </p>
      <p className="para-txt">
        GoEducate and the Go Partners are either registered trademarks or
        trademarks of GoEducate Incorporated in the United States and/or other
        countries.
      </p>
      <div className="btn-row clearfix">
        <Button
          href={TERMS}
          className="btn-outline btn-dark-line"
          target="_blank">
          Terms
        </Button>
      </div>
    </div>
  </div>
);

AboutUs.propTypes = {
  closeHandler: PropTypes.func.isRequired,
};

export default AboutUs;
