import {combineReducers} from 'redux';

import state from './state';
import tuitionLink from './tuitionLink';
import consortiumStates from './consortiumStates';
import companytoInstitution from './companytoInstitution';
import locationSearch from './locationSearch';
import linkedInDetails from './linkedInDetails';

export const staticData = combineReducers({
  state,
  tuitionLink,
  locationSearch,
  linkedInDetails,
  consortiumStates,
  companytoInstitution,
});
