import * as TYPES from 'redux/types';
import {API, JSON_HEADER} from 'config/srm.config';
import {API as SUPER_USER_API} from 'config/superuser.config';

let inProgress = false;
export default ({dispatch, getState}) => next => async action => {
  const {
    auth: {
      user_portal = null,
      access = {},
      access: {exp = null} = {},
      refresh: {token = null} = {},
      loggedinUserId = null,
      isOnline,
    } = {},
  } = getState();

  const {type} = action;

  if (!type) {
    return next(action);
  }

  const refreshThreshold = new Date().getTime(); // Less than 5 minutes from now
  // for testing 3.42e6 = 57 minutes
  if (
    !inProgress &&
    isOnline &&
    type !== TYPES.SIGNOUT &&
    exp &&
    refreshThreshold > exp * 1000 - 300000
  ) {
    let USER_ID = loggedinUserId;
    inProgress = true;
    if (!USER_ID) {
      USER_ID = access['cognito:username'];
    }

    if (!token || !USER_ID) {
      return next({
        type: TYPES.SIGNOUT,
      });
    }

    if (token && USER_ID) {
      const config = {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        headers: Object.assign({}, JSON_HEADER),
        body: JSON.stringify(
          Object.assign({}, {user_uuid: USER_ID}, {refresh_token: token})
        ),
      };
      try {
        // check for super user ---
        let endPoint = `${API.auth.refresh}`;
        if (user_portal === 'super_user') {
          endPoint = `${SUPER_USER_API.auth.refresh_token}`;
        }
        await fetch(endPoint, config)
          .then(response => response.json())
          .then(json => {
            inProgress = false;
            return next({
              payload: json,
              type: TYPES.REFRESH_ACCESS_TOKEN,
            });
          })
          .catch(err => {
            inProgress = false;
            console.log(err);
            return next({
              type: TYPES.SIGNOUT,
            });
          });
      } catch (err) {
        inProgress = false;
        console.log(err);
        return next({
          type: TYPES.SIGNOUT,
        });
      }
    }
  } else {
    return next(action);
  }
};
