import React from 'react';
import {Divider, Icon} from 'antd';
import {NoSearchResults} from 'core';

const AwardsItem = ({
  awardTypes,
  data: {item_type, item_title, comments} = {},
}) => {
  const awardType =
    awardTypes && awardTypes.find(item => item.item_type_uuid === item_type);
  const commentValues =
    comments &&
    comments.length > 0 &&
    comments.filter(comment => typeof comment.additional_info === 'string');
  return (
    <div className="work-experience-item">
      <div className="header-details">
        <span className="company-name text-capitalize">
          {awardType && awardType.item_type} :
        </span>
        &nbsp;
        <span>{item_title}</span>
      </div>
      {commentValues && commentValues.length > 0 && (
        <div className="role-n-responsibilities">
          <div className="responsibilities">
            <ul>
              {commentValues.map((comment, index) =>
                comment && comment.additional_info ? (
                  <li key={`point-${index}`} className={`point point-${index}`}>
                    {/* <Icon type="caret-right" /> */}
                    {comment.additional_info}
                  </li>
                ) : (
                  <span>-</span>
                )
              )}
            </ul>
          </div>
        </div>
      )}
      <Divider className="_divider" />
    </div>
  );
};

const Awards = ({data, awardTypes}) => {
  const {awards_leadership_projects_publications = []} = data || {};
  return (
    <div className="work-experience">
      <div className="work-experience-header">
        Awards, Leadership, Projects & Publications
      </div>
      <div className="work-experience-content">
        {awards_leadership_projects_publications &&
          awards_leadership_projects_publications.length > 0 &&
          awards_leadership_projects_publications.map((item, idx) => (
            <AwardsItem
              key={'awards_leadership_projects_publications-' + idx}
              data={item}
              awardTypes={awardTypes}
            />
          ))}
      </div>
      {/* <Divider className="_divider" /> */}
    </div>
  );
};

export default Awards;
