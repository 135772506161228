import Welcome from './components/Welcome';
import CreateProfile from './components/CreateProfile';
import {
  CreateNewProfile,
  Connect,
  PublishProfile,
} from './components/NewProfile';
import './components/style.css';

export const Profile = {
  Welcome,
  CreateProfile,
  CreateNewProfile,
  Connect,
  PublishProfile,
};
