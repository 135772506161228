import {combineReducers} from 'redux';
import studentsList from './studentsList';
import savedSearches from './savedSearches';
import savedProfiles from './savedProfiles';

export const talentExchangeReducer = combineReducers({
  studentsList,
  savedSearches,
  savedProfiles,
});
