import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import * as TYPES from './constants';
import {
  requestCourseManagementCourses,
  receiveCourseManagementCourses,
  handleCourseManagementCoursesError,
} from './actions';
const initialState = {
  request: null,
  data: {},
  error: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_COURSE_MANAGEMENT_COURSES:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_COURSE_MANAGEMENT_COURSES:
      if (action.payload && action.payload.courses) {
        action.payload.courses.map((course, idx) => (course.key = idx + 1));
      }
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };

    case TYPES.COURSE_MANAGEMENT_COURSES_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchCatalogCourses = catalogId => (dispatch, getState) => {
  const {
    auth: {
      access: {token = null},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  let endPoint = API.course_management.courses;
  if (catalogId)
    endPoint = `${API.course_management.courses}?catalog_id=${catalogId}`;
  dispatch(requestCourseManagementCourses());
  try {
    return fetch(endPoint, config)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Api Fails');
      })
      .then(json => {
        dispatch(receiveCourseManagementCourses(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleCourseManagementCoursesError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleCourseManagementCoursesError(err));
  }
};
