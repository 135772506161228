import React from 'react';
import {Button} from 'antd';
import {Icon} from 'antd';
import {NoSearchResults} from 'core';

const Portfolio = ({student_Info}) => {
  const {documents = [], portfolio_url} = student_Info;
  const filteredDocs = documents.filter(
    doc => doc.doc_type === 'portfolio_document'
  );
  const weburl = portfolio_url && portfolio_url.replace(/^https?\:\/\//i, '');

  return (
    <div className="profile-portfolio">
      <div className="portfolio-header">Portfolio</div>
      <div className="portfolio-content">
        {portfolio_url ||
        (filteredDocs &&
          Array.isArray(filteredDocs) &&
          filteredDocs.length > 0) ? (
          <>
            {portfolio_url && (
              <div className="portfolioUrlSec">
                <div className="">Portfolio URL: </div>
                <a
                  href={portfolio_url}
                  title={portfolio_url}
                  target="_blank"
                  className="portfolioUrlBtn px-2">
                  {weburl}
                </a>
              </div>
            )}
            {filteredDocs &&
              Array.isArray(filteredDocs) &&
              filteredDocs.length > 0 && (
                <div className="_docs-list">
                  {filteredDocs &&
                    filteredDocs.length > 0 &&
                    filteredDocs.map(doc => (
                      <div className="_item" key={doc.doc_uuid}>
                        <div className="_ic">
                          <Icon type="file" />
                        </div>
                        <a
                          href={doc.doc_link}
                          target="_blank"
                          className="portfolioUrlLink px-2"
                          download="file">
                          {doc.doc_name}
                        </a>
                      </div>
                    ))}
                </div>
              )}
          </>
        ) : (
          <NoSearchResults
            className="no-results-text"
            message="No Data Found"
          />
        )}
      </div>
    </div>
  );
};

export default Portfolio;
