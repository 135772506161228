import React, {Component} from 'react';

import {Form, Row, Col, Select, Input, Icon, Tag} from 'antd';

import debounce from 'lodash.debounce';

import {isEmpty} from 'core';

import {graduationCap} from 'sharedResources/assets/images';

import './style.css';

const FormItem = Form.Item;
const Option = Select.Option;

class ClusterProgramSelector extends Component {
  state = {};
  componentDidUpdate(prevProps, prevState) {
    const {data} = this.props;
    if (prevProps.data !== data) {
      if (data && !isEmpty(data)) {
        const {career_cluster} = data;
        this.selectClusterSelect(career_cluster, 'true');
      }
    }
  }

  SearchPrograms = term => {
    if (term && term.length >= 3) {
      const {fetchQualifyingPrograms} = this.props;
      fetchQualifyingPrograms(term);
      this.setState({
        searchTerm: term,
      });
    }
  };

  onSelectProgram = uuid => {
    const {
      qualifyingPrograms,
      onSelectProgram,
      selectedQualifyPrograms,
    } = this.props;
    const item = qualifyingPrograms.data.filter(i => i.uuid === uuid)[0];
    const exists = selectedQualifyPrograms.filter(i => i.uuid === uuid)[0];
    if (!exists) onSelectProgram(item);
  };

  CollegeName = ({data = {}, withHyphen}) => {
    const {institute_details = {}, institute_name} = data || {};
    const inst_name =
      (institute_details && institute_details.name) || institute_name || null;
    return inst_name ? (
      <span className="program-college-name">
        {withHyphen && ' - '}
        {inst_name}
      </span>
    ) : null;
  };

  render() {
    const {
      form: {getFieldDecorator},
      noForm,
      headingText,
      clearSelectedPrograms,
      qualifyingPrograms,
      selectedQualifyPrograms,
      showInstituteName,
      placeholder,
    } = this.props;
    const {searchTerm, searchValue} = this.state;
    const options =
      qualifyingPrograms &&
      qualifyingPrograms.data &&
      Array.isArray(qualifyingPrograms.data) &&
      qualifyingPrograms.data.map(
        (d, idx) =>
          searchTerm &&
          idx < 15 && (
            <Option
              key={d.uuid}
              value={d.uuid}
              className="program-option"
              // disabled={selectedQualifyPrograms.some(i => i.uuid === d.uuid)}
            >
              {showInstituteName && (
                <img src={graduationCap} className="college-icon" alt="" />
              )}
              {d.title}
              {showInstituteName && <this.CollegeName data={d} />}
            </Option>
          )
      );
    const contentComponent = () => {
      return (
        <div className="table-form">
          <div className="custom-collapse-header">
            <Row type="flex" className="ant-col-24">
              <Col xs={24} sm={24} md={24} lg={17}>
                {headingText || 'Link Job to Qualifying Programs'}
              </Col>
            </Row>
          </div>
          <div className={'steps-header'}>
            <Row type="flex" gutter={10}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                className="steps-header border-bottom"
                style={{
                  position: 'relative',
                }}>
                <FormItem label="Qualifying Programs">
                  {qualifyingPrograms && qualifyingPrograms.request ? (
                    <Icon
                      type="loading"
                      style={{
                        position: 'absolute',
                        zIndex: '1',
                        top: '4px',
                        left: '5px',
                        fontSize: 16,
                      }}
                    />
                  ) : (
                    <Icon
                      type="search"
                      style={{
                        position: 'absolute',
                        zIndex: '1',
                        top: '4px',
                        left: '5px',
                      }}
                    />
                  )}

                  <Select
                    showSearch
                    showArrow={false}
                    filterOption={false}
                    defaultActiveFirstOption={false}
                    allowClear={!showInstituteName}
                    value={searchValue}
                    onSearch={debounce(this.SearchPrograms, 800)}
                    onChange={val => this.onSelectProgram(val, 'search')}
                    placeholder={placeholder || 'Search Programs'}
                    notFoundContent={
                      searchTerm
                        ? 'no occupation matches for search query'
                        : null
                    }
                    style={{paddingLeft: '11px'}}>
                    {options}
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row type="flex" style={{width: '100%'}}>
              <Col style={{width: '100%'}}>
                <FormItem
                  className={`${
                    showInstituteName ? 'tabbed-tag-parent' : ''
                  } occupation-form-item`}
                  label="Selected Qualifying Programs:">
                  {getFieldDecorator('qualifying_programs', {})(
                    <>
                      {selectedQualifyPrograms
                        ? selectedQualifyPrograms.map(item => (
                            <Tag
                              className={`${
                                showInstituteName ? 'tabbed' : ''
                              } occupation-tag`}
                              closable
                              visible
                              key={item.uuid}
                              onClose={() => clearSelectedPrograms(item.uuid)}>
                              {item.title}
                              {showInstituteName && (
                                <this.CollegeName data={item} withHyphen />
                              )}
                            </Tag>
                          ))
                        : '—'}
                    </>
                  )}
                </FormItem>
              </Col>
            </Row>
          </div>
        </div>
      );
    };

    return noForm ? contentComponent() : <Form>{contentComponent()}</Form>;
  }
}

export default Form.create()(ClusterProgramSelector);
export {ClusterProgramSelector};
