import * as TYPES from './constants';
import {
  requestPartnersGraphdata,
  receivePartnersGraphdata,
  handlePartnersGraphdataError,
} from './actions';

import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {
  data: null,
  request: null,
  error: null,
  monthsData: 'all',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_PARTNERS_GRAPH_DATA:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_PARTNERS_GRAPH_DATA:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.PARTNERS_MONTHS_FILTER:
      return {
        ...state,
        monthsData: action.months,
      };
    case TYPES.PARTNERS_GRAPH_DATA_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchpartnersGraphData = () => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestPartnersGraphdata());
  try {
    return fetch(`${API.partners.partner_dashboard_data}`, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receivePartnersGraphdata(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handlePartnersGraphdataError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handlePartnersGraphdataError(err));
  }
};
