import * as TYPES from './constants';
import {
  requestCompanytoInstitution,
  receiveCompanytoInstitution,
  handleCompanytoInstitutionError,
} from './actions';

import {Services} from 'core';

import {API} from 'config/consortium.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

const services = new Services();
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_COMPANY_TO_INSTITUTION_DATA:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_COMPANY_TO_INSTITUTION_DATA:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.COMPANY_TO_INSTITUTION_DATA_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchcompanytoinstitution = () => (dispatch, getState) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();
  dispatch(requestCompanytoInstitution());

  let endpoint = `${API.administration.allInstitutes}`;

  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receiveCompanytoInstitution(res));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleCompanytoInstitutionError(err));
  }
};
