export const REQUEST_TIME = 'REQUEST_TIME';
export const REQUEST_CLUSTER_TYPE = 'REQUEST_CLUSTER_TYPE';
export const RECEIVE_CLUSTER_TYPE = 'RECEIVE_CLUSTER_TYPE';
export const REQUEST_CLUSTER_TYPE_ERROR = 'REQUEST_CLUSTER_TYPE_ERROR';
export const REQUEST_PATH_TYPE_NAME = 'REQUEST_PATH_TYPE_NAME';
export const RECEIVE_PATH_TYPE_NAME = 'RECEIVE_PATH_TYPE_NAME';
export const REQUEST_PATH_TYPE_NAME_ERROR = 'REQUEST_PATH_TYPE_NAME_ERROR';
export const REQUEST_CLUSTERS = 'REQUEST_CLUSTERS';
export const RECEIVE_CLUSTERS = 'RECEIVE_CLUSTERS';
export const REQUEST_CLUSTERS_ERROR = 'REQUEST_CLUSTERS_ERROR';
export const CLEAR_CAREER_PATH = 'CLEAR_CAREER_PATH';
export const SEARCH_CAREER_PATH = 'SEARCH_CAREER_PATH';
export const REQUEST_CAREER_PATHS = 'REQUEST_CAREER_PATHS';
export const RECEIVE_CAREER_PATHS = 'RECEIVE_CAREER_PATHS';
export const REQUEST_CAREER_PATHS_ERROR = 'REQUEST_CAREER_PATHS_ERROR';
export const DELETE_CAREER_PATH = 'DELETE_CAREER_PATH';
export const REQUEST_PROGRAM_DURATION = 'REQUEST_PROGRAM_DURATION';
export const RECEIVE_PROGRAM_DURATION = 'RECEIVE_PROGRAM_DURATION';
export const REQUEST_PROGRAM_DURATION_ERROR = 'REQUEST_PROGRAM_DURATION_ERROR';

export const REQUEST_PROGRAMS = 'REQUEST_PROGRAMS';
export const RECEIVE_PROGRAMS = 'RECEIVE_PROGRAMS';
export const PROGRAMS_ERROR = 'PROGRAMS_ERROR';

export const REQUEST_ACHIVEMENTS = 'REQUEST_ACHIVEMENTS';
export const RECEIVE_ACHIVEMENTS = 'RECEIVE_ACHIVEMENTS';
export const REQUEST_ACHIVEMENTS_ERROR = 'REQUEST_ACHIVEMENTS_ERROR';
export const REQUEST_COURSE_TYPES = 'REQUEST_COURSE_TYPES';
export const RECEIVE_COURSE_TYPES = 'RECEIVE_COURSE_TYPES';
export const REQUEST_COURSE_TYPES_ERROR = 'REQUEST_COURSE_TYPES_ERROR';
export const REQUEST_MILESTONE_TYPES = 'REQUEST_MILESTONE_TYPES';
export const RECEIVE_MILESTONE_TYPES = 'RECEIVE_MILESTONE_TYPES';
export const REQUEST_MILESTONE_TYPES_ERROR = 'REQUEST_MILESTONE_TYPES_ERROR';
export const REQUEST_SEARCH_COURSE = 'REQUEST_SEARCH_COURSE';
export const RECEIVE_SEARCH_COURSE = 'RECEIVE_SEARCH_COURSE';
export const REQUEST_SEARCH_COURSE_ERROR = 'REQUEST_SEARCH_COURSE_ERROR';
export const REMOVE_SEARCH_COURSE = 'REMOVE_SEARCH_COURSE';
export const REQUEST_PATH_DETAILS = 'REQUEST_PATH_DETAILS';
export const RECEIVE_PATH_DETAILS = 'RECEIVE_PATH_DETAILS';
export const REQUEST_PATH_DETAILS_ERROR = 'REQUEST_PATH_DETAILS_ERROR';
export const REMOVE_PATH_DETAILS = 'REMOVE_FILTER_PATHS';
export const REQUEST_FILTER_PATHS = 'REQUEST_FILTER_PATHS';
export const RECEIVE_FILTER_PATHS = 'RECEIVE_FILTER_PATHS';
export const REQUEST_FILTER_PATHS_ERROR = 'REQUEST_FILTER_PATHS_ERROR';
export const REMOVE_FILTER_PATHS = 'REMOVE_FILTER_PATHS';
export const COUNT_PATHS = 'COUNT_PATHS';
export const PATHWAYS_MONTHS_FILTER = 'PATHWAYS_MONTHS_FILTER';
export const REQUEST_ONBOARDING_PATHS = 'REQUEST_ONBOARDING_PATHS';
export const RECEIVE_ONBOARDING_PATHS = 'RECEIVE_ONBOARDING_PATHS';
export const REQUEST_ONBOARDING_PATHS_ERROR = 'REQUEST_ONBOARDING_PATHS_ERROR';
export const REQUEST_ONBOARDING_PATHS_TYPES = 'REQUEST_ONBOARDING_PATHS_TYPES';
export const RECEIVE_ONBOARDING_PATHS_TYPES = 'RECEIVE_ONBOARDING_PATHS_TYPES';
export const REQUEST_ONBOARDING_PATHS_TYPES_ERROR =
  'REQUEST_ONBOARDING_PATHS_TYPES_ERROR';
export const REQUEST_SAVE_ONBOARDING_PATHS_TYPES =
  'REQUEST_SAVE_ONBOARDING_PATHS_TYPES';
export const REQUEST_SAVE_ONBOARDING_PATHS_TYPES_ERROR =
  'REQUEST_SAVE_ONBOARDING_PATHS_TYPES_ERROR';

export const REQUEST_ONSAVE_ONBOARDING_PATHS =
  'REQUEST_ONSAVE_ONBOARDING_PATHS';
export const REQUEST_SAVE_ONBOARDING_PATHS = 'REQUEST_SAVE_ONBOARDING_PATHS';
export const REQUEST_SAVE_ONBOARDING_PATHS_ERROR =
  'REQUEST_SAVE_ONBOARDING_PATHS_ERROR';

export const REQUEST_GENED_PATHS = 'REQUEST_GENED_PATHS';
export const RECIEVE_GENED_PATHS = 'RECIEVE_GENED_PATHS';
export const GENNED_PATHS_ERROR = 'GENNED_PATHS_ERROR';

export const REQUEST_ONBOARD_PATHS = 'REQUEST_ONBOARD_PATHS';
export const ONBOARD_PATHS_ERROR = 'ONBOARD_PATHS_ERROR';
export const RECIEVE_ONBOARD_PATHS = 'RECIEVE_ONBOARD_PATHS';

// Overview AI Generator
export const REQUEST_AI_GENERATED_OVERVIEW_CONTENT =
  'REQUEST_AI_GENERATED_OVERVIEW_CONTENT';
export const RECIEVE_AI_GENERATED_OVERVIEW_CONTENT =
  'RECIEVE_AI_GENERATED_OVERVIEW_CONTENT';
export const AI_GENERATED_OVERVIEW_CONTENT_ERROR =
  'AI_GENERATED_OVERVIEW_CONTENT_ERROR';
