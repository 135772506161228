import * as React from 'react';
import {Box} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {VariableSizeList} from 'react-window';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import useMediaQuery from '@mui/material/useMediaQuery';

const LISTBOX_PADDING = 8; // px
const OuterElementContext = React.createContext({});

function renderRow(props) {
  const {data, index, style} = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
    lineHeight: 1.1,
  };
  return (
    <Typography
      component="li"
      {...dataSet[0]}
      variant="body1"
      style={inlineStyle}>
      {dataSet && dataSet[1] && dataSet[1]['label'] ? dataSet[1]['label'] : ''}
    </Typography>
  );
}

function renderRowWithIconAndCheckMark(props) {
  const {data, index, style} = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
    lineHeight: 1.1,
  };
  const Icon = dataSet[1]['icon'];
  return (
    <Typography
      component="li"
      {...dataSet[0]}
      variant="body1"
      style={inlineStyle}>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex">
          {dataSet && dataSet[1] && dataSet[1]['icon'] && <Icon />}
          {dataSet && dataSet[1] && dataSet[1]['label'] && (
            <Box display="flex" sx={{ml: 1}}>
              <Typography variant="body1">
                {dataSet[1]['title']
                  ? `${dataSet[1]['title']}`
                  : dataSet[1]['label']}
              </Typography>
              {dataSet[1]['secondaryText'] &&
                Array.isArray(dataSet[1]['secondaryText']) && (
                  <>
                    <Typography variant="body1" mx={0.5}>
                      {dataSet[1]['secondaryText'][0]
                        ? `- ${dataSet[1]['secondaryText'][0]} -`
                        : ''}
                    </Typography>
                    <Typography color="primary" variant="body1">
                      {dataSet[1]['secondaryText'][1]
                        ? ` ${dataSet[1]['secondaryText'][1]}`
                        : ''}
                    </Typography>
                  </>
                )}
            </Box>
          )}
        </Box>
        {dataSet && dataSet[0] && dataSet[0]['aria-selected'] && (
          <CheckIcon fontSize="small" color="primary" />
        )}
      </Box>
    </Typography>
  );
}

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(
    () => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    },
    [data]
  );
  return ref;
}

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const {children, ...other} = props;
  const itemData = [];
  children.forEach(item => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = child => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          width="100%"
          ref={gridRef}
          overscanCount={5}
          itemData={itemData}
          innerElementType="ul"
          itemCount={itemCount}
          outerElementType={OuterElementType}
          height={getHeight() + 2 * LISTBOX_PADDING}
          itemSize={index => getChildSize(itemData[index])}>
          {props.id ===
          'Autocomplete-with-multiple-limit-tags-with-icon-with-check-mark-listbox'
            ? renderRowWithIconAndCheckMark
            : renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export default ListboxComponent;
