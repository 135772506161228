import * as TYPES from './constants';
import {Services} from 'core';
import {API} from 'config/employer.config';
import {JWT_HEADER, JSON_HEADER} from 'config/srm.config';
import {
  // SavedProfileList
  requestSavedProfiles,
  receiveSavedProfiles,
  handleSavedProfilesError,
} from './actions';

const initialState = {
  data: null,
  error: false,
  request: false,
};

const savedProfiles = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SAVED_PROFILES_LIST_REQUEST:
      return {
        ...state,
        request: true,
      };

    case TYPES.SAVED_PROFILES_LIST_RECEIVE:
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload,
      };

    case TYPES.SAVED_PROFILES_LIST_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };

    case TYPES.SAVED_PROFILES_LIST_CLEAR:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export const fetchSavedProfiles = params => (dispatch, getState) => {
  const {
    auth: {
      access = {},
      access: {token = null},
    },
    instance: {
      data: {institution_id},
    },
  } = getState();
  const cognitoID = access['cognito:username'];

  const config = {
    mode: 'cors',
    method: 'GET',
    cache: 'default',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
  };
  dispatch(requestSavedProfiles());
  const {page = 0, sort = 'date_desc', pageSize = 10} = params || {};
  const URI = `${
    API.administration.saved_profiles
  }?page=${page}&page_size=${pageSize}&sort=${sort}&srm_user_uuid=${cognitoID}&institution_uuid=${institution_id}`;
  try {
    return fetch(URI, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveSavedProfiles(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleSavedProfilesError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleSavedProfilesError(err));
  }
};

export const saveStudentProfile = (payload, onSuccess, onError) => async (
  dispatch,
  getState
) => {
  try {
    const {
      instance: {
        data: {institution_id},
      },
      auth: {
        access = {},
        access: {token = null},
      },
    } = getState();
    const services = new Services();
    const cognitoID = access['cognito:username'];
    const endPoint = API.administration.saved_profiles;
    const body = {
      action: payload?.action,
      srm_user_uuid: cognitoID,
      institution_uuid: institution_id,
      student_uuid: payload?.row?.student_uuid,
    };
    const result = await services.createUpdateRecord(
      token,
      endPoint,
      body,
      'POST'
    );
    onSuccess && onSuccess(result);
  } catch (err) {
    console.log(err);
    onError && onError();
  }
};

export default savedProfiles;
