import React, {useEffect, useState} from 'react';
import {VisibilityOff, Visibility} from '@mui/icons-material';
import {
  Stack,
  TextField,
  IconButton,
  Typography,
  FormControl,
  FormHelperText,
  InputAdornment,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';

const MUITextField = props => {
  const theme = useTheme();
  const {label, InputProps, muiInputProps = {}, formControlProps = {}} = props;
  const [innerValue, setInnerValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const {
    error,
    fullWidth = true,
    showRequired = false,
    disableHeight = false,
    ...restFormControlProps
  } = formControlProps || {};
  const {
    onChange,
    value = '',
    type = 'text',
    showCharLength = false, // eg: 8/10,
    size = 'medium', // 'small' | 'medium'
    inputProps, // eg: inputProps: {maxLength: 10},
    ...restMuiInputProps
  } = muiInputProps || {};

  useEffect(
    () => {
      if (value) {
        setInnerValue(value);
      } else {
        setInnerValue('');
      }
    },
    [value]
  );

  const handleOnChange = event => {
    event.persist();
    const newValue = event.currentTarget.value;
    setInnerValue(newValue);
    onChange(event);
  };

  // Show Password logic
  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const passwordAdornment = () => (
    <InputAdornment position="end">
      <IconButton
        edge="end"
        aria-label="toggle password visibility"
        onClick={handleTogglePasswordVisibility}>
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <FormControl
      fullWidth={fullWidth}
      error={error ? true : false}
      sx={{height: disableHeight ? 'auto' : 55}}
      {...restFormControlProps}>
      <TextField
        {...restMuiInputProps}
        hiddenLabel
        size={size}
        value={innerValue}
        inputProps={inputProps}
        onChange={handleOnChange}
        error={error ? true : false}
        id={`${label}-outlined-input`}
        InputProps={
          type === 'password'
            ? {
                endAdornment: passwordAdornment(),
              }
            : InputProps
        }
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        label={
          label ? (
            <label>
              {showRequired && (
                <Typography
                  component="span"
                  sx={{color: theme.palette.error.main}}>
                  *&nbsp;
                </Typography>
              )}
              {label}
            </label>
          ) : null
        }
      />
      <Stack
        width="100%"
        direction="row"
        alignItems="baseline"
        justifyContent="space-between">
        <FormHelperText
          sx={{
            mt: 0.3,
            width: '80%',
            fontSize: 12,
            lineHeight: 1.1,
          }}>
          {error}
        </FormHelperText>
        {showCharLength && inputProps?.maxLength && (
          <Typography variant="body2" textAlign="right" color="text.secondary">
            {innerValue?.length || 0}/{inputProps?.maxLength}
          </Typography>
        )}
      </Stack>
    </FormControl>
  );
};

MUITextField.propTypes = {
  label: PropTypes.string,
  InputProps: PropTypes.object,
  muiInputProps: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
    inputProps: PropTypes.object,
  }),
  formControlProps: PropTypes.shape({
    error: PropTypes.string,
    fullWidth: PropTypes.bool,
    showRequired: PropTypes.bool,
    showCharLength: PropTypes.bool,
  }),
};

export default MUITextField;
