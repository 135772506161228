import React, {Component} from 'react';
import {Collapse, Select, Switch} from 'antd';

import {monthFormat} from 'core';
import {programTypes} from 'config/businesspartner.config';

const Panel = Collapse.Panel;
const Option = Select.Option;

class CustomCollapseSidebar extends Component {
  state = {};
  // componentDidMount = () => {
  //   const {selectedProgram} = this.props;
  //   if (selectedProgram) {
  //     this.setState({
  //       selectedUUID: selectedProgram.business_partner_program_uuid,
  //     });
  //   }
  // };

  // componentDidUpdate = (prevProps, prevState) => {
  //   const {selectedProgram} = this.props;
  //   if (selectedProgram) {
  //     if (
  //       prevState.selectedUUID !== selectedProgram.business_partner_program_uuid
  //     ) {
  //       this.setState({
  //         selectedUUID: selectedProgram.business_partner_program_uuid,
  //       });
  //     }
  //   }
  // };

  program = (matchingKey, matchingData, returningKey, bindingName) => {
    const {selectedUUID} = this.state;
    const {data, getSelectedItem, onStatusChange} = this.props;
    let chunkData =
      data && data.filter(item => item[matchingKey] === matchingData);
    return (
      chunkData &&
      chunkData.map(chunk => (
        <div
          key={chunk[returningKey]}
          className={`clearfix sidebar-tab ${
            selectedUUID === chunk[returningKey] ? 'active' : ''
          }`}
          onClick={() => getSelectedItem(chunk[returningKey])}>
          <div className="ant-col-6 text-center margin-r10">
            <span className="sidebar-count">02</span>
            <span className="event-users">MEMBERS</span>
          </div>
          <div className="clearfix">
            <p className="sidebar-title" title="Check New Event">
              {chunk[bindingName]}
            </p>
            <p className="sidebar-date margin-b5">
              created on: {monthFormat(chunk.created_on)}
            </p>
            <span className="sidebar-date margin-r10">Publish</span>
            <Switch
              defaultChecked={chunk.is_published}
              onChange={checked => onStatusChange(checked, chunk[returningKey])}
            />
          </div>
        </div>
      ))
    );
  };

  panel = () => {
    const {panelHeader} = this.props;
    return (
      panelHeader &&
      panelHeader.map(
        ({title, matchingKey, matchingData, returningKey, bindingName}) => {
          return (
            <Panel
              key={title}
              header={
                <div className="sidebar-collapse-title">
                  <span className="icon icon-id-card" />
                  <span className="sidebar-collapse-title-text">{title}</span>
                </div>
              }>
              {this.program(
                matchingKey,
                matchingData,
                returningKey,
                bindingName
              )}
            </Panel>
          );
        }
      )
    );
  };

  render() {
    // 1st prop
    const panelHeader = [
      {
        title: 'text',
        matchingKey: 'name/uuid/id',
        matchingData: 'internship',
        returningKey: 'uuid/business_partner_id',
        bindingName: 'name',
      },
    ];
    // 2nd prop all data
    return (
      <Collapse accordion bordered={false} className="data-table-scroll-400">
        {this.panel()}
      </Collapse>
    );
  }
}

export default CustomCollapseSidebar;
