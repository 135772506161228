import React from 'react';
import {Grid} from 'mui-core';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {numberInUSFormat} from 'mui-core/utils';
import {Box, Stack, Typography} from '@mui/material';

const Item = styled(Stack)(({theme, color}) => ({
  border: '0px',
  textAlign: 'left',
  borderLeft: '3px',
  boxShadow: 'none',
  // margin: '6px 0px',
  borderColor: color,
  borderRadius: '0px',
  borderStyle: 'solid',
  backgroundColor: 'transparent',
  paddingLeft: theme.spacing(1.8),
}));

const Stats = props => {
  const {data = [], largeLabel = false} = props;
  const formatCount = count => {
    try {
      return numberInUSFormat(count);
    } catch (err) {
      return 0;
    }
  };
  const columns = largeLabel ? {xs: 4, sm: 8, md: 7} : {xs: 4, sm: 8, md: 12};
  return (
    <Box sx={{flexGrow: 1, margin: '24px 0px'}}>
      <Grid container spacing={{xs: 2, md: 4}} columns={columns}>
        {data &&
          data.map(({title = '', count = '0', color}, index) => (
            <Grid xs={2} sm={2} md={1.1} key={index}>
              <Item color={color}>
                <Typography
                  lineHeight={1}
                  variant="metricNo"
                  color="text.primary">
                  {formatCount(count)}
                </Typography>
                <Typography variant="caption" color="text.primary">
                  {title}
                </Typography>
              </Item>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

Stats.propTypes = {
  data: PropTypes.array,
};

export default React.memo(Stats);
