import React from 'react';
import {Avatar, Switch} from 'antd';
import {EllipseMenu, getCloudinaryImage} from 'core';
import {Insticon} from 'sharedResources/assets/images';

import './style.css';

const InstitutionCard = ({
  data = {},
  onView,
  statusHide,
  readOnly,
  className,
  onSwitchStatus,
  selectedCardId,
  onCardClick,
  doubleLineTxt,
}) => {
  const {
    name,
    logo,
    logo_cloudinary,
    status,
    institution_id,
    institution_type_name,
  } = data || {};
  const {name: instituteType} = institution_type_name || {};
  const menuData = [
    {
      func: onView,
      menuItem: 'View Details',
      key: 'view details',
      passingData: data,
    },
  ];

  const statusTxt = status ? 'Active' : 'Inactive';
  let displayText = name;
  if (doubleLineTxt && displayText.length > 25) {
    displayText = `${displayText.substring(0, 25)}...`;
  }

  return (
    <>
      <div
        className={`${className} institution-card ${
          selectedCardId === institution_id ? 'active' : 'inactive'
        }`}
        onClick={() => {
          onCardClick && onCardClick(institution_id);
        }}
        style={{cursor: `${onCardClick ? 'pointer' : 'initial'}`}}>
        <div className="data-card">
          <div className="card-details">
            <div type="flex" className="institution-card-content">
              <Avatar
                src={
                  getCloudinaryImage(logo_cloudinary, 'logo') ||
                  logo ||
                  Insticon
                }
                size="1"
                className={`${
                  logo_cloudinary || logo ? 'active' : ''
                } avt-size`}
              />
              <h4
                className={`${
                  doubleLineTxt ? 'display-double-line' : ''
                } card-title margin-b10`}
                title={name}>
                {displayText}
              </h4>
              {!className && (
                <h5 className="card-sub-title text-lite-bold margin-b10">
                  {instituteType}
                </h5>
              )}
              {!readOnly && <EllipseMenu menuData={menuData} />}
            </div>
          </div>
          {!statusHide && (
            <div className="status">
              <span className="status-header">Status</span>
              <span className="switcher">
                {!readOnly && (
                  <Switch
                    checked={status}
                    onChange={value => onSwitchStatus(value, institution_id)}
                  />
                )}
                <span
                  className={`${readOnly ? 'readonly' : ''} ${
                    status ? 'active' : ''
                  } txt`}>
                  {statusTxt}
                </span>
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InstitutionCard;
