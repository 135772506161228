import React from 'react';
import {Button} from 'antd';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import './Banner.css';

const Banner = ({mainLabel, render, history, noAction}) => (
  <div className="clearfix cards-container">
    <h2 className="title">
      {mainLabel}
      {!noAction && (
        <Button
          onClick={() => history.push('/addevent')}
          className="ant-btn btn-outline-white border-rad-100">
          Add Event
        </Button>
      )}
    </h2>
    {render()}
  </div>
);

Banner.propTypes = {
  render: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(Banner);
