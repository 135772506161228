import React from 'react';
import {Form, Row, Col, Divider, Select, Icon, Tag} from 'antd';
const {Option} = Select;
const FormItem = Form.Item;

const defaultContentPadding = 42;
class SchoolSelect extends React.Component {
  state = {
    filteredSchools: [],
    selectedSchools: [],
  };
  componentDidMount() {
    if (this.props.initialValue) {
      this.setState({
        selectedSchools: this.props.initialValue,
      });
    }
    if (this.props.institutionList) {
      this.onSearch('');
    }
  }
  componentDidUpdate(preProps) {
    if (this.props.institutionList !== preProps.institutionList) {
      this.onSearch('');
    }
  }
  onSearch = keyword => {
    const {institutionList} = this.props;
    const filteredSchools = institutionList.filter(institute =>
      institute.name.toLowerCase().includes(keyword.toLowerCase())
    );
    this.setState({filteredSchools});
  };
  onSchoolRemove = uuid => {
    this.setState(
      {
        selectedSchools: this.state.selectedSchools.filter(
          st => st.institution_id !== uuid
        ),
      },
      () => {
        this.onChange();
        this.onSearch('');
      }
    );
  };
  onSchoolSelect = schoolId => {
    const {
      institutionList,
      form: {resetFields},
    } = this.props;
    const school = institutionList.filter(
      st => st.institution_id === schoolId
    )[0];
    this.setState(
      {
        selectedSchools: [...this.state.selectedSchools, school],
      },
      () => {
        this.onChange();
        resetFields();
        this.onSearch('');
      }
    );
  };

  isSchoolSelected = id => {
    const {selectedSchools} = this.state;
    const school = selectedSchools.filter(
      school => school.institution_id === id
    );
    if (school.length > 0) return true;
    return false;
  };

  onChange = () => {
    const {onChange} = this.props;
    if (onChange) onChange(this.state.selectedSchools);
  };
  render() {
    const {filteredSchools, selectedSchools} = this.state;
    const {
      contentPadding,
      institutionList,
      form: {getFieldDecorator},
    } = this.props;
    return (
      <Form layout="inline">
        <div
          style={{
            padding: contentPadding || defaultContentPadding,
          }}
          className="school-selector filter-section">
          <div
            style={{
              marginLeft: contentPadding
                ? -contentPadding
                : -defaultContentPadding,
              marginRight: contentPadding
                ? -contentPadding
                : -defaultContentPadding,
            }}
            className="heading-text text-center">
            Filter by School
          </div>
          <Row
            type={'flex'}
            gutter={42}
            align={'middle'}
            className="filter-form">
            <Col>
              <FormItem label="Select School">
                {getFieldDecorator('cluster', {
                  // initialValue: SelectedCluster && SelectedCluster.uuid,
                })(
                  <Select
                    showSearch
                    onSelect={this.onSchoolSelect}
                    optionFilterProp={'children'}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{minWidth: 200}}
                    placeholder={'Select Schools'}>
                    {institutionList &&
                      institutionList.map(({name, institution_id}) => (
                        <Option
                          key={institution_id + 'sc-select'}
                          disabled={this.isSchoolSelected(institution_id)}
                          value={institution_id}
                          title={name}>
                          {name}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            {/* <Col sm={2}>
                        <Divider className="divider-circle-text vertical-line">
                            OR
                        </Divider>
                        </Col>
                        <Col sm={11}>
                            <FormItem label="Search for a School" className="school-select">
                                <>
                                    <Icon
                                    type="search"
                                    style={{
                                        position: 'absolute',
                                        zIndex: '1',
                                        top: '4px',
                                        left: '5px',
                                    }}
                                    />
                                        <Select
                                            showSearch
                                            showArrow={false}
                                            filterOption={false}
                                            onDeselect={this.onSchoolRemove}
                                            mode={'multiple'}
                                            defaultActiveFirstOption={false}
                                            allowClear
                                            onSelect={this.onSchoolSelect}
                                            value={selectedSchools.map(school=>school.institution_id)}
                                            // disabled={!allPrograms}
                                            onSearch={this.onSearch}
                                            placeholder={'Search'}
                                            notFoundContent={'no programs matchs for search query'}
                                            style={{width: '275px', paddingLeft: '11px'}}>
                                            {filteredSchools &&
                                                filteredSchools.length > 0 &&
                                                filteredSchools.map(({name, institution_id}) => (
                                                <Option 
                                                    key={institution_id + 'search'} 
                                                    value={institution_id} 
                                                    title={name}>
                                                        {name}
                                                </Option>
                                                ))}
                                        </Select>
                                </>
                            </FormItem>
                        </Col> */}
          </Row>
          <div
            style={{
              marginLeft: contentPadding
                ? -contentPadding
                : -defaultContentPadding,
              marginRight: contentPadding
                ? -contentPadding
                : -defaultContentPadding,
              paddingLeft: contentPadding
                ? contentPadding
                : defaultContentPadding,
              paddingRight: contentPadding
                ? contentPadding
                : defaultContentPadding,
            }}
            className="selected-section">
            <div className="lbl-s">Selected School(s):</div>
            <div className="tags-s">
              {selectedSchools &&
                selectedSchools.map(school => (
                  <Tag
                    key={'school-tag' + school.institution_id}
                    onClose={() => this.onSchoolRemove(school.institution_id)}
                    closable
                    className="pgm-tag">
                    {school.name}
                  </Tag>
                ))}
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

export default Form.create()(SchoolSelect);
