import * as TYPES from './constants';
import {
  receiveInstitutionPermissionList,
  requestInstitutionPermissionList,
  handleInstitutionPermissionError,
} from './actions';

import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {
  intitutionPermissions: null,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_INSTITUTION_PERMISSION_LIST:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_INSTITUTION_PERMISSION_LIST:
      return {
        ...state,
        intitutionPermissions: action.payload,
        request: false,
        error: false,
      };
    case TYPES.INSTITUTION_PERMISSION_LIST_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchInstitutionPermissionList = () => (dispatch, getState) => {
  const state = getState();
  let accessToken = null;
  if (state && state.auth && state.auth.access) {
    const {
      auth: {
        access: {token},
      },
    } = state;
    accessToken = token;
  }

  if (accessToken) {
    const config = {
      method: 'GET',
      mode: 'cors',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
      cache: 'default',
    };
    dispatch(requestInstitutionPermissionList());
    try {
      return fetch(`${API.srm.institute_type_permission}`, config)
        .then(response => response.json())
        .then(json => {
          dispatch(receiveInstitutionPermissionList(json));
        })
        .catch(err => {
          console.log(err);
          dispatch(handleInstitutionPermissionError(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(handleInstitutionPermissionError(err));
    }
  }
};
