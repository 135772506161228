import React from 'react';
import {Avatar, Button, Dropdown, Tooltip} from 'antd';
import {getCloudinaryImage} from 'core';
import './PathCard.css';
import {
  defaultPath,
  skillCertify,
  occupationDefaultImg,
} from 'sharedResources/assets/images';

const GpsPathCard = ({
  data,
  disLogo = true,
  displayThumbNail,
  config,
  customClass = '',
}) => {
  const {
    banner_cloudinary,
    award_type_name,
    title,
    institute_details: {logo_cloudinary, name} = {},
    occupation_details: {occupation_name} = {},
    uuid,
    tagType,
  } = data || {};

  const {
    buttonClass,
    buttonText,
    buttonAction,
    buttonDisabled,
    type,
    buttonActionData,
  } = config || {};
  return (
    <div className={`pathcard ${customClass}`}>
      <div className="pathcard_sec">
        {tagType && (
          <span className={`tag ${tagType.toLowerCase()}`}>{tagType}</span>
        )}
        <img
          src={
            banner_cloudinary
              ? getCloudinaryImage(banner_cloudinary)
              : tagType
              ? occupationDefaultImg
              : defaultPath
          }
          alt="search banner"
          className="lazyload blur-up img-fluid"
        />
        {disLogo && (
          <div className="avatar">
            <Avatar
              size={50}
              src={getCloudinaryImage(logo_cloudinary, 'logo')}
              // icon={<UserOutlined />}
            />
          </div>
        )}
        <div className="px-3">
          <h4 title={title || occupation_name}>{title || occupation_name}</h4>
          {!displayThumbNail && (
            <>
              {name && (
                <p title={name} className="companyName">
                  {config && config.nameLabel && config.nameLabel}
                  <span>{name}</span>
                </p>
              )}
              {award_type_name && (
                <h5>
                  <img
                    src={skillCertify}
                    alt="skill Certificate"
                    className="img-fluid pr-1"
                  />
                  {award_type_name}
                </h5>
              )}
            </>
          )}
        </div>
        {config && buttonText && (
          <Button
            disabled={buttonDisabled}
            className={buttonClass}
            onClick={
              buttonActionData ? () => buttonAction(data) : buttonAction
            }>
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default GpsPathCard;
