import CollegeInfo from './components/collegeInfo';
import MarketPlace from './components/marketPlace';
import StudentSuccess from './components/studentSuccess';
import Pathways from './components/pathways';
import Institutions from './components/institutions';
import WorkForce from './components/workForce';
import Filters from './components/filters';
import './components/style.css';

export const Dashboard = {
  CollegeInfo,
  MarketPlace,
  StudentSuccess,
  Pathways,
  Institutions,
  WorkForce,
  Filters,
};
