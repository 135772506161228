import * as TYPES from 'redux/types';

import {API} from 'config/srm.config';

const initialState = {};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_DEPARTMENT:
      return {
        ...state,
        ...action.payload,
      };
    case TYPES.RECEIVE_DEPARTMENT:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.REQUEST_DEPARTMENT_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

const receiveDepartment = json => ({
  type: TYPES.RECEIVE_DEPARTMENT,
  payload: json,
});
const requestDepartmentError = err => ({
  type: TYPES.REQUEST_DEPARTMENT_ERROR,
  err,
});
const requestDepartment = json => ({
  type: TYPES.REQUEST_DEPARTMENT,
  payload: json,
});

export const fetchDepartment = () => ({
  type: TYPES.APIREQUEST,
  payload: {
    endpoint: `${API.institution.department}`,
    method: 'GET',
    server: 'institution',
    onsuccess: receiveDepartment,
    onerror: requestDepartmentError,
    onRequestStart: requestDepartment,
  },
});

export const departmentSelector = ({department: {data}}) => data;
export const departmentRequest = ({department: {request}}) => request;
