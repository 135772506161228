import * as TYPES from 'redux/types';

// Institution Profile
export const requestInstitutionProfile = () => {
  return {
    type: TYPES.REQUEST_INSTITUTION_USER,
  };
};
export const receiveInstitutionProfile = json => {
  return {
    type: TYPES.RECEIVE_INSTITUTION_USER,
    payload: json,
  };
};

export const handleReceiveProfileError = err => ({
  type: TYPES.REQUEST_INSTITUTION_USER_ERROR,
  err,
});

export const setToggleInstitutionProfile = data => ({
  type: TYPES.TOGGLE_INSTITUTION_PROFILE,
  payload: data,
});
