import React, {useState, useEffect, useCallback} from 'react';
import {Form, Select, InputNumber, Row, Col, Tag} from 'antd';
import debounce from 'lodash/debounce';
import {
  capitalizeFirstLetter,
  FieldWithCount,
  StepAction,
  Address,
  StartEndDate,
  isArray,
  PATTERN,
  isObject,
  isEmpty,
  Services,
} from 'core';
import {GPS_DOMAIN, API} from 'config/srm.config';
const FormItem = Form.Item;
const Option = Select.Option;
const services = new Services();

const debouncedInputValidator = async (bodyData, callback, errorMsg, token) => {
  if (bodyData) {
    const res = await services.checkFieldInputAvailability(
      token,
      `${API.srm.subdomain_license}`,
      bodyData
    );
    const response = await (res && res.json());
    if (res.status === 200) {
      callback(response.exists ? errorMsg : undefined);
    } else {
      callback();
    }
  } else {
    callback();
  }
};

const LicenseParameters = props => {
  const {
    data,
    mode,
    currentStep,
    totalSteps,
    isSaving = false,
    onSwitchMode,
    onSubmit,
    form,
    form: {getFieldDecorator},
    statesData,
    consortiumStates,
    instituteTypesData,
    userPortal,
    token,
    institutionData,
    fetchMSALocations,
    msaLocations: {data: msaLocationsData, request: msaLocationsRequest} = {},
  } = props;

  const [UUIDs, setUUIDs] = useState(null);
  const [licenseType, setLicenseType] = useState(null);
  const [msaSearchValue, setMSASearchValue] = useState('');
  const [consortiumList, setConsortiumList] = useState(null);
  const [institutionTypes, setInstitutionTypes] = useState(null);
  const displayForm = mode === 'create' || mode === 'edit' ? true : false;
  const {location_details = []} = msaLocationsData || {};

  const getUserPortal = userPortal && userPortal.toLowerCase();
  const title = getUserPortal === 'consortium' ? 'Institution' : 'License';
  let SUPERTYPE_TEXT_VALUE = '';
  let SUBTYPE_TEXT_VALUE = '';

  /**=== This will run at time of Edit ====*/
  if (
    data &&
    data.institution_super_type_name &&
    data.institution_type_name &&
    instituteTypesData &&
    isArray(instituteTypesData.data)
  ) {
    instituteTypesData.data.forEach(institute => {
      if (institute.uuid === data.institution_super_type_name.uuid) {
        SUPERTYPE_TEXT_VALUE = institute.type;
        institute &&
          institute.sub_types &&
          institute.sub_types.forEach(instituteSubType => {
            if (
              instituteSubType.institution_type_id ===
              data.institution_type_name.institution_type_id
            ) {
              SUBTYPE_TEXT_VALUE = instituteSubType.name;
            }
          });
      }
    });
  }

  if (
    data &&
    isArray(data.consortium_list) &&
    data.consortium_list.length > 0
  ) {
    let selectedConsortiums = data.consortium_list.map(({name}) => name);
    SUBTYPE_TEXT_VALUE = selectedConsortiums.join(', ');
  }

  const onLicenseTypeChangeHandler = value => {
    const getlicenceType =
      instituteTypesData.data &&
      instituteTypesData.data.filter(({uuid}) => uuid === value);
    if (getlicenceType && getlicenceType[0]) {
      setLicenseType(getlicenceType[0].type);
      setInstitutionTypes(getlicenceType[0].sub_types);
    }
  };

  useEffect(() => {
    const getlicenceType =
      instituteTypesData.data &&
      instituteTypesData.data.filter(({type}) => type === 'Consortium');
    if (
      getlicenceType &&
      getlicenceType[0] &&
      isArray(getlicenceType[0].consortiumList) &&
      getlicenceType[0].consortiumList.length
    ) {
      setConsortiumList(getlicenceType[0].consortiumList);
    }
  }, []);

  useEffect(
    () => {
      if (data && data.institution_super_type_name) {
        instituteTypesData?.data?.forEach(instiType => {
          if (instiType.uuid === data.institution_super_type_name.uuid) {
            setLicenseType(instiType.type);
            setInstitutionTypes(instiType.sub_types);
          }
        });
      }
    },
    [data]
  );

  useEffect(
    () => {
      const uuids = {};
      instituteTypesData.data &&
        instituteTypesData.data.forEach(institute => {
          uuids[institute.type] = institute.uuid;
        });
      !UUIDs && setUUIDs(uuids);
    },
    [instituteTypesData]
  );

  const debouncedSubdomainValidator = useCallback(
    debounce(debouncedInputValidator, 500),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const debouncedNameValidator = useCallback(
    debounce(debouncedInputValidator, 300),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const institutionTypesData =
    instituteTypesData.data &&
    instituteTypesData.data.filter(({type}) => type === 'Institution');

  const showSubDomain = licenseType;

  const showConnectedStates =
    licenseType === 'Institution' || licenseType === 'Employer';

  const selectedUUID =
    UUIDs &&
    (getUserPortal === 'consortium' ? UUIDs.Institution : UUIDs[licenseType]);

  //const isSubdomainDisabled = !!(mode !== 'create' && (data && data.subdomain));

  const ropInitialValues =
    data &&
    Array.isArray(data.consortium_list) &&
    data.consortium_list.length > 0
      ? data.consortium_list.map(res => {
          return res.institution_id;
        })
      : [];

  const {consortium_list: selectedList = []} = data || {};

  let filteredStates =
    consortiumStates &&
    consortiumStates.data &&
    consortiumStates.data.data &&
    Array.isArray(consortiumStates.data.data) &&
    consortiumStates.data.data.length > 0
      ? selectedList.map(item => {
          return consortiumStates.data.data.find(
            res =>
              res.consortium_uuid === item ||
              res.consortium_uuid === item.institution_id
          );
        })
      : [];

  let filteredRopData =
    filteredStates && Array.isArray(filteredStates) && filteredStates.length > 0
      ? filteredStates.map(res => {
          return res && res.consortium_uuid;
        })
      : [];
  // const institutionininitialvalues =
  //   data &&
  //   Array.isArray(data.institution_list) &&
  //   data.institution_list !== undefined &&
  //   data.institution_list.length > 0 &&
  //   data.institution_list.map(res => {
  //     return res.institution_id;
  //   });

  const selectedInstitutionValue =
    data &&
    data.institution_list &&
    Array.isArray(data.institution_list) &&
    data.institution_list.length > 0
      ? data.institution_list.map(res => {
          return res.institution_id;
        })
      : [];
  const {institution_list: selectedInstitutionList = []} = data || {};

  let filteredinstitution =
    institutionData &&
    institutionData.data &&
    Array.isArray(institutionData.data) &&
    institutionData.data.length > 0
      ? selectedInstitutionList.map(item => {
          return institutionData.data.find(
            state =>
              state.institution_id === item ||
              state.institution_id === item.institution_id
          );
        })
      : [];
  let instituteFilteredData =
    filteredinstitution &&
    Array.isArray(filteredinstitution) &&
    filteredinstitution.length > 0
      ? filteredinstitution.map(res => {
          return res && res.institution_id;
        })
      : [];

  const handleMSASearch = debounce(value => {
    fetchMSALocations(value);
    setMSASearchValue(value);
  }, 500);

  let MSAOptions = location_details;

  if (msaSearchValue) {
    MSAOptions = location_details.filter(
      ({city, state_code}) =>
        city &&
        state_code &&
        `${city}, ${state_code}`
          .toLowerCase()
          .indexOf(msaSearchValue.toLowerCase()) >= 0
    );
  }

  return (
    <Form className="table-form" onSubmit={event => onSubmit(event, form)}>
      <div className={`content-pad superuser license ${mode}`}>
        <h1 className="form-head">Basic Information</h1>
        <Row className="table-form-1-col" style={{padding: 20}}>
          <Col>
            <Row>
              <Col span={12}>
                <FormItem label={`${title} Name`}>
                  {displayForm ? (
                    getFieldDecorator('name', {
                      rules: [
                        {
                          required: true,
                          message: `${title} name field cannot be empty.`,
                        },
                        {
                          pattern: PATTERN.Alpha_Numeric,
                          message: 'Only Alpha-numeric character allowed',
                        },
                        {
                          validator: (_, value, callback) => {
                            debouncedNameValidator(
                              data &&
                                data.name !== value &&
                                selectedUUID &&
                                PATTERN.Alpha_Numeric.test(value) && {
                                  license_name: value,
                                  license_type: selectedUUID,
                                },
                              callback,
                              `${title} Name '${value}' already exists`,
                              token
                            );
                          },
                        },
                      ],
                      initialValue: data && data.name,
                    })(
                      <FieldWithCount
                        fieldtype="Input"
                        showcount
                        maxLength={40}
                      />
                    )
                  ) : (
                    <span className="txt">{data && data.name}</span>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              {getUserPortal !== 'consortium' && (
                <Col span={mode === 'readOnly' ? 24 : 12}>
                  {instituteTypesData.data && (
                    <FormItem label={`${title} Type`}>
                      {displayForm ? (
                        getFieldDecorator('institution_super_type_name.uuid', {
                          rules: [
                            {
                              required: true,
                              message: `${title} Type field cannot be empty.`,
                            },
                          ],
                          initialValue:
                            data &&
                            data.institution_super_type_name &&
                            data.institution_super_type_name.uuid,
                        })(
                          <Select
                            disabled={mode === 'edit' ? true : false}
                            onChange={onLicenseTypeChangeHandler}>
                            {instituteTypesData.data.map(({type, uuid}) => (
                              <Option key={uuid} value={uuid}>
                                {type}
                              </Option>
                            ))}
                          </Select>
                        )
                      ) : (
                        <>
                          <span className="txt">{SUPERTYPE_TEXT_VALUE}</span>
                          {/* <span className="txt" style={{marginLeft: '20px'}}>
                            {SUBTYPE_TEXT_VALUE}
                          </span> */}
                        </>
                      )}
                    </FormItem>
                  )}
                </Col>
              )}

              {getUserPortal === 'consortium' && (
                <Col span={mode === 'readOnly' ? 24 : 12}>
                  {instituteTypesData.data && (
                    <FormItem label={`${title} Type`}>
                      {displayForm ? (
                        getFieldDecorator(
                          'institution_type_name.institution_type_id',
                          {
                            rules: [
                              {
                                required: true,
                                message: `${title} Type field cannot be empty.`,
                              },
                            ],
                            initialValue:
                              data &&
                              data.institution_type_name &&
                              data.institution_type_name.institution_type_id,
                          }
                        )(
                          <Select
                            disabled={
                              getUserPortal !== 'consortium' && mode === 'edit'
                                ? true
                                : false
                            }>
                            {institutionTypesData &&
                              institutionTypesData[0] &&
                              institutionTypesData[0].sub_types.map(
                                ({institution_type_id, name}) => (
                                  <Option
                                    key={institution_type_id}
                                    value={institution_type_id}>
                                    {name}
                                  </Option>
                                )
                              )}
                          </Select>
                        )
                      ) : (
                        <>
                          <span className="txt">{SUBTYPE_TEXT_VALUE}</span>
                        </>
                      )}
                    </FormItem>
                  )}
                </Col>
              )}

              {/* start only for super user */}
              <Col span={12}>
                {licenseType === 'Institution' &&
                  getUserPortal !== 'consortium' && (
                    <FormItem label="Institution Type">
                      {displayForm ? (
                        getFieldDecorator(
                          'institution_type_name.institution_type_id',
                          {
                            rules: [
                              {
                                required: true,
                                message:
                                  'Institution type field can not be empty',
                              },
                            ],
                            initialValue:
                              data &&
                              data.institution_type_name &&
                              data.institution_type_name.institution_type_id,
                          }
                        )(
                          <Select>
                            {institutionTypes &&
                              institutionTypes.map(
                                ({institution_type_id, name}) => (
                                  <Option
                                    key={institution_type_id}
                                    value={institution_type_id}>
                                    {name}
                                  </Option>
                                )
                              )}
                          </Select>
                        )
                      ) : (
                        <span className="txt">
                          {data &&
                          data.institution_type_name &&
                          data.institution_type_name.name
                            ? data.institution_type_name.name
                            : '-'}
                        </span>
                      )}
                    </FormItem>
                  )}
                {licenseType === 'Reporter' && displayForm && (
                  <FormItem label="Link Consortium">
                    {displayForm &&
                      getFieldDecorator('consortium_list', {
                        rules: [
                          {
                            required: true,
                            message: 'Consortium field can not be empty',
                          },
                        ],
                        initialValue:
                          (data &&
                            data.consortium_list &&
                            isArray(data.consortium_list) &&
                            data.consortium_list.map(cl => {
                              if (isObject(cl) && !isEmpty(cl)) {
                                return cl.institution_id;
                              } else {
                                return cl;
                              }
                            })) ||
                          undefined,
                      })(
                        <Select
                          mode="multiple"
                          placeholder="Select licenses for Reporter access">
                          {consortiumList &&
                            isArray(consortiumList) &&
                            consortiumList.map(({institution_id, name}) => (
                              <Option
                                key={institution_id}
                                value={institution_id}>
                                {capitalizeFirstLetter(name)}
                              </Option>
                            ))}
                        </Select>
                      )}
                  </FormItem>
                )}
              </Col>
              {/* end only for super user */}
            </Row>
            <Row>
              {showSubDomain && (
                <Col
                  span={
                    mode === 'readOnly'
                      ? 8
                      : licenseType === 'Institution'
                      ? 12
                      : 10
                  }>
                  <FormItem label={`Subdomain`} className="subdomain-form-item">
                    {displayForm ? (
                      <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>
                          {getFieldDecorator('subdomain', {
                            // hidden: isSubdomainDisabled,
                            rules: [
                              {
                                required: true,
                                message: `${title} subdomain cannot be empty.`,
                              },
                              {
                                pattern: PATTERN.Alpha_Numeric_Without_Space,
                                message:
                                  'Only lower-case Alpha-numeric character allowed',
                              },
                              {
                                validator: (_, value, callback) => {
                                  if (
                                    value &&
                                    data &&
                                    data.subdomain &&
                                    data.subdomain === value
                                  ) {
                                    callback();
                                    return;
                                  }
                                  debouncedSubdomainValidator(
                                    data &&
                                      data.subdomain !== value &&
                                      PATTERN.Alpha_Numeric_Without_Space.test(
                                        value
                                      ) && {
                                        subdomain: value,
                                      },
                                    callback,
                                    `Subdomain '${value}' already exists`,
                                    token
                                  );
                                },
                              },
                            ],
                            initialValue:
                              data &&
                              data.subdomain &&
                              data.subdomain.toLowerCase(),
                          })(
                            <FieldWithCount
                              fieldtype="Input"
                              showcount
                              maxLength={20}
                              className="subdomain-input"
                              // disabled={isSubdomainDisabled}
                            />
                          )}
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <span className="subdomain-domain">
                            <strong>&nbsp;&nbsp;&nbsp;.{GPS_DOMAIN}</strong>
                          </span>
                        </Col>
                      </Row>
                    ) : (
                      <span className="txt">
                        {data && data.subdomain
                          ? `${data.subdomain.toLowerCase()}.${GPS_DOMAIN}`
                          : '-'}
                      </span>
                    )}
                  </FormItem>
                </Col>
              )}
              {(licenseType === 'Consortium' ||
                licenseType === 'Institution') && (
                <Col span={licenseType === 'Institution' ? 12 : 8}>
                  <FormItem label="Default MSA">
                    {displayForm ? (
                      getFieldDecorator('default_msa', {
                        rules: [
                          {
                            required: true,
                            message: `Default MSA field cannot be empty.`,
                          },
                        ],
                        initialValue:
                          (data &&
                            data.default_msa &&
                            data.default_msa.city &&
                            data.default_msa.state_code &&
                            `${data.default_msa.city}, ${
                              data.default_msa.state_code
                            }`) ||
                          undefined,
                      })(
                        <Select
                          showSearch
                          filterOption={false}
                          value={msaSearchValue}
                          onSearch={handleMSASearch}
                          loading={msaLocationsRequest}
                          defaultActiveFirstOption={false}
                          onSelect={() => handleMSASearch('')}
                          placeholder="Please select a Default MSA"
                          notFoundContent="no location matches found">
                          {!msaLocationsRequest &&
                            MSAOptions.slice(0, 50).map(
                              ({uuid, city, state_code}) => (
                                <Option key={uuid} value={uuid}>
                                  {`${city}, ${state_code}`}
                                </Option>
                              )
                            )}
                        </Select>
                      )
                    ) : (
                      <span className="txt">
                        {(data &&
                          data.default_msa &&
                          data.default_msa.city &&
                          data.default_msa.state_code &&
                          `${data.default_msa.city}, ${
                            data.default_msa.state_code
                          }`) ||
                          '-'}
                      </span>
                    )}
                  </FormItem>
                </Col>
              )}
            </Row>
            <Row>
              <Col span={mode === 'readOnly' ? 8 : 10}>
                {licenseType === 'Consortium' &&
                  getUserPortal !== 'consortium' && (
                    <FormItem label="Max No. of Institutions">
                      {displayForm ? (
                        getFieldDecorator('max_no_institutions', {
                          rules: [
                            {
                              required: true,
                              message:
                                'Max No. of Institution can not be empty',
                            },
                            {
                              pattern: PATTERN.Whole_Number_UpToTenThousands,
                              message:
                                'Only Whole numbers allowed between 1 - 9999',
                            },
                          ],
                          initialValue: data && data.max_no_institutions,
                        })(
                          <InputNumber
                            min={1}
                            step={1}
                            max={9999}
                            precision={1}
                            style={{width: '120px'}}
                          />
                        )
                      ) : (
                        <span className="txt">
                          {data && data.max_no_institutions}
                        </span>
                      )}
                    </FormItem>
                  )}
              </Col>
              {licenseType === 'Consortium' && (
                <Col span={mode === 'readOnly' ? 16 : 14}>
                  <FormItem
                    label="Link Multiple MSAs"
                    className="connectedStates-form-item">
                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        {displayForm ? (
                          getFieldDecorator('nearby_msa', {
                            initialValue:
                              (data &&
                                data.nearby_msa &&
                                Array.isArray(data.nearby_msa) &&
                                data.nearby_msa.length > 0 &&
                                data.nearby_msa.map(
                                  ({city, state_code}) =>
                                    `${city}, ${state_code}`
                                )) ||
                              [],
                          })(
                            <Select
                              allowClear
                              showSearch
                              mode="multiple"
                              filterOption={false}
                              value={msaSearchValue}
                              tokenSeparators={[',']}
                              onSearch={handleMSASearch}
                              loading={msaLocationsRequest}
                              defaultActiveFirstOption={false}
                              placeholder="Select Linked MSAs"
                              onSelect={() => handleMSASearch('')}
                              notFoundContent="no location matches found"
                              // filterOption={(input, option) =>
                              //   option.props.children
                              //     .toLowerCase()
                              //     .indexOf(input.toLowerCase()) >= 0
                              // }
                            >
                              {!msaLocationsRequest &&
                                MSAOptions.slice(0, 50).map(
                                  ({uuid, city, state_code}) => (
                                    <Option key={uuid} value={uuid}>
                                      {`${city}, ${state_code}`}
                                    </Option>
                                  )
                                )}
                            </Select>
                          )
                        ) : (
                          <span className="txt">
                            {data &&
                            Array.isArray(data.nearby_msa) &&
                            data.nearby_msa.length > 0
                              ? data.nearby_msa.map(
                                  ({id, city, state_code}) => (
                                    <Tag
                                      key={id}>{`${city}, ${state_code}`}</Tag>
                                  )
                                )
                              : '-'}
                          </span>
                        )}
                      </Col>
                    </Row>
                  </FormItem>
                </Col>
              )}
            </Row>
            {showConnectedStates && (
              <Row>
                <Col span={mode === 'readOnly' ? 24 : 12}>
                  <FormItem
                    label={`Connected ROP(s)`}
                    className="connectedStates-form-item">
                    {displayForm ? (
                      <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>
                          {getFieldDecorator('consortium_list', {
                            initialValue:
                              mode === 'edit'
                                ? ropInitialValues
                                : filteredRopData,
                            //initialValue: data && data.consortium_list,
                          })(
                            <Select
                              showArrow
                              allowClear
                              showSearch
                              mode="multiple"
                              tokenSeparators={[',']}
                              placeholder="Select ROP"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }>
                              {consortiumStates &&
                                consortiumStates.data &&
                                consortiumStates.data.data &&
                                isArray(consortiumStates.data.data) &&
                                consortiumStates.data.data.map(
                                  (
                                    {
                                      city,
                                      state_code,
                                      consortium_name,
                                      consortium_uuid,
                                    },
                                    index
                                  ) => (
                                    <Option
                                      key={index}
                                      value={consortium_uuid}
                                      className="capitalize">
                                      {`${capitalizeFirstLetter(
                                        consortium_name
                                      )} - ${city}, ${state_code}`}
                                    </Option>
                                  )
                                )}
                            </Select>
                          )}
                        </Col>
                      </Row>
                    ) : (
                      <span className="txt">
                        {data &&
                        Array.isArray(data.consortium_list) &&
                        data.consortium_list.length > 0
                          ? data.consortium_list.map(
                              ({name, city, state_code}, idx) => (
                                <Tag
                                  key={`state-${idx}`}
                                  title={`${name} - ${city}, ${state_code}`}>
                                  {`${capitalizeFirstLetter(
                                    name
                                  )} - ${city}, ${state_code}`}
                                </Tag>
                              )
                            )
                          : '-'}
                      </span>
                    )}
                  </FormItem>
                </Col>
              </Row>
            )}
          </Col>

          {licenseType === 'Employer' && (
            <Col span={mode === 'readOnly' ? 24 : 12}>
              <FormItem
                label={`Connected Institution(s)`}
                className="connectedStates-form-item">
                {displayForm ? (
                  <Row>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      {getFieldDecorator('institution_list', {
                        initialValue:
                          mode === 'edit'
                            ? selectedInstitutionValue
                            : instituteFilteredData,
                      })(
                        <Select
                          showArrow
                          allowClear
                          showSearch
                          mode="multiple"
                          tokenSeparators={[',']}
                          placeholder="Select institution"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }>
                          {institutionData &&
                            institutionData.data &&
                            Array.isArray(institutionData.data) &&
                            institutionData.data.length > 0 &&
                            institutionData.data.map((res, index) => (
                              <Option
                                key={index}
                                value={res.institution_id}
                                title={`${res.name} - ${
                                  res.physical_address__city
                                },
                                ${res.state_code}`}>
                                {`${res.name} - ${res.physical_address__city},
                          ${res.state_code}`}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Col>
                  </Row>
                ) : (
                  <span className="txt">
                    {data &&
                    Array.isArray(data.institution_list) &&
                    data.institution_list.length > 0
                      ? data.institution_list.map((res, idx) => (
                          <Tag key={`state-${idx}`}>
                            {`${res.name} - ${res.city},
                          ${res.state_code}`}
                          </Tag>
                        ))
                      : '-'}
                  </span>
                )}
              </FormItem>
            </Col>
          )}
        </Row>

        {getUserPortal !== 'consortium' && (
          <div>
            <Row className="form-head">
              <Col span={12}>
                <h1
                  style={{
                    marginBottom: '0px',
                  }}>
                  Contract Period
                </h1>
              </Col>
            </Row>
            <StartEndDate
              mode={mode}
              data={data}
              displayForm={displayForm}
              licenseType={licenseType}
              {...props}
            />
          </div>
        )}

        <Address
          mode={mode}
          data={data}
          displayForm={displayForm}
          licenseType={licenseType}
          statesData={statesData}
          {...form}
        />
      </div>
      <FormItem className="steps-action margin-muted">
        <StepAction
          currentStep={currentStep}
          totalSteps={totalSteps}
          mode={mode}
          onSwitchMode={onSwitchMode}
          isSaving={isSaving}
        />
      </FormItem>
    </Form>
  );
};

export default Form.create({
  onValuesChange: ({form}, changedFields, allFields) => {
    if (
      !allFields.physical_address_details['address_line1'] &&
      !allFields.physical_address_details['city'] &&
      !allFields.physical_address_details['state'] &&
      !allFields.physical_address_details['zipcode']
    ) {
      form.setFields({
        physical_address_details: {
          address_line1: {
            value: '',
            errors: [''],
          },
          city: {
            value: '',
            errors: [''],
          },
          state: {
            value: '',
            errors: [''],
          },
          zipcode: {
            value: '',
            errors: [''],
          },
        },
      });
      form.validateFields([
        'physical_address_details.address_line1',
        'physical_address_details.city',
        'physical_address_details.state',
        'physical_address_details.zipcode',
      ]);
    }

    if (
      !allFields.mailing_address_details['address_line1'] &&
      !allFields.mailing_address_details['city'] &&
      !allFields.mailing_address_details['state'] &&
      !allFields.mailing_address_details['zipcode']
    ) {
      form.setFields({
        mailing_address_details: {
          address_line1: {
            value: '',
            errors: [''],
          },
          city: {
            value: '',
            errors: [''],
          },
          state: {
            value: '',
            errors: [''],
          },
          zipcode: {
            value: '',
            errors: [''],
          },
        },
      });
      form.validateFields([
        'mailing_address_details.address_line1',
        'mailing_address_details.city',
        'mailing_address_details.state',
        'mailing_address_details.zipcode',
      ]);
    }
  },
})(LicenseParameters);
