import jwtDecode from 'jwt-decode';

import {isAccessTokenExpired} from 'redux/modules/auth';

export const removeTokens = () => {
  removeItem('auth');
  //localStorage.removeItem('auth');
};

export const loadTokens = () => {
  let auth;
  try {
    auth = localStorage.getItem('auth');
    if (auth && isAccessTokenExpired(auth)) {
      removeTokens();
    }
  } catch (err) {
    console.log(err);
  }
  return auth ? JSON.parse(auth) : null;
};

export const saveTokens = payload => {
  const authtokens = {
    loggedinUserId: payload.srm_user,
    userType: payload.user_type,
    user_portal: payload.user_portal,
    UIPermissions: payload.UIPermissions,
    permissions: payload.permissions,
    institution_id: payload.institution_id,
    institution_uuid: payload.institution_uuid,
    institutionType: payload.institution_type,
    access: {
      token: payload.access,
      bodyToken: payload.token,
      ...jwtDecode(payload.access),
    },
    refresh: {
      token: payload.refresh,
      //...jwtDecode(payload.refresh),
    },
  };
  try {
    saveItem('auth', JSON.stringify(authtokens));
    //localStorage.setItem('auth', JSON.stringify(authtokens));
  } catch (err) {
    console.log(err);
  }
};

export const saveItem = (item, val) => {
  localStorage.setItem(item, val);
};
export const removeItem = item => {
  localStorage.removeItem(item);
};
