import * as TYPES from './constants';

// SRM PARTNERS
export const receivePartnersList = json => ({
  type: TYPES.RECEIVE_PARTNERS_LIST,
  payload: json,
});

export const requestPartnersList = () => ({
  type: TYPES.REQUEST_PARTNERS_LIST,
});

export const handlePartnersListError = err => ({
  type: TYPES.PARTNERS_LIST_ERROR,
  err,
});
export const receivePartnersGraphdata = json => ({
  type: TYPES.RECEIVE_PARTNERS_GRAPH_DATA,
  payload: json,
});

export const requestPartnersGraphdata = () => ({
  type: TYPES.REQUEST_PARTNERS_GRAPH_DATA,
});

export const handlePartnersGraphdataError = err => ({
  type: TYPES.PARTNERS_GRAPH_DATA_ERROR,
  err,
});

export const filterGraphQuaterly = months => ({
  type: TYPES.PARTNERS_MONTHS_FILTER,
  months,
});

// PARTNER USERS
export const receivePartnerUsers = json => ({
  type: TYPES.RECEIVE_PARTNER_USERS,
  payload: json,
});

export const requestPartnerUsers = () => ({
  type: TYPES.REQUEST_PARTNER_USER,
});

export const handlePartnersUserError = err => ({
  type: TYPES.PARTNER_USER_ERROR,
  err,
});
