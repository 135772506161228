import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

import {Row, Col, message, Collapse} from 'antd';
import {FixedSizeList as List} from 'react-window';
import {API} from 'config/srm.config';

import {accessToken} from 'redux/modules/auth';

import {
  fetchCatalogCourses,
  getCoursesSelector,
  fetchSkills,
  getSkillsSelector,
} from 'redux/modules/courseManagement';
import {
  fetchCreditStatus,
  fetchCourseInstructionType,
  fetchCourseTransferType,
  getCreditStatusSelector,
  getCourseInstructionTypeSelector,
  getCourseTransferTypeSelector,
} from 'redux/modules/courseManagement';

import {Search, sortArrayOfObjPropertyBased} from 'core';
import CourseItem from './CourseItem';
import ListItem from './ListItem';
import AddCourse from './../../../apps/srmApp/modules/administration/components/course-management/AddCourse';

import {Services} from './../../services';
import './style.css';

const services = new Services();
const {success, error} = message;
const {Panel} = Collapse;

function AllCourseList({
  fetchCatalogCourses,
  fetchCreditStatus,
  fetchCourseInstructionType,
  fetchCourseTransferType,
  token,
  orignalData,
  creditStatus = [],
  courseInstructionTypes,
  courseTransferTypes,
  skills,
  displayListItem,
  disableCollapse,
  config,
  title,
  itemHeight,
  isElectiveOnly,
  electiveCourse,
}) {
  const [visible, setVisibility] = useState(false);
  const [searchedTerm, setSearchedTerm] = useState(null);
  const [searchedCourses, setSearchedCourses] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    (!orignalData || !orignalData.data) && fetchCatalogCourses();
    if (!courseInstructionTypes) {
      fetchCourseInstructionType();
    }
    if (!courseTransferTypes) {
      fetchCourseTransferType();
    }
    if (creditStatus && creditStatus.length <= 0) {
      fetchCreditStatus();
    }
    if (skills && skills.length <= 0) {
      fetchSkills();
    }
  }, []);

  useEffect(
    () => {
      if (searchedTerm && searchedTerm != '') {
        onSearch(searchedTerm);
      }
    },
    [orignalData.data]
  );
  const createCourse = data => {
    let endPoint = API.course_management.courses;
    let method = 'POST';
    let message = 'New course added successfully';
    if (data.uuid) {
      endPoint = `${endPoint}${data.uuid}`;
      method = 'PUT';
      message = 'Course edited successfully';
      delete data.uuid;
    }
    services
      .createUpdateRecord(token, endPoint, data, method)
      .then(res => {
        if (res && res.Error) {
          error(res.Error);
          return;
        }
        if (res && res.course_id) {
          success(`${message} ${res.course_id}`);
          fetchCatalogCourses();
          toggleAddNewCourse();
        }
      })
      .catch(err => console.log(err));
  };

  const onSearch = term => {
    let searchedCourses = null;
    if (term && term !== '') {
      searchedCourses = sortArrayOfObjPropertyBased(
        orignalData.data.courses.filter(course => {
          if (
            course.course_id.toLowerCase().includes(term.toLowerCase()) &&
            course.status
          ) {
            return course;
          }
        }),
        'course_id'
      );
    }
    setSearchedCourses(searchedCourses);
    setSearchedTerm(term);
  };

  const toggleAddNewCourse = () => setVisibility(!visible);

  const getCourse = course => {
    if (course) {
      setSelectedCourse(course);
      setVisibility(true);
    }
    return false;
  };

  const RenderCourses = ({index, style}) => {
    const course = courses[index];
    return (
      <div
        className={index % 2 ? 'CourseItemOdd' : 'CourseItemEven'}
        style={style}>
        {displayListItem ? (
          <ListItem data={course} onClick={getCourse} {...config} />
        ) : (
          <CourseItem
            data={course}
            onClick={getCourse}
            isElectiveOnly={isElectiveOnly}
          />
        )}
      </div>
    );
  };

  let courses = [];
  if (searchedCourses) {
    courses = [...searchedCourses];
  } else if (isElectiveOnly) {
    courses = electiveCourse;
  } else if (
    orignalData &&
    orignalData.data &&
    orignalData.data.courses &&
    orignalData.data.courses.length
  ) {
    courses = [...orignalData.data.courses.filter(course => course.status)];
  }

  return (
    <div className="all-courses-list">
      <Collapse
        className={`${
          disableCollapse
            ? 'disableCollapse courseContainer'
            : 'courseContainer'
        }`}
        defaultActiveKey="list-of-courses">
        <Panel
          className="courseHeader"
          header={`${title || `Courses (${courses.length || 0})`}`}
          key="list-of-courses">
          {!isElectiveOnly && (
            <div className="courseSearch">
              <Search
                placeholder="Search course via course ID"
                onSearch={onSearch}
              />
            </div>
          )}
          <div className="list">
            <Row className="courseHeader floating">
              <Col span={18} className="courese_id">
                COURSE ID
              </Col>
              <Col span={6} className="course_units">
                UNITS
              </Col>
            </Row>
            <div className="content">
              {isElectiveOnly ? (
                <List
                  className="List"
                  height={50}
                  itemCount={1}
                  itemSize={42}
                  width={'100%'}>
                  {RenderCourses}
                </List>
              ) : orignalData.request ? (
                <span className="onLoading"> Loading... </span>
              ) : searchedCourses &&
                searchedCourses.length === 0 &&
                courses &&
                courses.length === 0 ? (
                <span className="noSearch">No results found.</span>
              ) : (
                orignalData &&
                orignalData.data &&
                !orignalData.error && (
                  <List
                    className="List"
                    height={350}
                    itemCount={courses.length}
                    itemSize={itemHeight || 42}
                    width={'100%'}>
                    {RenderCourses}
                  </List>
                )
              )}
            </div>
          </div>
        </Panel>
      </Collapse>
      {/* <div className="courseContainer non-collapse">
        <div className="courseHeader">{`${title ||
          `Courses (${courses.length || 0})`}`}</div>

        <div className="courseSearch">
          <Search
            placeholder="Search course via course ID"
            onSearch={onSearch}
          />
        </div>
        <div className="list">
          <Row className="courseHeader floating">
            <Col span={20} className="courese_id">
              COURSE ID
            </Col>
            <Col span={4} className="course_units">
              UNITS
            </Col>
          </Row>
          <div className="content">
            {orignalData.request ? (
              <span className="onLoading"> Loading... </span>
            ) : searchedCourses &&
              searchedCourses.length === 0 &&
              courses &&
              courses.length === 0 ? (
              <span className="noSearch">No results found.</span>
            ) : (
              orignalData &&
              orignalData.data &&
              !orignalData.error && (
                <List
                  className="List"
                  height={350}
                  itemCount={courses.length}
                  itemSize={itemHeight || 42}
                  width={150}>
                  {RenderCourses}
                </List>
              )
            )}
          </div>
        </div>
      </div> */}
      {visible && !isElectiveOnly && (
        <AddCourse
          data={selectedCourse}
          courses={orignalData && orignalData.data && orignalData.data.courses}
          visible={visible}
          toggleAddNewCourse={toggleAddNewCourse}
          createCourse={createCourse}
          creditStatus={creditStatus}
          courseInstructionTypes={courseInstructionTypes}
          courseTransferTypes={courseTransferTypes}
          skillsData={skills}
          onSave
        />
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  token: accessToken(state),
  creditStatus: getCreditStatusSelector(state),
  courseInstructionTypes: getCourseInstructionTypeSelector(state),
  courseTransferTypes: getCourseTransferTypeSelector(state),
  orignalData: getCoursesSelector(state),
  skills: getSkillsSelector(state),
});

export default connect(
  mapStateToProps,
  {
    fetchCatalogCourses,
    fetchCreditStatus,
    fetchCourseInstructionType,
    fetchCourseTransferType,
  }
)(AllCourseList);
