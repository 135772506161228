import React, {useState} from 'react';
import debounce from 'lodash.debounce';
import {Input, Checkbox, Avatar, Icon, Form} from 'antd';
import {NoSearchResults, Services, isObject, isArray} from 'core';

import './AsyncSearch.css';

const FormItem = Form.Item;

const AsyncSearch = props => {
  const {
    onResultSelect,
    uri,
    token,
    label,
    initialResult,
    placeholder,
    hideLabel,
  } = props;
  const services = new Services();
  const [results, setResults] = useState(null);

  const searchError = error => console.log(error);

  const onClose = event => {
    event.preventDefault();
    setResults(null);
  };

  const resultSelect = event => onResultSelect(event.target.value);

  const search = async term => {
    try {
      services
        .fetchRecords(`${uri}?search=${term}`, token)
        .then(res => {
          setResults(res);
        })
        .catch(err => searchError(err));
    } catch (err) {
      searchError(err);
    }
  };

  const debounceSearch = debounce(search, 800);

  const changeHandler = evt => {
    if (evt.target.value) {
      debounceSearch(evt.target.value);
    }
  };

  let selectedRecords = [];

  if (initialResult && isObject(initialResult)) {
    selectedRecords = Object.keys(initialResult).map(recordId => {
      return initialResult[recordId].uuid;
    });
  }
  if (initialResult && isArray(initialResult)) {
    selectedRecords = initialResult.map(recordId => {
      return recordId.uuid;
    });
  }

  return (
    <>
      {
        <FormItem label={!hideLabel && label ? label : null}>
          <Input onChange={changeHandler} placeholder={placeholder} />
        </FormItem>
      }
      {results && results.length === 0 && <NoSearchResults />}
      {results && results.length > 0 && (
        <div className="asynchsearch">
          <FormItem label={!hideLabel && 'Results'} className="send-mail-data">
            <div className="clearfix data-table-header">
              <div className="ant-col-8">Name</div>
              <div className="ant-col-10">Email</div>
              <div className="ant-col-6">Work Phone</div>
              <Icon
                type="close"
                className="close-btn"
                theme="outlined"
                onClick={onClose}
              />
            </div>
            <div>
              <div className="data-table-row-border data-table-scroll-300">
                {results.map(item => (
                  <div
                    className={`clearfix data-table-row avatar-table-row-pad ${
                      selectedRecords && selectedRecords.includes(item.uuid)
                        ? 'selected'
                        : ''
                    }`}
                    key={item.uuid}>
                    <Checkbox
                      id="audience-checkbox"
                      value={item}
                      onChange={resultSelect}>
                      <span
                        className="ant-col-8 text-ellipsis"
                        title={item.first_name}>
                        <Avatar
                          src={item.picture}
                          alt={item.first_name}
                          icon="user"
                        />
                        {item.first_name}
                      </span>
                      <span
                        className="ant-col-10 text-ellipsis"
                        title={item.email}>
                        {item.email}
                      </span>
                      <span
                        className="ant-col-6 text-ellipsis"
                        title={item.phone_number}>
                        {item.phone_number}
                      </span>
                    </Checkbox>
                  </div>
                ))}
              </div>
            </div>
          </FormItem>
        </div>
      )}
    </>
  );
};

export default AsyncSearch;
