export const REQUEST_PARTNERS_LIST = 'REQUEST_PARTNERS_LIST';
export const RECEIVE_PARTNERS_LIST = 'RECEIVE_PARTNERS_LIST';
export const PARTNERS_LIST_ERROR = 'PARTNERS_LIST_ERROR';
export const REQUEST_PARTNERS_GRAPH_DATA = 'REQUEST_PARTNERS_GRAPH_DATA';
export const RECEIVE_PARTNERS_GRAPH_DATA = 'RECEIVE_PARTNERS_GRAPH_DATA';
export const PARTNERS_GRAPH_DATA_ERROR = 'PARTNERS_GRAPH_DATA_ERROR';
export const PARTNERS_MONTHS_FILTER = 'PARTNERS_MONTHS_FILTER';
export const RECEIVE_PARTNER_USERS = 'RECEIVE_PARTNER_USERS';
export const REQUEST_PARTNER_USER = 'REQUEST_PARTNER_USER';
export const PARTNER_USER_ERROR = 'PARTNER_USER_ERROR';
