import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import _ from 'lodash';
import * as TYPES from './constants';
import {isObject, isEmpty} from 'core';
import {
  requestSkills,
  receiveSkills,
  handleSkillsError,
  addNewSkill,
  editNewSkill,
} from './actions';
const initialState = {
  request: null,
  data: [],
  error: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_SKILLS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_SKILLS:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.ADD_SKILL:
      const _d = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      return {
        ...state,
        data: [...state.data, ..._d],
      };
    case TYPES.EDIT_SKILL:
      const index = _.findIndex(state.data, {uuid: action.payload.uuid});
      const d = [...state.data];
      d.splice(index, 1, action.payload.skill);
      return {
        ...state,
        data: d,
      };
    case TYPES.SKILLS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const addSkill = skill => dispatch => {
  dispatch(addNewSkill(skill));
};

export const editSkill = (skill, uuid) => dispatch => {
  dispatch(editNewSkill({skill, uuid}));
};

export const fetchSkills = (params, callback) => (dispatch, getState) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  const endPoint = `${API.course_management.skills}`;
  const promise1 = fetch(`${endPoint}?source=emi`, config);
  const promise2 = fetch(`${endPoint}?source=linkedin`, config);
  dispatch(requestSkills());
  try {
    let endPoint = `${API.course_management.skills}`;
    if (params && isObject(params) && !isEmpty(params)) {
      Object.keys(params).map((key, idx) => {
        if (idx === 0) {
          endPoint += `?${key}=${params[key]}`;
        } else {
          endPoint += `&${key}=${params[key]}`;
        }
      });
    }
    return Promise.all([promise1, promise2])
      .then(async ([aa, bb]) => {
        const a = await aa.json();
        const b = await bb.json();
        return [a, b];
      })
      .then(values => {
        console.log('values', values);
        const value1 =
          values && values[0] && Array.isArray(values[0]) ? values[0] : [];
        const value2 =
          values && values[1] && Array.isArray(values[1]) ? values[1] : [];
        const result = [...value1, ...value2];
        if (Array.isArray(result)) {
          dispatch(receiveSkills(result));
          if (callback) callback(result);
        } else {
          dispatch(handleSkillsError());
        }
      });
    // return fetch(endPoint, config)
    //   .then(response => {
    //     if (response.ok) {
    //       return response.json();
    //     }
    //     throw new Error('Api Fails');
    //   })
    //   .then(json => {
    //     dispatch(receiveSkills(json));
    //     if (callback) callback(json);
    //   })
    //   .catch(err => {
    //     console.log(err);
    //     dispatch(handleSkillsError(err));
    //   });
  } catch (err) {
    console.log(err);
    dispatch(handleSkillsError(err));
  }
};
