import {useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {styled, useTheme} from '@mui/material/styles';
import AboutUs from 'apps/common-modules/shared/AboutUs';
import {logout, fetchInstance, userPortalSelector} from 'redux/modules/auth';
import {
  Box,
  Menu,
  Grow,
  Stack,
  Button,
  Avatar,
  Dialog,
  Divider,
  MenuItem,
  Typography,
  ListItemText,
} from '@mui/material';
import {ExpandMore, ExpandLess, ChevronRightRounded} from '@mui/icons-material';

const StyledAccountButton = styled(Button)(() => ({
  border: 'none',
  minWidth: 'auto',
  boxShadow: 'none',
  background: 'transparent',
}));

const ProfileMenu = ({onSignOut, userDetails, profileMenuData = []}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [aboutModal, setAboutModal] = useState(false);
  const userPortal = useSelector(userPortalSelector) || {};
  const {first_name, last_name, email} = userDetails || {};
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onAboutModalClose = () => {
    setAboutModal(false);
  };

  const handleMenuClick = menu => {
    handleClose();
    const {click = null} = menu;
    click && click(menu);
  };

  const onLogout = () => {
    dispatch(logout());
    dispatch(fetchInstance());
    onSignOut && onSignOut();
  };

  return (
    <Box>
      <StyledAccountButton
        size="small"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{cursor: 'pointer'}}
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'basic-menu' : undefined}
        endIcon={open ? <ExpandLess /> : <ExpandMore />}
        startIcon={
          <Avatar
            width={40}
            height={40}
            alt="avatar-logo"
            sx={{cursor: 'pointer'}}
          />
        }
      />
      <Menu
        open={open}
        id="account-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            mt: 1.5,
            minWidth: '240px',
            overflow: 'visible',
            borderRadius: '20px',
            bgcolor:
              theme.palette.mode === 'dark'
                ? theme.palette.darkGray.main
                : theme.palette.background.paper,
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '& ul': {
              padding: 0,
            },
            // '&::before': {
            //   top: 0,
            //   right: 14,
            //   zIndex: 0,
            //   width: 10,
            //   height: 10,
            //   content: '""',
            //   display: 'block',
            //   position: 'absolute',
            //   bgcolor:
            //     theme.palette.mode === 'dark'
            //       ? theme.palette.darkGray.main
            //       : theme.palette.background.paper,
            //   transform: 'translateY(-50%) rotate(45deg)',
            // },
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
        {email && first_name && last_name && (
          <>
            <Grow in={open} timeout={800}>
              <Stack
                p={2}
                gap={0}
                width="100%"
                alignItems="flex-start"
                justifyContent="center">
                <Typography variant="h6" color="text.primary">
                  {email}
                </Typography>
                <Typography variant="h6" color={theme.palette.grey.main}>
                  {`${first_name} ${last_name}`}
                </Typography>
              </Stack>
            </Grow>
            <Divider />
          </>
        )}
        {profileMenuData &&
          Array.isArray(profileMenuData) &&
          profileMenuData?.length > 0 &&
          profileMenuData.map(menuOption => {
            const {label, to = ''} = menuOption;
            return (
              <Grow in={open} timeout={500}>
                <MenuItem
                  to={to}
                  component={to ? Link : 'li'}
                  onClick={() => handleMenuClick(menuOption)}>
                  <Stack
                    width="100%"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <ListItemText>{label}</ListItemText>
                    <ChevronRightRounded />
                  </Stack>
                </MenuItem>
              </Grow>
            );
          })}
        {userPortal !== 'super_user' && (
          <Grow in={open} timeout={500}>
            <MenuItem onClick={() => setAboutModal(true)}>
              <Stack
                width="100%"
                direction="row"
                alignItems="center"
                justifyContent="space-between">
                <ListItemText>About Us</ListItemText>
                <ChevronRightRounded />
              </Stack>
            </MenuItem>
          </Grow>
        )}
        <Grow in={open} timeout={800}>
          <Stack
            p={2}
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="center">
            <Button variant="outlined" width={200} onClick={onLogout}>
              Log Out
            </Button>
          </Stack>
        </Grow>
      </Menu>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={aboutModal}
        PaperProps={{
          sx: {
            borderRadius: '20px',
          },
        }}
        onClose={onAboutModalClose}>
        <AboutUs />
      </Dialog>
    </Box>
  );
};

export default ProfileMenu;
