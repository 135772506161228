import React from 'react';
import {Button} from 'antd';

const StepAction = ({
  currentStep,
  isSaving,
  totalSteps,
  goToPrevStep,
  mode,
  onSwitchMode,
  onCustomSave,
  saveText = 'Save',
}) => {
  return mode && mode != 'create' ? (
    <div className="steps-action">
      <Button
        type={mode === 'readOnly' ? 'primary' : 'default'}
        onClick={onSwitchMode}
        className="btn-outline margin-r20"
        style={{color: mode !== 'readOnly' && 'rgb(153, 180, 69)'}}>
        {`${mode === 'readOnly' ? 'Edit' : 'Cancel'}`}
      </Button>
      {mode === 'edit' && !onCustomSave && (
        <Button
          type="primary"
          htmlType="submit"
          loading={isSaving}
          disabled={isSaving}>
          Save
        </Button>
      )}
      {mode === 'edit' && onCustomSave && (
        <Button
          type="primary"
          onClick={onCustomSave}
          loading={isSaving}
          disabled={isSaving}>
          Save
        </Button>
      )}
    </div>
  ) : (
    <div className="steps-action">
      {currentStep > 0 && (
        <Button onClick={goToPrevStep} className="btn-outline margin-r20">
          Previous
        </Button>
      )}
      {currentStep <= totalSteps - 1 && onCustomSave && (
        <Button
          type="primary"
          onClick={onCustomSave}
          loading={isSaving}
          disabled={isSaving}>
          {`${currentStep === totalSteps - 1 ? saveText : 'Next'}`}
        </Button>
      )}
      {currentStep <= totalSteps - 1 && !onCustomSave && (
        <Button
          type="primary"
          htmlType="submit"
          loading={isSaving}
          disabled={isSaving}>
          {`${currentStep === totalSteps - 1 ? saveText : 'Next'}`}
        </Button>
      )}
    </div>
  );
};

export default StepAction;
