import React from 'react';
import {Stats as MUIStats} from 'mui-core';
import {StyledContainer, StatsLabel} from './styles';

const Posting = ({data}) => {
  const preparePostingsStatsData = () => {
    if (data) {
      const {
        apprenticeships = 0,
        external_jobs = 0,
        scholarships = 0,
        internships = 0,
        other = 0,
        jobs = 0,
      } = data;
      const statsData = [
        {
          title: 'Jobs',
          count: jobs,
          color: '#8B572A',
        },
        {
          title: 'Internships',
          count: internships,
          color: '#2B39AD',
        },
        {
          title: 'Apprenticeships',
          count: apprenticeships,
          color: '#44D7B6',
        },
        {
          title: 'Scholarships',
          count: scholarships,
          color: '#A956E3',
        },
        {
          title: 'Other',
          count: other,
          color: '#B28B22',
        },
        {
          title: 'External Jobs',
          count: external_jobs,
          color: '#DE1D1D',
        },
      ];
      return statsData;
    }
    return [];
  };
  const postingsData = preparePostingsStatsData();
  return (
    <StyledContainer>
      <StatsLabel>Postings</StatsLabel>
      <MUIStats data={postingsData} />
    </StyledContainer>
  );
};

export default Posting;
