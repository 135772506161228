import React, {Component} from 'react';
import {Select} from 'antd';

import {Graph, calculateGraphData} from 'core';

const {Option} = Select;

//YEAR FILTER:: Awaiting for confirmation

// const currentDate = new Date();
// const CurrentYear = currentDate.getFullYear();

// let YearsArray = [CurrentYear];

// for (let i = 1; i <= 10; i++) {
//   let year = CurrentYear - i;
//   if (year >= 2020) {
//     YearsArray.push(year);
//   }
// }

class TimelineContainer extends Component {
  state = {
    filterBy: 'all',
    //yearBy: CurrentYear,
    data: {},
  };

  handleChange = filterBy => {
    this.setState({
      filterBy,
    });
  };

  // onYearChange = yearBy => {
  //   this.setState({
  //     ...this.state,
  //     yearBy,
  //   });
  // }

  render() {
    const {data, staticData, withoutHeader} = this.props;
    const {filterBy, yearBy} = this.state;
    const GraphData =
      calculateGraphData(data, filterBy, staticData, yearBy) || {};
    return (
      <div
        className={
          withoutHeader ? 'timeLine-container no-header' : 'timeLine-container'
        }>
        <div
          className={
            withoutHeader ? 'timeLine-Header no-header' : 'timeLine-Header'
          }>
          {!withoutHeader && <h3>TIMELINE</h3>}
          {/* <div className="timeLine-filter">
            <span>Display :</span>
            <Select
              defaultValue={yearBy}
              style={{ width: 120 }}
              onChange={this.onYearChange}>
              {YearsArray.map(year => <Option value={year}>{year}</Option>)}
            </Select>
          </div> */}
          <div className="timeLine-filter">
            <span>Display :</span>
            <Select
              defaultValue={filterBy}
              style={{width: 120}}
              onChange={this.handleChange}>
              <Option value="all">All</Option>
              <Option value={3}>3 months</Option>
              <Option value={6}>6 months</Option>
              <Option value={9}>9 months</Option>
              <Option value={12}>12 months</Option>
            </Select>
          </div>
        </div>
        <Graph data={GraphData} />
      </div>
    );
  }
}

export default TimelineContainer;
