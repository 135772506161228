import * as TYPES from './constants';
import {Services} from 'core';
import {
  requestStudentExchangePrograms,
  receiveStudentExchangePrograms,
  handleStudentExchangeProgramsError,
} from './actions';

import {JWT_HEADER, JSON_HEADER} from 'config/srm.config';
import {API} from 'config/businesspartner.config';

const services = new Services();

const initialState = {
  data: null,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_STUDENT_EXCHANGE_PROGRAMS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_STUDENT_EXCHANGE_PROGRAMS:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.STUDENT_EXCHANGE_PROGRAMS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    //   case TYPES.CLEAR_BUSINESS_PARTNER_STUDENT_PROFILE:
    //     return {
    //       ...state,
    //       data: null,
    //       error: false,
    //       request: false,
    //     };
    default:
      return state;
  }
};

export const fetchStudentExchangePrograms = () => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestStudentExchangePrograms());
  try {
    return fetch(
      `${
        API.administration.student_exchange_programs
      }?student_exchange_pos=true`,
      config
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Api Fails');
      })
      .then(json => {
        dispatch(receiveStudentExchangePrograms(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleStudentExchangeProgramsError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleStudentExchangeProgramsError(err));
  }
};
