import React from 'react';
import {Button, Select, DatePicker} from 'antd';
import moment from 'moment';
const {MonthPicker} = DatePicker;
const Option = Select.Option;

export default ({
  defaultValue,
  monthsChange,
  onStartChange,
  onEndChange,
  filterSubmit,
  initialStartDate,
  initialEndDate,
}) => {
  return (
    <div className="timeline-filter print-hide">
      <Select defaultValue="all" onChange={monthsChange} className="field-100">
        <Option value="all">All</Option>
        <Option value={3}>3 Months</Option>
        <Option value={6}>6 Months</Option>
        <Option value={9}>9 Months</Option>
        <Option value={12}>12 Months</Option>
      </Select>
      {filterSubmit && (
        <>
          <span className="filterby margin-l20">
            <span className="margin-r10">Filter By:</span>
            <MonthPicker
              format="YYYY-MM"
              placeholder="From"
              onChange={onStartChange}
              className="field-100 margin-r10"
              value={moment(initialStartDate || {})}
            />
            <MonthPicker
              format="YYYY-MM"
              placeholder="To"
              onChange={onEndChange}
              className="field-100 margin-r10"
              value={moment(initialEndDate || {})}
            />
          </span>
          <Button onClick={filterSubmit}>Filter</Button>
        </>
      )}
    </div>
  );
};
