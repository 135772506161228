import React, {useState, Fragment} from 'react';
import {Row, Col, Icon} from 'antd';
import {ErrorBoundary} from 'core';

const Stats = ({
  headerTitle,
  horizontaltop,
  horizontal,
  data,
  headingtop,
  colWidth,
  renderComponent,
}) => {
  const [config, setConfig] = useState({show: false});
  const onToggleClick = parent => {
    setConfig({
      show: config.parent !== parent ? true : !config.show,
      parent,
    });
  };
  const {show, parent} = config;
  const StatItem = (
    color,
    count,
    title,
    className = '',
    arrow,
    children,
    insideColWidth,
    uniqueKey,
    statIcon = ''
  ) => (
    <Col
      xs={12}
      sm={8}
      md={8}
      lg={insideColWidth || colWidth || 4}
      key={uniqueKey}
      className={`count-col bor-${color} ${className} ${(children &&
        children.length > 0 &&
        'show-pointer-events') ||
        ''}`}
      onClick={
        children && children.length ? () => onToggleClick(title) : () => {}
      }>
      {headingtop ? (
        <>
          <h4 className="count-txt">{title}</h4>
          <span className="label-txt">
            {statIcon}
            {count}
          </span>
        </>
      ) : (
        <>
          <h4 className="count-txt">
            {statIcon}
            {count}
            {arrow && <span className={`icon-arrow-${arrow}`} />}
            {children && children.length > 0 && (
              <Icon type={show && parent === title ? 'left' : 'right'} />
            )}
          </h4>
          <span className="label-txt">{title}</span>
        </>
      )}
    </Col>
  );

  return (
    <ErrorBoundary>
      <div className="overview-summary">
        {headerTitle && (
          <div className="overview-header">
            <h4 className="overview-title">{headerTitle}</h4>
            {renderComponent}
          </div>
        )}
        {horizontaltop && <hr />}
        <Row className="overview-counts">
          {data &&
            data.length > 0 &&
            data.map(
              (
                {
                  color,
                  count,
                  title,
                  statIcon,
                  className,
                  arrow,
                  children,
                  insideColWidth,
                },
                idx
              ) => {
                return (
                  <Fragment key={idx}>
                    {StatItem(
                      color,
                      count,
                      title,
                      className,
                      arrow,
                      children,
                      insideColWidth,
                      `${idx}-parent`,
                      statIcon
                    )}
                    {show &&
                      parent === title &&
                      children &&
                      children.length > 0 &&
                      children.map(
                        (
                          {color, count, title, statIcon, className, arrow},
                          idx
                        ) =>
                          StatItem(
                            color,
                            count,
                            title,
                            className,
                            arrow,
                            null,
                            insideColWidth,
                            `${idx}-child`,
                            statIcon
                          )
                      )}
                  </Fragment>
                );
              }
            )}
        </Row>
        {horizontal && <hr />}
      </div>
    </ErrorBoundary>
  );
};

export default Stats;
