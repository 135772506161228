import React from 'react';
import {Divider} from 'antd';
import {NoSearchResults} from 'core';

const Bio = ({data}) => {
  return (
    <div className="bio">
      {data && data.bio && (
        <>
          <div className="bio-header">Professional Summary</div>
          <div className="bio-content">{data && data.bio}</div>
        </>
      )}
      {/* <Divider className="_divider" /> */}
    </div>
  );
};

export default Bio;
