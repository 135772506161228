import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import {createRoot} from 'react-dom/client';

import configureStore from 'redux/cofigureStore';
import {loadTokens} from 'redux/localStorage';
import Root from './Root';

import * as serviceWorker from './serviceWorker';
import {hasWildCardSubdomain, transformHostnameToPath} from 'mui-core/utils';

// redirect from wildcard subdomain  to path
if (hasWildCardSubdomain()) {
  const newPath = transformHostnameToPath();
  window.location.href = newPath;
}
//Setup initial state
const initialAuth = loadTokens();
const persistedState = initialAuth
  ? {
      auth: initialAuth,
    }
  : window.__INITIAL_STATE__;

const store = configureStore(persistedState);

const root = createRoot(document.querySelector('#root'));

root.render(<Root store={store} />);

serviceWorker.register();
