import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {default as MUIButton} from '@mui/material/Button';

const Button = props => {
  const {loading, children, ...restProps} = props;
  return (
    <>
      {loading ? (
        <LoadingButton loading loadingPosition="start" {...restProps}>
          {children}
        </LoadingButton>
      ) : (
        <MUIButton {...restProps}>{children}</MUIButton>
      )}
    </>
  );
};

Button.propTypes = {
  ...MUIButton.propTypes,
  ...LoadingButton.propTypes,
};

export default Button;
