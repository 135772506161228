import React, {useState, useCallback} from 'react';
import {Select, Icon, Form, Row, Col, Input, Button, message} from 'antd';
import uniqBy from 'lodash/uniqBy';
import debounce from 'lodash/debounce';
import {isString, sortArrayOfObjPropertyBased, CustomSelect} from 'core';
import {addSkill, editSkill} from 'redux/modules/courseManagement';
import './style.css';
import {connect} from 'react-redux';
import {API, JWT_HEADER, JSON_HEADER} from 'config/srm.config';
import {accessToken} from 'redux/modules/auth';

import {Services} from './../../services';

const services = new Services();

const {Option} = Select;
const FormItem = Form.Item;

class SkillSearch extends React.Component {
  init = false;
  state = {
    skillText: '',
    searchTerm: '',
    filterSkills: [],
    skillLoading: false,
    skillEditText: '',
    skillEdit: -1,
  };

  componentDidMount() {
    this.initDefaultOptions();
  }

  componentDidUpdate(prev) {
    if (this.props.skillsData !== prev.skillsData) {
      if (!this.init) {
        this.initDefaultOptions();
      }
    }
  }

  initDefaultOptions = () => {
    if (
      Array.isArray(this.props.skillsData) &&
      this.props.skillsData.length > 0
    ) {
      this.search('a');
      this.init = true;
    }
  };

  search = term => {
    const {skillsData} = this.props;
    if (skillsData && Array.isArray(skillsData) && skillsData.length) {
      const filteredSkills = skillsData.filter(skill => {
        let skillName,
          termName = '';
        if (skill && skill.name && isString(skill.name)) {
          skillName = skill.name.toLowerCase();
        }
        if (term && isString(term)) {
          termName = term.toLowerCase();
        }
        if (term.length <= 3) {
          if (skillName && skillName.startsWith(termName)) {
            return skill;
          }
        } else {
          if (skillName && skillName.includes(termName)) {
            return skill;
          }
        }
      });

      this.setState({
        filterSkills: uniqBy(
          sortArrayOfObjPropertyBased(filteredSkills, 'name'),
          'name'
        ),
        searchTerm: term.trim(),
      });
    }
  };

  addSkills = () => {
    const {skillText} = this.state;
    const {token, addSkill, institution_id} = this.props;
    if (skillText) {
      this.setState({skillLoading: true});
      const endPoint = `${API.course_management.skills}`;
      services
        .createUpdateRecord(
          token,
          endPoint,
          {name: skillText, institution_id},
          'POST'
        )
        .then(async res => {
          // await fetchSkills();
          if (!res.name) {
            message.error(res && res.Error ? res.Error : res);
            this.setState({skillLoading: false});
            return;
          }
          await addSkill(res);
          this.setState({skillLoading: false, skillText: ''}, () => {
            this.search(this.state.searchTerm);
          });
        })
        .catch(() => {
          this.setState({skillLoading: false});
        });
    }
  };

  editSkill = (name, uuid) => {
    this.setState({skillEdit: uuid, skillEditText: name});
  };

  onEditSkill = existName => {
    const {skillEdit, skillEditText} = this.state;
    if (existName === skillEditText) {
      this.setState({
        skillEditText: '',
        skillEdit: -1,
      });
      return;
    }
    const {token, editSkill, institution_id} = this.props;
    // const data = skillData.filter(i => i.uuid === skillEdit)[0];
    if (skillEdit && skillEditText) {
      const endPoint = `${API.course_management.skills}${skillEdit}`;
      this.setState({skillLoading: true});
      services
        .createUpdateRecord(
          token,
          endPoint,
          {name: skillEditText, institution_id},
          'PATCH'
        )
        .then(async res => {
          // await fetchSkills();
          // await fetchSkills();
          await editSkill(res, skillEdit);
          this.setState(
            {
              skillEditText: '',
              skillEdit: -1,
              skillLoading: false,
            },
            () => {
              this.search(this.state.searchTerm);
            }
          );
        })
        .catch(() => {
          this.setState({skillLoading: false});
        });
    }
  };

  debounceSearch = debounce(this.search, 800);

  searchSkills = value => {
    if (value) {
      this.debounceSearch(value);
    }
  };
  render() {
    const {
      initialValue,
      getFieldDecorator,
      formItemLayout,
      decoratorKey,
      label,
      skillsData,
      addSkill,
      token,
      access,
      multiple,
      institution_id,
    } = this.props;
    const {filterSkills, searchTerm} = this.state;
    // const bp_id = access && access['custom:institution_uuid'];
    return (
      <FormItem label={label} colon={false} {...formItemLayout}>
        <div className="clearfix">
          {getFieldDecorator(decoratorKey, {
            rules: [
              // {
              //   required: true,
              //   message: 'Skills can not be empty',
              // },
            ],
            initialValue,
          })(
            <CustomSelect.Select
              onSearch={this.searchSkills}
              showArrow={false}
              loading={this.state.skillLoading}
              showSearch
              multiple
              footer={
                <Row type="flex" gutter={30} className="add-award-c">
                  <Col className="i-c">
                    <div>
                      <Input
                        onChange={e =>
                          this.setState({skillText: e.target.value})
                        }
                        value={this.state.skillText}
                      />
                    </div>
                  </Col>
                  <Col className="b-c">
                    <Button
                      //   disabled={!this.state.awardType || existAwardType}
                      size={'small'}
                      type="link"
                      onClick={this.addSkills}>
                      + Add Item
                    </Button>
                  </Col>
                </Row>
              }
              style={{width: '100%'}}
              placeholder={'Search'}
              notFoundContent={searchTerm ? 'no matches for search query' : ''}>
              {filterSkills && filterSkills.length > 0
                ? filterSkills.map(({name, uuid, institution_uuid}) => (
                    <CustomSelect.Option
                      renderInSelect={name}
                      key={uuid}
                      value={name}
                      title={name}>
                      {this.state.skillEdit === uuid ? (
                        <div
                          className="ac-o-list-edit"
                          onClick={e => e.stopPropagation()}>
                          <Input
                            onChange={e =>
                              this.setState({
                                skillEditText: e.target.value,
                              })
                            }
                            suffix={
                              <Icon
                                type="check-circle"
                                onClick={() => this.onEditSkill(name)}
                              />
                            }
                            value={this.state.skillEditText}
                          />
                        </div>
                      ) : (
                        <div className="ac-o-list">
                          <span>{name}</span>
                          {institution_uuid === institution_id && (
                            <Icon
                              type="edit"
                              onClick={e => {
                                e.stopPropagation();
                                this.editSkill(name, uuid);
                              }}
                            />
                          )}
                        </div>
                      )}
                    </CustomSelect.Option>
                  ))
                : []}
            </CustomSelect.Select>
          )}
        </div>
      </FormItem>
    );
  }
}

// = ({
//   initialValue,
//   getFieldDecorator,
//   formItemLayout,
//   decoratorKey,
//   label,
//   skillsData,
//   addSkill,
//   token,
// }) => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [filterSkills, setFilterSkills] = useState([]);
//   const [skillText, setSkillText] = useState('');
//   const [skillLoading, setSkillLoading] = useState(false);

//   const search = term => {
//     if (skillsData && Array.isArray(skillsData) && skillsData.length) {
//       const filteredSkills = skillsData.filter(skill => {
//         if (term.length <= 3) {
//           if (skill.name.toLowerCase().startsWith(term.toLowerCase())) {
//             return skill;
//           }
//         } else {
//           if (skill.name.toLowerCase().includes(term.toLowerCase())) {
//             return skill;
//           }
//         }
//       });
//       setFilterSkills(
//         uniqBy(
//           sortArrayOfObjPropertyBased(filteredSkills, 'name').slice(0, 21),
//           'name'
//         )
//       );
//       setSearchTerm(term.trim());
//     }
//   };

//   const addSkills = () => {
//     if (skillText) {
//       setSkillLoading(true);
//       const endPoint = `${API.course_management.skills}`;
//       services
//         .createUpdateRecord(token, endPoint, {name: skillText}, 'POST')
//         .then(async res => {
//           // await fetchSkills();
//           await addSkill(res);
//           await setSkillLoading(false);
//           await setSkillText('');
//           setTimeout(() => {
//             search(searchTerm);
//           }, 800);
//         })
//         .catch(() => {
//           setSkillLoading(false);
//         });
//     }
//   };

//   const debounceSearch = debounce(search, 800);

//   const debouncedSearch = value => {
//     if (value) {
//       debounceSearch(value);
//     }
//   };
//   return (
//     <FormItem label={label} colon={false} {...formItemLayout}>
//       <div className="clearfix">
//         {getFieldDecorator(decoratorKey, {
//           rules: [
//             {
//               required: true,
//               message: 'Skills can not be empty',
//             },
//           ],
//           initialValue,
//         })(
//           <CustomSelect.Select
//             onSearch={debouncedSearch}
//             showArrow={false}
//             showSearch
//             footer={
//               <Row type="flex" gutter={30} className="add-award-c">
//                 <Col className="i-c">
//                   <div>
//                     <Input
//                       onChange={e => setSkillText(e.target.value)}
//                       value={skillText}
//                     />
//                   </div>
//                 </Col>
//                 <Col className="b-c">
//                   <Button
//                     //   disabled={!this.state.awardType || existAwardType}
//                     type="link"
//                     onClick={addSkills}>
//                     + Add Item
//                   </Button>
//                 </Col>
//               </Row>
//             }
//             style={{minWidth: 300, width: '100%'}}
//             placeholder={'Search'}
//             notFoundContent={searchTerm ? 'no matches for search query' : ''}>
//             {filterSkills && filterSkills.length > 0
//               ? filterSkills.map(({name, uuid}) => (
//                   <CustomSelect.Option key={uuid} value={name} title={name}>
//                     {name}
//                   </CustomSelect.Option>
//                 ))
//               : []}
//           </CustomSelect.Select>
//         )}
//       </div>
//     </FormItem>
//   );
// };

const mapStateToProps = state => ({
  token: accessToken(state),
  access: state.auth.access,
  institution_id: state.auth.institution_id,
});

export default connect(
  mapStateToProps,
  {
    editSkill,
    addSkill,
  }
)(SkillSearch);
