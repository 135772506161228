import * as TYPES from './constants';
import {
  requestBusinessPartnerStudentProfiles,
  receiveBusinessPartnerStudentProfiles,
  handleBusinessPartnerStudentProfilesError,
  updateBusinessPartnerStudentProfilesFilterStatus,
} from './actions';

import {JWT_HEADER, JSON_HEADER} from 'config/srm.config';
import {API} from 'config/businesspartner.config';

const initialState = {
  data: null,
  request: null,
  error: null,
  applyFilter: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_BUSINESS_PARTNER_STUDENT_PROFILES:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_BUSINESS_PARTNER_STUDENT_PROFILES:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.BUSINESS_PARTNER_STUDENT_PROFILES_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.UPDATE_BUSINESS_PARTNER_STUDENT_PROFILES_FILTER_STATUS:
      return {
        ...state,
        applyFilter: action.payload,
      };
    default:
      return state;
  }
};

export const fetchBusinessPartnerStudentProfiles = (filter, applyFilter) => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
    businessPartnerAdmin: {companyProfile: {business_partner_id} = {}} = {},
  } = getState();

  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  let queryParams = '';
  const {program, states, school} = filter || {};
  program &&
    program.length > 0 &&
    program.forEach(pgm => {
      queryParams += 'program=' + pgm.uuid + '&';
    });
  states &&
    states.length > 0 &&
    states.forEach(state => {
      queryParams += 'location=' + state.name + '&';
    });
  school &&
    school.length > 0 &&
    school.forEach(sc => {
      queryParams += 'school=' + sc.institution_id + '&';
    });
  if (queryParams) queryParams = '&' + queryParams.slice(0, -1);
  if (applyFilter) {
    dispatch(updateBusinessPartnerStudentProfilesFilterStatus(true));
  }
  dispatch(requestBusinessPartnerStudentProfiles());
  const URI = `${API.administration.student_profiles +
    '?bp_uuid=' +
    business_partner_id +
    queryParams}`;
  //const enURI = encodeURI(URI);

  try {
    return fetch(URI, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveBusinessPartnerStudentProfiles(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleBusinessPartnerStudentProfilesError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleBusinessPartnerStudentProfilesError(err));
  }
};

export const fetchBusinessPartnerStudentExchangeData = (
  filter,
  applyFilter,
  sortBy = 'recent',
  page = 1
) => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
    // businessPartnerAdmin: {companyProfile: {business_partner_id} = {}} = {},
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  let queryParams = '';
  const {
    // program,
    // states,
    // school,
    // occupation,
    skills,
    locations,
    award_types,
    educators,
  } = filter || {};
  const default_page_size = 8;
  // program &&
  //   program.length > 0 &&
  //   program.forEach(pgm => {
  //     queryParams += 'pos=' + encodeURIComponent(pgm.name) + '&';
  //   });
  skills &&
    skills.length > 0 &&
    skills.forEach(skill => {
      queryParams += 'skill=' + encodeURIComponent(skill.name) + '&';
    });
  // occupation &&
  //   occupation.length > 0 &&
  //   occupation.forEach(occ => {
  //     queryParams += 'occupation=' + encodeURIComponent(occ.name) + '&';
  //   });
  locations &&
    locations.length > 0 &&
    locations.forEach(loc => {
      queryParams +=
        'locations=' +
        encodeURIComponent(loc.city) +
        ',' +
        encodeURIComponent(loc.state) +
        '&';
    });
  award_types &&
    award_types.length > 0 &&
    award_types.forEach(award => {
      queryParams += 'award_types=' + encodeURIComponent(award.uuid) + '&';
    });
  educators &&
    educators.length > 0 &&
    educators.forEach(edu => {
      queryParams +=
        'educators=' +
        encodeURIComponent(edu.institution_id ? edu.institution_id : edu.name) +
        '&';
    });
  // states &&
  //   states.length > 0 &&
  //   states.forEach(state => {
  //     queryParams += 'location=' + state.name + '&';
  //   });
  // school &&
  //   school.length > 0 &&
  //   school.forEach(sc => {
  //     queryParams += 'school=' + sc.institution_id + '&';
  //   });
  if (queryParams) queryParams = '&' + queryParams.slice(0, -1);
  if (applyFilter) {
    dispatch(updateBusinessPartnerStudentProfilesFilterStatus(true));
  }
  dispatch(requestBusinessPartnerStudentProfiles());
  const URI = `${API.administration.student_exchange_data +
    '?page_size=' +
    default_page_size +
    '&page=' +
    page +
    '&sort=' +
    sortBy +
    queryParams}`;
  //const enURI = encodeURI(URI);
  try {
    return fetch(URI, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveBusinessPartnerStudentProfiles(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleBusinessPartnerStudentProfilesError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleBusinessPartnerStudentProfilesError(err));
  }
};
