import * as TYPES from './constants';
import {
  requestGenedPaths,
  handleGenedPathsError,
  receiveGenedPaths,
} from './actions';
import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_GENED_PATHS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECIEVE_GENED_PATHS:
      return {
        ...state,
        data: action.payload,
      };
    case TYPES.GENNED_PATHS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchGenedPaths = () => (dispatch, getState) => {
  const state = getState();
  let accessToken = null;
  if (state && state.auth) {
    const {
      auth: {
        access: {token},
      },
    } = state;
    accessToken = token;
  }

  const config = {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: Object.assign({}, JSON_HEADER, JWT_HEADER(accessToken)),
  };

  dispatch(requestGenedPaths());

  try {
    return fetch(`${API.pathway.general_education_path}`, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveGenedPaths(json));
      })
      .catch(err => {
        dispatch(handleGenedPathsError(err));
      });
  } catch (err) {
    dispatch(handleGenedPathsError(err));
  }
};
