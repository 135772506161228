import * as TYPES from './constants';

// get Employer Profile
export const requestEmployerProfile = () => ({
  type: TYPES.REQUEST_EMPLOYER_PROFILE,
});
export const receiveEmployerProfile = json => ({
  type: TYPES.RECEIVE_EMPLOYER_PROFILE,
  payload: json,
});
export const handleEmployerProfileError = err => ({
  type: TYPES.EMPLOYER_PROFILE_ERROR,
  err,
});
export const toggleEmployerProfile = data => ({
  type: TYPES.TOGGLE_EMPLOYER_PROFILE,
  payload: data,
});

// External companies i.e. careeronestop companies
export const requestCareerOneStopCompanies = () => ({
  type: TYPES.REQUEST_CAREER_ONE_STOP_COMPANIES,
});
export const receivedCareerOneStopCompanies = json => ({
  type: TYPES.RECEIVED_CAREER_ONE_STOP_COMPANIES,
  payload: json,
});
export const careerOneStopCompaniesError = () => ({
  type: TYPES.CAREER_ONE_STOP_COMPANIES_ERROR,
});
export const clearCareerOneStopCompanies = () => ({
  type: TYPES.CAREER_ONE_STOP_COMPANIES_CLEAR,
});

// get Dashboard Metrics
export const requestEmployerDashboardMetrics = () => ({
  type: TYPES.REQUEST_EMPLOYER_DASHBOARD_METRICS,
});
export const receiveEmployerDashboardMetrics = json => ({
  type: TYPES.RECEIVE_EMPLOYER_DASHBOARD_METRICS,
  payload: json,
});
export const handleEmployerDashboardMetricsError = err => ({
  type: TYPES.EMPLOYER_DASHBOARD_ERROR_METRICS,
  err,
});
