import React from 'react';

import {CustomCollapse, CustomTabs, Stats} from 'core';

import PanelHOC from './panelHOC';
import TimelineContainer from './timelineContainer';

/**
const statsData = [
  {
    title: 'Business Partners',
    count: 14,
    color: 'green',
  },
  {
    title: 'Total Internships',
    count: 23,
    color: 'orange',
  },
  {
    title: 'Total Apprenticeships',
    count: 23,
    color: 'brown',
  },
  {
    title: 'Total Jobs',
    count: 15,
    color: 'fun-blue',
  },
  {
    title: 'Total Events',
    count: 36,
    color: 'blue',
  },
];

const StudentStatsData = [
  {
    title: 'Job Applications',
    count: 14,
    color: 'green',
    insideColWidth: 4,
  },
  {
    title: 'Internship Applications',
    count: 23,
    color: 'orange',
  },
  {
    title: 'Apprenticeship Applications',
    count: 23,
    color: 'brown',
  },
];
*/

const graphPointStyling = {
  pointBackgroundColor: '#ffffff',
  pointRadius: 5,
  pointHoverRadius: 6,
};

const STATIC_WORKFORCE_TIMELINE_DATA = {
  labels: [],
  datasets: [
    {
      key: 'business_partners',
      label: 'Business Partners',
      data: [],
      borderColor: 'rgb(26, 178, 102)',
      backgroundColor: 'rgb(26, 178, 102)',
      pointHoverBackgroundColor: 'rgb(26, 178, 102)',
      ...graphPointStyling,
    },
    {
      key: 'total_internships',
      label: 'Total Internships',
      data: [],
      borderColor: 'rgb(250, 180, 10)',
      backgroundColor: 'rgb(250, 180, 10)',
      pointHoverBackgroundColor: 'rgb(250, 180, 10)',
      ...graphPointStyling,
    },
    {
      key: 'total_apprenticeships',
      label: 'Total Apperenticeships',
      data: [],
      borderColor: 'rgb(139, 87, 42)',
      backgroundColor: 'rgb(139, 87, 42)',
      pointHoverBackgroundColor: 'rgb(139, 87, 42)',
      ...graphPointStyling,
    },
    {
      key: 'jobs',
      label: 'Total Jobs',
      data: [],
      borderColor: 'rgb(42, 73, 139)',
      backgroundColor: 'rgb(42, 73, 139)',
      pointHoverBackgroundColor: 'rgb(42, 73, 139)',
      ...graphPointStyling,
    },
    {
      key: 'total_others',
      label: 'Total Others',
      data: [],
      borderColor: 'rgb(77 51 229)',
      backgroundColor: 'rgb(77 51 229)',
      pointHoverBackgroundColor: 'rgb(77 51 229)',
      ...graphPointStyling,
    },
    {
      key: 'total_events',
      label: 'Total Events',
      data: [],
      borderColor: 'rgb(72, 175, 247)',
      backgroundColor: 'rgb(72, 175, 247)',
      pointHoverBackgroundColor: 'rgb(72, 175, 247)',
      ...graphPointStyling,
    },
  ],
};
const STATIC_STUDENT_TIMELINE_DATA = {
  labels: [],
  datasets: [
    {
      key: 'job_applications',
      label: 'Job Applications',
      data: [],
      borderColor: 'rgb(26, 178, 102)',
      backgroundColor: 'rgb(26, 178, 102)',
      pointHoverBackgroundColor: 'rgb(26, 178, 102)',
      ...graphPointStyling,
    },
    {
      key: 'internship_applications',
      label: 'Internship Applications',
      data: [],
      borderColor: 'rgb(250, 180, 10)',
      backgroundColor: 'rgb(250, 180, 10)',
      pointHoverBackgroundColor: 'rgb(250, 180, 10)',
      ...graphPointStyling,
    },
    {
      key: 'apprenticeship_applications',
      label: 'Apprenticeship Applications',
      data: [],
      borderColor: 'rgb(139, 87, 42)',
      backgroundColor: 'rgb(139, 87, 42)',
      pointHoverBackgroundColor: 'rgb(139, 87, 42)',
      ...graphPointStyling,
    },
    {
      key: 'other_applications',
      label: 'Other Applications',
      data: [],
      borderColor: 'rgb(77 51 229)',
      backgroundColor: 'rgb(77 51 229)',
      pointHoverBackgroundColor: 'rgb(77 51 229)',
      ...graphPointStyling,
    },
  ],
};
/**
const WORKFORCE_DUMMY_DATA = {
  business_partners: {
    timeline: {
      2020: {
        Jan: 0,
        Feb: 10,
        Mar: 22,
        Apr: 25,
        May: 32,
        Jun: 34,
        Jul: 39,
        Aug: 42,
        Sep: 50,
        Oct: 55,
        Nov: 60,
        Dec: 62,
      },
    },
  },
  total_internships: {
    timeline: {
      2020: {
        Jan: 2,
        Feb: 12,
        Mar: 15,
        Apr: 18,
        May: 20,
        Jun: 25,
        Jul: 30,
        Aug: 33,
        Sep: 40,
        Oct: 35,
        Nov: 40,
        Dec: 45,
      },
    },
  },
  total_apprenticeships: {
    timeline: {
      2020: {
        Jan: 1,
        Feb: 18,
        Mar: 20,
        Apr: 30,
        May: 25,
        Jun: 40,
        Jul: 50,
        Aug: 55,
        Sep: 40,
        Oct: 43,
        Nov: 45,
        Dec: 50,
      },
    },
  },
  jobs: {
    timeline: {
      2020: {
        Jan: 0,
        Feb: 8,
        Mar: 15,
        Apr: 20,
        May: 23,
        Jun: 27,
        Jul: 30,
        Aug: 34,
        Sep: 37,
        Oct: 40,
        Nov: 45,
        Dec: 50,
      },
    },
  },
  total_events: {
    timeline: {
      2020: {
        Jan: 4,
        Feb: 10,
        Mar: 15,
        Apr: 12,
        May: 16,
        Jun: 20,
        Jul: 25,
        Aug: 29,
        Sep: 32,
        Oct: 25,
        Nov: 27,
        Dec: 30,
      },
    },
  },
};
const STUDENT_DUMMY_DATA = {
  job_applications: {
    timeline: {
      2020: {
        Jan: 0,
        Feb: 10,
        Mar: 22,
        Apr: 25,
        May: 32,
        Jun: 34,
        Jul: 39,
        Aug: 42,
        Sep: 50,
        Oct: 55,
        Nov: 60,
        Dec: 62,
      },
    },
  },
  internship_applications: {
    timeline: {
      2020: {
        Jan: 2,
        Feb: 12,
        Mar: 15,
        Apr: 18,
        May: 20,
        Jun: 25,
        Jul: 30,
        Aug: 33,
        Sep: 40,
        Oct: 35,
        Nov: 40,
        Dec: 45,
      },
    },
  },
  apprenticeship_applications: {
    timeline: {
      2020: {
        Jan: 1,
        Feb: 18,
        Mar: 20,
        Apr: 30,
        May: 25,
        Jun: 40,
        Jul: 50,
        Aug: 55,
        Sep: 40,
        Oct: 43,
        Nov: 45,
        Dec: 50,
      },
    },
  },
};
**/

const calculateWorkforceMetrics = data => {
  if (data && data.workforce_metrics) {
    const {
      business_partner,
      internships,
      apprenticeships,
      jobs_posted,
      others,
      events,
    } = data.workforce_metrics;
    return [
      {
        title: 'Business Partners',
        count: business_partner || 0,
        color: 'green',
      },
      {
        title: 'Total Internships',
        count: internships || 0,
        color: 'orange',
      },
      {
        title: 'Total Apprenticeships',
        count: apprenticeships || 0,
        color: 'brown',
      },
      {
        title: 'Total Jobs',
        count: jobs_posted || 0,
        color: 'fun-blue',
      },
      {
        title: 'Total Others',
        count: others || 0,
        color: 'violet-blue',
      },
      {
        title: 'Total Events',
        count: events || 0,
        color: 'blue',
      },
    ];
  }
  return [];
};

const calculateStudentWorkforceMetrics = data => {
  if (data && data.student_workforce_metrics) {
    const {
      job_application,
      internship_application,
      apprenticeship_application,
      other_application,
    } = data.student_workforce_metrics;
    return [
      {
        title: 'Job Applications',
        count: job_application || 0,
        color: 'green',
        insideColWidth: 4,
      },
      {
        title: 'Internship Applications',
        count: internship_application || 0,
        color: 'orange',
      },
      {
        title: 'Apprenticeship Applications',
        count: apprenticeship_application || 0,
        color: 'brown',
      },
      {
        title: 'Other Applications',
        count: other_application || 0,
        color: 'violet-blue',
      },
    ];
  }
  return [];
};

const prepareWorkforceTimeline = data => {
  if (data && data.workforce_timeline) {
    const {
      bp_timeline,
      internship_timeline,
      apprenticeship_timeline,
      jobs_timeline,
      other_timeline,
      events_timeline,
    } = data.workforce_timeline;
    return {
      business_partners: {
        timeline: bp_timeline || {},
      },
      total_internships: {
        timeline: internship_timeline || {},
      },
      total_apprenticeships: {
        timeline: apprenticeship_timeline || {},
      },
      jobs: {
        timeline: jobs_timeline || {},
      },
      total_others: {
        timeline: other_timeline || {},
      },
      total_events: {
        timeline: events_timeline || {},
      },
    };
  }
};
const prepareStudentWorkforceTimeline = data => {
  if (data && data.student_workforce_timeline) {
    const {
      job_application_timeline,
      internship_application_timeline,
      apprenticeship_application_timeline,
      other_application_timeline,
    } = data.student_workforce_timeline;
    return {
      job_applications: {
        timeline: job_application_timeline || {},
      },
      internship_applications: {
        timeline: internship_application_timeline || {},
      },
      apprenticeship_applications: {
        timeline: apprenticeship_application_timeline || {},
      },
      other_applications: {
        timeline: other_application_timeline || {},
      },
    };
  }
};
function WorkForce(props) {
  const {data: propData} = props;

  const WORK_FORCE_METRICS = calculateWorkforceMetrics(propData);
  const STUDENT_WORK_FORCE_METRICS = calculateStudentWorkforceMetrics(propData);
  const WORK_FORCE_TIMELINE = prepareWorkforceTimeline(propData);
  const STUDENT_WORK_FORCE_TIMELINE = prepareStudentWorkforceTimeline(propData);

  const TabsData = [
    {
      title: 'WORKFORCE METRICS',
      key: 'workforce_metrics',
      children: (
        <TimelineContainer
          data={WORK_FORCE_TIMELINE}
          staticData={STATIC_WORKFORCE_TIMELINE_DATA}
          withoutHeader
        />
      ),
    },
    {
      title: 'STUDENT METRICS',
      key: 'student_metrics',
      children: (
        <TimelineContainer
          data={STUDENT_WORK_FORCE_TIMELINE}
          staticData={STATIC_STUDENT_TIMELINE_DATA}
          withoutHeader
        />
      ),
    },
  ];
  const data = [
    {
      title: 'WORKFORCE',
      key: 'work_force',
      passData: '',
      childern: (
        <div className="content">
          <Stats
            data={WORK_FORCE_METRICS}
            headerTitle="Workforce Metrics"
            horizontal
          />
          <Stats
            data={STUDENT_WORK_FORCE_METRICS}
            colWidth={5}
            headerTitle="Student Metrics"
          />
          <CustomTabs
            data={TabsData}
            tabBarStyle={{
              height: '40px',
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <CustomCollapse
      data={data}
      switchPanel={props.switchPanel}
      activeKey={props.activeKey || 'work_force'}
    />
  );
}

export default PanelHOC(WorkForce);
