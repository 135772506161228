import * as TYPES from './constants';

// get Opportunity Details
export const requestEmployerOpportunityDetails = () => ({
  type: TYPES.REQUEST_EMPLOYER_OPPORTUNITY_DETAILS,
});
export const receiveEmployerOpportunityDetails = json => ({
  type: TYPES.RECEIVE_EMPLOYER_OPPORTUNITY_DETAILS,
  payload: json,
});
export const handleEmployerOpportunityDetailsError = err => ({
  type: TYPES.EMPLOYER_OPPORTUNITY_DETAILS_ERROR,
  err,
});
export const clearEmployerOpportunityDetails = () => ({
  type: TYPES.CLEAR_EMPLOYER_OPPORTUNITY_DETAILS,
});

// get Opportunity Applicants
export const requestEmployerOpportunityApplicants = () => ({
  type: TYPES.REQUEST_EMPLOYER_OPPORTUNITY_APPLICANTS,
});
export const receiveEmployerOpportunityApplicants = json => ({
  type: TYPES.RECEIVE_EMPLOYER_OPPORTUNITY_APPLICANTS,
  payload: json,
});
export const handleEmployerOpportunityApplicantsError = err => ({
  type: TYPES.EMPLOYER_OPPORTUNITY_APPLICANTS_ERROR,
  err,
});
export const clearEmployerOpportunityApplicants = () => ({
  type: TYPES.CLEAR_EMPLOYER_OPPORTUNITY_APPLICANTS,
});
