import React, {Component} from 'react';
import {
  Form,
  Select,
  Modal,
  Row,
  Col,
  Button,
  message,
  Icon,
  Input,
} from 'antd';
import {
  API,
  // AUTH_HEADER,
  JWT_HEADER,
  JSON_HEADER,
  ValidFieldsLength,
} from 'config/srm.config';
import {API as BP_API} from 'config/businesspartner.config';

import {
  //PATTERN,
  FormFieldCounter,
  // AsyncSearch,
  PageHeader,
  //Counter,
  TinyEditor,
} from 'core';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './Email.css';

const FormItem = Form.Item;
//const Option = Select.Option;

class Email extends Component {
  state = {
    selectedResults: {},
    disable: false,
    text: null,
    textLength: 0,
    errorShow: false,
  };
  goBack = () => {
    const {
      history,
      location: {state},
    } = this.props;
    if (state && state.previousPath && state.currentPage) {
      history.push(state.previousPath, {page: state.currentPage});
    } else if (
      state &&
      state.data &&
      state.data.event &&
      state.data.previousPath
    ) {
      history.push(state.data.previousPath, {
        event: state.data.event,
        activeTab: 'student_list',
      });
    } else {
      history.goBack();
    }
  };

  selectedResultsRecipients = key => {
    const {selectedResults} = this.state;
    const usersArray = Object.values(selectedResults);
    if (usersArray && usersArray[0] && usersArray[0].user_details) {
      //added for new schema change in user api, (uuid -> user_details.cognito_username)
      return usersArray.map(
        ({user_details}) => user_details && user_details.user_uuid
      );
    } else if (key === 'uuid') {
      return usersArray.map(({uuid}) => uuid);
    } else if (key === 'all') {
      return key;
    } else {
      return Object.keys(this.state.selectedResults);
    }
  };

  postData = async formData => {
    const {token, data, uri, recipientsKey} = this.props.location.state;
    const {history} = this.props;
    const {audience} = this.state;
    let requestdata = {
      ...formData,
      recipients: this.selectedResultsRecipients(recipientsKey),
    };
    if (audience && audience.length) {
      requestdata['audience'] = [];
      audience.map(({account: {uuid}}) => {
        requestdata['audience'].push(uuid);
      });
    }

    if (data && data.previousPath) {
      requestdata = Object.assign(requestdata, {event: data.event.uuid});
      delete data.previousPath;
      delete data.event;
    }

    requestdata = data ? Object.assign(requestdata, data) : requestdata;

    try {
      const config = {
        method: 'POST',
        mode: 'cors',
        headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
        body: JSON.stringify(requestdata),
      };
      const response = await (await fetch(uri, config)).json();
      if (response.Success || response.success) {
        Modal.success({
          content: 'Message sent',
          okText: 'Continue',
          onOk: () => {
            this.goBack();
          },
        });
      } else {
        throw Error('Something went wrong with api, Try again later');
      }
    } catch (err) {
      if (err) {
        message.error(err.message);
        this.setState({
          disable: false,
        });
      }
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const {selectedResults} = this.state;
      if (!err && Object.keys(selectedResults).length) {
        this.setState(
          {
            disable: true,
          },
          () => this.postData(values)
        );
      }
    });
  };

  // handleSubmit = e => {
  //   e.preventDefault();
  //   const {text, textLength} = this.state;
  //   this.setState(
  //     {
  //       errorShow: textLength === 0 ? true : false,
  //     },
  //     () =>
  //       this.props.form.validateFields((err, values) => {
  //         const {selectedResults} = this.state;
  //         if (!err && Object.keys(selectedResults).length) {
  //           this.setState(
  //             {
  //               disable: true,
  //             },
  //             () => {
  //               values['text'] = text;
  //               this.postData(values);
  //             }
  //           );
  //         }
  //       })
  //   );
  // };

  async componentDidMount() {
    // TODO: check recipients contains srm_user
    const {recipients, data, token} = this.props.location.state || {};
    this.setState({
      selectedResults: recipients,
    });
    if (data && !data.event_not_required) {
      const config = {
        method: 'GET',
        mode: 'cors',
        headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
        cache: 'default',
      };
      try {
        if (data && data.event) {
          // const res = await (await fetch(
          //   `${API.outreach.staff_map}?event__uuid=${data.event.uuid}`,
          //   config
          // )).json();
          // this.setState({
          //   audience: res,
          // });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
  updateRecipients = obj => {
    const selectedResults = Object.assign({}, this.state.selectedResults);
    const selectedResultsSrmid = Object.keys(selectedResults);

    if (selectedResultsSrmid.includes(obj.uuid || obj.student_uuid)) {
      delete selectedResults[obj.uuid || obj.student_uuid];
    } else {
      selectedResults[obj.uuid || obj.student_uuid] = obj;
    }
    this.setState({
      selectedResults,
    });
  };
  onRecipientSearchResultSelect = obj => this.updateRecipients(obj);
  onRemoveRecipient = obj => this.updateRecipients(obj);

  onRemoveAudience = uuid => {
    const {audience} = this.state;
    const filterAudience =
      audience &&
      audience.filter(audi => {
        if (audi.uuid !== uuid) {
          return audi;
        }
      });
    this.setState({
      audience: filterAudience,
    });
  };

  onEditChange = (err, txt) => {};

  render() {
    const {form, location} = this.props;
    const {getFieldDecorator} = form;
    const {token, user_portal, recipientsKey} = location.state || {};
    const {title: titlelen, description: descLen} = ValidFieldsLength;
    const {
      selectedResults,
      disable,
      audience,
      text,
      textLength,
      errorShow,
      editorState,
    } = this.state;
    let URI = API.srm.search_user;
    if (user_portal === 'business_partner') {
      URI = BP_API.administration.business_partner_user;
    }

    const SelectedKeys =
      recipientsKey !== 'all' &&
      selectedResults &&
      Object.keys(selectedResults);

    return (
      <>
        <PageHeader pageTitle="Send email" />
        <div className="ant-col-16 boxshadow send-email">
          <Form className="sms-email-form" onSubmit={this.handleSubmit}>
            <div className="send-email-header">
              <Button onClick={this.goBack}>
                <Icon type="left" className="btn-back" /> BACK
              </Button>
              <button
                className="ant-btn ant-btn-primary email-send-button"
                type="submit"
                disabled={disable}>
                SEND
              </button>
            </div>
            <Row className="content-pad">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={20}
                className="send-detail-row table-form float-none margin-auto">
                <FormItem label="Email Subject">
                  {getFieldDecorator('title', {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please enter email subject.',
                      },
                    ],
                  })(
                    <FormFieldCounter
                      fieldname="title"
                      form={form}
                      fieldlen={70}
                    />
                  )}
                </FormItem>
                <FormItem label="Receipients">
                  {// iff recipientsKey is "all", show "all recipients"
                  recipientsKey === 'all' ? (
                    <Input disabled value={selectedResults} />
                  ) : (
                    <>
                      <Row className="data-table-header">
                        <Col span={8}>Name</Col>
                        <Col span={10}>Email</Col>
                        <Col span={6}>Mobile</Col>
                      </Row>
                      <div className="data-table-row-border data-table-scroll-300">
                        {SelectedKeys &&
                          SelectedKeys.map(item => {
                            const Name =
                              selectedResults[item].name ||
                              `${selectedResults[item].first_name ||
                                selectedResults[item]
                                  .student_first_name} ${selectedResults[item]
                                .last_name ||
                                selectedResults[item].student_last_name}`;
                            const Email =
                              selectedResults[item].email ||
                              selectedResults[item].student_email;
                            const Mobile =
                              selectedResults[item].mobile ||
                              selectedResults[item].student_mobile ||
                              selectedResults[item].phone_number;
                            return (
                              <Row
                                className="data-table-row"
                                key={
                                  selectedResults[item].uuid ||
                                  selectedResults[item].student_uuid
                                }>
                                <Col span={8}>
                                  <div className="text-ellipsis" title={Name}>
                                    {Name}
                                  </div>
                                </Col>
                                <Col span={10}>
                                  <div className="text-ellipsis" title={Email}>
                                    {Email}
                                  </div>
                                </Col>
                                <Col span={6}>
                                  <div className="text-ellipsis" title={Mobile}>
                                    {Mobile}
                                  </div>
                                </Col>
                                {SelectedKeys.length > 1 && (
                                  <Icon
                                    type="close"
                                    theme="outlined"
                                    onClick={() =>
                                      this.onRemoveRecipient(
                                        selectedResults[item]
                                      )
                                    }
                                  />
                                )}
                              </Row>
                            );
                          })}
                        {selectedResults &&
                          !Object.keys(selectedResults).length && (
                            <div className="error-msg">
                              Recipients cannot be empty, please select one.
                            </div>
                          )}
                      </div>
                    </>
                  )}
                </FormItem>

                {/* {(audience && audience.length && (
                  <FormItem label="Audience">
                    <div>
                      <div>
                        <Row className="data-table-header">
                          <Col span={8}>Name</Col>
                          <Col span={10}>Email</Col>
                          <Col span={6}>Mobile</Col>
                        </Row>
                      </div>
                      <div className="data-table-row-border data-table-scroll-300">
                        {audience.map(
                          ({
                            account: {
                              first_name,
                              last_name,
                              email,
                              phone_number,
                            },
                            uuid,
                          }) => {
                            const Name = `${first_name} ${last_name}`;
                            const Email = email;
                            const Mobile = phone_number;
                            return (
                              <Row className="data-table-row" key={uuid}>
                                <Col span={8}>
                                  <div className="text-ellipsis" title={Name}>
                                    {Name}
                                  </div>
                                </Col>
                                <Col span={10}>
                                  <div className="text-ellipsis" title={Email}>
                                    {Email}
                                  </div>
                                </Col>
                                <Col span={6}>
                                  <div className="text-ellipsis" title={Mobile}>
                                    {Mobile}
                                  </div>
                                </Col>
                                <Icon
                                  type="close"
                                  theme="outlined"
                                  onClick={() => this.onRemoveAudience(uuid)}
                                />
                              </Row>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </FormItem>
                )) ||
                  ''} */}
                {/* <AsyncSearch
                  ref={ref => (this.getAsyncSearchFunc = ref)}
                  uri={URI}
                  onResultSelect={this.onRecipientSearchResultSelect}
                  placeholder="Search only by name..."
                  token={token}
                  label="Add Recipient:"
                  initialResult={this.state.selectedResults}
                /> */}
                <FormItem label="Message Details">
                  {getFieldDecorator('text', {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please enter message body.',
                      },
                    ],
                  })(<TinyEditor maxLen={descLen} />)
                  // <FormFieldCounter
                  //   style={{maxWidth: 'none', width: '400px'}}
                  //   fieldname="text"
                  //   fieldlen={descLen}
                  //   textareamfield
                  //   form={form}
                  // />
                  }
                </FormItem>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default Form.create()(Email);
