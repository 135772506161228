export const REQUEST_STATES = 'REQUEST_STATES';
export const RECEIVE_STATES = 'RECEIVE_STATES';
export const STATES_ERROR = 'STATES_ERROR';

export const REQUEST_CONSORTIUM_STATES_DATA = 'REQUEST_CONSORTIUM_STATES_DATA';
export const RECEIVE_CONSORTIUM_STATES_DATA = 'RECEIVE_CONSORTIUM_STATES_DATA';
export const CONSORTIUM_STATES_DATA_ERROR = 'CONSORTIUM_STATES_DATA_ERROR';

export const REQUEST_COMPANY_TO_INSTITUTION_DATA =
  'REQUEST_COMPANY_TO_INSTITUTION_DATA';
export const RECEIVE_COMPANY_TO_INSTITUTION_DATA =
  'RECEIVE_COMPANY_TO_INSTITUTION_DATA';
export const COMPANY_TO_INSTITUTION_DATA_ERROR =
  'COMPANY_TO_INSTITUTION_DATA_ERROR';

// Tuition link
export const TUITION_LINK_ERROR = 'TUITION_LINK_ERROR';
export const REQUEST_TUITION_LINK = 'REQUEST_TUITION_LINK';
export const RECEIVE_TUITION_LINK = 'RECEIVE_TUITION_LINK';

// Location search
export const LOCATION_SEARCH_CLEAR = 'LOCATION_SEARCH_CLEAR';
export const LOCATION_SEARCH_ERROR = 'LOCATION_SEARCH_ERROR';
export const REQUEST_LOCATION_SEARCH = 'REQUEST_LOCATION_SEARCH';
export const RECEIVE_LOCATION_SEARCH = 'RECEIVE_LOCATION_SEARCH';

// linkedIn
export const LINKEDIN_DETAILS_REQUEST = 'LINKEDIN_DETAILS_REQUEST';
export const LINKEDIN_DETAILS_RECEIVE = 'LINKEDIN_DETAILS_RECEIVE';
export const LINKEDIN_DETAILS_ERROR = 'LINKEDIN_DETAILS_ERROR';
export const LINKEDIN_DETAILS_CLEAR = 'LINKEDIN_DETAILS_CLEAR';
