import React from 'react';
import {Menu, Icon} from 'antd';

const {ItemGroup, Item} = Menu;

const ProfileMenu = ({onMenuClick, data}) => (
  <Menu onClick={onMenuClick} className="profile-dropdown">
    <ItemGroup title={<h1 className="profile-title">Options</h1>}>
      {data &&
        data.map(({title, key}) => (
          <Item key={key}>
            {title}
            {key !== 'log_out' && <Icon className="arrow-right" type="right" />}
          </Item>
        ))}
    </ItemGroup>
  </Menu>
);

export default ProfileMenu;
