import * as TYPES from 'redux/types';
import {API} from 'config/srm.config';

const initialState = {
  request: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.RECEIVE_NOTIFICATIONS:
      return {
        ...state,
        data: action.payload,
        request: false,
      };
    case TYPES.REQUEST_NOTIFICATIONS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.CLEAR_NOTIFICATIONS:
      return {
        error: false,
        request: false,
        update: true,
      };

    case TYPES.REMOVE_NOTIFICATION:
      return {
        ...state,
        data: state.data.filter(item => item.uuid !== action.uuid),
      };

    default:
      return state;
  }
};

export const notificationSelector = ({notifications = {}}) => notifications;
export const notificationUpdateSelector = ({notifications: {update} = {}}) =>
  update;
export const notificationRequestSelector = ({notifications: {request} = {}}) =>
  request;
export const notificationRequestErrorSelector = ({
  notifications: {error} = {},
}) => error;

const receiveNotifications = json => ({
  type: TYPES.RECEIVE_NOTIFICATIONS,
  payload: json,
});
const requestNotificationsError = err => ({
  type: TYPES.REQUEST_NOTIFICATIONS_ERROR,
  err,
});

export const removeNotification = uuid => ({
  type: TYPES.REMOVE_NOTIFICATION,
  uuid,
});

export const clearAllNotifications = () => ({
  type: TYPES.CLEAR_NOTIFICATIONS,
});

export const fetchNotifications = () => ({
  type: TYPES.APIREQUEST,
  payload: {
    endpoint: `${API.outreach.notifications}`,
    method: 'GET',
    server: 'outreach',
    onsuccess: receiveNotifications,
    onerror: requestNotificationsError,
  },
});
