const monthsShortNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const defaultMonthsFilter = 3;

const modifyGraphData = data => {
  const obj = {};
  data &&
    Object.keys(data).forEach(key1 => {
      obj[key1] = {};
      const series = data[key1];
      Object.keys(series).forEach(key2 => {
        const years = series[key2];
        Object.keys(years).forEach(key3 => {
          const month = years[key3];
          const k = `${key3}-${key2}`;
          obj[key1][k] = month;
        });
      });
    });
  return obj;
};

function filterGraphSeries(data, month, date) {
  let currentMonth = parseInt(date.getMonth());
  let currentYear = parseInt(date.getFullYear());
  let labels = [];
  let seriesData = [];
  while (labels.length < month) {
    if (currentMonth === -1) {
      currentYear--;
      currentMonth = 11;
      continue;
    }
    const m = monthsShortNames[currentMonth];
    const label = `${m}-${currentYear}`;
    labels.push(label);
    seriesData.push(data[label] || 0);
    currentMonth--;
  }
  return {
    data: seriesData.reverse(),
    labels: labels.reverse(),
  };
}

export const filterGraphTimeLineData = (
  month = defaultMonthsFilter,
  data,
  staticData
) => {
  const date = new Date();
  let finalData = {labels: [], datasets: []};
  if (!data) {
    return finalData;
  }
  const graphData = modifyGraphData(data);
  if (staticData && Array.isArray(staticData)) {
    staticData.forEach(dt => {
      const series = graphData[dt.key];
      const seriesData = filterGraphSeries(series, month, date);
      if (seriesData.data && seriesData.labels) {
        dt.data = seriesData.data;
        dt.labels = seriesData.labels;
      }
    });
    finalData = [...staticData];
  }
  let labels = [];
  if (finalData && finalData.length > 0) {
    labels = finalData[0].labels;
  }
  return {
    datasets: finalData,
    labels,
  };
};
