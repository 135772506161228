import React from 'react';
import {View, StyleSheet} from '@react-pdf/renderer';

import Bio from './Bio';
import Skills from './Skills';
import Awards from './Awards';
import WorkExperience from './WorkExperience';

const styles = StyleSheet.create({
  block: {
    width: '70%',
    paddingLeft: 6,
    textAlign: 'left',
    flexDirection: 'column',
  },
  header: {
    padding: 6,
    paddingLeft: 8,
    width: '100%',
    fontSize: 12,
    borderWidth: 1,
    borderRadius: 8,
    textAlign: 'left',
    borderStyle: 'solid',
    borderColor: '#4abaed',
    // borderColor: '#EAEDF1',
  },
  content: {
    paddingLeft: 8,
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 10,
    color: '#000',
    // height: 'auto',
  },
  inline: {
    marginBottom: 4,
  },
  label: {
    fontSize: 14,
    color: '#000',
    marginBottom: 10,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  value: {
    fontSize: 9,
    color: '#000',
    textAlign: 'left',
    fontWeight: 'normal',
    lineHeight: 1.2,
  },
  boldLabel: {
    width: '100%',
    fontSize: 10,
    marginBottom: 4,
    textAlign: 'left',
    fontWeight: 'bold',
  },
  bulletList: {
    paddingLeft: 8,
    marginTop: 4,
    marginBottom: 4,
  },
  bulletItems: {
    fontSize: 8,
    lineHeight: 1.2,
    color: '#000',
    textAlign: 'left',
    fontWeight: 'normal',
    marginLeft: 2,
    marginBottom: 4,
  },
  SkillsList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

export default ({studentProfile, awardTypes}) => (
  <View style={styles.block}>
    {studentProfile.bio && (
      <Bio styles={styles} studentProfile={studentProfile} />
    )}
    {studentProfile.skills &&
      Array.isArray(studentProfile.skills) &&
      studentProfile.skills.length > 0 && (
        <Skills styles={styles} studentProfile={studentProfile} />
      )}
    {studentProfile.work_exp &&
      Array.isArray(studentProfile.work_exp) &&
      studentProfile.work_exp.length > 0 && (
        <WorkExperience styles={styles} studentProfile={studentProfile} />
      )}
    {studentProfile.awards_leadership_projects_publications &&
      Array.isArray(studentProfile.awards_leadership_projects_publications) &&
      studentProfile.awards_leadership_projects_publications.length > 0 && (
        <Awards
          styles={styles}
          awardTypes={awardTypes}
          studentProfile={studentProfile}
        />
      )}
  </View>
);
