import React from 'react';
import {Page, Document, StyleSheet, View} from '@react-pdf/renderer';
import {ErrorBoundary} from 'core/components';

import Header from './components/Header';
import Banner from './components/Banner';
import OverviewPanel from './components/OverviewPanel';
import ContentPanel from './components/ContentPanel';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    backgroundColor: '#ffffff',
    height: '100%',
    paddingTop: 15,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
  },
  doc: {
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
    paddingBottom: 4,
    paddingLeft: 20,
    paddingRight: 20,
    flex: '1',
  },
  container: {
    flexDirection: 'row',
  },
  sidePanel: {
    paddingTop: 15,
    paddingBottom: 12,
    marginRight: 5,
  },
  contentPanel: {
    width: '70%',
    marginLeft: 5,
  },
});

const MyProfileDocument = ({studentProfile, pathInfo, awardTypes}) => {
  const {first_name = '', last_name = ''} =
    studentProfile.student_details || {};

  return (
    <ErrorBoundary nameOfComponent="core-pdf-document">
      <Document title={`${first_name} ${last_name}`}>
        <Page size="A4" wrap style={styles.page}>
          <View style={styles.doc}>
            <Header />
            {studentProfile && pathInfo && awardTypes && (
              <>
                <Banner studentProfile={studentProfile} pathInfo={pathInfo} />
                <OverviewPanel
                  style={styles.sidePanel}
                  studentProfile={studentProfile}
                />
                <ContentPanel
                  // pathInfo={pathInfo}
                  awardTypes={awardTypes}
                  // studentInfo={studentInfo}
                  studentProfile={studentProfile}
                />
              </>
            )}
          </View>
        </Page>
      </Document>
    </ErrorBoundary>
  );
};

export default MyProfileDocument;
