import React from 'react';
import {Image, View, StyleSheet} from '@react-pdf/renderer';
// import {getLogo} from 'core/utils';
import {IMG_CLOUDINARY} from './../../../../config/srm.config';
const styles = StyleSheet.create({
  header: {
    backgroundColor: '#FFF',
    flexDirection: 'row',
    maxHeight: '60px',
    width: '100%',
    marginBottom: 10,
  },
  leftColumn: {
    width: '30%',
  },
});

export default () => {
  const getLogo = (publicID, ext = 'jpg') =>
    `${IMG_CLOUDINARY}/q_60/d_defaultlogoimage_zot4ua.svg/${publicID}.${ext}`;

  return (
    <View style={styles.header}>
      <View style={styles.leftColumn}>
        <Image
          style={styles.image}
          source={{
            uri: `${getLogo(
              'gps-graphics/goeducatelogo_orignal_k3rxvx',
              'png'
            )}?noCache=${Math.random().toString()}`,
            method: 'GET',
            header: {
              'Access-Control-Allow-Origin': '*',
              'Cache-Control': 'no-cache',
            },
          }}
        />
      </View>
    </View>
  );
};
