import * as TYPES from './constants';
import {
  requestLoggedInInstitute,
  receiveLoggedInInstitute,
  handleLoggedInInstituteError,
} from './actions';

import {getLoggedInstituteUUID} from 'redux/modules/auth';

import {Services} from 'core';

import {API} from 'config/reporter.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_LOGGED_IN_INSTITUTE:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_LOGGED_IN_INSTITUTE:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.LOGGED_IN_INSTITUTE_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchLoggedInInstitute = () => (dispatch, getState) => {
  const {
    auth: {access = {}, access: {token = null} = {}},
  } = getState();

  dispatch(requestLoggedInInstitute());
  const ID = getLoggedInstituteUUID(getState());
  let endpoint = `${API.admin.institute}${ID}/`;
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receiveLoggedInInstitute(res));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleLoggedInInstituteError(err));
  }
};
