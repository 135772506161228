import {combineReducers} from 'redux';

import bp_users from './users';
import companyProfile from './company';
import jobs from './jobs';
import programs from './program';
import dashboard from './dashboard';
import industry from './industry';
import occupations from './occupations';
import opportunityDetails from './opportunityDetails';
import studentProfiles from './studentProfiles';
import studentProfile from './studentProfile';
import skills from './skills';
import studentSkills from './studentSkills';
import studentAwardTypes from '../../../studentManagement/studentAwardTypes';
import studentProfileData from '../../../studentManagement/studentProfileData';
import profile from '../../../studentManagement/studentProfile';
import studentExchangePrograms from './studentExchangePrograms';
import studentExchangeOccupations from './occupations';
import qualifyingPrograms from './qualifyingPrograms';
import opportunities from './opportunities';
import careerOneStopCompanies from './careerOneStopCompanies';
import studentAchievementsOptions from './studentAchievementsOptions';

export const businessPartnerAdmin = combineReducers({
  users: bp_users,
  companyProfile,
  jobs,
  programs,
  dashboard,
  industry,
  occupations,
  opportunityDetails,
  studentProfiles,
  studentProfile,
  skills,
  studentSkills,
  studentAwardTypes,
  studentProfileData,
  profile,
  studentExchangePrograms,
  studentExchangeOccupations,
  qualifyingPrograms,
  opportunities,
  careerOneStopCompanies,
  studentAchievementsOptions,
});
