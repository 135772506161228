import * as TYPES from './constants';

//SRM Super User License
export const receiveSuperUserLicense = json => ({
  type: TYPES.RECEIVE_SUPER_USER_LICENSE,
  payload: json,
});

export const requestSuperUserLicense = () => ({
  type: TYPES.REQUEST_SUPER_USER_LICENSE,
});

export const handleSuperUserLicenseError = err => ({
  type: TYPES.SUPER_USER_LICENSE_ERROR,
  err,
});

//SRM Super User Institutions List
export const receiveSuperUserQuikFyndData = json => ({
  type: TYPES.RECEIVE_SUPER_USER_QUIKFYND_DATA,
  payload: json,
});

export const requestSuperUserQuikFyndData = () => ({
  type: TYPES.REQUEST_SUPER_USER_QUIKFYND_DATA,
});

export const handleSuperUserQuikFyndDataError = err => ({
  type: TYPES.SUPER_USER_QUIKFYND_DATA_ERROR,
  err,
});

//SRM Super User Institution Types
export const receiveSuperUserIstitutionTypes = json => ({
  type: TYPES.RECEIVE_SUPER_USER_INSTITUTION_TYPES,
  payload: json,
});

export const requestSuperUserIstitutionTypes = () => ({
  type: TYPES.REQUEST_SUPER_USER_INSTITUTION_TYPES,
});

export const handleSuperUserIstitutionTypesError = err => ({
  type: TYPES.SUPER_USER_INSTITUTION_TYPES_ERROR,
  err,
});

//SRM Super User Institutions
export const receiveSuperUserIstitutions = json => ({
  type: TYPES.RECEIVE_SUPER_USER_INSTITUTIONS,
  payload: json,
});

export const requestSuperUserIstitutions = () => ({
  type: TYPES.REQUEST_SUPER_USER_INSTITUTIONS,
});

export const handleSuperUserIstitutionsError = err => ({
  type: TYPES.SUPER_USER_INSTITUTIONS_ERROR,
  err,
});

//SRM Super User Institute Details
export const receiveSuperUserInstituteDetails = json => ({
  type: TYPES.RECEIVE_SUPER_USER_INSTITUTE_DETAILS,
  payload: json,
});

export const requestSuperUserInstituteDetails = () => ({
  type: TYPES.REQUEST_SUPER_USER_INSTITUTE_DETAILS,
});

export const handleSuperUserInstituteDetailsError = err => ({
  type: TYPES.SUPER_USER_INSTITUTE_DETAILS_ERROR,
  err,
});

export const clearSuperUserInstituteDetails = () => ({
  type: TYPES.CLEAR_SUPER_USER_INSTITUTE_DETAILS,
});

//SRM Super User Institutes DetailsTimeline
export const receiveSuperUserInstitutesTimeline = json => ({
  type: TYPES.RECEIVE_SUPER_USER_INSTITUTES_TIMELINE,
  payload: json,
});

export const requestSuperUserInstitutesTimeline = () => ({
  type: TYPES.REQUEST_SUPER_USER_INSTITUTES_TIMELINE,
});

export const handleSuperUserInstitutesTimelineError = err => ({
  type: TYPES.SUPER_USER_INSTITUTES_TIMELINE_ERROR,
  err,
});

// SRM SUPER USER PARTNERS REQUESTS AND RESPONSES
export const receivePartnersRequests = json => ({
  type: TYPES.RECEIVE_PARTNERS_REQUESTS,
  payload: json,
});

export const receiveAllPartnersRequests = json => ({
  type: TYPES.RECEIVE_ALL_PARTNERS_REQUESTS,
  payload: json,
});

export const requestPartnersRequests = () => ({
  type: TYPES.REQUEST_PARTNERS_REQUESTS,
});

export const handlePartnersRequestsErrors = err => ({
  type: TYPES.PARTNERS_REQUESTS_ERROR,
  err,
});

export const clearPartnersRequests = () => ({
  type: TYPES.CLEAR_PARTNERS_REQUESTS,
});

// Banner instances

export const receiveBanerInstances = json => ({
  type: TYPES.RECEIVE_BANNER_INSTANCES,
  payload: json,
});

export const requestBanerInstances = () => ({
  type: TYPES.REQUEST_BANNER_INSTANCES,
});

export const handleBanerInstancesError = err => ({
  type: TYPES.BANNER_INSTANCES_ERROR,
  err,
});

// MSA Locations

export const receiveMSALocations = json => ({
  type: TYPES.RECEIVE_MSA_LOCATIONS,
  payload: json,
});

export const requestMSALocations = () => ({
  type: TYPES.REQUEST_MSA_LOCATIONS,
});

export const handleMSALocationsError = err => ({
  type: TYPES.MSA_LOCATIONS_ERROR,
  err,
});

//SRM Super User Institute Types
export const receiveSuperUserInstituteTypes = json => ({
  type: TYPES.RECEIVE_SUPER_USER_INSTITUTE_TYPES,
  payload: json,
});
export const requestSuperUserInstituteTypes = () => ({
  type: TYPES.REQUEST_SUPER_USER_INSTITUTE_TYPES,
});
export const handleSuperUserInstituteTypesError = err => ({
  type: TYPES.SUPER_USER_INSTITUTE_TYPES_ERROR,
  err,
});

export const superInstituteTypesClear = () => ({
  type: TYPES.SUPER_USER_INSTITUTE_TYPES_CLEAR,
});

//SRM Unique membership code
export const receiveUniqueMembershipCodeCheck = json => ({
  type: TYPES.RECEIVE_UNIQUE_MEMBERSHIP_CODE,
  payload: json,
});
export const requestUniqueMembershipCodeCheck = () => ({
  type: TYPES.REQUEST_UNIQUE_MEMBERSHIP_CODE,
});
export const uniqueMembershipCodeCheckError = err => ({
  type: TYPES.UNIQUE_MEMBERSHIP_CODE_ERROR,
  err,
});
