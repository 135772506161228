import * as TYPES from './constants';
import {
  requestBusinessPartnerProfile,
  receiveBusinessPartnerProfile,
  handleBusinessPartnerProfileError,
  toggleCompanyProfile,
  setStep,
} from './actions';

import {getLoggedInstituteUUID} from 'redux/modules/auth';

import {JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import {API} from 'config/businesspartner.config';

const initialState = {
  createCompanyProfile: undefined,
  currentStep: 0,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_BUSINESS_PARTNER_PROFILE:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_BUSINESS_PARTNER_PROFILE:
      return {
        ...state,
        ...action.payload[0],
        createCompanyProfile:
          action.payload[0] && !action.payload[0].industry_sector_name
            ? // !action.payload[0].email_id ||
              // !action.payload[0].physical_address_details ||
              // !action.payload[0].physical_address_details.address_line1 ||
              // !action.payload[0].physical_address_details.city ||
              // !action.payload[0].physical_address_details.state ||
              // !action.payload[0].physical_address_details.zipcode)
              true
            : false,
        request: false,
        error: false,
      };
    case TYPES.GO_TO_NEXT:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case TYPES.GO_TO_PREVIOUS:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case TYPES.RESET_STEP:
      return {
        ...state,
        currentStep: 0,
      };
    case TYPES.SET_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case TYPES.TOGGLE_COMPANY_PROFILE:
      return {
        ...state,
        createCompanyProfile: action.payload,
      };
    case TYPES.BUSINESS_PARTNER_PROFILE_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchBusinessPartnerProfile = companyProfile => (
  dispatch,
  getState
) => {
  const {
    auth: {access, access: {token = null} = {}},
  } = getState();

  const uuid = getLoggedInstituteUUID(getState());

  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestBusinessPartnerProfile());
  try {
    if (uuid) {
      return fetch(`${API.administration.institution}${uuid}/`, config)
        .then(response => response.json())
        .then(json => {
          dispatch(receiveBusinessPartnerProfile([json]));
          if (companyProfile) {
            dispatch(toggleCompanyProfile(companyProfile));
            dispatch(setStep(0));
          }
        })
        .catch(err => {
          console.log(err);
          dispatch(handleBusinessPartnerProfileError(err));
        });
    }
  } catch (err) {
    console.log(err);
    dispatch(handleBusinessPartnerProfileError(err));
  }
};
