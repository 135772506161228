import * as TYPES from './constants';
import {
  requestBusinessPartnerDashboard,
  receiveBusinessPartnerDashboard,
  handleBusinessPartnerDashboardError,
} from './actions';

import {JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import {API} from 'config/businesspartner.config';

const initialState = {
  data: null,
  request: null,
  error: null,
  monthsFilter: 'all',
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_BUSINESS_PARTNER_DASHBOARD:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_BUSINESS_PARTNER_DASHBOARD:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.BUSINESS_PARTNER_MONTHS_FILTER:
      return {
        ...state,
        monthsFilter: action.months,
      };
    case TYPES.BUSINESS_PARTNER_DASHBOARD_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchBusinessPartnerDashboard = () => (dispatch, getState) => {
  const {auth: {access: {token = null} = {}} = {}} = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestBusinessPartnerDashboard());
  try {
    return fetch(`${API.administration.dashboard}`, config)
      .then(response => response.json())
      .then(json => {
        if (json.workforce_insights) {
          dispatch(receiveBusinessPartnerDashboard(json.workforce_insights));
        } else {
          dispatch(handleBusinessPartnerDashboardError('Something went wrong'));
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(handleBusinessPartnerDashboardError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleBusinessPartnerDashboardError(err));
  }
};
