import * as TYPES from './constants';
import {
  requestQualifyingPrograms,
  receiveQualifyingPrograms,
  handleQualifyingProgramsError,
} from './actions';
import {API} from 'config/employer.config';
import {JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {
  data: null,
  error: null,
  request: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_QUALIFYING_PROGRAMS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_QUALIFYING_PROGRAMS:
      return {
        ...state,
        data: action.payload,
        request: false,
      };
    case TYPES.QUALIFYING_PROGRAMS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.QUALIFYING_PROGRAMS_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const fetchQualifyingPrograms = term => (dispatch, getState) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestQualifyingPrograms());
  try {
    return fetch(
      `${
        API.administration.publish
      }?qualifying_programs=true&pathway_name=${term}`,
      config
    )
      .then(response => response.json())
      .then(json => {
        dispatch(receiveQualifyingPrograms(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleQualifyingProgramsError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleQualifyingProgramsError(err));
  }
};
