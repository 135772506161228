import React, {Component} from 'react';
import {Form, Input, Icon, Tag, Tooltip} from 'antd';
const FormItem = Form.Item;

class PickList extends Component {
  state = {
    options: [],
    inputVisible: false,
    inputValue: '',
  };

  handleClose = removedTag => {
    const options = this.state.options.filter(tag => tag !== removedTag);
    this.setState({options});
  };

  showInput = () => {
    this.setState({inputVisible: true}, () => this.input.focus());
  };

  handleInputChange = e => {
    this.setState({inputValue: e.target.value});
  };

  handleInputConfirm = () => {
    const {inputValue} = this.state;
    let {options} = this.state;
    if (inputValue && options.indexOf(inputValue) === -1) {
      options = [...options, inputValue];
    }
    this.setState({
      options,
      inputVisible: false,
      inputValue: '',
    });
  };

  saveInputRef = input => (this.input = input);

  // populateDefaultOptions = default_options => {
  //   const optionsArray = default_options.split(',');
  //   const updatedArray = optionsArray.map(tag =>
  //     tag.replace(/[ \[|\]|'|"|/]+/g, '')
  //   );
  //   this.setState({
  //     options: updatedArray,
  //   });
  // };

  // componentDidMount() {
  //   const {
  //     data: {options, default_options},
  //   } = this.props;
  //   if (options && options.length) {
  //     this.setState({
  //       options,
  //       showPicklistOptions: true,
  //     });
  //   } else if (default_options) {
  //     this.populateDefaultOptions(default_options);
  //   }
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   const {
  //     data: {default_options},
  //   } = this.props;
  //   const {options: prevOptions} = prevState;
  //   const {options: currentOptions} = this.state;
  //   if (prevOptions.length === currentOptions.length) {
  //     return;
  //   }
  //   if (default_options && !prevOptions.length && !currentOptions.length) {
  //     this.populateDefaultOptions(default_options);
  //   }
  // }

  render() {
    const {formDecorator, required, fieldName} = this.props;
    const {options, inputVisible, inputValue} = this.state;
    const formFieldName = fieldName ? fieldName : 'options';
    return (
      <>
        <div
          className={`picklist-option ${
            !options.length && required ? 'error' : ''
          }`}>
          {options &&
            options.length > 0 &&
            options.map(tag => {
              const isLongTag = tag.length > 20;
              const tagElem = (
                <Tag key={tag} closable onClose={() => this.handleClose(tag)}>
                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tag} key={tag}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}
          {inputVisible && (
            <Input
              ref={this.saveInputRef}
              type="text"
              size="small"
              style={{width: 78}}
              value={inputValue}
              onChange={this.handleInputChange}
              onBlur={this.handleInputConfirm}
              onPressEnter={this.handleInputConfirm}
            />
          )}

          {!inputVisible && (
            <Tag
              onClick={this.showInput}
              className="ant-tag-input"
              style={{background: '#fff', borderStyle: 'dashed'}}>
              <Icon type="plus" /> Enter Tags ...
            </Tag>
          )}
        </div>

        {!options.length && required && (
          <span className="error-text">Options can not be empty</span>
        )}

        {options &&
          options.length > 0 &&
          options.map((tag, idx) => {
            return (
              <FormItem key={idx} className="margin-muted">
                {formDecorator(`${formFieldName}[${idx}]`, {
                  initialValue: tag,
                })(<Input type="hidden" placeholder="tag" />)}
              </FormItem>
            );
          })}
      </>
    );
  }
}

export default PickList;
