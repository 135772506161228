export const REQUEST_BUSINESS_PARTNER_USERS = 'REQUEST_BUSINESS_PARTNER_USERS';
export const RECEIVE_BUSINESS_PARTNER_USERS = 'RECEIVE_BUSINESS_PARTNER_USERS';
export const BUSINESS_PARTNER_USERS_ERROR = 'BUSINESS_PARTNER_USERS_ERROR';
export const BUSINESS_PARTNER_MONTHS_FILTER = 'BUSINESS_PARTNER_MONTHS_FILTER';
export const CHANGE_BUSINESS_PARTNER_PROGRAM_FIRST_FETCH =
  'CHANGE_BUSINESS_PARTNER_PROGRAM_FIRST_FETCH';
export const REQUEST_BUSINESS_PARTNER_PROGRAM =
  'REQUEST_BUSINESS_PARTNER_PROGRAM';
export const RECEIVE_BUSINESS_PARTNER_PROGRAM_DATA_CLEAR =
  'RECEIVE_BUSINESS_PARTNER_PROGRAM_DATA_CLEAR';
export const REQUEST_BUSINESS_PARTNER_PROGRAM_STUDENT_LIST =
  'REQUEST_BUSINESS_PARTNER_PROGRAM_STUDENT_LIST';
export const RECEIVE_BUSINESS_PARTNER_PROGRAM =
  'RECEIVE_BUSINESS_PARTNER_PROGRAM';
export const RECEIVE_BUSINESS_PARTNER_PROGRAM_DETAILS =
  'RECEIVE_BUSINESS_PARTNER_PROGRAM_DETAILS';
export const RECEIVE_BUSINESS_PARTNER_PROGRAM_STUDENT_LIST =
  'RECEIVE_BUSINESS_PARTNER_PROGRAM_STUDENT_LIST';
export const BUSINESS_PARTNER_PROGRAM_ERROR = 'BUSINESS_PARTNER_PROGRAM_ERROR';

//SRM Business Partner Opportunities
export const REQUEST_BUSINESS_PARTNER_OPPORTUNITY_DETAILS =
  'REQUEST_BUSINESS_PARTNER_OPPORTUNITY_DETAILS';
export const RECEIVE_BUSINESS_PARTNER_OPPORTUNITY_DETAILS =
  'RECEIVE_BUSINESS_PARTNER_OPPORTUNITY_DETAILS';
export const BUSINESS_PARTNER_OPPORTUNITY_DETAILS_ERROR =
  'BUSINESS_PARTNER_OPPORTUNITY_DETAILS_ERROR';
export const RECEIVE_BUSINESS_PARTNER_OPPORTUNITY_DATA_CLEAR =
  'RECEIVE_BUSINESS_PARTNER_OPPORTUNITY_DATA_CLEAR';
export const REQUEST_BUSINESS_PARTNER_OPPORTUNITY_STUDENT_LIST =
  'REQUEST_BUSINESS_PARTNER_OPPORTUNITY_STUDENT_LIST';
export const RECEIVE_BUSINESS_PARTNER_OPPORTUNITY_STUDENT_LIST =
  'RECEIVE_BUSINESS_PARTNER_OPPORTUNITY_STUDENT_LIST';

export const REQUEST_BUSINESS_PARTNER_PROFILE =
  'REQUEST_BUSINESS_PARTNER_PROFILE';
export const RECEIVE_BUSINESS_PARTNER_PROFILE =
  'RECEIVE_BUSINESS_PARTNER_PROFILE';
export const BUSINESS_PARTNER_PROFILE_ERROR = 'BUSINESS_PARTNER_PROFILE_ERROR';
export const REQUEST_BUSINESS_PARTNER_DASHBOARD =
  'REQUEST_BUSINESS_PARTNER_DASHBOARD';
export const RECEIVE_BUSINESS_PARTNER_DASHBOARD =
  'RECEIVE_BUSINESS_PARTNER_DASHBOARD';
export const BUSINESS_PARTNER_DASHBOARD_ERROR =
  'BUSINESS_PARTNER_DASHBOARD_ERROR';
export const REQUEST_BUSINESS_PARTNER_INDUSTRY =
  'REQUEST_BUSINESS_PARTNER_INDUSTRY';
export const RECEIVE_BUSINESS_PARTNER_INDUSTRY =
  'RECEIVE_BUSINESS_PARTNER_INDUSTRY';
export const BUSINESS_PARTNER_INDUSTRY_ERROR =
  'BUSINESS_PARTNER_INDUSTRY_ERROR';

export const CHANGE_BUSINESS_PARTNER_JOBS_FIRST_FETCH =
  'CHANGE_BUSINESS_PARTNER_JOBS_FIRST_FETCH';
export const REQUEST_BUSINESS_PARTNER_JOBS = 'REQUEST_BUSINESS_PARTNER_JOBS';
export const RECEIVE_BUSINESS_PARTNER_JOBS = 'RECEIVE_BUSINESS_PARTNER_JOBS';
export const BUSINESS_PARTNER_JOBS_ERROR = 'BUSINESS_PARTNER_JOBS_ERROR';
export const REQUEST_BUSINESS_PARTNER_STUDENT_PROFILES =
  'REQUEST_BUSINESS_PARTNER_STUDENT_PROFILES';
export const RECEIVE_BUSINESS_PARTNER_STUDENT_PROFILES =
  'RECEIVE_BUSINESS_PARTNER_STUDENT_PROFILES';
export const BUSINESS_PARTNER_STUDENT_PROFILES_ERROR =
  'BUSINESS_PARTNER_STUDENT_PROFILES_ERROR';
export const UPDATE_BUSINESS_PARTNER_STUDENT_PROFILES_FILTER_STATUS =
  'UPDATE_BUSINESS_PARTNER_STUDENT_PROFILES_FILTER_STATUS';

export const CLEAR_BUSINESS_PARTNER_STUDENT_PROFILE =
  'CLEAR_BUSINESS_PARTNER_STUDENT_PROFILE';
export const REQUEST_BUSINESS_PARTNER_STUDENT_PROFILE =
  'REQUEST_BUSINESS_PARTNER_STUDENT_PROFILE';
export const RECEIVE_BUSINESS_PARTNER_STUDENT_PROFILE =
  'RECEIVE_BUSINESS_PARTNER_STUDENT_PROFILE';
export const BUSINESS_PARTNER_STUDENT_PROFILE_ERROR =
  'BUSINESS_PARTNER_STUDENT_PROFILE_ERROR';

export const REQUEST_OCCUPATIONS = 'REQUEST_OCCUPATIONS';
export const RECEIVE_OCCUPATIONS = 'RECEIVE_OCCUPATIONS';
export const OCCUPATIONS_ERROR = 'OCCUPATIONS_ERROR';
export const TOGGLE_COMPANY_PROFILE = 'TOGGLE_COMPANY_PROFILE';
export const GO_TO_PREVIOUS = 'GO_TO_PREVIOUS';
export const GO_TO_NEXT = 'GO_TO_NEXT';
export const RESET_STEP = 'RESET_STEP';
export const SET_STEP = 'SET_STEP';

export const REQUEST_STUDENT_SKILS_STATUS = 'REQUEST_STUDENT_SKILS_STATUS';
export const RECEIVE_STUDENT_SKILS_STATUS = 'RECEIVE_STUDENT_SKILS_STATUS';
export const STUDENT_SKILS_STATUS_ERROR = 'STUDENT_SKILS_STATUS_ERROR';

export const REQUEST_SKILLS_DATA = 'REQUEST_SKILLS_DATA';
export const RECEIVE_SKILLS_DATA = 'RECEIVE_SKILLS_DATA';
export const SKILLS_DATA_ERROR = 'SKILLS_DATA_ERROR';

export const REQUEST_STUDENT_EXCHANGE_PROGRAMS =
  'REQUEST_STUDENT_EXCHANGE_PROGRAMS';
export const RECEIVE_STUDENT_EXCHANGE_PROGRAMS =
  'RECEIVE_STUDENT_EXCHANGE_PROGRAMS';
export const STUDENT_EXCHANGE_PROGRAMS_ERROR =
  'STUDENT_EXCHANGE_PROGRAMS_ERROR';

export const REQUEST_QUALIFYING_PROGRAMS = 'REQUEST_QUALIFYING_PROGRAMS';
export const RECEIVE_QUALIFYING_PROGRAMS = 'RECEIVE_QUALIFYING_PROGRAMS';
export const QUALIFYING_PROGRAMS_ERROR = 'QUALIFYING_PROGRAMS_ERROR';

// Opportunities
export const CLEAR_OPPORTUNITIES = 'CLEAR_OPPORTUNITIES';
export const REQUEST_OPPORTUNITIES = 'REQUEST_OPPORTUNITIES';
export const RECEIVE_OPPORTUNITIES = 'RECEIVE_OPPORTUNITIES';
export const OPPORTUNITIES_ERROR = 'OPPORTUNITIES_ERROR';
export const OPPORTUNITY_DETAILS_CLEAR = 'OPPORTUNITY_DETAILS_CLEAR';
export const RECEIVE_OPPORTUNITY_DETAILS = 'RECEIVE_OPPORTUNITY_DETAILS';

// External companies i.e careeronestop companies
export const REQUEST_CAREER_ONE_STOP_COMPANIES =
  'REQUEST_CAREER_ONE_STOP_COMPANIES';
export const RECEIVED_CAREER_ONE_STOP_COMPANIES =
  'RECEIVED_CAREER_ONE_STOP_COMPANIES';
export const CAREER_ONE_STOP_COMPANIES_ERROR =
  'CAREER_ONE_STOP_COMPANIES_ERROR';

// Acheivements Options
export const REQUEST_ACHIEVEMENT_OPTIONS = 'REQUEST_ACHIEVEMENT_OPTIONS';
export const RECEIVED_ACHIEVEMENT_OPTIONS = 'RECEIVED_ACHIEVEMENT_OPTIONS';
export const ACHIEVEMENT_OPTIONS_ERROR = 'ACHIEVEMENT_OPTIONS_ERROR';
