import * as TYPES from 'redux/types';

import {API} from 'config/srm.config';

const initialState = {request: true};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_INSTITUTION_FACULTY:
      return {
        ...state,
        ...action.payload,
      };
    case TYPES.RECEIVE_INSTITUTION_FACULTY:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.DELETE_FACULTY:
      return {
        ...state,
        data: state.data.filter(faculty => faculty.uuid != action.payload),
      };
    case TYPES.UPDATE_FACULTY_STATUS:
      return {
        ...state,
        data: state.data.map(faculty => {
          if (faculty.uuid === action.payload.uuid) {
            faculty.is_active = action.payload.status;
          }
          return faculty;
        }),
      };
    case TYPES.REQUEST_INSTITUTION_FACULTY_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

const receiveInstitutionFaculty = json => ({
  type: TYPES.RECEIVE_INSTITUTION_FACULTY,
  payload: json,
});
const requestInstitutionFacultyError = err => ({
  type: TYPES.REQUEST_INSTITUTION_FACULTY_ERROR,
  err,
});
const requestInstitutionFaculty = json => ({
  type: TYPES.REQUEST_INSTITUTION_FACULTY,
  payload: json,
});

export const updateFacultyStatus = obj => ({
  type: TYPES.UPDATE_FACULTY_STATUS,
  payload: obj,
});

export const deleteFaculty = uuid => ({
  type: TYPES.DELETE_FACULTY,
  payload: uuid,
});

export const fetchInstitutionFaculty = () => ({
  type: TYPES.APIREQUEST,
  payload: {
    endpoint: `${API.auth.users}`,
    method: 'GET',
    server: 'institution',
    onsuccess: receiveInstitutionFaculty,
    onerror: requestInstitutionFacultyError,
    onRequestStart: requestInstitutionFaculty,
  },
});

export const institutionFacultySelector = ({institutionFaculty: {data}}) =>
  data;
export const institutionFacultyRequest = ({institutionFaculty: {request}}) =>
  request;
export const institutionFacultyRequestError = ({institutionFaculty: {error}}) =>
  error;
