import {Typography} from '@mui/material';
import Slider, {SliderThumb} from '@mui/material/Slider';
import React, {useState, useEffect} from 'react';

const ThumbComponent = props => {
  const {children, ...other} = props;
  const {ownerState: {value = []} = {}} = other;

  return (
    <SliderThumb {...other}>
      {children}
      <Typography color="white" fontSize={11} variant="body1">
        {value?.[other['data-index']] || 0}
      </Typography>
    </SliderThumb>
  );
};

export default function RangeSlider({
  min = 0,
  max = 100,
  label = '',
  value = [0, 0],
  onChange = () => {},
  showLabelOnThumb = false,
  valueLabelDisplay = 'auto',
}) {
  const [marks, setMarks] = useState([]);

  useEffect(
    () => {
      setMarks([{label: min, value: min}, {label: `${max}+`, value: max}]);
    },
    [min, max]
  );

  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Slider
      min={min}
      max={max}
      marks={marks}
      value={value}
      onChange={handleChange}
      getAriaLabel={() => label}
      slots={{thumb: showLabelOnThumb ? ThumbComponent : null}}
      valueLabelDisplay={showLabelOnThumb ? 'off' : valueLabelDisplay}
    />
  );
}
