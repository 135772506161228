export const getStatesSelector = state => state.staticData.state;

export const getConsortiumStatesSelector = state =>
  state && state.staticData && state.staticData.consortiumStates;

export const getCompanytoInstituteSelector = state =>
  state && state.staticData && state.staticData.companytoInstitution;

// tuition link selector
export const getTuitionLinkSelector = state =>
  state && state.staticData && state.staticData.tuitionLink;

// location search selector
export const getLocationSearchSelector = state =>
  state && state.staticData && state.staticData.locationSearch;

//linkedIn details selector
export const getLinkedInDetailsSelector = state => {
  return state.staticData && state.staticData.linkedInDetails;
};
