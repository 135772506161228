// get Apprenticeships List
export const REQUEST_EMPLOYER_APPRENTICESHIPS =
  'REQUEST_EMPLOYER_APPRENTICESHIPS';
export const RECEIVE_EMPLOYER_APPRENTICESHIPS =
  'RECEIVE_EMPLOYER_APPRENTICESHIPS';
export const EMPLOYER_APPRENTICESHIPS_ERROR = 'EMPLOYER_APPRENTICESHIPS_ERROR';
export const CLEAR_EMPLOYER_APPRENTICESHIPS = 'CLEAR_EMPLOYER_APPRENTICESHIPS';
export const EMPLOYER_APPRENTICESHIP_STATUS_UPDATE =
  'EMPLOYER_APPRENTICESHIP_STATUS_UPDATE';

// Occupations List
export const REQUEST_OCCUPATIONS_LIST = 'REQUEST_OCCUPATIONS_LIST';
export const RECEIVE_OCCUPATIONS_LIST = 'RECEIVE_OCCUPATIONS_LIST';
export const OCCUPATIONS_LIST_ERROR = 'OCCUPATIONS_LIST_ERROR';
export const OCCUPATIONS_LIST_CLEAR = 'OCCUPATIONS_LIST_CLEAR';
