import {styled} from '@mui/material/styles';
import {default as MuiTabPanel} from '@mui/lab/TabPanel';
import PropTypes from 'prop-types';
const TabPanel = styled(MuiTabPanel)({});

TabPanel.propTypes = {
  ...TabPanel.propTypes,
  bordered: PropTypes.bool,
};

export default TabPanel;
