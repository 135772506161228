import {createSelector} from 'reselect';

export const institutionFacultySelector = ({institutionFaculty: {data} = {}}) =>
  data;
export const institutionFacultyRequest = ({
  institutionFaculty: {request} = {},
}) => request;
export const institutionFacultyRequestError = ({
  institutionFaculty: {error} = {},
}) => error;

export const getCollegeProfileSelector = ({profile}) => profile;

export const getInstitutionFaculty = createSelector(
  [
    institutionFacultyRequest,
    institutionFacultySelector,
    institutionFacultyRequestError,
    getCollegeProfileSelector,
  ],
  (
    institutionFacultyRequest,
    institutionFacultySelector,
    institutionFacultyRequestError,
    getCollegeProfileSelector
  ) => ({
    request: institutionFacultyRequest,
    error: institutionFacultyRequestError,
    data: institutionFacultySelector,
    profileData: getCollegeProfileSelector,
  })
);
