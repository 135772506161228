import * as TYPES from './constants';
import {API} from 'config/employer.config';
import {JWT_HEADER, JSON_HEADER} from 'config/srm.config';
import {
  requestProgramDetails,
  receiveProgramDetails,
  handleProgramDetailsError,
} from './actions';

const initialState = {
  data: null,
  error: false,
  request: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PROGRAM_DETAILS_REQUEST:
      return {
        ...state,
        request: true,
      };
    case TYPES.PROGRAM_DETAILS_RECEIVE:
      return {
        ...state,
        request: false,
        data: action.payload,
      };
    case TYPES.PROGRAM_DETAILS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.CLEAR_PROGRAM_DETAILS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const fetchEnrolledProgramDetails = id => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    mode: 'cors',
    method: 'GET',
    cache: 'default',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
  };
  dispatch(requestProgramDetails());

  const URI = `${API.administration.enrolled_program_details}/${id}`;
  try {
    return fetch(URI, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveProgramDetails(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleProgramDetailsError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleProgramDetailsError(err));
  }
};
