import React, {Component} from 'react';
import './style.css';
import {message} from 'antd';
import {Counter} from 'core';
import {connect} from 'react-redux';
import {Editor} from '@tinymce/tinymce-react';
import {accessToken} from 'redux/modules/auth';
import {API, JWT_HEADER, IMG_CLOUDINARY, JSON_HEADER} from 'config/srm.config';

const KEY = process.env.REACT_APP_TINY_MCE_KEY;
const editorRef = React.createRef();
const apiKey = KEY;
const enablePlugins = [
  'image',
  'print preview importcss tinydrive searchreplace autolink',
  'directionality visualblocks visualchars fullscreen image ',
  'link media  template codesample table charmap hr pagebreak paste',
  'nonbreaking anchor toc insertdatetime advlist lists  wordcount',
  'imagetools textpattern noneditable help charmap quickbars code',
];
//code emoticons
const enableToolbar =
  'image | deleteImage | undo redo |fontselect | formatselect | ' +
  'fontsizeselect | bold italic underline | hr strikethrough superscript subscript | preview fullscreen | outdent indent |' +
  'cut copy paste pastetext | selectall removeformat | forecolor backcolor | alignleft aligncenter alignright alignjustify alignnone |' +
  'link anchor | bullist numlist | nonbreaking pagebreak blockquote | charmap image media |' +
  'template codesample ltr rtl | insertdatetime | visualaid searchreplace | print ';
//charmap emoticons
const enableMenuBar = 'file edit view insert format tools table tc help';
const enableHelptabs = [
  'shortcuts', // the default shortcuts tab
  'keyboardnav', // the default keyboard navigation tab
];
const enableQuickSelectionOptions =
  'bold italic underline | quicklink h1 h2 h3 | blockquote image quicktable';
const defaultTemplates = [
  {
    title: 'Template 1',
    description: 'creates a new table',
    content:
      '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
  },
  {
    title: 'Template 2',
    description: 'A cure for writers block',
    content: 'Once upon a time...',
  },
  {
    title: 'Template 3',
    description: 'New List with dates',
    content:
      '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
  },
];
const imageModificationClasses = [
  {title: 'None', value: ''},
  {title: 'No border', value: 'img_no_border'},
  {
    title: 'Borders',
    menu: [
      {title: 'Green border', value: 'img_green_border'},
      {title: 'Blue border', value: 'img_blue_border'},
      {title: 'Red border', value: 'img_red_border'},
    ],
  },
];

const lightTheme = {
  skin: 'oxide',
  content_css: 'default',
};
const darkTheme = {
  skin: 'oxide-dark',
  content_css: 'dark',
};

// const htmlToText = (html) => {
//   var temp = document.createElement('div');
//   temp.innerHTML = html;
//   return temp.textContent.replace(/(<([^>]+)>)/gi, ''); // Or return temp.innerText if you need to return only visible text. It's slower.
// }

let editorContent = '';

class TinyEditor extends Component {
  state = {
    value: '',
    textLength: 0,
    darkThemeEnabled: false,
    hasLenError: false,
    resImageURL: '',
    propsUrl: '',
    propsImageName: '',
  };
  componentDidMount() {
    const {value, onChange} = this.props;
    if (value) {
      this.setState(
        {
          value,
        },
        () => onChange(this.state.value)
      );
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const {maxLen, value, onChange, checkexceptional} = this.props;
    if (editorRef.current) {
      if (maxLen && prevState.textLength !== this.state.textLength) {
        this.setState({
          textLength: this.state.textLength,
          hasLenError: this.state.textLength > maxLen,
        });
        //editorRef.current.plugins.wordcount.body.getCharacterCount()
      }
      if (prevState.value !== this.state.value) {
        checkexceptional && onChange(editorRef.current.getContent());
        !checkexceptional &&
          this.setState(
            {
              value: this.state.value,
            },
            () => onChange(this.state.value)
          );
      }
      if (
        checkexceptional &&
        value &&
        !prevState.value &&
        !editorRef.current.getContent()
      ) {
        this.setState(
          {
            value,
          },
          () => onChange(this.state.value)
        );
        //editorRef.current.setContent(this.props.newValue);
      }
    }
    if (prevProps.value !== value) {
      this.setState(
        {
          value: value,
        },

        () => {
          onChange(value);
        }
      );
    }
  }

  // onThemeChange = value => {
  //   this.setState({
  //     darkThemeEnabled: value,
  //   });
  // };

  render() {
    const {
      maxLen,
      toolbar,
      hideMenuBar,
      height,
      disableHelpTabs,
      disableQuickSelectionOptions,
      disableQuickbarsInsertYoolbar,
    } = this.props;
    const {textLength, darkThemeEnabled, hasLenError} = this.state;
    const theme = darkThemeEnabled ? darkTheme : lightTheme;
    const handleDelete = (oldList, newList) => {
      oldList = oldList.map(list => {
        const v = list.match(/<img.*?src="(.*?)"/)[1];
        if (v) {
          return {url: v, key: v.match(/[^/]*$/)[0]};
        } else return '';
      });
      newList = newList.map(list => {
        const v = list.match(/<img.*?src="(.*?)"/)[1];
        if (v) {
          return {url: v, key: v.match(/[^/]*$/)[0]};
        } else return '';
      });
      const deleted = oldList.filter(a => newList.indexOf(a) === -1);
      if (deleted.length > 0) {
        deleted.forEach(img => deleteImage(img.url, img.key));
      }
    };

    // const handleKeyDown = event => {
    //   editorContent = editorRef.current.getContent();
    // };

    // const handleKeyUp = event => {
    //   const regex = /<img[^>]+src="([^">]+)"/g;
    //   const newContent = editorRef.current.getContent();
    //   const oldList = editorContent.match(regex) || [];
    //   const newList = newContent.match(regex) || [];
    //   console.log(oldList, newList);
    //   if (newList.length < oldList.length) {
    //     handleDelete(oldList, newList);
    //   }
    // };

    const handleImageDelete = newContent => {
      const regex = /<img[^>]+src="([^">]+)"/g;
      const oldList = editorContent.match(regex) || [];
      const newList = newContent.match(regex) || [];
      console.log(oldList, newList);
      if (newList.length < oldList.length) {
        handleDelete(oldList, newList);
      }
      editorContent = newContent;
    };

    const deleteImage = (image_url, key) => {
      // state data close
      const objData = {
        image_url,
      };
      const {token} = this.props;
      const endpoint = `${API.tinymceEditor.uploadimage}/${key}`;
      fetch(endpoint, {
        method: 'DELETE',
        headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
        body: JSON.stringify(objData),
      }).then(response => console.log(response));
      // }
    };

    const validateImageSize = file => {
      const maxSize = 10 * 1024 * 1024; // Maximum size in bytes (10MB)
      const fileSize = file.size;
      if (fileSize > maxSize) {
        return false;
      }
      return true;
    };

    return (
      <div className="custom-editor">
        {/* <Switch checked={darkThemeEnabled} onChange={this.onThemeChange} /> */}

        <Editor
          apiKey={apiKey}
          onInit={(evt, editor) => {
            editorRef.current = editor;
            this.setState({
              textLength: editor.plugins.wordcount.body.getCharacterCount(),
              hasLenError: false,
            });
          }}
          onEditorChange={(newValue, editor) => {
            console.log('onEditorChange', newValue, editor);
            handleImageDelete(newValue);
            if (maxLen) {
              const contentLen = editor.plugins.wordcount.body.getCharacterCount();
              if (contentLen <= maxLen) {
                this.setState({
                  value: newValue,
                  textLength: contentLen,
                  hasLenError: false,
                });
              } else {
                // forcefull rerender::
                message.warning(
                  'Max length exceeds, your content might not save.'
                );
                this.setState({
                  textLength: contentLen,
                  hasLenError: true,
                });
              }
            } else {
              this.setState({
                value: newValue,
              });
            }
          }}
          //initialValue={this.props.value}
          value={!this.state.value ? '' : this.state.value || this.props.value}
          init={{
            height: height || 600,
            plugins: enablePlugins,
            menubar: !hideMenuBar ? enableMenuBar : false,
            help_tabs: disableHelpTabs ? false : enableHelptabs,
            toolbar_sticky: false,
            toolbar: toolbar || enableToolbar,
            encoding: 'xml',
            entity_encoding: 'named+numeric+raw',
            entities: '160,nbsp',
            quickbars_selection_toolbar: disableQuickSelectionOptions
              ? false
              : enableQuickSelectionOptions,
            quickbars_insert_toolbar: disableQuickbarsInsertYoolbar
              ? false
              : 'image quicktable',
            image_class_list: imageModificationClasses,

            //block_unsupported_drop: false,
            //images_upload_credentials: false,

            // file_browser_callback: false,
            images_upload_base_path: API.tinymceEditor.uploadimage,
            images_upload_credentials: true,
            images_upload_handler: (blobInfo, success, failure) => {
              if (validateImageSize(blobInfo.blob())) {
                let data = new FormData();
                var reader = new FileReader();
                data.append('image', blobInfo.blob(), blobInfo.filename());
                const endpoint = API.tinymceEditor.uploadimage;

                const {token} = this.props;

                let fetchRes = fetch(endpoint, {
                  method: 'POST',
                  headers: JWT_HEADER(token),
                  body: data,
                });
                fetchRes
                  .then(response => response.json())
                  .then(data => {
                    this.setState({resImageURL: data.image_url});
                    success(`${IMG_CLOUDINARY}/${data.image_url}`);
                  })
                  .catch(error => {
                    failure('Image upload failed: ' + error.message);
                  });

                reader.readAsDataURL(blobInfo.blob());
              } else {
                // Reject the image upload if it exceeds the size limit
                const errorMessage =
                  'The image size exceeds the allowed limit (10 MB).';
                failure(errorMessage);
              }
            },

            //setup: handleToolbarSetup,
            automatic_uploads: true,
            image_uploadtab: true,
            image_advtab: false,
            image_caption: false,
            link_list: [],
            templates: defaultTemplates,
            template_cdate_format:
              '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
            template_mdate_format:
              '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
            toolbar_mode: 'sliding',
            skin: theme.skin,
            content_css: theme.content_css,
            paste_as_text: true,

            //file_picker_types: 'image',
            /* and here's our custom image picker*/

            content_style:
              'body {font - family:Arial Rounded MT, Arial, Helvetica, sans-serif; font-size:14px }',
          }}
          //onChange={handleEditorChange}
          // onKeyDown={handleKeyDown}
          // onKeyUp={handleKeyUp}
        />

        {maxLen && (
          <Counter
            haserror={hasLenError}
            current={textLength || 0}
            total={maxLen}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: accessToken(state),
});

export default connect(
  mapStateToProps,
  {}
)(TinyEditor);
