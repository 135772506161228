import React from 'react';
import {Spin, Icon} from 'antd';

import {isEmpty, isArray, isObject, NoSearchResults} from 'core';

import './RequestErrorLoaderHandler.css';

const antIcon = <Icon type="loading" spin />;

export default ({
  isOnline,
  showDefault,
  overlay,
  initialData,
  overrideLoaderKey,
  title,
  errorMessage,
  noRecords,
  showStaticLoder,
  icon,
  headerTitle,
  checkNestedKey,
  nestedClass,
  isSavingData,
  hideContainer,
}) => {
  const DATA = initialData && initialData[overrideLoaderKey || 'data'];
  return (
    <>
      {isOnline && showDefault && (
        <div className="initial-loading">
          <span className="small-request-loader">
            <Spin indicator={antIcon} />
            <span className="loader-text">{title || 'Please Wait...'}</span>
          </span>
        </div>
      )}

      {(isOnline && showStaticLoder) ||
        (isSavingData && (
          <div
            className={`${
              overlay
                ? 'initial-loading loader-text-overlay'
                : 'initial-loading'
            }`}>
            <span className="small-request-loader">
              <Spin indicator={antIcon} />
              <span className="loader-text">{title}</span>
            </span>
          </div>
        ))}
      {isOnline && title && initialData && initialData.request && (
        <div
          className={`${
            overlay ? 'initial-loading loader-text-overlay' : 'initial-loading'
          }`}>
          <span className="small-request-loader">
            <Spin indicator={antIcon} />
            <span className="loader-text">{title}</span>
          </span>
        </div>
      )}

      {!isOnline && (
        <NoSearchResults
          icon="offline"
          message="No internet connection found. App is running in 'offline mode'."
        />
      )}
      {isOnline &&
        errorMessage &&
        initialData &&
        initialData.error &&
        !hideContainer && (
          <NoSearchResults
            headerTitle={headerTitle}
            message={errorMessage}
            className={nestedClass}
          />
        )}

      {isOnline &&
        noRecords &&
        checkNestedKey &&
        initialData &&
        DATA &&
        DATA[checkNestedKey] &&
        isArray(DATA[checkNestedKey]) &&
        !DATA[checkNestedKey].length &&
        !initialData.error &&
        !initialData.request &&
        !hideContainer && (
          <NoSearchResults
            icon={icon}
            message={noRecords}
            headerTitle={headerTitle}
            className={nestedClass}
          />
        )}

      {isOnline &&
        noRecords &&
        initialData &&
        DATA &&
        isArray(DATA) &&
        !DATA.length &&
        !initialData.error &&
        !initialData.request &&
        !hideContainer && (
          <NoSearchResults
            message={noRecords}
            icon={icon}
            headerTitle={headerTitle}
            className={nestedClass}
          />
        )}

      {isOnline &&
        noRecords &&
        initialData &&
        DATA &&
        !isArray(DATA) &&
        isObject(DATA) &&
        isEmpty(DATA) &&
        !initialData.error &&
        !initialData.request &&
        !hideContainer && (
          <NoSearchResults
            message={noRecords}
            icon={icon}
            headerTitle={headerTitle}
            className={nestedClass}
          />
        )}
    </>
  );
};
