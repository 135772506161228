import * as TYPES from './constants';
import {
  requestOccupations,
  receiveOccupations,
  handleOccupationsError,
} from './actions';

import {JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import {API} from 'config/businesspartner.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_OCCUPATIONS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_OCCUPATIONS:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.OCCUPATIONS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchOccupations = term => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  if (term) {
    dispatch(requestOccupations());
    try {
      return fetch(`${API.administration.occupation}?name=${term}`, config)
        .then(response => response.json())
        .then(json => {
          dispatch(receiveOccupations(json));
        })
        .catch(err => {
          console.log(err);
          dispatch(handleOccupationsError(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(handleOccupationsError(err));
    }
  }
};

export const fetchStudentExchangeOccupations = () => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  // if (term) {
  dispatch(requestOccupations());
  try {
    return fetch(
      `${API.administration.occupation}?student_exchange_occ=true`,
      config
    )
      .then(response => response.json())
      .then(json => {
        dispatch(receiveOccupations(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleOccupationsError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleOccupationsError(err));
  }
  // }
};
