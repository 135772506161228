import React, {Component, useEffect, useState} from 'react';

import {Menu, Dropdown} from 'antd';

class NotificationControls extends Component {
  onControlsVisibilityChange = controlsVisibility => {
    const {
      data: {id},
    } = this.props;
    this.props.handleControlsVisiblity({[id]: controlsVisibility});
  };

  render() {
    const {
      onNotificationClick,
      data,
      data: {is_read},
      controlsVisiblity,
    } = this.props;
    return (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="0">
              <span
                onClick={() => onNotificationClick(data, null, 'is_deleted')}>
                Clear Notification
              </span>
            </Menu.Item>
            {is_read === false && (
              <Menu.Item key="1">
                <span
                  onClick={() => onNotificationClick(data, null, 'is_read')}>
                  Mark as Read
                </span>
              </Menu.Item>
            )}
          </Menu>
        }
        trigger={['click']}
        overlayClassName="notification-icons-dropdown"
        visible={controlsVisiblity}
        onVisibleChange={this.onControlsVisibilityChange}>
        <span className="icon-more-horizontal icon-more" />
      </Dropdown>
    );
  }
}

export default NotificationControls;
