import * as TYPES from 'redux/types';

import {API} from 'config/srm.config';

const initialState = {request: true};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_INSTITUTION_TYPE:
      return {
        ...state,
        ...action.payload,
      };
    case TYPES.RECEIVE_INSTITUTION_TYPE:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.REQUEST_INSTITUTION_TYPE_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

const receiveInstitutionType = json => ({
  type: TYPES.RECEIVE_INSTITUTION_TYPE,
  payload: json,
});
const requestInstitutionTypeErr = err => ({
  type: TYPES.REQUEST_INSTITUTION_TYPE_ERROR,
  err,
});
const requestInstitutionType = json => ({
  type: TYPES.REQUEST_INSTITUTION_TYPE,
  payload: json,
});

export const fetchInstitutionType = institutionId => ({
  type: TYPES.APIREQUEST,
  payload: {
    endpoint: institutionId
      ? `${API.institution.institution_type}?super_type__uuid=${institutionId}`
      : `${API.institution.institution_type}`,
    method: 'GET',
    server: 'institution',
    onsuccess: receiveInstitutionType,
    onerror: requestInstitutionTypeErr,
    onRequestStart: requestInstitutionType,
  },
});

export const institutionTypeSelector = ({institutionType: {data}}) => data;
export const institutionTypeRequest = ({institutionType: {request}}) => request;
export const institutionTypeRequestError = ({institutionType: {error}}) =>
  error;
