import * as TYPES from './constants';
import {
  requestBusinessPartnerProgram,
  requestBusinessPartnerProgramStudentList,
  receiveBusinessPartnerProgram,
  receiveBusinessPartnerProgramDetails,
  receiveBusinessPartnerProgramStudentsList,
  handleBusinessPartnerProgramError,
  changeBusinessPartnerProgramFirstTimeFetchStatus,
} from './actions';
import {Services} from 'core';
import {API, DefaultPageSize, PageStart} from 'config/businesspartner.config';

const initialState = {
  programDetails: null,
  studentsList: null,
  totalStudents: 0,
  data: null,
  isFetchingStudentList: false,
  request: null,
  error: null,
  isfetchingfirstTime: true,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_BUSINESS_PARTNER_PROGRAM:
      return {
        ...state,
        request: true,
      };
    case TYPES.REQUEST_BUSINESS_PARTNER_PROGRAM_STUDENT_LIST:
      return {
        ...state,
        isFetchingStudentList: true,
      };
    case TYPES.CHANGE_BUSINESS_PARTNER_PROGRAM_FIRST_FETCH:
      return {
        ...state,
        isfetchingfirstTime: action.payload,
      };
    case TYPES.RECEIVE_BUSINESS_PARTNER_PROGRAM:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.RECEIVE_BUSINESS_PARTNER_PROGRAM_DETAILS:
      return {
        ...state,
        programDetails: (action.payload && action.payload[0]) || [],
        request: false,
        error: false,
      };
    case TYPES.RECEIVE_BUSINESS_PARTNER_PROGRAM_DATA_CLEAR:
      return {
        ...state,
        programDetails: null,
        studentsList: null,
        totalStudents: 0,
        data: null,
        request: false,
        error: false,
      };
    case TYPES.RECEIVE_BUSINESS_PARTNER_PROGRAM_STUDENT_LIST:
      return {
        ...state,
        studentsList: (action.payload && action.payload.student_details) || {},
        totalStudents: (action.payload && action.payload.total_students) || 0,
        request: false,
        isFetchingStudentList: false,
        error: false,
      };
    case TYPES.BUSINESS_PARTNER_PROGRAM_ERROR:
      return {
        ...state,
        error: true,
        request: false,
        isFetchingStudentList: false,
      };
    default:
      return state;
  }
};

export const fetchBusinessPartnerPrograms = (
  filterType = 'all',
  pageSize = DefaultPageSize,
  pageNo = PageStart,
  searchTerm = ''
) => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
    businessPartnerAdmin: {
      programs: {isfetchingfirstTime},
    },
  } = getState();
  dispatch(requestBusinessPartnerProgram());
  try {
    return services
      .fetchRecords(
        `${
          API.administration.programs
        }?filter=${filterType}&page_size=${pageSize}&page=${pageNo}&search=${searchTerm}`,
        token
      )
      .then(json => {
        if (isfetchingfirstTime) {
          if (
            filterType !== 'all' ||
            searchTerm !== '' ||
            pageNo !== PageStart
          ) {
            dispatch(changeBusinessPartnerProgramFirstTimeFetchStatus(false));
          }
        }
        dispatch(receiveBusinessPartnerProgram(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleBusinessPartnerProgramError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleBusinessPartnerProgramError(err));
  }
};

export const fetchBusinessPartnerProgramStudentList = (
  programId,
  pageSize = 12,
  pageNo = 1,
  sortBy = 'created_date',
  searchTerm = ''
) => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestBusinessPartnerProgramStudentList());
  try {
    return services
      .fetchRecords(
        `${
          API.administration.business_partner_program_student_list
        }?program_id=${programId}&page_size=${pageSize}&page=${pageNo}&sort=${sortBy}&search=${searchTerm}`,
        token
      )
      .then(studentList => {
        dispatch(receiveBusinessPartnerProgramStudentsList(studentList));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleBusinessPartnerProgramError(err));
      });
  } catch (error) {
    console.log(error);
    dispatch(handleBusinessPartnerProgramError(error));
  }
};

export const fetchBusinessPartnerProgramDetails = programID => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestBusinessPartnerProgram());
  try {
    return services
      .fetchRecords(`${API.administration.programs}${programID}}`, token)
      .then(programDetails => {
        dispatch(receiveBusinessPartnerProgramDetails(programDetails));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleBusinessPartnerProgramError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleBusinessPartnerProgramError(err));
  }
};
