import {createSelector} from 'reselect';
import {isEmpty, calculateQuaterlyMonthsData} from 'core';

// Static Data for TimeLine Manipulations
const staticTimeLineData = {
  labels: [],
  datasets: [
    {
      key: 'All',
      label: 'Total Partners',
      data: [],
      borderColor: 'rgb(250, 180, 10)',
      backgroundColor: 'rgb(250, 180, 10)',
    },
    {
      key: 'Active',
      label: 'Active Partners',
      data: [],
      borderColor: 'rgb(26, 178, 102)',
      backgroundColor: 'rgb(26, 178, 102)',
    },
    {
      key: 'Inactive',
      label: 'Inactive Partners',
      data: [],
      borderColor: 'rgb(255, 65, 65)',
      backgroundColor: 'rgb(255, 65, 65)',
    },
  ],
};

export const getPartnersGraphDataSelector = state =>
  state.partners.graphData.data;
export const getPartnersListSelector = state =>
  state.partners.srmPartners.partnersList;
export const getPartnersErrorSelector = state =>
  state.partners.srmPartners.error;
export const getPartnersRequestSelector = state =>
  state.partners.srmPartners.request;
const monthsDataSelector = state => state.partners.graphData.monthsData;

// calculate graph data :::
export const partnersGraphData = createSelector(
  [getPartnersGraphDataSelector, monthsDataSelector],
  (getPartnersGraphDataSelector, monthsDataSelector) => {
    if (getPartnersGraphDataSelector) {
      const timeLineData = {};
      const data = getPartnersGraphDataSelector;
      const labels = [];
      Object.keys(data).map(partnerData => {
        if (data[partnerData] && !isEmpty(data[partnerData])) {
          Object.keys(data[partnerData]).map(year => {
            Object.keys(data[partnerData][year]).map(month => {
              if (!labels.includes(`${month}-${year}`)) {
                labels.push(`${month}-${year}`);
              }
            });
          });
        }
        timeLineData.labels = labels;
        const datasets = [];
        staticTimeLineData.datasets.map(partner => {
          if (data[partner.key]) {
            Object.keys(data[partner.key]).map(year => {
              let calculatedData = Object.values(data[partner.key][year]);
              if (monthsDataSelector !== 'all') {
                calculatedData = calculateQuaterlyMonthsData(
                  monthsDataSelector,
                  calculatedData,
                  [year]
                );
              }
              datasets.push(
                Object.assign({}, partner, {
                  data: calculatedData,
                })
              );
            });
          }
        });
        timeLineData.datasets = datasets;
      });

      if (monthsDataSelector !== 'all') {
        timeLineData.labels = calculateQuaterlyMonthsData(
          monthsDataSelector,
          timeLineData.labels
        );
      }
      return timeLineData;
    }
  }
);

export const getPartnersList = createSelector(
  [
    getPartnersRequestSelector,
    getPartnersListSelector,
    getPartnersErrorSelector,
  ],
  (
    getPartnersRequestSelector,
    getPartnersListSelector,
    getPartnersErrorSelector
  ) => ({
    request: getPartnersRequestSelector,
    error: getPartnersErrorSelector,
    data: getPartnersListSelector,
  })
);

export const getInstitutionUUID = state =>
  state.auth.access['custom:institution_uuid'];
export const getInstitutionType = state => state.auth.institutionType;
export const getDashboardData = state => state.dashboard;
export const getStudentData = state =>
  state.dashboard &&
  state.dashboard.data &&
  state.dashboard.data.students_count;
export const getInstitutionData = state =>
  state.dashboard && state.dashboard.data && state.dashboard.data.institution;
export const getDashboardOutreachEvents = state =>
  state.dashboard &&
  state.dashboard.data &&
  state.dashboard.data.outreach_events;
export const getDashboardWorkforce = state =>
  state.dashboard && state.dashboard.data && state.dashboard.data.workforce;
export const getDashboardPrograms = state =>
  state.dashboard && state.dashboard.data && state.dashboard.data.programs;
export const getProgramListSelector = state =>
  state.dashboard && state.dashboard.data && state.dashboard.data.programs_list;
export const programListFilterSelector = state =>
  state.dashboard && state.dashboard.programListFilter;
export const getProgramEnrollmentSelector = state =>
  state.dashboard &&
  state.dashboard.data &&
  state.dashboard.data.programs_enrolled;
export const programEnrollmentFilterSelector = state =>
  state.dashboard && state.dashboard.programEnrollmentFilter;
export const getProfileUploadStatus = state =>
  state.dashboard && state.dashboard.showPofileUploadSpinner;

export const dashboardProgramListSelector = createSelector(
  [getProgramListSelector, programListFilterSelector],
  (getProgramListSelector, programListFilterSelector) => {
    if (getProgramListSelector && programListFilterSelector) {
      return getProgramListSelector[programListFilterSelector];
    }
    return {};
  }
);

export const dashboardProgramEnrollmentSelector = state =>
  state.programsEnrolled && state.programsEnrolled.data;

export const dashboardProgramActivityGridSelector = state =>
  state.programActivityGrid;

// export const dashboardProgramEnrollmentSelector = createSelector(
//   [getProgramEnrollmentSelector, programEnrollmentFilterSelector],
//   (getProgramEnrollmentSelector, programEnrollmentFilterSelector) => {
//     if (getProgramEnrollmentSelector && programEnrollmentFilterSelector) {
//       return getProgramEnrollmentSelector[programEnrollmentFilterSelector];
//     }
//     return {};
//   }
// );
