import React, {Component} from 'react';

import {Form, Row, Col, Select, Input, Icon, Tag} from 'antd';

import debounce from 'lodash.debounce';

import {PickList, isEmpty, isArray} from 'core';
import './style.css';

const FormItem = Form.Item;
const Option = Select.Option;

class ClusterProgramSelector extends Component {
  state = {};

  componentDidMount() {
    const {clusters, pathNames, data} = this.props;
    if (data && !isEmpty(data)) {
      const {career_cluster, career_cluster_type, program, occupation} = data;
      this.selectClusterSelect(career_cluster, 'true');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {data} = this.props;
    if (prevProps.data !== data) {
      if (data && !isEmpty(data)) {
        const {career_cluster} = data;
        this.selectClusterSelect(career_cluster, 'true');
      }
    }
  }

  SearchOccupation = term => {
    if (term && term.length >= 3) {
      const {
        occupations,
        noForm,
        form: {setFieldsValue},
        fetchOccupations,
        parentFormDecorator: {setFieldsValue: parentsetFieldsValue},
      } = this.props;
      fetchOccupations(term);
      this.setState({
        searchTerm: term,
      });
    }
  };

  selectClusterSelect = (clusterId, loadInitialProgram) => {
    const {
      clusters,
      pathNames,
      data,
      noForm,
      form: {setFieldsValue},
      parentFormDecorator: {setFieldsValue: parentsetFieldsValue},
    } = this.props;

    const fieldSetValue = noForm ? parentsetFieldsValue : setFieldsValue;

    const SelectedCluster =
      clusters && clusters.filter(cluster => cluster.uuid === clusterId)[0];
    if (SelectedCluster) {
      const SelectedClusterType = pathNames.filter(
        path => path.id === SelectedCluster.cluster_type
      )[0];
      this.setState(
        {
          SelectedCluster,
          SelectedClusterType,
          SelectedProgram: null,
          SelectedOccupation:
            loadInitialProgram === 'true' ? data && data.occupation : null,
          searchValue: undefined,
          searchTerm: null,
          filterOccupations: [],
        },
        () => {
          if (loadInitialProgram === 'true') {
            this.selectProgramSelect(data && data.program, 'true');
          }
        }
      );
    }

    if (loadInitialProgram !== 'true') {
      fieldSetValue({
        program: undefined,
        occupation: undefined,
      });
    }
  };
  selectProgramSelect = (programId, loadInitialOccupation) => {
    const {
      data,
      noForm,
      form: {setFieldsValue},
      parentFormDecorator: {setFieldsValue: parentsetFieldsValue},
    } = this.props;

    const fieldSetValue = noForm ? parentsetFieldsValue : setFieldsValue;
    const {
      SelectedCluster: {programs},
    } = this.state;
    const SelectedProgram =
      programs && programs.filter(program => program.uuid === programId)[0];
    this.setState({
      SelectedProgram,
      SelectedOccupation:
        loadInitialOccupation === 'true' ? data && data.occupation : null,
      searchValue: undefined,
      searchTerm: null,
    });

    if (loadInitialOccupation !== 'true') {
      fieldSetValue({
        occupation: undefined,
      });
    }
  };

  findClusterAndProgramUuid = occupation_uuid => {
    const {clusters} = this.props;
    let data = {cluster_uuid: null, program_uuid: null};
    clusters &&
      clusters.forEach(cluster => {
        cluster.programs &&
          cluster.programs.length &&
          cluster.programs.forEach(program => {
            program.occupation &&
              program.occupation.length &&
              program.occupation.forEach(occupationData => {
                if (occupationData.uuid === occupation_uuid) {
                  data.cluster_uuid = cluster.uuid;
                  data.program_uuid = program.uuid;
                  return data;
                }
              });
          });
      });
    return data;
  };
  onSelectOccupation = (occupation_uuid, fieldType) => {
    const {
      onOccupationSelect,
      noForm,
      occupations,
      form: {setFieldsValue},
      parentFormDecorator: {setFieldsValue: parentsetFieldsValue},
    } = this.props;
    const {SelectedProgram} = this.state;
    const {data: occupationsData = []} = occupations || {};
    const fieldSetValue = noForm ? parentsetFieldsValue : setFieldsValue;
    if (occupation_uuid) {
      let searchData = [];
      let uuidData = this.findClusterAndProgramUuid(occupation_uuid);
      if (fieldType === 'search') {
        searchData = [...occupationsData];
      } else if (fieldType === 'select') {
        searchData = [...SelectedProgram.occupation];
      }
      const occupationName = searchData.filter(
        occupation => occupation.occupation_uuid === occupation_uuid
      )[0]['occupation_name'];
      this.setState(
        {
          searchValue: undefined,
          searchTerm: null,
          SelectedCluster: undefined,
          // SelectedProgram: null,
          filterOccupations: [],
        },
        () => {
          fieldSetValue({
            career_cluster_type: undefined,
            career_cluster: undefined,
            program: undefined,
            occupation: undefined,
            occupation_name: occupationName,
          });
          onOccupationSelect(
            occupationName,
            occupation_uuid,
            uuidData.cluster_uuid,
            uuidData.program_uuid
          );
        }
      );
    }
  };

  render() {
    const {
      clusters,
      form,
      form: {getFieldDecorator},
      renderComponent,
      noForm,
      hidePickList,
      parentFormDecorator,
      parentFormDecorator: {getFieldDecorator: parentDecorator},
      data,
      occupations: getOccupations,
      headingText,
      hideHeading,
      occupationSelected,
      require,
      programRequired,
      occupationRequired,
      onOccupationSelect,
      hideBottom,
      occupations,
      disable,
      clearSelectedOccupation,
    } = this.props;
    const {
      SelectedCluster,
      SelectedProgram,
      SelectedClusterType,
      SelectedOccupation,
      filterOccupations,
      searchTerm,
      searchValue,
    } = this.state;
    const fieldDecorator = noForm ? parentDecorator : getFieldDecorator;
    const options =
      occupations &&
      occupations.data &&
      isArray(occupations.data) &&
      occupations.data.map((d, idx) => {
        if (searchTerm !== '' && searchTerm && idx < 15) {
          return (
            <Option key={d.occupation_uuid} value={d.occupation_uuid}>
              {d.occupation_name}
            </Option>
          );
        }
      });
    const contentComponent = () => {
      return (
        <div className="table-form">
          {!hideHeading && (
            <div className="custom-collapse-header">
              <Row type="flex" className="ant-col-24">
                <Col xs={24} sm={24} md={24} lg={17}>
                  {headingText || 'Link Event to an Occupation'}
                </Col>
                {renderComponent && (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={7}
                    className="filter table-form">
                    <FormItem label="Available Date">
                      {fieldDecorator('course_term')(renderComponent())}
                    </FormItem>
                  </Col>
                )}
              </Row>
            </div>
          )}
          <div className={hideBottom ? 'steps-header-hide' : 'steps-header'}>
            <Row type="flex" gutter={10}>
              {/* This part of code will be removed ß */}
              {/* <Col xs={24} sm={24} md={24} lg={getOccupations ? 12 : 24}>
                <Col xs={24} sm={24} md={24} lg={8}>
                  {fieldDecorator('career_cluster_type', {
                    initialValue:
                      (SelectedClusterType && SelectedClusterType.uuid) ||
                      (data && data.career_cluster_type),
                  })(<Input type="hidden" />)}
                  <FormItem label="Select Career Cluster" colon={false}>
                    {fieldDecorator('career_cluster', {
                      rules: [
                        {
                          required: require,
                          message: 'Please Select Cluster',
                        },
                      ],
                      initialValue: SelectedCluster && SelectedCluster.uuid,
                    })(
                      <Select
                        onChange={this.selectClusterSelect}
                        disabled={disable}
                        placeholder="Select Career Cluster">
                        {clusters &&
                          clusters.map(({name, uuid}) => (
                            <Option key={uuid} value={uuid} title={name}>
                              {name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                  <FormItem
                    className={!programRequired && 'two-rows-label'}
                    label={`Select Program  ${!programRequired &&
                      '(Optional)'}`}
                    colon={false}>
                    {fieldDecorator('program', {
                      rules: [
                        {
                          required: programRequired,
                          message: 'Please Select Program',
                        },
                      ],
                      initialValue: SelectedProgram && SelectedProgram.uuid,
                    })(
                      <Select
                        onChange={this.selectProgramSelect}
                        allowClear
                        disabled={disable}
                        placeholder="Select Program">
                        {SelectedCluster &&
                          SelectedCluster.programs &&
                          SelectedCluster.programs.map(({name, uuid}) => (
                            <Option key={uuid} value={uuid} title={name}>
                              {name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                  <FormItem
                    className={!occupationRequired && 'two-rows-label'}
                    label={`Select Occupation ${!occupationRequired &&
                      '(Optional)'}`}
                    colon={false}>
                    {fieldDecorator('occupation', {
                      rules: [
                        {
                          required: occupationRequired,
                          message: 'Please Select Occupation',
                        },
                      ],
                      initialValue: SelectedOccupation,
                    })(
                      <Select
                        disabled={disable}
                        allowClear
                        onChange={val =>
                          onOccupationSelect &&
                          this.onSelectOccupation(val, 'select')
                        }
                        placeholder="Select Occupation">
                        {SelectedProgram &&
                          SelectedProgram.occupation &&
                          SelectedProgram.occupation.map(({name, uuid}) => (
                            <Option key={uuid} value={uuid} title={name}>
                              {name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Col> */}
              {getOccupations && (
                // <Col xs={24} sm={24} md={24} lg={12}>
                //   <Col
                //     md={2}
                //     lg={2}
                //     style={{
                //       position: 'relative',
                //       top: '45%',
                //       left: '2%',
                //     }}>
                //     <Divider className="divider-circle-text vertical-line">
                //       OR
                //     </Divider>
                //   </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  className="steps-header border-bottom"
                  style={{
                    position: 'relative',
                  }}>
                  <FormItem label="Search Occupation">
                    {occupations && occupations.request ? (
                      <Icon
                        type="loading"
                        style={{
                          position: 'absolute',
                          zIndex: '1',
                          top: '4px',
                          left: '5px',
                          fontSize: 16,
                        }}
                      />
                    ) : (
                      <Icon
                        type="search"
                        style={{
                          position: 'absolute',
                          zIndex: '1',
                          top: '4px',
                          left: '5px',
                        }}
                      />
                    )}

                    <Select
                      showSearch
                      showArrow={false}
                      filterOption={false}
                      defaultActiveFirstOption={false}
                      allowClear
                      value={searchValue}
                      disabled={disable}
                      onSearch={debounce(this.SearchOccupation, 800)}
                      onChange={val => this.onSelectOccupation(val, 'search')}
                      placeholder="Search Occupations"
                      notFoundContent={
                        searchTerm
                          ? 'no occupation matchs for search query'
                          : null
                      }
                      style={{paddingLeft: '11px'}}>
                      {options}
                    </Select>
                  </FormItem>
                </Col>
              )}
            </Row>
            <Row type="flex" style={{width: '100%'}}>
              <Col style={{width: '100%'}}>
                <FormItem
                  className="occupation-form-item"
                  label="Selected Occupation:">
                  {getFieldDecorator('occupation_name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select an occupation',
                      },
                    ],
                    initialValue: occupationSelected,
                  })(
                    <>
                      {occupationSelected ? (
                        isArray(occupationSelected) ? (
                          occupationSelected.map((item, index) => (
                            <Tag
                              className="occupation-tag"
                              closable
                              visible
                              key={index}
                              onClose={() => clearSelectedOccupation(item)}>
                              {item}
                            </Tag>
                          ))
                        ) : (
                          <Tag
                            className="occupation-tag"
                            closable
                            visible
                            onClose={clearSelectedOccupation}>
                            {occupationSelected}
                          </Tag>
                        )
                      ) : (
                        '—'
                      )}
                    </>
                  )}
                </FormItem>
                <FormItem style={{margin: '0', padding: '0'}}>
                  {getFieldDecorator('occupation_uuid', {
                    initialValue: data && data.occupation,
                  })(<Input type="hidden" />)}
                </FormItem>
              </Col>
            </Row>
            {!hidePickList && (
              <Row type="flex">
                <Col xs={24} sm={24} md={24} lg={24}>
                  <FormItem label="Select Tags" className="margin-muted">
                    <PickList formDecorator={getFieldDecorator} />
                  </FormItem>
                </Col>
              </Row>
            )}
          </div>
        </div>
      );
    };

    return noForm ? contentComponent() : <Form>{contentComponent()}</Form>;
  }
}

export default Form.create()(ClusterProgramSelector);
export {ClusterProgramSelector};
