import {useState} from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {Stack, Box, Chip, Button, Typography} from '@mui/material';

const StyledChipsContainer = styled(Box)(({theme, margin}) => ({
  '> div': {
    overflowY: 'auto',
    minHeight: 'auto',
    maxHeight: '192px',
    alignContent: 'flex-start',
    margin: margin || '12px 8px 0px 0px !important',
  },
}));

const ChipStack = ({
  margin,
  displayLimit,
  chipSet = [],
  color = 'white',
  displayShowAll = false,
  emptyMsg = 'No Data Found',
  showAllColor = 'primary.main',
  backgroundColor = 'primary.main',
}) => {
  const [isShowAll, setIsShowAll] = useState(false);
  const onShowClick = () => setIsShowAll(!isShowAll);
  const displaySet = !isShowAll ? chipSet.slice(0, displayLimit) : chipSet;

  return (
    <StyledChipsContainer margin={margin}>
      <Stack direction="row" flexWrap="wrap">
        {displaySet?.length > 0 ? (
          displaySet.map((chip, idx) => (
            <Chip
              label={`${chip}`}
              key={`chip-${idx}`}
              sx={{
                mr: 1,
                mb: 1,
                color: color,
                textTransform: 'capitalize',
                backgroundColor: backgroundColor,
              }}
            />
          ))
        ) : (
          <Typography variant="body" color="text.primary">
            {emptyMsg}
          </Typography>
        )}
      </Stack>
      {displayShowAll && chipSet.length > displayLimit && (
        <Button onClick={onShowClick} sx={{mt: 1, color: showAllColor}}>
          {isShowAll ? 'Show Less' : 'Show All'}
        </Button>
      )}
    </StyledChipsContainer>
  );
};

ChipStack.propTypes = {
  color: PropTypes.string,
  margin: PropTypes.string,
  chipSet: PropTypes.array,
  emptyMsg: PropTypes.string,
  showAllColor: PropTypes.string,
  displayLimit: PropTypes.number,
  displayShowAll: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

export default ChipStack;
