import React from 'react';
import {Text, View, StyleSheet} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    // flexWrap: 'wrap',
    alignItems: 'flex-start',
    // flex: 1,
    paddingBottom: 2,
    marginBottom: 3,
    width: '100%',
  },
  bulletPoint: {
    width: 5,
    fontSize: 18,
    marginRight: 4,
    marginTop: -4,
    color: '#4abaed',
  },
  itemContent: {
    fontSize: 9,
    fontWeight: 'normal',
    lineHeight: 1.15,
  },
});

const List = ({children}) => children;

export const Item = ({children, itemStyles = {}}) => (
  <View style={styles.item}>
    <Text style={styles.bulletPoint}>•</Text>
    <Text wrap style={{...styles.itemContent, ...itemStyles}}>
      {children}
    </Text>
  </View>
);

export default List;
