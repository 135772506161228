export const BUSINESS_PARTNERS_ROUTES = {
  COMPANY_PROFILE: {
    CREATE_PROFIE: '/company-profile',
  },
  SEND: {
    EMAIL: '/send/message',
  },
  HOME: {
    DASHBOARD: '/',
  },
  ADMIN: {
    USER_MANAGEMENT: '/user/management',
    ADD_USER: '/adduser',
  },
  PROGRAMS: {
    ADD_PROGRAM: '/add/program',
    PROGRAMS_LIST: '/programs',
    PROGRAM_DETAILS: '/program',
  },
  JOBS: {
    JOBS_LIST: '/jobs',
    JOBS_SUMMARY: '/job/summary',
  },
  OPPORTUNITIES: {
    ADD_OPPORTUNITY: '/add/opportunity',
    OPPORTUNITIES_LIST: '/opportunities',
    OPPORTUNITY_DETAILS: '/opportunity',
    // OPPORTUNITIES_LIST: '/opportunities',
    // OPPORTUNITIES_SUMMARY: '/opportunity/summary',
    // ADD_OPPORTUNITY: '/add/opportunity',
  },
  EVENTS: {
    ADD_EVENT: '/addevent',
    EDIT_EVENT: '/editevent',
    EVENTS_LIST: '/events',
  },
  WORKFORCE: {
    STUDENT_EXCHANGE: '/workforce/student-exchange',
    STUDENT_PROFILE: '/workforce/student-profile',
  },
};
