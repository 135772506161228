import React from 'react';
import {Stats as MUIStats} from 'mui-core';
import {StyledContainer} from './styles';

const ProgramActivity = ({data}) => {
  const prepareProgramInsightstatsData = () => {
    if (data) {
      const {saved, completed, enrolled, in_progress} = data;
      const statsData = [
        {
          title: 'Saved',
          count: saved,
          color: '#D77520',
        },
        {
          title: 'Enrolled',
          count: enrolled,
          color: '#1AB266',
        },
        {
          title: 'In Progress',
          count: in_progress,
          color: '#207A6C',
        },
        {
          title: 'Completed',
          count: completed,
          color: '#3EA0E5',
        },
      ];
      return statsData;
    }
    return [];
  };
  const usersData = prepareProgramInsightstatsData();
  return (
    <StyledContainer>
      <MUIStats data={usersData} />
    </StyledContainer>
  );
};

export default ProgramActivity;
