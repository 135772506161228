import React, {useEffect, useState} from 'react';
import './style.css';

import {Col, Row, Form, Icon, Select, Tag, Spin} from 'antd';
import {debounce} from 'lodash';
import {API} from 'config/businesspartner.config';
import {Services} from '../../services';
const services = new Services();
const defaultMinimumCharacter = 2;

const data = [
  {label: 'akash'},
  {label: 'acchu'},
  {label: 'akrhu'},
  {label: 'avinash'},
  {label: 'akara'},
  {label: 'akarka'},
  {label: 'adarhsa'},
];

const endpoint = `${API.administration.acheivement_options}`;

const FormItem = Form.Item;

const AwardTypeSearch = props => {
  // props destructure
  const {onChange, minimumCharacter, token, value} = props;
  //   state
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [awards, setAwards] = useState([]);
  //   controls
  useEffect(() => {
    if (value && Array.isArray(value));
    setAwards(value || []);
  }, []);
  const isAwardselected = label => {
    const exists = awards.filter(d => d.uuid === label)[0];
    return exists ? true : false;
  };
  const onLocationRemove = label => {
    const newawards = awards.filter(loc => loc.uuid !== label);
    setAwards(newawards);
    onLocationChange(newawards);
  };

  const onSelect = v => {
    console.log('selc', awards, v);
    const location = data.filter(i => i.uuid === v)[0];
    if (location) {
      if (!isAwardselected(v)) {
        const newawards = [...awards, location];
        setAwards(newawards);
        onLocationChange(newawards);
      } else {
        onLocationRemove(v);
      }
    }
  };
  const onSearch = debounce(term => {
    if (term && term.length >= (minimumCharacter || defaultMinimumCharacter)) {
      setLoading(true);
      services
        .fetchRecords(`${endpoint}?award_name=${term}`, token)
        .then(result => {
          setLoading(false);
          if (result && Array.isArray(result)) {
            const data = result;
            setData(data);
          }
        });
    }
  }, 1300);
  const onLocationChange = newawards => {
    if (onChange) onChange(newawards || awards);
  };

  const options =
    data &&
    Array.isArray(data) &&
    data.map(award => {
      const isSelected = isAwardselected(award.uuid);
      return (
        <Select.Option
          key={award.uuid}
          value={award.uuid}
          className={
            'option-selected-check' + (isSelected ? ' selected ' : '') // class styles wrote in sharedResourses/app.css
          }>
          {award.options}
          {isSelected && <Icon type="check" />}
        </Select.Option>
      );
    });
  console.log(awards, data);
  return (
    <div className="award-search filter-section">
      <div className="heading-text text-center">Filter by Award Type</div>
      <Row type="flex" gutter={42} align="middle">
        <Col sm={7}>Search by Award Type</Col>
        <Col sm={17} className="searchOccupation" style={{margin: '0 auto'}}>
          <FormItem>
            <>
              <Icon
                type="search"
                style={{
                  position: 'absolute',
                  zIndex: '1',
                  top: '4px',
                  left: '5px',
                }}
              />
              <Select
                showSearch
                loading={loading}
                showArrow={false}
                filterOption={false}
                defaultActiveFirstOption={false}
                allowClear={!loading}
                value={null}
                onSelect={onSelect}
                onSearch={onSearch}
                placeholder="Search"
                notFoundContent={
                  loading ? (
                    <Spin size="small" />
                  ) : (
                    'no award type matchs for search query'
                  )
                }
                style={{paddingLeft: '11px'}}>
                {options}
              </Select>
            </>
          </FormItem>
        </Col>
      </Row>
      <div className="program-study-selector filter-section">
        <div className="selected-section">
          <div className="lbl-s">
            {/* {required && <span className="required">* </span>} */}
            Selected Award Type (s):
          </div>
          <div className="tags-s">
            {(awards &&
              Array.isArray(awards) &&
              awards.length > 0 &&
              awards.map((award, index) => (
                <Tag
                  key={'pgm-tag' + award.uuid}
                  onClose={() => onLocationRemove(award.uuid)}
                  closable
                  className="occ-tag award-tag">
                  {award.options}
                </Tag>
              ))) || (
              <span
                style={{
                  position: 'relative',
                  top: '3px',
                }}>
                -
              </span>
            )}
            {/* {showErrors && selectedPrograms.length === 0 && (
            <FormItem>
              {getFieldDecorator('ss', {
                rules: [
                  {required: true, message: 'Please select the occupation'},
                ],
              })(<div style={{paddingBottom: 7}} />)}
            </FormItem>
          )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwardTypeSearch;
