import {styled} from '@mui/material/styles';
import {Box, Typography} from '@mui/material';
const StatsLabel = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '400',
  lineHeight: '24px',
  textTransform: 'uppercase',
}));

const StyledContainer = styled(Box)(() => ({
  margin: '16px 0px',
}));

export {StatsLabel, StyledContainer};
