import {combineReducers} from 'redux';

import institutions from './institutions';
import institutionTypes from './institutionTypes';
import instituteDetails from './instituteDetails';
import profile from './profile';

export const consortium = combineReducers({
  institutions,
  institutionTypes,
  instituteDetails,
  profile,
});
