import React from 'react';
import {formatPhoneNumber} from 'core/utils';
import {View, Text} from '@react-pdf/renderer';

export default ({styles, studentProfile}) => {
  const {address = {}, phone_number, email} =
    studentProfile.student_details || {};
  const {city, state} = address || {};
  const location = city && state ? city + ', ' + state : '----';
  const phoneNumber = formatPhoneNumber(phone_number);

  return (
    <>
      <View style={styles.colorHeader}>
        <Text>Overview</Text>
      </View>
      {location && (
        <View style={styles.section}>
          <Text style={styles.label}>Lives In</Text>
          <Text style={styles.value}>{location}</Text>
        </View>
      )}
      {email && (
        <View style={styles.section}>
          <Text style={styles.label}>Email Address</Text>
          <Text style={styles.value}>{email}</Text>
        </View>
      )}
      {phoneNumber !== '' && (
        <View style={styles.section}>
          <Text style={styles.label}>Phone Number</Text>
          <Text style={styles.value}>{phoneNumber}</Text>
        </View>
      )}
    </>
  );
};
