import * as TYPES from './constants';
import {
  requestDashboardProgramEnrollmentData,
  receiveDashboardProgramEnrollmentData,
  handleDashboardProgramEnrollmentDataError,
} from './actions';
import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {
  data: null,
  request: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_DASHBOARD_PROGRAM_ENROLLMENT_DATA:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_DASHBOARD_PROGRAM_ENROLLMENT_DATA:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.DASHBOARD_PROGRAM_ENROLLMENT_DATA_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchDashboardProgramEnrollmentData = searchInput => (
  dispatch,
  getState
) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();

  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestDashboardProgramEnrollmentData());
  try {
    const params = `?page=1&page_size=30${(searchInput &&
      '&search=' + searchInput) ||
      ''}`;
    return fetch(`${API.pathway.publish + params}`, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveDashboardProgramEnrollmentData(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleDashboardProgramEnrollmentDataError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleDashboardProgramEnrollmentDataError(err));
  }
};
