import React from 'react';
import {Form, Input, Select} from 'antd';
import {PATTERN} from 'core';

const FormItem = Form.Item;
const Option = Select.Option;

const Phone = ({
  getFieldDecorator,
  required,
  label,
  fieldName,
  prefixFieldName,
  data,
  removeColon,
}) => {
  const dataLen = data && data.length;
  const prefixSelector = getFieldDecorator(
    `${prefixFieldName || 'country_code'}`,
    {
      initialValue: (data && data.substring(0, dataLen - 10)) || '+1',
    }
  )(
    <Select>
      <Option value="+1">+1</Option>
    </Select>
  );
  return (
    <FormItem label={label} colon={removeColon ? false : true}>
      {getFieldDecorator(fieldName, {
        rules: [
          {
            required,
            message: 'Please input your phone number with valid country code',
          },
          {
            pattern: PATTERN.Phone,
            message: 'Please enter a valid 10 digit phone number',
          },
        ],
        initialValue: data && data.substring(dataLen - 10, dataLen),
      })(<Input minLength={10} maxLength={10} addonBefore={prefixSelector} />)}
    </FormItem>
  );
};

export default Phone;
