import React from 'react';

import {CustomCollapse, CustomTabs, Stats} from 'core';

import PanelHOC from './panelHOC';
import TimelineContainer from './timelineContainer';
import ProgressbarContainer from './progressbarContainer';

/**
const statsData = [
  {
    title: 'Total Pathways',
    count: 14,
    color: 'green',
  },
  {
    title: 'CTE Pathways',
    count: 23,
    color: 'orange',
  },
];
**/

const graphPointStyling = {
  pointBackgroundColor: '#ffffff',
  pointRadius: 5,
  pointHoverRadius: 6,
};

const STATIC_TIMELINE_DATA = {
  labels: [],
  datasets: [
    {
      key: 'total_pathways',
      label: 'Total Pathways',
      data: [],
      borderColor: 'rgb(26, 178, 102)',
      backgroundColor: 'rgb(26, 178, 102)',
      pointHoverBackgroundColor: 'rgb(26, 178, 102)',
      ...graphPointStyling,
    },
    {
      key: 'cte_pathways',
      label: 'CTE Pathways',
      data: [],
      borderColor: 'rgb(250, 180, 10)',
      backgroundColor: 'rgb(250, 180, 10)',
      pointHoverBackgroundColor: 'rgb(250, 180, 10)',
      ...graphPointStyling,
    },
  ],
};

/** // For testing
const DUMMY_DATA = {
  total_pathways: {
    timeline: {
      2020: {
        Jan: 0,
        Feb: 5,
        Mar: 8,
        Apr: 12,
        May: 16,
        Jun: 20,
        Jul: 22,
        Aug: 23,
        Sep: 25,
        Oct: 27,
        Nov: 29,
        Dec: 30,
      },
    },
  },
  cte_pathways: {
    timeline: {
      2020: {
        Jan: 2,
        Feb: 5,
        Mar: 10,
        Apr: 11,
        May: 13,
        Jun: 15,
        Jul: 17,
        Aug: 20,
        Sep: 23,
        Oct: 25,
        Nov: 28,
        Dec: 30,
      },
    },
  },
};
**/

const calculatePathwaysStats = data => {
  if (data && data.student_pathway && data.student_pathway.pathways) {
    const {total_pathways, cte_pathways} = data.student_pathway.pathways;
    return [
      {
        title: 'Total Pathways',
        count: total_pathways || 0,
        color: 'green',
      },
      {
        title: 'CTE Pathways',
        count: cte_pathways || 0,
        color: 'orange',
      },
    ];
  }
  return [];
};

const prepareTimelineData = data => {
  if (data && data.student_pathway && data.student_pathway.pathways) {
    const {
      total_pathways_timeline,
      cte_pathways_timeline,
    } = data.student_pathway.pathways;
    return {
      total_pathways: {
        timeline: total_pathways_timeline,
      },
      cte_pathways: {
        timeline: cte_pathways_timeline,
      },
    };
  }
};

function Pathways(props) {
  const {data: propData} = props;
  const PATHWAYS_STATS = calculatePathwaysStats(propData);
  const TIMELINE_DATA = prepareTimelineData(propData);

  const TabsBarGraphData = [
    {
      title: 'PROGRAMS OF STUDY BY PATHWAY COUNT',
      key: 'program_of_study_by_pathway_count',
      children: (
        <ProgressbarContainer
          displayDataNestedObj
          data={propData && propData.programs_of_study_by_pathway_count}
          emptyMessage="There are no active pathways."
          sortingOptions={[
            {
              sortby: 'interest',
              title: 'Enrollment',
            },
            {
              sortby: 'title',
              title: 'Program Title',
            },
          ]}
        />
      ),
    },
    {
      title: 'PROGRAMS OF STUDY BY ENROLLMENT',
      key: 'program_of_study_by_enrollment',
      children: (
        <ProgressbarContainer
          displayDataNestedObj
          data={propData && propData.programs_of_study_by_enrollment}
          emptyMessage="There are no active pathways."
          sortingOptions={[
            {
              sortby: 'interest',
              title: 'Enrollment',
            },
            {
              sortby: 'title',
              title: 'Program Title',
            },
          ]}
        />
      ),
    },
  ];

  const data = [
    {
      title: 'PATHWAYS',
      key: 'pathways',
      passData: '',
      childern: (
        <div className="content">
          <Stats data={PATHWAYS_STATS} />
          <TimelineContainer
            data={TIMELINE_DATA}
            staticData={STATIC_TIMELINE_DATA}
          />
          <CustomTabs
            data={TabsBarGraphData}
            tabBarStyle={{
              height: '40px',
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <CustomCollapse
      data={data}
      switchPanel={props.switchPanel}
      activeKey={props.activeKey || 'pathways'}
    />
  );
}

export default PanelHOC(Pathways);
