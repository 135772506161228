// components
import {
  Box,
  Alert,
  Stack,
  Avatar,
  Button,
  styled,
  Tooltip,
  useTheme,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {Grid, ContentBox} from 'mui-core';
import ResumePreview from './ResumePreview';
import NoDataPreview from './NoDataPreview';
import {LocationOn, InsertDriveFile, Download} from '@mui/icons-material';
// utils
import {
  xLogoBlack,
  tiktokBlack,
  linkedInBlack,
  faceBookBlack,
  instagramBlack,
  mediumLogoBlack,
} from 'sharedResources/assets/images';
import {isArray, getGoBanner, getCloudinaryImage} from 'mui-core/utils';
import profilePreview from 'data/profilePreview.json';

const {
  noDataPreviewHeading,
  resumePreviewHeading,
  workPreferences: {openTo = {}, employeeStatus = {}} = {},
} = profilePreview;
const {aJob, anApprenticeship, anInternship} = openTo;
const {openToWork, openToAnApprenticeship, openToAnInternship} = employeeStatus;

const ProfileDetailsContainer = styled(Box)(() => ({
  zIndex: '2',
  display: 'flex',
  marginLeft: '32px',
  alignItems: 'start',
  position: 'relative',
  justifyContent: 'flex-start',
}));

const AvatarContainer = styled(Avatar)(() => ({
  zIndex: 9,
  display: 'flex',
  marginTop: '-60px',
  position: 'relative',
  marginBottom: '21px',
  alignContent: 'center',
  justifyContent: 'center',
}));

const HandelDocuments = ({documents = [], title, filterBy, url = null}) => {
  const filteredDocs = documents.filter(doc => doc.doc_type === filterBy);

  return (
    <Stack>
      {((filteredDocs && filteredDocs.length > 0) || url) && (
        <Typography
          pl={0.5}
          variant="h5"
          lineHeight={2}
          color="text.primary"
          textTransform="capitalize">
          {title}:
        </Typography>
      )}
      {url && (
        <Tooltip title={url}>
          <Typography
            href={url}
            component="a"
            maxWidth={180}
            variant="body1"
            target="_blank"
            download="file"
            sx={{
              overflow: 'hidden',
              paddingLeft: '12px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              textDecoration: 'underline',
            }}>
            {url}
          </Typography>
        </Tooltip>
      )}
      {filteredDocs &&
        filteredDocs.length > 0 &&
        filteredDocs.map(doc => (
          <Stack key={doc.doc_uuid}>
            <Stack flexDirection="row">
              <Button
                variant="text"
                href={doc.doc_link}
                startIcon={<InsertDriveFile />}>
                {doc.doc_name}
              </Button>
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
};

const TagContainer = styled(Stack)(() => ({
  top: 24,
  left: 0,
  zIndex: 1,
  position: 'absolute',
}));

const Tag = styled(Alert)(() => ({
  padding: '4px 24px',
  borderRadius: '0 42px 42px 0',
}));

const ProfilePreview = ({
  data,
  openTo,
  resumeList,
  profileData,
  handleResumeDownload,
}) => {
  const theme = useTheme();
  const bannerImage = getGoBanner(data?.banner);
  const profileImage = getCloudinaryImage(data?.photo, 'logo');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const resumeId =
    (isArray(resumeList) &&
      resumeList.length > 0 &&
      resumeList[0].resume_uuid) ||
    '';
  const cognitoUserName = data?.cognito_username;
  const resumeName =
    (isArray(resumeList) &&
      resumeList.length > 0 &&
      resumeList[0].resume_name) ||
    '';

  const SocialIconsButton = props => {
    const {link, src, alt} = props;
    return (
      <IconButton
        size="small"
        href={link}
        target="_blank"
        sx={{filter: `invert(${theme.palette.mode === 'dark' ? 1 : 0})`}}>
        <img
          src={src}
          alt={alt}
          style={{maxHeight: isMobile && '14px', maxWidth: isMobile && '14px'}}
        />
      </IconButton>
    );
  };

  const student_information =
    (profileData &&
      Array.isArray(profileData) &&
      profileData.length > 0 &&
      profileData[0]) ||
    {};

  const documentTypes = [
    {
      type: 'transcript',
      title: 'Transcripts',
      documentData: student_information?.documents,
    },
    {
      type: 'cover-letter',
      title: 'Cover Letter',
      documentData: student_information?.documents,
    },
    {
      type: 'portfolio',
      title: 'Portfolio link',
      url: student_information?.portfolio_url,
      documentData: student_information?.documents,
    },
  ];

  let employmentStatus = '';

  if (openTo === aJob) {
    employmentStatus = openToWork;
  } else if (openTo === anApprenticeship) {
    employmentStatus = openToAnApprenticeship;
  } else if (openTo === anInternship) {
    employmentStatus = openToAnInternship;
  }

  return (
    <Box>
      <Stack mb={2}>
        <img
          width="100%"
          height="215px"
          src={bannerImage}
          style={{
            padding: '0px !important',
            borderRadius: '20px 20px 0px 0px',
            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
          }}
        />
        {employmentStatus && (
          <TagContainer>
            <Tag severity="info" variant="filled">
              <Typography variant="caption">{employmentStatus}</Typography>
            </Tag>
          </TagContainer>
        )}

        <ProfileDetailsContainer>
          <AvatarContainer
            alt="avatar-logo"
            src={profileImage}
            sx={{
              border: '1px solid white',
              width: {xs: '80px', sm: '150px'},
              height: {xs: '80px', sm: '150px'},
            }}
          />
          <Stack gap={0.4} pl={1} pt={0.3}>
            {data?.first_name && (
              <Typography
                pl={0.5}
                variant="h5"
                lineHeight={1.5}
                color="text.primary"
                textTransform="capitalize">
                {`${data?.first_name} ${data?.last_name}`}
              </Typography>
            )}
            {data?.career_headline && (
              <Typography pl={0.5} variant="body2" color={'text.primary'}>
                {data?.career_headline}
              </Typography>
            )}
            {data?.address?.city && data?.address?.state && (
              <Stack direction="row" alignItems="center">
                <LocationOn
                  sx={{
                    fontSize: isMobile ? 'small' : '20px',
                    color:
                      theme.palette.mode === 'light'
                        ? theme.palette.text.secondary
                        : theme.palette.darkGray.contrastText,
                  }}
                />
                <Typography
                  variant="body2"
                  textTransform="capitalize"
                  color={
                    theme.palette.mode === 'light'
                      ? theme.palette.text.secondary
                      : theme.palette.darkGray.contrastText
                  }
                  sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                  {data?.address?.city + ', ' + data?.address?.state}
                </Typography>
              </Stack>
            )}
            <Stack gap={0.5} direction="row">
              {data && data.facebook_link && data.show_facebook_link && (
                <SocialIconsButton
                  alt="fb"
                  src={faceBookBlack}
                  link={data.facebook_link}
                />
              )}

              {data && data.twitter_link && data.show_twitter_link && (
                <SocialIconsButton
                  alt="tw"
                  src={xLogoBlack}
                  link={data.twitter_link}
                />
              )}

              {data && data.linkedin_link && data.show_linkedin_link && (
                <SocialIconsButton
                  alt="li"
                  src={linkedInBlack}
                  link={data.linkedin_link}
                />
              )}

              {data && data.instagram_link && data.show_instagram_link && (
                <SocialIconsButton
                  alt="ig"
                  src={instagramBlack}
                  link={data.instagram_link}
                />
              )}

              {data && data.tiktok_link && data.show_tiktok_link && (
                <SocialIconsButton
                  alt="tt"
                  src={tiktokBlack}
                  link={data.tiktok_link}
                />
              )}

              {data && data.medium_link && data.show_medium_link && (
                <SocialIconsButton
                  alt="med"
                  src={mediumLogoBlack}
                  link={data.medium_link}
                />
              )}
            </Stack>
          </Stack>
        </ProfileDetailsContainer>
      </Stack>

      <Box>
        <Grid container mt={2} ml={4}>
          {documentTypes.map((doc, index) => {
            return (
              <Grid
                item
                sm={5}
                md={4}
                lg={4}
                xs={12}
                key={index}
                display="flex"
                justifyContent={{xs: 'left', md: 'center'}}>
                <HandelDocuments
                  url={doc?.url}
                  title={doc?.title}
                  filterBy={doc?.type}
                  documents={doc?.documentData}
                />
              </Grid>
            );
          })}
        </Grid>

        <Box
          sx={{
            height: '100%',
            borderRadius: '10px',
            position: 'relative',
          }}>
          <ContentBox sx={{m: 3}}>
            {isArray(resumeList) &&
            resumeList.length > 0 &&
            resumeList[0].resume_uuid ? (
              <>
                <Stack
                  sx={{
                    right: 50,
                    zIndex: 9,
                    marginTop: '4px',
                    position: 'absolute',
                  }}>
                  <IconButton
                    size="medium"
                    title="Download"
                    color="text.primary"
                    aria-label="download"
                    onClick={() =>
                      handleResumeDownload(
                        resumeId,
                        cognitoUserName,
                        resumeName
                      )
                    }>
                    <Download fontSize="medium" />
                  </IconButton>
                </Stack>
                <ResumePreview
                  disableScroll
                  isShareProfile
                  disableEyeIcon
                  isMobile={isMobile}
                  data={resumeList[0]}
                  heading={resumePreviewHeading}
                />
              </>
            ) : (
              <NoDataPreview
                disableEyeIcon
                isShareProfile
                heading={noDataPreviewHeading}
              />
            )}
          </ContentBox>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfilePreview;
