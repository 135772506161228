import {combineReducers} from 'redux';
import jobs from './jobsList';
import educationLevel from './educationLevel';
import experienceLevel from './experienceLevel';
import qualifyingPrograms from './qualifyingPrograms';

export const jobsReducer = combineReducers({
  jobsList: jobs,
  educationLevel: educationLevel,
  experienceLevel: experienceLevel,
  qualifyingPrograms: qualifyingPrograms,
});
