import {createSelector} from 'reselect';
import {
  isEmpty,
  calculateQuaterlyMonthsData,
  getSortedUniqueYears,
  prepareTimelineLables,
  prepareTimeLineDatasets,
} from 'core';

// Static Data for TimeLine Manipulations
const staticTimeLineData = {
  labels: [],
  datasets: [
    {
      key: 'college',
      label: 'College Paths',
      data: [],
      borderColor: 'rgb(250, 180, 10)',
      backgroundColor: 'rgb(250, 180, 10)',
    },
    {
      key: 'onboarding',
      label: 'Onboard Paths',
      data: [],
      borderColor: 'rgb(45, 201, 224)',
      backgroundColor: 'rgb(45, 201, 224)',
    },
    {
      key: 'general education',
      label: 'General Education Options',
      data: [],
      borderColor: '#1AB266',
      backgroundColor: '#1AB266',
    },
  ],
};

//Career Paths :::
export const getPathsSelector = state =>
  state &&
  state.pathways &&
  state.pathways.careerPaths &&
  state.pathways.careerPaths.data;
export const geAwardTypesSelector = state =>
  state &&
  state.pathways &&
  state.pathways.careerPaths &&
  state.pathways.careerPaths.originalUnMutedData &&
  state.pathways.careerPaths.originalUnMutedData.reduce(
    (current, item) =>
      current.includes(item.award_type)
        ? current
        : [...current, item.award_type],
    []
  );
export const getPageDetailsSelector = state =>
  state &&
  state.pathways &&
  state.pathways.careerPaths &&
  state.pathways.careerPaths.page_details;
export const getImmutablePathsSelector = state =>
  state &&
  state.pathways &&
  state.pathways.careerPaths &&
  state.pathways.careerPaths.originalUnMutedData;
export const getclusterTypes = state =>
  state &&
  state.pathways &&
  state.pathways.pathways &&
  state.pathways.pathways.clusterTypes;
export const getPathsNames = state =>
  state &&
  state.pathways &&
  state.pathways.pathways &&
  state.pathways.pathways.pathNames;
const getErrorSelector = state =>
  state &&
  state.pathways &&
  state.pathways.careerPaths &&
  state.pathways.careerPaths.error;
export const getRequestSelector = state =>
  state &&
  state.pathways &&
  state.pathways.careerPaths &&
  state.pathways.careerPaths.request;

export const getPaths = createSelector(
  [
    getRequestSelector,
    getPathsSelector,
    getPageDetailsSelector,
    geAwardTypesSelector,
    getErrorSelector,
  ],
  (
    getRequestSelector,
    getPathsSelector,
    getPageDetailsSelector,
    geAwardTypesSelector,
    getErrorSelector
  ) => ({
    request: getRequestSelector,
    error: getErrorSelector,
    data: getPathsSelector || null,
    page_details: getPageDetailsSelector || null,
    award_types: geAwardTypesSelector || null,
  })
);

export const getImmutablePaths = createSelector(
  [getRequestSelector, getImmutablePathsSelector, getErrorSelector],
  (getRequestSelector, getImmutablePathsSelector, getErrorSelector) => ({
    request: getRequestSelector,
    error: getErrorSelector,
    data: getImmutablePathsSelector || null,
  })
);

export const getAllProgramsFromClusters = state => {
  const clusters = state && state.pathways && state.pathways.pathways.clusters;
  if (clusters && clusters.length > 0) {
    const programs = [];
    clusters.forEach(cluster => {
      cluster.programs &&
        cluster.programs.length > 0 &&
        cluster.programs.forEach(program => {
          const pg = program;
          pg.cluster = {
            uuid: cluster.uuid,
            name: cluster.name,
          };
          programs.push(pg);
        });
    });
    return programs;
  }
  return [];
};

export const countPaths = createSelector(
  [getPathsSelector],
  getPaths => {
    const pathCountObj = {};
    getPaths &&
      getPaths.length &&
      getPaths.forEach(path => {
        let path_type_name = path.path_type_name;
        if (!pathCountObj[path_type_name]) {
          pathCountObj[path_type_name] = {count: 0, data: []};
        }
        if (pathCountObj[path_type_name]) {
          pathCountObj[path_type_name]['count'] += 1;
          pathCountObj[path_type_name]['data'].push(path);
        }
      });
    return pathCountObj;
  }
);

// Filter Paths :::
export const getFilterPathsSelector = state => state.pathways.filterPaths.data;
const getFilterPathErrorSelector = state => state.pathways.filterPaths.error;
const getFilterPathRequestSelector = state =>
  state.pathways.filterPaths.request;
const getPathsCountSelector = state => state.pathways.filterPaths.pathCounts;
const getGraphDataSelector = state => state.pathways.filterPaths.graphData;
const monthsDataSelector = state => state.pathways.filterPaths.monthsData;

// calculate graph data :::
export const graphData = createSelector(
  [getGraphDataSelector, monthsDataSelector],
  (getGraphDataSelector, monthsDataSelector) => {
    if (getGraphDataSelector) {
      const timeLineData = {};
      const data = getGraphDataSelector;
      // const labels = [];

      const uniqYears = getSortedUniqueYears(data);
      const labels = prepareTimelineLables(data, uniqYears);
      const datasets = prepareTimeLineDatasets(
        staticTimeLineData,
        data,
        uniqYears,
        monthsDataSelector
      );

      // Object.keys(data).map(path => {
      //   if (data[path] && !isEmpty(data[path])) {
      //     Object.keys(data[path]).map(year => {
      //       Object.keys(data[path][year]).map(month => {
      //         if (!labels.includes(`${month}-${year}`)) {
      //           labels.push(`${month}-${year}`);
      //         }
      //       });
      //     });
      //   }
      // });
      // timeLineData.labels = labels;
      // const datasets = [];
      // staticTimeLineData.datasets.map(pathData => {
      //   if (data[pathData.key]) {
      //     Object.keys(data[pathData.key]).map(year => {
      //       let calculatedData = Object.values(data[pathData.key][year]);
      //       if (monthsDataSelector !== 'all') {
      //         calculatedData = calculateQuaterlyMonthsData(
      //           monthsDataSelector,
      //           calculatedData
      //         );
      //       }

      //       datasets.push(
      //         Object.assign({}, pathData, {
      //           data: calculatedData,
      //         })
      //       );
      //     });
      //   }
      // });
      timeLineData.labels = labels;
      timeLineData.datasets = datasets;

      if (monthsDataSelector !== 'all') {
        timeLineData.labels = calculateQuaterlyMonthsData(
          monthsDataSelector,
          timeLineData.labels,
          uniqYears
        );
      }
      return timeLineData;
    }
  }
);

export const getFilteredPaths = createSelector(
  [
    getFilterPathRequestSelector,
    getFilterPathsSelector,
    getPathsCountSelector,
    getFilterPathErrorSelector,
  ],
  (
    getFilterPathRequestSelector,
    getFilterPathsSelector,
    getPathsCountSelector,
    getFilterPathErrorSelector
  ) => ({
    request: getFilterPathRequestSelector,
    error: getFilterPathErrorSelector,
    data: getFilterPathsSelector,
    pathCounts: getPathsCountSelector,
  })
);

// Search Course :::
const courseSelector = state => state.pathways.courseSearch.data;
const getSearchCourseErrorSelector = state => state.pathways.courseSearch.error;
const getSearchCourseRequestSelector = state =>
  state.pathways.courseSearch.request;

export const getCourse = createSelector(
  [
    getSearchCourseRequestSelector,
    courseSelector,
    getSearchCourseErrorSelector,
  ],
  (
    getSearchCourseRequestSelector,
    courseSelector,
    getSearchCourseErrorSelector
  ) => {
    if (getSearchCourseRequestSelector) {
      return {request: true};
    }
    if (getSearchCourseErrorSelector) {
      return {error: true};
    }
    return courseSelector;
  }
);

//Search Paths :::
const pathDetailsSelector = state => state.pathways.pathSearch.data;
const getSearchPathErrorSelector = state => state.pathways.pathSearch.error;
const getSearchPathRequestSelector = state => state.pathways.pathSearch.request;

export const getPathDetails = createSelector(
  [
    getSearchPathRequestSelector,
    pathDetailsSelector,
    getSearchPathErrorSelector,
  ],
  (
    getSearchPathRequestSelector,
    pathDetailsSelector,
    getSearchPathErrorSelector
  ) => {
    if (getSearchPathRequestSelector) {
      return {request: true};
    }
    if (getSearchPathErrorSelector) {
      return {error: true};
    }
    return pathDetailsSelector;
  }
);

// Pathways
export const getAchivements = state => state.pathways.pathways.achivements;
export const getCourseTypes = state => state.pathways.pathways.courseTypes;
export const getMilestoneTypes = state =>
  state.pathways.pathways.milestoneTypes;
export const getProgramDuration = state =>
  state.pathways.pathways.programDuration;
export const getPrograms = state => state.pathways.searchPrograms;
export const getClusters = state =>
  state && state.pathways && state.pathways.pathways.clusters;
export const getPathWaysToken = state => state.auth.pathways;

// Onboarding Path selector
export const getPathwaysRequestSelector = state =>
  state.pathways.pathways.request;
export const getOnboardingPaths = state =>
  state.pathways.pathways.onboarding_paths;
export const getOnboardingPathsTypes = state =>
  state.pathways.pathways.onboarding_paths_types;
export const saveOnboardingPaths = state =>
  state.pathways.pathways.onboarding_path;
export const apiRequestTime = state =>
  state.pathways.filterPaths.apiRequestTime;

// gened paths

export const getGenedPaths = state => state.pathways.genedPaths.data;

// onboard paths selectors

export const getOnBoardPaths = state => state.pathways.onBoardingPaths;

// Overview AI Generator
export const getAIGeneratedOverviewContent = state =>
  state.pathways.overviewAIGenerator;
