import React from 'react';
import {Icon} from 'antd';
import {NoSearchResults} from 'core';

const DocumentUpload = ({data: {documents = []} = {}, title}) => {
  const filteredDocs =
    title === 'Transcript'
      ? documents.filter(doc => doc.doc_type === 'transcript')
      : documents.filter(doc => doc.doc_type === 'related_document');
  return (
    <>
      {filteredDocs && filteredDocs.length > 0 && (
        <div className="work-experience">
          <div className="work-experience-header">{title}</div>
          <div className="work-experience-content">
            <div className="_list-details">
              <div className="_details-content">
                <div className="_docs-list">
                  {filteredDocs &&
                    filteredDocs.length > 0 &&
                    filteredDocs.map(doc => (
                      <div className="_item" key={doc.doc_uuid}>
                        <div className="_ic">
                          <Icon type="file" />
                        </div>
                        <a
                          href={doc.doc_link}
                          target="_blank"
                          className="px-2"
                          download="file">
                          {doc.doc_name}
                        </a>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentUpload;
