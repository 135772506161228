import React, {Component} from 'react';
import {ApolloProvider} from 'react-apollo';
import {Rehydrated} from 'aws-appsync-react';

import {client} from 'config/graphqlConfig/';

const GraphqlWrapper = HocComponent =>
  class extends Component {
    render() {
      return (
        <ApolloProvider client={client}>
          <Rehydrated>
            <HocComponent {...this.props} />
          </Rehydrated>
        </ApolloProvider>
      );
    }
  };

export default GraphqlWrapper;
