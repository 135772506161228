import React from 'react';
import {Row, Col, Icon} from 'antd';

import {useDrag} from 'react-dnd';
import {isNumber} from 'core';

const MANDATORY_FIELDS = ['course_id', 'title', 'description', 'units'];

const style = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const ListItem = ({
  data = {},
  itemType,
  noDrag,
  checkIfAddedToTerm,
  termIndex,
  itemIndex,
  onDeleteCourse,
  onClick,
  notCheckedForRequiredFields,
  itemLeftWidth,
  itemRightWidth,
}) => {
  const checkAllRequiredInfo = () => {
    if (!notCheckedForRequiredFields) {
      for (let i = 0; i < MANDATORY_FIELDS.length; i++) {
        const FIELD = MANDATORY_FIELDS[i];
        if (
          data[FIELD] === undefined ||
          data[FIELD] === '' ||
          data[FIELD] === null
        ) {
          if (isNumber(data[FIELD]) && data[FIELD] == 0) {
            continue;
          }
          return false;
        }
      }
    }
    return true;
  };
  let isExists = checkIfAddedToTerm && checkIfAddedToTerm(data);
  const [{isDragging}, drag] = useDrag({
    item: {
      data,
      type: 'TERMS_ITEM',
      itemType,
      termIndex,
      itemIndex,
    },
    canDrag: () => {
      return checkAllRequiredInfo();
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const {course_id, units} = data;

  const isDragable = checkAllRequiredInfo();

  const opacity = isDragging ? 0.4 : 1;
  const cursor =
    (isDragable && (isDragging ? 'grabbing' : 'grab')) || 'pointer';

  return (
    <div
      className="card-list-item"
      ref={drag}
      style={{opacity, cursor, pointerEvents: isExists ? 'none' : 'initial'}}
      onClick={() => !isDragable && onClick && onClick(data)}>
      {data &&
        (itemType === 'course' ||
          itemType === 'core' ||
          itemType === 'general' ||
          itemType === 'elective') && (
          <Row className="courseHeader courseItem course-data">
            <Col
              span={itemLeftWidth || 20}
              style={{...style}}
              className="course-id">
              {!isDragable && <span className="icon-icuparrow" />}
              {course_id}
            </Col>
            <Col span={itemRightWidth || 4}>
              <Row>
                <Col span={12}>{units || '-'}</Col>
                <Col span={12}>
                  {isExists && (
                    <Icon className="item-icon checked" type="check-circle" />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      {data && itemType === 'terms-item' && (
        <div className="item">
          <div className="item-title">
            {data.course_id ||
              data.segment_alter_name ||
              data.title ||
              data.name ||
              data.segment_name}
          </div>
          <Icon
            className="item-icon"
            type="delete"
            onClick={() => onDeleteCourse(termIndex, itemIndex)}
          />
        </div>
      )}
    </div>
  );
};

export default ListItem;
