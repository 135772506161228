import * as TYPES from './constants';
import {
  requestReports,
  receiveReports,
  handleReportsError,
  cancelReportFirstTimeLoad,
} from './actions';

import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {
  data: null,
  request: false,
  error: null,
  firstTimeLoad: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_REPORTS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_REPORTS:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.REPORTS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.REPORTS_FIRST_LOAD:
      return {...state, firstTimeLoad: false};
    default:
      return state;
  }
};

export const fetchReports = (Obj, pageSize = 1, pageNo = 1) => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  let endPoint = `${
    API.reports.report
  }?page_size=${pageSize}&page_num=${pageNo}`;
  if ((Obj && Object.keys(Obj).length) || pageNo > 1) {
    dispatch(cancelReportFirstTimeLoad());
  }
  if (Obj && Object.keys(Obj).length) {
    Object.keys(Obj).forEach(key => {
      if (key === 'selectedFilter' && Obj[key] !== 'all') {
        endPoint = endPoint + '&status=' + Obj[key];
      }
      if (key === 'searchTerm' && Obj[key].trim()) {
        endPoint = endPoint + '&name=' + Obj[key];
      }
    });
  }
  dispatch(requestReports());
  try {
    return fetch(endPoint, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveReports(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleReportsError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleReportsError(err));
  }
};
