import * as TYPES from './constants';

import {
  requestSearchCourse,
  handleSearchCourseError,
  receiveSearchCourse,
} from './actions';

import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const courseData = require('./demoCourseSearch.json');

const initialState = {
  data: null,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_SEARCH_COURSE:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_SEARCH_COURSE:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.REMOVE_SEARCH_COURSE:
      return {
        ...state,
        data: null,
      };
    case TYPES.REQUEST_SEARCH_COURSE_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchCourse = courseId => (dispatch, getState) => {
  // const secret = `${API.pathway.secret}`;
  const state = getState();
  let accessToken = null;
  if (state && state.auth && state.auth.access) {
    const {
      auth: {
        access: {token},
      },
    } = state;
    accessToken = token;
  }

  if (accessToken) {
    const config = {
      method: 'GET',
      mode: 'cors',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
      cache: 'default',
    };
    dispatch(requestSearchCourse());
    try {
      return (
        fetch(
          `${API.course_management.courses}?course_id=${courseId}&status=True`,
          config
        )
          .then(response => response.json())
          // .then(response => filterStaticCourse(courseId)) // added to make search static for the demo purpose
          .then(json => {
            dispatch(receiveSearchCourse(json));
          })
          .catch(err => {
            dispatch(handleSearchCourseError(err));
          })
      );
    } catch (err) {
      dispatch(handleSearchCourseError(err));
    }
  }
};

const filterStaticCourse = id => {
  const result = courseData.filter(item => item.courseId === id);
  if (result && result.length > 0) return result[0];
  else return {status: 404, message: 'ID not found'};
};
