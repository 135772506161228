import * as TYPES from './constants';

import {
  requestCareerPaths,
  handleCareerPathsError,
  receiveCareerPaths,
  requestOnboardingPaths,
  receiveOnboardingPaths,
  handleOnboardingPathsError,
} from './actions';

import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import {sortArrayOfObjPropertyBased} from 'core';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_CAREER_PATHS:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECEIVE_CAREER_PATHS:
      return {
        ...state,
        originalUnMutedData: !state.originalUnMutedData
          ? action.payload.data
          : state.originalUnMutedData,
        data: action.payload.data,
        // state.searchTerm && state.searchTerm != ''
        //   ? searchingPaths(state.searchTerm, action.payload)
        //   : action.payload,
        page_details: action.payload.page_details,
        request: false,
        error: false,
      };
    case TYPES.CLEAR_CAREER_PATH:
      return {
        ...state,
        orignalUnMuteddata: null,
        searchTerm: null,
        data: [],
        request: false,
        error: false,
      };
    case TYPES.REQUEST_CAREER_PATHS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.COUNT_PATHS:
      return {
        ...state,
        pathsCount: action.payload,
      };
    case TYPES.DELETE_CAREER_PATH:
      return {
        ...state,
        data: state.data.filter(
          careerPath => careerPath.uuid != action.payload
        ),
      };

    case TYPES.SEARCH_CAREER_PATH:
      return {
        ...state,
        data: searchingPaths(
          action.payload.toLowerCase(),
          state.orignalUnMuteddata
        ),
        page_details: action.payload.page_details,
        searchTerm: action.payload.toLowerCase(),
        request: false,
        error: false,
      };

    default:
      return state;
  }
};

// const searchTerm = action.payload.toLowerCase();
const searchingPaths = (term, data) => {
  return data.filter(path => {
    if (path.OnboardingType) {
      return path.OnboardingType.name.toLowerCase().includes(term);
    } else {
      return path.title.toLowerCase().includes(term);
    }
  });
};

export const fetchCareerPaths = ({
  page,
  award_type,
  status,
  sort,
  search,
} = {}) => (dispatch, getState) => {
  const state = getState();
  let accessToken = null;
  let PathNames = null;
  if (state && state.auth && state.auth.access) {
    const {
      auth: {access: {token} = {}},
      pathways: {pathways: {pathNames} = {}} = {},
    } = state;
    accessToken = token;
    PathNames = pathNames;
  }

  if (accessToken) {
    const config = {
      method: 'GET',
      mode: 'cors',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
      cache: 'default',
    };
    dispatch(requestCareerPaths());
    try {
      const params = `?page=${page || 1}&sort=${sort ||
        'recent'}&${(award_type && '&award_type=' + award_type) ||
        ''}${(status && '&status=' + status) || ''}${(search &&
        '&search=' + search) ||
        ''}`;
      return fetch(`${API.pathway.publish + params}`, config)
        .then(response => response.json())
        .then(json => {
          if (json) {
            // const SortedPaths =
            //   json && sortArrayOfObjPropertyBased(json.data, 'title');
            // const collegePathObj =
            //   PathNames &&
            //   PathNames.filter(
            //     path => path.path_name.trim().toLowerCase() === 'college'
            //   )[0];
            // const updatedCollegePaths = SortedPaths.map(path => {
            //   if (path) {
            //     path.path_type_name = collegePathObj && collegePathObj.uuid;
            //     return path;
            //   }
            // });
            // dispatch(fetchGeneralEdPaths(updatedCollegePaths));
            dispatch(
              receiveCareerPaths({
                data: json.data,
                page_details: json.page_details,
              })
            );
          }
        })
        .catch(err => {
          console.log(err);
          dispatch(handleCareerPathsError(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(handleCareerPathsError(err));
    }
  }
};

export const fetchGeneralEdPaths = collegePaths => (dispatch, getState) => {
  const state = getState();
  let accessToken = null;
  let PathNames = null;
  if (state && state.auth && state.auth.access) {
    const {
      auth: {access: {token} = {}},
      pathways: {pathways: {pathNames} = {}} = {},
    } = state;
    accessToken = token;
    PathNames = pathNames;
  }

  if (accessToken) {
    const config = {
      method: 'GET',
      mode: 'cors',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
      cache: 'default',
    };
    dispatch(requestCareerPaths());
    try {
      return fetch(`${API.pathway.general_education_path}`, config)
        .then(response => response.json())
        .then(json => {
          if (json) {
            const SortedGenEdPaths =
              json && sortArrayOfObjPropertyBased(json, 'title');
            const genEdPathObj =
              PathNames &&
              PathNames.filter(
                path =>
                  path.path_name.trim().toLowerCase() === 'general education'
              )[0];
            const updatedGenEdPaths = SortedGenEdPaths.map(path => {
              if (path) {
                path.path_type_name = genEdPathObj && genEdPathObj.uuid;
                return path;
              }
            });

            const collegeAndGenEdPaths = [
              ...collegePaths,
              ...updatedGenEdPaths,
            ];

            dispatch(fetchOnboardingPaths(collegeAndGenEdPaths));
          }
        })
        .catch(err => {
          dispatch(handleCareerPathsError(err));
        });
    } catch (err) {
      dispatch(handleCareerPathsError(err));
    }
  }
};

export const fetchOnboardingPaths = collegeAndGenEdPaths => (
  dispatch,
  getState
) => {
  const state = getState();
  let accessToken = null;
  let PathNames = null;
  if (state && state.auth) {
    const {
      auth: {access: {token} = {}},
      pathways: {pathways: {pathNames} = {}} = {},
    } = state;
    accessToken = token;
    PathNames = pathNames;
  }

  if (accessToken) {
    const config = {
      method: 'GET',
      mode: 'cors',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
      cache: 'default',
    };
    dispatch(requestOnboardingPaths());
    try {
      return fetch(`${API.pathway.onboarding_path}`, config)
        .then(response => response.json())
        .then(json => {
          dispatch(receiveOnboardingPaths(json));
          if (collegeAndGenEdPaths) {
            const onboardingPathObj =
              PathNames &&
              PathNames.filter(
                path => path.path_name.trim().toLowerCase() === 'onboarding'
              )[0];
            const onboardingPaths = json.map(path => {
              if (path) {
                path.path_type_name =
                  onboardingPathObj && onboardingPathObj.uuid;
                return path;
              }
            });
            const combinePaths = [...collegeAndGenEdPaths, ...onboardingPaths];
            dispatch(receiveCareerPaths(combinePaths));
          }
        })
        .catch(err => {
          console.log(err);
          dispatch(handleOnboardingPathsError(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(handleOnboardingPathsError(err));
    }
  }
};
