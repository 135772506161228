import React from 'react';
import {Form, Row, Col, Select, Divider, Icon, Tag} from 'antd';
import './style.css';
const FormItem = Form.Item;
const {Option} = Select;
const defaultContentPadding = 42;

class ProgamOfStudySelector extends React.Component {
  state = {
    selectedCluster: null,
    filteredPrograms: [],
    selectedPrograms: [],
    searchTerm: null,
  };
  componentDidMount() {
    this.setInitialValue();
  }
  setInitialValue = () => {
    if (this.props.initialValue) {
      this.setState({
        selectedPrograms: this.props.initialValue,
      });
    }
  };
  onClusterSelect = clusterId => {
    const {clusters} = this.props;
    this.setState({
      selectedCluster:
        clusters && clusters.filter(cluster => cluster.uuid === clusterId)[0],
    });
  };
  componentDidUpdate(preProps) {
    if (this.props.allPrograms !== preProps.allPrograms) {
      // this.onProgramSearch('');
    }
    if (this.props.initialValue !== preProps.initialValue) {
      this.setInitialValue();
    }
  }
  onProgramSelect = programId => {
    const {
      allPrograms,
      form: {resetFields},
    } = this.props;
    const program = allPrograms.filter(pgm => pgm.uuid === programId)[0];
    this.setState(
      {
        selectedPrograms: [...this.state.selectedPrograms, program],
      },
      () => {
        this.onProgramChange();
        resetFields();
        this.onClusterSelect(null);
        // this.onProgramSearch('');
      }
    );
  };
  isProgramSelected = pgmId => {
    const {selectedPrograms} = this.state;
    const pgm = selectedPrograms.filter(program => program.uuid === pgmId);
    if (pgm.length > 0) return true;
    return false;
  };
  onProgramSearch = keyword => {
    const {allPrograms} = this.props;
    let filteredPrograms = allPrograms.filter(program =>
      program.name.toLowerCase().includes(keyword.toLowerCase())
    );
    if (!keyword) filteredPrograms = [];
    this.setState({filteredPrograms, searchTerm: keyword});
  };
  onProgramRemove = uuid => {
    this.setState(
      {
        selectedPrograms: this.state.selectedPrograms.filter(
          pgm => pgm.uuid !== uuid
        ),
      },
      () => {
        this.onProgramChange();
      }
    );
  };
  onProgramChange = () => {
    const {onChange, form, parentForm, noForm} = this.props;
    const {selectedPrograms, selectedCluster} = this.state;
    const resetFields = noForm
      ? parentForm
        ? parentForm.resetFields
        : null
      : form
      ? form.resetFields
      : null;
    if (resetFields) resetFields();
    if (onChange) {
      onChange(selectedPrograms);
    }
    if (this.props.onSelectProgram) {
      this.props.onSelectProgram(selectedCluster, selectedPrograms);
    }
  };
  render() {
    const {
      selectedCluster,
      filteredPrograms,
      selectedPrograms,
      searchTerm,
    } = this.state;
    const {
      clusters,
      allPrograms,
      contentPadding,
      singleSelect,
      hideTitle,
      noForm,
      showErrors,
      parentForm,
      required,
      form,
    } = this.props;
    const decorator = form && form.getFieldDecorator;
    const parentDecorator = parentForm && parentForm.getFieldDecorator;

    const getFieldDecorator = noForm ? parentDecorator : decorator;
    const content = () => (
      <div
        style={{
          padding: contentPadding || defaultContentPadding,
        }}
        className="program-study-selector filter-section">
        {!hideTitle && (
          <div
            style={{
              marginLeft: contentPadding
                ? -contentPadding
                : -defaultContentPadding,
              marginRight: contentPadding
                ? -contentPadding
                : -defaultContentPadding,
            }}
            className="heading-text text-center">
            Filter by Program of Study
          </div>
        )}
        <Row type="flex" gutter={42} align="middle">
          <Col sm={11} className="left-side" style={{display: 'none'}}>
            <FormItem label="Select Career Cluster">
              {getFieldDecorator('cluster', {
                // initialValue: SelectedCluster && SelectedCluster.uuid,
              })(
                <Select
                  onChange={this.onClusterSelect}
                  disabled={
                    singleSelect &&
                    selectedPrograms &&
                    selectedPrograms.length > 0
                  }
                  // disabled={disable}
                  placeholder="Select Career Cluster">
                  {clusters &&
                    clusters.map(({name, uuid}) => (
                      <Option key={uuid} value={uuid} title={name}>
                        {name}
                      </Option>
                    ))}
                </Select>
              )}
            </FormItem>

            <FormItem style={{marginTop: -16}} label="Select Program of Study">
              {getFieldDecorator('program', {
                // initialValue: SelectedCluster && SelectedCluster.uuid,
              })(
                <Select
                  onChange={this.onProgramSelect}
                  disabled={
                    singleSelect &&
                    selectedPrograms &&
                    selectedPrograms.length > 0
                  }
                  // disabled={disable}
                  placeholder="Select Program of Study">
                  {selectedCluster &&
                    selectedCluster.programs &&
                    selectedCluster.programs.map(({name, uuid}) => (
                      <Option
                        key={uuid}
                        disabled={this.isProgramSelected(uuid)}
                        value={uuid}
                        title={name}>
                        {name}
                      </Option>
                    ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col sm={2} style={{display: 'none'}}>
            <Divider className="divider-circle-text vertical-line">OR</Divider>
          </Col>
          <Col sm={20} className="searchByProgram" style={{margin: '0 auto'}}>
            <FormItem label="Search by Program of Study">
              <>
                <Icon
                  type="search"
                  style={{
                    position: 'absolute',
                    zIndex: '1',
                    top: '4px',
                    left: '5px',
                  }}
                />
                {getFieldDecorator('search')(
                  <Select
                    showSearch
                    disabled={
                      !allPrograms ||
                      (singleSelect &&
                        selectedPrograms &&
                        selectedPrograms.length > 0)
                    }
                    showArrow={false}
                    filterOption={false}
                    defaultActiveFirstOption={false}
                    allowClear
                    onSelect={this.onProgramSelect}
                    onSearch={this.onProgramSearch}
                    placeholder="Search program"
                    notFoundContent={
                      searchTerm ? 'no programs matchs for search query' : ''
                    }
                    style={{paddingLeft: '11px'}}>
                    {filteredPrograms &&
                      filteredPrograms.length > 0 &&
                      filteredPrograms.map(({name, uuid}) => (
                        <Option
                          disabled={this.isProgramSelected(uuid)}
                          key={uuid}
                          value={uuid}
                          title={name}>
                          {name}
                        </Option>
                      ))}
                  </Select>
                )}
              </>
            </FormItem>
          </Col>
        </Row>
        <div
          style={{
            marginLeft: contentPadding
              ? -contentPadding
              : -defaultContentPadding,
            marginRight: contentPadding
              ? -contentPadding
              : -defaultContentPadding,
            paddingLeft: contentPadding
              ? contentPadding
              : defaultContentPadding,
            paddingRight: contentPadding
              ? contentPadding
              : defaultContentPadding,
          }}
          className="selected-section">
          <div className="lbl-s">
            {required && <span className="required">* </span>}
            Selected Program of Study:
          </div>
          <div className="tags-s">
            {(selectedPrograms.length > 0 &&
              selectedPrograms.map((program, index) => (
                <Tag
                  key={'pgm-tag' + program.uuid}
                  onClose={() => this.onProgramRemove(program.uuid)}
                  closable
                  className="pgm-tag">
                  {program.name}
                </Tag>
              ))) || (
              <span
                style={{
                  position: 'relative',
                  top: '3px',
                }}>
                -
              </span>
            )}
            {showErrors && selectedPrograms.length === 0 && (
              <FormItem>
                {getFieldDecorator('ss', {
                  rules: [
                    {required: true, message: 'Please select the program'},
                  ],
                })(<div style={{paddingBottom: 7}} />)}
              </FormItem>
            )}
          </div>
        </div>
      </div>
    );
    return noForm ? content() : <Form>{content()}</Form>;
  }
}
export default Form.create({
  name: 'ProgamOfStudySelectorForm',
})(ProgamOfStudySelector);
