import React from 'react';
import {Tabs} from 'antd';

const TabPane = Tabs.TabPane;
const CustomTabs = props => (
  <div className="tabs-container">
    <Tabs type="card" {...props}>
      {props.data &&
        props.data.map(({title, key, className, children, lazyRender}) => (
          <TabPane
            tab={title}
            key={key}
            forceRender={lazyRender ? false : true}
            className={className}>
            {children}
          </TabPane>
        ))}
    </Tabs>
  </div>
);

export default CustomTabs;
