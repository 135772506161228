import React, {Component} from 'react';
import moment from 'moment';
import {Button} from 'antd';
import NotificationControls from './NotificationControls';

import {NOTIFICATION_TYPES} from 'config/srm.config';
class Notifications extends Component {
  render() {
    const {
      data = [],
      onNotificationClick,
      onClearAllNotifications,
      handleControlsVisiblity,
      controlsVisiblity,
    } = this.props;
    return (
      <div className="profile-dropdown header-notification">
        <>
          <div className="clearfix profile-title">
            {data && data.length > 0 && (
              <Button
                className="btn-link float-left "
                onClick={() => onClearAllNotifications(data, 'is_read')}>
                Mark All Read
              </Button>
            )}
            <span className="title-head">Notifications</span>
            {data && data.length > 0 && (
              <Button
                className="btn-link float-right"
                onClick={() => onClearAllNotifications(data, 'is_deleted')}>
                Clear All
              </Button>
            )}
          </div>
          <div className="data-scroll">
            {data && data.length === 0 ? (
              <div className="initial-loading">No notifications!</div>
            ) : (
              data &&
              data.length &&
              data.map(
                ({
                  id,
                  is_read,
                  is_deleted,
                  notification_details,
                  user_details,
                  type,
                }) => {
                  const {event} = JSON.parse(notification_details);
                  const User = JSON.parse(user_details);
                  const passingData = Object.assign(
                    {},
                    {id, is_read, is_deleted}
                  );
                  let visible = false;
                  const notificationId = Object.keys(controlsVisiblity)[0];
                  if (id === notificationId) {
                    visible = controlsVisiblity[notificationId];
                  }

                  return (
                    <div
                      key={id}
                      className={`ant-list ant-list-split ${
                        !is_read ? 'is_read' : ''
                      }`}>
                      <div className="ant-list-item">
                        <div>
                          {type && type === NOTIFICATION_TYPES.NEWCOMMUNITY && (
                            <div className="ant-list-item-meta-content">
                              <div className="card-action-icons">
                                <NotificationControls
                                  onNotificationClick={onNotificationClick}
                                  data={passingData}
                                  controlsVisiblity={visible}
                                  handleControlsVisiblity={
                                    handleControlsVisiblity
                                  }
                                />
                              </div>
                              <span className="event-title">
                                {User.first_name} created an event,
                              </span>
                              <div className="ant-list-item-meta-description">
                                <Button
                                  onClick={() =>
                                    onNotificationClick(
                                      passingData,
                                      event,
                                      'is_read'
                                    )
                                  }
                                  className="btn-link btn-link-underline">
                                  {event.title}
                                </Button>
                              </div>
                            </div>
                          )}
                          {type && type === NOTIFICATION_TYPES.ALERTME && (
                            <div className="ant-list-item-meta-content">
                              <div className="card-action-icons">
                                <NotificationControls
                                  onNotificationClick={onNotificationClick}
                                  data={passingData}
                                  controlsVisiblity={visible}
                                  handleControlsVisiblity={
                                    handleControlsVisiblity
                                  }
                                />
                              </div>
                              <span
                                className="ant-list-item-meta-title"
                                onClick={() =>
                                  onNotificationClick(
                                    passingData,
                                    event,
                                    'is_read'
                                  )
                                }>
                                {event.title}
                              </span>
                              <div
                                className="ant-list-item-meta-description"
                                onClick={() =>
                                  onNotificationClick(
                                    passingData,
                                    event,
                                    'is_read'
                                  )
                                }>
                                {event.description}
                                {/* <Button
                                  onClick={() => onNotificationClick(id, event)}
                                  className="btn-link btn-link-underline">
                                  {event.description}
                                </Button> */}
                                <span>
                                  {moment(event.start_date).fromNow()}
                                </span>
                              </div>
                            </div>
                          )}
                          {type && type === NOTIFICATION_TYPES.ALERTCOMMUNITY && (
                            <div className="ant-list-item-meta-content">
                              <div className="card-action-icons">
                                <NotificationControls
                                  onNotificationClick={onNotificationClick}
                                  data={passingData}
                                  controlsVisiblity={visible}
                                  handleControlsVisiblity={
                                    handleControlsVisiblity
                                  }
                                />
                              </div>
                              <span className="ant-list-item-meta-title">
                                Event
                              </span>
                              <div className="ant-list-item-meta-description">
                                <Button
                                  className="btn-link btn-link-underline"
                                  onClick={() =>
                                    onNotificationClick(id, event)
                                  }>
                                  {event.title}
                                </Button>
                                <span>
                                  {moment(event.start_date).fromNow()}
                                </span>
                              </div>
                            </div>
                          )}
                          {type && type === NOTIFICATION_TYPES.SURVEYRESPONSE && (
                            <div className="ant-list-item-meta-content">
                              <div className="card-action-icons">
                                <NotificationControls
                                  onNotificationClick={onNotificationClick}
                                  data={passingData}
                                  controlsVisiblity={visible}
                                  handleControlsVisiblity={
                                    handleControlsVisiblity
                                  }
                                />
                              </div>
                              <span className="event-title">
                                Student Added to,
                              </span>
                              <div className="ant-list-item-meta-description">
                                <Button
                                  onClick={() => onNotificationClick(id, event)}
                                  className="btn-link btn-link-underline">
                                  {event.title}
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              )
            )}
          </div>
        </>
      </div>
    );
  }
}

export default Notifications;
