import React, {useState, useEffect} from 'react';
import ReactExport from 'react-export-excel';

import {Button, Icon, message} from 'antd';

import {
  windowPrint,
  isArray,
  monthFormat,
  transformOpportunityData,
} from 'core';
import ErrorBoundary from '../ErrorBoundary';
import {Services} from './../../services';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const {error} = message;
const services = new Services();

// dummy data for refrence::
const DUMMY_DATA = [
  {
    columns: [],
    data: [
      [
        {
          value: 'CSU And UC Applications Hands On Workshops',
          style: {font: {sz: '16', bold: true}},
        },
      ],
      [
        {value: 'Created by:', style: {font: {normal: true}}},
        {value: 'Vaibhav Katariya', style: {font: {bold: true}}},
      ],
      [
        {value: 'Event Date:', style: {font: {normal: true}}},
        {
          value: 'September 5, 2019 11:33 AM - September 17, 2019 11:35 AM',
          style: {font: {bold: true}},
        },
      ],
      [
        {value: 'Location:', style: {font: {normal: true}}},
        {value: 'No location saved', style: {font: {bold: true}}},
      ],
      [],
      [
        {
          value: 'First Name',
          style: {
            font: {bold: true},
            fill: {patternType: 'solid', fgColor: {rgb: 'FFF5F6FA'}},
            alignment: {wrapText: true},
          },
        },
        {
          value: 'Email',
          style: {
            font: {bold: true},
            fill: {patternType: 'solid', fgColor: {rgb: 'FFF5F6FA'}},
            alignment: {wrapText: true},
          },
        },
        {
          value: 'Mobile',
          style: {
            font: {bold: true},
            fill: {patternType: 'solid', fgColor: {rgb: 'FFF5F6FA'}},
            alignment: {wrapText: true},
          },
        },
        {
          value: 'Address',
          style: {
            font: {bold: true},
            fill: {patternType: 'solid', fgColor: {rgb: 'FFF5F6FA'}},
            alignment: {wrapText: true},
          },
        },
      ],
      [
        {
          value: 'Sankar',
          style: {
            font: {normal: true},
            alignment: {wrapText: true},
          },
        },
        {
          value: 'sankar.md@accionlabs.com',
          style: {
            font: {normal: true},
            alignment: {wrapText: true},
          },
        },
        {
          value: '1234567890',
          style: {
            font: {normal: true},
            alignment: {wrapText: true},
          },
        },
        {
          value: 'Whitefield, Bangalore',
          style: {
            font: {normal: true},
            alignment: {wrapText: true},
          },
        },
      ],
      [
        {
          value: 'Vaibhav',
          style: {
            font: {normal: true},
            alignment: {wrapText: true},
          },
        },
        {
          value: 'vaibhav@accionlabs.com',
          style: {
            font: {normal: true},
            alignment: {wrapText: true},
          },
        },
        {
          value: '1234567890',
          style: {
            font: {normal: true},
            alignment: {wrapText: true},
          },
        },
        {
          value: 'Whitefield, Bangalore',
          style: {
            font: {normal: true},
            alignment: {wrapText: true},
          },
        },
      ],
      [
        {
          value: 'Raja',
          style: {
            font: {normal: true},
            alignment: {wrapText: true},
          },
        },
        {
          value: 'raja@accionlabs.com',
          style: {
            font: {normal: true},
            alignment: {wrapText: true},
          },
        },
        {
          value: '1234567890',
          style: {
            font: {normal: true},
            alignment: {wrapText: true},
          },
        },
        {
          value: 'Whitefield, Bangalore',
          style: {
            font: {normal: true},
            alignment: {wrapText: true},
          },
        },
      ],
    ],
  },
];

const DownLoad = ({
  text,
  downloadXL,
  filename = 'list',
  dataSet,
  serverBased,
}) => {
  const [dataToBeExport, setDataToBeExport] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (dataSet) {
      setDataToBeExport(dataSet);
    }
  }, []);

  const fetchAndPrepareData = event => {
    setIsFetching(true);

    if (serverBased && serverBased.uri) {
      const {
        uri,
        token,
        opportunity_student_list,
        defaultColumnStyle,
        displayColumns,
        format,
        responseKey,
        getAllPages,
      } = serverBased;

      services.fetchRecords(uri, token).then(res => {
        let response = res;

        if (response && getAllPages) {
          const columns = [
            {
              value: '#',
              style: {
                font: {bold: true},
                fill: {patternType: 'solid', fgColor: {rgb: 'FFF5F6FA'}},
                alignment: {wrapText: true},
              },
            },
          ];
          response &&
            response.structure &&
            response.structure.forEach(columnHeader => {
              let OBJ = {
                value: '',
                style: {
                  font: {bold: true},
                  fill: {patternType: 'solid', fgColor: {rgb: 'FFF5F6FA'}},
                  alignment: {wrapText: true},
                },
              };
              OBJ.value = columnHeader.display_name;
              columns.push(OBJ);
            });
          const URIS = [];
          let arr = new Array(response.no_of_pages);
          arr
            .fill('url')
            .forEach((val, idx) =>
              URIS.push(`${uri}?page_num=${idx + 1}&page_size=1000`)
            );
          Promise.all(URIS.map(url => services.fetchRecords(url, token)))
            .then(function(responses) {
              let rows = [];
              Promise.all(
                responses.map((response, pageNo) => {
                  response.data.map((row, rowNo) => {
                    const rowIndex = pageNo
                      ? pageNo * 50 + (rowNo + 1)
                      : rowNo + 1;
                    let rowArray = [
                      {
                        value: rowIndex.toString(),
                        style: {
                          font: {normal: true},
                          alignment: {wrapText: true},
                        },
                      },
                    ];
                    Object.keys(row).map(column => {
                      const OBJ = {
                        value: '',
                        style: {
                          font: {normal: true},
                          alignment: {wrapText: true},
                        },
                      };
                      OBJ.value =
                        (row[column] && row[column].toString()) || '-';
                      rowArray.push(OBJ);
                    });
                    rows.push(rowArray);
                  });
                })
              );
              return {columns, rows};
            })
            .then(data => {
              /// Here we have to do all the manipulations of the file....
              /// CREATE FILE STRUCTURE
              setDataToBeExport([
                {columns: [], data: [data.columns, ...data.rows]},
              ]);
              setIsFetching(false);
            })
            .catch(function(error) {
              console.log(error);
            });

          return;
        }

        if (!response) {
          setIsFetching(false);
          return;
        }
        if (response && responseKey) {
          response = response[responseKey];
        }

        if (opportunity_student_list) {
          response = transformOpportunityData(response);
        }

        if (response && isArray(response)) {
          const FORMAT = {...format[0]};
          if (
            FORMAT.data &&
            isArray(FORMAT.data) &&
            displayColumns &&
            defaultColumnStyle
          ) {
            response.forEach(record => {
              if (record) {
                const createRow = [];
                displayColumns.forEach(column => {
                  const createColumm = Object.assign(
                    {},
                    {...defaultColumnStyle}
                  );
                  if (column && isArray(column)) {
                    column.forEach(subcolumn => {
                      if (createColumm.value) {
                        createColumm.value += ` ${record[subcolumn] || '-'}`;
                      } else {
                        createColumm.value = record[subcolumn] || '-';
                      }
                    });
                  } else {
                    if (column === 'created_date' || column === 'apply_date') {
                      createColumm.value =
                        (record[column] && monthFormat(record[column])) || '-';
                    } else {
                      createColumm.value = record[column] || '-';
                    }
                  }
                  createRow.push(createColumm);
                });
                FORMAT.data.push(createRow);
              }
            });
          }
          setDataToBeExport([FORMAT]);
          setIsFetching(false);
          return;
        }
        error(
          'File Cannot be downloaded, Something went wrong try again later.'
        );
        setIsFetching(false);
      });
    }
  };

  return (
    <ErrorBoundary>
      {(!downloadXL || serverBased) && (
        <Button
          loading={isFetching}
          type={serverBased.buttonType || 'primary'}
          className="btn-download"
          onClick={!downloadXL ? windowPrint : fetchAndPrepareData}>
          {`${isFetching ? 'Preparing file' : serverBased.text || 'Download'}`}
          {!serverBased.text && <Icon type="download" />}
        </Button>
      )}
      {dataToBeExport && (
        <ExcelFile
          hideElement={serverBased}
          element={
            !text ? (
              <Button type="primary" className="btn-download">
                Download <Icon type="download" />
              </Button>
            ) : (
              <span>{text}</span>
            )
          }
          filename={filename}>
          <ExcelSheet dataSet={dataToBeExport} name="Organization" />
        </ExcelFile>
      )}
    </ErrorBoundary>
  );
};

export default DownLoad;
