import {Services} from 'core';
import * as TYPES from './constants';
import {API, jobsPageSize, defaultJobSort} from 'config/employer.config';
import {
  requestEmployerApprenticeships,
  receiveEmployerApprenticeships,
  handleEmployerApprenticeshipsError,
  employerApprenticeshipStatusUpdate,
} from './actions';

const initialState = {
  data: null,
  error: null,
  request: null,
};
const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_EMPLOYER_APPRENTICESHIPS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_EMPLOYER_APPRENTICESHIPS:
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload || {},
      };
    case TYPES.EMPLOYER_APPRENTICESHIPS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.CLEAR_EMPLOYER_APPRENTICESHIPS:
      return {
        ...initialState,
      };
    case TYPES.EMPLOYER_APPRENTICESHIP_STATUS_UPDATE:
      return {
        ...state,
        error: false,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchApprenticeships = ({
  page = 0,
  searchKey = '',
  sort = defaultJobSort,
  pageSize = jobsPageSize,
}) => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestEmployerApprenticeships());
  try {
    let endpoint = `${
      API.administration.opportunities
    }?opportunity_type=apprenticeship&page_size=${pageSize}&page=${page +
      1}&sort_by=${sort}`;
    if (searchKey) {
      endpoint += `&search=${searchKey}`;
    }
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receiveEmployerApprenticeships(res));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleEmployerApprenticeshipsError(err));
      });
  } catch (error) {
    console.log(error);
    dispatch(handleEmployerApprenticeshipsError(error));
  }
};

export const deleteApprenticeship = (deleteId, callback) => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  try {
    const endPoint = `${API.administration.opportunities}${deleteId}`;
    return services
      .deleteRecord(token, endPoint)
      .then(res => {
        if (res) {
          if (callback) callback(res);
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(handleEmployerApprenticeshipsError(err));
      });
  } catch (error) {
    console.log(error);
    dispatch(handleEmployerApprenticeshipsError(error));
  }
};

export const updateApprenticeshipStatus = (id, status, callback) => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  try {
    dispatch(requestEmployerApprenticeships());
    let endPoint = `${API.administration.opportunities}${id}`;
    let bodyData = {
      is_published: status,
    };
    return services
      .updateStatus(token, endPoint, bodyData)
      .then(res => {
        if (res) {
          dispatch(employerApprenticeshipStatusUpdate());
          if (callback) callback(res);
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(handleEmployerApprenticeshipsError(err));
      });
  } catch (error) {
    console.log(error);
    dispatch(handleEmployerApprenticeshipsError(error));
  }
};
