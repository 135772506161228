// get opportunity Details
export const REQUEST_EMPLOYER_OPPORTUNITY_DETAILS =
  'REQUEST_EMPLOYER_OPPORTUNITY_DETAILS';
export const RECEIVE_EMPLOYER_OPPORTUNITY_DETAILS =
  'RECEIVE_EMPLOYER_OPPORTUNITY_DETAILS';
export const EMPLOYER_OPPORTUNITY_DETAILS_ERROR =
  'EMPLOYER_OPPORTUNITY_DETAILS_ERROR';
export const CLEAR_EMPLOYER_OPPORTUNITY_DETAILS =
  'CLEAR_EMPLOYER_OPPORTUNITY_DETAILS';

// get opportunity Applicants
export const RECEIVE_EMPLOYER_OPPORTUNITY_APPLICANTS =
  'RECEIVE_EMPLOYER_OPPORTUNITY_APPLICANTS';
export const REQUEST_EMPLOYER_OPPORTUNITY_APPLICANTS =
  'REQUEST_EMPLOYER_OPPORTUNITY_APPLICANTS';
export const EMPLOYER_OPPORTUNITY_APPLICANTS_ERROR =
  'EMPLOYER_OPPORTUNITY_APPLICANTS_ERROR';
export const CLEAR_EMPLOYER_OPPORTUNITY_APPLICANTS =
  'CLEAR_EMPLOYER_OPPORTUNITY_APPLICANTS';
