import * as TYPES from './constants';

//user actions
export const requestUserDetail = () => ({
  type: TYPES.REQUEST_USER_DETAILS,
});
export const handleUserError = err => ({
  type: TYPES.REQUEST_USER_DETAILS_ERROR,
  err,
});
export const receiveUserDetails = json => ({
  type: TYPES.RECEIVE_USER_DETAILS,
  payload: json,
});

//requestTypeAction action
export const receiveInfoRequestTypes = payload => ({
  type: TYPES.RECEIVE_INFO_REQUEST_TYPE,
  payload,
});

// leadmap actions
export const requestLeadMap = payload => ({
  type: TYPES.REQUEST_LEADMAP,
  payload,
});

export const receiveLeadMap = payload => ({
  type: TYPES.RECEIVE_LEAPMAP,
  payload,
});

// eventsaction
export const requestEventList = () => ({
  type: TYPES.REQUEST_EVENT_LIST,
});
export const handleError = err => ({
  type: TYPES.REQUEST_EVENT_LIST_ERROR,
  err,
});
export const receiveEventList = events => ({
  type: TYPES.RECEIVE_EVENT_LIST,
  events,
});
export const setFilter = (filter = 'personal') => ({
  type: TYPES.EVENT_FILTER,
  filter,
});
export const deleteEvent = (eventuuid, token) => ({
  type: TYPES.REQUEST_EVENT_DELETE,
  request: {
    eventuuid,
    token,
  },
});
export const editEvent = (eventuuid, token) => ({
  type: TYPES.REQUEST_EVENT_EDIT,
  request: {
    eventuuid,
    token,
  },
});

//demographic Action
export const receiveDemographic = payload => ({
  type: TYPES.RECEIVE_DEMOGRAPHIC,
  payload,
});
