export const REPORTER_ROUTES = {
  HOME: {
    DASHBOARD: '/',
    INSTITUTIONS: '/institutions',
    INSTITUTIONS_DASHBOARD: '/institutions/:id',
  },
  ADMIN: {USER_MANAGEMENT: '/user/management'},
  REPORTER: {CREATE: '/reporter/profile'},
  REPORTS: {DASHBOARD: '/reports', CREATE_REPORTS: 'reports/create'},
};
