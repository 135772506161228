import React from 'react';
import {Text, View} from '@react-pdf/renderer';

export default ({studentProfile, styles}) => {
  const {bio = 'N/A'} = studentProfile;

  return (
    <>
      <View style={styles.header}>
        <Text>Professional Summary</Text>
      </View>
      <View style={styles.content}>
        <Text style={styles.value}>{bio}</Text>
      </View>
    </>
  );
};
