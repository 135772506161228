import React from 'react';
import {View, StyleSheet} from '@react-pdf/renderer';

import Divider from './Divider';
import Education from './Education';
import Interests from './Interests';
import OverviewInfo from './OverviewInfo';
import VolunteerExperience from './VolunteerExperience';

const styles = StyleSheet.create({
  block: {
    // padding: 12,
    // paddingLeft: 6,
    // paddingRight: 6,
    width: '30%',
    borderWidth: 1,
    borderRadius: 8,
    textAlign: 'left',
    borderStyle: 'solid',
    borderColor: '#4abaed',
    // borderColor: '#EAEDF1',
    flexDirection: 'column',
  },
  innerBlock: {
    width: '90%',
    height: '98%',
    top: '1%',
    left: '3%',
  },
  colorHeader: {
    padding: 6,
    color: '#DE4279',
    paddingLeft: 8,
    width: '100%',
    fontSize: 12,
    textAlign: 'left',
  },
  section: {
    padding: 6,
    textAlign: 'left',
  },
  label: {
    fontSize: 10,
    marginBottom: 4,
    fontWeight: '900',
  },
  bold: {
    fontSize: 9,
    color: '#000',
    fontWeight: '900',
    paddingBottom: 3,
  },
  value: {
    fontSize: 8,
    color: '#000',
    fontWeight: '200',
    display: 'inline',
  },
  SkillsList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  inline: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  listText: {
    fontSize: 8,
    lineHeight: 1.2,
    marginLeft: 2,
    marginBottom: 4,
  },
});

export default ({studentProfile}) => {
  const {education = [], voluntary_exp = [], interest = []} =
    studentProfile || {};

  return (
    <View style={styles.block}>
      <View style={styles.innerBlock}>
        <OverviewInfo styles={styles} studentProfile={studentProfile} />
        {education && Array.isArray(education) && education.length > 0 ? (
          <>
            <Divider />
            <Education styles={styles} education={education} />
          </>
        ) : null}
        {voluntary_exp &&
        Array.isArray(voluntary_exp) &&
        voluntary_exp.length > 0 ? (
          <>
            <Divider />
            <VolunteerExperience
              styles={styles}
              voluntary_exp={voluntary_exp}
            />
          </>
        ) : null}
        {interest && Array.isArray(interest) && interest.length > 0 ? (
          <>
            <Divider />
            <Interests styles={styles} studentProfile={studentProfile} />
          </>
        ) : null}
      </View>
    </View>
  );
};
