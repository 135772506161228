import * as TYPES from './constants';
import {
  requestBanerInstances,
  handleBanerInstancesError,
  receiveBanerInstances,
} from './actions';

import {Services} from 'core';

import {API} from 'config/superuser.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_BANNER_INSTANCES:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_BANNER_INSTANCES:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.BANNER_INSTANCES_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchBannerInstances = () => (dispatch, getState) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();
  dispatch(requestBanerInstances());
  let endpoint = `${
    API.administration.banner_management
  }?get_all_instances=true`;
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receiveBanerInstances(res));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleBanerInstancesError(err));
  }
};
