import React from 'react';
import {Avatar} from 'antd';
import {getCloudinaryImage} from 'core';

const ProfileHeader = ({data}) => {
  const logo =
    data &&
    data.logo_cloudinary &&
    getCloudinaryImage(data.logo_cloudinary, 'logo');
  return (
    <div className="steps-header d-flex">
      <div>
        <Avatar
          className="photo-img"
          src={logo}
          alt="partner logo"
          icon={`${'picture'}`}
        />
      </div>
      <div>
        <h2 className="main-head">
          {(data && data.name) || 'No company name'}
        </h2>
        <h4 className="sub-head">
          {`${data &&
            data.physical_address_details &&
            (data.physical_address_details.city
              ? data.physical_address_details.city +
                ', ' +
                data.physical_address_details.state
              : data.physical_address_details.state || 'No company address')}`}
        </h4>
      </div>
    </div>
  );
};

export default ProfileHeader;
