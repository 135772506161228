import {API, JWT_HEADER, JSON_HEADER} from 'config/srm.config';
import * as TYPES from './constants';
import {Services} from 'core';
import {receiveAwardTypes, requestAwardTypes, awardTypesError} from './actions';

const services = new Services();

const initialState = {request: false, data: null, error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.STUDENT_AWARD_TYPES_LOAD:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.STUDENT_AWARD_TYPES_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.STUDENT_AWARD_TYPE_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchAwardTypes = () => async (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();

  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };

  let endPoint = `${API.student.student_award_type}`;
  dispatch(requestAwardTypes());

  try {
    return fetch(endPoint, config)
      .then(response => response.json())
      .then(res => {
        if (Array.isArray(res)) dispatch(receiveAwardTypes(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(awardTypesError(err));
  }
};
