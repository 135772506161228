import React from 'react';
import {Avatar} from 'antd';
import {getCloudinaryImage} from 'core';

export default function CollegeInfo({data = {}}) {
  const logoData =
    getCloudinaryImage(data.logo_cloudinary, 'logo') || data.logo;

  return (
    <div className="collegeInfo">
      <Avatar src={logoData} icon="picture" />
      <span className="collegeName">{data && data.name}</span>
    </div>
  );
}
