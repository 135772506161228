const {Box, Stack, Paper, Alert} = require('@mui/material');

const NoAccess = () => {
  return (
    <>
      <Stack
        sx={{minHeight: 500}}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}>
        <Paper>
          <Alert severity="warning">
            Module Access Error The selected module is currently disabled.
            <br />
            To activate, please contact{' '}
            <a href="mailto:support@goeducate.com">GoEducate support</a> for
            assistance in enabling this module.
            <br />
            Thank you for your cooperation.
          </Alert>
        </Paper>
      </Stack>
    </>
  );
};

export default NoAccess;
