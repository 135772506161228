import * as TYPES from './constants';
import {
  requestInstituteDashboardData,
  receiveInstituteDashboardData,
  handleInstituteDashboardDataError,
} from './actions';
import {getLoggedInstituteUUID} from 'redux/modules/auth';

import {Services, isArray} from 'core';

import {API} from 'config/srm.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_INSTITUTE_DASHBOARD_DATA:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_INSTITUTE_DASHBOARD_DATA:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.INSTITUTE_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchInstituteDashboard = () => (dispatch, getState) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();

  dispatch(requestInstituteDashboardData());
  // const ID = institutionId || getLoggedInstituteUUID(getState());
  let endpoint = `${API.institution.dashboard}/`;
  // let isFilterList = false;
  // if (filterList && isArray(filterList) && filterList.length) {
  //   isFilterList = true;
  //   endpoint = endpoint.concat(`?institution_list=${filterList.join()}`);
  // }
  // if (filterByProgramId) {
  //   endpoint = endpoint.concat(
  //     `${isFilterList ? '&' : '?'}student_success_pos=${filterByProgramId}`
  //   );
  // }
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receiveInstituteDashboardData(res));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleInstituteDashboardDataError(err));
  }
};
