import {
  careerOneStopCompaniesError,
  requestCareerOneStopCompanies,
  receivedCareerOneStopCompanies,
} from './actions';
import {Services} from 'core';
import * as TYPES from './constants';
import {API} from 'config/employer.config';

const services = new Services();

const initialState = {
  data: null,
  error: null,
  request: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_CAREER_ONE_STOP_COMPANIES:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVED_CAREER_ONE_STOP_COMPANIES:
      return {
        error: false,
        request: false,
        data: action.payload,
      };
    case TYPES.CAREER_ONE_STOP_COMPANIES_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.CAREER_ONE_STOP_COMPANIES_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const fetchCareerOneStopCompanies = searchKeyword => (
  dispatch,
  getState
) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();
  dispatch(requestCareerOneStopCompanies());
  let endpoint = `${
    API.administration.career_one_stop_companies_list
  }?action=link_bp`;
  if (searchKeyword) {
    endpoint = `${
      API.administration.career_one_stop_companies_list
    }?search=${searchKeyword}&action=link_bp`;
  }
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => dispatch(receivedCareerOneStopCompanies(res)))
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(careerOneStopCompaniesError());
  }
};
