import React, {Component, Fragment} from 'react';
import {Form, Icon, Button, Modal} from 'antd';
import update from 'immutability-helper';
import {prepareOverviewData} from 'core';
import {isObject} from 'util';

import AddOverViewFormItem from './AddOverViewFormItem';
import './AddOverview.css';

const StaticOverview = [
  {
    status: true,
    overview_key: '',
    overview_type: '',
    overview_values: [{value: ''}],
  },
];

class AddOverview extends Component {
  state = {
    overviews: [],
    generateAIKey: '',
  };

  componentDidMount = () => {
    const {data: {overviews: overViewData = []} = {}} = this.props;
    let overviews = StaticOverview;
    if (overViewData && overViewData.length) {
      overviews = prepareOverviewData([...overViewData]);
    }
    this.setState({
      overviews,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {data: {overviews: overViewData = []} = {}} = this.props;
    const {overviews} = prevState;
    if (
      overViewData &&
      overViewData.length &&
      overViewData !== prevProps?.data?.overviews
    ) {
      let overviews = prepareOverviewData([...overViewData]);
      this.setState({
        overviews,
      });
    }
  };

  addOverView = () => {
    this.setState(
      update(this.state, {
        overviews: {
          $push: [...StaticOverview],
        },
      })
    );
  };

  removeOverView = rmIdx => {
    const {nameKey} = this.props;
    this.setState(
      update(this.state, {
        overviews: {
          $splice: [[rmIdx, 1]],
        },
      }),
      () => {
        let updateObj = {};
        this.state.overviews.map((overview, index) => {
          const status = `${nameKey || 'overview_content'}[${index}][status]`;
          updateObj[status] = overview.status;
          const overview_key = `${nameKey ||
            'overview_content'}[${index}][overview_key]`;
          updateObj[overview_key] = overview.overview_key;
          const overview_type = `${nameKey ||
            'overview_content'}[${index}][overview_type]`;
          updateObj[overview_type] = overview.overview_type;
          if (overview.overview_values) {
            overview.overview_values.map((overviewValue, idxChild) => {
              const fieldName = `${nameKey ||
                'overview_content'}[${index}].overview_values[${idxChild}].value`;
              updateObj[fieldName] = overviewValue.value;
            });
          }
        });
        this.props.form.setFieldsValue({
          ...updateObj,
        });
        this.overviewItem.state.forceRerender = true;
      }
    );
  };

  onUpdate = (index, data) => {
    this.setState(
      update(this.state, {
        overviews: {
          [index]: {
            $set: data,
          },
        },
      })
    );
  };

  onGOAIBtnClick = key => {
    let AIKey = '';
    let skillsLearned = [];
    let relatedCareers = [];
    let overViewDescription = '';
    switch (key) {
      case 'overview_section.overview_description': {
        AIKey = 'program_description';
        overViewDescription = this.props.form.getFieldValue(key);
        break;
      }
      case 'overview_section.related_careers': {
        AIKey = 'related_careers';
        relatedCareers = this.props.relatedCareers;
        break;
      }
      case 'overview_section.skills_learned': {
        AIKey = 'skills_learned';
        skillsLearned = this.props.form.getFieldValue(key);
        break;
      }
      default: {
        return;
      }
    }
    if (
      overViewDescription ||
      skillsLearned.length > 0 ||
      relatedCareers.length > 0
    ) {
      this.setState({
        generateAIKey: AIKey,
      });
    } else {
      this.props.generatefromAI(AIKey);
    }
  };

  render() {
    const {
      form,
      form: {getFieldDecorator},
      noForm,
      nameKey,
      renderTopChildren,
      displayForm = true,
      overviewButtonText,
    } = this.props;
    const {overviews, generateAIKey} = this.state;
    const contentComponent = () => (
      <div
        className={`AddOverviewContent ${displayForm ? 'create' : 'readonly'}`}>
        {renderTopChildren && (
          <div className="list-row">
            {renderTopChildren.map((item, index) => (
              <>
                <Button
                  className="goAIBtn"
                  key={`${item.label} AI Btn`}
                  onClick={() => this.onGOAIBtnClick(item.key)}>
                  Go AI
                </Button>
                <Form.Item
                  key={index + '-formItem'}
                  label={item.label || null}
                  style={
                    item.label === 'Description' ? {marginBottom: '36px'} : {}
                  }>
                  {getFieldDecorator(item.key, {
                    rules: [...item.rules],
                    initialValue: item.value,
                  })(item.field)}
                </Form.Item>
              </>
            ))}
          </div>
        )}
        {generateAIKey && (
          <Modal
            onOk={() => {
              this.props.generatefromAI(generateAIKey);
              this.setState({
                generateAIKey: '',
              });
            }}
            okText="Yes"
            cancelText="No"
            maskClosable={false}
            visible={generateAIKey ? true : false}
            title="Are you sure you want to run AI GENERATOR?"
            onCancel={() => this.setState({generateAIKey: ''})}>
            Once initiated, the system will overwrite any previous information
            and cannot be undone.
          </Modal>
        )}
        {overviews &&
          overviews.length > 0 &&
          overviews.map((overView, index) => (
            <Fragment key={index}>
              {displayForm && overviews.length > 1 && (
                <Icon
                  onClick={() => this.removeOverView(index)}
                  type="delete"
                />
              )}
              <AddOverViewFormItem
                ref={ref => (this.overviewItem = ref)}
                form={form}
                overView={overView}
                parentIndex={index}
                key={`${index}-overview`}
                nameKey={nameKey}
                onUpdate={this.onUpdate}
                displayForm={displayForm}
                {...this.props}
              />
            </Fragment>
          ))}
        <div className="text-right">
          {displayForm && (
            <Button
              className="btn-blue-outline add-more"
              onClick={this.addOverView}>
              {overviewButtonText || (
                <>
                  <Icon type="plus" />
                  {'Add New Overview Content'}
                </>
              )}
            </Button>
          )}
        </div>
      </div>
    );

    return noForm ? contentComponent() : <Form>{contentComponent()}</Form>;
  }
}

export default Form.create({
  onFieldsChange: (
    {requiredFields, statusKey, isTabValid},
    changedField,
    allFields
  ) => {
    if (requiredFields && statusKey && isTabValid) {
      let isValid = true;
      requiredFields.forEach(field => {
        if (field && isObject(field)) {
          for (let [key, val] of Object.entries(field)) {
            if (
              !allFields[key][val].value ||
              allFields[key][val].value === undefined ||
              allFields[key][val].value === ''
            ) {
              isValid = false;
            }
          }
        }
      });
      isTabValid(statusKey, isValid);
    }
  },
})(AddOverview);
export {AddOverview};
