import {styled} from '@mui/material/styles';
import {default as MuiTabContext} from '@mui/lab/TabContext';
import PropTypes from 'prop-types';
const TabContext = styled(MuiTabContext)(props => {
  return {
    // borderBottom: '1px solid #e8e8e8',
  };
});

TabContext.propTypes = {
  ...TabContext.propTypes,
  bordered: PropTypes.bool,
};

export default TabContext;
