import * as TYPES from './constants';

// SRM Intitution Permissions
export const receiveInstitutionPermissionList = json => ({
  type: TYPES.RECEIVE_INSTITUTION_PERMISSION_LIST,
  payload: json,
});

export const requestInstitutionPermissionList = () => ({
  type: TYPES.REQUEST_INSTITUTION_PERMISSION_LIST,
});

export const handleInstitutionPermissionError = err => ({
  type: TYPES.INSTITUTION_PERMISSION_LIST_ERROR,
  err,
});
// SRM User Permissions
export const receiveUserPermissionList = json => ({
  type: TYPES.RECEIVE_USER_PERMISSION_LIST,
  payload: json,
});

export const requestUserPermissionList = () => ({
  type: TYPES.REQUEST_USER_PERMISSION_LIST,
});

export const handleUserPermissionError = err => ({
  type: TYPES.USER_PERMISSION_LIST_ERROR,
  err,
});
