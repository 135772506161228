import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Icon, Avatar} from 'antd';

import {imgFormat} from 'config/srm.config';
import {isString, isObject, isFile} from 'core';

import './FileUploader.css';

class PreviewImageUploader extends Component {
  state = {
    sourceData: null,
  };

  componentDidMount() {
    this._mounted = true;
    this.loadInitialData();
  }

  componentDidUpdate(prevProps) {
    const {data} = this.props;
    if (prevProps.data != data) {
      this.loadInitialData();
    }
  }

  loadInitialData() {
    const {data, forceUpdate} = this.props;
    if ((data && isString(data)) || forceUpdate) {
      this.setState({
        sourceData: data,
      });
    } else if (data && isFile(data)) {
      const isValid = this.validateFile(data);
      if (isValid && !isValid.error) {
        this.previewImage(data);
        return;
      }
      this.setState(isValid);
    } else {
    }
  }
  componentWillUnmount() {
    this._mounted = false;
  }

  handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    const {dragImage} = this.props;
    if (!dragImage) {
      return;
    }
    if (dragImage) {
      const taranferingData = e.dataTransfer;
      const file = taranferingData.files[0];
      this.previewImage(file);
    }
  };

  previewImage = file => {
    const {allowedFormat, onChange} = this.props;
    if (file && isFile(file)) {
      const reader = new FileReader();
      reader.onload = () => {
        if (this._mounted) {
          const data =
            allowedFormat === 'video'
              ? URL.createObjectURL(file)
              : reader.result;
          this.setState(
            {
              sourceData: data,
            },
            () => onChange(file)
          );
        }
      };
      reader.readAsDataURL(file);
    }
  };

  handleFileUpload = e => {
    e.preventDefault();
    e.stopPropagation();
    const target = e.target || null;
    const file = target.files[0] || e;
    const isValid = this.validateFile(file);
    if (file && isFile(file) && isValid && !isValid.error) {
      this.setState(isValid, () => this.previewImage(file));
      return;
    }
    this.setState(isValid);
  };

  validateFile = file => {
    let type;
    if (file && isObject(file) && isFile(file)) {
      type = file.type.split('/')[0];
    }
    const {allowedFormat, checkSize} = this.props;
    const validInfo = {};

    if (type === allowedFormat) {
      if (type === 'image' && !imgFormat.includes(file.type)) {
        validInfo.error = true;
        validInfo.errMsg = 'Only .JPG, .JPEG & .PNG allowed';
        return validInfo;
      }
      if (type === 'video' && file.type !== 'video/mp4') {
        validInfo.error = true;
        validInfo.errMsg = 'Only .MP4 Video allowed';
        return validInfo;
      }
      if (
        checkSize &&
        parseFloat(file.size / (1024 * 1024)).toFixed(2) > checkSize
      ) {
        validInfo.error = true;
        validInfo.errMsg = `Could not save banner attachment, file size exceeds ${checkSize} MB limit`;
        return validInfo;
      }
      validInfo.error = false;
      validInfo.errMsg = null;
      return validInfo;
    }
    validInfo.error = true;
    validInfo.errMsg = `Not a Valid ${allowedFormat} file format`;
    return validInfo;
  };

  deleteFile = () => {
    const {onDelete} = this.props;
    this.setState(
      {
        sourceData: null,
      },
      () => onDelete(true)
    );
  };

  allowDrop(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    const {error, errMsg} = this.state;
    const {
      icon,
      title,
      subTitle,
      allowedFormat,
      readOnly,
      dragImage,
      changeImageLabel,
    } = this.props;
    const {sourceData} = this.state;
    return (
      <div
        className="uploader"
        onDragOver={this.allowDrop}
        onDrop={this.handleDrop}>
        {sourceData || readOnly ? (
          <div className={`live-preview ${readOnly ? 'readOnly' : 'normal'}`}>
            <Fragment>
              {sourceData && allowedFormat === 'image' && (
                <Avatar src={sourceData} />
              )}
              {sourceData && allowedFormat === 'video' && (
                <video height="250" controls>
                  <source src={sourceData} type="video/mp4" />
                  <p>
                    Try different browser, Your browser is not supporting video.
                  </p>
                </video>
              )}
              {!sourceData && (
                <span className="nodata">
                  {icon}
                  <span>No image/video to display.</span>
                </span>
              )}
              {!readOnly && changeImageLabel && (
                <span className="changeBannerButton" onClick={this.deleteFile}>
                  {changeImageLabel}
                </span>
              )}
              {!readOnly && !changeImageLabel && (
                <Icon type="close" onClick={this.deleteFile} />
              )}
            </Fragment>
          </div>
        ) : (
          <div className="image-uploader">
            <input
              type="file"
              name="file-upload"
              onChange={this.handleFileUpload}
            />
            <span className="photo-link">
              {icon}
              <span className="uploader-text">
                <span>
                  {title}
                  <span className="drag-enable">
                    {dragImage && ' or drag file here'}
                  </span>
                </span>
                <div className="text-center">{subTitle}</div>
                {/* <span className="text-muted">{subTitle}</span> */}
              </span>
              {error && <div className="image-error">{errMsg}</div>}
            </span>
          </div>
        )}
      </div>
    );
  }
}

PreviewImageUploader.propTypes = {
  title: PropTypes.string.isRequired,
  allowedFormat: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default PreviewImageUploader;
