import {PropTypes} from 'prop-types';
import {useTheme} from '@mui/material/styles';
import {themeColorMode} from 'mui-core/utils';
import {Stack, useMediaQuery} from '@mui/material';
import {textloading} from 'sharedResources/assets/images';
import settingsData from 'data/settings.json';

const {showingOfResultsFor: {showingTxt, resultsTxt, ofTxt, forTxt} = {}} =
  settingsData || {};

const SearchResultsCounter = ({
  request,
  forTitle,
  totalResults,
  currentLength,
}) => {
  let formattedTotalResults =
    (totalResults && Number(totalResults).toLocaleString()) || 0;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const currentData = (
    <span>
      {request ? (
        <img
          alt="loader"
          height="6px"
          width="20px"
          src={textloading}
          style={{opacity: 0.5, padding: '0px 4px'}}
        />
      ) : (
        `(${currentLength})`
      )}
    </span>
  );
  if (isMobile) {
    return (
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        {currentData} <em>/</em>
        <span>{formattedTotalResults}</span>
      </Stack>
    );
  }
  return (
    <Stack
      spacing={0.5}
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      color={themeColorMode(
        theme,
        theme.palette.grey.main,
        theme.palette.grey.contrastText
      )}>
      <span>{showingTxt}</span>
      <span>{currentData}</span>
      <span>{ofTxt}</span>
      <span>{formattedTotalResults}</span>
      <span>{resultsTxt}</span>
      {forTitle && (
        <>
          <span>{forTxt}</span>
          <span>{`${unescape(forTitle)}`}</span>
        </>
      )}
    </Stack>
  );
};

SearchResultsCounter.propTypes = {
  request: PropTypes.bool,
  forTitle: PropTypes.string,
  totalResults: PropTypes.number,
  currentLength: PropTypes.number,
};
export default SearchResultsCounter;
