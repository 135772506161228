import * as TYPES from './constants';
import {
  requestTuitionLink,
  receiveTuitionLink,
  handleTuitionLinkError,
} from './actions';
import {Services} from 'core';
import {API} from 'config/superuser.config';

const initialState = {
  data: [],
  error: null,
  request: null,
};
const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_TUITION_LINK:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_TUITION_LINK:
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload,
      };
    case TYPES.TUITION_LINK_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchTuitionLink = () => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestTuitionLink());
  let endpoint = `${API.administration.institution_link}`;
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(result => {
        if (Array.isArray(result) && result.length > 0) {
          dispatch(receiveTuitionLink(result));
        } else {
          dispatch(handleTuitionLinkError());
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(handleTuitionLinkError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleTuitionLinkError(err));
  }
};
