import * as TYPES from './constants';
import {
  requestAchievementOptions,
  receivedAchievementOptions,
  AchievementOptionsError,
} from './actions';

import {API} from 'config/businesspartner.config';
import {Services} from 'core';

const initialState = {
  data: null,
  request: null,
  error: null,
};

const services = new Services();
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_ACHIEVEMENT_OPTIONS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVED_ACHIEVEMENT_OPTIONS:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.ACHIEVEMENT_OPTIONS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchAchievementOptionsData = () => (dispatch, getState) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();
  dispatch(requestAchievementOptions());

  let endpoint = `${API.administration.acheivement_options}`;

  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receivedAchievementOptions(res));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(AchievementOptionsError(err));
  }
};
