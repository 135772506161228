import * as TYPES from './constants';
import {API} from 'config/srm.config';
import {
  requestPrivacySetting,
  receivePrivacySetting,
  handlePrivacySettingError,
} from './actions';

import {Services} from 'core';

const services = new Services();

const initialState = {request: false, data: {}, error: false};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_PRIVACY_SETTING:
      return {
        ...state,
        error: false,
        request: true,
      };
    case TYPES.RECEIVE_PRIVACY_SETTING:
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload,
      };
    case TYPES.HANDLE_PRIVACY_SETTING_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };

    case TYPES.CLEAR_PRIVACY_SETTING:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const fetchPrivacySetting = (userName = '') => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();

  let endPoint = `${API.student.public_privacy_settings}?username=${userName}`;
  dispatch(requestPrivacySetting());
  try {
    return services.fetchRecords(endPoint, token).then(res => {
      if (res) {
        dispatch(receivePrivacySetting(res));
        return;
      }
      dispatch(handlePrivacySettingError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(handlePrivacySettingError(err));
  }
};
