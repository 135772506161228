import React from 'react';
import {Row, Col, Icon} from 'antd';
import {useDrag} from 'react-dnd';

import {isNumber} from 'core';

const style = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const MANDATORY_FIELDS = ['course_id', 'title', 'description', 'units'];
const MANDATORY_FIELDS_ELECTIVES = ['elective_id', 'elective_name', 'units'];
const CourseItem = ({
  //updateRequiredCourse,
  data = {},
  onClick,
  deleteCourse,
  courseIndex,
  stopDrag,
  isElectiveOnly,
}) => {
  const checkAllRequiredInfo = () => {
    const FIELDS = isElectiveOnly
      ? MANDATORY_FIELDS_ELECTIVES
      : MANDATORY_FIELDS;
    for (let i = 0; i < FIELDS.length; i++) {
      const FIELD = FIELDS[i];
      if (
        data[FIELD] === undefined ||
        data[FIELD] === '' ||
        data[FIELD] === null
      ) {
        if (isNumber(data[FIELD]) && data[FIELD] == 0) {
          continue;
        }
        return false;
      }
    }
    return true;
  };
  const [{isDragging}, drag] = useDrag({
    item: {data, type: 'COURSE_ITEM'},
    canDrag: () => {
      if (stopDrag) {
        return false;
      }
      return checkAllRequiredInfo();
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const isDragable = checkAllRequiredInfo();
  const opacity = isDragging ? 0.4 : 1;
  const cursor =
    (isDragable && (isDragging ? 'grabbing' : 'grab')) || 'pointer';

  return (
    <div
      ref={drag}
      style={{opacity, cursor}}
      onClick={() => !isDragable && onClick && onClick(data)}>
      <Row className="courseHeader courseItem">
        <Col
          span={deleteCourse ? 18 : 20}
          style={{...style}}
          title={data && (data.title || data.elective_id)}
          className="course-id">
          {!isDragable && <span className="icon-icuparrow" />}
          {data && (data.course_id || data.elective_id)}
        </Col>
        <Col span={4} className="course-units">
          {(data && data.units) || '-'}
        </Col>
        {deleteCourse && (
          <Col span={2}>
            <Icon
              onClick={() => deleteCourse(courseIndex)}
              type="delete"
              style={{cursor: 'pointer'}}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
export default CourseItem;
