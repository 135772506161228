import moment from 'moment';
import {
  isArray,
  isObject,
  isString,
  isEmpty,
  sortArrayOfObjPropertyBased,
} from './utils';
import {monthsShortNames} from 'config/srm.config';
import _ from 'lodash';

const DATE_FORMATS = ['MM/DD/YYYY', 'MM-DD-YYYY', 'M/D/YYYY', 'M-D-YYYY'];
export const genericSearchTerm = (searchTerm, item, searchCriteria) => {
  let completeString = '';
  let isRetunable = false;
  if (isArray(searchCriteria)) {
    searchCriteria.map(arg => {
      if (
        arg.value &&
        isArray(arg.value) &&
        arg.value.length &&
        isArray(searchTerm) &&
        searchTerm.length
      ) {
        arg.value.forEach((val, index) => {
          if (item[val] === searchTerm[index]) {
            if ((index > 0 && isRetunable) || index === 0) {
              isRetunable = true;
            }
          } else {
            isRetunable = false;
          }
        });
      }
      if (item[arg.value]) {
        if (arg.dataType === 'dateTime') {
          const date = moment(item[arg.value]);
          DATE_FORMATS.map(date_fomat => {
            completeString += date.format(date_fomat) + ' ';
          });
        } else {
          completeString += item[arg.value] + ' ';
        }
      } else if (
        !item[arg.value] &&
        arg.value === 'name' &&
        item['first_name'] &&
        item['last_name']
      ) {
        completeString += item['first_name'] + ' ' + item['last_name'] + ' ';
      }
    });
  }
  if (isRetunable) {
    return item;
  }
  completeString = completeString.toLowerCase();
  if (
    isString(searchTerm) &&
    completeString.includes(searchTerm.toLowerCase())
  ) {
    return item;
  }
};

export const searchFilter = (searchQuery, records, searchCriteria) => {
  if (records && isArray(records) && records.length) {
    const result = records.filter(path =>
      genericSearchTerm(searchQuery, path, searchCriteria)
    );
    return result;
  }
};

export const sorterFunc = (filterBy, data, key) => {
  let filterData = null;
  if (filterBy === 'all') {
    filterData = data;
  } else if (filterBy === 'active') {
    filterData = data && data.filter(item => item[key] === true);
  } else if (filterBy === 'inactive') {
    filterData = data && data.filter(item => item[key] === false);
  } else if (filterBy === 'alphabetical') {
    filterData = sortArrayOfObjPropertyBased(data, 'job_title');
  }
  return filterData;
};

export const calcActiveInactiveData = (data, key) => {
  const total = (data && isArray(data) && data.length) || 0;
  const active =
    (data && isArray(data) && data.filter(item => item[key] === true).length) ||
    0;
  const inactive = total - active;
  return {
    total,
    active,
    inactive,
  };
};

export const monthFormat = (dateStringOrObj, formatType = 'MM/DD/YYYY') =>
  moment(dateStringOrObj).format(formatType);

export const onZipCodeChange = e => {
  let zipCode = e.target.value;
  if (zipCode.length > 5 && !zipCode.includes('-')) {
    zipCode = zipCode.split('');
    zipCode.splice(5, 0, '-');
    zipCode = zipCode.join('');
    return zipCode;
  } else if (zipCode.length > 10 && zipCode.includes('-')) {
    zipCode = zipCode.substring(0, 10);
    return zipCode;
  } else {
    return zipCode;
  }
};

// DEPENDECY FOR OVERVIEW COMPONENT::::
export const prepareOverviewData = data => {
  const transformOverviewData = [];
  if (data && data.length) {
    data.map(overview => {
      const hasTitle =
        overview && overview.taskTitle && overview.taskTitle.length;
      if (overview && overview.title && overview.title.length > 0) {
        const transformOverview = {};
        transformOverview.status = true;
        transformOverview.overview_key = overview.title;
        transformOverview.overview_type = 'text';
        if (overview && overview.value && overview.value.length) {
          transformOverview.overview_values = [];
          overview.value.map((overviewValue, idx) => {
            transformOverview.overview_values.push({
              value: overviewValue,
              ...(hasTitle &&
                overview.taskTitle[idx] && {title: overview.taskTitle[idx]}),
            });
          });
        }
        if (overview && overview.taskTitle && overview.taskTitle.length) {
          transformOverview.overview_titles = [];
          overview.taskTitle.forEach(overviewTitle => {
            transformOverview.overview_titles.push({
              title: overviewTitle,
            });
          });
        }
        transformOverviewData.push(transformOverview);
      }
    });
  }
  return transformOverviewData;
};

export const preparePostOverviewData = overviews => {
  const transformedOverviewData = [];
  if (overviews && overviews.length) {
    overviews.map(overview => {
      const transformedOverview = {};
      if (overview.status && overview.overview_key.length > 0) {
        transformedOverview.title = overview.overview_key;
        if (overview.overview_values.length) {
          transformedOverview.value = [];
          overview.overview_values.map(overviewValue => {
            transformedOverview.value.push(overviewValue.value);
          });
        }
        transformedOverviewData.push(transformedOverview);
      }
    });
  }
  return transformedOverviewData;
};

export const calculateQuaterlyMonthsData = (
  noOfMonths,
  orignalData,
  uniqYears = []
) => {
  const currentDate = new Date();
  const CurrentMonth = currentDate.getMonth() + 1;
  const CurrentYear = currentDate.getFullYear().toString();
  const LastYear = (currentDate.getFullYear() - 1).toString();
  const StartIndex = CurrentMonth - noOfMonths;
  let data = [];
  const orignalLen = orignalData.length;
  const isCurrentYearPresent = uniqYears.includes(CurrentYear);
  const isLastYearPresent = uniqYears.includes(LastYear);

  if (orignalLen > 12 && StartIndex <= 0 && isCurrentYearPresent) {
    const pastYearData =
      StartIndex === 0 ? [] : [...orignalData].splice(0, orignalLen - 12);
    const currentYearData = [...orignalData].splice(
      orignalLen - 12,
      orignalLen
    );
    const data1 =
      (isLastYearPresent && pastYearData.splice(StartIndex, noOfMonths)) || [];
    const data2 = currentYearData.splice(
      0,
      (isLastYearPresent && noOfMonths - data1.length) || CurrentMonth
    );
    data = [...data1, ...data2];
    return data;
  } else if (orignalLen <= 12 && StartIndex <= 0 && isCurrentYearPresent) {
    // data = orignalData.splice(0, noOfMonths + StartIndex);
    data = orignalData.splice(-noOfMonths);
    return data;
  } else if (!isCurrentYearPresent && isLastYearPresent && StartIndex < 0) {
    // check for last year is present
    const pastYearData = [...orignalData];
    const data1 = pastYearData.splice(StartIndex, noOfMonths);
    data = [...data1];
    return data;
  } else if (!isCurrentYearPresent && !isLastYearPresent) {
    data = orignalData.splice(0, 0);
    return data;
  } else if (orignalLen >= 12 && StartIndex > 0 && isCurrentYearPresent) {
    const currentYearData = [...orignalData].splice(
      orignalLen - 12,
      orignalLen
    );
    data = currentYearData.splice(StartIndex, noOfMonths);
    return data;
  }
  return data;
};

export const getSortedUniqueYears = data => {
  let uniqYears = [];
  if (data && isObject(data) && !isEmpty(data)) {
    Object.keys(data).forEach(key => {
      if (
        data[key] &&
        data[key]['timeline'] &&
        !isEmpty(data[key]['timeline'])
      ) {
        uniqYears.push(...Object.keys(data[key]['timeline']));
      } else if (data[key] && !data[key]['timeline'] && !isEmpty(data[key])) {
        uniqYears.push(...Object.keys(data[key]));
      }
    });
    uniqYears = _.uniq(uniqYears).sort();
  }
  return uniqYears;
};

export const prepareTimelineLables = (data, uniqueYears) => {
  const labels = [];
  const populateLabels = (month, year) => {
    if (!labels.includes(`${month}-${year}`)) {
      labels.push(`${month}-${year}`);
    }
  };
  const prepareLabels = data => {
    uniqueYears.forEach(year => {
      if (!data[year] && year !== 'count') {
        console.log(monthsShortNames);
        Object.keys(monthsShortNames).forEach(month =>
          populateLabels(month, year)
        );
      } else {
        Object.keys(data[year]).forEach(month => populateLabels(month, year));
      }
    });
  };
  Object.keys(data).forEach(key => {
    if (data[key] && data[key]['timeline'] && !isEmpty(data[key]['timeline'])) {
      prepareLabels(data[key]['timeline']);
    } else if (data[key] && !isEmpty(data[key])) {
      prepareLabels(data[key]);
    }
  });
  return labels;
};

export const prepareTimeLineDatasets = (
  dummyData,
  actualData,
  uniqYears,
  noOfMonths
) => {
  const datasets = [];
  const prepareData = data => {
    let timelineCombineData = [];
    uniqYears.forEach(year => {
      if (year !== 'count') {
        let calculatedData = [];
        if (!data[year]) {
          calculatedData = Object.values(monthsShortNames);
        } else {
          // if Year Data is not present fill all months with 0
          calculatedData =
            (data[year] && Object.values(data[year])) || new Array(12).fill(0);
        }
        timelineCombineData.push(...calculatedData);
      }
    });
    if (noOfMonths !== 'all') {
      timelineCombineData = calculateQuaterlyMonthsData(
        noOfMonths,
        timelineCombineData,
        uniqYears
      );
    }
    return timelineCombineData;
  };

  dummyData &&
    dummyData.datasets &&
    dummyData.datasets.forEach(timeLineData => {
      if (
        actualData[timeLineData.key] &&
        actualData[timeLineData.key]['timeline'] &&
        !isEmpty(actualData[timeLineData.key]['timeline'])
      ) {
        const data = prepareData(actualData[timeLineData.key]['timeline']);
        datasets.push(Object.assign({}, timeLineData, {data}));
      } else if (
        actualData[timeLineData.key] &&
        !isEmpty(actualData[timeLineData.key])
      ) {
        const data = prepareData(actualData[timeLineData.key]);
        datasets.push(Object.assign({}, timeLineData, {data}));
      }
    });
  return datasets;
};

export const calculateGraphData = (
  graphDataObj,
  monthsSelector,
  staticDataObj
  // yearSelector
) => {
  if (graphDataObj && staticDataObj) {
    const currentDate = new Date();
    const CurrentYear = currentDate.getFullYear();
    const DummyData = Object.assign({}, staticDataObj);
    const timeLineData = {};
    let data = Object.assign({}, graphDataObj);
    // Important to check future years present.
    // YEAR FILTER ::  Awaiting for confirmation
    // if (yearSelector !== 'all') {
    //   let modifyData = {};
    //   Object.keys(data).forEach(mainKey => {
    //     const newDataObj = Object.assign({}, { [mainKey]: { timeline: {} } });
    //     data[mainKey] &&
    //       Object.keys(data[mainKey].timeline).filter(year => {
    //         if (year == yearSelector) {
    //           newDataObj[mainKey].timeline[year] = {
    //             ...data[mainKey].timeline[year],
    //           };
    //         }
    //       });
    //     modifyData = { ...modifyData, ...newDataObj };
    //   });
    //   data = modifyData;
    // }

    if (monthsSelector !== 'all') {
      let modifyData = {};
      Object.keys(data).forEach(mainKey => {
        const newDataObj = Object.assign({}, {[mainKey]: {timeline: {}}});
        data[mainKey] &&
          Object.keys(data[mainKey].timeline).filter(year => {
            if (year <= CurrentYear) {
              newDataObj[mainKey].timeline[year] = {
                ...data[mainKey].timeline[year],
              };
            }
          });
        modifyData = {...modifyData, ...newDataObj};
      });
      data = modifyData;
    }
    const uniqYears = getSortedUniqueYears(data);
    const labels = prepareTimelineLables(data, uniqYears);
    const datasets = prepareTimeLineDatasets(
      DummyData,
      data,
      uniqYears,
      monthsSelector
    );
    timeLineData.labels = labels;
    timeLineData.datasets = datasets;
    if (monthsSelector !== 'all') {
      timeLineData.labels = calculateQuaterlyMonthsData(
        monthsSelector,
        timeLineData.labels,
        uniqYears
      );
    }
    return timeLineData;
  }
};

export const queryStringParse = data =>
  data
    .substring(1)
    .split('&')
    .map(item => {
      const idx = item.indexOf('=');
      const variableName = item.substring(0, idx);
      const variableValue = item.substring(idx + 1);
      return Object.assign(
        {},
        {
          [variableName]: variableValue,
        }
      );
    })
    .reduce(function(result, current) {
      return Object.assign(result, current);
    }, {});

export const trimAndJoinString = data =>
  data
    .trim()
    .replace(/ +/g, '-')
    .toLowerCase();
