import MyriadProBold from './MyriadProBold.OTF';
import MyriadProLight from './MyriadProLight.OTF';
import MyriadProRegular from './MyriadProRegular.OTF';
import MyriadProSemiBold from './MyriadProSemiBold.OTF';

export const MyriadProFonts = {
  LIGHT: {
    OTF: MyriadProLight,
  },
  REGULAR: {
    OTF: MyriadProRegular,
  },
  SEMIBOLD: {
    OTF: MyriadProSemiBold,
  },
  BOLD: {
    OTF: MyriadProBold,
  },
};
