import React, {useState, useEffect} from 'react';
import {Form, DatePicker, Row, Col} from 'antd';
import moment from 'moment';
import {monthFormat} from 'core';

const FormItem = Form.Item;

const StartEndDate = props => {
  const {
    mode,
    licenseType,
    form: {getFieldDecorator},
    data: {contract_end_date, contract_start_date},
  } = props;
  const [endDate, setEndDate] = useState(
    contract_end_date ? moment(contract_end_date) : null
  );
  const [startDate, setStartDate] = useState(
    contract_start_date ? moment(contract_start_date) : null
  );
  const disabledStartDate = current => endDate && endDate < current;
  const disabledEndDate = current => startDate && startDate > current;
  const displayForm = mode === 'create' || mode === 'edit' ? true : false;

  useEffect(
    () => {
      if (!displayForm) {
        setEndDate(null);
        setStartDate(null);
      }
    },
    [displayForm]
  );

  return (
    <Row style={{padding: mode === 'create' && '20px'}}>
      <Col span={12}>
        <FormItem label="Start Date">
          {displayForm ? (
            getFieldDecorator('contract_start_date', {
              rules: [
                {
                  required: licenseType !== 'Consortium' ? true : false,
                  message: 'Start Date field cannot be empty.',
                },
              ],
              initialValue: contract_start_date
                ? moment(contract_start_date)
                : undefined,
            })(
              <DatePicker
                format="MM-DD-YYYY"
                disabledDate={disabledStartDate}
                onChange={val => setStartDate(val)}
              />
            )
          ) : (
            <span className="txt">
              {contract_start_date
                ? monthFormat(contract_start_date, 'LL')
                : '-'}
            </span>
          )}
        </FormItem>
      </Col>
      <Col span={12}>
        <FormItem label="End Date">
          {displayForm ? (
            getFieldDecorator('contract_end_date', {
              rules: [
                {
                  required: licenseType === 'Reporter',
                  message: 'End Date field cannot be empty.',
                },
              ],
              initialValue: contract_end_date
                ? moment(contract_end_date)
                : undefined,
            })(
              <DatePicker
                format="MM-DD-YYYY"
                disabledDate={disabledEndDate}
                onChange={val => setEndDate(val)}
              />
            )
          ) : (
            <span className="txt">
              {contract_end_date ? monthFormat(contract_end_date, 'LL') : '-'}
            </span>
          )}
        </FormItem>
      </Col>
    </Row>
  );
};

export default StartEndDate;
