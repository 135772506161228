import * as TYPES from './constants';
import {
  requestPathDetails,
  handlePathDetailsError,
  receivePathDetails,
} from './actions';
import {API, JSON_HEADER, SUBSCRIPTION_HEADER} from 'config/srm.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_PATH_DETAILS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_PATH_DETAILS:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.REMOVE_PATH_DETAILS:
      return {
        ...state,
        data: null,
      };
    case TYPES.REQUEST_PATH_DETAILS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

const fetchAllCoursesDetails = data => (dispatch, getState) => {
  const secret = `${API.pathway.secret}`;
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, SUBSCRIPTION_HEADER(secret), JSON_HEADER),
    cache: 'default',
  };
  try {
    const courseArray = data.programPaths[0].basicInformation.requiredCourses;
    Promise.all(
      courseArray.map(course => {
        return fetch(
          `${API.pathway.course_search}docId=1548326699167&courseId=${course}`,
          config
        )
          .then(response => response.json())
          .then(json => json)
          .catch(err => {});
      })
    ).then(res => {
      data['allCoursesDetails'] = res.filter(course => course.status !== 404);
      dispatch(receivePathDetails(data));
    });
  } catch (err) {}
};

export const fetchPathDetails = program => (dispatch, getState) => {
  const secret = `${API.pathway.secret}`;
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, SUBSCRIPTION_HEADER(secret), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestPathDetails());

  try {
    return fetch(
      `${API.pathway.program_paths}docId=1548326699167&programName=${program}`,
      config
    )
      .then(response => response.json())
      .then(json => {
        // promise All for fetching courses
        if (json.status !== 404) {
          dispatch(fetchAllCoursesDetails(json));
        } else {
          dispatch(handlePathDetailsError(json.message));
        }
      })
      .catch(err => {
        dispatch(handlePathDetailsError(err));
      });
  } catch (err) {
    dispatch(handlePathDetailsError(err));
  }
};
