import React from 'react';
import {Modal, Form} from 'antd';

import {Course} from 'sharedResources/modules';

const {AddUpdateCourse} = Course;

const AddCourse = ({
  visible,
  toggleAddNewCourse,
  form,
  creditStatus,
  createCourse,
  courses,
  courseInstructionTypes,
  courseTransferTypes,
  data,
  skillsData,
}) => {
  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      //co_requisites, skills_learned,pre_requisites
      Object.keys(values).map(value => {
        if (values[value] === '' || values[value] === undefined) {
          values[value] = null;
        }
      });
      // For temporary use
      if (values['co_requisites'] === null) {
        values['co_requisites'] = [];
      }
      if (values['pre_requisites'] === null) {
        values['pre_requisites'] = [];
      }
      if (values['skills_learned'] === null) {
        values['skills_learned'] = [];
      }
      if (!err) {
        createCourse(values);
      }
    });
  };

  const checkForExistsID = (rule, value, callback) => {
    if (value && data && data.course_id === value) {
      callback();
      return;
    }
    if (courses && courses.length && value) {
      const exists = courses.filter(
        course => course.course_id.toLowerCase() === value.toLowerCase()
      );
      if (exists.length) {
        callback(true);
        return;
      }
    }
    callback();
  };

  return (
    <Modal
      title={`${data ? 'Edit' : 'Add New'} Course`}
      maskClosable={false}
      visible={visible}
      onOk={handleSubmit}
      okText={`${data ? 'Save' : 'Add'}`}
      onCancel={toggleAddNewCourse}
      width="800px"
      className="modal-move-top add-new-course-form scroller">
      <AddUpdateCourse
        form={form}
        checkForExistsID={checkForExistsID}
        courses={courses}
        creditStatus={creditStatus}
        courseInstructionTypes={courseInstructionTypes}
        courseTransferTypes={courseTransferTypes}
        data={data || {}}
        skillsData={skillsData}
      />
    </Modal>
  );
};

export default Form.create()(AddCourse);
