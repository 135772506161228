import React from 'react';
import PropTypes from 'prop-types';
import {Select} from 'antd';
import {isArray, isString, isObject} from 'core';

const Option = Select.Option;

const Filter = ({
  onFilter,
  filterOptions,
  filterLabel,
  normal,
  noDefaultFilterOption,
  defaultOptionValue,
  overlayClassName,
}) => {
  const changeHandler = val => onFilter(val);
  if (filterOptions) {
    return (
      <div className="filter-field">
        {filterLabel && <span className="filter-label">{filterLabel}</span>}
        <Select
          dropdownClassName={overlayClassName}
          defaultValue={defaultOptionValue ? defaultOptionValue : 'all'}
          onChange={changeHandler}>
          {!noDefaultFilterOption && <Option value="all">All</Option>}
          {normal &&
            isArray(filterOptions) &&
            filterOptions.map((filterOption, idx) => {
              if (isString(filterOption)) {
                return (
                  <Option value={filterOption} key={idx} title={filterOption}>
                    {filterOption}
                  </Option>
                );
              }
              if (isObject(filterOption) && filterOption.type) {
                const {type, value} = filterOption;
                return (
                  <Option value={value || type} key={idx} title={type}>
                    {type}
                  </Option>
                );
              }
            })}
          {!normal &&
            isArray(filterOptions) &&
            filterOptions.map((opt, idx) => (
              <Option
                value={
                  opt.info_request_type_details.display === 'Custom'
                    ? opt.display
                    : opt.info_request_type_details.display
                }
                key={
                  opt.info_request_type_details.uuid ||
                  `${opt.info_request_type_details.title}-${idx}`
                }>
                {opt.info_request_type_details.title === 'Others'
                  ? opt.display
                  : opt.info_request_type_details.title}
              </Option>
            ))}
        </Select>
      </div>
    );
  } else {
    return null;
  }
};

Filter.propTypes = {
  filterOptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.object),
  ]),
};

export default Filter;
