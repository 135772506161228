import {API} from 'config/srm.config';
import * as TYPES from './constants';
import {requestCatalog, receiveCataog, handleCatalogError} from './actions';
import {Services} from './../../../core/services';

const services = new Services();

const initialState = {
  request: null,
  data: {
    /*catalogs:[{title: 'abcdefghijk', docid: 123, active: true},{title: '1234556677', docid:456, active: false}]*/
  },
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_CATALOG:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_CATALOG:
      if (action.payload && action.payload.catalogs) {
        action.payload.catalogs.map((catalog, idx) => (catalog.key = idx + 1));
      }
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };

    case TYPES.CATALOG_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchCatalogs = () => (dispatch, getState) => {
  const {
    auth: {
      access: {token = null},
    },
  } = getState();
  dispatch(requestCatalog());
  let endpoint = API.course_management.catalog;
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receiveCataog(res));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleCatalogError(err));
  }
};
