import * as TYPES from './constants';
import {
  requestInstitutions,
  receiveInstitutions,
  receiveInstitutionsListForFilter,
  handleInstitutionsError,
} from './actions';

import {getLoggedInstituteUUID} from 'redux/modules/auth';

import {Services} from 'core';

import {API} from 'config/superuser.config';

const initialState = {
  filterInstitutionList: null,
  statsInfo: {},
  data: null,
  request: null,
  error: null,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_CONSORTIUM_INSTITUTIONS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_CONSORTIUM_INSTITUTIONS:
      return {
        ...state,
        data: action.payload || null,
        request: false,
        error: false,
      };
    case TYPES.RECEIVE_CONSORTIUM_INSTITUTIONS_LIST_FOR_FILTER:
      return {
        ...state,
        filterInstitutionList:
          (action.payload && action.payload.license) || null,
        statsInfo: (action.payload && action.payload.overview_details) || {},
        request: false,
        error: false,
      };
    case TYPES.CONSORTIUM_INSTITUTIONS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchInstitutions = (
  consortiumId = null,
  page = 1,
  pageSize = 12,
  type,
  sort = 'recent',
  status,
  search
) => (dispatch, getState) => {
  let isFetchingforFilter = false;
  if (
    consortiumId === null &&
    page === null &&
    pageSize === null &&
    type === null &&
    sort === null &&
    status === null &&
    search === null
  ) {
    isFetchingforFilter = true;
  }

  const {
    auth: {access = {}, access: {token} = {}},
  } = getState();
  console.log('cc', getState());
  const parentId = consortiumId || getLoggedInstituteUUID(getState());
  if (parentId) {
    dispatch(requestInstitutions());
    let endpoint = isFetchingforFilter
      ? `${API.administration.institution}?parent=${parentId}&show=institution`
      : `${
          API.administration.institution
        }?parent=${parentId}&show=institution&page=${page}&page_size=${pageSize}&sort=${sort}`;
    if (type && type !== 'all') {
      endpoint = endpoint.concat(`&type=${type}`);
    }
    if (status && status !== 'all') {
      endpoint = endpoint.concat(`&status=${status}`);
    }

    if (search) {
      endpoint = endpoint.concat(`&search=${search}`);
    }
    try {
      return services
        .fetchRecords(endpoint, token)
        .then(res => {
          if (isFetchingforFilter) {
            dispatch(receiveInstitutionsListForFilter(res));
            return;
          }
          dispatch(receiveInstitutions(res));
        })
        .catch(err => console.log(err));
    } catch (err) {
      console.log(err);
      dispatch(handleInstitutionsError(err));
    }
  }
};
