import StyleButton from './StyleButton';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';

var INLINE_STYLES = [
  {label: 'Bold', style: 'BOLD', icon: FormatBoldIcon},
  {label: 'Italic', style: 'ITALIC', icon: FormatItalicIcon},
  // {label: 'Underline', style: 'UNDERLINE', icon: FormatUnderlinedIcon},
  //   {label: 'Monospace', style: 'CODE'},
];

const FontStyles = props => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          icon={type.icon}
        />
      ))}
    </div>
  );
};
export default FontStyles;
