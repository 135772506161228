import React from 'react';
import RadarChart from 'core/components/RadarChart';
import {RadarData, RadarOptions} from './Data';

const SpiderChart = ({data}) => {
  if (!data) return null;
  const labelLength = 16;
  const dataLength = 11;
  const dataSetData = [];
  const labels = data
    ? data.map((item = {}) => {
        dataSetData.push(item.experience);
        return item.skill;
      })
    : [];

  const radarData = {
    labels: [...labels, ...Array.from({length: labelLength - data.length})],
    datasets: [
      {
        ...RadarData.datasets[0],
        data:
          data && data.length
            ? [
                ...dataSetData,
                ...Array.from({length: dataLength - data.length}, () => 0),
              ]
            : [],
      },
    ],
  };

  return <RadarChart data={radarData} options={RadarOptions} />;
};

export default SpiderChart;
