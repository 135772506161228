import React from 'react';
import {Tag, Row} from 'antd';
import './skillsTable.css';

const SkillsTable = ({data = [], deleteSkill, editSkill, viewOnly}) => {
  const getLineWidth = value => (value * 100) / 10;

  return (
    <div>
      {data && data.length ? (
        <div className="graph-results">
          <table className="graph-table">
            <thead>
              <tr>
                <th style={{width: '25%'}}>Skills Tag</th>
                <th style={{width: '5%'}} className="dividerLine" />
                <th style={{width: '55%'}}>
                  Years of Experience
                  <Row type="flex" align="middle" justify="space-between">
                    <span>
                      <h5>0</h5>
                    </span>
                    <span>
                      <h5>10+</h5>
                    </span>
                  </Row>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((dt = {}, index) => (
                  <tr key={index}>
                    <td
                      key={dt.uuid}
                      style={{width: '25%'}}
                      title={`${dt.skill} (${dt.experience}${
                        dt.experience > 1 ? ' yrs' : ' yr'
                      })`}>
                      <Tag color="#e6e7ff">
                        <span>
                          {`${
                            dt.skill && dt.skill.length > 35
                              ? dt.skill.substring(0, 35) + '..'
                              : dt.skill
                          }
                          (${dt.experience}${
                            dt.experience > 1 ? ' yrs' : ' yr'
                          })`}
                        </span>
                      </Tag>
                    </td>
                    <td style={{width: '5%'}} className="dividerLine" />
                    <td style={{width: '55%'}} title={dt.experience}>
                      <div className="bar-perc-c">
                        <div className="bar-perc-h">
                          <div
                            style={{width: `${getLineWidth(dt.experience)}%`}}
                            className="bar-perc"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="noSkillFound" justify="center" align="middle">
          <h5>No Skills Found</h5>
        </div>
      )}
    </div>
  );
};

export default SkillsTable;
