export const REQUEST_UPLOAD_PROFILE_DATA = 'REQUEST_UPLOAD_PROFILE_DATA';
export const UPLOAD_PROFILE_DATA_SUCCESS_ERROR =
  'UPLOAD_PROFILE_DATA_SUCCESS_ERROR';
export const REQUEST_DASHBOARD_DATA = 'REQUEST_DASHBOARD_DATA';
export const RECEIVE_DASHBOARD_DATA = 'RECEIVE_DASHBOARD_DATA';
export const DASHBOARD_DATA_ERROR = 'DASHBOARD_DATA_ERROR';
export const DASHBOARD_PROGRAM_LIST_FILTER = 'DASHBOARD_PROGRAM_LIST_FILTER';
export const DASHBOARD_PROGRAM_ENROLLMENT_FILTER =
  'DASHBOARD_PROGRAM_ENROLLMENT_FILTER';
export const RECEIVE_DASHBOARD_PROGRAM_ENROLLMENT_DATA =
  'RECEIVE_DASHBOARD_PROGRAM_ENROLLMENT_DATA';
export const REQUEST_DASHBOARD_PROGRAM_ENROLLMENT_DATA =
  'REQUEST_DASHBOARD_PROGRAM_ENROLLMENT_DATA';
export const DASHBOARD_PROGRAM_ENROLLMENT_DATA_ERROR =
  'DASHBOARD_PROGRAM_ENROLLMENT_DATA_ERROR';
