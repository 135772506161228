import * as TYPES from './constants';
import {requestPrograms, receivePrograms, handleProgramsError} from './actions';

import {JSON_HEADER} from 'config/srm.config';
import {API} from 'config/srm.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_PROGRAMS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_PROGRAMS:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.PROGRAMS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

// fetch programs:::
export const fetchPrograms = term => (dispatch, getState) => {
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JSON_HEADER),
    cache: 'default',
  };
  if (term) {
    dispatch(requestPrograms());
    try {
      return fetch(`${API.pathway.program}?name=${term}`, config)
        .then(response => response.json())
        .then(json => {
          dispatch(receivePrograms(json));
        })
        .catch(err => {
          console.log(err);
          dispatch(handleProgramsError(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(handleProgramsError(err));
    }
  }
};
