import * as TYPES from 'redux/types';
import {API, JSON_HEADER, userTypePermissions} from 'config/srm.config';
import {API as ADMIN_API} from 'config/superuser.config';
import {saveTokens, removeTokens} from 'redux/localStorage';

export const authStart = () => ({
  type: TYPES.AUTH_REQUEST,
});

export const appStatus = status => {
  return {
    type: TYPES.APP_STATUS,
    status,
  };
};

const getUiPermissions = payload => {
  //can be used in future
  //userTypePermissions.includes(payload.user_type)
  const allowAccess = payload && true;
  const UIPermissions = {
    userManagement: {
      menuAccess: allowAccess,
      statusChange: allowAccess,
      addNewUser: allowAccess,
    },
    eventManagement: {
      menuAccess: allowAccess,
      addNewEvent: allowAccess,
    },
    pathways: {
      menuAccess: allowAccess,
      addNewPathway: allowAccess,
    },
    partners: {
      menuAccess: allowAccess,
      addNewPartner: allowAccess,
    },
  };
  return UIPermissions;
};

export const authSuccess = (payload, body) => {
  if (
    payload.ChallengeName &&
    payload.ChallengeName === 'NEW_PASSWORD_REQUIRED' &&
    payload.Session
  ) {
    const bodyObj = JSON.parse(body);
    payload.userName = bodyObj.username;
    return {
      payload,
      type: TYPES.NEW_PASSWORD_REQUIRED,
    };
  }

  payload.UIPermissions = getUiPermissions(payload);
  saveTokens(payload);
  return {
    payload,
    type: TYPES.AUTH_SUCCESS,
  };
};

export const authRequest = (payload, body) => {
  return {
    payload,
    type: TYPES.AUTH_REQUEST,
  };
};

export const authFail = msg => {
  return {
    type: TYPES.AUTH_ERROR,
    msg,
  };
};

export const refreshAccessToken = payload => {
  payload.UIPermissions = getUiPermissions(payload);
  saveTokens(payload);
  return {
    payload,
    type: TYPES.REFRESH_ACCESS_TOKEN,
  };
};

export const login = (username, password, isAdmin) => {
  let endpoint = `${API.auth.token}`;
  if (isAdmin) {
    endpoint = `${ADMIN_API.auth.token}`;
  }
  //Access Token API
  return {
    type: TYPES.APIREQUEST,
    payload: {
      endpoint,
      method: 'POST',
      headers: JSON_HEADER,
      onRequestStart: authRequest,
      body: JSON.stringify({username, password}),
      onsuccess: authSuccess,
      onerror: authFail,
    },
  };
};

export const createPassword = (email, new_password, session) => ({
  type: TYPES.APIREQUEST,
  payload: {
    endpoint: `${API.auth.forceResetPassword}`,
    method: 'POST',
    headers: JSON_HEADER,
    body: JSON.stringify({email, new_password, session}),
    onsuccess: authSuccess,
    onerror: authFail,
  },
});

export const logout = () => {
  removeTokens();
  return {
    type: TYPES.SIGNOUT,
  };
};

export function withJwtAuth(token, headers = {}) {
  return state => ({
    ...headers,
    Authorization: `Bearer ${token}`,
  });
}

// INSTANCE ACTIONS
export const requestInstance = () => ({
  type: TYPES.INSTANCE_REQUEST,
});
export const recieveInstance = json => ({
  type: TYPES.INSTANCE_SUCCESS,
  payload: json,
});
export const errorInstance = err => ({
  type: TYPES.INSTANCE_ERROR,
  err,
});
export const clearInstance = () => ({
  type: TYPES.CLEAR_INSTANCE,
});
