import React, {Fragment} from 'react';
import {Form, Input} from 'antd';
import {
  PhoneExtInput,
  FieldWithCount,
  PATTERN,
  isObject,
  isEmpty,
  isNumber,
  StepAction,
} from 'core';

const FormItem = Form.Item;

const PrimaryContacts = ({
  form,
  form: {getFieldDecorator},
  onSubmit,
  currentStep,
  totalSteps,
  goToPrevStep,
  mode,
  onSwitchMode,
  data,
  isSaving,
  className,
  updatePrimaryContacts,
}) => {
  const displayForm = mode === 'create' || mode == 'edit' ? true : false;

  const CommonFields = [
    // {
    //   field_type: 'hidden',
    //   localKey: 'primary_contact_uuid',
    //   rules: [],
    // },
    {
      field_type: 'countField',
      count_field: {
        fieldtype: 'Input',
        showcount: true,
        maxLength: 40,
      },
      localKey: 'first_name',
      label: 'First Name',
      rules: [
        {
          required: true,
          message: 'First name cannot be empty',
        },
        {
          pattern: PATTERN.Name_With_Space,
          message: 'Please enter a valid name',
        },
      ],
    },
    {
      field_type: 'countField',
      count_field: {
        fieldtype: 'Input',
        showcount: true,
        maxLength: 40,
      },
      localKey: 'last_name',
      label: 'Last Name',
      rules: [
        {
          required: true,
          message: 'Last name cannot be empty',
        },
        {
          pattern: PATTERN.Name_With_Space,
          message: 'Please enter a valid name',
        },
        {max: 40, message: 'Maximum 40 characters allowed.'},
      ],
    },
    {
      field_type: 'input',
      localKey: 'email',
      label: 'Email',
      rules: [
        {
          required: true,
          message: 'Please enter email',
        },
        {
          whitespace: true,
          type: 'email',
          message: 'Please enter a valid email',
        },
      ],
    },
    {
      field_type: 'phone',
      localKey: 'phone',
      label: 'Phone',
      rules: [
        {
          required: true,
          message: 'Phone cannot be empty',
        },
        {
          validator(rule, value, callback) {
            if (value && isObject(value) && !isEmpty(value)) {
              let phoneValue;
              if (value.phone_no.startsWith('+1')) {
                phoneValue = parseInt(value.phone_no.substr(2)) || null;
              } else {
                phoneValue = parseInt(value.phone_no) || null;
              }

              let phone_extension = value.phone_extension || null;
              if (phoneValue && isNumber(phoneValue)) {
                if (PATTERN.Phone.test(Number(phoneValue))) {
                  if (phone_extension && phone_extension !== '') {
                    if (PATTERN.Ext.test(String(phone_extension))) {
                      callback();
                      return;
                    } else {
                      callback('Enter a valid ext.');
                      return;
                    }
                  }
                  callback();
                  return;
                } else {
                  callback('Please enter a valid phone no.');
                  return;
                }
              } else {
                if (
                  !phoneValue &&
                  value &&
                  value.phone_no &&
                  value.phone_no.length > 2
                ) {
                  callback('Please enter a valid phone no.');
                  return;
                }
                // byPass non required fields.
                if (
                  rule.field == 'primary_contact_2.phone' ||
                  rule.field == 'primary_contact_3.phone'
                ) {
                  callback();
                  return;
                }
                callback('Phone field can not be empty.');
              }
            }
            callback();
          },
        },
      ],
    },
  ];

  const primaryContacts = [
    {
      headerName: 'Billing Contact Info',
      foreignKey: 'billing_support_contact_info',
      fields: [...CommonFields],
    },
    {
      headerName: 'Technical Contact Info',
      foreignKey: 'technical_contact_info',
      fields: [...CommonFields],
    },
    {
      headerName: 'Customer Support Contact Info',
      foreignKey: 'customer_support_contact_info',
      fields: [...CommonFields],
    },
  ];

  let considerContacts = primaryContacts;
  if (updatePrimaryContacts) {
    considerContacts = updatePrimaryContacts.map(contact => {
      contact.fields = [...CommonFields];
      return contact;
    });
  }

  return (
    <Form className="table-form" onSubmit={event => onSubmit(event, form)}>
      <div className={`content-pad ${className} ${mode}`}>
        {considerContacts &&
          considerContacts.map(
            ({headerName, foreignKey, fields, isNotRequired}, idx) => {
              return (
                <Fragment key={idx}>
                  <h1 className="form-head">{headerName}</h1>
                  <div className="table-form-2-col">
                    {fields &&
                      fields.map(
                        (
                          {label, field_type, localKey, rules, count_field},
                          index
                        ) => {
                          let rulesToBeBinded = [...rules];
                          let firstRule = null;
                          if (isNotRequired && rules.length) {
                            firstRule = Object.assign({}, rules[0], {
                              required: false,
                            });
                            rulesToBeBinded[0] = firstRule;
                          }
                          return (
                            <FormItem
                              key={'field-' + index}
                              label={label || null}
                              style={{
                                display: field_type === 'hidden' && 'none',
                              }}>
                              {displayForm ? (
                                getFieldDecorator(`${foreignKey}.${localKey}`, {
                                  rules: [...rulesToBeBinded],
                                  initialValue:
                                    field_type === 'phone'
                                      ? {
                                          phone_no:
                                            (data &&
                                              data[foreignKey] &&
                                              data[foreignKey][localKey]) ||
                                            '+1',
                                          phone_extension:
                                            (data &&
                                              data[foreignKey] &&
                                              data[foreignKey][
                                                'phone_extension'
                                              ]) ||
                                            undefined,
                                        }
                                      : (data &&
                                          data[foreignKey] &&
                                          data[foreignKey][localKey]) ||
                                        undefined,
                                })(
                                  field_type === 'phone' ? (
                                    <PhoneExtInput />
                                  ) : field_type === 'hidden' ? (
                                    <Input type="hidden" />
                                  ) : field_type === 'countField' ? (
                                    <FieldWithCount {...count_field} />
                                  ) : (
                                    <Input />
                                  )
                                )
                              ) : (
                                <span
                                  style={{
                                    display: field_type === 'hidden' && 'none',
                                  }}
                                  className="readOnly">
                                  <span className="txt">
                                    {data &&
                                      data[foreignKey] &&
                                      data[foreignKey][localKey]}
                                  </span>
                                  {field_type === 'phone' && (
                                    <span
                                      style={{marginLeft: '40px'}}
                                      className="extfield">
                                      Ext.
                                      <span
                                        style={{marginLeft: '5px'}}
                                        className="txt">
                                        {(data &&
                                          data[foreignKey] &&
                                          data[foreignKey][
                                            'phone_extension'
                                          ]) ||
                                          '-'}
                                      </span>
                                    </span>
                                  )}
                                </span>
                              )}
                            </FormItem>
                          );
                        }
                      )}
                  </div>
                </Fragment>
              );
            }
          )}
      </div>
      <FormItem className="steps-action margin-muted">
        <StepAction
          currentStep={currentStep}
          goToPrevStep={goToPrevStep}
          totalSteps={totalSteps}
          mode={mode}
          onSwitchMode={onSwitchMode}
          isSaving={isSaving}
        />
      </FormItem>
    </Form>
  );
};

export default Form.create()(PrimaryContacts);
