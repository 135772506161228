import React, {useEffect, createRef} from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'antd';
import debounce from 'lodash.debounce';

const Search = ({onSearch, selectedFilter, placeholder, classname, value}) => {
  let searchref = createRef();
  const debounceEvent = (...args) => {
    const debouncedEvent = debounce(...args);
    return e => {
      e.persist();
      return debouncedEvent(e);
    };
  };

  const changeHandler = e => onSearch(e.target.value);

  const crossHandler = () => {
    if (searchref.value) {
      searchref.value = '';
      onSearch('');
    }
  };

  useEffect(
    () => {
      searchref.value = '';
    },
    [selectedFilter]
  );
  useEffect(
    () => {
      if (value) {
        searchref.value = value;
      } else {
        searchref.value = '';
      }
    },
    [value]
  );
  return (
    <div className="search-placeholder">
      <Icon type="search" className="search-icon" />
      <input
        type="text"
        className={classname}
        placeholder={placeholder}
        ref={ref => (searchref = ref)}
        onChange={debounceEvent(changeHandler, 500)}
      />
      <Icon className="close-icon" type="close" onClick={crossHandler} />
    </div>
  );
};

Search.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

Search.defaultProps = {
  selectedFilter: 'all',
  placeholder: 'Search',
};

export default Search;
