export const getInstitutionTypesSelector = state =>
  state.reporterAppReducer && state.reporterAppReducer.institutionTypes;
// export const getReporterUUID = ({auth: {access = {}} = {}}) =>
//   access['custom:institution_uuid'];
export const getToken = ({auth: {access = {}} = {}}) => access['token'];
export const getReporterUsers = state =>
  state.reporterAppReducer && state.reporterAppReducer.reporterUsers;
export const getDescendentInstituions = state =>
  state.reporterAppReducer && state.reporterAppReducer.descendentInstitution;
export const getLoggedInInstitute = state =>
  state.reporterAppReducer && state.reporterAppReducer.loggedInInstitute;
export const getReporterUserProfileSelector = state =>
  state && state.reporterAppReducer && state.reporterAppReducer.profile;
export const getInstituteDashboardSelector = state =>
  state.reporterAppReducer && state.reporterAppReducer.instituteDashboard;
export const getInstituteDetailsSelector = state =>
  state.reporterAppReducer && state.reporterAppReducer.instituteDetails;
export const getConsortiumInstitutionsSelector = state =>
  state &&
  state.reporterAppReducer &&
  state.reporterAppReducer.consortiumInstitutions;
export const getConsortiumsSelector = state =>
  state && state.reporterAppReducer && state.reporterAppReducer.consortiums;

export const getReporterUUID = state => {
  const {
    user,
    auth: {loggedinUserId},
  } = state;
  const usr = (loggedinUserId && user ? user[loggedinUserId] : null) || {};
  return usr ? usr.institution_uuid : null;
};
