import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Icon, Avatar, Modal, message} from 'antd';

import {imgFormat} from 'config/srm.config';
import {
  isString,
  isFile,
  isArray,
  extractFileName,
  fileTypeIcon,
} from '../../utils';

import './FileUploader.css';
import Cropper from './Cropper';

class FileUploader extends Component {
  cropFile = null;
  state = {
    fileList: [],
    cropModal: false,
  };

  componentDidMount() {
    this._mounted = true;
    this.dropArea &&
      this.dropArea.addEventListener('drop', this.handleDrop, false);
    this.loadDefaultImage();
  }

  componentDidUpdate(nextProps, nextState) {
    const {fileList, file} = nextState;
    const {livePreview, multipleUpload, initialFileData, imgSrc} = this.props;
    if (
      !livePreview &&
      fileList &&
      initialFileData &&
      fileList !== initialFileData
    ) {
      this.loadDefaultImage();
    } else if (
      livePreview &&
      fileList &&
      initialFileData &&
      isArray(initialFileData) &&
      fileList.length !== initialFileData.length
    ) {
      this.previewImage(initialFileData[0]);
    } else if (
      livePreview &&
      !multipleUpload &&
      file &&
      fileList.length &&
      file.name !== fileList[0].name
    ) {
      this.previewImage(fileList[0]);
    } else if (
      (imgSrc && isFile(imgSrc) && !nextState.imgSrc) ||
      !Object.is(file, imgSrc)
    ) {
      this.previewImage(imgSrc);
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    this.dropArea &&
      this.dropArea.removeEventListener('drop', this.handleDrop, false);
  }

  handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    let {dragImage} = this.props;
    if (!dragImage) {
      return false;
    }
    let dt = e.dataTransfer;
    let files = dt.files;
    this.setState({
      onDrag: true,
    });
    this.handleFileUpload(files[0]);
  };

  forceDefaultImageLoad = () => {
    const {imgSrc, onChange, initialFileData} = this.props;
    if (this._mounted) {
      if (onChange && typeof onChange === 'function') {
        onChange(null);
      }
      this.setState({
        imgSrc: isFile(imgSrc) ? null : imgSrc,
        showDeleteButton: false,
        error: false,
        errMsg: null,
        fileList: initialFileData,
      });
    }
  };

  loadDefaultImage = loadDefault => {
    const {imgSrc, onChange, livePreview} = this.props;
    if (this.imgUpload) this.imgUpload.value = '';
    if (livePreview && loadDefault) {
      if (onChange && typeof onChange === 'function') {
        onChange(0);
        return;
      }
    }
    if (!livePreview && loadDefault) {
      this.forceDefaultImageLoad();
      return;
    }
    if (imgSrc && isFile(imgSrc)) {
      this.previewImage(imgSrc);
      if (onChange && typeof onChange === 'function') {
        onChange(imgSrc);
      }
    } else {
      this.forceDefaultImageLoad();
    }
  };

  fileDelete = val => {
    // delete file from fileList array as per the index receiving
    const {onChange, forceReset} = this.props;
    this.imgUpload.value = '';
    if (val > -1) {
      if (onChange && typeof onChange === 'function') {
        onChange(val);
        forceReset && this.resetInitialState();
      }
    }
  };

  resetInitialState = () => {
    if (this._mounted) {
      this.setState({
        file: null,
        imgSrc: null,
        error: false,
        errMsg: null,
        showDeleteButton: false,
        fileList: [],
        onDrag: false,
      });
    }
  };

  previewImage = file => {
    const {allowedFormat} = this.props;
    if (allowedFormat === 'image' && file && file.attachment) {
      if (this._mounted) {
        this.setState({
          file,
          imgSrc: file.attachment,
          fileList: this.props.initialFileData,
          error: false,
          errMsg: null,
          showDeleteButton: true,
          onDrag: false,
        });
      }
    } else if (file && isFile(file) && allowedFormat === 'video') {
      if (this._mounted) {
        this.setState({
          file,
          imgSrc: URL.createObjectURL(file),
          error: false,
          errMsg: null,
          showDeleteButton: true,
          onDrag: false,
        });
      }
    } else if (file && isFile(file)) {
      const reader = new FileReader();
      reader.onload = () => {
        if (this._mounted) {
          this.setState({
            file,
            imgSrc: reader.result,
            error: false,
            errMsg: null,
            showDeleteButton: true,
            onDrag: false,
          });
        }
      };
      if (reader) reader.readAsDataURL(file);
    } else if (file === null) {
      this.resetInitialState();
    }
  };

  handleFileUpload = (e, crop) => {
    const {onDrag, fileList, cropModal} = this.state;
    !onDrag && e.preventDefault();
    const {
      onChange,
      type,
      multipleUpload,
      livePreview,
      allowedFormat,
      checkSize,
      form,
      nameKey,
    } = this.props;
    const target = !onDrag ? e.target : null;
    let file = !onDrag ? (target && target.files[0] ? target.files[0] : {}) : e;
    if (crop) {
      file = crop.file;
      this.setState({cropModal: false});
      onChange(crop.file, crop.image);
      this.cropFile = null;
      return;
    }
    const imgType = file && file.type;
    if (file && isFile(file)) {
      if (
        checkSize &&
        parseFloat(file.size / (1024 * 1024)).toFixed(2) > checkSize
      ) {
        if (nameKey && form) {
          form.validateFields([nameKey], {
            force: true,
          });
        } else {
          this.setState({
            error: true,
            errMsg: `Could not save company logo, file size exceeds ${checkSize}MB limit`,
          });
        }
        return false;
      }
      if (type === 'imagePreview' && !multipleUpload) {
        if (imgType && imgFormat.includes(imgType)) {
          if (typeof onChange === 'function') {
            onChange(file);
          }
          this.previewImage(file);
        } else {
          this.setState({
            error: true,
            errMsg: 'Only .JPG, .JPEG & .PNG allowed',
          });
        }
      } else if (type === 'file') {
        // check for format.....
        if (allowedFormat === 'image') {
          if (imgType && !imgFormat.includes(imgType)) {
            this.setState({
              error: true,
              errMsg: 'Only .JPG, .JPEG & .PNG allowed',
            });
            return false;
          }
        } else if (allowedFormat === 'video') {
          if (imgType && imgType !== 'video/mp4') {
            this.setState({error: true, errMsg: 'Only .MP4 Video allowed'});
            return false;
          }
        }

        if (multipleUpload) {
          this.setState({
            fileList: fileList && [...fileList].concat(file),
            onDrag: false,
            error: false,
            errMsg: null,
          });
        } else if (!multipleUpload && !livePreview) {
          this.fileDelete(0);
          this.setState(
            {
              fileList: [file],
              onDrag: false,
              error: false,
              errMsg: null,
            },
            () => this.previewImage(file)
          );
        } else {
          this.fileDelete(0);
          this.setState({
            fileList: [file],
            onDrag: false,
            error: false,
            errMsg: null,
          });
        }
        if (onChange && typeof onChange === 'function') {
          onChange(file);
        }
      }
    }
  };

  handleCropUpload = e => {
    const {checkSize} = this.props;
    const {onDrag} = this.state;
    const target = !onDrag ? e.target : null;
    const file = !onDrag ? target.files[0] : e;
    const imgType = file && file.type;
    if (imgType && !imgFormat.includes(imgType)) {
      message.error('Only .JPG, .JPEG & .PNG allowed');
      return false;
    }
    if (
      checkSize &&
      parseFloat(file.size / (1024 * 1024)).toFixed(2) > checkSize
    ) {
      message.error(
        `Could not save banner attachment, file size exceeds ${checkSize}MB limit`
      );
      return false;
    }
    this.cropFile = {
      file,
      event: e,
    };
    this.setState({cropModal: true});
  };

  closeCropModal = () => {
    if (this.imgUpload) this.imgUpload.value = '';
    this.setState({cropModal: false});
  };

  render() {
    const {
      imgSrc,
      error,
      errMsg,
      showDeleteButton,
      fileList,
      cropModal,
    } = this.state;
    const {
      title,
      subTitle,
      cropper,
      type,
      dragImage,
      icon,
      defaultIcon,
      imgSrc: propImage,
      deleteImage,
      livePreview,
      bannerView,
      allowedFormat,
      readOnly,
      alwaysShow,
      setRef,
      enableCloudinaryImageTitle, // use it to parse actual image title from cloudinary url (SRM-4035)
    } = this.props;
    return readOnly ? (
      <div className="uploader">
        <div className="imageDisplay">
          <Avatar
            className="photo-img"
            src={imgSrc || propImage}
            alt="userImage"
            icon={`${defaultIcon || 'picture'}`}
          />
        </div>
      </div>
    ) : (
      <div className="uploader" ref={ref => (this.dropArea = ref)}>
        {type === 'imagePreview' && (
          <div className="imageDisplay">
            <Avatar
              className="photo-img"
              src={imgSrc || propImage}
              alt="userImage"
              icon={`${defaultIcon || 'picture'}`}
            />
            {showDeleteButton && (
              <Icon type="close" onClick={() => this.loadDefaultImage(true)} />
            )}
          </div>
        )}
        {!livePreview && (
          <>
            {propImage && isString(propImage) && !alwaysShow ? (
              <></>
            ) : (
              <div className="image-uploader">
                <input
                  ref={ref => {
                    if (setRef) setRef(ref);
                    this.imgUpload = ref;
                  }}
                  type="file"
                  onChange={
                    cropper ? this.handleCropUpload : this.handleFileUpload
                  }
                />
                <span className="photo-link">
                  {icon}
                  <span className="uploader-text">
                    <span>
                      {title}
                      {
                        <span className="drag-enable">
                          {dragImage && ' or drag file here'}
                        </span>
                      }
                    </span>
                    <span className="text-muted">{subTitle}</span>
                  </span>
                </span>
              </div>
            )}
            {propImage && isString(propImage) && deleteImage && (
              <Icon type="delete" className="del-icon" onClick={deleteImage} />
            )}
          </>
        )}

        {type === 'file' && livePreview && bannerView && (
          <div className="live-preview">
            {fileList &&
              fileList.map((file, idx) => {
                const {attachment} = file;
                return (
                  <Fragment key={idx}>
                    {allowedFormat === 'image' && (
                      <Avatar src={imgSrc || attachment} />
                    )}
                    {allowedFormat === 'video' && (
                      <video height="250" controls>
                        <source src={imgSrc || attachment} type="video/mp4" />
                        <p>
                          Try different browser, Your browser is not supporting
                          video.
                        </p>
                      </video>
                    )}
                    <Icon
                      type="close"
                      onClick={() => this.loadDefaultImage(true)}
                    />
                  </Fragment>
                );
              })}
          </div>
        )}
        {type === 'file' && !bannerView && (
          <div className="file-list">
            {fileList &&
              isArray(fileList) &&
              fileList.map((file, idx) => {
                const {attachment} = file;
                return (
                  <div className="file" key={idx}>
                    {attachment ? (
                      <a
                        href={attachment}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{display: 'inherit'}}>
                        <span className="file-type-icon">
                          <Icon type={fileTypeIcon(attachment)} />
                        </span>
                        <div className="file-txt">
                          <span className="attachment-file-name">
                            {extractFileName(
                              attachment,
                              enableCloudinaryImageTitle
                            )}
                          </span>
                        </div>
                      </a>
                    ) : (
                      <>
                        <span className="file-type-icon">
                          <Icon type={fileTypeIcon(file.type)} />
                        </span>
                        <div className="file-txt">
                          <span className="file-name">{file.name}</span>
                          <span className="file-size">{`${file.size} KB`}</span>
                        </div>
                      </>
                    )}
                    <span className="file-close">
                      <Icon
                        type="close"
                        onClick={() => {
                          this.fileDelete(idx);
                        }}
                      />
                    </span>
                  </div>
                );
              })}
          </div>
        )}
        {error && <div className="image-error">{errMsg}</div>}
        {cropModal && (
          <Cropper
            visible={cropModal}
            onCancel={this.closeCropModal}
            cropFile={this.cropFile.file}
            aspectRatio={3 / 1}
            onCrop={crop => this.handleFileUpload(this.cropFile.event, crop)}
          />
        )}
      </div>
    );
  }
}

FileUploader.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  imgSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
  initialFileData: PropTypes.array,
};

export default FileUploader;
