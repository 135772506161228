import {useDispatch, useSelector} from 'react-redux';
import {authToken} from 'redux/modules/auth';
import {sendMessageOpen} from 'redux/modules/general';

// custom hook to get token and user
export const useAuth = () => {
  const {isOnline, access: {token} = {}, request: isAuthRequest} =
    useSelector(authToken) || {};
  return {token, isOnline, isAuthRequest};
};

export const useEmailSender = () => {
  const dispatch = useDispatch();
  const sendMail = recipients => {
    if (Array.isArray(recipients) && recipients.length > 0) {
      dispatch(sendMessageOpen({open: true, recipients: recipients}));
    }
  };
  return {sendMail};
};
