import React, {Component} from 'react';
import {Form, Row, Col, Select, Tag, Icon} from 'antd';

const {Option} = Select;
const FormItem = Form.Item;
const defaultContentPadding = 42;

class StateSelector extends Component {
  state = {
    selectedStates: [],
  };

  componentDidMount() {
    if (this.props.initialValue) {
      this.setState({
        selectedStates: this.props.initialValue,
      });
    }
  }

  onStateSelect = stateId => {
    const {
      states: {data: stateArray} = {},
      form: {resetFields},
    } = this.props;
    const state = stateArray.filter(st => st.uuid === stateId)[0];
    this.setState(
      {
        selectedStates: [...this.state.selectedStates, state],
      },
      () => {
        resetFields();
        this.onChange();
      }
    );
  };

  onChange = () => {
    const {onChange} = this.props;
    if (onChange) onChange(this.state.selectedStates);
  };

  onStateRemove = uuid => {
    this.setState(
      {
        selectedStates: this.state.selectedStates.filter(
          st => st.uuid !== uuid
        ),
      },
      () => {
        this.onChange();
      }
    );
  };
  isStateSelected = id => {
    const {selectedStates} = this.state;
    const state = selectedStates.filter(state => state.uuid === id);
    if (state.length > 0) return true;
    return false;
  };
  render() {
    const {
      contentPadding,
      selectedPrograms,
      states: {data: stateArray} = {},
      form: {getFieldDecorator},
    } = this.props;
    const {selectedStates} = this.state;
    return (
      <Form layout="inline">
        <div
          style={{
            padding: contentPadding || defaultContentPadding,
          }}
          className="state-selector filter-section">
          <div
            style={{
              marginLeft: contentPadding
                ? -contentPadding
                : -defaultContentPadding,
              marginRight: contentPadding
                ? -contentPadding
                : -defaultContentPadding,
            }}
            className="heading-text text-center">
            Filter by Location
          </div>
          <Row type="flex" gutter={42} className="filter-form" align="middle">
            <Col>
              <FormItem label="Select States">
                {getFieldDecorator('state')(
                  <Select
                    onSelect={this.onStateSelect}
                    showSearch={false}
                    // disabled={disable}]
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select States"
                    style={{minWidth: 200}}>
                    {stateArray &&
                      stateArray.map(({name, uuid}) => (
                        <Option
                          key={uuid}
                          disabled={this.isStateSelected(uuid)}
                          value={uuid}
                          title={name}>
                          {name}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <div
            style={{
              marginLeft: contentPadding
                ? -contentPadding
                : -defaultContentPadding,
              marginRight: contentPadding
                ? -contentPadding
                : -defaultContentPadding,
              paddingLeft: contentPadding
                ? contentPadding
                : defaultContentPadding,
              paddingRight: contentPadding
                ? contentPadding
                : defaultContentPadding,
            }}
            className="selected-section">
            <div className="lbl-s">Selected Location(s):</div>
            <div className="tags-s">
              {selectedStates &&
                selectedStates.map(state => (
                  <Tag
                    key={'pgm-tag' + state.uuid}
                    onClose={() => this.onStateRemove(state.uuid)}
                    closable
                    className="pgm-tag">
                    {state.name}
                  </Tag>
                ))}
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

export default Form.create()(StateSelector);
