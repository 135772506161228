import React, {Component} from 'react';
import {message} from 'antd';

import {
  EditorState,
  convertToRaw,
  getDefaultKeyBinding,
  ContentState,
} from 'draft-js';

import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import ErrorBoundary from '../ErrorBoundary';
import {Counter} from 'core';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.css';

const {error} = message;

let backSpace = false;
class TextEditor extends Component {
  state = {
    text: null,
    textLength: 0,
    editorState: EditorState.createEmpty(),
  };

  componentDidUpdate = ({
    value: prevValue,
    forceRerender: prevForceRerender,
  }) => {
    const {value: newValue, forceRerender, changeRenderStatus} = this.props;
    // NEED TO VERIFY FOR FIRST LOAD:::|| prevUpdateStatus !== newUpdateStatus
    //prevValue === '' &&
    if (prevValue === '' && prevValue !== newValue) {
      this.setInitialValue(newValue);
    }
    if (prevValue !== '' && prevValue !== newValue && newValue === '') {
      this.setInitialValue(newValue, true);
    }
    if (forceRerender && prevForceRerender !== forceRerender) {
      this.setInitialValue(newValue);
      changeRenderStatus(false);
    }
  };

  componentDidMount = () => this.setInitialValue(this.props.value);

  setInitialValue = (value, force = false) => {
    const {maxLen} = this.props;
    const update = value => {
      let editorState = EditorState.createEmpty();
      let plainTxtLen = 0;
      const blocksFromHtml = htmlToDraft(value);
      const {contentBlocks, entityMap} = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      editorState = EditorState.createWithContent(contentState);
      const plainTxt = editorState.getCurrentContent().getPlainText('\u0001');
      plainTxtLen = plainTxt.trim().length;
      this.setState({
        editorState: EditorState.moveFocusToEnd(editorState),
        textLength: plainTxtLen > maxLen ? maxLen : plainTxtLen,
      });
    };
    if (value && value !== '') {
      update(value);
    } else if (force) {
      update(value);
    }
  };

  onEditorStateChange = editorState => {
    const {maxLen, onChange} = this.props;
    const GET_CONTENT = editorState.getCurrentContent();
    const content = draftToHtml(convertToRaw(GET_CONTENT));
    const plainTxt = GET_CONTENT.getPlainText('\u0001');
    const plainTxtLen = plainTxt.trim().length;
    if (!backSpace && maxLen && plainTxtLen > maxLen) {
      error(`Charcter limit exceeds, max ${maxLen} characters allowed.`);
      return;
    }
    this.setState(
      {
        editorState: editorState,
        text: content,
        textLength: plainTxtLen > maxLen ? maxLen : plainTxtLen,
      },
      () => {
        onChange && onChange(plainTxtLen ? content : plainTxt);
        backSpace = false;
      }
    );
  };

  handleBeforeInput = plainTxt => {
    const {maxLen} = this.props;
    const {editorState} = this.state;
    if (maxLen && editorState) {
      if (
        editorState
          .getCurrentContent()
          .getPlainText('\u0001')
          .trim().length >= maxLen
      ) {
        error(`Charcter limit max ${maxLen} characters allowed.`);
        return 'handled';
      }
    }
  };

  keyBindingFn = event => {
    if (event.key === 'Backspace' && event.keyCode == 8) {
      backSpace = true;
    } else {
      backSpace = false;
    }
    return getDefaultKeyBinding(event);
  };

  render() {
    const {editorState, textLength} = this.state;
    const {maxLen, placeholder} = this.props;

    return (
      <div className="custom-editor">
        <ErrorBoundary>
          <Editor
            editorState={editorState}
            wrapperClassName="editor-wrapper"
            editorClassName="editor"
            onEditorStateChange={this.onEditorStateChange}
            handleBeforeInput={this.handleBeforeInput}
            keyBindingFn={this.keyBindingFn}
            placeholder={placeholder}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontFamily',
                'fontSize',
                'list',
                'textAlign',
                'link',
                'image',
                'colorPicker',
                'history',
                'remove',
              ],
              image: {alignmentEnabled: false, previewImage: true},
              list: {options: ['unordered', 'ordered']},
              link: {defaultTargetOption: '_blank'},
            }}
          />
          <Counter current={textLength || 0} total={maxLen} />
        </ErrorBoundary>
      </div>
    );
  }
}

export default TextEditor;
