import {Services} from 'core';
import * as TYPES from './constants';
import {API} from 'config/employer.config';
import {
  requestEmployerEducationLevel,
  receiveEmployerEducationLevel,
  handleEmployerEducationLevelError,
} from './actions';

const initialState = {
  data: null,
  error: null,
  request: null,
};
const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_EMPLOYER_EDUCATION_LEVEL:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_EMPLOYER_EDUCATION_LEVEL:
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload || {},
      };
    case TYPES.EMPLOYER_EDUCATION_LEVEL_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };

    default:
      return state;
  }
};

export const fetchEducationLevel = () => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestEmployerEducationLevel());
  try {
    let endpoint = `${API.administration.education_level}`;
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receiveEmployerEducationLevel(res));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleEmployerEducationLevelError(err));
      });
  } catch (error) {
    console.log(error);
    dispatch(handleEmployerEducationLevelError(error));
  }
};
