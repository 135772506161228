import React, {useState, useCallback} from 'react';
import {Select, Icon, Form} from 'antd';
import uniqBy from 'lodash/uniqBy';
import debounce from 'lodash/debounce';
import {sortArrayOfObjPropertyBased} from 'core';

const {Option} = Select;
const FormItem = Form.Item;

export default function SkillSearch({
  initialValue,
  getFieldDecorator,
  formItemLayout,
  decoratorKey,
  label,
  skillsData,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterSkills, setFilterSkills] = useState([]);

  const search = term => {
    if (skillsData && Array.isArray(skillsData) && skillsData.length) {
      const filteredSkills = skillsData.filter(skill => {
        if (term.length <= 3) {
          if (skill.name.toLowerCase().startsWith(term.toLowerCase())) {
            return skill;
          }
        } else {
          if (skill.name.toLowerCase().includes(term.toLowerCase())) {
            return skill;
          }
        }
      });
      setFilterSkills(
        uniqBy(
          sortArrayOfObjPropertyBased(filteredSkills, 'name').slice(0, 21),
          'name'
        )
      );
      setSearchTerm(term.trim());
    }
  };

  const debouncedSearch = useCallback(
    debounce(search, 800),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <FormItem label={label} colon={false} {...formItemLayout}>
      <div className="clearfix">
        {getFieldDecorator(decoratorKey, {
          rules: [
            {
              required: true,
              message: 'Skills can not be empty',
            },
          ],
          initialValue,
        })(
          <Select
            onSearch={debouncedSearch}
            showArrow={false}
            showSearch
            filterOption={false}
            defaultActiveFirstOption={false}
            allowClear
            placeholder={
              <React.Fragment>
                <Icon type="search" />
                &nbsp; Search
              </React.Fragment>
            }
            notFoundContent={searchTerm ? 'no matches for search query' : ''}>
            {filterSkills &&
              filterSkills.length > 0 &&
              filterSkills.map(({name, uuid}) => (
                <Option key={uuid} value={name} title={name}>
                  {name}
                </Option>
              ))}
          </Select>
        )}
      </div>
    </FormItem>
  );
}
