import React from 'react';
import {Stats as MUIStats} from 'mui-core';
import {StyledContainer, StatsLabel} from './styles';

const Applicants = ({data}) => {
  const prepareApplicantsStatsData = () => {
    if (data) {
      const {
        apprenticeships = 0,
        scholarships = 0,
        internships = 0,
        other = 0,
        jobs = 0,
      } = data;
      const statsData = [
        {
          title: 'Jobs',
          count: jobs,
          color: '#7D19EA',
        },
        {
          title: 'Internships',
          count: internships,
          color: '#2BAD60',
        },
        {
          title: 'Apprenticeships',
          count: apprenticeships,
          color: '#9CA515',
        },
        {
          title: 'Scholarships',
          count: scholarships,
          color: '#A956E3',
        },
        {
          title: 'Other',
          count: other,
          color: '#B23E22',
        },
      ];
      return statsData;
    }
    return [];
  };
  const applicantsData = prepareApplicantsStatsData();
  return (
    <StyledContainer>
      <StatsLabel>Applicants</StatsLabel>
      <MUIStats data={applicantsData} />
    </StyledContainer>
  );
};

export default Applicants;
