import {
  requestSuperUserLicenseTypes,
  receiveSuperUserLicenseTypes,
  handleSuperUserLicenseTypesError,
} from './actions';
import * as TYPES from './constants';
import {Services, isArray} from 'core';
import {API} from 'config/superuser.config';

const initialState = {
  data: null,
  error: null,
  request: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_SUPER_USER_LICENSE_TYPES:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_SUPER_USER_LICENSE_TYPES:
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload,
      };
    case TYPES.SUPER_USER_LICENSE_TYPES_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchSuperUserLicenseTypes = () => (dispatch, getState) => {
  const services = new Services();
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestSuperUserLicenseTypes());
  let endpoint = `${API.administration.institution_type}`;
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        if (
          res?.super_type_info &&
          isArray(res?.super_type_info) &&
          res?.super_type_info.length
        ) {
          return res;
        }
      })
      .then(result => {
        dispatch(receiveSuperUserLicenseTypes(result));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleSuperUserLicenseTypesError(err));
  }
};
