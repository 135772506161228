import React, {useEffect, useState, useMemo} from 'react';
import {Row, Col, Button} from 'antd';
import {PDFDownloadLink, PDFViewer} from '@react-pdf/renderer';
import {useParams} from 'react-router-dom';
import {queryStringParse, isEmpty} from 'core';

import {API as SRM_API} from 'config/srm.config';

import ProfileHeader from './ProfileHeader';
// import ProfileStatus from './ProfileStatus';
import Bio from './Bio';
// import EducationalPathways from './EducationalPathways';
import Skills from './Skills';
import WorkExperience from './WorkExperience';
import Awards from './Awards';
import DocumentUploaded from './DocumentUploaded';
// import OverviewPanel from './OverviewPanel';
// import SkillsPanel from './SkillsPanel';
import DynamicHeader from './../../../core/hoc/DynamicHeader';
import OverviewInfo from './OverviewInfo';
import MyEducation from './MyEducation';
import Course from './Course';
import LicenseAndCertifications from './LicenseAndCertifications';
import VolunteerExperience from './VolunteerExperience';
import Interests from './Interests';
import Portfolio from './Portfolio';
// import SkillsLibrary from './SkillsLibrary';
import {MyProfileDocument} from './../../../core/components';

import * as SkillService from './../../../core/skills-service';

import {Services, ProfileServices} from './../../../core/services';
import {monthNames} from 'config/businesspartner.config';
import './style.css';

const services = new Services();

function StudentProfile({
  fetchStudentProfile,
  clearStudentProfile,
  studentProfileData: {data: student_Info},
  studentProfile: {data: student_profile},
  studentAwardTypes: {data: awardTypes},
  isFromWorkforce,
  fetchSkillsStatus,
  fetchSkillsData,
  achievementOptions,
  skills,
  studentSkills,
  token,
  history,
  history: {
    location: {search},
  },
  studentID,
  hideSendMsg,
  type,
}) {
  const [ready, setReady] = useState(false);
  const [pdfStudentProfile, setPdfStudentProfile] = useState(null);
  const [pdfActivePath, setPdfActivePath] = useState(null);
  let {cognito_username} = queryStringParse(search);
  const active_path_details =
    (student_profile &&
      student_profile.active_path &&
      Array.isArray(student_profile.active_path) &&
      student_profile.active_path.length > 0 &&
      student_profile.active_path[0]) ||
    {};
  const student_information =
    (student_Info &&
      Array.isArray(student_Info) &&
      student_Info.length > 0 &&
      student_Info[0]) ||
    {};
  const {studentid} = useParams();

  const profileServices = new ProfileServices(
    token,
    null,
    null,
    history,
    'srm'
  );

  const [chartData, setChartData] = useState([]);
  const [chartSelectedIndex, setChartSelectedIndex] = useState(0);
  const [skillsData, setSkills] = useState(null);
  let formattedSkillsData = [];
  let tempChartData = [];

  useEffect(
    () => {
      setPdfStudentProfile(student_information);
      setPdfActivePath(active_path_details);
      if (
        (Object.keys(student_information).length >= 0 &&
          Object.keys(active_path_details).length >= 0 &&
          awardTypes !== null) ||
        (awardTypes !== null && ready !== true)
      ) {
        setReady(true);
      }
    },
    [student_Info, student_profile, awardTypes]
  );

  useEffect(() => {
    fetchStudentProfile(cognito_username);
    // My Employability Skills is not yet developed
    // setChartData([]);
    // initSkillsDataforChart();
    return () => clearStudentProfile();
  }, []);

  const initSkillsDataforChart = async () => {
    if (!skills.data) await fetchSkillsData();
    fetchSkillsStatus(studentid);
  };

  useEffect(
    () => {
      if (
        studentSkills.data &&
        studentSkills.data.student_response &&
        skills.data
      ) {
        constructDataArrayForRadarChart(skills.data);
      } else {
        setChartData([]);
      }
    },
    [studentSkills]
  );

  const getAwardType = id => {
    let value = id;
    if (Array.isArray(achievementOptions)) {
      const d = achievementOptions.filter(i => i.uuid === id)[0];
      if (d) {
        value = d.options;
      }
    }
    return value;
  };

  const onSendMsg = () => {
    const {user_profile_details = {}} = student_profile;
    const uri = `${SRM_API.institution.message_staff}`;
    const studentObj = {};
    if (student_profile) {
      studentObj[student_profile.student_uuid] = student_profile;
    }
    profileServices.sendMessage(studentObj, uri, {}, 'student_uuid');
  };

  const constructDataArrayForRadarChart = skills => {
    const chartData = SkillService.createRadarChartData(skills);
    tempChartData = chartData;
    findCurrentSkillsStatus(studentSkills.data);
  };

  const findCurrentSkillsStatus = skillsStatus => {
    const formattedSkillStatus = SkillService.findCurrentSkillsStatus(
      skillsStatus.student_response || skills.data,
      skillsStatus
    );
    if (formattedSkillStatus) {
      constructSkillsArray(formattedSkillStatus);
    } else {
      constructSkillsArray(skills.data);
    }
  };

  const changeSectionInSpiderChart = (index, isBack, isLast) => {
    const tempData = [...tempChartData];
    if (!tempData[index]) return;
    if (index !== 0) {
      tempData[index - 1]['status'] = 'completed';
      tempData[index - 1]['count'] = formattedSkillsData[index - 1].value;
    }
    if (isLast) {
      tempData[index]['status'] = 'completed';
      tempData[index]['icon'] =
        SkillService.RadarCharticons[tempData[index]['iconName']].white;
      tempData[index]['count'] = formattedSkillsData[index].value;
      return;
    }
    tempData[index]['status'] = 'progress';
    tempData[index]['icon'] =
      SkillService.RadarCharticons[tempData[index]['iconName']].white;
    setChartData([...tempData]);
  };

  const consolidateValuesForSpiderChart = (subSkills, index, isLast) => {
    const skills = SkillService.consolidateValuesForSpiderChart(
      subSkills,
      formattedSkillsData,
      index
    );
    formattedSkillsData = skills;
    const _index = isLast ? index : index + 1;
    changeSectionInSpiderChart(_index, false, isLast);
  };

  const constructSkillsArray = skills => {
    const formattedSkills = SkillService.constructSkillsArray(
      skills,
      studentSkills.data
    );
    formattedSkillsData = formattedSkills.skills;
    setSkills(formattedSkillsData);
    if (
      formattedSkills &&
      formattedSkills.skills &&
      formattedSkills.skills.length > 0
    ) {
      formattedSkills.skills.forEach((skill, index) => {
        if (index < formattedSkills.menuIndex) {
          consolidateValuesForSpiderChart(skill.subSkills, index);
        }
      });
      if (
        formattedSkills &&
        formattedSkills.skills[formattedSkills.skills.length - 1].value !==
          Infinity
      ) {
        consolidateValuesForSpiderChart(
          formattedSkills.skills[formattedSkills.skills.length - 1].subSkills,
          formattedSkills.skills.length - 1,
          true
        );
      }
    }
  };

  const onChartItemClick = item => {
    setChartSelectedIndex(item.index);
  };
  // const renderDownload = useMemo(
  //   () => {
  //     return (
  //       // (pdfStudentProfile &&
  //       //   pdfActivePath &&
  //       //   awardTypes &&
  //       //   !isEmpty(pdfStudentProfile) &&
  //       //   !isEmpty(pdfActivePath)) ||
  //       // (isEmpty(pdfActivePath) &&
  //       //   Array.isArray(awardTypes) &&
  //       //   awardTypes.length > 0 && (
  //       <>
  //         {ready && (
  //           <PDFDownloadLink
  //             document={
  //               <MyProfileDocument
  //                 studentProfile={pdfStudentProfile || {}}
  //                 pathInfo={pdfActivePath || {}}
  //                 awardTypes={awardTypes || []}
  //               />
  //             }
  //             fileName="my_profile.pdf">
  //             {({loading}) => (
  //               <Button className="profile-download-btn">
  //                 {loading ? 'LOADING' : 'DOWNLOAD'}
  //               </Button>
  //             )}
  //           </PDFDownloadLink>
  //         )}
  //         {/* For development use only */}
  //         {/* {ready && (
  //             <PDFViewer width="100%" height="700vh">
  //               <MyProfileDocument
  //                 studentProfile={pdfStudentProfile}
  //                 pathInfo={pdfActivePath}
  //                 awardTypes={awardTypes}
  //               />
  //             </PDFViewer>
  //           )} */}
  //       </>
  //       // ))
  //     );
  //   },
  //   [ready]
  // );

  const resultEducationInfo =
    student_profile.student_credentials &&
    Array.isArray(student_profile.student_credentials) &&
    student_profile.student_credentials.filter(element => {
      return element.credential_type === 'education';
    });

  const resultCertificationInfo =
    student_profile.student_credentials &&
    Array.isArray(student_profile.student_credentials) &&
    student_profile.student_credentials.filter(element => {
      return element.credential_type === 'certification';
    });

  const resultCourseInfo =
    student_profile.student_credentials &&
    Array.isArray(student_profile.student_credentials) &&
    student_profile.student_credentials.filter(element => {
      return element.credential_type === 'course';
    });

  return (
    <>
      <ProfileHeader
        data={student_profile}
        collegeData={active_path_details}
        hideSendMsg={hideSendMsg}
        onSendMsg={onSendMsg}
      />
      <div className="clearfix column-gap">
        <div className="ProfileContent Profile-MyProfile">
          <Row type="flex" gutter={40}>
            {/* <Col md={7}>
              <OverviewPanel
                student_profile={student_profile || {}}
                student_Info={student_information || {}}
                activePath={active_path_details || {}}
                isFromWorkforce={isFromWorkforce}
                type={type}
              /> */}
            {/* <SkillsPanel
                  marginTop={30}
                  loading={studentSkills.request}
                  isFromWorkforce={isFromWorkforce}
                  skills={skillsData}
                  chartData={chartData}
                  infoIndex={chartSelectedIndex}
                  chartSetting={{
                    ...SkillService.chartDefaultSettings.props,
                    onClick: onChartItemClick,
                  }}
                /> */}
            {/* </Col> */}
            <Col md={24}>
              <div className="ProfileContent">
                {/* <ProfileStatus data={student_information} type={type} /> */}
                <div className="profileUserInfo">
                  <OverviewInfo student_profile={student_profile || {}} />
                  <div className="downloadContainer">
                    {ready && (
                      <PDFDownloadLink
                        document={
                          <MyProfileDocument
                            studentProfile={pdfStudentProfile || {}}
                            pathInfo={pdfActivePath || {}}
                            awardTypes={awardTypes || []}
                          />
                        }
                        fileName="my_profile.pdf">
                        {({loading}) => (
                          <>
                            <Button className="profile-download-btn">
                              {loading ? 'LOADING' : 'DOWNLOAD'}
                            </Button>
                          </>
                        )}
                      </PDFDownloadLink>
                    )}
                  </div>
                </div>

                {student_information &&
                  student_information.bio &&
                  student_information.bio && <Bio data={student_information} />}

                {resultEducationInfo &&
                  Array.isArray(resultEducationInfo) &&
                  resultEducationInfo.length > 0 && (
                    <MyEducation
                      resultEducationInfo={resultEducationInfo || []}
                      getAwardType={getAwardType}
                    />
                  )}

                {resultCertificationInfo &&
                  Array.isArray(resultCertificationInfo) &&
                  resultCertificationInfo.length > 0 && (
                    <LicenseAndCertifications
                      resultCertificationInfo={resultCertificationInfo || []}
                      getAwardType={getAwardType}
                      SettingsMonths={monthNames}
                    />
                  )}

                {resultCourseInfo &&
                  Array.isArray(resultCourseInfo) &&
                  resultCourseInfo.length > 0 && (
                    <Course
                      resultCourseInfo={resultCourseInfo || []}
                      getAwardType={getAwardType}
                      SettingsMonths={monthNames}
                    />
                  )}

                {student_information.skills &&
                  Array.isArray(student_information.skills) &&
                  student_information.skills.length > 0 && (
                    <Skills data={student_information} />
                  )}

                {student_information &&
                  student_information.work_exp &&
                  Array.isArray(student_information.work_exp) &&
                  student_information.work_exp.length > 0 && (
                    <WorkExperience data={student_information} />
                  )}

                {/* <SkillsLibrary
                  activePathsData={student_profile.active_path || []}
                  credentialsInfo={student_profile.student_credentials || []}
                  getAwardType={getAwardType}
                /> */}
                {student_information &&
                  student_information.voluntary_exp &&
                  Array.isArray(student_information.voluntary_exp) &&
                  student_information.voluntary_exp.length > 0 && (
                    <VolunteerExperience student_Info={student_information} />
                  )}

                {student_information &&
                  student_information.documents &&
                  Array.isArray(student_information.documents) &&
                  student_information.documents.length > 0 && (
                    <Portfolio student_Info={student_information} />
                  )}

                {student_information &&
                  student_information.documents &&
                  Array.isArray(student_information.documents) &&
                  student_information.documents.length > 0 && (
                    <DocumentUploaded
                      title="Transcript"
                      data={student_information}
                    />
                  )}

                {student_information &&
                  student_information.documents &&
                  Array.isArray(student_information.documents) &&
                  student_information.documents.length > 0 && (
                    <DocumentUploaded
                      title="Resume, Cover Letter & Related Documents"
                      data={student_information}
                    />
                  )}

                {student_information &&
                  student_information.awards_leadership_projects_publications &&
                  Array.isArray(
                    student_information.awards_leadership_projects_publications
                  ) &&
                  student_information.awards_leadership_projects_publications
                    .length > 0 && (
                    <Awards
                      data={student_information}
                      awardTypes={awardTypes}
                    />
                  )}

                {student_information.interest &&
                  Array.isArray(student_information.interest) &&
                  student_information.interest.length > 0 && (
                    <Interests interest={student_information.interest} />
                  )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default DynamicHeader(StudentProfile);
