export const EMPLOYER_ROUTES = {
  HOME: {
    DASHBOARD: '/',
    OLD_DASHBOARD: '/dashboard',
  },
  JOBS: {
    VIEW_JOB: '/job',
    JOBS_LIST: '/jobs',
    EDIT_JOB: '/job/edit',
    CREATE_JOB: '/job/create',
  },
  APPRENTICESHIPS: {
    VIEW_APPRENTICESHIP: '/apprenticeship',
    APPRENTICESHIPS_LIST: '/apprenticeships',
    EDIT_APPRENTICESHIP: '/apprenticeship/edit',
    CREATE_APPRENTICESHIP: '/apprenticeship/create',
  },
  TALENT_EXCHANGE: {
    STUDENTS_LIST: '/talent-exchange',
  },
  TALENT_HUB: {
    TALENT_EXCHANGE: '/talent-hub/talentExchange',
    EMERGING_TALENT: '/talent-hub/emergingTalent',
    PROGRAM_ENROLLMENTS: '/talent-hub/emergingTalent/enrollments',
  },
  ADMIN: {
    ADD_USER: '/adduser',
    USER_MANAGEMENT: '/user/management',
  },
  COMPANY_SETTINGS: {
    SETTINGS: '/company-settings',
  },
  SEND: {
    EMAIL: '/send/message',
  },
  // ANT Routes
  OPPORTUNITIES: {
    ADD_OPPORTUNITY: '/add/opportunity',
    OPPORTUNITIES_LIST: '/opportunities',
    OPPORTUNITY_DETAILS: '/opportunity',
  },
  EVENTS: {
    EVENTS_LIST: '/events',
    ADD_EVENT: '/addevent',
    EDIT_EVENT: '/editevent',
  },
  WORKFORCE: {
    STUDENT_PROFILE: '/workforce/student-profile',
    STUDENT_EXCHANGE: '/workforce/student-exchange',
  },
  EMPLOYER_PROFILE: {
    CREATE_PROFILE: '/employer-profile',
  },
  SEND: {
    EMAIL: '/send/message',
  },
};
