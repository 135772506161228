import Applicants from './Applicants';
import Posting from './Posting';
import TalentExchange from './TalentExchange';
import UserMetrics from './UserMetrics';
import BusinessPartners from './BusinessPartners';
import ProgramInsights from './ProgramInsights';
import ProgramActivity from './ProgramActivity';

const DashBoard = {
  Posting,
  Applicants,
  TalentExchange,
  UserMetrics,
  BusinessPartners,
  ProgramInsights,
  ProgramActivity,
};
export {DashBoard};
