import {SUPER_USER_ROUTES} from './routes';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

const SRM = process.env.REACT_APP_SRM;

export const API = {
  auth: {
    token: `${SRM}super-user/token/`,
    refresh_token: `${SRM}super-user/refresh-token/`,
  },
  administration: {
    //license: `${SRM}super-user/institute/`,
    //institute_type: `${SRM}institute_type/?is_licenseable=true`,
    institutions_list: `${SRM}super-user/institution-list/`,
    institution: `${SRM}v1/super-user/institute/`,
    institution_type: `${SRM}institute_super_type/`,
    institution_sub_type: `${SRM}institute_type/`,
    institution_link: `${SRM}org_state_list/?college_scorecard=true`,
    institution_timeline: `${SRM}v1/super-user/institutions-timeline/`,
    institution_users: `${SRM}v1/super-user/institute-users/`,
    institution_update_users: `${SRM}v1/super-user/update-institute-user/`,
    institution_add_new_user: `${SRM}v1/super-user/add-institute-user/`,
    check_institute_user: `${SRM}v1/super-user/check-institute-user/`,
    user_resend_temp_password: `${SRM}user_resend_temp_password`,
    update_user_status: `${SRM}v1/super-user/update-user-status/`,
    remove_child_institution: `${SRM}v1/super-user/remove-child-institution/`,
    delete_institute_user: `${SRM}v1/super-user/delete-institute-user/`,
    partners: `${SRM}requested_institute`,
    sendMail: `${SRM}message_staff/`,
    user: `${SRM}user/`,
    banner_management: `${SRM}banner_management/`,
    msa_locations: `${SRM}gps/location_details`,
    partner_list: `${SRM}partner-list`,
    company_management: `${SRM}company_management`,
  },
};

export const defautHomePage = '/';

export const noOfAdminCardInPage = 12;

export const instituteData = [
  {
    institution_type_id: '67d10dca-0a76-4941-921d-4b3d3456c603',
    name: 'College District',
  },
  {
    institution_type_id: 'ac6a3ca0-e8ad-4e93-96de-b84b3e3690ef',
    name: 'College',
  },
];

export const eventAlert = {
  'Same Time': '0',
  '5 Minutes Before': '-300',
  '10 Minutes Before': '-600',
  '15 Minutes Before': '-900',
  '30 Minutes Before': '-1800',
  '1 Hours Before': '-3600',
  '2 Hours Before': '-7200',
  '1 Day Before': '-86400',
  '2 Day Before': '-172800',
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const notificationSettingsData = [
  {
    title: 'New Event',
    data: [
      {
        label: 'From Community',
        key: 'alert_from_community',
      },
    ],
  },
  {
    title: 'Event Alerts',
    data: [
      {
        label: 'From Me',
        key: 'alert_from_me',
      },
      {
        label: 'From Community',
        key: 'new_from_community',
      },
    ],
  },
  {
    title: 'Event Responses',
    data: [
      {
        label: 'Survey Responses',
        key: 'response_from_survey',
      },
    ],
  },
];

export const profileMenu = [
  {
    title: 'About Us',
    key: 'about_us',
  },
  {
    title: 'Log Out',
    key: 'log_out',
  },
];

export const sideBarMenu = [
  {
    title: 'Home',
    Icon: HomeIcon,
    key: 'Home',
    path: SUPER_USER_ROUTES.HOME.DASHBOARD,
    // submenus: [
    //   {
    //     title: 'Dahboard',
    //     Icon: HomeIcon,
    //     key: 'Home',
    //     path: '/asd',
    //   },
    // ],
  },
  {
    title: 'Super User Management',
    Icon: AccountCircleIcon,
    key: 'Partners',
    path: SUPER_USER_ROUTES.ADMIN.PARTNER_MANAGEMENT,
  },
  {
    title: 'Banner Management',
    Icon: PhotoLibraryIcon,
    key: 'Banner_Management',
    path: SUPER_USER_ROUTES.BANNER_MANAGEMENT.HOME,
  },
  {
    title: 'License Management',
    Icon: ViewModuleIcon,
    key: 'license',
    path: SUPER_USER_ROUTES.LICENSE_MANAGEMENT.DASHBOARD,
  },
];

export const homePageMenu = [
  {
    title: 'Home',
    imgSrc: 'icon-Home icon',
    key: 'Home',
    mainMenu: true,
    path: SUPER_USER_ROUTES.HOME.DASHBOARD,
  },
  {
    title: 'Administration',
    imgSrc: 'icon-Profile icon',
    key: 'Administration',
    content: [
      // {
      //   submenuTitle: 'License Management',
      //   path: SUPER_USER_ROUTES.ADMIN.LICENSE_MANAGEMENT,
      // },
      // {
      //   submenuTitle: 'Institutions',
      //   path: SUPER_USER_ROUTES.ADMIN.INSTITUTIONS,
      // },
    ],
  },
  {
    title: 'Partners',
    imgSrc: 'icon-partners icon',
    key: 'Partners',
    content: [
      {
        submenuTitle: 'Requests & Approvals',
        path: SUPER_USER_ROUTES.ADMIN.PARTNER_MANAGEMENT,
      },
      {
        submenuTitle: 'License Management',
        path: SUPER_USER_ROUTES.ADMIN.LICENSE_MANAGEMENT,
      },
    ],
  },
  {
    title: 'Banner Management',
    imgSrc: 'icon-partners icon',
    key: 'Banner_Management',
    mainMenu: true,
    path: SUPER_USER_ROUTES.BANNER_MANAGEMENT.HOME,
  },
  // {
  //   title: 'Institutions',
  //   imgSrc: 'icon-Home icon',
  //   key: 'institutions',
  //   mainMenu: true,
  //   path: SUPER_USER_ROUTES.ADMIN.INSTITUTIONS,
  // },
];
