export const getInstitutionsSelector = state =>
  state &&
  state.consortiumAppReducer &&
  state.consortiumAppReducer.institutions;

export const getConsortiumUserProfileSelector = state =>
  state && state.consortiumAppReducer && state.consortiumAppReducer.profile;

export const getInstitutionTypesSelector = state =>
  state &&
  state.consortiumAppReducer &&
  state.consortiumAppReducer.institutionTypes;

export const getInstituteDetailsSelector = state =>
  state &&
  state.consortiumAppReducer &&
  state.consortiumAppReducer.instituteDetails;

export const getConsortiumUUID = state => {
  const {
    user,
    auth: {loggedinUserId},
  } = state || {};
  const usr = (loggedinUserId && user ? user[loggedinUserId] : null) || {};
  return usr ? usr.institution_uuid : null;
};
