import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import * as TYPES from './constants';
import {
  requestStudentWorkforce,
  receiveStudentWorkforce,
  handleStudentWorkforceError,
} from './actions';
const initialState = {
  request: null,
  data: {},
  error: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_STUDENT_WORKFORCE:
      return {
        ...state,
        request: true,
      };

    case TYPES.RECEIVE_STUDENT_WORKFORCE:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };

    case TYPES.STUDENT_WORKFORCE_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchStudentWorkforce = student_uuid => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestStudentWorkforce());
  try {
    const promises = {
      // assessments: fetch(
      //   `${API.student.student_assessment}?student_uuid=${student_uuid}`,
      //   config
      // ),
      appliedPrograms: fetch(
        `${
          API.student.programs
        }?action_type=apply&student_uuid=${student_uuid}`,
        config
      ),
      savedPrograms: fetch(
        `${API.student.programs}?action_type=save&student_uuid=${student_uuid}`,
        config
      ),
      // appliedJobs: fetch(
      //   `${API.student.jobs}?action_type=apply&student_uuid=${student_uuid}`,
      //   config
      // ),
      // savedJobs: fetch(
      //   `${API.student.jobs}?action_type=save&student_uuid=${student_uuid}`,
      //   config
      // ),
      subscribed: fetch(
        `${API.student.subscriptions}?student_uuid=${student_uuid}`,
        config
      ),
      studentSurvey: fetch(
        `${API.student.student_survey}?student_uuid=${student_uuid}`,
        config
      ),
      studentCIS: fetch(
        `${API.student.student_cis}?stu_id=${student_uuid}`,
        config
      ),
      appliedOpportunities: fetch(
        `${
          API.student.student_opportunity
        }?action_type=apply&stu_id=${student_uuid}`,
        config
      ),
      savedOpportunities: fetch(
        `${
          API.student.student_opportunity
        }?action_type=save&stu_id=${student_uuid}`,
        config
      ),
    };
    const entries = Object.entries(promises);
    Promise.all(entries.map(e => e[1]))
      .then(async res => {
        const resp = {};
        for (let i = 0; i < res.length; i++) {
          const name = entries[i][0];
          const a = await res[i].json();
          resp[name] = a;
        }
        return resp;
      })
      .then(response => {
        dispatch(receiveStudentWorkforce(response));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleStudentWorkforceError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleStudentWorkforceError(err));
  }
};
