import * as TYPES from './constants';
import {API, JWT_HEADER} from 'config/srm.config';

import {receiveInfoRequestTypes} from './actions';

const initialState = {
  request: false,
  error: false,
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_INFO_REQUEST_TYPE:
      return {
        request: true,
        error: false,
      };
    case TYPES.RECEIVE_INFO_REQUEST_TYPE:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.REQUEST_INFO_REQUEST_TYPE_ERROR:
      return {
        request: false,
        error: true,
      };

    default:
      return state;
  }
};

export const inforequesttype = token => ({
  type: TYPES.APIREQUEST,
  payload: {
    endpoint: `${API.outreach.request_type}`,
    method: 'GET',
    headers: JWT_HEADER(token),
    onsuccess: receiveInfoRequestTypes,
  },
});
