import React from 'react';
import {Form, Row, Col, Select, Divider, Icon, Tag} from 'antd';
import './style.css';
const FormItem = Form.Item;
const {Option} = Select;
const defaultContentPadding = 42;

class OccupationSelector extends React.Component {
  state = {
    selectedCluster: null,
    filteredOccupations: [],
    selectedPrograms: [],
    searchTerm: null,
  };
  async componentDidMount() {
    await this.props.fetchOccupations();
    this.setInitialValue();
  }
  setInitialValue = () => {
    if (this.props.initialValue) {
      this.setState({
        selectedPrograms: this.props.initialValue,
      });
    }
  };
  onClusterSelect = clusterId => {
    const {clusters} = this.props;
    this.setState({
      selectedCluster:
        clusters && clusters.filter(cluster => cluster.uuid === clusterId)[0],
    });
  };
  componentDidUpdate(preProps) {
    if (this.props.allPrograms !== preProps.allPrograms) {
      // this.onProgramSearch('');
    }
    if (this.props.initialValue !== preProps.initialValue) {
      this.setInitialValue();
    }
  }
  onProgramSelect = programId => {
    const {
      form: {resetFields},
      occupations,
    } = this.props;
    let searchData = [];
    const {data: occupationsData = []} = occupations || {};
    searchData = [...occupationsData];
    const occupationName = searchData.filter(
      occupation => occupation.occupation_uuid === programId
    )[0]['occupation_name'];
    this.setState(
      {
        selectedPrograms: [
          ...this.state.selectedPrograms,
          {name: occupationName, uuid: programId},
        ],
      },
      () => {
        this.onProgramChange();
        resetFields();
        this.onClusterSelect(null);
        // this.onProgramSearch('');
      }
    );
  };
  isProgramSelected = pgmId => {
    const {selectedPrograms} = this.state;
    const pgm = selectedPrograms.filter(program => program.uuid === pgmId);
    if (pgm.length > 0) return true;
    return false;
  };
  onProgramSearch = keyword => {
    const {occupations} = this.props;
    let filteredOccupations =
      occupations.data &&
      Array.isArray(occupations.data) &&
      occupations.data.filter(occ =>
        occ.occupation_name.toLowerCase().includes(keyword.toLowerCase())
      );
    if (!keyword) filteredOccupations = [];
    this.setState({filteredOccupations, searchTerm: keyword});
  };
  onProgramRemove = uuid => {
    this.setState(
      {
        selectedPrograms: this.state.selectedPrograms.filter(
          pgm => pgm.uuid !== uuid
        ),
      },
      () => {
        this.onProgramChange();
      }
    );
  };
  onProgramChange = () => {
    const {onChange, form, parentForm, noForm} = this.props;
    const {selectedPrograms, selectedCluster} = this.state;
    const resetFields = noForm
      ? parentForm
        ? parentForm.resetFields
        : null
      : form
      ? form.resetFields
      : null;
    if (resetFields) resetFields();
    if (onChange) {
      onChange(selectedPrograms);
    }
    if (this.props.onSelectProgram) {
      this.props.onSelectProgram(selectedCluster, selectedPrograms);
    }
  };
  render() {
    const {
      selectedCluster,
      filteredOccupations,
      selectedPrograms,
      searchTerm,
    } = this.state;
    const {
      clusters,
      allPrograms,
      contentPadding,
      singleSelect,
      hideTitle,
      noForm,
      showErrors,
      parentForm,
      required,
      form,
      // occupations,
    } = this.props;
    const decorator = form && form.getFieldDecorator;
    const parentDecorator = parentForm && parentForm.getFieldDecorator;

    const getFieldDecorator = noForm ? parentDecorator : decorator;
    const options =
      filteredOccupations &&
      filteredOccupations.length > 0 &&
      filteredOccupations.map((d, idx) => {
        if (searchTerm !== '' && searchTerm && idx < 25) {
          return (
            <Option
              key={d.occupation_uuid}
              value={d.occupation_uuid}
              disabled={this.isProgramSelected(d.occupation_uuid)}>
              {d.occupation_name}
            </Option>
          );
        }
      });
    const content = () => (
      <div
        style={{
          padding: contentPadding || defaultContentPadding,
        }}
        className="program-study-selector filter-section">
        {!hideTitle && (
          <div
            style={{
              marginLeft: contentPadding
                ? -contentPadding
                : -defaultContentPadding,
              marginRight: contentPadding
                ? -contentPadding
                : -defaultContentPadding,
            }}
            className="heading-text text-center">
            Filter by Occupation
          </div>
        )}
        <Row type="flex" gutter={42} align="middle">
          <Col sm={20} className="searchOccupation" style={{margin: '0 auto'}}>
            <FormItem label="Search by Occupation">
              <>
                <Icon
                  type="search"
                  style={{
                    position: 'absolute',
                    zIndex: '1',
                    top: '4px',
                    left: '5px',
                  }}
                />
                {getFieldDecorator('search')(
                  <Select
                    showSearch
                    showArrow={false}
                    filterOption={false}
                    defaultActiveFirstOption={false}
                    allowClear
                    onSelect={this.onProgramSelect}
                    onSearch={this.onProgramSearch}
                    placeholder="Search Occupation"
                    notFoundContent={
                      searchTerm ? 'no occupations matchs for search query' : ''
                    }
                    style={{paddingLeft: '11px'}}>
                    {options}
                  </Select>
                )}
              </>
            </FormItem>
          </Col>
        </Row>
        <div
          style={{
            marginLeft: contentPadding
              ? -contentPadding
              : -defaultContentPadding,
            marginRight: contentPadding
              ? -contentPadding
              : -defaultContentPadding,
            paddingLeft: contentPadding
              ? contentPadding
              : defaultContentPadding,
            paddingRight: contentPadding
              ? contentPadding
              : defaultContentPadding,
          }}
          className="selected-section">
          <div className="lbl-s">
            {required && <span className="required">* </span>}
            Selected Occupation (s):
          </div>
          <div className="tags-s">
            {(selectedPrograms &&
              Array.isArray(selectedPrograms) &&
              selectedPrograms.length > 0 &&
              selectedPrograms.map((program, index) => (
                <Tag
                  key={'pgm-tag' + program.uuid}
                  onClose={() => this.onProgramRemove(program.uuid)}
                  closable
                  className="occ-tag">
                  {program.name}
                </Tag>
              ))) || (
              <span
                style={{
                  position: 'relative',
                  top: '3px',
                }}>
                -
              </span>
            )}
            {showErrors && selectedPrograms.length === 0 && (
              <FormItem>
                {getFieldDecorator('ss', {
                  rules: [
                    {required: true, message: 'Please select the occupation'},
                  ],
                })(<div style={{paddingBottom: 7}} />)}
              </FormItem>
            )}
          </div>
        </div>
      </div>
    );
    return noForm ? content() : <Form>{content()}</Form>;
  }
}
export default Form.create({
  name: 'OccupationSelectorForm',
})(OccupationSelector);
