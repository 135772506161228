import React from 'react';
import {Tag} from 'antd';

class Select extends React.Component {
  state = {
    optionsNodes: [],
    selected: '',
    isFocus: false,
    searchTerm: '',
  };
  selectedOptions = [];
  componentDidMount() {
    window.addEventListener('click', this.onWindowClick);
    this.initializeOptions();
    this.initialize();
  }
  initializeOptions = () => {
    if (this.props.children) {
      this.props.children.forEach(c => {
        let idx = null;
        const exiOpt = this.selectedOptions.filter((i, index) => {
          if (i.value === c.key) {
            idx = index;
            return true;
          } else {
            return false;
          }
        });
        if (!exiOpt[0]) {
          this.selectedOptions.push({
            value: c.props.value,
            children: c.props.renderInSelect
              ? c.props.renderInSelect
              : c.props.children,
          });
          this.forceUpdate();
        }
        if (exiOpt[0] && exiOpt[0].children === exiOpt[0].value) {
          this.selectedOptions[idx] = {
            value: c.props.value,
            children: c.props.renderInSelect
              ? c.props.renderInSelect
              : c.props.children,
          };
          this.forceUpdate();
        }
      });
    }
  };
  initialize = () => {
    const {value, initValue} = this.props;
    const state = {};
    if (value) {
      state.selected = value;
    }
    // if (initValue) {
    //   state.selected = initValue;
    // }
    this.setState({...this.state, ...state});
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevProps.value) {
      this.setState({selected: this.props.value});
    }
    // if (this.state.selected !== prevState.selected) {
    //   this.onChange();
    // }
    if (
      this.props.isNewItemsInserted &&
      this.props.children !== prevProps.children
    ) {
      this.initializeOptions();
    }
  }
  onChange = () => {
    const {onChange} = this.props;
    if (onChange) onChange(this.state.selected);
  };
  onWindowClick = () => {
    if (this.state.isFocus) {
      this.setState({isFocus: false, searchTerm: ''}, () => {
        if (this.props.onBlur) this.props.onBlur();
      });
    }
  };
  onSelect = props => {
    const {onChange} = this.props;
    const {disabled, value} = props;
    const exiOpt = this.selectedOptions.filter(i => i.value === value);
    if (!exiOpt[0])
      this.selectedOptions.push({
        ...props,
        children: props.renderInSelect ? props.renderInSelect : props.children,
      });
    const {multiple, onSelect} = this.props;
    const {selected} = this.state;
    if (disabled) return;
    let selectedValues = '';
    if (multiple) {
      if (Array.isArray(selected)) {
        if (selected.indexOf(value) === -1) {
          selectedValues = [...selected, value];
        } else {
          selectedValues = selected.filter(i => i !== value);
        }
      } else {
        selectedValues = [value];
      }
    } else {
      selectedValues = value;
    }
    if (onSelect) onSelect(selected);
    this.setState(
      {selected: selectedValues, isFocus: false, searchTerm: ''},
      () => {
        if (this.props.onBlur) this.props.onBlur();
        onChange(selectedValues);
      }
    );
  };
  onFocus = () => {
    this.setState({isFocus: true});
  };
  onSearch = e => {
    const {onSearch} = this.props;
    if (onSearch) onSearch(e.target.value);
    this.setState({searchTerm: e.target.value});
  };
  contentClick = e => {
    e.stopPropagation();
  };
  getGroupingOptions = options => {
    const {groupLabels} = this.props;
    let data = {};
    const addItem = (type = 'others', item) => {
      data[type] = {
        title: type,
        data: [item],
      };
    };
    if (options && Array.isArray(options)) {
      options.forEach(dt => {
        const opt = dt.props;
        const groupingValue = opt.groupingValue || 'others';
        if (data[groupingValue]) {
          data[groupingValue].data.push(dt);
        } else {
          addItem(groupingValue, dt);
        }
      });
    }
    console.log('grouping', options, data);
    const gp = Object.keys(data);
    return (
      <React.Fragment>
        {gp &&
          Array.isArray(gp) &&
          gp.map(key => {
            console.log('grouping child', data[key].data);
            const options = data[key].data;
            return (
              <div key={key}>
                <div className="group-title">
                  {groupLabels ? groupLabels[key] : key}
                </div>
                {options}
              </div>
            );
          })}
      </React.Fragment>
    );
  };
  getDropDownChilds = (children = [], grouping) => {
    const {selected, searchTerm} = this.state;
    const {
      footer,
      notFoundContent,
      dropDownMaxHeight,
      multiple,
      filterOption,
    } = this.props;
    let options = React.Children.map(children, child =>
      React.cloneElement(child, {
        onClick: () => this.onSelect(child.props),
        selected: selected,
        multiple,
      })
    );
    if (filterOption) {
      options = options.filter(child => {
        const searchV = child.props
          ? child.props.searchValue
            ? child.props.searchValue
            : child.props.children
          : '';
        if (searchTerm && child.props && searchV)
          return filterOption(
            searchTerm,
            child.props ? searchV : '',
            child.props ? child.props : ''
          );
        else return true;
      });
    }

    const dropDownStyles = {};
    if (dropDownMaxHeight) {
      dropDownStyles['maxHeight'] = dropDownMaxHeight;
    }
    const dropDownContent = (
      <React.Fragment>
        <div className="options-list" style={dropDownStyles}>
          {grouping ? this.getGroupingOptions(options) : options}
        </div>
        <div className="c-s-footer">{footer}</div>
      </React.Fragment>
    );
    return options.length > 0 ? (
      dropDownContent
    ) : notFoundContent && searchTerm ? (
      notFoundContent
    ) : (
      <React.Fragment>{footer}</React.Fragment>
    );
  };
  onRemove = (e, v) => {
    e.stopPropagation();
    this.onSelect({value: v});
  };
  getLabel = () => {
    const {selected} = this.state;
    const {multiple} = this.props;
    let label = '';
    if (Array.isArray(this.selectedOptions)) {
      if (!multiple) {
        const c = this.selectedOptions.filter(
          child => child.value === selected
        );
        label = c[0] ? c[0].children : '';
        if (label === '' && this.state.selected) label = this.state.selected;
      } else {
        label = [];
        this.selectedOptions.forEach(child => {
          if (selected.indexOf(child.value) !== -1) {
            label.push(child);
          }
        });
        if (Array.isArray(selected) && label.length !== selected.length) {
          selected.forEach(i => {
            const exists = this.selectedOptions.filter(k => k.value === i);
            if (!exists[0])
              this.selectedOptions.push({
                value: i,
                children: i,
              });
          });
          this.forceUpdate();
        }
      }
    }
    return multiple
      ? label.map(l => (
          <Tag
            visible
            closable
            onClose={e => this.onRemove(e, l.value)}
            key={l.value}>
            {l.children}
          </Tag>
        ))
      : label;
  };
  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }
  clear = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({selected: ''});
  };
  render() {
    const {isFocus, searchTerm, selected} = this.state;
    const {
      children,
      style = {},
      className = '',
      placeholder,
      clearable,
      hideSelectedLabel,
      loading,
      multiple,
      grouping,
    } = this.props;
    const rootClassNames = ['custom-select'];
    if (isFocus) {
      rootClassNames.push('is-focused');
    }
    if (loading) {
      rootClassNames.push('is-loading');
    }
    if (multiple) {
      rootClassNames.push('is-multi');
    }
    if (searchTerm) {
      rootClassNames.push('has-search-term');
    }
    rootClassNames.push(className);
    return (
      <div
        className={rootClassNames.join(' ')}
        style={style}
        onClick={this.contentClick}>
        <div className="label-container">
          {!hideSelectedLabel && (
            <div className={'c-label'}>
              {this.getLabel()}
              {multiple && (
                <input
                  onFocus={this.onFocus}
                  style={
                    Array.isArray(selected) && selected.length === 0
                      ? {top: 0}
                      : {}
                  }
                  placeholder={placeholder}
                  onChange={this.onSearch}
                  value={searchTerm}
                />
              )}
            </div>
          )}
          {(((!selected ||
            (Array.isArray(selected) && selected.length === 0)) &&
            placeholder &&
            !multiple &&
            searchTerm.length === 0) ||
            (searchTerm.length === 0 && hideSelectedLabel)) && (
            <div className="c-s-placeholder">{placeholder}</div>
          )}
          {!multiple && (
            <input
              onFocus={this.onFocus}
              onChange={this.onSearch}
              value={searchTerm}
            />
          )}

          {clearable && !isFocus && selected && (
            <div className="c-closable" onClick={this.clear} />
          )}
        </div>
        <div className="dropdown-container">
          {this.getDropDownChilds(children, grouping)}
        </div>
      </div>
    );
  }
}
export default Select;
