import React from 'react';

import {CustomCollapse, Stats} from 'core';

import PanelHOC from './panelHOC';

const calculateInstitutionStats = data => {
  return [
    {
      title: 'Total Institutions',
      count: (data && data['Total Institutions']) || 0,
      color: 'orange',
    },
    {
      title: 'High School',
      count: (data && data['High School']) || 0,
      color: 'brown',
      insideColWidth: 3,
    },
    {
      title: 'Community College',
      count: (data && data['Community College']) || 0,
      color: 'fun-blue',
    },
    {
      title: 'State College',
      count: (data && data['State College']) || 0,
      color: 'green',
      insideColWidth: 3,
    },
    {
      title: 'University',
      count: (data && data['University']) || 0,
      color: 'dark-blue',
      insideColWidth: 3,
    },
    {
      title: 'Adult Learning',
      count: (data && data['Adult Learning']) || 0,
      color: 'purple',
      insideColWidth: 3,
    },
    {
      title: 'CTE Vocational',
      count: (data && data['CTE Vocational']) || 0,
      color: 'light-brown',
    },
  ];
};

function Institutions(props) {
  const {title, data: propsData = {}} = props;
  const INSTITUTION_STATS = calculateInstitutionStats(propsData);
  const data = [
    {
      title: title || 'INSTITUTIONS',
      key: 'institutions',
      passData: '',
      childern: (
        <div className="content">
          <Stats data={INSTITUTION_STATS} />
        </div>
      ),
    },
  ];

  return (
    <CustomCollapse
      data={data}
      switchPanel={props.switchPanel}
      activeKey={props.activeKey || 'institutions'}
    />
  );
}

export default PanelHOC(Institutions);
