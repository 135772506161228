import React from 'react';
import {View, StyleSheet} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  body: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    marginRight: 4,
    marginBottom: 4,
    width: 'auto',
    fontSize: 6,
    borderRadius: 10,
    color: '#8050f4',
    backgroundColor: '#e6e7ff',
  },
});

export default ({children}) => {
  return <View style={styles.body}>{children}</View>;
};
