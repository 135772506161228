import React, {useState} from 'react';
import {Row, Col, Select} from 'antd';

const {Option} = Select;
const Filters = ({data, onChange}) => {
  const [filterValues, setFilterValues] = useState([]);
  const handleChange = value => {
    onChange(value);
    setFilterValues(value);
  };
  const resetFilter = () => {
    onChange([]);
    setFilterValues([]);
  };

  return (
    <div className="dashboard-filter">
      <Row>
        <Col span={4}>
          <span className="label">Filter by Institutions</span>
        </Col>
        <Col span={17}>
          <span className="field">
            <Select
              mode="multiple"
              style={{width: '100%', height: 'auto', paddingBottom: 3}}
              placeholder="All Institutions"
              //defaultValue={['china']}
              value={filterValues}
              onChange={handleChange}
              optionLabelProp="label">
              {data &&
                data.map(({name, id, institution_id}) => (
                  <Option key={institution_id} value={id} label={name}>
                    {name}
                  </Option>
                ))}
            </Select>
          </span>
        </Col>
        <Col span={3} style={{textAlign: 'right'}}>
          {filterValues && filterValues.length > 0 && (
            <span className="reset" onClick={resetFilter}>
              Reset Filter
            </span>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Filters;
