export const sortEducationByDate = data => {
  return data.sort((a, b) => {
    if (a.school_end_date < b.school_end_date) {
      return 1;
    } else {
      return -1;
    }
  });
};

export const sortProfessionalExperienceByDate = data => {
  let havingIsPresent = [];
  let notHavingIsPresent = [];
  data.forEach(ele => {
    if (ele.is_present) {
      havingIsPresent.push(ele);
    } else {
      notHavingIsPresent.push(ele);
    }
  });

  const sortedIsPresent = havingIsPresent.sort((a, b) => {
    if (a.start_date < b.start_date) return 1;
    else return -1;
  });
  const sortedNotIsPresent = notHavingIsPresent.sort((a, b) => {
    if (a.end_date < b.end_date) {
      return 1;
    } else {
      return -1;
    }
  });

  return [...sortedIsPresent, ...sortedNotIsPresent];
};

export const sortBydateReceived = data => {
  return data.sort((a, b) => {
    if (a.date_received < b.date_received) {
      return 1;
    } else {
      return -1;
    }
  });
};

export const sortVolunteerExperienceByDate = data => {
  return data.sort((a, b) => {
    if (a.end_date < b.end_date) {
      return 1;
    } else {
      return -1;
    }
  });
};
