export const TALENT_EXCHANGE_STUDENTS_LIST_REQUEST =
  'TALENT_EXCHANGE_STUDENTS_LIST_REQUEST';
export const TALENT_EXCHANGE_STUDENTS_LIST_RECEIVE =
  'TALENT_EXCHANGE_STUDENTS_LIST_RECEIVE';
export const TALENT_EXCHANGE_STUDENTS_LIST_ERROR =
  'TALENT_EXCHANGE_STUDENTS_LIST_ERROR';
export const TALENT_EXCHANGE_STUDENTS_LIST_CLEAR =
  'TALENT_EXCHANGE_STUDENTS_LIST_CLEAR';

// Saved Searches
export const SAVED_SEARCH_LIST_REQUEST = 'SAVED_SEARCH_LIST_REQUEST';
export const SAVED_SEARCH_LIST_RECEIVE = 'SAVED_SEARCH_LIST_RECEIVE';
export const SAVED_SEARCH_LIST_ERROR = 'SAVED_SEARCH_LIST_ERROR';

// saved Profile List
export const SAVED_PROFILES_LIST_REQUEST = 'SAVED_PROFILES_LIST_REQUEST';
export const SAVED_PROFILES_LIST_RECEIVE = 'SAVED_PROFILES_LIST_RECEIVE';
export const SAVED_PROFILES_LIST_ERROR = 'SAVED_PROFILES_LIST_ERROR';
export const SAVED_PROFILES_LIST_CLEAR = 'SAVED_PROFILES_LIST_CLEAR';

// save Profile
export const SAVE_PROFILE_REQUEST = 'SAVE_PROFILE_REQUEST';
export const SAVE_PROFILE_RECEIVE = 'SAVE_PROFILE_RECEIVE';
export const SAVE_PROFILE_ERROR = 'SAVE_PROFILE_ERROR';
