import goEducateLogo from './applogo_white.svg';
import GoEducateLogo from './logo';
import goEducateLogoColored from './applogo.svg';
import goIcon from './Go_logo.svg';
import srmLogo from './stargate-logo-new.svg';
import logoSrc from './stargate-gps-logo.png';
import createProfile from './createicon.png';
import collegeLogoSrc from './compton-college-logo.jpg';
import backgroundBannerImage from './event-banner.jpg';
import suitCaseIcon from './suit-case.svg';
import profileHeader from './profile-header-bg.jpg';
import adminLogo from './admin_logo.png';
import pathTitleImg from './defaultpathtil.jpg';
import reportIcon from './report.png';
import goLoader from './GoLoader.gif';
import NotepadIcon from './notepad.svg';
import CompareDash from './compare_dash.svg';
import defaultPath from './no_pathway_banner.jpg';
import whiteTick from './whitetick.svg';
import occupationDefaultImg from './occupationdefaultimg.jpg';
import defaultimgcover from './defaultbannerprofile.jpg';
import GradientGoeducateLogo from './goeducate-gradient.png';

import FaceBook from '../icons/facebook.svg';
import Instagram from '../icons/instagram.svg';
import Twitter from '../icons/twitter.svg';
import LinkedIn from '../icons/linkedin.svg';
import Insticon from '../icons/insticon.svg';
import skillCertify from '../icons/certificatetickicon.svg';
import summaryChecked from '../icons/summary_checked.svg';
import summaryUnchecked from '../icons/summary_unchecked.svg';
import IconInfo from '../icons/icon_info.svg';
import IconInfoBlue from '../icons/infoiconblue.svg';
import graduationCap from './graduationcap.svg';
import videoIcon from '../icons/videoicon.svg';
import Ticktok from '../icons/tiktok_black.svg';
import Medium from '../icons/medium_black.svg';

const BASE_URL =
  'https://res.cloudinary.com/goeducate-inc/image/upload/q_auto/gps-graphics';
const VIDEO_BASEURL =
  'https://res.cloudinary.com/goeducate-inc/video/upload/q_auto/gps-graphics/videos';

const loginVideoBanner = `${VIDEO_BASEURL}/login_banner_gvbvx0.mov`;
const AboutBackground = `${BASE_URL}/banner/about_background_ao9out.png`;
const defaulGoBanner = `${BASE_URL}/banner/GO_Profile_Banner_oquifz.png`;
const xLogoBlack = `${BASE_URL}/icons/X_logo_black_rawgdj.svg`;
const tiktokBlack = `${BASE_URL}/icons/tiktok_black_npgxow.svg`;
const textloading = `${BASE_URL}/text_loading_lro0pg.gif`;
const alertYellowIcon = `${BASE_URL}/icons/alertyellow_gn3ywd.svg`;
const faceBookBlack = `${BASE_URL}/icons/FacebookBlack_xyaxmm.svg`;
const linkedInBlack = `${BASE_URL}/icons/LinkedInBlack_wjlsjf.svg`;
const mediumLogoBlack = `${BASE_URL}/icons/medium_black_ihauug.svg`;
const instagramBlack = `${BASE_URL}/icons/insta_logo_black_q1wwc2.svg`;
const resumePreviewIcon = `${BASE_URL}/icons/prztnn5rjggauajnfaxt.svg`;
const connectCompanyIcon = `${BASE_URL}/icons/connect_company_icon_cbtqzk.png`;
const disconnectCompanyIcon = `${BASE_URL}/icons/disconnect_company_icon_xwqr5e.png`;
const EmployerWelcomeBanner = `${BASE_URL}/banner/ab06c0272cdbe5ad3d5074471feae5e7_ml66ij.png`;

export {
  BASE_URL,
  VIDEO_BASEURL,
  defaulGoBanner,
  loginVideoBanner,
  goLoader,
  textloading,
  goEducateLogo,
  GoEducateLogo,
  goEducateLogoColored,
  AboutBackground,
  goIcon,
  logoSrc,
  collegeLogoSrc,
  createProfile,
  backgroundBannerImage,
  occupationDefaultImg,
  srmLogo,
  suitCaseIcon,
  profileHeader,
  adminLogo,
  pathTitleImg,
  FaceBook,
  Instagram,
  Twitter,
  LinkedIn,
  Insticon,
  reportIcon,
  NotepadIcon,
  skillCertify,
  CompareDash,
  summaryChecked,
  summaryUnchecked,
  defaultPath,
  whiteTick,
  defaultimgcover,
  IconInfo,
  IconInfoBlue,
  graduationCap,
  videoIcon,
  Ticktok,
  Medium,
  GradientGoeducateLogo,
  EmployerWelcomeBanner,
  xLogoBlack,
  tiktokBlack,
  faceBookBlack,
  linkedInBlack,
  instagramBlack,
  mediumLogoBlack,
  resumePreviewIcon,
  connectCompanyIcon,
  disconnectCompanyIcon,
  alertYellowIcon,
};
