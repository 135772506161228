import uniqBy from 'lodash/uniqBy';
import * as TYPES from './constants';
import {
  requestAIGeneratedOverviewContent,
  receiveAIGeneratedOverviewContent,
  handleAIGeneratedOverviewContentError,
} from './actions';
import {fetchSkills} from '../courseManagement';
import {receiveSkills} from '../courseManagement/actions';
import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {data: {}};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_AI_GENERATED_OVERVIEW_CONTENT:
      return {
        ...state,
        request: true,
        error: false,
      };

    case TYPES.RECIEVE_AI_GENERATED_OVERVIEW_CONTENT:
      return {
        ...state,
        data: {...state.data, ...action.payload},
        request: false,
        error: false,
      };

    case TYPES.AI_GENERATED_OVERVIEW_CONTENT_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };

    default:
      return state;
  }
};

export const fetchAIGeneratedOverViewContent = params => (
  dispatch,
  getState
) => {
  let skillsList = [];
  let accessToken = null;
  const state = getState();
  let endPoint = `${API.pathway.publish}`;
  const paramsList = Object.keys(params) || [];
  paramsList.map(
    (key, idx) => (endPoint += `${idx === 0 ? '?' : '&'}${key}=${params[key]}`)
  );
  if (state && state.auth && state.auth.access) {
    const {
      auth: {
        access: {token},
      },
    } = state;
    accessToken = token;
  }
  if (state && state.courseManagement && state.courseManagement.skills) {
    const {courseManagement: {skills: {data = []} = {}} = {}} = state || {};
    skillsList = data;
  }
  if (accessToken) {
    const config = {
      mode: 'cors',
      method: 'GET',
      cache: 'default',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
    };
    dispatch(requestAIGeneratedOverviewContent());
    try {
      return fetch(endPoint, config)
        .then(response => response.json())
        .then(async json => {
          if (json.skills) {
            dispatch(
              fetchSkills({}, () => {
                dispatch(receiveAIGeneratedOverviewContent(json));
              })
            );
          } else {
            dispatch(receiveAIGeneratedOverviewContent(json));
          }
        })
        .catch(err => {
          console.log(err);
          dispatch(handleAIGeneratedOverviewContentError(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(handleAIGeneratedOverviewContentError(err));
    }
  }
};
