import React from 'react';
import {Row, Col, Divider, Button, Modal} from 'antd';

import './course-details-modal.css';

const CourseDetailsModal = ({
  visible,
  course = {},
  institute_name,
  onClose,
}) => {
  const onCloseModal = () => {
    if (onClose) onClose();
  };
  return visible ? (
    <Modal
      footer={false}
      width="800px"
      className="courseDetailModal"
      wrapClassName="_modal-wrap"
      onCancel={onCloseModal}
      visible={true}>
      <div className="course-detail-container">
        <div className="CourseDetails">
          <div className="courseTitle">Course Details</div>
          {(institute_name ||
            course.institute_name ||
            course.institution_name) && (
            <div className="courseInfoContainer">
              <span className="courseInfoLabel">School:</span>
              <span className="courseInfoData">
                {institute_name ||
                  course.institute_name ||
                  course.institution_name ||
                  '-'}
              </span>
            </div>
          )}
          <div className="courseInfoContainer">
            <span className="courseInfoLabel">Course ID:</span>
            <span className="courseInfoData">{course.course_id || '-'}</span>
          </div>
          <div className="courseInfoContainer">
            <span className="courseInfoLabel">Course Title:</span>
            <span className="courseInfoData">
              {course.course_name || course.title || '-'}
            </span>
          </div>
          <div className="courseInfoContainer">
            <span className="courseInfoLabel">Course Description:</span>
            <span className="courseInfoData">{course.description || '-'}</span>
          </div>
          <div className="courseInfoContainer">
            <span className="courseInfoLabel">Credits/Units :</span>
            <span className="courseInfoData">{course.units || '-'}</span>
          </div>
          <div className="courseInfoContainer">
            <span className="courseInfoLabel">Course Instruction Type I:</span>
            <span className="courseInfoData">
              {course.course_instruction_type_1 || '-'}
            </span>
          </div>
          <div className="courseInfoContainer">
            <span className="courseInfoLabel">No. of Hours I:</span>
            <span className="courseInfoData">
              {course.num_of_hours_1 || '-'}
            </span>
          </div>
          <div className="courseInfoContainer">
            <span className="courseInfoLabel">Course Instruction Type II:</span>
            <span className="courseInfoData">
              {course.course_instruction_type_2 || '-'}
            </span>
          </div>
          <div className="courseInfoContainer">
            <span className="courseInfoLabel">No. of Hours II:</span>
            <span className="courseInfoData">
              {course.num_of_hours_2 || '-'}
            </span>
          </div>
          <div className="courseInfoContainer">
            <span className="courseInfoLabel">Transfer Type:</span>
            <span className="courseInfoData">{course.transferable || '-'}</span>
          </div>
          <div className="courseInfoContainer">
            <span className="courseInfoLabel">Skills Learned:</span>
            <span className="courseInfoData">
              {(course.skills_learned &&
                course.skills_learned
                  .toString()
                  .split(',')
                  .join(', ')) ||
                '-'}
            </span>
          </div>
          <div className="courseInfoContainer">
            <span className="courseInfoLabel">Pre-requisite(s):</span>
            <span className="courseInfoData">
              {(course.pre_requisites &&
                course.pre_requisites
                  .toString()
                  .split(',')
                  .join(', ')) ||
                '-'}
            </span>
          </div>
          <div className="courseInfoContainer">
            <span className="courseInfoLabel">Co-requisite(s):</span>
            <span className="courseInfoData">
              {(course.co_requisites &&
                course.co_requisites
                  .toString()
                  .split(',')
                  .join(', ')) ||
                '-'}
            </span>
          </div>
          <div className="courseInfoContainer">
            <span className="courseInfoLabel">Course TOP Code:</span>
            <span className="courseInfoData">
              {course.course_top_code || '-'}
            </span>
          </div>
          <div className="courseInfoContainer">
            <span className="courseInfoLabel">Credit Status:</span>
            <span className="courseInfoData">
              {course.credit_status || '-'}
            </span>
          </div>
          <div className="courseInfoContainer">
            <span className="courseInfoLabel">Learning Outcomes:</span>
            <span className="courseInfoData">
              {course.learning_outcomes || '-'}
            </span>
          </div>
          <div className="courseInfoContainer">
            <span className="courseInfoLabel">Course Notes:</span>
            <span className="courseInfoData">{course.course_notes || '-'}</span>
          </div>
          {/* <Divider/>
                    <Button className="takeCourseBtn" type="primary">TAKE THIS COURSE</Button> */}
        </div>
      </div>
    </Modal>
  ) : (
    <div />
  );
};

export default CourseDetailsModal;
