import React from 'react';
import {Collapse, Icon, Switch, Tooltip} from 'antd';

import {isEmpty, isObject, isArray} from 'core';
import {isBoolean} from 'util';

const Panel = Collapse.Panel;

const panelHeader = (
  title,
  activeKey,
  key,
  data,
  showSwitch,
  handleToggle,
  toggleState,
  tooltipText
) => {
  const toggleIcon = key === activeKey ? 'minus' : 'plus';
  const handleSwitch = (value, e) => {
    e.preventDefault();
    e.stopPropagation();
    handleToggle && handleToggle(value);
  };
  return (
    <>
      <span>
        {title}
        {showSwitch && (
          <span className="pl-3">
            <Switch onChange={handleSwitch} checked={toggleState} />
            &nbsp;&nbsp;
            {tooltipText && (
              <Tooltip title={tooltipText}>
                <Icon type="info-circle" theme="filled" />
              </Tooltip>
            )}
          </span>
        )}
      </span>
      <div className="action-icons">
        {data && isArray(data) && data.length > 0 ? (
          <Icon type="check" />
        ) : data && isObject(data) && !isEmpty(data) ? (
          <Icon type="check" />
        ) : data && isBoolean(data) && data === true ? (
          <Icon type="check" />
        ) : (
          ''
        )}
        <Icon type={toggleIcon} />
      </div>
    </>
  );
};

const CustomCollapse = ({switchPanel, activeKey, data}) => {
  const pannelData = () => {
    return (
      data &&
      data.map(
        (
          {
            title,
            key,
            passData,
            childern,
            showSwitch,
            handleToggle,
            toggleState,
            tooltipText,
          },
          idx
        ) => {
          const forceRender = key !== activeKey;
          return (
            <Panel
              header={panelHeader(
                title,
                activeKey,
                key,
                passData,
                showSwitch,
                handleToggle,
                toggleState,
                tooltipText
              )}
              key={key}
              showArrow={false}
              forceRender={forceRender}>
              {childern}
            </Panel>
          );
        }
      )
    );
  };
  return switchPanel ? (
    (activeKey !== undefined && (
      <Collapse accordion activeKey={[activeKey]} onChange={switchPanel}>
        {pannelData()}
      </Collapse>
    )) || <div />
  ) : (
    // <Collapse accordion>{pannelData()}</Collapse>
    <Collapse
      defaultActiveKey={[activeKey]}
      expandIcon={({isActive}) =>
        isActive ? <Icon type="minus" /> : <Icon type="plus" />
      }
      expandIconPosition="right">
      {data &&
        data.map(({title, key, passData, childern}, idx) => {
          return (
            <Panel header={title} key={key}>
              {childern}
            </Panel>
          );
        })}
    </Collapse>
  );
};

export default CustomCollapse;
