export const RadarData = {
  datasets: [
    {
      label: '',
      backgroundColor: '#7b5fe36e',
      borderColor: '#7b5fe36e',
      pointBackgroundColor: '#7B5FE3',
      pointBorderColor: '#7B5FE3',
      pointBorderWidth: 5,
      pointHoverBackgroundColor: '#7B5FE3',
      pointHoverBorderColor: '#7B5FE3',
      data: [3, 6, 10],
    },
  ],
};
export const RadarOptions = {
  layout: {
    padding: 20,
  },

  tooltips: {
    enabled: false,
  },

  scale: {
    scaleShowLabels: false,
    pointLabels: {
      fontStyle: 'bold',
      fontFamily: 'Arial Rounded MT',
      borderColor: '#7b5fe36e',
      lineHeight: 2,
      padding: 5,
    },
    ticks: {
      display: false,
      beginAtZero: true,
      min: 0,
      max: 10,
      stepSize: 1,
    },
    angleLines: {
      color: '#8050F4',
      lineWidth: 1,
    },
    gridLines: {
      color: '#8050F4',
      circular: true,
    },
  },
  legend: {
    display: false,
  },
};
