import {memo, useEffect, useState, useCallback} from 'react';
// components
import {
  gridClasses,
  DataGridPro,
  GridFilterPanel,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import {Button} from 'mui-core';
import {Box, Stack, Typography, LinearProgress} from '@mui/material';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
// utils
import {PropTypes} from 'prop-types';
import {alpha, styled} from '@mui/material/styles';

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGridPro)(
  ({theme, striped, checkboxSelection}) => ({
    '& p': {
      marginTop: 'initial',
      marginBottom: 'initial',
    },
    '& .MuiDataGrid-columnHeaders': {
      fontSize: '15px',
      color: theme.palette.mode === 'dark' ? 'white' : theme.palette.grey[800],
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.darkGray.main
          : theme.palette.grey[300],
    },
    '& .MuiDataGrid-virtualScroller': {
      fontSize: '14px',
    },
    '& .first-header': {
      paddingLeft: checkboxSelection ? '32px' : '48px',
    },
    '& .first-header-cell': {
      paddingLeft: checkboxSelection ? '32px' : '48px',
    },
    '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-detailPanel': {
      backgroundColor:
        theme.palette.mode === 'light'
          ? theme.palette.grey[200]
          : theme.palette.background.paper,
    },
    [`& .${gridClasses.row}.odd`]: striped
      ? {
          backgroundColor:
            theme.palette.mode === 'light'
              ? theme.palette.grey[50]
              : theme.palette.dialogDarkBG.main,
          '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.midGray.main, ODD_OPACITY),
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
          '&.Mui-selected': {
            backgroundColor: alpha(
              theme.palette.midGray.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
            '&:hover, &.Mui-hovered': {
              backgroundColor: alpha(
                theme.palette.midGray.main,
                ODD_OPACITY +
                  theme.palette.action.selectedOpacity +
                  theme.palette.action.hoverOpacity
              ),
              // Reset on touch devices, it doesn't add specificity
              '@media (hover: none)': {
                backgroundColor: alpha(
                  theme.palette.midGray.main,
                  ODD_OPACITY + theme.palette.action.selectedOpacity
                ),
              },
            },
          },
        }
      : {},
  })
);

const StyledGridOverlay = styled(Box)(() => ({
  height: 340,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const FilterPanelContainer = styled(Stack)(({theme}) => ({
  padding: '8px',
  '& .MuiDataGrid-filterForm': {
    alignItems: 'flex-end',
    '& .MuiDataGrid-filterFormLogicOperatorInput': {
      display: 'none',
    },
    '& .MuiDataGrid-filterFormValueInput .MuiFormControl-root ': {
      width: '100%',
    },
    '& input': {
      height: '30px',
      border: 'none',
      font: 'inherit',
      fontSize: 'initial',
      padding: '0px !important',
      color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
    },
  },
}));

const DataGrid = ({
  sx = {},
  loading,
  rows = [],
  sortModel,
  columns = [],
  filters = [],
  pageSize = 10,
  onApplyFilter,
  // onFilterChange,
  height = 'auto',
  isRowSelectable,
  onSortModelChange,
  onPageModelChange,
  totalRowCount = 0,
  autoHeight = false,
  hideFooter = false,
  pagination = false,
  showToolbar = false,
  paginationModel = {
    page: 0,
    pageSize: 10,
  },
  getDetailPanelHeight,
  getDetailPanelContent,
  hideColumnMenu = true,
  filterMode = 'client',
  sortingMode = 'client',
  hideGridExport = true,
  hideOuterBorder = true,
  hideGridFilter = false,
  hideQuickFilter = true,
  rowSelectionModel = [],
  showStripedGrid = false,
  hideColumnFilter = false,
  paginationMode = 'client',
  onRowSelectionModelChange,
  checkboxSelection = false,
  disableColumnResize = true,
  disableRowSelection = true,
  disableColumnPinning = true,
  hideFooterPagination = false,
  noResultsMsg = 'No Results Found',
  pageSizeOptions = [10, 15, 20, 25],
  keepNonExistentRowsSelected = true,
  hideFooterSelectedRowCount = false,
  getRowId,
  initialState,
}) => {
  const containerStyles = {};
  const [gridFilters, setGridFilters] = useState(filters);
  const [rowCountState, setRowCountState] = useState(totalRowCount || 0);

  // switching total_count to undefined during loading resets the page to zero.
  // To avoid this problem, we are keeping the previous value of rowCount while loading
  useEffect(
    () => {
      setRowCountState(prevRowCountState =>
        totalRowCount !== undefined ? totalRowCount : prevRowCountState
      );
    },
    [totalRowCount]
  );

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <ContentPasteOffIcon fontSize="large" />
        <Box sx={{mt: 2}}>
          <Typography variant="body1">{noResultsMsg}</Typography>
        </Box>
      </StyledGridOverlay>
    );
  }

  function CustomToolbar() {
    return (
      <Stack
        sx={{p: 0.5}}
        direction="row"
        alignItems="center"
        justifyContent="space-between">
        <GridToolbarContainer>
          {!hideGridFilter && <GridToolbarFilterButton />}
          {!hideGridExport && <GridToolbarExport />}
        </GridToolbarContainer>
        {!hideQuickFilter && (
          <GridToolbarQuickFilter
            debounceMs={600}
            quickFilterParser={searchInput =>
              searchInput
                .split(',')
                .map(value => value.trim())
                .filter(value => value !== '')
            }
          />
        )}
      </Stack>
    );
  }

  function CustomFilterPanel(props) {
    const {onApply, onCancel, showServerInteractiveButtons} = props;
    return (
      <FilterPanelContainer direction="column" position="relative">
        <GridFilterPanel {...props} />
        {showServerInteractiveButtons && (
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{position: 'absolute', right: 16, bottom: 16}}>
            <Button variant="outlined" onClick={onCancel}>
              Clear all
            </Button>
            <Button variant="contained" onClick={onApply}>
              Apply
            </Button>
          </Stack>
        )}
      </FilterPanelContainer>
    );
  }

  const onApplyBtnClick = () => {
    onApplyFilter(gridFilters);
  };

  const onCancelBtnClick = () => {
    setGridFilters([]);
    onApplyFilter([], true);
  };

  const onFilterChange = useCallback(filterModel => {
    setGridFilters(filterModel.items);
  }, []);

  const filterColumns = ({field, columns, currentFilters}) => {
    // remove already filtered fields from list of columns
    const filteredFields = currentFilters?.map(({field}) => field);
    return columns
      .filter(
        colDef =>
          colDef.filterable &&
          (colDef.field === field || !filteredFields.includes(colDef.field))
      )
      .map(column => column.field);
  };
  const getColumnForNewFilter = ({currentFilters, columns}) => {
    const filteredFields = currentFilters?.map(({field}) => field);
    const columnForNewFilter = columns
      .filter(
        colDef => colDef.filterable && !filteredFields.includes(colDef.field)
      )
      .find(colDef => colDef.filterOperators?.length);
    return columnForNewFilter?.field ?? null;
  };

  if (height) containerStyles.height = `${height}px`;

  return (
    <Box sx={{...containerStyles, width: '100%'}}>
      <StripedDataGrid
        rows={rows}
        disableAutosize
        columns={columns}
        loading={loading}
        sortModel={sortModel}
        disableColumnSelector
        disableDensitySelector
        filterDebounceMs={600}
        pagination={pagination}
        filterMode={filterMode}
        autoHeight={autoHeight}
        hideFooter={hideFooter}
        rowCount={rowCountState}
        sortingMode={sortingMode}
        striped={showStripedGrid}
        paginationMode={paginationMode}
        paginationModel={paginationModel}
        pageSizeOptions={pageSizeOptions}
        isRowSelectable={isRowSelectable}
        disableColumnMenu={hideColumnMenu}
        filterModel={{items: gridFilters}}
        onFilterModelChange={onFilterChange}
        checkboxSelection={checkboxSelection}
        rowSelectionModel={rowSelectionModel}
        onSortModelChange={onSortModelChange}
        disableColumnFilter={hideColumnFilter}
        disableColumnResize={disableColumnResize}
        experimentalFeatures={{lazyLoading: true}}
        disableColumnPinning={disableColumnPinning}
        onPaginationModelChange={onPageModelChange}
        hideFooterPagination={hideFooterPagination}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        disableRowSelectionOnClick={disableRowSelection}
        sx={{...sx, borderWidth: hideOuterBorder ? 0 : 2}}
        onRowSelectionModelChange={onRowSelectionModelChange}
        hideFooterSelectedRowCount={hideFooterSelectedRowCount}
        keepNonExistentRowsSelected={keepNonExistentRowsSelected}
        getRowId={getRowId}
        initialState={
          initialState
            ? initialState
            : {
                pagination: {paginationModel: {pageSize: pageSize, page: 0}},
              }
        }
        slots={{
          columnMenuColumnsItem: null,
          loadingOverlay: LinearProgress,
          noRowsOverlay: memo(CustomNoRowsOverlay),
          noResultsOverlay: memo(CustomNoRowsOverlay),
          toolbar: showToolbar ? memo(CustomToolbar) : null,
          filterPanel: props => (
            <CustomFilterPanel
              {...props}
              onApply={onApplyBtnClick}
              onCancel={onCancelBtnClick}
              showServerInteractiveButtons={filterMode === 'server'}
            />
          ),
        }}
        slotProps={{
          filterPanel: {
            filterFormProps: {
              filterColumns,
              multiFilterOperator: 'and',
              showMultiFilterOperators: false,
            },
            getColumnForNewFilter,
            disableRemoveAllButton: filterMode === 'server',
          },
        }}
        getRowClassName={itm =>
          itm.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
      />
    </Box>
  );
};

DataGrid.propTypes = {
  sx: PropTypes.object,
  rows: PropTypes.array,
  loading: PropTypes.bool,
  filters: PropTypes.array,
  height: PropTypes.string,
  columns: PropTypes.array,
  pageSize: PropTypes.number,
  autoHeight: PropTypes.bool,
  hideFooter: PropTypes.bool,
  pagination: PropTypes.bool,
  showToolbar: PropTypes.bool,
  sortModel: PropTypes.object,
  filterMode: PropTypes.string,
  sortingMode: PropTypes.string,
  onApplyFilter: PropTypes.func,
  noResultsMsg: PropTypes.string,
  hideColumnMenu: PropTypes.bool,
  hideGridExport: PropTypes.bool,
  hideGridFilter: PropTypes.bool,
  // onFilterChange: PropTypes.func,
  totalRowCount: PropTypes.number,
  isRowSelectable: PropTypes.func,
  showStripedGrid: PropTypes.bool,
  hideQuickFilter: PropTypes.bool,
  hideOuterBorder: PropTypes.bool,
  hideColumnFilter: PropTypes.bool,
  paginationMode: PropTypes.string,
  pageSizeOptions: PropTypes.array,
  onPageModelChange: PropTypes.func,
  onSortModelChange: PropTypes.func,
  paginationModel: PropTypes.object,
  checkboxSelection: PropTypes.bool,
  rowSelectionModel: PropTypes.array,
  disableRowSelection: PropTypes.bool,
  disableColumnResize: PropTypes.bool,
  disableColumnPinning: PropTypes.bool,
  hideFooterPagination: PropTypes.bool,
  getDetailPanelHeight: PropTypes.func,
  getDetailPanelContent: PropTypes.func,
  onRowSelectionModelChange: PropTypes.func,
  hideFooterSelectedRowCount: PropTypes.func,
  keepNonExistentRowsSelected: PropTypes.bool,
};

export default memo(DataGrid);
