import * as TYPES from './constants';
import {requestUsers, receiveUsers, handleUsersError} from './actions';

import {JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import {API} from 'config/businesspartner.config';

const initialState = {
  users: null,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_BUSINESS_PARTNER_USERS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_BUSINESS_PARTNER_USERS:
      return {
        ...state,
        users: action.payload,
        business_partner_id:
          action.payload &&
          Array.isArray(action.payload) &&
          action.payload.length > 0 &&
          action.payload[0].institution_uuid,
        request: false,
        error: false,
      };
    case TYPES.BUSINESS_PARTNER_USERS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchBusinessPartnerUsers = () => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestUsers());
  try {
    return fetch(`${API.administration.business_partner_user}`, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveUsers(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleUsersError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleUsersError(err));
  }
};
