import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Form, Input, Icon} from 'antd';
import {PATTERN, FileUploader, getCloudinaryImage} from 'core';

import {getProfileUploadStatus} from 'redux/modules/homepage';

const FormItem = Form.Item;
class EditProfileForm extends Component {
  state = {
    makeRequired: false,
    confirmDirty: false,
    imageData: '',
    OrignalPassword: localStorage.getItem('password'),
  };

  getImageData = file => {
    let {resetFields} = this.props.form;
    resetFields('userImage');
    this.setState({
      imageData: file,
    });
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({confirmDirty: this.state.confirmDirty || !!value});
  };

  validateProfileForm = e => {
    let currentPassword = e.target.value;
    const {OrignalPassword} = this.state;
    const {validateFields, resetFields} = this.props.form;

    if (currentPassword && OrignalPassword === currentPassword) {
      this.setState(
        {
          makeRequired: true,
        },
        () => {
          validateFields(['newpassword', 'confirmpassword'], {
            force: true,
          });
        }
      );
    } else {
      resetFields(['newpassword', 'confirmpassword']);
      this.setState({
        makeRequired: false,
      });
      return false;
    }
  };

  comparePassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('newpassword')) {
      callback('Two passwords that you enter are inconsistent');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirmpassword'], {force: true});
    }
    callback();
  };

  handleSubmit = () => {
    const {userData: {email = null} = {}, form, onEditProfileSave} = this.props;

    const {validateFields, resetFields} = form;
    const {imageData} = this.state;
    validateFields((err, values) => {
      if (!err) {
        if (
          imageData &&
          typeof imageData === 'object' &&
          imageData.constructor === File
        ) {
          values.profile_image = imageData;
        }

        // on Save Success Reset Fields
        // resetFields();
        // Post 'values' DATA TO THE API
        if (email && values.email === email) {
          delete values.email;
        }
        onEditProfileSave(values);
      }
    });
  };

  render() {
    const {
      closeHandler,
      form: {getFieldDecorator},
      type,
      userData: {
        first_name = '',
        email = '',
        picture = '',
        last_name = '',
      } = {},
      profileUploadStatus,
    } = this.props;

    const {makeRequired} = this.state;

    return (
      <div className="profile-dropdown edit-prof-dropdown">
        <h1 className="profile-title">
          <span className="link">
            <Icon onClick={closeHandler} type="left" />
          </span>
          {type === 'basic' ? 'Edit Profile' : 'Change Password'}
        </h1>
        <>
          <Form className="edit-prof-form table-form">
            {type === 'basic' && (
              <>
                <FormItem className="margin-muted">
                  <div className="change-photo">
                    {profileUploadStatus && (
                      <div className="lds-ellipsis">
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    )}
                    <FileUploader
                      alwaysShow
                      title="Change Profile Photo"
                      imgSrc={getCloudinaryImage(picture, 'logo')}
                      onChange={this.getImageData}
                      type="imagePreview"
                      multipleUpload={false}
                      defaultIcon="user"
                    />
                  </div>
                </FormItem>
                <FormItem label="User Name">
                  <Input
                    type="text"
                    value={`${first_name} ${last_name}`}
                    readOnly
                  />
                </FormItem>
                <FormItem label="Email">
                  {getFieldDecorator('email', {
                    initialValue: email,
                  })(<Input type="email" readOnly />)}
                </FormItem>
                {/* <h1 className="sub-title"> Password </h1> */}
              </>
            )}
            {type === 'password' && (
              <>
                <FormItem label="Current">
                  {getFieldDecorator('currentpassword', {
                    rules: [
                      {
                        required: false,
                        message: 'Please Enter Old Password',
                      },
                    ],
                  })(
                    <Input
                      type="password"
                      onChange={this.validateProfileForm}
                    />
                  )}
                </FormItem>
                <FormItem label="New">
                  {getFieldDecorator('newpassword', {
                    rules: [
                      {
                        required: makeRequired,
                        message: 'Please Enter New Password',
                      },
                      {
                        pattern: PATTERN.Password,
                        message: `Password must contain atleast 8 characters,
                                  one uppercase, one lowercase, one number and one special character from ! @ # $ % *`,
                      },
                      {
                        validator: this.validateToNextPassword,
                      },
                    ],
                  })(
                    <Input
                      type="password"
                      //   placeholder="New Password"
                      disabled={!makeRequired}
                    />
                  )}
                </FormItem>
                <FormItem label="Verify">
                  {getFieldDecorator('confirmpassword', {
                    rules: [
                      {
                        required: makeRequired,
                        message: 'Please Confirm Your Password',
                      },
                      {
                        validator: this.comparePassword,
                      },
                    ],
                  })(
                    <Input
                      type="password"
                      //   placeholder="New Password, again"
                      onBlur={this.handleConfirmBlur}
                      disabled={!makeRequired}
                    />
                  )}
                </FormItem>
              </>
            )}
          </Form>
        </>
        <div className="btn-row">
          <a onClick={closeHandler} className="ant-btn btn-cancel">
            Cancel
          </a>
          <a onClick={this.handleSubmit} className="ant-btn ant-btn-primary">
            Save
          </a>
        </div>
      </div>
    );
  }
}

EditProfileForm.propTypes = {
  userData: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profileUploadStatus: getProfileUploadStatus(state),
});

export default Form.create()(
  connect(
    mapStateToProps,
    {}
  )(EditProfileForm)
);
