import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import * as TYPES from './constants';
import {
  requestCourseManagementCourseTransferType,
  receiveCourseManagementCourseTransferType,
  handleCourseManagementCourseTransferTypeError,
} from './actions';
const initialState = {
  request: null,
  data: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_COURSE_MANAGEMENT_COURSE_TRANSFER_TYPE:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_COURSE_MANAGEMENT_COURSE_TRANSFER_TYPE:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };

    case TYPES.COURSE_MANAGEMENT_COURSE_TRANSFER_TYPE_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchCourseTransferType = () => (dispatch, getState) => {
  const {
    auth: {
      access: {token = null},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestCourseManagementCourseTransferType());
  try {
    return fetch(`${API.course_management.course_transfer_type}`, config)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Api Fails');
      })
      .then(json => {
        dispatch(receiveCourseManagementCourseTransferType(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleCourseManagementCourseTransferTypeError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleCourseManagementCourseTransferTypeError(err));
  }
};
