export const REQUEST_INSTITUTE_DASHBOARD_DATA =
  'REQUEST_INSTITUTE_DASHBOARD_DATA';
export const RECEIVE_INSTITUTE_DASHBOARD_DATA =
  'RECEIVE_INSTITUTE_DASHBOARD_DATA';
export const INSTITUTE_DASHBOARD_DATA_ERROR = 'INSTITUTE_DASHBOARD_DATA_ERROR';
export const REQUEST_REPORTER_USERS = 'REQUEST_REPORTER_USERS';
export const RECEIVE_REPORTER_USERS = 'RECEIVE_REPORTER_USERS';
export const REPORTER_USERS_ERROR = 'REPORTER_USERS_ERROR';

export const REQUEST_DESCENDENT_INSTITUTIONS =
  'REQUEST_DESCENDENT_INSTITUTIONS';
export const RECEIVE_DESCENDENT_INSTITUTIONS =
  'RECEIVE_DESCENDENT_INSTITUTIONS';
export const DESCENDENT_INSTITUTIONS_ERROR = 'DESCENDENT_INSTITUTIONS_ERROR';

export const REQUEST_LOGGED_IN_INSTITUTE = 'REQUEST_LOGGED_IN_INSTITUTE';
export const RECEIVE_LOGGED_IN_INSTITUTE = 'RECEIVE_LOGGED_IN_INSTITUTE';
export const LOGGED_IN_INSTITUTE_ERROR = 'LOGGED_IN_INSTITUTE_ERROR';

export const REQUEST_REPORTER_USER = 'REQUEST_REPORTER_USER';
export const REQUEST_REPORTER_USER_ERROR = 'REQUEST_REPORTER_USER_ERROR';
export const TOGGLE_REPORTER_PROFILE = 'TOGGLE_REPORTER_PROFILE';

export const REQUEST_INSTITUTE_DETAILS = 'REQUEST_INSTITUTE_DETAILS';
export const RECEIVE_INSTITUTE_DETAILS = 'RECEIVE_INSTITUTE_DETAILS';
export const INSTITUTE_DETAILS_ERROR = 'INSTITUTE_DETAILS_ERROR';

export const REQUEST_REPORTER_CONSORTIUMS = 'REQUEST_REPORTER_CONSORTIUMS';
export const RECEIVE_REPORTER_CONSORTIUMS = 'RECEIVE_REPORTER_CONSORTIUMS';
export const REPORTER_CONSORTIUMS_ERROR = 'REPORTER_CONSORTIUMS_ERROR';
