import React, {useState, useEffect} from 'react';
import {Divider, Icon} from 'antd';
import {NoSearchResults} from 'core';
import moment from 'moment';

const dateFormat = 'MM/DD/YYYY';
const WorkExperienceItem = ({data, index}) => {
  const job_responsibilities =
    data.job_responsibilities &&
    data.job_responsibilities.length > 0 &&
    data.job_responsibilities.filter(
      comment => typeof comment.job_responsibilities === 'string'
    );
  return (
    <div key={`work-experience-${index}`} className="work-experience-item">
      <div className="header-details">
        <span className="company-name">{data.company_name}</span>
        <span className="location"> - {data.job_location}</span>
      </div>
      <div className="time-period">
        <span className="start">
          {data.start_date && moment(data.start_date).format(dateFormat)}
        </span>
        <span className="end">
          {data.is_present
            ? '- Present'
            : `- ${data.end_date && moment(data.end_date).format(dateFormat)}`}
        </span>
      </div>
      {job_responsibilities && job_responsibilities.length > 0 && (
        <div className="role-n-responsibilities">
          <span className="role-n-responsibilities-title">
            {data.job_title}
          </span>
          <div className="responsibilities">
            <ul>
              {job_responsibilities.length > 0 &&
                job_responsibilities.map((job_responsibility, idx) => (
                  <li
                    key={`work-experience-responsibility-${index}-${idx}`}
                    className="point">
                    {/* <Icon type="caret-right" /> */}
                    {job_responsibility.job_responsibilities}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
      <Divider className="_divider" />
    </div>
  );
};

const WorkExperience = ({data}) => {
  const [weData, setWeData] = useState([]);

  useEffect(
    () => {
      if (data && data.work_exp) {
        setWeData([...data.work_exp]);
      }
    },
    [data]
  );

  return (
    <div className="work-experience">
      <div className="work-experience-header">Work Experience</div>
      <div className="work-experience-content">
        {weData &&
          weData.length > 0 &&
          weData.map((data, index) => (
            <WorkExperienceItem key={index} index={index} data={data} />
          ))}
      </div>
      {/* <Divider className="_divider" /> */}
    </div>
  );
};

export default WorkExperience;
