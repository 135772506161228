export const REQUEST_REPORT_OBJECTS = 'REQUEST_REPORT_OBJECTS';
export const RECEIVE_REPORT_OBJECTS = 'RECEIVE_REPORT_OBJECTS';
export const REPORT_OBJECTS_ERROR = 'REPORT_OBJECTS_ERROR';

export const REQUEST_REPORT_RUN = 'REQUEST_REPORT_RUN';
export const RECEIVE_REPORT_RUN = 'RECEIVE_REPORT_RUN';
export const REPORT_RUN_ERROR = 'REPORT_RUN_ERROR';

export const REQUEST_REPORTS = 'REQUEST_REPORTS';
export const RECEIVE_REPORTS = 'RECEIVE_REPORTS';
export const REPORTS_ERROR = 'REPORTS_ERROR';
export const REPORTS_FIRST_LOAD = 'REPORTS_FIRST_LOAD';

export const REQUEST_REPORT = 'REQUEST_REPORT';
export const RECEIVE_REPORT = 'RECEIVE_REPORT';
export const REPORT_ERROR = 'REPORT_ERROR';
export const CLEAR_REPORT = 'CLEAR_REPORT';
