import React from 'react';
import {Tag} from 'antd';
import {NoSearchResults} from 'core';

const Interests = ({interest}) => {
  return (
    <div className="profile-interests">
      <div className="interests-header">Interests</div>
      <div className="interests-content">
        {interest &&
          interest.length > 0 &&
          interest.map((int, idx) => (
            <Tag
              key={'pf-int-dt-' + idx}
              visible
              className="ant-tag-primary long-tag">
              <span>
                <span key={idx}>{int}</span>
              </span>
            </Tag>
          ))}
      </div>
    </div>
  );
};

export default Interests;
