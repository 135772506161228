import {
  superInstituteTypesClear,
  requestSuperUserInstituteTypes,
  receiveSuperUserInstituteTypes,
  handleSuperUserInstituteTypesError,
} from './actions';
import * as TYPES from './constants';
import {Services, isArray} from 'core';
import {API} from 'config/superuser.config';

const initialState = {
  data: null,
  error: null,
  request: null,
};

const services = new Services();
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_SUPER_USER_INSTITUTE_TYPES:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_SUPER_USER_INSTITUTE_TYPES:
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload,
      };
    case TYPES.SUPER_USER_INSTITUTE_TYPES_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.SUPER_USER_INSTITUTE_TYPES_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const fetchSuperUserInstituteTypes = uuid => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestSuperUserInstituteTypes());
  let endpoint = `${
    API.administration.institution_sub_type
  }?super_type__uuid=${uuid}&is_licenseable=true`;
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(result => {
        dispatch(receiveSuperUserInstituteTypes(result));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleSuperUserInstituteTypesError(err));
  }
};

export const clearSuperInstituteTypes = () => (dispatch, getState) => {
  dispatch(superInstituteTypesClear());
};
