import * as TYPES from './constants';
import {
  requestPartnersList,
  receivePartnersList,
  handlePartnersListError,
} from './actions';
import {fetchpartnersGraphData} from './partnersGraph';
import {getLoggedInstituteUUID} from 'redux/modules/auth';

import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';

import {API as SU_API} from 'config/superuser.config';

const initialState = {
  partnersList: null,
  request: null,
  error: null,
  monthsData: 'all',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_PARTNERS_LIST:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_PARTNERS_LIST:
      return {
        ...state,
        partnersList: action.payload && action.payload.license,
        request: false,
        error: false,
      };
    case TYPES.PARTNERS_LIST_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchpartnersList = (consortiumId = null) => (
  dispatch,
  getState
) => {
  const {
    auth: {access = {}, access: {token} = {}},
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestPartnersList());
  try {
    const parentId = consortiumId || getLoggedInstituteUUID(getState());
    let endPoint = `${
      SU_API.administration.institution
    }?parent=${parentId}&show=employer`;

    //let endPoint = `${API.partners.partner_list}`;
    return fetch(endPoint, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receivePartnersList(json));
        dispatch(fetchpartnersGraphData());
      })
      .catch(err => {
        console.log(err);
        dispatch(handlePartnersListError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handlePartnersListError(err));
  }
};
