import React, {Component} from 'react';

import {Row, Col, Progress, Select} from 'antd';
import {isString, isArray, isObject, isEmpty} from 'core';

const {Option} = Select;

const getMaxValue = valuesArray =>
  valuesArray.reduce((max, val) => (val > max ? val : max), valuesArray[0]);

const prepareDatafromArray = data => {
  let updateObj = {};
  data &&
    data.forEach(({name, institution_uuid, count}, index) => {
      updateObj = Object.assign({}, updateObj, {
        [`${name}+idx-${index}`]: count,
      });
    });
  return updateObj;
};

class ProgressbarContainer extends Component {
  state = {
    sortBy: 'interest',
    filterBy: 'all',
  };

  onSortbyChange = sortBy => {
    this.setState({
      sortBy,
    });
  };

  onFilterbyChange = filterBy => {
    this.setState({
      filterBy,
    });
  };

  sortObject = receiveData => {
    let data = receiveData;
    if (receiveData && isArray(receiveData)) {
      data = prepareDatafromArray(receiveData);
    }

    const {sortBy} = this.state;
    if (data && sortBy === 'interest') {
      const sortable = [];
      for (let val in data) {
        sortable.push([val, data[val]]);
      }
      sortable.sort((a, b) => b[1] - a[1]);
      const objSorted = {};
      sortable.forEach(item => (objSorted[item[0]] = item[1]));
      return objSorted;
    } else if (data && sortBy === 'title') {
      return Object.keys(data)
        .sort()
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: data[key],
          }),
          {}
        );
    }
  };

  render() {
    const {
      data,
      emptyMessage,
      sortingOptions,
      displayDataNestedObj,
      hideFilter,
    } = this.props;
    const {sortBy, filterBy} = this.state;
    const isDataArray = data && isArray(data);

    let filteredData = data;

    /// check for data if Array ......
    if (data && isDataArray) {
      filteredData = prepareDatafromArray(data);
    }

    if (displayDataNestedObj && data && filterBy !== 'all') {
      filteredData = {[filterBy]: data[filterBy]};
    }

    const clusters = displayDataNestedObj && data && Object.keys(data);
    let newDataObj = {};
    displayDataNestedObj &&
      data &&
      Object.keys(filteredData).forEach(key => {
        let modifySubObj = {};
        Object.keys(filteredData[key]).forEach(subKey => {
          modifySubObj[`${subKey}-key.${key}`] = filteredData[key][subKey];
        });
        newDataObj = {...newDataObj, ...modifySubObj};
      });

    const orignalData = displayDataNestedObj ? newDataObj : filteredData;

    let LIST_DATA = sortBy ? this.sortObject(orignalData) : orignalData;
    const valuesArray = (LIST_DATA && Object.values(LIST_DATA)) || [];
    const MAX_VALUE = getMaxValue(valuesArray);
    const newName = data => {
      let idx =
        data && isString(data) && data.indexOf(isDataArray ? '+idx-' : '-key.');
      return data && idx && data.substr(0, idx);
    };

    /**
  [
            {
              institution_uuid: 'eeteroj-rety-urtutr-u',
              name: 'Testing',
              count: 0,
            },
            {
              institution_uuid: 'uriweiten-sdfnsdf-rter',
              name: 'Testing',
              count: 1,
            },
            {
              institution_uuid: 'uriw-rtr-eiten-sdfnsdf-rter',
              name: 'Testing',
              count: 3,
            },
            {
              institution_uuid: 'uri-rr-weiten-sdfnsdf-rter',
              name: 'Testing',
              count: 4,
            },
          ]
 */

    return (
      <>
        <div className="progress-container">
          <div className="progress-filters">
            {sortingOptions && (
              <span className="filter sort">
                Sort By:
                <Select
                  defaultValue={sortBy}
                  style={{width: 120}}
                  onChange={this.onSortbyChange}>
                  {sortingOptions.map(({sortby, title}, idx) => (
                    <Option value={sortby} key={`${idx}-sort`}>
                      {title}
                    </Option>
                  ))}
                </Select>
              </span>
            )}
            {displayDataNestedObj && !hideFilter && clusters && (
              <span className="filter">
                Filter by Cluster:
                <Select
                  defaultValue={filterBy}
                  style={{width: 200}}
                  onChange={this.onFilterbyChange}>
                  <Option value="all">All</Option>
                  {clusters.map((cluster, idx) => (
                    <Option value={cluster} key={`${idx}-filter`}>
                      {cluster}
                    </Option>
                  ))}
                </Select>
              </span>
            )}
          </div>
          <div className="progress-list">
            {(LIST_DATA &&
              isObject(LIST_DATA) &&
              !isEmpty(LIST_DATA) &&
              Object.keys(LIST_DATA).map((name, idx) => {
                // 115 should be the highest count Number
                const calculatedPercentage = (
                  (LIST_DATA[name] / MAX_VALUE) *
                  100
                ).toFixed(3);
                return (
                  <Row
                    key={`programList-${idx}`}
                    type="flex"
                    gutter={10}
                    className="progress-bar">
                    <Col xs={24} sm={24} md={24} lg={4} className="text-right">
                      {displayDataNestedObj || isDataArray
                        ? newName(name)
                        : name}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={18}>
                      <Progress
                        percent={parseFloat(calculatedPercentage)}
                        className="progress-orange"
                        showInfo={false}
                      />
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={2}
                      className="font-size-16">
                      {LIST_DATA[name]}
                    </Col>
                  </Row>
                );
              })) || <div className="no-data-to-display">{emptyMessage}</div>}
          </div>
        </div>
      </>
    );
  }
}

export default ProgressbarContainer;
