import React from 'react';
import AllCourseList from '../AllCourseList';

export default function ElectiveCourseList() {
  const data = [
    {
      elective_id: 'Elective',
      elective_name: 'Elective',
      units: 0,
      course_type: 'elective',
    },
  ];
  return (
    <AllCourseList
      isElectiveOnly
      electiveCourse={data}
      title="Electives"
      disableCollapse
    />
  );
}
