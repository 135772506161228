import * as TYPES from './constants';
import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import {requestEventList, handleError, receiveEventList} from './actions';

const initialState = {
  data: null,
  request: false,
  error: false,
  filter: 'personal',
};
const sortEvents = events => {
  return (
    events &&
    Array.isArray(events) &&
    events.sort(function(a, b) {
      return new Date(a.start_date) - new Date(b.start_date);
    })
  );
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_EVENT_LIST:
      return {
        ...state,
        request: true,
        filter: 'personal',
      };
    case TYPES.RECEIVE_EVENT_LIST:
      return {
        ...state,
        data: sortEvents(action.events),
        request: false,
      };
    case TYPES.REQUEST_EVENT_LIST_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.EVENT_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    default:
      return state;
  }
};

const monthNames = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

export const sortByMonth = data =>
  data.sort((a, b) => monthNames[a[0]] - monthNames[b[0]]);

export const eventsData = events =>
  Object.entries(events).map(events => [
    events[0],
    sortByMonth(Object.entries(events[1])),
  ]);

export const fetchEventList = () => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
      srm_user_uuid,
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
  };
  dispatch(requestEventList());
  try {
    return fetch(API.outreach.event, config)
      .then(response => response.json())
      .then(json => {
        if (json.detail) {
          dispatch(handleError(json.detail));
        } else {
          // dispatch(setFilter());
          dispatch(receiveEventList(json));
        }
      });
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const getAllEvents = ({calendar: {data}}) => data;
