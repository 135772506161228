export const isAccessTokenExpired = ({access: {exp = null} = {}}) =>
  exp && 1000 * exp - new Date().getTime() < 60000;

export const loadTokens = () => {
  let auth;
  try {
    auth = localStorage.getItem('auth');
    if (auth && isAccessTokenExpired(auth)) {
      auth = null;
    }
  } catch (err) {
    console.log(err);
  }
  return auth ? JSON.parse(auth) : null;
};

export function getBase() {
  const pathname = window.location.pathname;
  const firstSubpath = pathname.split('/')[1];
  return firstSubpath;
}

export function getBaseURL() {
  let baseURL = '';
  const base = getBase();
  const auth = loadTokens();
  if (
    auth &&
    auth.user_portal === 'super_user' &&
    !(base === 'admin' || base === '')
  ) {
    baseURL = base;
    return baseURL;
  }
  if (auth && auth.user_portal === 'super_user') {
    baseURL = 'admin';
  } else {
    baseURL = base;
  }
  return baseURL;
}

export function getXToken() {
  const auth = loadTokens();
  const base = getBase();
  const headers = {};
  if (
    auth &&
    auth.user_portal === 'super_user' &&
    !(base === 'admin' || base === '')
  ) {
    headers['X-Origin'] = getBase();
  }
  if (!((auth && auth.user_portal === 'super_user') || base === 'admin')) {
    headers['X-Origin'] = getBase();
  }
  return headers;
}
