import * as TYPES from './constants';

// theme
export const changeAppThemeMode = data => ({
  type: TYPES.CHANGE_APP_THEME_MODE,
  payload: data,
});
export const toggleAppDrawer = data => ({
  type: TYPES.TOGGLE_APP_DRAWER,
  payload: data,
});

//SRM Super User License Types
export const requestSuperUserLicenseTypes = () => ({
  type: TYPES.REQUEST_SUPER_USER_LICENSE_TYPES,
});
export const receiveSuperUserLicenseTypes = json => ({
  type: TYPES.RECEIVE_SUPER_USER_LICENSE_TYPES,
  payload: json,
});
export const handleSuperUserLicenseTypesError = err => ({
  type: TYPES.SUPER_USER_LICENSE_TYPES_ERROR,
  err,
});

// send message

export const sendMessageOpen = data => ({
  type: TYPES.SEND_MESSAGE_OPEN,
  payload: data,
});
export const sendMessageClose = data => ({
  type: TYPES.SEND_MESSAGE_CLOSE,
  payload: data,
});
