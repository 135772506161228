import React, {Component} from 'react';
import {Input, Select} from 'antd';
import {isObject, isEmpty, isString} from 'core';
import './style.css';

const Option = Select.Option;

class PhoneExtInput extends Component {
  state = {
    country_code: '+1',
    phone_no: '',
    phone_extension: '',
  };

  componentDidUpdate = ({value}) => {
    const {value: newValue} = this.props;
    if (newValue !== value) {
      this.updatePhone(newValue);
    }
  };

  componentDidMount() {
    this.updatePhone(this.props.value);
  }

  updatePhone = value => {
    const {extNotRequired} = this.props;
    let country_code = '+1';
    let phone_extension = '';
    let phone_no = '';
    if (value && isObject(value) && !isEmpty(value)) {
      phone_extension = value.phone_extension || '';
      if (value.phone_no && value.phone_no !== '') {
        const phoneValue = value.phone_no.trim();
        phone_no =
          (phoneValue && phoneValue.startsWith('+1') && phoneValue.substr(2)) ||
          (phoneValue !== '+1' && phoneValue) ||
          '';
        //this is not required for a time being because we are having +1 country code only
        //country_code = phoneValue.replace(phone_no, ' ').trim() || '+1';
      }
    }
    if (value && isString(value) && value !== '' && extNotRequired) {
      const phoneValue = value.trim();
      phone_no =
        (phoneValue && phoneValue.startsWith('+1') && phoneValue.substr(2)) ||
        (phoneValue !== '+1' && phoneValue) ||
        '';
      //country_code = phoneValue.replace(phone_no, ' ').trim() || '+1';
    }

    this.setState({
      country_code,
      phone_no,
      phone_extension,
    });
  };

  onPhoneChange = e => {
    const {value} = e.target;
    this.setState({phone_no: value}, () => {
      this.triggerChange();
    });
  };

  onExtChange = e => {
    const {value} = e.target;
    this.setState({phone_extension: value}, () => {
      this.triggerChange();
    });
  };

  triggerChange = () => {
    const {onChange, extNotRequired} = this.props;
    const {country_code, phone_no, phone_extension} = this.state;
    if (onChange) {
      const value = phone_no ? country_code + phone_no : '';
      if (extNotRequired) {
        onChange(value);
        return;
      }
      onChange({
        phone_no: value,
        phone_extension,
        country_code,
      });
    }
  };

  render() {
    const {country_code, phone_no, phone_extension} = this.state;
    const {disabled, extNotRequired} = this.props;
    const inputBeforePhone = (
      <Select disabled={disabled} value={country_code}>
        <Option value="+1">+1</Option>
      </Select>
    );

    const inputAfterPhone =
      (!extNotRequired && (
        <>
          <span className="label"> Ext.</span>
          <Input
            disabled={disabled}
            maxLength={6}
            onChange={this.onExtChange}
            value={phone_extension}
          />
        </>
      )) ||
      null;
    return (
      <div className="phone-with-ext">
        <Input
          addonBefore={inputBeforePhone}
          addonAfter={inputAfterPhone}
          disabled={disabled}
          value={phone_no}
          onChange={this.onPhoneChange}
          maxLength={10}
        />
      </div>
    );
  }
}

export default PhoneExtInput;
