import {CONSORTIUM_ROUTES} from './routes';
const SRM = process.env.REACT_APP_SRM;

export const API = {
  administration: {
    institution: `${SRM}v1/super-user/institute/`,
    consortiumStates: `${SRM}org_state_list`,
    allConsortiums: `${SRM}institute/?get_all_rop=true`,
    allInstitutes: `${SRM}institute/?get_all_institutes=true`,
  },
};
//?student_uuid=
export const programTypes = [
  {
    icon: 'icon-apprenticeship',
    name: 'Apprenticeships',
  },
  {
    icon: 'icon-internship',
    name: 'Internships',
  },
];

export const defautHomePage = '/';

export const noOfAdminCardInPage = 12;
export const DefaultPageSize = 12;
export const PageStart = 1;

export const jobsSorters = [
  {
    key: 'asc',
    value: 'A to Z',
  },
  {
    key: 'desc',
    value: 'Z to A',
  },
];

export const eventAlert = {
  'Same Time': '0',
  '5 Minutes Before': '-300',
  '10 Minutes Before': '-600',
  '15 Minutes Before': '-900',
  '30 Minutes Before': '-1800',
  '1 Hours Before': '-3600',
  '2 Hours Before': '-7200',
  '1 Day Before': '-86400',
  '2 Day Before': '-172800',
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const notificationSettingsData = [
  {
    title: 'New Event',
    data: [
      {
        label: 'From Community',
        key: 'alert_from_community',
      },
    ],
  },
  {
    title: 'Event Alerts',
    data: [
      {
        label: 'From Me',
        key: 'alert_from_me',
      },
      {
        label: 'From Community',
        key: 'new_from_community',
      },
    ],
  },
  {
    title: 'Event Responses',
    data: [
      {
        label: 'Survey Responses',
        key: 'response_from_survey',
      },
    ],
  },
];

export const profileMenu = [
  {
    title: 'Edit Profile',
    key: 'edit_profile',
  },
  {
    title: 'Change Password',
    key: 'change_password',
  },
  {
    title: 'Notification Settings',
    key: 'notifications',
  },
  {
    title: 'About Us',
    key: 'about_us',
  },
  {
    title: 'Log Out',
    key: 'log_out',
  },
];

export const homePageMenu = [
  {
    title: 'Home',
    imgSrc: 'icon-Home icon',
    key: 'Home',
    mainMenu: true,
    path: CONSORTIUM_ROUTES.HOME.DASHBOARD,
  },
  {
    title: 'Administration',
    imgSrc: 'icon-Profile icon',
    key: 'Administration',
    content: [
      {
        submenuTitle: 'User management',
        path: CONSORTIUM_ROUTES.ADMINISTRATION.USER_MANAGEMENT.USERS,
        key: 'user_management',
      },
      {
        submenuTitle: 'Institution management',
        path:
          CONSORTIUM_ROUTES.ADMINISTRATION.INSTITUTION_MANAGEMENT.INSTITUTIONS,
        key: 'institution_management',
      },
    ],
  },
  {
    title: 'Outreach',
    imgSrc: 'icon-outreach-new icon',
    key: 'outreach',
    content: [
      {
        submenuTitle: 'Calendar',
        path: CONSORTIUM_ROUTES.OUTREACH.CALENDER,
        key: 'outreach_calendar',
      },
      {
        submenuTitle: 'Events List',
        path: CONSORTIUM_ROUTES.OUTREACH.EVENTS,
        key: 'outreach_event_list',
      },
    ],
  },
  {
    title: 'Partners',
    imgSrc: 'icon-partners icon',
    key: 'partners',
    content: [
      {
        submenuTitle: 'Dashboard',
        path: CONSORTIUM_ROUTES.PARTNERS.DASHBOARD,
        key: 'partners_dashboard',
      },
      {
        submenuTitle: 'List of Partners',
        path: CONSORTIUM_ROUTES.PARTNERS.PARTNERS_LIST,
        key: 'partners_list',
      },
    ],
  },
  // {
  //   title: 'Reports',
  //   imgSrc: 'icon-reports icon',
  //   key: 'reports',
  //   mainMenu: true,
  //   path: CONSORTIUM_ROUTES.REPORTS.DASHBOARD,
  // },
];
