import * as TYPES from './constants';

// get Career Interest List
export const requestCareerInterestList = () => ({
  type: TYPES.CAREER_INTEREST_LIST_REQUEST,
});
export const receiveCareerInterestList = json => ({
  type: TYPES.CAREER_INTEREST_LIST_RECEIVE,
  payload: json,
});
export const handleCareerInterestListError = err => ({
  type: TYPES.CAREER_INTEREST_LIST_ERROR,
  err,
});
export const clearCareerInterestList = () => ({
  type: TYPES.CLEAR_CAREER_INTEREST_LIST,
});
// get Program Enrollments
export const requestProgramEnrollmentsList = () => ({
  type: TYPES.PROGRAM_ENROLLMENT_LIST_REQUEST,
});
export const receiveProgramEnrollmentsList = json => ({
  type: TYPES.PROGRAM_ENROLLMENT_LIST_RECEIVE,
  payload: json,
});
export const handleProgramEnrollmentsListError = err => ({
  type: TYPES.PROGRAM_ENROLLMENT_LIST_ERROR,
  err,
});
export const clearProgramEnrollmentsList = () => ({
  type: TYPES.CLEAR_PROGRAM_ENROLLMENT_LIST,
});
// GoSurvey Results
export const requestGoSurveyResults = () => ({
  type: TYPES.GO_SURVEY_RESULTS_REQUEST,
});
export const receiveGoSurveyResults = json => ({
  type: TYPES.GO_SURVEY_RESULTS_RECEIVE,
  payload: json,
});
export const handleGoSurveyResultsError = err => ({
  type: TYPES.GO_SURVEY_RESULTS_ERROR,
  err,
});
export const clearGoSurveyResults = () => ({
  type: TYPES.CLEAR_GO_SURVEY_RESULTS,
});
// Enrolled List
export const requestEnrolledList = () => ({
  type: TYPES.ENROLLED_LIST_REQUEST,
});
export const receiveEnrolledList = json => ({
  type: TYPES.ENROLLED_LIST_RECEIVE,
  payload: json,
});
export const handleEnrolledListError = err => ({
  type: TYPES.ENROLLED_LIST_ERROR,
  err,
});
export const clearEnrolledList = () => ({
  type: TYPES.CLEAR_ENROLLED_LIST,
});

// Enrolled Overview
export const requestProgramDetails = () => ({
  type: TYPES.PROGRAM_DETAILS_REQUEST,
});
export const receiveProgramDetails = json => ({
  type: TYPES.PROGRAM_DETAILS_RECEIVE,
  payload: json,
});
export const handleProgramDetailsError = err => ({
  type: TYPES.PROGRAM_DETAILS_ERROR,
  err,
});
export const clearProgramDetails = () => ({
  type: TYPES.CLEAR_PROGRAM_DETAILS,
});
