import React from 'react';
import {formatPhoneNumber} from 'core/utils';

const OverviewInfo = ({student_profile}) => {
  const {address, phone_number, email} = student_profile || {};
  const {city = '', state = ''} = address || {};

  let location = null;
  if (city && state) location = city + ', ' + state;

  const formattedPhoneNumber = formatPhoneNumber(phone_number);

  return (
    <>
      <div className="profile-overviewinfo">
        {email && (
          <>
            <div className="sub-panel-title">Email Address:</div>
            <div className="sub-panel-value">{email}</div>
          </>
        )}
      </div>
      <div className="profile-overviewinfo">
        {formattedPhoneNumber && (
          <>
            <div className="sub-panel-title">Phone Number:</div>
            <div className="sub-panel-value">{formattedPhoneNumber}</div>
          </>
        )}
      </div>
    </>
  );
};

export default OverviewInfo;
