import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Checkbox} from 'antd';

const StudentHeading = ({studentColsLabel, onAllStudentSelect, allselect}) => (
  <div>
    <Row className="data-table-header no-border">
      <div>
        <Col span={1}>
          <Checkbox
            style={{textAlign: 'center'}}
            value="all"
            checked={allselect}
            onChange={onAllStudentSelect}
          />
        </Col>
      </div>
      <Col span={5}>
        <div>Name</div>
      </Col>

      <Col span={6}>
        <div>Email</div>
      </Col>
      <Col span={6}>
        <div>Mobile</div>
      </Col>
      {/* {studentColsLabel.map((item, i) => {
        return(
        <Col span={6} key={i}>
          <div>{item.demo_request_field.title}</div>
        </Col>
      )})} */}

      <Col span={6}>
        <div>Action</div>
      </Col>
    </Row>
  </div>
);

StudentHeading.propTypes = {
  studentColsLabel: PropTypes.array,
};
StudentHeading.defaultProps = {
  studentColsLabel: ['Name', 'Email', 'Phone #', 'Action'],
};

export default StudentHeading;
