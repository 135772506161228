import * as TYPES from './constants';
import {
  requestOpportunities,
  receiveOpportunities,
  OpportunitiesError,
  clearOpportunities,
  receiveOpportunityDetails,
  clearOpportunityDetails,
} from './actions';

import {JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import {API} from 'config/businesspartner.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_OPPORTUNITIES:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_OPPORTUNITIES:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.RECEIVE_OPPORTUNITY_DETAILS:
      const d = (action.payload && action.payload[0]) || [];
      if (d) {
        d.skills = d.skills.map(i => i.skills);
      }
      return {
        ...state,
        opportunityDetails: d,
        request: false,
        error: false,
      };
    case TYPES.OPPORTUNITIES_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.CLEAR_OPPORTUNITIES:
      return initialState;
    case TYPES.OPPORTUNITY_DETAILS_CLEAR:
      return {
        ...state,
        opportunityDetails: null,
        data: null,
        request: false,
        error: false,
      };
    default:
      return state;
  }
};

export const fetchAllOpportunities = (
  filterBy,
  filterByStatus,
  pageSize = 9,
  pageNo = 1,
  searchTerm
) => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestOpportunities());
  try {
    let endpoint = `${
      API.administration.opportunities
    }?page_size=${pageSize}&page=${pageNo}`;
    if (filterBy) {
      endpoint += `&filter=${filterBy}`;
    }
    if (filterByStatus) {
      endpoint += `&status=${filterByStatus}`;
    }
    if (searchTerm) {
      endpoint += `&search=${searchTerm}`;
    }
    return fetch(endpoint, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveOpportunities(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(OpportunitiesError(err));
      });
  } catch (error) {
    console.log(error);
    dispatch(OpportunitiesError(error));
  }
};

export const fetchOpportunityDetails = opportunity_id => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestOpportunities());
  try {
    let endpoint = `${API.administration.opportunities}${opportunity_id}`;
    return fetch(endpoint, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveOpportunityDetails(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(OpportunitiesError(err));
      });
  } catch (error) {
    console.log(error);
    dispatch(OpportunitiesError(error));
  }
};

export const clearOpportunitiesDetails = () => dispatch => {
  dispatch(clearOpportunityDetails());
};

export const clearAllOpportunities = () => dispatch => {
  dispatch(clearOpportunities());
};
