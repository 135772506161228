import * as TYPES from './constants';

const initialState = {
  mode: 'light',
  openMenuBar: true,
};

const theme = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CHANGE_APP_THEME_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case TYPES.TOGGLE_APP_DRAWER:
      return {
        ...state,
        openMenuBar:
          action.payload !== null && action.payload !== undefined
            ? action.payload
            : !state.openMenuBar,
      };
    default:
      return state;
  }
};

export default theme;
