import React from 'react';
import {Text, View} from '@react-pdf/renderer';
import {formatViewDate} from 'core/utils';
import List, {Item} from './List';
import Divider from './Divider';

export default ({styles, studentProfile}) => {
  const {work_exp = []} = studentProfile;
  return (
    <>
      <View style={styles.header}>
        <Text>Work Experience</Text>
      </View>
      <View style={styles.content}>
        {!work_exp ||
          !Array.isArray(work_exp) ||
          (work_exp.length === 0 && (
            <Text style={styles.value}>No work experience added.</Text>
          ))}
        {work_exp &&
          Array.isArray(work_exp) &&
          work_exp.length > 0 &&
          work_exp.map((exp, exp_idx) => {
            return (
              <View key={`work-exp-${exp_idx}`} style={styles.list}>
                {exp.company_name && exp.job_location && (
                  <View style={styles.inline}>
                    <Text style={styles.boldLabel}>
                      {exp.company_name}
                      {' - '}
                      <Text style={styles.bold}>{exp.job_location}</Text>
                    </Text>
                  </View>
                )}
                {exp.start_date && (exp.is_present || exp.end_date) && (
                  <View style={styles.inline}>
                    <Text style={styles.value}>
                      {formatViewDate(exp.start_date)}
                      {' - '}
                      {exp.is_present
                        ? 'Present'
                        : formatViewDate(exp.end_date)}
                    </Text>
                  </View>
                )}
                {exp.job_title && (
                  <View style={styles.inline}>
                    <Text style={styles.boldLabel}>{exp.job_title}</Text>
                  </View>
                )}
                <View style={styles.inline}>
                  <List>
                    {exp.job_responsibilities &&
                      Array.isArray(exp.job_responsibilities) &&
                      exp.job_responsibilities.length > 0 &&
                      exp.job_responsibilities.map(
                        ({job_responsibilities}, idx) =>
                          job_responsibilities && (
                            <Item key={idx}>{job_responsibilities}</Item>
                          )
                      )}
                  </List>
                </View>
                {exp_idx !== work_exp.length - 1 ? <Divider /> : null}
              </View>
            );
          })}
      </View>
    </>
  );
};
