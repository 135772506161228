import Client from 'aws-appsync';
import AppSync from './AppSync';

export const client = new Client({
  url: AppSync.ApiUrl,
  region: AppSync.Region,
  auth: {
    type: AppSync.AuthMode,
    apiKey: AppSync.ApiKey,
  },
});
