import * as TYPES from './constants';
import {
  requestLinkedInDetails,
  recieveLinkedInDetails,
  linkedInDetailsError,
} from './actions';
import {API} from 'config/businesspartner.config';
import {JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {
  job_title_request: false,
  skill_name_request: false,
  degree_name_request: false,
  field_of_study_request: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.LINKEDIN_DETAILS_REQUEST:
      return {
        ...state,
        error: false,
        ...action.payload,
      };
    case TYPES.LINKEDIN_DETAILS_RECEIVE:
      return {
        ...state,
        error: false,
        ...action.payload,
      };
    case TYPES.LINKEDIN_DETAILS_ERROR:
      return {
        ...state,
        error: true,
      };
    case TYPES.LINKEDIN_DETAILS_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const fetchLinkedInDetails = (key, value) => (dispatch, getState) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestLinkedInDetails());
  try {
    return fetch(
      `${API.administration.linkedin_details}?${key}=${value}`,
      config
    )
      .then(response => response.json())
      .then(json => {
        dispatch(
          recieveLinkedInDetails({[key]: json, [`${key}_request`]: false})
        );
      })
      .catch(err => {
        dispatch(linkedInDetailsError(err));
      });
  } catch (err) {
    dispatch(linkedInDetailsError(err));
  }
};
