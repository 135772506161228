import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import * as TYPES from './constants';
import {
  requestCourseManagementCreditStatus,
  receiveCourseManagementCreditStatus,
  handleCourseManagementCreditStatusError,
} from './actions';
const initialState = {
  request: null,
  data: [],
  error: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_COURSE_MANAGEMENT_CREDIT_STATUS:
      return {
        ...state,
        request: true,
      };

    case TYPES.RECEIVE_COURSE_MANAGEMENT_CREDIT_STATUS:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };

    case TYPES.COURSE_MANAGEMENT_CREDIT_STATUS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };

    default:
      return state;
  }
};

export const fetchCreditStatus = () => (dispatch, getState) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestCourseManagementCreditStatus());
  try {
    return fetch(`${API.course_management.course_credit_status}`, config)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Fetching Credit Status Api Fails');
      })
      .then(json => {
        dispatch(receiveCourseManagementCreditStatus(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleCourseManagementCreditStatusError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleCourseManagementCreditStatusError(err));
  }
};
