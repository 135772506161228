import * as TYPES from './constants';
import {API} from 'config/employer.config';
import {JWT_HEADER, JSON_HEADER} from 'config/srm.config';
import {
  receiveSavedSearches,
  requestSavedSearches,
  handleSavedSearchesError,
} from './actions';
import {Services} from 'core';

const services = new Services();

const initialState = {
  data: null,
  error: false,
  request: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SAVED_SEARCH_LIST_REQUEST:
      return {
        ...state,
        request: true,
      };
    case TYPES.SAVED_SEARCH_LIST_RECEIVE:
      return {
        ...state,
        data: action.payload,
        request: false,
      };
    case TYPES.SAVED_SEARCH_LIST_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchSavedSearches = () => (dispatch, getState) => {
  const {
    auth: {
      access = {},
      access: {token},
    },
    instance: {
      data: {institution_id},
    },
  } = getState();
  const cognitoID = access['cognito:username'];

  const config = {
    mode: 'cors',
    method: 'GET',
    cache: 'default',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
  };
  dispatch(requestSavedSearches());
  const URI = `${
    API.administration.saved_searches
  }?srm_user_uuid=${cognitoID}&institution_uuid=${institution_id}&search_area=talent_exchange`;
  try {
    return fetch(URI, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveSavedSearches(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleSavedSearchesError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleSavedSearchesError(err));
  }
};

export const saveSearch = (payload, onSuccess, onError) => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access = {},
      access: {token},
    },
    instance: {
      data: {institution_id},
    },
  } = getState();
  const cognitoID = access['cognito:username'];

  const body = {
    srm_user_uuid: cognitoID,
    institution_uuid: institution_id,
    search_area: 'talent_exchange',
    ...payload,
  };
  dispatch(requestSavedSearches());
  const URI = API.administration.saved_searches;
  try {
    services
      .createUpdateRecord(token, URI, body, 'POST')
      .then(res => {
        if (res) {
          dispatch(receiveSavedSearches(res));
          onSuccess && onSuccess();
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(handleSavedSearchesError(err));
        onError && onError();
      });
  } catch (err) {
    console.log(err);
    dispatch(handleSavedSearchesError(err));
    onError && onError();
  }
};

export const deleteSavedSearch = (searchId, onSuccess, onError) => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestSavedSearches());

  const URI = `${API.administration.saved_searches}/${searchId}`;
  try {
    services
      .deleteRecord(token, URI)
      .then(res => {
        if (res) {
          onSuccess && onSuccess();
          dispatch(fetchSavedSearches());
        }
      })
      .catch(err => {
        console.log(err);
        onError && onError();
      });
  } catch (err) {
    console.log(err);
    onError && onError();
  }
};
