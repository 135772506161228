import React from 'react';
import {Row, Col, Select} from 'antd';

import {CustomCollapse, Stats} from 'core';

import PanelHOC from './panelHOC';
import TimelineContainer from './timelineContainer';
const {Option} = Select;
/**
const statsData = [
  {
    title: 'Started Pathway',
    count: 14,
    color: 'light-brown',
  },
  {
    title: 'Completed Application',
    count: 23,
    color: 'green',
  },
  {
    title: 'Completed Financial Aid',
    count: 23,
    color: 'dark-green',
  },
  {
    title: 'Completed Onboarding',
    count: 15,
    color: 'blue',
  },
  {
    title: 'Completed Pathways',
    count: 14,
    color: 'light-green',
  },
];
*/

const graphPointStyling = {
  pointBackgroundColor: '#ffffff',
  pointRadius: 5,
  pointHoverRadius: 6,
};
const STATIC_TIMELINE_DATA = {
  labels: [],
  datasets: [
    {
      key: 'started_pathway',
      label: 'Started Pathway',
      data: [],
      borderColor: 'rgb(215, 117, 32)',
      backgroundColor: 'rgb(215, 117, 32)',
      pointHoverBackgroundColor: 'rgb(215, 117, 32)',
      ...graphPointStyling,
    },
    {
      key: 'completed_onboarding',
      label: 'Completed Onboarding',
      data: [],
      borderColor: 'rgb(26, 178, 102)',
      backgroundColor: 'rgb(26, 178, 102)',
      pointHoverBackgroundColor: 'rgb(26, 178, 102)',
      ...graphPointStyling,
    },
    {
      key: 'completed_pathway',
      label: 'Completed Pathway',
      data: [],
      borderColor: 'rgb(32, 122, 108)',
      backgroundColor: 'rgb(32, 122, 108)',
      pointHoverBackgroundColor: 'rgb(32, 122, 108)',
      ...graphPointStyling,
    },
    // {
    //   key: 'completed_application',
    //   label: 'Completed Application',
    //   data: [],
    //   borderColor: 'rgb(26, 178, 102)',
    //   backgroundColor: 'rgb(26, 178, 102)',
    //   pointHoverBackgroundColor: 'rgb(26, 178, 102)',
    //   ...graphPointStyling,
    // },
    // {
    //   key: 'completed_financial_aid',
    //   label: 'Completed Financial Aid',
    //   data: [],
    //   borderColor: 'rgb(32, 122, 108)',
    //   backgroundColor: 'rgb(32, 122, 108)',
    //   pointHoverBackgroundColor: 'rgb(32, 122, 108)',
    //   ...graphPointStyling,
    // },
    {
      key: 'students_enrolled_in_job_training_program',
      label: 'Students Enrolled in Job Training Program',
      data: [],
      borderColor: 'rgb(72, 175, 247)',
      backgroundColor: 'rgb(72, 175, 247)',
      pointHoverBackgroundColor: 'rgb(72, 175, 247)',
      ...graphPointStyling,
    },
  ],
};

/**
 * rgb(72, 175, 247)
 * rgb(114, 233, 61)
const DUMMY_DATA = {
  started_pathway: {
    timeline: {
      2019: {
        Jan: 0,
        Feb: 5,
        Mar: 11,
        Apr: 13,
        May: 16,
        Jun: 17,
        Jul: 20,
        Aug: 25,
        Sep: 25,
        Oct: 30,
        Nov: 35,
        Dec: 31,
      },
      2020: {
        Jan: 0,
        Feb: 10,
        Mar: 22,
        Apr: 25,
        May: 32,
        Jun: 34,
        Jul: 39,
        Aug: 42,
        Sep: 50,
        Oct: 55,
        Nov: 60,
        Dec: 62,
      },
    },
  },
  completed_application: {
    timeline: {
      2020: {
        Jan: 2,
        Feb: 12,
        Mar: 15,
        Apr: 18,
        May: 20,
        Jun: 25,
        Jul: 30,
        Aug: 33,
        Sep: 40,
        Oct: 35,
        Nov: 40,
        Dec: 45,
      },
      2021: {
        Jan: 20,
        Feb: 25,
        Mar: 30,
        Apr: 35,
        May: 40,
        Jun: 35,
        Jul: 40,
        Aug: 45,
        Sep: 50,
        Oct: 55,
        Nov: 60,
        Dec: 50,
      },
    },
  },
  completed_financial_aid: {
    timeline: {
      2020: {
        Jan: 1,
        Feb: 18,
        Mar: 20,
        Apr: 30,
        May: 25,
        Jun: 40,
        Jul: 50,
        Aug: 55,
        Sep: 40,
        Oct: 43,
        Nov: 45,
        Dec: 50,
      },
    },
  },
  completed_onboarding: {
    timeline: {
      2020: {
        Jan: 0,
        Feb: 8,
        Mar: 15,
        Apr: 20,
        May: 23,
        Jun: 27,
        Jul: 30,
        Aug: 34,
        Sep: 37,
        Oct: 40,
        Nov: 45,
        Dec: 50,
      },
    },
  },
  completed_pathway: {
    timeline: {
      2020: {
        Jan: 4,
        Feb: 14,
        Mar: 24,
        Apr: 34,
        May: 44,
        Jun: 54,
        Jul: 64,
        Aug: 74,
        Sep: 84,
        Oct: 94,
        Nov: 100,
        Dec: 8,
      },
    },
  },
};
*/

const calculateStudentSuccessStats = data => {
  if (data && data.student_pathway && data.student_pathway.student_success) {
    const {
      started_pathway,
      completed_application,
      completed_financial_aid,
      completed_onboarding,
      completed_pathway,
      students_enrolled_in_job_training_program,
    } = data.student_pathway.student_success;
    return [
      {
        title: 'Started Pathway',
        count: started_pathway || 0,
        color: 'light-brown',
      },
      {
        title: 'Completed Onboarding',
        count: completed_onboarding || 0,
        color: 'green',
      },
      {
        //completed_pathway || 0
        title: 'Completed Pathway',
        count: '-',
        color: 'dark-green',
      },
      {
        //completed_application || 0
        title: 'Students Enrolled in Job Training Program',
        count: students_enrolled_in_job_training_program || 0,
        color: 'blue',
      },
      // {
      //   //completed_application || 0
      //   title: 'Completed Application',
      //   count: '-',
      //   color: 'green',
      // },
      // {
      //   // completed_financial_aid || 0
      //   title: 'Completed Financial Aid',
      //   count: '-',
      //   color: 'dark-green',
      // },
    ];
  }
  return [];
};

const prepareTimelineData = data => {
  if (data && data.student_pathway && data.student_pathway.student_success) {
    const {
      started_pathway_timeline,
      completed_application_timeline,
      completed_financial_aid_timeline,
      completed_onboarding_timeline,
      completed_pathway_timeline,
      students_enrolled_in_job_training_program_timeline,
    } = data.student_pathway.student_success;
    return {
      started_pathway: {
        timeline: started_pathway_timeline || {},
      },
      completed_onboarding: {
        timeline: completed_onboarding_timeline || {},
      },
      completed_pathway: {
        timeline: completed_pathway_timeline || {},
      },
      students_enrolled_in_job_training_program: {
        timeline: students_enrolled_in_job_training_program_timeline || {},
      },
      // completed_application: {
      //   timeline: completed_application_timeline || {},
      // },
      // completed_financial_aid: {
      //   timeline: completed_financial_aid_timeline || {},
      // },
    };
  }
};

function StudentSuccess(props) {
  const {data: propData, onFilterChange} = props;
  const {
    student_pathway: {
      student_success: {
        program_of_study_list = [],
        selected_program_of_study,
      } = {},
    } = {},
  } = propData || {};
  const STUDENT_SUCCESS_STATS = calculateStudentSuccessStats(propData);
  const TIMELINE_DATA = prepareTimelineData(propData);

  const data = [
    {
      title: 'STUDENT SUCCESS',
      key: 'student_success',
      childern: (
        <div className="content">
          <Row>
            <Col span={6} className="student-success-filter">
              <span className="label">Filter by Program of Study</span>
            </Col>
            <Col span={18}>
              <Select
                defaultValue="all"
                value={selected_program_of_study || 'all'}
                onChange={onFilterChange}>
                <Option key="all" value="all" label="all">
                  All
                </Option>
                {program_of_study_list &&
                  program_of_study_list.map(({program_uuid, program_name}) => (
                    <Option
                      key={program_uuid}
                      value={program_uuid}
                      label={program_name}>
                      {program_name}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>
          <div />
          <Stats horizontaltop data={STUDENT_SUCCESS_STATS} />
          <TimelineContainer
            data={TIMELINE_DATA}
            staticData={STATIC_TIMELINE_DATA}
          />
        </div>
      ),
    },
  ];

  return (
    <CustomCollapse
      data={data}
      switchPanel={props.switchPanel}
      activeKey={props.activeKey || 'student_success'}
    />
  );
}

export default PanelHOC(StudentSuccess);
