import React from 'react';
import {Stats as MUIStats} from 'mui-core';
import {StyledContainer} from './styles';

const BusinessPartners = ({data}) => {
  const prepareBusinessPartnersStatsData = () => {
    if (data) {
      const {total_business_partners} = data;
      const statsData = [
        {
          title: 'Total Business Partners',
          count: total_business_partners,
          color: '#1AB266',
        },
      ];
      return statsData;
    }
    return [];
  };
  const usersData = prepareBusinessPartnersStatsData();
  return (
    <StyledContainer>
      {/* <StatsLabel>Users Metrics</StatsLabel> */}
      <MUIStats data={usersData} />
    </StyledContainer>
  );
};

export default BusinessPartners;
