import * as TYPES from './constants';
import {
  receiveReporterProfile,
  handleReceiveProfileError,
  setToggleReporterProfile,
} from './actions';

import {getLoggedInstituteUUID} from 'redux/modules/auth';

import {Services} from 'core';
import {API} from 'config/superuser.config';

const initialState = {
  reporterProfile: null,
  isReporterProfileExist: false,
  editMode: false,
  request: null,
  error: null,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_REPORTER_USER:
      return {
        ...state,
        isReporterProfileExist:
          action.payload &&
          //action.payload.primary_contact_1.phone &&
          //action.payload.primary_contact_1.email &&
          action.payload.physical_address_details.address_line1 &&
          action.payload.physical_address_details.city &&
          action.payload.physical_address_details.state &&
          action.payload.physical_address_details.zipcode &&
          action.payload.primary_contact_1.first_name &&
          action.payload.primary_contact_1.last_name &&
          (action.payload.logo_cloudinary || action.payload.logo),
        error: false,
        request: false,
        reporterProfile: action.payload,
      };
    case TYPES.TOGGLE_REPORTER_PROFILE:
      return {
        ...state,
        editMode: action.payload,
      };
    case TYPES.REQUEST_REPORTER_USER_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const toggleReporterProfile = status => dispatch => {
  dispatch(setToggleReporterProfile(status));
};

export const fetchReporterProfile = () => (dispatch, getState) => {
  const {
    user,
    auth: {loggedinUserId, access = {}, access: {token} = {}},
  } = getState();
  const institution_uuid = getLoggedInstituteUUID(getState());
  let endpoint = `${API.administration.institution}${institution_uuid}`;
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receiveReporterProfile(res));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleReceiveProfileError(err));
  }
};
