import React from 'react';
import {View, Text} from '@react-pdf/renderer';
import {formatViewDate} from 'core/utils';
import List, {Item} from './List';
import Divider from './Divider';

export default ({styles, education}) => {
  return (
    <View style={styles.section}>
      <Text style={styles.label}>Education</Text>
      {education && Array.isArray(education) && education.length > 0 ? (
        education.map((ed, idx) => {
          const EdComments =
            ed.comments &&
            Array.isArray(ed.comments) &&
            ed.comments.length > 0 &&
            ed.comments.filter(
              ({comments}) => comments && typeof comments === 'string'
            );
          return (
            // <View key={'edu-profile-' + idx} style={styles.inline}>
            <View key={'edu-profile-' + idx}>
              {ed.school_name && (
                <Text wrap style={styles.bold}>
                  School: <Text style={styles.value}>{ed.school_name}</Text>
                </Text>
              )}
              {ed.school_start_date && (ed.school_end_date || ed.is_present) && (
                <Text wrap style={styles.bold}>
                  Attended:
                  <Text style={styles.value}>
                    {`${formatViewDate(ed.school_start_date)} -
                    ${
                      ed.is_present
                        ? 'Present'
                        : formatViewDate(ed.school_end_date)
                    }`}
                  </Text>
                </Text>
              )}
              {ed.degree_award && (
                <Text wrap style={styles.bold}>
                  Degree/Award:
                  <Text style={styles.value}>{ed.degree_award}</Text>
                </Text>
              )}
              <Text wrap style={styles.bold}>
                {ed.area_of_focus && (
                  <>
                    Area of Focus/Major:
                    <Text style={styles.value}>{ed.area_of_focus}</Text>
                  </>
                )}
              </Text>

              {EdComments &&
                Array.isArray(EdComments) &&
                EdComments.length > 0 && (
                  <>
                    <Text style={styles.bold}>Comments:</Text>
                    <List>
                      {EdComments.map(
                        ({comments}, id) =>
                          comments && (
                            <Item key={`comment-${id}`}>
                              {comments.replaceAll('\n', ' ')}
                            </Item>
                          )
                      )}
                    </List>
                  </>
                )}
              {idx + 1 !== education.length && <Divider />}
            </View>
          );
        })
      ) : (
        <Text style={styles.value}>N/A</Text>
      )}
    </View>
  );
};
