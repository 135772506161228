import {Services} from 'core';
import * as TYPES from './constants';
import {API} from 'config/employer.config';
import {
  clearEmployerOpportunityApplicants,
  requestEmployerOpportunityApplicants,
  receiveEmployerOpportunityApplicants,
  handleEmployerOpportunityApplicantsError,
} from './actions';

const initialState = {
  data: null,
  error: null,
  request: null,
};
const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_EMPLOYER_OPPORTUNITY_APPLICANTS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_EMPLOYER_OPPORTUNITY_APPLICANTS:
      const newData = action.payload || {};
      if (newData?.skills?.length > 0) {
        newData.skills = newData.skills.map(i => i.skills);
      }
      return {
        ...state,
        error: false,
        request: false,
        data: newData,
      };
    case TYPES.EMPLOYER_OPPORTUNITY_APPLICANTS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.CLEAR_EMPLOYER_OPPORTUNITY_APPLICANTS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const fetchOpportunityApplicants = ({
  page = 1,
  pageSize = 10,
  searchKey = '',
  sort = 'date_desc',
  opportunityId = '',
}) => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestEmployerOpportunityApplicants());
  try {
    let endpoint = `${
      API.administration.business_partner_opportunity_student_list
    }?opportunity_uuid=${opportunityId}&page_size=${pageSize}&page=${page}&sort=${sort}&search=${searchKey}`;
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receiveEmployerOpportunityApplicants(res));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleEmployerOpportunityApplicantsError(err));
      });
  } catch (error) {
    console.log(error);
    dispatch(handleEmployerOpportunityApplicantsError(error));
  }
};

export const clearOpportunityApplicants = () => dispatch => {
  dispatch(clearEmployerOpportunityApplicants());
};
