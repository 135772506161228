import React, {useState, useEffect} from 'react';
import {styled} from '@mui/material/styles';
import {DataGrid} from '@mui/x-data-grid';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
  '& .MuiTablePagination-root': {
    '& p': {
      marginBottom: '0px',
    },
  },
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    '& .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: '#faf9fe',
    },
  },
}));

const StyledGridOverlay = styled('Box')(({theme}) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 1,
  margin: 1,
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay height="auto" mx="auto">
      <ContentPasteOffIcon fontSize="large" />
      <Box sx={{mt: 1.5, fontSize: '16px'}}>No Results Found</Box>
    </StyledGridOverlay>
  );
}

const CustomizedDataGrid = props => {
  const {
    loading,
    rowCount,
    page = 1,
    rows = [],
    columns = [],
    initialState,
    onPageChange,
    pageSize = 10,
    onPageSizeChange,
    onSortModelChange,
    autoHeight = true,
    pagination = true,
    disableColumnMenu = true,
    disableSelectionOnClick = true,
  } = props;
  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  // switching total_count to undefined during loading resets the page to zero.
  // To avoid this problem, we are keeping the previous value of rowCount while loading
  useEffect(
    () => {
      setRowCountState(prevRowCountState =>
        rowCount !== undefined ? rowCount : prevRowCountState
      );
    },
    [rowCount]
  );

  return (
    <StyledDataGrid
      page={page}
      rows={rows}
      loading={loading}
      columns={columns}
      pageSize={pageSize}
      filterMode="server"
      sortingMode="server"
      paginationMode="server"
      pagination={pagination}
      autoHeight={autoHeight}
      rowCount={rowCountState}
      initialState={initialState}
      onPageChange={onPageChange}
      sortingOrder={['asc', 'desc']}
      onPageSizeChange={onPageSizeChange}
      onSortModelChange={onSortModelChange}
      disableColumnMenu={disableColumnMenu}
      rowsPerPageOptions={[10, 15, 20, 30]}
      components={{
        NoRowsOverlay: CustomNoRowsOverlay,
      }}
      disableSelectionOnClick={disableSelectionOnClick}
    />
  );
};

CustomizedDataGrid.propTypes = {
  rows: PropTypes.array,
  page: PropTypes.number,
  loading: PropTypes.bool,
  columns: PropTypes.array,
  autoHeight: PropTypes.bool,
  pageSize: PropTypes.number,
  rowCount: PropTypes.number,
  pagination: PropTypes.bool,
  onPageChange: PropTypes.func,
  sortingOrder: PropTypes.array,
  initialState: PropTypes.object,
  onPageSizeChange: PropTypes.func,
  disableColumnMenu: PropTypes.bool,
  onSortModelChange: PropTypes.func,
  disableSelectionOnClick: PropTypes.bool,
};

export default CustomizedDataGrid;
