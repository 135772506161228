import {Col, Row, Form, Icon, Select, Tag, Spin} from 'antd';
import './style.css';
import React, {useEffect, useState} from 'react';
import {debounce} from 'lodash';
import {API} from 'config/businesspartner.config';
import {Services} from '../../services';
const services = new Services();
const defaultMinimumCharacter = 2;

const data = [
  {label: 'akash'},
  {label: 'acchu'},
  {label: 'akrhu'},
  {label: 'avinash'},
  {label: 'akara'},
  {label: 'akarka'},
  {label: 'adarhsa'},
];

const endpoint = `${API.administration.location_details}`;

const FormItem = Form.Item;

const LocationSearch = props => {
  // props destructure
  const {onChange, minimumCharacter, token, value} = props;
  //   state
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [locations, setLocations] = useState([]);
  //   controls
  useEffect(() => {
    if (value && Array.isArray(value));
    setLocations(value || []);
  }, []);
  const isLocationSelected = label => {
    const exists = locations && locations.filter(d => d.zipcode === label)[0];
    return exists ? true : false;
  };
  const onLocationRemove = label => {
    const newLocations = locations.filter(loc => loc.zipcode !== label);
    setLocations(newLocations);
    onLocationChange(newLocations);
  };

  const onSelect = v => {
    console.log('selc', locations, v);
    const location = data.filter(i => i.zipcode === v)[0];
    if (location) {
      if (!isLocationSelected(v)) {
        const newLocations = [...locations, location];
        setLocations(newLocations);
        onLocationChange(newLocations);
      } else {
        onLocationRemove(v);
      }
    }
  };
  const onSearch = debounce(term => {
    if (term && term.length >= (minimumCharacter || defaultMinimumCharacter)) {
      setLoading(true);
      services
        .fetchRecords(`${endpoint}?location_name=${term}`, token)
        .then(result => {
          setLoading(false);
          const {location_details} = result;
          if (location_details && Array.isArray(location_details)) {
            const data = location_details;
            setData(data);
          }
        });
    }
  }, 1300);
  const onLocationChange = newLocations => {
    if (onChange) onChange(newLocations || locations);
  };

  const options =
    data &&
    Array.isArray(data) &&
    data.map(location => {
      const isSelected = isLocationSelected(location.zipcode);
      return (
        <Select.Option
          key={location.label}
          value={location.zipcode}
          className={
            'option-selected-check' + (isSelected ? ' selected ' : '') // class styles wrote in sharedResourses/app.css
          }>
          {location.city}, {location.state_code}
          {isSelected && <Icon type="check" />}
        </Select.Option>
      );
    });
  console.log(locations, data);
  return (
    <div className="location-search filter-section">
      <div className="heading-text text-center">Filter by Locations</div>
      <Row type="flex" gutter={42} align="middle">
        <Col sm={7}>Search by Location</Col>
        <Col
          sm={17}
          className="location-search searchOccupation"
          style={{margin: '0 auto'}}>
          <FormItem>
            <>
              <Icon
                type="search"
                style={{
                  position: 'absolute',
                  zIndex: '1',
                  top: '4px',
                  left: '5px',
                }}
              />
              <Select
                showSearch
                loading={loading}
                showArrow={false}
                filterOption={false}
                defaultActiveFirstOption={false}
                allowClear={!loading}
                value={null}
                onSelect={onSelect}
                onSearch={onSearch}
                placeholder="Search"
                notFoundContent={
                  loading ? (
                    <Spin size="small" />
                  ) : (
                    'no location matchs for search query'
                  )
                }
                style={{paddingLeft: '11px'}}>
                {options}
              </Select>
            </>
          </FormItem>
        </Col>
      </Row>
      <div className="program-study-selector filter-section">
        <div className="selected-section">
          <div className="lbl-s">
            {/* {required && <span className="required">* </span>} */}
            Selected Location (s):
          </div>
          <div className="tags-s">
            {(locations &&
              Array.isArray(locations) &&
              locations.length > 0 &&
              locations.map((location, index) => (
                <Tag
                  key={'pgm-tag' + location.label}
                  onClose={() => onLocationRemove(location.zipcode)}
                  closable
                  className="occ-tag">
                  {location.city}, {location.state_code}
                </Tag>
              ))) || (
              <span
                style={{
                  position: 'relative',
                  top: '3px',
                }}>
                -
              </span>
            )}
            {/* {showErrors && selectedPrograms.length === 0 && (
            <FormItem>
              {getFieldDecorator('ss', {
                rules: [
                  {required: true, message: 'Please select the occupation'},
                ],
              })(<div style={{paddingBottom: 7}} />)}
            </FormItem>
          )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationSearch;
