// outreach types
export const APIREQUEST = 'APIREQUEST';
export const REQUEST_EVENT_LIST = 'REQUEST_EVENT_LIST';
export const RECEIVE_EVENT_LIST = 'RECEIVE_EVENT_LIST';
export const REQUEST_EVENT_LIST_ERROR = 'EVENT_LIST_ERROR';
export const EVENT_FILTER = 'EVENT_FILTER';
export const REQUEST_USER_DETAILS = 'REQUEST_USER_DETAILS';
export const RECEIVE_USER_DETAILS = 'RECEIVE_USER_DETAILS';
export const REQUEST_USER_DETAILS_ERROR = 'USER_DETAILS_ERROR';
export const REQUEST_DEMOGRAPHIC = 'REQUEST_DEMOGRAPHIC';
export const RECEIVE_DEMOGRAPHIC = 'RECEIVE_DEMOGRAPHIC';
export const REQUEST_DEMOGRAPHIC_ERROR = 'REQUEST_DEMOGRAPHIC_ERROR';
export const REQUEST_INFO_REQUEST_TYPE = 'REQUEST_INFO_REQUEST_TYPE';
export const RECEIVE_INFO_REQUEST_TYPE = 'RECEIVE_INFO_REQUEST_TYPE';
export const REQUEST_INFO_REQUEST_TYPE_ERROR =
  'REQUEST_INFO_REQUEST_TYPE_ERROR';
export const REQUEST_LEADMAP = 'REQUEST_LEADMAP';
export const RECEIVE_LEAPMAP = 'RECEIVE_LEAPMAP';
export const REQUEST_LEADMAP_ERROR = 'REQUEST_LEADMAP_ERROR';
export const REQUEST_EVENT_DELETE = 'EVENT_DELETE';
export const REQUEST_EVENT_EDIT = 'EVENT_EDIT';
