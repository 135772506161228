import React from 'react';
import {Button, Row, Col, Tag, Avatar} from 'antd';
import {GPS_DOMAIN} from 'config/srm.config';
import ProfileHeader from './ProfileHeader';
import {
  numberInUSFormat,
  getCloudinaryImage,
  capitalizeFirstLetter,
} from 'core';

const AddressComponent = ({data}) => {
  return (
    (data && (
      <>
        <div>
          {(data.physical_address_details &&
            data.physical_address_details.address_line1) ||
            ''}
        </div>
        <div>
          {(data.physical_address_details &&
            data.physical_address_details.address_line2) ||
            ''}
        </div>
        <div>
          {(data.physical_address_details &&
            data.physical_address_details.city) ||
            ''}
        </div>
        <div>
          {(data.physical_address_details &&
            data.physical_address_details.state) ||
            ''}
        </div>
        <div>
          {(data &&
          data.physical_address_details &&
          data.physical_address_details.zipcode &&
          data.physical_address_details.zipcode.length > 5 &&
          !data.physical_address_details.zipcode.includes('-')
            ? data.physical_address_details.zipcode.slice(0, 5) +
              '-' +
              data.physical_address_details.zipcode.slice(5)
            : data.physical_address_details.zipcode) || ''}
        </div>
      </>
    )) || <span>-</span>
  );
};

const Logo = ({data}) => {
  const logo =
    data &&
    data.logo_cloudinary &&
    getCloudinaryImage(data.logo_cloudinary, 'logo');
  return (
    <Avatar
      className="photo-img"
      src={logo}
      alt="partner logo"
      icon={`${'picture'}`}
    />
  );
};
const Banner = ({data}) => {
  const imageData =
    data && (getCloudinaryImage(data.banner_cloudinary || '_') || data.banner);
  return imageData ? (
    <img className="photo-img" src={imageData} alt="partner Banner" />
  ) : null;
};

const Publish = ({
  prevStep,
  onSave,
  initialData,
  isPublishing,
  tuitionLinkOptions = [],
  consortiumStates: {data: consortiumList = []},
}) => {
  const {consortium_list: selectedList = [], institution_link} = initialData;
  let filteredStates =
    selectedList &&
    selectedList.map(item => {
      return (
        consortiumList &&
        consortiumList !== null &&
        consortiumList.find(res => res.consortium_uuid === item)
      );
    });

  const getTuitionAmount = (tuition = {}) => {
    const {npt4_pub, npt4_prog, npt4_priv, npt4_other} = tuition;
    const getValue = npt4_pub || npt4_prog || npt4_priv || npt4_other;
    if (!getValue) {
      return getValue;
    } else {
      const formattedAmount = numberInUSFormat(getValue);
      return `$${formattedAmount}`;
    }
  };

  const getLinkedTuitionId = linkId => {
    const tutionLinkObj =
      tuitionLinkOptions?.find(
        ele => ele?.id.toString() === linkId.toString()
      ) || {};
    return `${tutionLinkObj?.institute_name} - ${tutionLinkObj?.city}, ${
      tutionLinkObj?.state_code
    } - ${getTuitionAmount(tutionLinkObj)}`;
  };

  return (
    <div className="steps-content connect_bp">
      <ProfileHeader data={initialData} />
      <div className="select-path">
        <div className="select-header">
          <p className="font-size-20 margin-b10">
            Publishing “{initialData && initialData.name}” Under “
            {(initialData &&
              initialData.institution_type_name &&
              initialData.institution_type_name.name) ||
              ''}
            ”
          </p>
          <p className="para-sub-txt">
            Please review and confim by clicking on Publish Institution Profile
          </p>
        </div>
        <div className="custom-collapse">
          <div className="custom-collapse-header">Profile Information</div>
        </div>
        <div className="banner-publish">
          <Banner data={initialData} />
        </div>
        <div className="bp_profile_info">
          <Row>
            <Col span={4} className="text-right">
              <h5>Institution Name : </h5>
            </Col>
            <Col
              span={4}
              className="text-left"
              title={initialData && initialData.name}>
              <span className="profile_elip">
                {initialData && initialData.name}
              </span>
            </Col>
            <Col span={3} className="text-right">
              <h5>Institution Type : </h5>
            </Col>
            <Col span={5} className="text-left">
              <span>
                {(initialData &&
                  initialData.institution_type_name &&
                  initialData.institution_type_name.name) ||
                  ''}
              </span>
            </Col>
            <Col span={3} className="text-right">
              <h5>Website : </h5>
            </Col>
            <Col
              span={4}
              className="text-left"
              title={initialData && initialData.web_url}>
              <span className="profile_elip">
                {initialData && initialData.web_url}
              </span>
            </Col>
          </Row>

          <Row>
            <Col span={4} className="text-right">
              <h5> Logo : </h5>
            </Col>
            <Col span={4} className="text-left">
              <span>
                <Logo data={initialData} />
              </span>
            </Col>
            <Col span={3} className="text-right">
              <h5> Tagline : </h5>
            </Col>
            <Col span={5} className="text-left">
              <p>
                <span>{initialData && initialData.tagline}</span>
              </p>
            </Col>
            <Col span={3} className="text-right">
              <h5>Profile Link URL : </h5>
            </Col>
            <Col
              span={5}
              className="text-left"
              title={`${initialData && initialData.subdomain}.${GPS_DOMAIN}`}>
              <span className="profile_elip">
                {initialData && initialData.subdomain}.{GPS_DOMAIN}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={4} className="text-right">
              <h5>Default MSA : </h5>
            </Col>
            <Col
              span={4}
              className="text-left"
              title={
                initialData &&
                initialData.default_msa &&
                initialData.default_msa.city &&
                initialData.default_msa.state_code
              }>
              <span className="profile_elip">
                {(initialData &&
                  initialData.default_msa &&
                  initialData.default_msa.city &&
                  initialData.default_msa.state_code &&
                  `${initialData.default_msa.city}, ${
                    initialData.default_msa.state_code
                  }`) ||
                  '-'}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={4} className="text-right">
              <h5>Connected ROP(s) : </h5>
            </Col>
            <Col span={20} className="text-left">
              <span>
                {filteredStates && filteredStates.length > 0
                  ? filteredStates.map(
                      ({city, state_code, consortium_name} = {}) => {
                        return (
                          consortium_name && (
                            <Tag>
                              {`${capitalizeFirstLetter(
                                consortium_name
                              )} - ${city}, ${state_code}`}
                            </Tag>
                          )
                        );
                      }
                    )
                  : '-'}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={4} className="text-right">
              <h5>About Institution : </h5>
            </Col>
            <Col span={20} className="text-left pl-1 pt-1">
              {initialData &&
              initialData.overview_contents &&
              initialData.overview_contents[0] &&
              initialData.overview_contents[0].value[0] &&
              initialData.overview_contents[0].value[0] ? (
                <div
                  className="pl-2"
                  dangerouslySetInnerHTML={{
                    __html: initialData.overview_contents[0].value[0],
                  }}
                />
              ) : (
                <div className="pl-2">-</div>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={4} className="text-right">
              <h5>Tuition Link: </h5>
            </Col>
            <Col span={20} className="text-left pl-1 pt-1">
              {institution_link ? (
                <span className="profile_elip">
                  {getLinkedTuitionId(institution_link)}
                </span>
              ) : (
                <div className="pl-2">-</div>
              )}
            </Col>
          </Row>
        </div>
        <div className="custom-collapse">
          <div className="custom-collapse-header">Contact Details</div>
        </div>
        <div className="bp_contact_info">
          <Row>
            <Col span={3} className="text-right">
              <h5> Address : </h5>
            </Col>
            <Col span={5} className="text-left pl-2 pt-1">
              <AddressComponent data={initialData} />
            </Col>
            <Col span={2} className="text-right">
              <h5> Phone : </h5>
            </Col>
            <Col span={4} className="text-left">
              <span>{(initialData && initialData.phone_number) || '-'}</span>
            </Col>
            <Col span={3} className="text-right">
              <h5> Email : </h5>
            </Col>
            <Col span={7} className="text-left">
              <span>{(initialData && initialData.email_id) || '-'}</span>
            </Col>
          </Row>
        </div>
      </div>
      <div className="margin-muted">
        <div className="steps-action">
          <Button type="primary" onClick={prevStep} className="margin-r20">
            Previous Step
          </Button>
          <Button type="primary" onClick={onSave} loading={isPublishing}>
            Publish Institution Profile
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Publish;
