import React, {Component} from 'react';
import update from 'immutability-helper';

import {Form, Input, Checkbox, Button, Icon} from 'antd';

import {FieldWithCount, TinyEditor} from 'core';

const FormItem = Form.Item;
const StaticContent = {value: ''};
class AddOverViewFormItem extends Component {
  state = {
    overview_values: [],
    forceRerender: false,
  };

  setPropsDataToState = ({overview_key, overview_values}) => {
    const {nameKey = '', parentIndex = 0} = this.props;
    let newOverViewValues = [StaticContent];
    if (overview_values && overview_values.length) {
      newOverViewValues = overview_values;
    }
    let isValidateHeader = false;
    newOverViewValues &&
      newOverViewValues.forEach(data => {
        data.value = data.value;
        if (data.value !== '' && data.value.length) {
          isValidateHeader = true;
        }
      });
    this.setState({
      overview_values: newOverViewValues,
      validateContentFields: overview_key === '' ? false : true,
      validateHeaderField: isValidateHeader,
    });
  };

  componentDidMount = () => {
    const {overView} = this.props;
    if (overView) this.setPropsDataToState(overView);
  };

  componentDidUpdate = prevProps => {
    const {overView} = this.props;
    if (prevProps.overView !== overView) this.setPropsDataToState(overView);
  };

  removeOverViewValue = rmOverviewValIdx => {
    const {
      parentIndex,
      nameKey,
      overView: {overview_key, overview_type, status},
    } = this.props;
    this.setState(
      update(this.state, {
        overview_values: {
          $splice: [[rmOverviewValIdx, 1]],
        },
      }),
      () => {
        let updateObj = {};
        this.state.overview_values.map((overviewValue, index) => {
          const fieldName = `${nameKey ||
            'overview_content'}[${parentIndex}].overview_values[${index}].value`;
          updateObj[fieldName] = overviewValue.value;
        });
        this.props.form.setFieldsValue({
          ...updateObj,
        });

        this.setState(
          {
            forceRerender: true,
          },
          () => {
            this.props.onUpdate(
              parentIndex,
              Object.assign(
                {},
                {
                  overview_key,
                  overview_type,
                  status,
                },
                {overview_values: this.state.overview_values}
              )
            );
          }
        );
      }
    );
  };

  addOverViewValue = () => {
    this.setState(
      update(this.state, {
        overview_values: {
          $push: [StaticContent],
        },
      })
    );
  };

  onOverviewKeyChange = event => {
    const {parentIndex, nameKey} = this.props;
    const headerValue = event.target.value;
    this.setState(
      {
        validateContentFields: headerValue.length ? true : false,
      },
      () => {
        this.props.onUpdate(
          parentIndex,
          Object.assign(
            {},
            {
              overview_key: headerValue,
              overview_type: 'text',
              status: true,
            },
            {overview_values: this.state.overview_values}
          )
        );

        if (headerValue === '' || !headerValue) {
          this.props.form.validateFields(
            [
              `${nameKey ||
                'overview_content'}[${parentIndex}].overview_values`,
            ],
            {
              force: true,
            }
          );
        }
      }
    );
  };

  onContentChange = (value, index) => {
    const {
      nameKey,
      parentIndex,
      overView: {overview_key, overview_type, status},
    } = this.props;
    const fieldValue = value;
    const {overview_values} = this.state;
    let isValidateHeader = false;
    if (fieldValue !== '' && fieldValue.length) {
      isValidateHeader = true;
    }
    overview_values &&
      overview_values.forEach(data => {
        if (data.value !== '' && data.value.length) {
          isValidateHeader = true;
        }
      });

    this.setState(
      update(this.state, {
        overview_values: {
          [index]: {
            value: {$set: fieldValue},
          },
        },
      }),
      () => {
        this.props.onUpdate(
          parentIndex,
          Object.assign(
            {},
            {
              overview_key,
              overview_type,
              status,
            },
            {overview_values: this.state.overview_values}
          )
        );

        if (isValidateHeader) {
          this.props.form.validateFields(
            [`${nameKey || 'overview_content'}[${parentIndex}][overview_key]`],
            {
              force: true,
            }
          );
        }
      }
    );
  };

  changeRenderStatus = value => {
    this.setState({
      forceRerender: value,
    });
  };

  render() {
    const {
      parentIndex,
      overView,
      form: {getFieldDecorator},
      nameKey,
      displayForm,
      data,
      showContentTitle,
      headingPlaceholder,
      contentPlaceholder,
      titlePlaceholder,
      contentButtonText,
      contentMaxLength,
      titleMaxLength,
      headingMaxLength,
    } = this.props;
    const {
      overview_values,
      validateContentFields,
      validateHeaderField,
      forceRerender,
    } = this.state;

    const contentFields =
      overview_values &&
      overview_values.length > 0 &&
      overview_values.map((overviewValue, index) => (
        <div
          key={index}
          className={`loop ${showContentTitle ? 'has-title' : ''}`}>
          {showContentTitle && (
            <FormItem className="form-item">
              {displayForm ? (
                getFieldDecorator(
                  `${nameKey ||
                    'overview_content'}[${parentIndex}].overview_values[${index}].title`,
                  {
                    rules: [
                      {
                        required: validateContentFields,
                        whitespace: true,
                        message: 'Please enter Title',
                      },
                    ],
                    initialValue: overviewValue.title || '',
                  }
                )(
                  <FieldWithCount
                    showcount
                    maxLength={titleMaxLength || 40}
                    placeholder={titlePlaceholder || 'Title'}
                  />
                )
              ) : (
                <p>{overviewValue.title || ''}</p>
              )}
            </FormItem>
          )}
          <FormItem className="form-item">
            {displayForm ? (
              getFieldDecorator(
                `${nameKey ||
                  'overview_content'}[${parentIndex}].overview_values[${index}].value`,
                {
                  rules: [
                    {
                      required: validateContentFields,
                      whitespace: true,
                      message: 'Please enter content',
                    },
                  ],
                  initialValue: overviewValue.value || '',
                }
              )(
                <TinyEditor
                  maxLen={contentMaxLength || 10000}
                  // placeholder={contentPlaceholder || 'Content'}
                  //onChange={value => this.onContentChange(value, index)}
                  //forceRerender={forceRerender}
                  //changeRenderStatus={this.changeRenderStatus}
                />
              )
            ) : (
              <p>{overviewValue.value}</p>
            )}
          </FormItem>

          {/* <FieldWithCount
                    showcount
                    maxLength={1000}
                    placeholder="Content"
                    fieldtype="TextArea"
                    autosize
                    onChange={event => this.onContentChange(event, index)}
                  /> */}
          {/* {index === overview_values.length - 1 ? (
            <Button icon="plus" onClick={this.addOverViewValue} />
          ) : (
              <Button
                icon="minus"
                onClick={() => this.removeOverViewValue(index)}
              />
            )} */}
          {displayForm && overview_values.length > 1 && (
            <Icon
              className="delete"
              onClick={() => this.removeOverViewValue(index)}
              type="delete"
            />
          )}
        </div>
      ));
    console.log('overview', showContentTitle, parentIndex, validateHeaderField);
    return (
      <div className="list-row">
        {!displayForm && data && data.length <= 0 && (
          <p
            style={{
              background: 'rgb(246, 246, 246)',
              padding: '20px',
              textAlign: 'center',
            }}>
            No Overview Data
          </p>
        )}
        <div className="checkbox" style={{display: 'none'}}>
          <FormItem>
            {getFieldDecorator(
              `${nameKey || 'overview_content'}[${parentIndex}][status]`,
              {
                initialValue: overView.status,
                valuePropName: 'checked',
              }
            )(<Checkbox className="checkbox-grey-outline" />)}
          </FormItem>
        </div>
        <FormItem>
          {displayForm ? (
            getFieldDecorator(
              `${nameKey || 'overview_content'}[${parentIndex}][overview_key]`,
              {
                rules: [
                  {
                    required: showContentTitle && parentIndex === 0,
                    whitespace: true,
                    message: 'Please enter Header details.',
                  },
                ],
                initialValue: overView.overview_key,
              }
            )(
              <FieldWithCount
                showcount
                maxLength={headingMaxLength || 100}
                placeholder={headingPlaceholder || 'Heading'}
                onChange={this.onOverviewKeyChange}
              />
            )
          ) : (
            <h4>{overView.overview_key}</h4>
          )}
        </FormItem>
        <FormItem style={{display: 'none'}}>
          {getFieldDecorator(
            `${nameKey || 'overview_content'}[${parentIndex}][overview_type]`,
            {
              initialValue: overView.overview_type || 'text',
            }
          )(<Input hidden />)}
        </FormItem>
        <div className="content">{contentFields}</div>
        <div className="text-center">
          {displayForm && (
            <Button
              className="btn-blue-outline add-more"
              onClick={this.addOverViewValue}>
              {contentButtonText || (
                <>
                  <Icon type="plus" />
                  {'Add Content'}
                </>
              )}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default AddOverViewFormItem;
