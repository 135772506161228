import {createSelector} from 'reselect';
import {filterEvents, prepareEventMetaData} from 'core';
import moment from 'moment';

export const getDemographicSelector = state => state && state.demographic;

export const getinforequesttypeSelector = state => state && state.requesttype;

export const eventLeadMapSelector = state =>
  state && state.leadmap && state.leadmap.data;

export const eventRequestSelector = ({calendar: {request} = {}}) => request;

export const eventErrorSelector = ({calendar: {error} = {}}) => error;

export const getCalendarEventselector = state => state && state.calendar;

export const usersSelector = ({user = {}}) => user;

export const userRequestSelector = ({user: {request} = {}}) => request;

export const userErrorSelector = ({user: {error} = {}}) => error;

export const loggedInUserUuidSelector = ({user = {}}) => user[user.show];

export const userDetailsBySrmId = state => srmid => usersSelector(state)[srmid];

export const leadMapEventUuidSelector = ({
  leadmap: {eventId = null} = {},
} = {}) => eventId;

export const leadMapRequestSelector = ({leadmap: {request} = {}} = {}) =>
  request;

export const eventsSelector = ({calendar: {data} = {}}) => data;

export const loggedInUserSrmUuidSelector = ({auth: {loggedinUserId}}) =>
  loggedinUserId;

export const currentFilterSelector = ({calendar: {filter = {}} = {}}) => filter;

export const filteredEvents = createSelector(
  eventsSelector,
  loggedInUserSrmUuidSelector,
  currentFilterSelector,
  (events, srm_user_uuid, filter) =>
    events && filterEvents(events, srm_user_uuid, filter)
);

export const prepareEventsData = createSelector(
  filteredEvents,
  events => {
    if (!events) {
      return;
    }
    const eventData = {};
    events.forEach(event => {
      let month = moment(event.start).format('MMMM');
      let year = moment(event.start).format('YYYY');
      if (!eventData[year]) {
        eventData[year] = {};
      }
      if (eventData[year] && !eventData[year][month]) {
        eventData[year][month] = [event];
      } else if (eventData[year] && eventData[year][month]) {
        eventData[year][month].push(event);
        eventData[year][month] = eventData[year][month];
      }
    });
    return eventData;
  }
);

export const eventMetaData = createSelector(
  filteredEvents,
  events => prepareEventMetaData(events)
);
