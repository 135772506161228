import * as TYPES from './constants';
import {
  requestConsortiumInstituteDetails,
  receiveConsortiumInstituteDetails,
  handleConsortiumInstituteDetailsError,
} from './actions';

import {Services} from 'core';

import {API} from 'config/superuser.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_CONSORTIUM_INSTITUTE_DETAILS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_CONSORTIUM_INSTITUTE_DETAILS:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.CLEAR_CONSORTIUM_INSTITUTE_DETAILS:
      return {
        ...state,
        data: null,
        request: false,
        error: false,
      };
    case TYPES.CONSORTIUM_INSTITUTE_DETAILS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchConsortiumInstituteDetails = instituteId => (
  dispatch,
  getState
) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();
  dispatch(requestConsortiumInstituteDetails());
  let endpoint = `${API.administration.institution}${instituteId}/`;
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        let endpoint = `${API.administration.institution_users}${instituteId}/`;
        return services.fetchRecords(endpoint, token).then(users => {
          return {
            ...res,
            // users,
          };
        });
      })
      .then(instituteResult => {
        dispatch(receiveConsortiumInstituteDetails(instituteResult));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleConsortiumInstituteDetailsError(err));
  }
};
