import React, {Component} from 'react';
import {Input, Select} from 'antd';
const {Option} = Select;

class UrlInput extends Component {
  state = {};

  componentDidUpdate = ({value}) => {
    const {value: newValue} = this.props;
    if (newValue !== value) {
      this.updateDomain(newValue);
    }
  };

  componentDidMount() {
    this.updateDomain(this.props.value);
  }

  updateDomain = value => {
    let protocol = 'https://';
    let domain = '';
    if (value && typeof value === 'string') {
      const valueArr = value.split('://');
      protocol = valueArr[0] + '://';
      domain = valueArr[1];
    }
    this.setState({
      domain,
      protocol,
    });
  };

  onDomainChange = e => {
    const {value} = e.target;
    this.setState({domain: value}, () => {
      this.triggerChange();
    });
  };

  onProtocolChange = protocol => {
    this.setState({protocol}, () => {
      this.triggerChange();
    });
  };

  triggerChange = () => {
    const {onChange, hideProtocolOnEmpty} = this.props;
    const {protocol, domain} = this.state;
    if (onChange) {
      const urlValue =
        (hideProtocolOnEmpty && domain === '' ? '' : protocol) + domain;
      onChange(urlValue);
    }
  };

  render() {
    const {protocol, domain} = this.state;
    const {disabled} = this.props;
    const selectBeforeWebsite = (
      <Select
        disabled={disabled}
        onChange={this.onProtocolChange}
        value={protocol}
        style={{width: 90}}>
        <Option style={{textTransform: 'lowercase'}} value="https://">
          https://
        </Option>
        <Option style={{textTransform: 'lowercase'}} value="http://">
          http://
        </Option>
      </Select>
    );
    return (
      <Input
        disabled={disabled}
        addonBefore={selectBeforeWebsite}
        value={domain}
        onChange={this.onDomainChange}
        placeholder="www.example.com"
      />
    );
  }
}

export default UrlInput;
