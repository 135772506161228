import React from 'react';
import {Stats as MUIStats} from 'mui-core';
import {StyledContainer} from './styles';

const ProgramInsights = ({data}) => {
  const prepareProgramInsightstatsData = () => {
    if (data) {
      const {active_programs, total_programs, inactive_programs} = data;
      const statsData = [
        {
          title: 'Total Programs',
          count: total_programs,
          color: '#F69D0B',
        },
        {
          title: 'Active Programs',
          count: active_programs,
          color: '#1AB266',
        },
        {
          title: 'Inactive Programs',
          count: inactive_programs,
          color: '#DF1B1B',
        },
      ];
      return statsData;
    }
    return [];
  };
  const usersData = prepareProgramInsightstatsData();
  return (
    <StyledContainer>
      <MUIStats data={usersData} />
    </StyledContainer>
  );
};

export default ProgramInsights;
