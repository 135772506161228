import * as TYPES from 'redux/types';
import {
  requestInstitutionProfile,
  receiveInstitutionProfile,
  handleReceiveProfileError,
  setToggleInstitutionProfile,
} from './actions';

import {getLoggedInstituteUUID} from 'redux/modules/auth';

import {userDetailsBySrmId} from 'redux/modules/outreach';

import {Services} from 'core';
import {API} from 'config/superuser.config';

const initialState = {
  data: null,
  isInstitutionProfileExist: false,
  editMode: false,
  request: null,
  error: null,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_INSTITUTION_USER:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_INSTITUTION_USER:
      return {
        ...state,
        isInstitutionProfileExist:
          action.payload &&
          action.payload.physical_address_details &&
          action.payload.physical_address_details.address_line1 &&
          action.payload.physical_address_details.city &&
          action.payload.physical_address_details.state &&
          action.payload.physical_address_details.zipcode &&
          action.payload.web_url &&
          (action.payload.logo_cloudinary || action.payload.logo),
        error: false,
        request: false,
        data: action.payload,
      };
    case TYPES.TOGGLE_INSTITUTION_PROFILE:
      return {
        ...state,
        editMode: action.payload,
      };
    case TYPES.REQUEST_INSTITUTION_USER_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const toggleInstitutionProfile = status => dispatch => {
  dispatch(setToggleInstitutionProfile(status));
};

export const fetchCollegeProfile = () => (dispatch, getState) => {
  const {
    auth: {loggedinUserId, access = {}, access: {token} = {}},
  } = getState();
  const institution_uuid = getLoggedInstituteUUID(getState());
  let endpoint = `${API.administration.institution}${institution_uuid}`;
  dispatch(requestInstitutionProfile());
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receiveInstitutionProfile(res));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleReceiveProfileError(err));
  }
};
