import {themeColorMode} from 'mui-core';
import {styled} from '@mui/material/styles';
import {Visibility} from '@mui/icons-material';
import profilePreview from 'data/profilePreview.json';
import {Box, Stack, useTheme, Typography} from '@mui/material';
import {resumePreviewIcon} from 'sharedResources/assets/images';

const {noDataPreview: {description} = {}} = profilePreview;

const StyledPreviewButton = styled(Box)(({theme}) => ({
  width: '100%',
  height: '58px',
  display: 'flex',
  padding: '9px 16px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10px 10px 0px 0px',
  backgroundColor: themeColorMode(
    theme,
    theme.palette.grey[200],
    theme.palette.grey[600]
  ),
}));

const NoDataPreview = ({
  isMobile,
  heading = 'Preview',
  isShareProfile = false,
  disableEyeIcon = false,
}) => {
  const theme = useTheme();
  const StyledDescription = styled(Typography)(({theme}) => ({
    '& img ': {
      filter:
        theme.palette.mode === 'light'
          ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(5313%) hue-rotate(103deg) brightness(123%) contrast(93%);'
          : '',
    },
  }));

  return (
    <Stack>
      {!isMobile && (
        <Stack alignItems="center">
          <StyledPreviewButton
            rounded
            size="small"
            variant="outlined"
            endIcon={<Visibility fontSize={'large'} />}>
            <Typography variant="h4">{heading}</Typography>
            {!disableEyeIcon && (
              <Visibility fontSize={'small'} sx={{marginLeft: '8px'}} />
            )}
          </StyledPreviewButton>
        </Stack>
      )}
      {!isShareProfile && (
        <Box
          mb={1}
          p={isMobile ? 2 : 6}
          my={isMobile ? 7 : 2}
          sx={{
            width: '100%',
            borderRadius: 1,
            textAlign: 'center',
            background:
              theme.palette.mode === 'dark'
                ? theme.palette.dialogDarkBG.main
                : 'linear-gradient(0deg, rgba(123, 96, 223, 0.08), rgba(123, 96, 223, 0.08))',
          }}>
          <Typography
            color="primary"
            variant="body1"
            sx={{
              color:
                theme.palette.mode === 'light'
                  ? 'primary.main'
                  : 'primary.light',
            }}>
            {description}
          </Typography>
        </Box>
      )}

      <StyledDescription
        display="flex"
        alignItems="center"
        justifyContent="center"
        py={8}>
        <img
          alt="Resume Preview"
          src={resumePreviewIcon}
          style={{
            maxWidth: isMobile ? 240 : 360,
          }}
        />
      </StyledDescription>
    </Stack>
  );
};

export default NoDataPreview;
