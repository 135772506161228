import {BUSINESS_PARTNERS_ROUTES} from './routes';
const SRM = process.env.REACT_APP_SRM;
const GPS = process.env.REACT_APP_GPS;
const STUDENT_PROFILE = process.env.REACT_APP_STUDENT_PROFILE;

export const API = {
  administration: {
    dashboard: `${SRM}srm_metrics/`,
    business_partner_user: `${SRM}user/`,
    business_partner_program_student_list: `${SRM}business_partner/program/sign-up`,
    business_partner_opportunity_student_list: `${GPS}v2/bp_opportunity_apply`,
    picture: `${SRM}user/picture/`,
    partner: `${SRM}partner/`,
    jobs: `${SRM}business-partner/jobs/`,
    programs: `${SRM}business-partner/program/`,
    changePassword: `${SRM}user/profile/`,
    message: `${SRM}partner-message/`,
    opportunity_message: `${SRM}business_partner/opportunity_send_message/`,
    program_message: `${SRM}business_partner/program_send_message/`,
    job_message: `${SRM}business_partner/jobs/send_message/`,
    //industry: `${SRM}industry-places-cluster/`,
    industry: `${SRM}industry-sectors/`,
    occupation: `${SRM}gps/occupation/`,
    student_profiles: `${GPS}v1/get-published-profiles/`,
    student_profile: `${STUDENT_PROFILE}user/user-profile/`,
    student_pathDetails: `${GPS}myprofile/`,
    student_exchange_data: `${GPS}v2/srm/student_exchange/`,
    student_exchange_programs: `${SRM}gps/program/`,
    publish: `${SRM}publish-v1/`,
    opportunities: `${SRM}business-partner/opportunity/`,
    institution: `${SRM}v1/super-user/institute/`,
    skills_from_text: `${SRM}skills_from_text/`,
    career_one_stop_companies_list: `${SRM}career_one_stop_company_list/`,
    location_details: `${SRM}gps/location_details`,
    skills: `${SRM}gps/skills`,
    acheivement_options: `${SRM}gps/acheivement-options/`,
    student_exchange: `${GPS}v2/srm/student_exchange/`,
    linkedin_details: `${GPS}linkedin_details`,
  },
};
//?student_uuid=
export const programTypes = [
  {
    icon: 'icon-apprenticeship',
    name: 'Apprenticeships',
  },
  {
    icon: 'icon-internship',
    name: 'Internships',
  },
];

export const defautHomePage = '/';

export const noOfAdminCardInPage = 12;
export const DefaultPageSize = 12;
export const PageStart = 1;
export const OpportunitiesDefaultPageSize = 9;

export const jobsSorters = [
  {
    key: 'asc',
    value: 'A to Z',
  },
  {
    key: 'desc',
    value: 'Z to A',
  },
];

export const eventAlert = {
  'Same Time': '0',
  '5 Minutes Before': '-300',
  '10 Minutes Before': '-600',
  '15 Minutes Before': '-900',
  '30 Minutes Before': '-1800',
  '1 Hours Before': '-3600',
  '2 Hours Before': '-7200',
  '1 Day Before': '-86400',
  '2 Day Before': '-172800',
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const notificationSettingsData = [
  {
    title: 'New Event',
    data: [
      {
        label: 'From Community',
        key: 'alert_from_community',
      },
    ],
  },
  {
    title: 'Event Alerts',
    data: [
      {
        label: 'From Me',
        key: 'alert_from_me',
      },
      {
        label: 'From Community',
        key: 'new_from_community',
      },
    ],
  },
  {
    title: 'Event Responses',
    data: [
      {
        label: 'Survey Responses',
        key: 'response_from_survey',
      },
    ],
  },
];

export const profileMenu = [
  {
    title: 'Edit Profile',
    key: 'edit_profile',
  },
  {
    title: 'Change Password',
    key: 'change_password',
  },
  {
    title: 'About Us',
    key: 'about_us',
  },
  {
    title: 'Log Out',
    key: 'log_out',
  },
];

export const homePageMenu = [
  {
    title: 'Home',
    imgSrc: 'icon-Home icon',
    key: 'Home',
    mainMenu: true,
    path: BUSINESS_PARTNERS_ROUTES.HOME.DASHBOARD,
  },
  {
    title: 'Administration',
    imgSrc: 'icon-Profile icon',
    key: 'Administration',
    content: [
      {
        submenuTitle: 'User management',
        path: BUSINESS_PARTNERS_ROUTES.ADMIN.USER_MANAGEMENT,
      },
    ],
  },
  // {
  //   title: 'Programs',
  //   imgSrc: 'icon-programs-new icon',
  //   key: 'Programs',
  //   mainMenu: true,
  //   path: BUSINESS_PARTNERS_ROUTES.PROGRAMS.PROGRAMS_LIST,
  // },
  // {
  //   title: 'Jobs',
  //   imgSrc: 'icon-jobs icon',
  //   key: 'Jobs',
  //   mainMenu: true,
  //   path: BUSINESS_PARTNERS_ROUTES.JOBS.JOBS_LIST,
  // },
  {
    title: 'Opportunities',
    imgSrc: 'icon-programs-new icon',
    key: 'Opportunities',
    mainMenu: true,
    path: BUSINESS_PARTNERS_ROUTES.OPPORTUNITIES.OPPORTUNITIES_LIST,
  },
  {
    title: 'Events',
    imgSrc: 'icon-events icon',
    key: 'Events',
    mainMenu: true,
    path: BUSINESS_PARTNERS_ROUTES.EVENTS.EVENTS_LIST,
  },
  {
    title: 'Talent Exchange',
    imgSrc: 'icon-bpworkforceicon icon',
    key: 'Workforce',
    mainMenu: true,
    path: BUSINESS_PARTNERS_ROUTES.WORKFORCE.STUDENT_EXCHANGE,
    // content: [
    //   {
    //     submenuTitle: 'Talent Exchange',
    //     path: BUSINESS_PARTNERS_ROUTES.WORKFORCE.STUDENT_EXCHANGE,
    //   },
    // ],
  },
];
