import * as React from 'react';
import {Box} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import {AppHeader, BackgroundPaper, Sidebar} from 'mui-core';

const drawerWidth = 270;

const openedMixin = (theme, drawerWidth) => ({
  width: drawerWidth,
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppLayout = props => {
  // vars
  const {
    children,
    onSignOut,
    userPortal,
    sideBarMenu,
    onLogoClick,
    userDetails,
    instanceDetails,
    createCompanyProfile,
  } = props;
  const theme = useTheme();

  return (
    <BackgroundPaper
      sx={{
        display: 'flex',
      }}>
      <AppHeader
        {...props}
        onSignOut={onSignOut}
        userDetails={userDetails}
        onLogoClick={onLogoClick}
        drawerWidth={drawerWidth}
      />
      {!createCompanyProfile && (
        <Sidebar
          data={sideBarMenu}
          userPortal={userPortal}
          openedMixin={openedMixin}
          drawerWidth={drawerWidth}
          DrawerHeader={DrawerHeader}
          instanceDetails={instanceDetails}
        />
      )}
      <Box component="main" sx={{flexGrow: 1}}>
        <BackgroundPaper
          sx={{minHeight: '100%'}}
          background={{
            light: theme.palette.background.main,
            dark: theme.palette.background.paper,
          }}>
          <DrawerHeader />
          {children}
        </BackgroundPaper>
      </Box>
    </BackgroundPaper>
  );
};
export default AppLayout;
