import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Switch, Form, Icon, Button} from 'antd';

import {notificationSettingsData} from 'config/srm.config';

const FormItem = Form.Item;
class NotificationSettings extends Component {
  handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    let {
      notificationSettings,
      notificationSettings: {user},
      saveNotification,
      form: {validateFields},
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        let request = false;
        Object.keys(values).map(item => {
          if (values[item] !== undefined) {
            request = true;
          }
          if (values[item] === undefined) {
            values[item] = notificationSettings[item];
          }
        });
        //posting data to the api
        if (request) {
          saveNotification(values, user);
        }
      }
    });
  };

  buildNotificationSettingsForm(notificationData, notificationSettings) {
    const {getFieldDecorator} = this.props.form;
    return (
      notificationData &&
      notificationData.map((item, index) => {
        return (
          <div key={index} className="notification-row">
            <h1 className="notification-title">{item.title}</h1>
            {item.data && Array.isArray(item.data)
              ? item.data.map((subitem, idx) => {
                  return (
                    <FormItem key={idx} label={subitem.label}>
                      {getFieldDecorator(subitem.key)(
                        <Switch
                          defaultChecked={
                            notificationSettings &&
                            notificationSettings[subitem.key]
                          }
                        />
                      )}
                    </FormItem>
                  );
                })
              : ''}
          </div>
        );
      })
    );
  }

  render() {
    const {notificationSettings, closeHandler} = this.props;
    return (
      <div className="profile-dropdown edit-prof-dropdown prof-notify-dropdown">
        <h1 className="profile-title">
          <span className="link">
            <Icon onClick={closeHandler} type="left" />
          </span>
          Notification Settings
        </h1>
        <Form
          onSubmit={this.handleSubmit}
          className="edit-prof-form table-form prof-notify-form">
          {this.buildNotificationSettingsForm(
            notificationSettingsData,
            notificationSettings
          )}
          <div className="btn-row">
            <Button
              onClick={closeHandler}
              className="ant-btn btn-outline btn-cancel">
              Cancel
            </Button>
            <Button
              className="ant-btn ant-btn-primary"
              onClick={this.handleSubmit}
              type="primary"
              htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

NotificationSettings.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  notificationSettings: PropTypes.object.isRequired,
};

export default Form.create()(NotificationSettings);
