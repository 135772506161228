import {combineReducers} from 'redux';
import enrolledList from './enrolledList';
import goSurveyResults from './goSurveyResult';
import enrolledProgram from './enrolledProgram';
import careerInterestList from './careerInterestList';
import programEnrollments from './programEnrollments';

export const emergingTalentReducer = combineReducers({
  enrolledList: enrolledList,
  goSurveyResults: goSurveyResults,
  enrolledProgram: enrolledProgram,
  careerInterestList: careerInterestList,
  programEnrollments: programEnrollments,
});
