import React from 'react';
import {Form, Select, Input, Row, Col} from 'antd';
import {
  PATTERN,
  isArray,
  isObject,
  isEmpty,
  onZipCodeChange,
  capitalizeFirstLetter,
} from 'core';

const FormItem = Form.Item;
const Option = Select.Option;

const Address = props => {
  const {
    displayForm,
    mode,
    data,
    statesData,
    licenseType,
    getFieldsValue,
    getFieldDecorator,
  } = props;
  const getCommonFields = (AddressTypes, licenseType) => {
    const checkIfRequired = () => {
      if (
        licenseType &&
        (AddressTypes === 'Mailing Address' || licenseType === 'Consortium')
      ) {
        return false;
      }
      return true;
    };

    const fieldValidator = (rule, value, callback) => {
      if (!checkIfRequired()) {
        let otherFieldsHaveValue = false;
        const fieldsObj = getFieldsValue();
        const name = rule.field.split('.')[1];

        if (
          licenseType === 'Consortium' &&
          AddressTypes === 'Physical Address'
        ) {
          Object.keys(fieldsObj.physical_address_details).forEach(item => {
            if (item !== name && item !== 'address_line2') {
              if (fieldsObj.physical_address_details[item]) {
                otherFieldsHaveValue = true;
              }
            }
          });
        } else {
          Object.keys(fieldsObj.mailing_address_details).forEach(item => {
            if (item !== name && item !== 'address_line2') {
              if (fieldsObj.mailing_address_details[item]) {
                otherFieldsHaveValue = true;
              }
            }
          });
        }

        const getLabel = fieldName => {
          switch (fieldName) {
            case 'address_line1':
              return 'Address';
            case 'zipcode':
              return 'Zip';
            default:
              return capitalizeFirstLetter(fieldName);
          }
        };

        if (otherFieldsHaveValue && !value) {
          callback(`${getLabel(name)} field cannot be empty`);
        } else callback();
      } else callback();
    };

    const commonFields = [
      {
        field_type: 'input',
        localKey: 'address_line1',
        label: 'Address Line 1',
        rules: [
          {
            whitespace: true,
            required: checkIfRequired(),
            message: 'Address field cannot be empty',
          },
          {
            validator: fieldValidator,
          },
        ],
      },
      {
        field_type: 'input',
        localKey: 'address_line2',
        label: 'Address Line 2',
        rules: [],
      },
      {
        field_type: 'input',
        localKey: 'city',
        label: 'City',
        rules: [
          {
            whitespace: true,
            required: checkIfRequired(),
            message: 'City field cannot be empty',
          },
          {
            pattern: PATTERN.Name_With_Space,
            message: 'Please enter a valid city',
          },
          {
            validator: fieldValidator,
          },
        ],
      },
      {
        field_type: 'select',
        localKey: 'state',
        label: 'State',
        rules: [
          {
            whitespace: true,
            required: checkIfRequired(),
            message: 'State field cannot be empty',
          },
          {
            pattern: PATTERN.Name_With_Space,
            message: 'Please enter a valid state',
          },
          {
            validator: fieldValidator,
          },
        ],
      },
      {
        field_type: 'input',
        localKey: 'zipcode',
        label: 'Zip',
        rules: [
          {
            whitespace: true,
            required: checkIfRequired(),
            message: 'Zip field cannot be empty',
          },
          {
            whitespace: true,
            pattern: PATTERN.Zip_Code,
            message: 'Please enter a valid 5 or 9 digit zip code',
          },
          {
            validator: fieldValidator,
          },
        ],
        miscellaneous: {getValueFromEvent: onZipCodeChange},
      },
    ];
    return commonFields;
  };

  const AddressTypes = [
    {
      headTitle: 'Physical Address',
      foreignKey: 'physical_address_details',
      fields: getCommonFields('Physical Address', licenseType),
    },
    {
      headTitle: 'Mailing Address',
      foreignKey: 'mailing_address_details',
      fields: getCommonFields('Mailing Address', licenseType),
    },
  ];

  const addressLength = AddressTypes.length;

  const isAddressEmpty = (data, foreignKey) => {
    if (foreignKey === 'physical_address_details') {
      return data &&
        isObject(data[foreignKey]) &&
        !isEmpty(data[foreignKey]) &&
        (data[foreignKey].address_line1 &&
          data[foreignKey].city &&
          data[foreignKey].state &&
          data[foreignKey].zipcode)
        ? false
        : true;
    } else {
      return isObject(data[foreignKey]) && isEmpty(data[foreignKey]);
    }
  };

  return (
    <>
      <Row className="form-head">
        {AddressTypes.map(({headTitle}, idx) => (
          <Col key={idx} span={24 / addressLength}>
            <h1
              style={{
                marginBottom: '0px',
              }}>
              {headTitle}
            </h1>
          </Col>
        ))}
      </Row>
      <Row style={{padding: mode === 'create' && '20px'}}>
        {AddressTypes.map(({foreignKey, fields}, idx) => {
          return (
            <Col key={`addressType-${idx}`} span={12}>
              {displayForm ? (
                fields.map(
                  (
                    {
                      field_type,
                      miscellaneous = {},
                      list = statesData.data || [],
                      localKey,
                      label,
                      rules,
                    },
                    idx
                  ) => {
                    return (
                      <FormItem
                        key={`${foreignKey}-${localKey}-${idx}`}
                        label={label}>
                        {getFieldDecorator(`${foreignKey}.${localKey}`, {
                          rules: [...rules],
                          ...miscellaneous,
                          initialValue:
                            data &&
                            data[foreignKey] &&
                            data[foreignKey][localKey],
                        })(
                          field_type === 'select' ? (
                            <Select allowClear={true}>
                              {list &&
                                isArray(list) &&
                                list.map((state, idx) => (
                                  <Option
                                    key={`${foreignKey}-state-${idx}`}
                                    value={state.name}>
                                    {state.name}
                                  </Option>
                                ))}
                            </Select>
                          ) : (
                            <Input />
                          )
                        )}
                      </FormItem>
                    );
                  }
                )
              ) : (
                <div className="readOnly-address">
                  {!isAddressEmpty(data, foreignKey) ? (
                    <>
                      <div>
                        {`${data &&
                          data[foreignKey] &&
                          data[foreignKey].address_line1}, ${(data &&
                          data[foreignKey] &&
                          data[foreignKey].address_line2) ||
                          ''}`}
                      </div>
                      <div>{`${data &&
                        data[foreignKey] &&
                        data[foreignKey].city}, ${data &&
                        data[foreignKey] &&
                        data[foreignKey].state}`}</div>
                      <div>{`${data &&
                        data[foreignKey] &&
                        data[foreignKey].zipcode}`}</div>
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              )}
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default Address;
