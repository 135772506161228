import React from 'react';
import {useMask} from '@react-input/mask';
import {numberInUSFormat} from 'mui-core/utils';
import DebouncedInput from 'mui-core/DebouncedInput';
import {useNumberFormat} from '@react-input/number-format';
import {useTheme, Typography, FormControl, FormHelperText} from '@mui/material';

const MaskInputField = ({
  label,
  InputProps,
  muiInputProps = {},
  masktype = 'currency',
  formControlProps = {},
}) => {
  const {onChange, value = '', ...restMuiInputProps} = muiInputProps || {};

  let inputFormats = {};

  if (masktype === 'phone') {
    inputFormats = {
      mask: '___ ___ ____',
      replacement: {_: /\d/},
    };
  } else if (masktype === 'zipcode') {
    inputFormats = {
      mask: '_____-____',
      replacement: {_: /\d/},
    };
  }

  const inputRef = useMask(inputFormats);
  const decimalsRef = useNumberFormat({
    locales: 'en',
    maximumFractionDigits: 2,
  });

  const handleMasking = e => {
    if (masktype === 'currency') {
      const stringWithoutCommas = e?.target?.value?.replace(/,/g, '');
      onChange(stringWithoutCommas);
    } else if (masktype === 'phone') {
      onChange(e?.target?.value);
    } else if (masktype === 'zipcode') {
      onChange(e?.target?.value);
    }
  };

  const commonObj = {
    ...restMuiInputProps,
    onChange: handleMasking,
    value: masktype === 'currency' ? numberInUSFormat(value) : value,
  };

  return (
    <>
      {masktype === 'phone' ? (
        <DebouncedInput
          label={label}
          inputRef={inputRef}
          InputProps={InputProps}
          muiInputProps={commonObj}
          formControlProps={formControlProps}
        />
      ) : masktype === 'currency' ? (
        <DebouncedInput
          label={label}
          inputRef={decimalsRef}
          InputProps={InputProps}
          muiInputProps={commonObj}
          formControlProps={formControlProps}
        />
      ) : masktype === 'zipcode' ? (
        <DebouncedInput
          label={label}
          inputRef={inputRef}
          InputProps={InputProps}
          muiInputProps={commonObj}
          formControlProps={formControlProps}
        />
      ) : (
        <DebouncedInput
          label={label}
          inputRef={decimalsRef}
          InputProps={InputProps}
          muiInputProps={commonObj}
          formControlProps={formControlProps}
        />
      )}
    </>
  );
};

export default MaskInputField;
