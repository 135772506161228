import * as TYPES from './constants';

// get jobs List
export const requestEmployerJobs = () => ({
  type: TYPES.REQUEST_EMPLOYER_JOBS,
});
export const receiveEmployerJobs = json => ({
  type: TYPES.RECEIVE_EMPLOYER_JOBS,
  payload: json,
});
export const handleEmployerJobsError = err => ({
  type: TYPES.EMPLOYER_JOBS_ERROR,
  err,
});
export const clearJobs = () => ({
  type: TYPES.CLEAR_EMPLOYER_JOBS,
});
export const employerJobStatusUpdate = () => ({
  type: TYPES.EMPLOYER_JOB_STATUS_UPDATE,
});

// create job - experience level
export const requestEmployerExperienceLevel = () => ({
  type: TYPES.REQUEST_EMPLOYER_EXPERIENCE_LEVEL,
});
export const receiveEmployerExperienceLevel = json => ({
  type: TYPES.RECEIVE_EMPLOYER_EXPERIENCE_LEVEL,
  payload: json,
});
export const handleEmployerExperienceLevelError = err => ({
  type: TYPES.EMPLOYER_EXPERIENCE_LEVEL_ERROR,
  err,
});

// create job - education level
export const requestEmployerEducationLevel = () => ({
  type: TYPES.REQUEST_EMPLOYER_EDUCATION_LEVEL,
});
export const receiveEmployerEducationLevel = json => ({
  type: TYPES.RECEIVE_EMPLOYER_EDUCATION_LEVEL,
  payload: json,
});
export const handleEmployerEducationLevelError = err => ({
  type: TYPES.EMPLOYER_EDUCATION_LEVEL_ERROR,
  err,
});

// create job - Qualifying programs
export const requestQualifyingPrograms = () => ({
  type: TYPES.REQUEST_QUALIFYING_PROGRAMS,
});
export const receiveQualifyingPrograms = json => ({
  type: TYPES.RECEIVE_QUALIFYING_PROGRAMS,
  payload: json,
});
export const handleQualifyingProgramsError = err => ({
  type: TYPES.QUALIFYING_PROGRAMS_ERROR,
  err,
});
export const handleQualifyingProgramsClear = err => ({
  type: TYPES.QUALIFYING_PROGRAMS_CLEAR,
});
