import * as TYPES from 'redux/types';
import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_NOTIFICATION_SETTINGS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_NOTIFICATION_SETTINGS:
      return {
        ...state,
        data: {...action.json},
        request: false,
      };
    case TYPES.NOTIFICATION_SETTINGS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };

    default:
      return state;
  }
};

export const notificationsSettingsSelector = ({notificationSettings = {}}) =>
  notificationSettings;

const receiveNotificationSettings = json => {
  return {
    type: TYPES.RECEIVE_NOTIFICATION_SETTINGS,
    json,
  };
};
const requestNotificationSettingsError = err => ({
  type: TYPES.NOTIFICATION_SETTINGS_ERROR,
  err,
});
const requestNotificationSettings = () => {
  return {
    type: TYPES.REQUEST_NOTIFICATION_SETTINGS,
  };
};

export const fetchNotificationSettings = () => (dispatch, getState) => {
  const state = getState();
  let accessToken = null;
  let userId = null;
  if (state && state.auth && state.auth.access) {
    const {
      auth: {loggedinUserId = null, access: {token} = {}},
    } = state;
    userId = loggedinUserId;
    accessToken = token;
  }

  if (accessToken && userId) {
    const config = {
      method: 'GET',
      mode: 'cors',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
      cache: 'default',
    };
    dispatch(requestNotificationSettings());
    try {
      return fetch(`${API.outreach.notification_settings}${userId}/`, config)
        .then(response => response.json())
        .then(json => {
          dispatch(receiveNotificationSettings(json));
        })
        .catch(err => {
          console.log(err);
          dispatch(requestNotificationSettingsError(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(requestNotificationSettingsError(err));
    }
  }
};

// export const fetchNotificationSettings = () => ({
//   type: TYPES.APIREQUEST,
//   payload: {
//     endpoint: `${API.outreach.notification_settings}`,
//     method: 'GET',
//     server: 'outreach',
//     onsuccess: receiveNotificationSettings,
//     onerror: requestNotificationSettingsError,
//   },
// });
