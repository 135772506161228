import * as TYPES from './constants';
import {
  requestBusinessPartnerJobs,
  receiveBusinessPartnerJobs,
  handleBusinessPartnerJobsError,
  changeBusinessPartnerJobsFirstTimeFetchStatus,
} from './actions';

import {JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import {API, DefaultPageSize, PageStart} from 'config/businesspartner.config';

const initialState = {
  data: null,
  totalJobs: 0,
  request: null,
  error: null,
  isfetchingfirstTime: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_BUSINESS_PARTNER_JOBS:
      return {
        ...state,
        request: true,
      };
    case TYPES.CHANGE_BUSINESS_PARTNER_JOBS_FIRST_FETCH:
      return {
        ...state,
        isfetchingfirstTime: action.payload,
      };
    case TYPES.RECEIVE_BUSINESS_PARTNER_JOBS:
      return {
        ...state,
        data: (action.payload && action.payload.job_details) || [],
        totalJobs:
          (action.payload &&
            action.payload.count_details &&
            action.payload.count_details.total_jobs) ||
          0,
        activeJobs:
          (action.payload &&
            action.payload.count_details &&
            action.payload.count_details.active_jobs) ||
          0,
        request: false,
        error: false,
      };
    case TYPES.BUSINESS_PARTNER_JOBS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchBusinessPartnerJobs = (
  pageSize = DefaultPageSize,
  page = PageStart,
  sortBy = 'asc',
  filterBy = 'all',
  searchTerm = ''
) => (dispatch, getState) => {
  const {
    auth: {access: {token = null} = {}},
    businessPartnerAdmin: {
      jobs: {isfetchingfirstTime},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestBusinessPartnerJobs());
  try {
    return fetch(
      `${
        API.administration.jobs
      }?page_size=${pageSize}&page=${page}&sort=${sortBy}&filter=${filterBy}&search=${searchTerm}`,
      config
    )
      .then(response => response.json())
      .then(json => {
        if (isfetchingfirstTime) {
          if (
            filterBy !== 'all' ||
            searchTerm !== '' ||
            page !== PageStart ||
            sortBy !== 'asc'
          ) {
            dispatch(changeBusinessPartnerJobsFirstTimeFetchStatus(false));
          }
        }
        dispatch(receiveBusinessPartnerJobs(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleBusinessPartnerJobsError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleBusinessPartnerJobsError(err));
  }
};
