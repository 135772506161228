import * as TYPES from './constants';
import {
  requestBusinessPartnerOpportunityDetails,
  receiveBusinessPartnerOpportunityDetails,
  handleBusinessPartnerOpportunityDetailsError,
  requestBusinessPartnerOpportunityStudentList,
  receiveBusinessPartnerOpportunityStudentList,
} from './actions';
import {Services} from 'core';
import {API} from 'config/businesspartner.config';

const initialState = {
  data: null,
  request: false,
  error: false,
  studentsList: null,
  pageDetails: {},
  isFetchingStudentList: false,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_BUSINESS_PARTNER_OPPORTUNITY_DETAILS:
      return {
        ...state,
        request: true,
      };
    case TYPES.REQUEST_BUSINESS_PARTNER_OPPORTUNITY_STUDENT_LIST:
      return {
        ...state,
        isFetchingStudentList: true,
      };
    case TYPES.RECEIVE_BUSINESS_PARTNER_OPPORTUNITY_DETAILS:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.BUSINESS_PARTNER_OPPORTUNITY_DETAILS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
        isFetchingStudentList: false,
      };
    case TYPES.RECEIVE_BUSINESS_PARTNER_OPPORTUNITY_STUDENT_LIST:
      return {
        ...state,
        studentsList: (action.payload && action.payload.data) || [],
        pageDetails: (action.payload && action.payload.page_details) || {},
        error: false,
        request: false,
        isFetchingStudentList: false,
      };
    case TYPES.RECEIVE_BUSINESS_PARTNER_OPPORTUNITY_DATA_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const fetchBusinessPartnerOpportunityDetails = opportunityID => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestBusinessPartnerOpportunityDetails());
  try {
    return services
      .fetchRecords(
        `${API.administration.opportunities}${opportunityID}`,
        token
      )
      .then(opportunityDetails => {
        dispatch(
          receiveBusinessPartnerOpportunityDetails(opportunityDetails[0])
        );
      })
      .catch(err => {
        console.log(err);
        dispatch(handleBusinessPartnerOpportunityDetailsError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleBusinessPartnerOpportunityDetailsError(err));
  }
};

export const fetchBusinessPartnerOpportunityStudentList = (
  opportunityId,
  pageSize = 10,
  pageNo = 1,
  sortBy = 'recent',
  searchTerm = ''
) => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestBusinessPartnerOpportunityStudentList());
  try {
    return services
      .fetchRecords(
        `${
          API.administration.business_partner_opportunity_student_list
        }?opportunity_uuid=${opportunityId}&page_size=${pageSize}&page=${pageNo}&sort=${sortBy}&search=${searchTerm}`,
        token
      )
      .then(studentList => {
        dispatch(receiveBusinessPartnerOpportunityStudentList(studentList));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleBusinessPartnerOpportunityDetailsError(err));
      });
  } catch (error) {
    console.log(error);
    dispatch(handleBusinessPartnerOpportunityDetailsError(error));
  }
};
