import {createSelector} from 'reselect';
import {
  calculateQuaterlyMonthsData,
  getSortedUniqueYears,
  prepareTimelineLables,
  prepareTimeLineDatasets,
} from 'core';

// Static Data for TimeLine Manipulations
const staticTimeLineData = {
  labels: [],
  datasets: [
    {
      key: 'All',
      label: 'Total Partners',
      data: [],
      borderColor: 'rgb(250, 180, 10)',
      backgroundColor: 'rgb(250, 180, 10)',
    },
    {
      key: 'Active',
      label: 'Active Partners',
      data: [],
      borderColor: 'rgb(26, 178, 102)',
      backgroundColor: 'rgb(26, 178, 102)',
    },
    {
      key: 'Inactive',
      label: 'Inactive Partners',
      data: [],
      borderColor: 'rgb(255, 65, 65)',
      backgroundColor: 'rgb(255, 65, 65)',
    },
  ],
};

export const getPartnersGraphDataSelector = state =>
  state.partners.graphData.data;
export const getPartnersListSelector = state =>
  state.partners.srmPartners.partnersList;
export const getPartnersErrorSelector = state =>
  state.partners.srmPartners.error;
export const getPartnersRequestSelector = state =>
  state.partners.srmPartners.request;
const monthsDataSelector = state => state.partners.graphData.monthsData;

export const partnerUsersSelector = state => state.partners.partnerUsers;

// calculate graph data :::
export const partnersGraphData = createSelector(
  [getPartnersGraphDataSelector, monthsDataSelector],
  (getPartnersGraphDataSelector, monthsDataSelector) => {
    if (getPartnersGraphDataSelector) {
      const timeLineData = {};
      const data = getPartnersGraphDataSelector;
      const uniqYears = getSortedUniqueYears(data);
      const labels = prepareTimelineLables(data, uniqYears);
      const datasets = prepareTimeLineDatasets(
        staticTimeLineData,
        data,
        uniqYears,
        monthsDataSelector
      );
      timeLineData.labels = labels;
      timeLineData.datasets = datasets;

      if (monthsDataSelector !== 'all') {
        timeLineData.labels = calculateQuaterlyMonthsData(
          monthsDataSelector,
          timeLineData.labels,
          uniqYears
        );
      }
      return timeLineData;
    }
  }
);

export const getPartnersList = createSelector(
  [
    getPartnersRequestSelector,
    getPartnersListSelector,
    getPartnersErrorSelector,
  ],
  (
    getPartnersRequestSelector,
    getPartnersListSelector,
    getPartnersErrorSelector
  ) => ({
    request: getPartnersRequestSelector,
    error: getPartnersErrorSelector,
    data: getPartnersListSelector,
  })
);
