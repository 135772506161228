import React from 'react';
import {View, Text} from '@react-pdf/renderer';

import SkillTag from './SkillTag';

export default ({styles, studentProfile}) => {
  const {interest = []} = studentProfile || {};

  const Interests = () => {
    return interest && Array.isArray(interest) && interest.length > 0 ? (
      interest.map((int, idx) => (
        <SkillTag key={`int-${idx}`}>
          <Text key={idx}>{int.trim()}</Text>
        </SkillTag>
      ))
    ) : (
      <Text style={styles.noSkills}>N/A</Text>
    );
  };
  return (
    <View style={styles.section}>
      <Text style={styles.label}>Interests</Text>
      <View style={styles.SkillsList}>{Interests()}</View>
    </View>
  );
};
