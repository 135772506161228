import {combineReducers} from 'redux';

import partners from './partners';
import partnersGraph from './partnersGraph';
import partnerUsers from './partnerUsers';

export const srmPartnersReducers = combineReducers({
  srmPartners: partners,
  graphData: partnersGraph,
  partnerUsers: partnerUsers,
});
