import React from 'react';
import {Dropdown, Menu} from 'antd';

import {isArray} from 'core';

const MenuItem = Menu.Item;

const createMenu = (menuData, stopPropagation) =>
  (menuData && (
    <Menu>
      {menuData &&
        menuData.map(
          ({
            key,
            func,
            passingData,
            menuItem,
            funcType,
            hide,
            render,
            disabled = false,
          }) =>
            (!hide && (
              <MenuItem key={key} disabled={disabled}>
                {render ? (
                  render
                ) : funcType === 'callByref' && !passingData ? (
                  <span
                    style={{pointerEvents: disabled ? 'none' : 'initial'}}
                    onClick={func}>
                    {menuItem}
                  </span>
                ) : funcType === 'callByref' && passingData ? (
                  <span
                    style={{pointerEvents: disabled ? 'none' : 'initial'}}
                    onClick={func(passingData)}>
                    {menuItem}
                  </span>
                ) : isArray(passingData) ? (
                  <span
                    style={{pointerEvents: disabled ? 'none' : 'initial'}}
                    onClick={e => {
                      if (stopPropagation) e.stopPropagation();
                      func(...passingData);
                    }}>
                    {menuItem}
                  </span>
                ) : (
                  <span
                    style={{pointerEvents: disabled ? 'none' : 'initial'}}
                    onClick={e => {
                      if (stopPropagation) e.stopPropagation();
                      func(passingData);
                    }}>
                    {menuItem}
                  </span>
                )}
              </MenuItem>
            )) ||
            ''
        )}
    </Menu>
  )) ||
  'No menu items';

const EllipseMenu = ({classes, menuData, ellipseClass, stopPropagation}) => {
  let showMenu = true;
  if (menuData) {
    const hideItemList = menuData.filter(({hide}) => hide === true);
    if (hideItemList.length === menuData.length) {
      showMenu = false;
    }
  }
  return (
    showMenu && (
      <div className={`card-action-icons ${ellipseClass ? ellipseClass : ''}`}>
        <Dropdown
          className={classes}
          overlay={createMenu(menuData, stopPropagation)}
          trigger={['click']}
          overlayClassName="card-action-icons-dropdown">
          {stopPropagation ? (
            <span
              onClick={e => e.stopPropagation()}
              className="icon-more-horizontal icon-more"
            />
          ) : (
            <span className="icon-more-horizontal icon-more" />
          )}
        </Dropdown>
      </div>
    )
  );
};

export default EllipseMenu;
