import * as TYPES from './constants';
import {
  requestFilterPaths,
  receiveFilterPaths,
  handleFilterPathsError,
} from './actions';
import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {
  data: null,
  pathCounts: null,
  graphData: null,
  request: null,
  error: null,
  monthsData: 'all',
  apiRequestTime: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_FILTER_PATHS:
      return {
        ...state,
        request: true,
      };
    case TYPES.PATHWAYS_MONTHS_FILTER:
      return {
        ...state,
        monthsData: action.months,
      };
    case TYPES.RECEIVE_FILTER_PATHS:
      return {
        ...state,
        data: action.payload.data,
        pathCounts: action.payload.pathCounts,
        graphData: action.payload.graphData,
        request: false,
        error: false,
      };
    case TYPES.REMOVE_FILTER_PATHS:
      return {
        ...state,
        data: null,
        pathCounts: null,
        graphData: null,
      };
    case TYPES.REQUEST_FILTER_PATHS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.REQUEST_TIME:
      return {
        ...state,
        apiRequestTime: action.requestTime,
      };
    case TYPES.REQUEST_FILTER_PATHS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

const setApiRequest = requestTime => ({
  type: TYPES.REQUEST_TIME,
  requestTime,
});

export const fetchFilterPathDetails = filterOptions => (dispatch, getState) => {
  const state = getState();
  let accessToken = null;
  if (state && state.auth) {
    const {
      auth: {
        access: {token},
      },
    } = state;
    accessToken = token;
  }

  dispatch(setApiRequest(filterOptions === 'firstTime' ? 'firstTime' : null));

  if (accessToken) {
    const defaultFilter = {
      uuid: '',
      from_date: '',
      to_date: '',
    };

    const config = {
      method: 'POST',
      mode: 'cors',
      cache: 'default',
      headers: Object.assign({}, JSON_HEADER, JWT_HEADER(accessToken)),
      body: JSON.stringify(
        filterOptions && filterOptions !== 'firstTime'
          ? filterOptions
          : defaultFilter
      ),
    };

    dispatch(requestFilterPaths());

    try {
      return fetch(`${API.pathway.filter_paths}`, config)
        .then(response => response.json())
        .then(json => {
          // const careerPaths = [];
          // json.paths &&
          //   json.paths.map(paths => {
          //     if (paths && paths.length) {
          //       careerPaths.push(...paths);
          //     }
          //   });

          const obj = {
            data: null,
            // careerPaths.sort(
            //   (currentItem, nextItem) => currentItem.id - nextItem.id
            // ),
            pathCounts: json.overview_metrics,
            graphData: json.graph_data,
          };

          dispatch(receiveFilterPaths(obj));
        })
        .catch(err => {
          dispatch(handleFilterPathsError(err));
        });
    } catch (err) {
      dispatch(handleFilterPathsError(err));
    }
  }
};
