import React from 'react';
import {Button} from 'mui-core';
import {Stack} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const Navigation = ({
  steps,
  activeStep,
  handleNext,
  handleCancel,
  handleSubmit,
  handlePrevious,
}) => {
  return (
    <Stack direction={'row'} justifyContent={'end'} gap={4} my={4} mr={4}>
      <Button title="Cancel" onClick={handleCancel} variant="outlined">
        Cancel
      </Button>
      {activeStep > 0 && (
        <Button title="Previous" onClick={handlePrevious} variant="outlined">
          <KeyboardArrowLeftIcon /> Previous
        </Button>
      )}
      {activeStep < steps.length && activeStep !== steps.length - 1 && (
        <Button title="Next" onClick={handleNext} variant="contained">
          Next Step <KeyboardArrowRightIcon />
        </Button>
      )}
      {activeStep === steps.length - 1 && (
        <Button title="Publish" onClick={handleSubmit} variant="contained">
          Publish
        </Button>
      )}
    </Stack>
  );
};
