import React, {Component, useState, useEffect} from 'react';
import {Row, Col, Popover, Progress, Icon, Tooltip, Table, Modal} from 'antd';
import CourseDetailsModal from './course-details-modal';
import mileStoneIcon from 'sharedResources/assets/icons/milestone-icon.png';
import awardImg from 'sharedResources/assets/icons/trophy.svg';
import './styles.css';

const formatNoOfUnits = data => {
  if (data && data.includes('.')) {
    const arr = data.split('.');
    const beforeDecimal = arr[0];
    const afterDecimal = arr[1];
    if (afterDecimal === '0' || afterDecimal === '00') {
      return beforeDecimal;
    }
    if (afterDecimal.endsWith('0')) {
      const uptoOnedecimal = afterDecimal.split()[0];
      return `${beforeDecimal}.${uptoOnedecimal}`;
    }
  }
  return data;
};

const PopOverCourseComponent = props => {
  const {
    data: {
      is_elective,
      total_units_required,
      path_title,
      course_name,
      course_id,
      credit_status,
      transferable,
      description,
      units,
      pre_requisites,
      co_requisites,
      course_instruction_type_1,
      course_instruction_type_2,
      num_of_hours_1,
      num_of_hours_2,
    } = {},
    closePopup,
  } = props || {};
  return is_elective ? (
    <div className="__PopOverContent _elective">
      <div className="close-btn" onClick={closePopup}>
        <i className="fa fa-angle-times" />
      </div>
      <div className="__ttl-bar">
        <h1>Elective</h1>
      </div>
      <div className="_bottom-section">
        <p>
          In order to complete the{' '}
          <b>
            <span className="color-primary">{path_title}</span>
          </b>
          pathway, students must complete a total of{' '}
          <b>
            <span className="color-primary">{total_units_required}</span>
          </b>
          units. Students may elect to take any program-applicable courses to
          satisfy the required number of units.
        </p>
      </div>
    </div>
  ) : (
    <div className="__PopOverContent">
      <div className="close-btn" onClick={closePopup}>
        <i className="fa fa-angle-times" />
      </div>
      <div className="__ttl-bar">
        <div className="__ttl">
          {course_name
            ? course_id + ' - ' + course_name
            : props.data.title
            ? course_id + ' - ' + props.data.title
            : 'Not Available'}
        </div>
        <div className="__sub-ttl">
          {credit_status ? credit_status : 'Not Available'}
        </div>
        <div className="__sub-ttl">
          Transfer: {transferable ? transferable : 'Not Available'}
        </div>
        <div className="__sub-ttl1">{description ? description : '-'}</div>
        {/* <div className="__sub-ttl1">{props.data && props.data.units ? props.data.units : 'NA'} units: {props.data && props.data.hours ? props.data.hours + ' hour lecture' : 'NA'} </div> */}
      </div>
      <div className="_bottom-section">
        <div className="show-info-true">
          Units: <span>{units ? units : '-'}</span>
        </div>
        <div className={'show-info-' + Boolean(course_instruction_type_1)}>
          {course_instruction_type_1}:{' '}
          <span>{num_of_hours_1 ? `${num_of_hours_1} hours` : '-'}</span>
        </div>
        <div className={'show-info-' + Boolean(course_instruction_type_2)}>
          {course_instruction_type_2}:{' '}
          <span>{num_of_hours_2 ? `${num_of_hours_2} hours` : '-'}</span>
        </div>
        <div className="show-info-true">
          Pre-requisite(s):{' '}
          <span>
            {pre_requisites && pre_requisites.length
              ? pre_requisites.toString().replace(/,/gi, ', ')
              : '-'}
          </span>
        </div>
        <div className="show-info-true">
          Co-requisite(s):{' '}
          <span>
            {co_requisites && co_requisites.length
              ? co_requisites.toString().replace(/,/gi, ', ')
              : '-'}
          </span>
        </div>
        {/* <div>Location: {props.data && props.data.course_location ? props.data.course_location : 'NA'}</div>
        <div>Term Offered: {props.data && props.data.term ? props.data.term : 'NA'}</div>
        <div>Length of Course: {props.data && props.data.hours ? props.data.hours : 'NA'}</div> */}
      </div>
    </div>
  );
};

const PopOverSegmentComponent = props => {
  const [courseSubPopVisible, setCourseSubPopVisible] = useState(-1);
  useEffect(() => {
    if (!props.visible) setCourseSubPopVisible(-1);
  });
  const {
    data: {
      segment_name,
      rule_type,
      number_of_required_course,
      courses = [],
    } = {},
  } = props || {};
  const tbleColumns = [
    {
      title: 'COURSE ID',
      dataIndex: 'course_id',
      key: 'id',
      render: (k, i, idx) => (
        // <Popover
        //   content={
        //     <PopOverCourseComponent
        //     data={i}
        //     closePopup={()=>setCourseSubPopVisible(-1)} />
        //   }
        //   trigger="click"
        //   visible={courseSubPopVisible === idx}
        //   onVisibleChange={()=>setCourseSubPopVisible(idx)}
        //   overlayClassName="__PopOverContentOuter"
        //   placement="bottomLeft">
        <span
          className="cursor-pointer hover-color-primary"
          onClick={() =>
            props.toggleViewCourse ? props.toggleViewCourse(i) : {}
          }>
          {k}
        </span>
        // </Popover>
      ),
    },
    {
      title: 'COURSE TITLE',
      dataIndex: 'course_name',
      key: 'course_name',
    },
    {
      title: 'UNITS',
      dataIndex: 'units',
      key: 'units',
    },
  ];
  return (
    <div className="__PopOverContent _segment">
      <div className="close-btn" onClick={props.closePopup}>
        <i className="fa fa-angle-times" />
      </div>
      <div className="__ttl-bar">
        <h1>{segment_name}</h1>
        <p>
          To satisfy this requirement, all of the following rules must be met:
        </p>
        {number_of_required_course && (
          <div className="_list">
            <Icon type="caret-right" />
            <b>
              {rule_type === 'Choose number of course(s)' &&
                `Rule: Choose
                                    ${number_of_required_course} course(s)`}
              {rule_type === 'Complete number of unit(s)' &&
                `Rule: Complete
                                    ${formatNoOfUnits(
                                      number_of_required_course
                                    )} unit(s)`}
            </b>
          </div>
        )}
      </div>
      <div className="_bottom-section">
        <Table
          dataSource={courses}
          bordered
          className="orange-theme-table"
          columns={tbleColumns}
          pagination={false}
        />
        {/* <div className="show-info-true">Units: <span>{props.data && props.data.units ? props.data.units : '-'}</span></div>
        <div className={'show-info-' + Boolean(props.data && props.data.course_instruction_type_1)}>{props.data && props.data.course_instruction_type_1}: <span>{props.data && props.data.num_of_hours_1 ? `${props.data.num_of_hours_1} hours` : '-'}</span></div>
        <div className={'show-info-' + Boolean(props.data && props.data.course_instruction_type_2)}>{props.data && props.data.course_instruction_type_2}: <span>{props.data && props.data.num_of_hours_2 ? `${props.data.num_of_hours_2} hours` : '-'}</span></div>
        <div className="show-info-true">Pre-requisite(s): <span>{props.data && props.data.pre_requisites && props.data.pre_requisites.length ? props.data.pre_requisites.toString().replace(/,/gi,", ") : '-'}</span></div>
        <div className="show-info-true">Co-requisite(s): <span>{props.data && props.data.co_requisites && props.data.co_requisites.length ? props.data.co_requisites.toString().replace(/,/gi,", ") : '-'}</span></div> */}
        {/* <div>Location: {props.data && props.data.course_location ? props.data.course_location : 'NA'}</div>
        <div>Term Offered: {props.data && props.data.term ? props.data.term : 'NA'}</div>
        <div>Length of Course: {props.data && props.data.hours ? props.data.hours : 'NA'}</div> */}
      </div>
    </div>
  );
};

class CourseMap extends Component {
  state;
  stepContainersRef = React.createRef();
  constructor(state) {
    super(state);
    this.state = {
      ui: {
        stepContainersRef: null,
        noOfItemsPerRow: 0,
        itemWidth: 300,
        totalRows: 0,
      },
      items: this.props.items,
      pathContainerMaxWidth: 0,
      pathDirectionArray: [],
      itemClicked: -1,
      viewCourse: null,
    };
  }

  componentDidMount() {
    this.initUi();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.listenForWindowResize);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.props.items) {
      this.setState(
        {
          items: nextProps.items,
        },
        () => {
          this.arrangeSteps();
        }
      );
    }
  }

  toggleViewCourse = course => {
    if (course && course.segment_name) return;
    this.setState({viewCourse: course ? course : null});
  };

  initUi = () => {
    this.setState(
      {
        ui: {
          ...this.state.ui,
          stepContainersRef: this.stepContainersRef.current,
        },
      },
      () => {
        window.addEventListener('resize', this.listenForWindowResize);
        this.arrangeSteps();
      }
    );
  };

  listenForWindowResize = e => {
    this.arrangeSteps();
  };

  arrangeSteps = () => {
    let courses = Array.isArray(this.state.items)
      ? this.state.items
      : this.state.items && Array.isArray(this.state.items.courses)
      ? this.state.items.courses
      : null;
    if (!courses || courses.length === 0) return;

    this.state.ui.stepContainersRef.style.overflow = 'hidden';
    const noOfItemsPerRow = parseInt(
      this.state.ui.stepContainersRef.offsetWidth / this.state.ui.itemWidth
    );
    const totalRows = Math.ceil(courses.length / noOfItemsPerRow);
    const pathContainerMaxWidth = this.state.ui.itemWidth * noOfItemsPerRow;
    const pathDirectionArray =
      totalRows !== Infinity && totalRows > 0 ? new Array(totalRows - 1) : [];
    pathDirectionArray.fill('');
    this.state.ui.stepContainersRef.style.overflow = 'initial';
    this.setState({
      noOfItemsPerRow,
      pathContainerMaxWidth,
      pathDirectionArray,
    });
  };

  onItemClick = (item, index) => {
    if (this.props.onItemClick) this.props.onItemClick(item, index);
  };

  popOverVisibleChange = (index, visible) => {
    this.setState({
      itemClicked: visible ? index : -1,
    });
  };

  getCourseType = item => {
    return item && item.course_type === 'core'
      ? 'Core'
      : item && item.course_type === 'elective'
      ? 'Elective'
      : 'General Ed';
  };

  renderPopUpContent = (item, index) => {
    return item && item.segment_name ? (
      <PopOverSegmentComponent
        data={item}
        toggleViewCourse={this.toggleViewCourse}
        closePopup={this.popOverVisibleChange.bind(this, index, false)}
        visible={this.state.itemClicked === index}
      />
    ) : (
      <PopOverCourseComponent
        data={item}
        closePopup={this.popOverVisibleChange.bind(this, index, false)}
      />
    );
  };

  render() {
    const {hideAward, isRecommendedPlan, noClick} = this.props;
    let courses = Array.isArray(this.state.items)
      ? this.state.items
      : this.state.items && Array.isArray(this.state.items.courses)
      ? this.state.items.courses
      : null;
    let totalUnits = 0;
    courses &&
      courses.forEach(course => {
        if (course && course.units && course.units !== 'None') {
          totalUnits += parseFloat(course.units);
        }
      });
    return (
      <div
        className={'CourseMap' + (this.props.isEnd ? ' end ' : '')}
        style={hideAward ? {marginBottom: 0} : {}}>
        <Row type="flex" justify="center">
          <Col md={24}>
            <div
              id="steps-container"
              ref={this.stepContainersRef}
              className="steps-container">
              {courses && courses.length === 0 && (
                <div className="_no-courses" style={{zIndex: 3}}>
                  {!this.props.isPlanned && (
                    <span>
                      <Icon type="exclamation-circle" /> No courses found.
                    </span>
                  )}
                  {this.props.isPlanned && (
                    <span>No courses have been planned for this term.</span>
                  )}
                </div>
              )}
              {courses &&
                courses.map((item, index) => (
                  <div
                    className={
                      'step-item-outer ' +
                      (this.state.itemClicked === index ? '_active' : '')
                    }
                    style={noClick ? {pointerEvents: 'none'} : {}}
                    onClick={() =>
                      noClick ? null : this.toggleViewCourse(item)
                    }
                    key={index}>
                    <Popover
                      content={this.renderPopUpContent(item, index)}
                      trigger="click"
                      overlayClassName="__PopOverContentOuter"
                      placement="bottomLeft"
                      visible={this.state.itemClicked === index}
                      // onVisibleChange={() => {
                      //   item.segment_name
                      //     ? this.popOverVisibleChange(index, true)
                      //     : {};
                      // }}
                    >
                      <div
                        className={' step-item ' + this.getCourseType(item)}
                        onClick={this.onItemClick.bind(this, item, index)}>
                        <div className="_top">
                          {/* <div className="_index flex flex-center">{index + 1}</div> */}
                          <div className="_id">
                            <Tooltip
                              title={
                                item.course_id ||
                                item.segment_name ||
                                item.course_name ||
                                item.title
                              }>
                              {item.course_id ||
                                item.segment_name ||
                                item.course_name ||
                                item.title}
                            </Tooltip>
                            {item && item.units && (
                              <div className="_units">{item.units} Units</div>
                            )}
                          </div>
                          {!this.props.hideTag && (
                            <div
                              className={`_type ${item && item.course_type}`}>
                              {this.getCourseType(item)}
                            </div>
                          )}
                          <div className="status_icon">
                            <Icon
                              type={
                                item.status === 'progress' ? 'redo' : 'check'
                              }
                            />
                          </div>
                          <Progress
                            className="_progress"
                            showInfo={false}
                            percent={item.progress * 100}
                          />
                        </div>
                        <div className="_bottom">
                          <div className="_title">
                            {item &&
                              item.segment_name &&
                              item.number_of_required_course && (
                                <div className="_sgmnet">
                                  <Icon type="info-circle" theme="filled" />
                                  {item &&
                                    item.rule_type ===
                                      'Choose number of course(s)' &&
                                    `Choose
                                    ${item &&
                                      item.number_of_required_course} course(s)`}
                                  {item &&
                                    item.rule_type ===
                                      'Complete number of unit(s)' &&
                                    `Complete
                                    ${formatNoOfUnits(
                                      item && item.number_of_required_course
                                    )} unit(s)`}
                                </div>
                              )}
                            {item &&
                              !item.is_elective &&
                              (item.course_name || item.title) && (
                                <div className="_course">
                                  <span
                                    className="_name"
                                    title={item.course_name || item.title}>
                                    {item.course_name || item.title}
                                  </span>
                                  {/* {item && item.units && <span className="_units"> ({item.units} units)</span>} */}
                                </div>
                              )}
                          </div>
                        </div>

                        {/* commented for GPS-1145
                       {( ( ((index + 1) % this.state.noOfItemsPerRow !== 0) && (this.state.items.length !== (index + 1)) ) ) ?
                         <div className="_arrow">
                          <div className="_dotted"/>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21 12l-18 12v-24z"/></svg>
                        </div>
                        : ''
                      } */}
                      </div>
                    </Popover>
                    {item.status === 'completed' &&
                      item.milestones &&
                      item.milestones.length > 0 && (
                        <div
                          className="_milestone-indicator"
                          onClick={() => {
                            if (this.props.onMileStonesClick)
                              this.props.onMileStonesClick(item.milestones);
                          }}>
                          <div className="_count">{item.milestones.length}</div>
                          <img src={mileStoneIcon} alt="" />
                        </div>
                      )}
                  </div>
                ))}

              <div className="_box-outline">
                <div className="_ttl">
                  {isRecommendedPlan
                    ? `Term  ${this.props.term} ${formatNoOfUnits(
                        totalUnits.toFixed(2)
                      )} Units`
                    : `Term  ${this.props.term} (${formatNoOfUnits(
                        totalUnits.toFixed(2)
                      )} Units)`}
                </div>
                {!(this.props.isEnd && hideAward) && (
                  <div
                    className={
                      '_end-award' + (this.props.isEnd ? ' end ' : '')
                    }>
                    <div className="_arw">
                      <div className="_dot" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24">
                        <path d="M21 12l-18 12v-24z" />
                      </svg>
                    </div>
                    {this.props.isEnd && (
                      <div className="_award">
                        <img src={awardImg} alt="award" />
                        <span className="_ttl-last-item">
                          {this.props.achievementOption}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* <div className="_path-container" style={{ maxWidth: this.state.pathContainerMaxWidth + "px" }}>
                {this.state.pathDirectionArray.map((i, index) =>
                  <div className={"_path"} key={index + "_path"}>
                    <div className="_top-right" />
                    <div className="_right" />
                    <div className="_middle" />
                    <div className="_left" />
                    <div className="_bottom-left" />
                    <div className="_arw">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21 12l-18 12v-24z" /></svg>
                    </div>
                  </div>
                )}
              </div> */}
            </div>
          </Col>
        </Row>
        <CourseDetailsModal
          onClose={this.toggleViewCourse}
          course={this.state.viewCourse}
          visible={this.state.viewCourse}
        />
      </div>
    );
  }
}

export default CourseMap;
