import * as TYPES from './constants';
import {
  requestSuperUserInstitutesTimeline,
  receiveSuperUserInstitutesTimeline,
  handleSuperUserInstitutesTimelineError,
} from './actions';

import {Services} from 'core';

import {API} from 'config/superuser.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_SUPER_USER_INSTITUTES_TIMELINE:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_SUPER_USER_INSTITUTES_TIMELINE:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.SUPER_USER_INSTITUTES_TIMELINE_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchSuperUserInstitutesTimeline = () => (dispatch, getState) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();
  dispatch(requestSuperUserInstitutesTimeline());
  let endpoint = `${API.administration.institution_timeline}`;
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receiveSuperUserInstitutesTimeline(res));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleSuperUserInstitutesTimelineError(err));
  }
};
