import * as TYPES from './constants';
import {
  requestConsortiumStates,
  receiveConsortiumStates,
  handleConsortiumStatesError,
} from './actions';

import {Services} from 'core';

import {API} from 'config/consortium.config';

const initialState = {
  data: null,
  error: null,
  request: null,
};

const services = new Services();
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_CONSORTIUM_STATES_DATA:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_CONSORTIUM_STATES_DATA:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.CONSORTIUM_STATES_DATA_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchConsortiumStates = () => (dispatch, getState) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();
  dispatch(requestConsortiumStates());
  // let endpoint = `${API.administration.consortiumStates}?super_type=Consortium`;
  let endpoint = `${API.administration.allConsortiums}`;
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        if (res?.length) {
          const bodyData =
            res.map(rop => ({
              consortium_name: rop.name,
              city: rop.default_msa__city,
              consortium_uuid: rop.institution_id,
              state_code: rop.default_msa__state_code,
            })) || [];
          const body = {data: bodyData};
          dispatch(receiveConsortiumStates(body));
        }
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleConsortiumStatesError(err));
  }
};
