import React, {Component} from 'react';
import {Menu, Icon, Layout} from 'antd';
import {NavLink} from 'react-router-dom';

import {CompanyInfo} from 'core';

const {SubMenu} = Menu;
const {Sider} = Layout;

class Sidebar extends Component {
  state = {
    collapsed: false,
    openKeys: [],
    defaultSelectedKeys: [],
  };

  componentDidMount() {
    const {location} = this.props;
    const strtoken = location && location.pathname.split('/');
    const winWidth = window.innerWidth;

    this.setState({
      openKeys: (strtoken && [strtoken[2]]) || null,
      defaultSelectedKeys: (strtoken && [strtoken[3]]) || null,
      collapsed: winWidth <= 1024 ? true : false,
    });
  }

  toggle = () => {
    this.setState(prevstate => ({
      collapsed: !prevstate.collapsed,
    }));
  };

  getMenuKeys = () => {
    const {data} = this.props;
    const rootSubmenuKeys =
      (data && data.map(subMenuKey => subMenuKey.key)) || [];
    return rootSubmenuKeys;
  };

  onOpenChange = openKeys => {
    const {openKeys: stateopenKeys} = this.state;
    const rootSubmenuKeys = this.getMenuKeys();
    const latestOpenKey = openKeys.find(
      key => stateopenKeys && stateopenKeys.indexOf(key) === -1
    );

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({openKeys});
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  buildMenu = (
    title,
    key,
    imgSrc,
    content,
    mainMenu,
    path,
    keyIndex,
    dynamicKey
  ) => {
    const {modulePermissions} = this.props;

    const rootSubmenuKeys = this.getMenuKeys();

    if (mainMenu) {
      return (
        <Menu.Item key={rootSubmenuKeys[keyIndex]}>
          <NavLink exact to={path}>
            <span className={imgSrc} />
            <span className="menu-category-title">{title}</span>
          </NavLink>
        </Menu.Item>
      );
    }
    const menuContent =
      content &&
      content.map(subItem => {
        dynamicKey++;
        if (modulePermissions) {
          // Only un-comment to meke things work
          // if (modulePermissions.includes(subItem.key)) {
          return this.buildSubMenu(subItem, dynamicKey);
          // }
        } else {
          return this.buildSubMenu(subItem, dynamicKey);
        }
      });
    return (
      menuContent &&
      menuContent.filter(content => content !== undefined).length > 0 && (
        <SubMenu
          key={rootSubmenuKeys[keyIndex]}
          title={
            <span className="menu-category">
              <span className={imgSrc} />
              <span className="menu-category-title">{title}</span>
            </span>
          }>
          {menuContent}
        </SubMenu>
      )
    );
  };

  buildSubMenu = (subItem, dynamicKey) => {
    const {bannerData = {}} = this.props;
    const bannerKeys = Object.keys(bannerData);
    return (
      <Menu.Item key={dynamicKey}>
        <NavLink exact to={subItem.path}>
          <Icon type="caret-right" />
          {`${subItem.submenuTitle} ${
            bannerKeys.includes(subItem.key)
              ? `(${bannerData[subItem.key]})`
              : ''
          }`}
        </NavLink>
      </Menu.Item>
    );
  };
  render() {
    let dynamicKey = 0;
    const {collapsed, openKeys, defaultSelectedKeys} = this.state;
    const {
      data,
      request,
      modulePermissions,
      companyInfo = {},
      editProfile,
      editHoverTitle,
    } = this.props;

    return (
      <>
        <Sider
          className="side-menu"
          trigger={null}
          collapsible
          collapsed={collapsed}>
          <div className="logo-address">
            <Icon
              className="trigger"
              type={collapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={this.toggle}
            />

            <CompanyInfo
              request={request}
              companyInfo={companyInfo}
              editProfile={editProfile}
              editHoverTitle={editHoverTitle}
            />
          </div>
          {
            <div className="main-menu">
              <Menu
                mode="inline"
                openKeys={openKeys}
                onOpenChange={this.onOpenChange}
                defaultSelectedKeys={defaultSelectedKeys}
                style={{height: '100%', borderRight: 0}}>
                {data &&
                  data.map((menuData, keyIndex) => {
                    const {
                      title,
                      key,
                      imgSrc,
                      content,
                      mainMenu,
                      path,
                    } = menuData;
                    if (modulePermissions && modulePermissions.includes(key)) {
                      return this.buildMenu(
                        title,
                        key,
                        imgSrc,
                        content,
                        mainMenu,
                        path,
                        keyIndex,
                        dynamicKey
                      );
                    } else {
                      return this.buildMenu(
                        title,
                        key,
                        imgSrc,
                        content,
                        mainMenu,
                        path,
                        keyIndex,
                        dynamicKey
                      );
                    }
                  })}
              </Menu>
            </div>
          }
        </Sider>
      </>
    );
  }
}

export default Sidebar;
