import React, {useState, useRef, useEffect} from 'react';
import {Table} from 'antd';
import debounce from 'lodash/debounce';

import './style.css';

let tableLastRowOffset = 0;

const TableWithLazyLoad = ({
  rowSelection = null,
  columns,
  data,
  fetchCall,
  page_details,
  request,
}) => {
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(1);
  const ref = useRef();

  useEffect(
    () => {
      setCount(parseInt(page_details.page_num));
      request ? setLoading(true) : setLoading(false);
      !request && data && data.length > 0 && setLoading(false);
    },
    [page_details, data]
  );

  const getLastRowOffset = () => {
    for (const refs in ref.current) {
      const nodeList = ref.current[refs];
      if (nodeList instanceof NodeList) {
        const table = nodeList[0] ? nodeList[0] : null;
        const tBody = table && table.querySelector('tbody');
        const tableLastRow = tBody && tBody.lastChild;
        tableLastRowOffset = (tableLastRow && tableLastRow.offsetTop) || 0;
        break;
      }
    }
  };

  const scrollToLastRow = () => {
    ref.current.scrollTo(0, tableLastRowOffset);
  };
  const debouncedLastRowOffset = debounce(getLastRowOffset, 100);
  const debouncedLastRowScroll = debounce(scrollToLastRow, 100);

  const onScroll = async ({target = {}}) => {
    if (target.scrollHeight - target.scrollTop < target.clientHeight + 2) {
      const pageCount = count + 1;
      if (pageCount <= page_details.no_of_pages) {
        debouncedLastRowOffset();
        setLoading(true);
        await fetchCall(pageCount);
        setLoading(false);
        debouncedLastRowScroll();
      }
    }
  };

  // Table shows 10 records in each load
  return (
    <>
      <Table
        rowSelection={rowSelection && rowSelection}
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{y: 510, scrollToFirstRowOnChange: false}}
        loading={loading}
        components={{
          table: props => (
            <div
              style={{maxHeight: 510, overflowY: 'scroll'}}
              className="ant-table-body scroll"
              ref={ref}
              onScroll={onScroll}>
              <table id="222" {...props} />
            </div>
          ),
        }}
      />
    </>
  );
};

export default TableWithLazyLoad;
