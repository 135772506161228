import * as TYPES from './constants';

// Course Management Courses::
export const requestCourseManagementCourses = () => ({
  type: TYPES.REQUEST_COURSE_MANAGEMENT_COURSES,
});

export const receiveCourseManagementCourses = json => ({
  type: TYPES.RECEIVE_COURSE_MANAGEMENT_COURSES,
  payload: json,
});

export const handleCourseManagementCoursesError = err => ({
  type: TYPES.COURSE_MANAGEMENT_COURSES_ERROR,
  err,
});

// Catalogs::
export const requestCatalog = () => ({
  type: TYPES.REQUEST_CATALOG,
});

export const receiveCataog = json => ({
  type: TYPES.RECEIVE_CATALOG,
  payload: json,
});

export const handleCatalogError = err => ({
  type: TYPES.CATALOG_ERROR,
  err,
});

// Skills::
export const requestSkills = () => ({
  type: TYPES.REQUEST_SKILLS,
});

export const receiveSkills = json => ({
  type: TYPES.RECEIVE_SKILLS,
  payload: json,
});

export const handleSkillsError = err => ({
  type: TYPES.SKILLS_ERROR,
  err,
});

export const addNewSkill = payload => ({
  type: TYPES.ADD_SKILL,
  payload,
});

export const editNewSkill = payload => ({
  type: TYPES.EDIT_SKILL,
  payload,
});

// Course Management Course Credit Status
export const requestCourseManagementCreditStatus = () => ({
  type: TYPES.REQUEST_COURSE_MANAGEMENT_CREDIT_STATUS,
});

export const receiveCourseManagementCreditStatus = json => ({
  type: TYPES.RECEIVE_COURSE_MANAGEMENT_CREDIT_STATUS,
  payload: json,
});

export const handleCourseManagementCreditStatusError = err => ({
  type: TYPES.COURSE_MANAGEMENT_CREDIT_STATUS_ERROR,
  err,
});

// Course Management Course Transfer Type
export const requestCourseManagementCourseTransferType = () => ({
  type: TYPES.REQUEST_COURSE_MANAGEMENT_COURSE_TRANSFER_TYPE,
});

export const receiveCourseManagementCourseTransferType = json => ({
  type: TYPES.RECEIVE_COURSE_MANAGEMENT_COURSE_TRANSFER_TYPE,
  payload: json,
});

export const handleCourseManagementCourseTransferTypeError = err => ({
  type: TYPES.COURSE_MANAGEMENT_COURSE_TRANSFER_TYPE_ERROR,
  err,
});

// Course Management Course Instruction Type
export const requestCourseManagementCourseInstructionType = () => ({
  type: TYPES.REQUEST_COURSE_MANAGEMENT_COURSE_INSTRUCTION_TYPE,
});

export const receiveCourseManagementCourseInstructionType = json => ({
  type: TYPES.RECEIVE_COURSE_MANAGEMENT_COURSE_INSTRUCTION_TYPE,
  payload: json,
});

export const handleCourseManagementCourseInstructionTypeError = err => ({
  type: TYPES.COURSE_MANAGEMENT_COURSE_INSTRUCTION_TYPE_ERROR,
  err,
});
