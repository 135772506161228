export const REQUEST_STUDENTS_METRICS = 'REQUEST_STUDENTS_METRICS';
export const RECEIVE_STUDENTS_METRICS = 'RECEIVE_STUDENTS_METRICS';
export const STUDENTS_METRICS_ERROR = 'STUDENTS_METRICS_ERROR';

// SWF SURVEY
export const REQUEST_SWF_SURVEY_DETAILS = 'REQUEST_SWF_SURVEY_DETAILS';
export const RECEIVE_SWF_SURVEY_DETAILS = 'RECEIVE_SWF_SURVEY_DETAILS';
export const SWF_SURVEY_DETAILS_ERROR = 'SWF_SURVEY_DETAILS_ERROR';
export const REQUEST_SWF_SURVEY_STUDENTS = 'REQUEST_SWF_SURVEY_STUDENTS';
export const RECEIVE_SWF_SURVEY_STUDENTS = 'RECEIVE_SWF_SURVEY_STUDENTS';
export const SWF_SURVEY_STUDENTS_ERROR = 'SWF_SURVEY_STUDENTS_ERROR';

// SWF Survey Institution Terms
export const REQUEST_SWF_INSTITUTION_TERMS = 'REQUEST_SWF_INSTITUTION_TERMS';
export const RECEIVE_SWF_INSTITUTION_TERMS = 'RECEIVE_SWF_INSTITUTION_TERMS';
export const RECEIVE_SWF_INSTITUTIONS = 'RECEIVE_SWF_INSTITUTIONS';
export const CHANGE_SWF_UI_PERMISSIONS = 'CHANGE_SWF_UI_PERMISSIONS';
export const SWF_INSTITUTION_TERMS_ERROR = 'SWF_INSTITUTION_TERMS_ERROR';

// SWF Submit Survey
export const SWF_CREATE_SURVEY_PENDING = 'SWF_CREATE_SURVEY_PENDING';
export const SWF_CREATE_SURVEY_SUCCESS = 'SWF_CREATE_SURVEY_SUCCESS';
export const SWF_CREATE_SURVEY_ERROR = 'SWF_CREATE_SURVEY_ERROR';

// SWF Programs
export const REQUEST_PROGRAMS = 'REQUEST_PROGRAMS';
export const RECEIVE_PROGRAMS = 'RECEIVE_PROGRAMS';
export const PROGRAMS_ERROR = 'PROGRAMS_ERROR';
