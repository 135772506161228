import React from 'react';

const Option = ({
  children,
  onClick,
  selected,
  disabled,
  style = {},
  value,
  className = '',
  multiple,
}) => {
  const classNames = ['c-s-option'];
  if (multiple) {
    if (selected.indexOf(value) !== -1) classNames.push('is-selected');
  } else {
    if (selected === value) {
      classNames.push('is-selected');
    }
  }

  if (disabled) {
    classNames.push('is-disabled');
  }
  classNames.push(className);
  return (
    <div onClick={onClick} style={style} className={classNames.join(' ')}>
      {children}
    </div>
  );
};

export default Option;
