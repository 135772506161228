import React from 'react';
import PropTypes from 'prop-types';
import {getCloudinaryImage} from 'core';
import {GPS_DOMAIN} from 'config/srm.config';
import {Icon, Avatar, Tooltip, Spin, Typography} from 'antd';

const URL = process.env.REACT_APP_GPS_URL;

const CompanyInfo = ({editHoverTitle, companyInfo, request, editProfile}) => {
  const {
    company_logo,
    company_name,
    company_address_line1,
    city,
    logo_cloudinary,
    logo,
    name,
    id,
    physical_address_details,
    created_by_super_user,
    users = [],
    subdomain,
    institution_super_type_name: {type} = {},
    default_msa: {city: msa_city = '', state_code = ''} = {},
  } = companyInfo || {};

  const logoData =
    getCloudinaryImage(logo_cloudinary, 'logo') || logo || company_logo;
  const orgName = company_name || name || 'No name';
  let address = `${
    company_address_line1 || city
      ? `${company_address_line1}, ${city}`
      : physical_address_details &&
        (physical_address_details.city && physical_address_details.state)
      ? `${physical_address_details.city}, ${physical_address_details.state}`
      : ''
  }`;
  if (type === 'Consortium' && msa_city && state_code) {
    address = `${msa_city}, ${state_code}`;
  }
  let instituteName = null;
  let institutionId = null;

  const formatLicenseId = id => {
    let formatedId = id;
    const IdLength = id.toString().length;
    if (IdLength < 5) {
      const diff = 5 - IdLength;
      for (let i = 0; i < diff; i++) {
        formatedId = `0${formatedId}`;
      }
    }
    return formatedId;
  };

  if (
    users.length &&
    users[0].user_portal.toLowerCase() !== 'reporter' &&
    created_by_super_user
  ) {
    instituteName = name
      .trim()
      .toLowerCase()
      .split(' ')
      .join('-');
    institutionId = formatLicenseId(id);
  }

  const subdomainLink =
    subdomain && `https://${subdomain.toLowerCase()}.${GPS_DOMAIN}`;

  const userType = users && users.length && users[0].user_portal.toLowerCase();

  return request || request === null ? (
    <>
      <Spin tip="Loading..." />
    </>
  ) : (
    <>
      <div className="college-logo">
        {logoData ? (
          <Avatar src={logoData} alt="college-logo" />
        ) : (
          <span className="icon-level-0" />
        )}
        {editProfile && (
          <Tooltip title={editHoverTitle}>
            <span
              className="icon-Path_Edit_Icon"
              onClick={() => editProfile(true)}
            />
          </Tooltip>
        )}
        <div className="custom-tooltip">
          <div className="custom-tooltip-arrow-up" />
          <div className="custom-tooltip-inner">
            <div className="clearfix">{orgName}</div>
            {address && address.length > 0 && (
              <>
                <Icon type="environment" className="gps-icon" />
                {address}
              </>
            )}
          </div>
        </div>
      </div>
      {/* {instituteName && institutionId ? (
        <a
          className="adrs-text"
          target="_blank"
          href={`${URL}${instituteName}/${institutionId}`}>
          {orgName}
        </a>
      ) : (
        <span className="adrs-text">{orgName}</span>
        )} */}
      <span className="adrs-text">{orgName}</span>
      {subdomainLink && userType !== 'business_partner' && (
        <Typography.Paragraph
          className="adrs-text subdomain-link"
          ellipsis
          title={subdomainLink}>
          <a
            href={subdomainLink}
            target="_blank"
            rel="noopener noreferrer">{`${subdomain.toLowerCase()}.${GPS_DOMAIN}`}</a>
        </Typography.Paragraph>
      )}
      {address && address.length > 0 && (
        <span className="adrs-text">
          <Icon type="environment" className="gps-icon" />
          {address}
        </span>
      )}
    </>
  );
};

CompanyInfo.propTypes = {
  companyInfo: PropTypes.shape({
    logo: PropTypes.string,
    company_logo: PropTypes.string,
    company_name: PropTypes.string,
    name: PropTypes.string,
    city: PropTypes.string,
    physical_address_details: PropTypes.object,
  }),
};
export default CompanyInfo;
