import * as TYPES from './constants';

import {
  receiveOnboardPaths,
  requestOnboardPaths,
  handletOnboardPathsError,
} from './actions';

import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_ONBOARD_PATHS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECIEVE_ONBOARD_PATHS:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.ONBOARD_PATHS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchOnboardPaths = filters => (dispatch, getState) => {
  // const secret = `${API.pathway.secret}`;
  const state = getState();
  let accessToken = null;
  if (state && state.auth && state.auth.access) {
    const {
      auth: {
        access: {token},
      },
    } = state;
    accessToken = token;
  }

  if (accessToken) {
    const config = {
      method: 'GET',
      mode: 'cors',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
      cache: 'default',
    };
    // filters
    let query = [];
    if (filters)
      Object.keys(filters).forEach(key => {
        if (filters[key]) query.push(`${key}=${filters[key]}`);
      });
    const qParams = query.length > 0 ? `?${query.join('&')}` : '';
    dispatch(requestOnboardPaths());
    try {
      return (
        fetch(`${API.pathway.onboarding_path}${qParams}`, config)
          .then(response => response.json())
          // .then(response => filterStaticCourse(courseId)) // added to make search static for the demo purpose
          .then(json => {
            dispatch(receiveOnboardPaths(json));
          })
          .catch(err => {
            dispatch(handletOnboardPathsError(err));
          })
      );
    } catch (err) {
      dispatch(handletOnboardPathsError(err));
    }
  }
};
