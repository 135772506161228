import React, {useEffect, useState} from 'react';
import './style.css';

import {Col, Row, Form, Icon, Select, Tag, Spin} from 'antd';
import {debounce} from 'lodash';
import {API} from 'config/businesspartner.config';
import {Services} from '../../services';
const services = new Services();
const defaultMinimumCharacter = 2;

const data = [
  {label: 'akash'},
  {label: 'acchu'},
  {label: 'akrhu'},
  {label: 'avinash'},
  {label: 'akara'},
  {label: 'akarka'},
  {label: 'adarhsa'},
];

const endpoint = `${API.administration.student_exchange}`;

const FormItem = Form.Item;

const EducatorsSearch = props => {
  // props destructure
  const {onChange, minimumCharacter, token, value} = props;
  //   state
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [educators, setEducators] = useState([]);
  //   controls
  useEffect(() => {
    if (value && Array.isArray(value));
    setEducators(value || []);
  }, []);
  const isEducatorselected = label => {
    const exists = educators.filter(
      d => (d.institution_id ? d.institution_id : d.name) === label
    )[0];
    return exists ? true : false;
  };
  const onLocationRemove = label => {
    const neweducators = educators.filter(
      loc => (loc.institution_id ? loc.institution_id : loc.name) !== label
    );
    setEducators(neweducators);
    onLocationChange(neweducators);
  };

  const onSelect = v => {
    console.log('selc', educators, v);
    const location = data.filter(
      i => (i.institution_id ? i.institution_id : i.name) === v
    )[0];
    if (location) {
      if (!isEducatorselected(v)) {
        const neweducators = [...educators, location];
        setEducators(neweducators);
        onLocationChange(neweducators);
      } else {
        onLocationRemove(v);
      }
    }
  };
  const onSearch = debounce(term => {
    if (term && term.length >= (minimumCharacter || defaultMinimumCharacter)) {
      setLoading(true);
      services
        .fetchRecords(`${endpoint}?educator_name=${term}`, token)
        .then(res => {
          setLoading(false);
          const result = res && res.educators;
          if (result && Array.isArray(result)) {
            const data = result;
            setData(data);
          }
        });
    }
  }, 1300);
  const onLocationChange = neweducators => {
    if (onChange) onChange(neweducators || educators);
  };

  const options =
    data &&
    Array.isArray(data) &&
    data.map(educator => {
      const isSelected = isEducatorselected(
        educator.institution_id ? educator.institution_id : educator.name
      );
      return (
        <Select.Option
          key={
            educator.institution_id ? educator.institution_id : educator.name
          }
          value={
            educator.institution_id ? educator.institution_id : educator.name
          }
          className={
            'option-selected-check' + (isSelected ? ' selected ' : '') // class styles wrote in sharedResourses/app.css
          }>
          {educator.name}
          {isSelected && <Icon type="check" />}
        </Select.Option>
      );
    });
  console.log(educators, data);
  return (
    <div className="educator-search filter-section">
      <div className="heading-text text-center">Filter by Educator</div>
      <Row type="flex" gutter={42} align="middle">
        <Col sm={7}>Search by Educator Type</Col>
        <Col sm={17} className="searchOccupation" style={{margin: '0 auto'}}>
          <FormItem>
            <>
              <Icon
                type="search"
                style={{
                  position: 'absolute',
                  zIndex: '1',
                  top: '4px',
                  left: '5px',
                }}
              />
              <Select
                showSearch
                loading={loading}
                showArrow={false}
                filterOption={false}
                defaultActiveFirstOption={false}
                allowClear={!loading}
                value={null}
                onSelect={onSelect}
                onSearch={onSearch}
                placeholder="Search"
                notFoundContent={
                  loading ? (
                    <Spin size="small" />
                  ) : (
                    'no educator type matchs for search query'
                  )
                }
                style={{paddingLeft: '11px'}}>
                {options}
              </Select>
            </>
          </FormItem>
        </Col>
      </Row>
      <div className="program-study-selector filter-section">
        <div className="selected-section">
          <div className="lbl-s">
            {/* {required && <span className="required">* </span>} */}
            Selected Educator Type (s):
          </div>
          <div className="tags-s">
            {(educators &&
              Array.isArray(educators) &&
              educators.length > 0 &&
              educators.map((educator, index) => (
                <Tag
                  key={
                    'pgm-tag' +
                    (educator.institution_id
                      ? educator.institution_id
                      : educator.name)
                  }
                  onClose={() =>
                    onLocationRemove(
                      educator.institution_id
                        ? educator.institution_id
                        : educator.name
                    )
                  }
                  closable
                  className="occ-tag edu-tag">
                  {educator.name}
                </Tag>
              ))) || (
              <span
                style={{
                  position: 'relative',
                  top: '3px',
                }}>
                -
              </span>
            )}
            {/* {showErrors && selectedPrograms.length === 0 && (
            <FormItem>
              {getFieldDecorator('ss', {
                rules: [
                  {required: true, message: 'Please select the occupation'},
                ],
              })(<div style={{paddingBottom: 7}} />)}
            </FormItem>
          )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducatorsSearch;
