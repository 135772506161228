import React from 'react';
import {Button} from 'antd';

const PageHeader = ({pageTitle, navigateTo, buttonText}) => (
  <h2 className="title">
    {pageTitle}
    {navigateTo && buttonText && (
      <Button className="btn-outline-white" onClick={navigateTo} shape="round">
        {buttonText}
      </Button>
    )}
  </h2>
);

export default PageHeader;
