import {REPORTER_ROUTES} from './routes';
const SRM = process.env.REACT_APP_SRM;
export const defautHomePage = '/';

export const API = {
  admin: {
    institution_metrics: `${SRM}get_institute_metrics/`,
    institute: `${SRM}institute/`,
  },
  institution: {
    institution_users: `${SRM}v1/super-user/institute-users/`,
    institution: `${SRM}v1/super-user/institute/`,
    user: `${SRM}user/`,
    user_resend_temp_password: `${SRM}user_resend_temp_password`,
  },
};

export const profileMenu = [
  {
    title: 'Edit Profile',
    key: 'edit_profile',
  },
  {
    title: 'Change Password',
    key: 'change_password',
  },
  {
    title: 'About Us',
    key: 'about_us',
  },
  {
    title: 'Log Out',
    key: 'log_out',
  },
];

export const homePageMenu = [
  {
    title: 'Home',
    imgSrc: 'icon-Home icon',
    key: 'Home',
    mainMenu: true,
    path: REPORTER_ROUTES.HOME.DASHBOARD,
  },
  {
    title: 'Administration',
    imgSrc: 'icon-Profile icon',
    key: 'Administration',
    content: [
      {
        submenuTitle: 'User Management',
        path: REPORTER_ROUTES.ADMIN.USER_MANAGEMENT,
      },
    ],
  },
  {
    title: 'Institutions',
    imgSrc: 'icon-institutionwhite icon',
    key: 'institutions',
    mainMenu: true,
    path: REPORTER_ROUTES.HOME.INSTITUTIONS,
  },
  // {
  //   title: 'Reports',
  //   imgSrc: 'icon-reports icon',
  //   key: 'reports',
  //   mainMenu: true,
  //   path: REPORTER_ROUTES.REPORTS.DASHBOARD,
  // },
];
