import * as TYPES from './constants';
import {
  requestInstitutionTypes,
  receiveInstitutionTypes,
  handleInstitutionTypesError,
} from './actions';

import {Services, isArray} from 'core';

import {API} from 'config/superuser.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_CONSORTIUM_INSTITUTION_TYPES:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_CONSORTIUM_INSTITUTION_TYPES:
      return {
        ...state,
        data: action.payload || null,
        request: false,
        error: false,
      };
    case TYPES.CONSORTIUM_INSTITUTION_TYPES_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchInstitutionTypes = () => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestInstitutionTypes());
  let endpoint = `${API.administration.institution_type}`;
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        if (res && isArray(res) && res.length) {
          return Promise.all(
            res.map(({type, uuid}) => {
              if (type && type.toLowerCase() === 'institution') {
                let uri = `${
                  API.administration.institution_sub_type
                }?super_type__uuid=${uuid}&is_licenseable=true`;
                return services.fetchRecords(uri, token).then(sub_types => {
                  return {uuid, type, sub_types};
                });
              }
              return {uuid, type};
            })
          );
        }
      })
      .then(res => dispatch(receiveInstitutionTypes(res)))
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleInstitutionTypesError(err));
  }
};
