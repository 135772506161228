import * as TYPES from './constants';

// STATES::
export const requestStates = () => ({
  type: TYPES.REQUEST_STATES,
});
export const receiveStates = json => ({
  type: TYPES.RECEIVE_STATES,
  payload: json,
});
export const handleStatesError = err => ({
  type: TYPES.STATES_ERROR,
  err,
});

// CONSORTIUM_STATES::
export const requestConsortiumStates = () => ({
  type: TYPES.REQUEST_CONSORTIUM_STATES_DATA,
});
export const receiveConsortiumStates = json => ({
  type: TYPES.RECEIVE_CONSORTIUM_STATES_DATA,
  payload: json,
});
export const handleConsortiumStatesError = err => ({
  type: TYPES.CONSORTIUM_STATES_DATA_ERROR,
  err,
});

//company to Institution
export const requestCompanytoInstitution = () => ({
  type: TYPES.REQUEST_COMPANY_TO_INSTITUTION_DATA,
});
export const receiveCompanytoInstitution = json => ({
  type: TYPES.RECEIVE_COMPANY_TO_INSTITUTION_DATA,
  payload: json,
});
export const handleCompanytoInstitutionError = err => ({
  type: TYPES.COMPANY_TO_INSTITUTION_DATA_ERROR,
  err,
});

// Tuition link
export const receiveTuitionLink = json => ({
  type: TYPES.RECEIVE_TUITION_LINK,
  payload: json,
});
export const requestTuitionLink = () => ({
  type: TYPES.REQUEST_TUITION_LINK,
});
export const handleTuitionLinkError = err => ({
  type: TYPES.TUITION_LINK_ERROR,
  err,
});

// Search location
export const receiveLocationSearch = json => ({
  type: TYPES.RECEIVE_LOCATION_SEARCH,
  payload: json,
});
export const requestLocationSearch = () => ({
  type: TYPES.REQUEST_LOCATION_SEARCH,
});
export const handleLocationSearchError = err => ({
  type: TYPES.LOCATION_SEARCH_ERROR,
  err,
});
export const clearLocationSearch = () => ({
  type: TYPES.LOCATION_SEARCH_CLEAR,
});

// linkedIn Details
export const requestLinkedInDetails = json => ({
  type: TYPES.LINKEDIN_DETAILS_REQUEST,
  payload: json,
});

export const recieveLinkedInDetails = json => ({
  type: TYPES.LINKEDIN_DETAILS_RECEIVE,
  payload: json,
});

export const linkedInDetailsError = err => ({
  type: TYPES.LINKEDIN_DETAILS_ERROR,
  err,
});

export const clearLinkedInDetails = () => ({
  type: TYPES.LINKEDIN_DETAILS_CLEAR,
});
