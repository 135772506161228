import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const Counter = ({current, total, style, haserror}) => {
  return (
    <span
      className={`counter ${haserror ? 'haserror' : ''}`}
      style={style ? style : {}}>
      {current}/{total}
    </span>
  );
};

Counter.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default Counter;
