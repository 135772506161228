import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import * as TYPES from './constants';
import {
  requestStudentComparedPaths,
  receiveStudentComparedPaths,
  handleStudentComparedPathsError,
  resetStudentComparedPaths,
} from './actions';
const initialState = {
  request: null,
  data: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_STUDENT_COMPARED_PATHS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_STUDENT_COMPARED_PATHS:
      return {
        ...state,
        data: action.payload,
        request: false,
      };
    case TYPES.STUDENT_SAVED_COMPARED_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.STUDENT_SAVED_COMPARED_RESET:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

export const fetchComparedPaths = student_uuid => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestStudentComparedPaths());
  try {
    return fetch(
      `${API.student.student_compared_paths}?student_uuid=${student_uuid}`,
      config
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Api Fails');
      })
      .then(json => {
        dispatch(receiveStudentComparedPaths(json));
      })
      .catch(err => {
        dispatch(handleStudentComparedPathsError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleStudentComparedPathsError(err));
  }
};

export const resetStudentComparedPath = () => dispatch => {
  dispatch(resetStudentComparedPaths());
};
