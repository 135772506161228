import * as TYPES from 'redux/types';

import {API} from 'config/srm.config';

const initialState = {};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.RECEIVE_ACCOUNTTYPE:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.RECEIVE_USER_TYPE:
      return {
        ...state,
        userTypes: action.payload,
        request: false,
        error: false,
      };
    case TYPES.REQUEST_ACCOUNTTYPE_ERROR:
    case TYPES.REQUEST_USER_TYPE_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

const receiveAccountType = json => ({
  type: TYPES.RECEIVE_ACCOUNTTYPE,
  payload: json,
});
const requestAccountTypeError = err => ({
  type: TYPES.REQUEST_ACCOUNTTYPE_ERROR,
  err,
});
const receiveUserType = json => ({
  type: TYPES.RECEIVE_USER_TYPE,
  payload: json,
});
const requestUserTypeError = err => ({
  type: TYPES.REQUEST_USER_TYPE_ERROR,
  err,
});

export const fetchAccountType = instituteUUID => ({
  type: TYPES.APIREQUEST,
  payload: {
    endpoint: `${
      API.srm.account_type
    }?user_institution_type__institution_type_id=${instituteUUID}`,
    method: 'GET',
    server: 'srm',
    onsuccess: receiveAccountType,
    onerror: requestAccountTypeError,
  },
});

export const fetchUserTypes = () => ({
  type: TYPES.APIREQUEST,
  payload: {
    endpoint: `${API.srm.account_type}`,
    method: 'GET',
    server: 'srm',
    onsuccess: receiveUserType,
    onerror: requestUserTypeError,
  },
});

export const getUserTypesSelector = ({accountType: {userTypes}}) => userTypes;
export const accountSelector = ({accountType: {data}}) => data;
