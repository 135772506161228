import * as TYPES from './constants';
import {requestReport, receiveReport, handleReportError} from './actions';

import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {
  data: null,
  request: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_REPORT:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_REPORT:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.REPORT_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.CLEAR_REPORT:
      return {
        ...state,
        data: null,
        error: null,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchReport = reportId => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  let endPoint = `${API.reports.report}/${reportId}`;

  dispatch(requestReport());
  try {
    return fetch(endPoint, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveReport(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleReportError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleReportError(err));
  }
};
