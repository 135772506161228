export const RadarCharticons = {
  'Occupation Skills': {
    grey: require('./../sharedResources/assets/icons/skills-profile/grey/occupationalgrey.png'),
    white: require('./../sharedResources/assets/icons/skills-profile/white/occupational.png'),
  },
  'People Skills': {
    grey: require('./../sharedResources/assets/icons/skills-profile/grey/peoplegrey.png'),
    white: require('./../sharedResources/assets/icons/skills-profile/white/people.png'),
  },
  'Personal Skills': {
    grey: require('./../sharedResources/assets/icons/skills-profile/grey/personalgrey.png'),
    white: require('./../sharedResources/assets/icons/skills-profile/white/personal.png'),
  },
  'Workplace Skills': {
    grey: require('./../sharedResources/assets/icons/skills-profile/grey/workplacegrey.png'),
    white: require('./../sharedResources/assets/icons/skills-profile/white/workplace.png'),
  },
  'Applied Knowledge': {
    grey: require('./../sharedResources/assets/icons/skills-profile/grey/knowledgegrey.png'),
    white: require('./../sharedResources/assets/icons/skills-profile/white/knowledge.png'),
  },
};

export const chartDefaultSettings = {
  props: {
    size: 400,
    axes: true, // show axes?
    scales: 3, // show scale circles?
    captions: true, // show captions?
    captionIndex: true, // show caption indexes
    icons: false, // show icons
    circleFill: true,
    zoomDistance: 1, // where on the axes are the captions?
    drawSize: 4,
    captionMargin: 10,
    captionProps: style => ({
      className: style,
      textAnchor: 'middle',
      fontSize: 10,
      fontFamily: 'Source Sans Pro',
    }),
  },
};

export function constructSkillsArray(skills, skillsCompleted) {
  const skillArray = [];
  if (!skills || Object.keys(skills).length === 0) return;

  const skillsStatus = skillsCompleted;
  let menuIndex = 0;
  if (skillsStatus && skillsStatus.student_response) {
    const skills = skillsStatus.student_response;
    menuIndex = Object.keys(skills).length;
  }

  Object.keys(skills).map(key => {
    const root = {};
    root['name'] = key;
    const eachRootSkill = skills[key];
    const subSkills = [];
    let totalSubSkill = 0;
    let skillvalue = 0;
    Object.keys(eachRootSkill).map((_key, _index) => {
      const subSkill = {};
      totalSubSkill = +1;
      subSkill['name'] = _key;
      subSkill['type'] = _key;
      subSkill['questions'] = eachRootSkill[_key];
      let total = 0;
      let subSkillValue = 0;
      if (subSkill.questions && subSkill.questions.length > 0) {
        subSkill.questions.forEach(que => {
          subSkill['section_desc'] = que['section_desc'];
          if (que.level === null || que.level === undefined) que.level = -1;
          else total += que.level;
        });
        subSkillValue = (total * 100) / (subSkill.questions.length * 3);
      }
      subSkill['value'] = subSkillValue;
      skillvalue = +subSkillValue;
      subSkills.push(subSkill);
    });
    root['subSkills'] = subSkills;
    const skillPercValue = (100 * totalSubSkill * 100) / skillvalue;
    root['value'] = skillPercValue;
    skillArray.push(root);
  });

  if (menuIndex === skillArray.length) menuIndex -= 1;
  return {
    skills: skillArray,
    menuIndex,
  };
}

export function findCurrentSkillsStatus(_skills, skillsStatus) {
  if (skillsStatus && skillsStatus.student_response) {
    const skills = skillsStatus.student_response;
    const skillsData = _skills; // bind saved option object to main skill data and reconstruct the array
    if (skillsData) {
      Object.keys(skillsData).forEach(key => {
        const subSkills = skillsData[key];
        if (subSkills) {
          Object.keys(subSkills).forEach(subSkillKey => {
            const questions = subSkills[subSkillKey];
            if (questions && questions.length > 0) {
              questions.forEach((question, index) => {
                if (
                  skills[key] &&
                  skills[key][subSkillKey] &&
                  skills[key][subSkillKey][index]
                ) {
                  question.level = skills[key][subSkillKey][index].options;
                }
              });
            }
          });
        }
      });
    }
    return skillsData;
  } else {
    return null;
  }
}

export function createRadarChartData(skills) {
  const data = [];
  if (!skills || Object.keys(skills).length === 0) return false;
  Object.keys(skills).map((key, i) => {
    data.push({
      caption: key,
      count: 0,
      icon: i === 0 ? RadarCharticons[key].white : RadarCharticons[key].grey,
      iconName: key,
      status: i === 0 ? 'progress' : '',
    });
  });
  return data;
}

export function consolidateValuesForSpiderChart(subSkills, skills, index) {
  let totalValue = 0;
  let totalSkills = 0;
  subSkills.forEach(subSkill => {
    subSkill.questions.forEach(question => {
      if (question.level >= 1) {
        totalValue += question.level;
      }
      totalSkills += 1;
    });
  });

  let total = totalValue / totalSkills;
  total = Math.round(total * (100 / 3));
  // total = (total/4) * 25
  skills[index].value = total;
  return skills;
}
