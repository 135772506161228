import React from 'react';

import {CustomCollapse, CustomTabs, Stats} from 'core';

import PanelHOC from './panelHOC';
import TimelineContainer from './timelineContainer';
import ProgressbarContainer from './progressbarContainer';

/*
const StudentsStatsData = [
  {
    title: 'Total Students',
    count: 14,
    color: 'green',
  },
  {
    title: 'K12 Students',
    count: '-',
    color: 'orange',
  },
  {
    title: 'College Students',
    count: '-',
    color: 'brown',
  },
  {
    title: 'Parents',
    count: '-',
    color: 'fun-blue',
  },
  {
    title: 'Professionals',
    count: '-',
    color: 'blue',
  },
  {
    title: 'Explorers',
    count: '-',
    color: 'violet-blue',
  },
];
const StudentIntrestStatsData = [
  {
    title: 'Interested in a School',
    count: 14,
    color: 'green',
  },
  {
    title: 'Interested in a Pathway',
    count: 23,
    color: 'orange',
  },
  {
    title: 'Started a Pathway',
    count: 23,
    color: 'brown',
  },
  // As Per AC it should not be included
  // {
  //   title: 'Started a Course',
  //   count: 15,
  //   color: 'fun-blue',
  // },
];
const CareerPlanStatsData = [
  {
    title: 'Completed Career Interest Survey',
    count: 14,
    color: 'green',
  },
  {
    title: 'Created a Career Plan',
    count: '-',
    color: 'brown',
  },
];
*/

const graphPointStyling = {
  pointBackgroundColor: '#ffffff',
  pointRadius: 5,
  pointHoverRadius: 6,
};
const STATIC_STUDENT_TIMELINE_DATA = {
  labels: [],
  datasets: [
    {
      key: 'total_students',
      label: 'Total',
      data: [],
      borderColor: 'rgb(26, 178, 102)',
      backgroundColor: 'rgb(26, 178, 102)',
      pointHoverBackgroundColor: 'rgb(26, 178, 102)',
      ...graphPointStyling,
    },
    {
      key: 'k12_students',
      label: 'K12',
      data: [],
      borderColor: 'rgb(250, 180, 10)',
      backgroundColor: 'rgb(250, 180, 10)',
      pointHoverBackgroundColor: 'rgb(250, 180, 10)',
      ...graphPointStyling,
    },
    {
      key: 'college_students',
      label: 'College',
      data: [],
      borderColor: 'rgb(139, 87, 42)',
      backgroundColor: 'rgb(139, 87, 42)',
      pointHoverBackgroundColor: 'rgb(139, 87, 42)',
      ...graphPointStyling,
    },
    {
      key: 'parents',
      label: 'Parents',
      data: [],
      borderColor: 'rgb(42, 73, 139)',
      backgroundColor: 'rgb(42, 73, 139)',
      pointHoverBackgroundColor: 'rgb(42, 73, 139)',
      ...graphPointStyling,
    },
    {
      key: 'professionals',
      label: 'Professionals',
      data: [],
      borderColor: 'rgb(72, 175, 247)',
      backgroundColor: 'rgb(72, 175, 247)',
      pointHoverBackgroundColor: 'rgb(72, 175, 247)',
      ...graphPointStyling,
    },
    {
      key: 'explorers',
      label: 'Explorers',
      data: [],
      borderColor: 'rgb(77, 51, 229)',
      backgroundColor: 'rgb(77, 51, 229)',
      pointHoverBackgroundColor: 'rgb(77, 51, 229)',
      ...graphPointStyling,
    },
  ],
};
const STATIC_STUDENT_INTEREST_TIMELINE_DATA = portalType => ({
  labels: [],
  datasets: [
    {
      key: 'interested_in_a_school',
      label:
        portalType !== 'school'
          ? 'Interested in a School'
          : 'Interested in this School',
      data: [],
      borderColor: 'rgb(68, 180, 230)',
      backgroundColor: 'rgb(68, 180, 230)',
      pointHoverBackgroundColor: 'rgb(68, 180, 230)',
      ...graphPointStyling,
    },
    {
      key: 'interested_in_a_pathway',
      label: 'Interested in a Pathway',
      data: [],
      borderColor: 'rgb(100, 52, 205)',
      backgroundColor: 'rgb(100, 52, 205)',
      pointHoverBackgroundColor: 'rgb(100, 52, 205)',
      ...graphPointStyling,
    },
    {
      key: 'started_a_pathway',
      label: 'Started a Pathway',
      data: [],
      borderColor: 'rgb(26, 178, 102)',
      backgroundColor: 'rgb(26, 178, 102)',
      pointHoverBackgroundColor: 'rgb(26, 178, 102)',
      ...graphPointStyling,
    },
    {
      key: 'started_a_course',
      label: 'Started a Course',
      data: [],
      borderColor: 'rgb(42, 73, 139)',
      backgroundColor: 'rgb(42, 73, 139)',
      pointHoverBackgroundColor: 'rgb(42, 73, 139)',
      ...graphPointStyling,
    },
  ],
});
const STATIC_CAREER_PLAN_TIMELINE_DATA = {
  labels: [],
  datasets: [
    {
      key: 'completed_career_interest_survey',
      label: 'Completed Career Interest Survey',
      data: [],
      borderColor: 'rgb(26, 178, 102)',
      backgroundColor: 'rgb(26, 178, 102)',
      pointHoverBackgroundColor: 'rgb(26, 178, 102)',
      ...graphPointStyling,
    },
    // {
    //   key: 'created_a_career_plan',
    //   label: 'Created a Career Plan',
    //   data: [],
    //   borderColor: 'rgb(139, 87, 42)',
    //   backgroundColor: 'rgb(139, 87, 42)',
    //   pointHoverBackgroundColor: 'rgb(139, 87, 42)',
    //   ...graphPointStyling,
    // },
  ],
};
/**
const STUDENT_DUMMY_DATA = {
  total_students: {
    timeline: {
      2020: {
        Jan: 0,
        Feb: 10,
        Mar: 22,
        Apr: 25,
        May: 32,
        Jun: 34,
        Jul: 39,
        Aug: 42,
        Sep: 50,
        Oct: 55,
        Nov: 60,
        Dec: 62,
      },
    },
  },
  k12_students: {
    timeline: {
      2020: {
        Jan: 2,
        Feb: 12,
        Mar: 15,
        Apr: 18,
        May: 20,
        Jun: 25,
        Jul: 30,
        Aug: 33,
        Sep: 40,
        Oct: 35,
        Nov: 40,
        Dec: 45,
      },
    },
  },
  college_students: {
    timeline: {
      2020: {
        Jan: 1,
        Feb: 18,
        Mar: 20,
        Apr: 30,
        May: 25,
        Jun: 40,
        Jul: 50,
        Aug: 55,
        Sep: 40,
        Oct: 43,
        Nov: 45,
        Dec: 50,
      },
    },
  },
  parents: {
    timeline: {
      2020: {
        Jan: 0,
        Feb: 8,
        Mar: 15,
        Apr: 20,
        May: 23,
        Jun: 27,
        Jul: 30,
        Aug: 34,
        Sep: 37,
        Oct: 40,
        Nov: 45,
        Dec: 50,
      },
    },
  },
  professionals: {
    timeline: {
      2020: {
        Jan: 4,
        Feb: 10,
        Mar: 15,
        Apr: 12,
        May: 16,
        Jun: 20,
        Jul: 25,
        Aug: 29,
        Sep: 32,
        Oct: 25,
        Nov: 27,
        Dec: 30,
      },
    },
  },
  explorers: {
    timeline: {
      2020: {
        Jan: 4,
        Feb: 10,
        Mar: 14,
        Apr: 16,
        May: 19,
        Jun: 22,
        Jul: 25,
        Aug: 20,
        Sep: 23,
        Oct: 25,
        Nov: 30,
        Dec: 32,
      },
    },
  },
};
const STUDENT_INTEREST_DUMMY_DATA = {
  interested_in_a_school: {
    timeline: {
      2020: {
        Jan: 0,
        Feb: 10,
        Mar: 22,
        Apr: 25,
        May: 32,
        Jun: 34,
        Jul: 39,
        Aug: 42,
        Sep: 50,
        Oct: 55,
        Nov: 60,
        Dec: 62,
      },
    },
  },
  interested_in_a_pathway: {
    timeline: {
      2020: {
        Jan: 2,
        Feb: 12,
        Mar: 15,
        Apr: 18,
        May: 20,
        Jun: 25,
        Jul: 30,
        Aug: 33,
        Sep: 40,
        Oct: 35,
        Nov: 40,
        Dec: 45,
      },
    },
  },
  started_a_pathway: {
    timeline: {
      2020: {
        Jan: 1,
        Feb: 18,
        Mar: 20,
        Apr: 30,
        May: 25,
        Jun: 40,
        Jul: 50,
        Aug: 55,
        Sep: 40,
        Oct: 43,
        Nov: 45,
        Dec: 50,
      },
    },
  },
  started_a_course: {
    timeline: {
      2020: {
        Jan: 0,
        Feb: 8,
        Mar: 15,
        Apr: 20,
        May: 23,
        Jun: 27,
        Jul: 30,
        Aug: 34,
        Sep: 37,
        Oct: 40,
        Nov: 45,
        Dec: 50,
      },
    },
  },
};
const CAREER_PLAN_DUMMY_DATA = {
  completed_career_interest_survey: {
    timeline: {
      2020: {
        Jan: 0,
        Feb: 10,
        Mar: 22,
        Apr: 25,
        May: 32,
        Jun: 34,
        Jul: 39,
        Aug: 42,
        Sep: 50,
        Oct: 55,
        Nov: 60,
        Dec: 62,
      },
    },
  },
  created_a_career_plan: {
    timeline: {
      2020: {
        Jan: 2,
        Feb: 12,
        Mar: 15,
        Apr: 18,
        May: 20,
        Jun: 25,
        Jul: 30,
        Aug: 33,
        Sep: 40,
        Oct: 35,
        Nov: 40,
        Dec: 45,
      },
    },
  },
};

*/

const calculateStudentInterestStats = (data, portalType) => {
  if (
    data &&
    data.student_pathway &&
    data.student_pathway.student_interest_metrics
  ) {
    const {
      interested_in_school,
      interested_in_pathway,
      started_a_pathway,
      started_a_course,
    } = data.student_pathway.student_interest_metrics;
    return [
      // {
      //   title:
      //     portalType !== 'school'
      //       ? 'Interested in a School'
      //       : 'Interested in this School',
      //   count: interested_in_school || 0,
      //   color: 'heart-blue',
      // },
      {
        title: 'Interested in a Pathway',
        count: interested_in_pathway || 0,
        color: 'flag-purple',
      },
      {
        title: 'Started a Pathway',
        count: started_a_pathway || 0,
        color: 'check-green',
      },
      // As Per AC it should not be included
      // {
      //   title: 'Started a Course',
      //   count: 15,
      //   color: 'fun-blue',
      // },
    ];
  }
  return [];
};

const calculateStudentStats = data => {
  if (data && data.student_pathway && data.student_pathway.student_metrics) {
    const {
      total_students,
      k12_students,
      college_students,
      parents,
      professionals,
      explorers,
    } = data.student_pathway.student_metrics;
    return [
      {
        title: 'Total Students',
        count: total_students || 0,
        color: 'green',
      },
      {
        title: 'K12 Students',
        count: '-',
        color: 'orange',
      },
      {
        title: 'College Students',
        count: '-',
        color: 'brown',
      },
      {
        title: 'Parents',
        count: '-',
        color: 'fun-blue',
      },
      {
        title: 'Professionals',
        count: '-',
        color: 'blue',
      },
      {
        title: 'Explorers',
        count: '-',
        color: 'violet-blue',
      },
    ];
  }
  return [];
};

const calculateCareerPlanStats = data => {
  if (data && data.career_assessment) {
    const {
      completed_career_assessment_survey,
      created,
    } = data.career_assessment.career_plan_metrics;
    return [
      {
        title: 'Completed Career Interest Survey',
        count: completed_career_assessment_survey || 0,
        color: 'green',
      },
      // {
      //   title: 'Created a Career Plan',
      //   count: '-',
      //   color: 'brown',
      // },
    ];
  }
  return [];
};

const prepareCareerTimeline = data => {
  if (data && data.career_assessment) {
    const {
      completed_career_assessment_survey_timeline,
      career_plan_timeline,
    } = data.career_assessment;
    return {
      completed_career_interest_survey: {
        timeline: completed_career_assessment_survey_timeline || {},
      },
      created_a_career_plan: {
        timeline: career_plan_timeline || {},
      },
    };
  }
};

const prepareStudentInterestTimeline = data => {
  if (data && data.student_pathway) {
    const {
      interested_in_school_timeline,
      interested_in_pathway_timeline,
      started_pathway_timeline,
    } = data.student_pathway;
    return {
      interested_in_a_school: {
        timeline: interested_in_school_timeline || {},
      },
      interested_in_a_pathway: {
        timeline: interested_in_pathway_timeline || {},
      },
      started_a_pathway: {
        timeline: started_pathway_timeline || {},
      },
    };
  }
};

const prepareStudentTimeline = data => {
  if (data && data.student_pathway) {
    const {total_students_timeline} = data.student_pathway;
    return {
      total_students: {
        timeline: total_students_timeline || {},
      },
      k12_students: {
        timeline: {},
      },
      college_students: {
        timeline: {},
      },
      parents: {
        timeline: {},
      },
      professionals: {
        timeline: {},
      },
      explorers: {
        timeline: {},
      },
    };
  }
};

function MarketPlace(props) {
  const {data: propData, portalType} = props;
  const STUDENT_STATS = calculateStudentStats(propData);
  const STUDENT_INTEREST_STATS = calculateStudentInterestStats(
    propData,
    portalType
  );
  const CAREER_PLAN_STATS = calculateCareerPlanStats(propData);
  const CAREER_TIMELINE_DATA = prepareCareerTimeline(propData);
  const STUDENT_INTEREST_TIMELINE_DATA = prepareStudentInterestTimeline(
    propData,
    portalType
  );
  const STUDENT_TIMELINE_DATA = prepareStudentTimeline(propData);

  const TabsTimelineData = [
    // {
    //   title: 'STUDENT METRICS',
    //   key: 'student_metrics',
    //   children: (
    //     <TimelineContainer
    //       data={STUDENT_TIMELINE_DATA}
    //       staticData={STATIC_STUDENT_TIMELINE_DATA}
    //       withoutHeader
    //     />
    //   ),
    // },
    {
      title: 'STUDENT METRICS',
      key: 'student_interest_metrics',
      children: (
        <TimelineContainer
          data={STUDENT_INTEREST_TIMELINE_DATA}
          staticData={STATIC_STUDENT_INTEREST_TIMELINE_DATA(portalType)}
          withoutHeader
        />
      ),
    },
    {
      title: 'CAREER PLAN METRICS',
      key: 'career_plan_metrics',
      children: (
        <TimelineContainer
          data={CAREER_TIMELINE_DATA}
          staticData={STATIC_CAREER_PLAN_TIMELINE_DATA}
          withoutHeader
        />
      ),
    },
  ];
  const TabsBarGraphData = [
    {
      title: 'CLUSTERS BY INTEREST',
      key: 'clusters_by_interest',
      children: (
        <ProgressbarContainer
          hideFilter
          displayDataNestedObj
          data={
            propData &&
            propData.student_pathway &&
            propData.student_pathway.clusters_by_interest
          }
          emptyMessage="There are no active pathways."
          sortingOptions={[
            {
              sortby: 'interest',
              title: 'Interest',
            },
            {
              sortby: 'title',
              title: 'Career Title',
            },
          ]}
        />
      ),
    },
    {
      title: 'PROGRAMS OF STUDY BY INTEREST',
      key: 'program_of_study_by_interest',
      children: (
        <ProgressbarContainer
          displayDataNestedObj
          data={
            propData &&
            propData.student_pathway &&
            propData.student_pathway.programs_of_study_by_interest
          }
          emptyMessage="There are no active pathways."
          sortingOptions={[
            {
              sortby: 'interest',
              title: 'Interest',
            },
            {
              sortby: 'title',
              title: 'Program Title',
            },
          ]}
        />
      ),
    },
  ];

  if (!portalType || portalType !== 'school') {
    TabsBarGraphData.push({
      title: 'SCHOOLS BY INTEREST',
      key: 'schools_by_interest',
      children: (
        <ProgressbarContainer
          data={
            propData &&
            propData.student_pathway &&
            propData.student_pathway.schools_by_interest
          }
          emptyMessage="There are no assigned schools."
          sortingOptions={[
            {
              sortby: 'interest',
              title: 'Interest',
            },
            {
              sortby: 'title',
              title: 'School Name',
            },
          ]}
        />
      ),
    });
  }
  const data = [
    {
      title: 'MARKETPLACE',
      key: 'market_place',
      passData: '',
      childern: (
        <div className="content">
          {/* <Stats
            data={STUDENT_STATS}
            headerTitle="Student Metrics"
            horizontal
          /> */}
          <Stats
            data={STUDENT_INTEREST_STATS}
            colWidth={5}
            headerTitle="Student Metrics"
            horizontal
          />
          <Stats
            data={CAREER_PLAN_STATS}
            colWidth={6}
            headerTitle="Career Plan Metrics"
          />
          <CustomTabs
            data={TabsTimelineData}
            tabBarStyle={{
              height: '40px',
            }}
          />
          <CustomTabs
            data={TabsBarGraphData}
            tabBarStyle={{
              height: '40px',
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <CustomCollapse
      data={data}
      switchPanel={props.switchPanel}
      activeKey={props.activeKey || 'market_place'}
    />
  );
}

export default PanelHOC(MarketPlace);
