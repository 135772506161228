import * as TYPES from 'redux/types';

import {API, JWT_HEADER, JSON_HEADER} from 'config/srm.config';

const initialState = {request: null};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_INSTITUTION:
      return {
        ...state,
        ...action.payload,
        request: true,
      };
    case TYPES.RECEIVE_INSTITUTION:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.RECEIVE_INSTITUTION_FILTER:
      return {
        ...state,
        institutionFilter: action.payload,
        request: false,
        error: false,
      };
    case TYPES.RECEIVE_INSTITUTION_LIST:
      return {
        ...state,
        institutionList: action.payload,
        request: false,
        error: false,
      };
    case TYPES.RECEIVE_INSTITUTION_DROPDOWN:
      return {
        ...state,
        dropdown: action.payload,
        request: false,
        error: false,
      };
    case TYPES.REQUEST_INSTITUTION_ERROR:
    case TYPES.REQUEST_INSTITUTION_DROPDOWN_ERROR:
    case TYPES.REQUEST_INSTITUTION_FILTER_ERROR:
    case TYPES.REQUEST_INSTITUTION_LIST_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

const receiveInstitution = json => ({
  type: TYPES.RECEIVE_INSTITUTION,
  payload: json,
});
const receiveInstitutionFilter = json => ({
  type: TYPES.RECEIVE_INSTITUTION_FILTER,
  payload: json,
});
const requestInstitutionFilterError = err => ({
  type: TYPES.REQUEST_INSTITUTION_FILTER_ERROR,
  err,
});
const requestInstitutionError = err => ({
  type: TYPES.REQUEST_INSTITUTION_ERROR,
  err,
});

const requestInstitution = json => ({
  type: TYPES.REQUEST_INSTITUTION,
  payload: json,
});

const receiveInstitutionListDropDown = json => ({
  type: TYPES.RECEIVE_INSTITUTION_DROPDOWN,
  payload: json,
});

const handleInstitutionListError = err => ({
  type: TYPES.REQUEST_INSTITUTION_DROPDOWN_ERROR,
  err,
});

const receiveInstitutionList = json => ({
  type: TYPES.RECEIVE_INSTITUTION_LIST,
  payload: json,
});
const requestInstitutionListError = err => ({
  type: TYPES.REQUEST_INSTITUTION_LIST_ERROR,
  err,
});

export const fetchInstitutionFilter = () => (dispatch, getState) => {
  const {
    auth: {access, access: {token} = {}},
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  try {
    return fetch(`${API.institution.institution}?institue_list=True`, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveInstitutionFilter(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(requestInstitutionFilterError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestInstitutionFilterError(err));
  }
};

export const fetchInstitutionList = () => (dispatch, getState) => {
  const {
    auth: {access, access: {token} = {}},
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };

  dispatch(requestInstitution());
  try {
    return fetch(
      `${API.institution.institution_list}?student_register=True`,
      config
    )
      .then(response => response.json())
      .then(json => {
        dispatch(receiveInstitutionList(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(requestInstitutionListError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestInstitutionListError(err));
  }
};

export const fetchInstitution = () => (dispatch, getState) => {
  const {
    auth: {access, access: {token} = {}},
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };

  dispatch(requestInstitution());
  try {
    return fetch(
      `${API.institution.institution}?is_system_generated=True`,
      config
    )
      .then(response => response.json())
      .then(json => {
        dispatch(receiveInstitution(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(requestInstitutionError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestInstitutionError(err));
  }
};

export const fetchInstitutionDropdown = () => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
      institution_id,
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  try {
    return fetch(
      `${API.institution.institution}?institution=${institution_id}`,
      config
    )
      .then(response => response.json())
      .then(json => {
        dispatch(receiveInstitutionListDropDown(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleInstitutionListError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleInstitutionListError(err));
  }
};

export const institutionFilterSelector = ({
  institution: {institutionFilter} = {},
}) => institutionFilter;
export const institutionListSelector = ({
  institution: {institutionList} = {},
}) => institutionList;
export const institutionSelector = state => state.institution;
export const institutionDataSelector = ({institution: {data} = {}}) => data;
export const institutionRequest = ({institution: {request} = {}}) => request;
export const institutionError = ({institution: {error} = {}}) => error;
export const getRootInstitutionNode = ({institution: {data = []}}) =>
  data.find(item => item.parent_institution === null);
export const institutionUuidMap = state => {
  const institutions = institutionDataSelector(state) || [];
  return institutions.reduce((acc, cur) => {
    acc[cur.institution_id] = cur;
    // if (cur.parent_institution) {
    //   acc[cur.uuid] = cur;
    // }
    return acc;
  }, {});
};
