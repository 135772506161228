import * as TYPES from './constants';

//SRM Reporter Institutions
export const receiveReporterUser = json => ({
  type: TYPES.RECEIVE_REPORTER_USERS,
  payload: json,
});

export const requestReporterUser = () => ({
  type: TYPES.REQUEST_REPORTER_USERS,
});

export const handleReporterUserError = err => ({
  type: TYPES.REPORTER_USERS_ERROR,
  err,
});

export const receiveDescendentInstitutions = json => ({
  type: TYPES.RECEIVE_DESCENDENT_INSTITUTIONS,
  payload: json,
});

export const requestDescendentInstitutions = () => ({
  type: TYPES.REQUEST_DESCENDENT_INSTITUTIONS,
});

export const handleDescendentInstitutionsError = err => ({
  type: TYPES.DESCENDENT_INSTITUTIONS_ERROR,
  err,
});

// Reporter Institute Dashboard
export const receiveInstituteDashboardData = json => ({
  type: TYPES.RECEIVE_INSTITUTE_DASHBOARD_DATA,
  payload: json,
});

export const requestInstituteDashboardData = () => ({
  type: TYPES.REQUEST_INSTITUTE_DASHBOARD_DATA,
});

export const handleInstituteDashboardDataError = err => ({
  type: TYPES.INSTITUTE_DASHBOARD_DATA_ERROR,
  err,
});

export const receiveInstituteDetails = json => ({
  type: TYPES.RECEIVE_INSTITUTE_DETAILS,
  payload: json,
});

export const requestInstituteDetails = () => ({
  type: TYPES.REQUEST_INSTITUTE_DETAILS,
});

export const handleInstituteDetailsError = err => ({
  type: TYPES.INSTITUTE_DETAILS_ERROR,
  err,
});

// Reporter Profile
export const receiveReporterProfile = json => {
  return {
    type: TYPES.REQUEST_REPORTER_USER,
    payload: json,
  };
};

export const handleReceiveProfileError = err => ({
  type: TYPES.REQUEST_REPORTER_USER_ERROR,
  err,
});

export const setToggleReporterProfile = data => ({
  type: TYPES.TOGGLE_REPORTER_PROFILE,
  payload: data,
});

// Reporter loggedin Institute
export const receiveLoggedInInstitute = json => ({
  type: TYPES.RECEIVE_LOGGED_IN_INSTITUTE,
  payload: json,
});

export const requestLoggedInInstitute = () => ({
  type: TYPES.REQUEST_LOGGED_IN_INSTITUTE,
});

export const handleLoggedInInstituteError = err => ({
  type: TYPES.LOGGED_IN_INSTITUTE_ERROR,
  err,
});

// Reporter consortiums
export const receiveConsortiums = json => ({
  type: TYPES.RECEIVE_REPORTER_CONSORTIUMS,
  payload: json,
});

export const requestConsortiums = () => ({
  type: TYPES.REQUEST_REPORTER_CONSORTIUMS,
});

export const handleConsortiumsError = err => ({
  type: TYPES.REPORTER_CONSORTIUMS_ERROR,
  err,
});
