import {useDrawer} from 'core/hooks';
import {ThemeSwitch} from 'mui-core';
import ProfileMenu from './ProfileMenu';
import {Menu} from '@mui/icons-material';
import {styled} from '@mui/material/styles';
import {Box, Stack, AppBar, Toolbar, IconButton} from '@mui/material';

const CustomAppBar = styled(AppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({theme, open, drawerWidth}) => ({
  zIndex: open ? theme.zIndex.drawer : theme.zIndex.drawer + 1,
  background:
    theme.palette.mode === 'light'
      ? theme.palette.background.paper
      : theme.palette.darkGray.main,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const FixedAppBar = ({
  onSignOut,
  drawerWidth,
  userDetails = {},
  hideSideBar = false,
  profileMenuData = [],
}) => {
  const [openMenuBar, toggleMenuBar] = useDrawer();

  return (
    <CustomAppBar
      position="fixed"
      drawerWidth={drawerWidth}
      open={!hideSideBar && openMenuBar}>
      <Toolbar>
        {!hideSideBar && (
          <IconButton
            mr={5}
            edge="start"
            aria-label="open drawer"
            onClick={() => toggleMenuBar(!openMenuBar)}>
            <Menu />
          </IconButton>
        )}
        <Box component="div" sx={{flexGrow: 1}} />
        <Stack gap={2} direction="row" alignItems="center">
          <ThemeSwitch />
          <ProfileMenu
            onSignOut={onSignOut}
            userDetails={userDetails}
            profileMenuData={profileMenuData}
          />
        </Stack>
      </Toolbar>
    </CustomAppBar>
  );
};
export default FixedAppBar;
