export const getCoursesSelector = state => state.courseManagement.courses;

export const getCourseInstructionTypeSelector = state =>
  state.courseManagement.courseInstructionsTypes.data;

export const getCourseTransferTypeSelector = state =>
  state.courseManagement.courseTransferTypes.data;

export const getCreditStatusSelector = state =>
  state.courseManagement.courseCreditStatus.data;

export const getSkillsSelector = state => state.courseManagement.skills.data;
export const getSkillsWithRequestSelector = state =>
  state.courseManagement.skills;

export const getCatalogSelector = state => state.courseManagement.catalogs;
