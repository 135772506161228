import * as TYPES from './constants';

// get Talent Exchange Students List
export const requestTalentExchangeStudents = () => ({
  type: TYPES.TALENT_EXCHANGE_STUDENTS_LIST_REQUEST,
});
export const receiveTalentExchangeStudents = json => ({
  type: TYPES.TALENT_EXCHANGE_STUDENTS_LIST_RECEIVE,
  payload: json,
});
export const handleTalentExchangeStudentsError = err => ({
  type: TYPES.TALENT_EXCHANGE_STUDENTS_LIST_ERROR,
  err,
});
export const clearTalentExchangeStudents = () => ({
  type: TYPES.TALENT_EXCHANGE_STUDENTS_LIST_CLEAR,
});

//Saved Searches
export const requestSavedSearches = () => ({
  type: TYPES.SAVED_SEARCH_LIST_REQUEST,
});
export const receiveSavedSearches = json => ({
  type: TYPES.SAVED_SEARCH_LIST_RECEIVE,
  payload: json,
});
export const handleSavedSearchesError = err => ({
  type: TYPES.SAVED_SEARCH_LIST_ERROR,
});

//SavedProfile List
export const requestSavedProfiles = () => ({
  type: TYPES.SAVED_PROFILES_LIST_REQUEST,
});
export const receiveSavedProfiles = json => ({
  type: TYPES.SAVED_PROFILES_LIST_RECEIVE,
  payload: json,
});
export const handleSavedProfilesError = err => ({
  type: TYPES.SAVED_PROFILES_LIST_ERROR,
  err,
});
export const clearSavedProfiles = () => ({
  type: TYPES.SAVED_PROFILES_LIST_CLEAR,
});

//Save Profile
export const requestSaveProfile = () => ({
  type: TYPES.SAVE_PROFILE_REQUEST,
});
export const receiveSaveProfile = json => ({
  type: TYPES.SAVE_PROFILE_RECEIVE,
  payload: json,
});
export const handleSaveProfileError = err => ({
  type: TYPES.SAVE_PROFILE_ERROR,
  err,
});
