import React, {Component} from 'react';
import {Input} from 'antd';
import {Counter} from 'core';

const {TextArea} = Input;

// TODO:use this component in AddEventDetails

class FormFieldCounter extends Component {
  state = {count: 0};
  countChar = (e, type) => {
    this.setState({
      count: e.target.value.length,
    });
  };
  charLimit = (e, field, limit) => {
    const {form, fieldname} = this.props;
    form.setFieldsValue({
      [fieldname]: e.target.value.substr(0, limit),
    });
  };
  render() {
    const {fieldlen, placeholder, textareamfield} = this.props;
    const Formfield = textareamfield ? TextArea : Input;
    return (
      <>
        <Formfield
          className="send-detail-input"
          rows={4}
          onChange={e => this.countChar(e)}
          onKeyDown={e => this.charLimit(e, fieldlen)}
          onKeyUp={e => this.charLimit(e, fieldlen)}
          placeholder={placeholder}
          maxLength={fieldlen}
        />
        <Counter total={fieldlen} current={this.state.count} />
      </>
    );
  }
}

export default FormFieldCounter;
