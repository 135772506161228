import * as TYPES from './constants';

// SRM PARTNERS
export const receiveUsers = json => ({
  type: TYPES.RECEIVE_BUSINESS_PARTNER_USERS,
  payload: json,
});

export const requestUsers = () => ({
  type: TYPES.REQUEST_BUSINESS_PARTNER_USERS,
});

export const handleUsersError = err => ({
  type: TYPES.BUSINESS_PARTNER_USERS_ERROR,
  err,
});

// SRM PARTNER Profile
export const receiveBusinessPartnerProfile = json => ({
  type: TYPES.RECEIVE_BUSINESS_PARTNER_PROFILE,
  payload: json,
});

export const requestBusinessPartnerProfile = () => ({
  type: TYPES.REQUEST_BUSINESS_PARTNER_PROFILE,
});

export const handleBusinessPartnerProfileError = err => ({
  type: TYPES.BUSINESS_PARTNER_PROFILE_ERROR,
  err,
});

export const toggleCompanyProfile = data => ({
  type: TYPES.TOGGLE_COMPANY_PROFILE,
  payload: data,
});

export const goToNext = () => ({
  type: TYPES.GO_TO_NEXT,
});

export const goToPrev = () => ({
  type: TYPES.GO_TO_PREVIOUS,
});

export const resetStep = () => ({
  type: TYPES.RESET_STEP,
});

export const setStep = data => ({
  type: TYPES.SET_STEP,
  payload: data,
});

//SRM Business Partner Jobs
export const changeBusinessPartnerJobsFirstTimeFetchStatus = value => ({
  type: TYPES.CHANGE_BUSINESS_PARTNER_JOBS_FIRST_FETCH,
  payload: value,
});

export const receiveBusinessPartnerJobs = json => ({
  type: TYPES.RECEIVE_BUSINESS_PARTNER_JOBS,
  payload: json,
});

export const requestBusinessPartnerJobs = () => ({
  type: TYPES.REQUEST_BUSINESS_PARTNER_JOBS,
});

export const handleBusinessPartnerJobsError = err => ({
  type: TYPES.BUSINESS_PARTNER_JOBS_ERROR,
  err,
});

//SRM Business Partner Student Profiles
export const receiveBusinessPartnerStudentProfiles = json => ({
  type: TYPES.RECEIVE_BUSINESS_PARTNER_STUDENT_PROFILES,
  payload: json,
});

export const requestBusinessPartnerStudentProfiles = () => ({
  type: TYPES.REQUEST_BUSINESS_PARTNER_STUDENT_PROFILES,
});

export const handleBusinessPartnerStudentProfilesError = err => ({
  type: TYPES.BUSINESS_PARTNER_STUDENT_PROFILES_ERROR,
  err,
});

export const updateBusinessPartnerStudentProfilesFilterStatus = value => ({
  type: TYPES.UPDATE_BUSINESS_PARTNER_STUDENT_PROFILES_FILTER_STATUS,
  payload: value,
});

//Fetch Student Profile
export const clearBusinessPartnerStudentProfile = () => ({
  type: TYPES.CLEAR_BUSINESS_PARTNER_STUDENT_PROFILE,
});

export const receiveBusinessPartnerStudentProfile = json => ({
  type: TYPES.RECEIVE_BUSINESS_PARTNER_STUDENT_PROFILE,
  payload: json,
});

export const requestBusinessPartnerStudentProfile = () => ({
  type: TYPES.REQUEST_BUSINESS_PARTNER_STUDENT_PROFILE,
});

export const handleBusinessPartnerStudentProfileError = err => ({
  type: TYPES.BUSINESS_PARTNER_STUDENT_PROFILE_ERROR,
  err,
});

//SRM Occupations
export const receiveOccupations = json => ({
  type: TYPES.RECEIVE_OCCUPATIONS,
  payload: json,
});

export const requestOccupations = () => ({
  type: TYPES.REQUEST_OCCUPATIONS,
});

export const handleOccupationsError = err => ({
  type: TYPES.OCCUPATIONS_ERROR,
  err,
});

//SRM Business Partner Programs
export const changeBusinessPartnerProgramFirstTimeFetchStatus = value => ({
  type: TYPES.CHANGE_BUSINESS_PARTNER_PROGRAM_FIRST_FETCH,
  payload: value,
});
export const receiveBusinessPartnerProgram = json => ({
  type: TYPES.RECEIVE_BUSINESS_PARTNER_PROGRAM,
  payload: json,
});
export const receiveBusinessPartnerProgramDetails = json => ({
  type: TYPES.RECEIVE_BUSINESS_PARTNER_PROGRAM_DETAILS,
  payload: json,
});

export const receiveBusinessPartnerProgramStudentsList = json => ({
  type: TYPES.RECEIVE_BUSINESS_PARTNER_PROGRAM_STUDENT_LIST,
  payload: json,
});

export const clearBusinessPartnerProgramData = () => ({
  type: TYPES.RECEIVE_BUSINESS_PARTNER_PROGRAM_DATA_CLEAR,
});

export const requestBusinessPartnerProgram = () => ({
  type: TYPES.REQUEST_BUSINESS_PARTNER_PROGRAM,
});
export const requestBusinessPartnerProgramStudentList = () => ({
  type: TYPES.REQUEST_BUSINESS_PARTNER_PROGRAM_STUDENT_LIST,
});

export const handleBusinessPartnerProgramError = err => ({
  type: TYPES.BUSINESS_PARTNER_PROGRAM_ERROR,
  err,
});

//SRM Business Partner Opportunity Details
export const requestBusinessPartnerOpportunityDetails = () => ({
  type: TYPES.REQUEST_BUSINESS_PARTNER_OPPORTUNITY_DETAILS,
});

export const receiveBusinessPartnerOpportunityDetails = json => ({
  type: TYPES.RECEIVE_BUSINESS_PARTNER_OPPORTUNITY_DETAILS,
  payload: json,
});

export const handleBusinessPartnerOpportunityDetailsError = err => ({
  type: TYPES.BUSINESS_PARTNER_OPPORTUNITY_DETAILS_ERROR,
  err,
});

export const clearBusinessPartnerOpportunityData = () => ({
  type: TYPES.RECEIVE_BUSINESS_PARTNER_OPPORTUNITY_DATA_CLEAR,
});

export const requestBusinessPartnerOpportunityStudentList = () => ({
  type: TYPES.REQUEST_BUSINESS_PARTNER_OPPORTUNITY_STUDENT_LIST,
});

export const receiveBusinessPartnerOpportunityStudentList = json => ({
  type: TYPES.RECEIVE_BUSINESS_PARTNER_OPPORTUNITY_STUDENT_LIST,
  payload: json,
});

//SRM Business Partner Dashboard
export const receiveBusinessPartnerDashboard = json => ({
  type: TYPES.RECEIVE_BUSINESS_PARTNER_DASHBOARD,
  payload: json,
});

export const requestBusinessPartnerDashboard = () => ({
  type: TYPES.REQUEST_BUSINESS_PARTNER_DASHBOARD,
});

export const handleBusinessPartnerDashboardError = err => ({
  type: TYPES.BUSINESS_PARTNER_DASHBOARD_ERROR,
  err,
});
//SRM Business Partner Industry
export const receiveBusinessPartnerIndustry = json => ({
  type: TYPES.RECEIVE_BUSINESS_PARTNER_INDUSTRY,
  payload: json,
});

export const requestBusinessPartnerIndustry = () => ({
  type: TYPES.REQUEST_BUSINESS_PARTNER_INDUSTRY,
});

export const handleBusinessPartnerIndustryError = err => ({
  type: TYPES.BUSINESS_PARTNER_INDUSTRY_ERROR,
  err,
});

export const filterGraphQuaterly = months => ({
  type: TYPES.BUSINESS_PARTNER_MONTHS_FILTER,
  months,
});

export const requestStudentSkillsStatus = () => ({
  type: TYPES.REQUEST_STUDENT_SKILS_STATUS,
});

export const receiveStudentSkillsStatus = json => ({
  type: TYPES.RECEIVE_STUDENT_SKILS_STATUS,
  payload: json,
});

export const handleStudentSkillsStatusError = err => ({
  type: TYPES.STUDENT_SKILS_STATUS_ERROR,
  err,
});

export const requestSkillsData = () => ({
  type: TYPES.REQUEST_SKILLS_DATA,
});

export const receiveSkillsData = json => ({
  type: TYPES.RECEIVE_SKILLS_DATA,
  payload: json,
});

export const handleSkillsDataError = err => ({
  type: TYPES.SKILLS_DATA_ERROR,
  err,
});

// Student exchange programs
export const requestStudentExchangePrograms = () => ({
  type: TYPES.REQUEST_STUDENT_EXCHANGE_PROGRAMS,
});

export const receiveStudentExchangePrograms = json => ({
  type: TYPES.RECEIVE_STUDENT_EXCHANGE_PROGRAMS,
  payload: json,
});

export const handleStudentExchangeProgramsError = err => ({
  type: TYPES.STUDENT_EXCHANGE_PROGRAMS_ERROR,
  err,
});

// Qulifying programs
export const requestQualifyingPrograms = () => ({
  type: TYPES.REQUEST_QUALIFYING_PROGRAMS,
});

export const receiveQualifyingPrograms = json => ({
  type: TYPES.RECEIVE_QUALIFYING_PROGRAMS,
  payload: json,
});

export const handleQualifyingProgramsError = err => ({
  type: TYPES.QUALIFYING_PROGRAMS_ERROR,
  err,
});

// Opportunities
export const clearOpportunities = () => ({
  type: TYPES.CLEAR_OPPORTUNITIES,
});

export const receiveOpportunities = json => ({
  type: TYPES.RECEIVE_OPPORTUNITIES,
  payload: json,
});

export const requestOpportunities = () => ({
  type: TYPES.REQUEST_OPPORTUNITIES,
});

export const OpportunitiesError = err => ({
  type: TYPES.OPPORTUNITIES_ERROR,
  err,
});

export const receiveOpportunityDetails = json => ({
  type: TYPES.RECEIVE_OPPORTUNITY_DETAILS,
  payload: json,
});

export const clearOpportunityDetails = () => ({
  type: TYPES.OPPORTUNITY_DETAILS_CLEAR,
});

// External companies i.e. careeronestop companies
export const requestCareerOneStopCompanies = () => ({
  type: TYPES.REQUEST_CAREER_ONE_STOP_COMPANIES,
});

export const receivedCareerOneStopCompanies = json => ({
  type: TYPES.RECEIVED_CAREER_ONE_STOP_COMPANIES,
  payload: json,
});

export const careerOneStopCompaniesError = () => ({
  type: TYPES.CAREER_ONE_STOP_COMPANIES_ERROR,
});

// Achievement Options for BP
export const requestAchievementOptions = () => ({
  type: TYPES.REQUEST_ACHIEVEMENT_OPTIONS,
});

export const receivedAchievementOptions = json => ({
  type: TYPES.RECEIVED_ACHIEVEMENT_OPTIONS,
  payload: json,
});

export const AchievementOptionsError = () => ({
  type: TYPES.ACHIEVEMENT_OPTIONS_ERROR,
});
