export const REQUEST_COURSE_MANAGEMENT_COURSES =
  'REQUEST_COURSE_MANAGEMENT_COURSES';
export const RECEIVE_COURSE_MANAGEMENT_COURSES =
  'RECEIVE_COURSE_MANAGEMENT_COURSES';
export const COURSE_MANAGEMENT_COURSES_ERROR =
  'COURSE_MANAGEMENT_COURSES_ERROR';

export const REQUEST_SKILLS = 'REQUEST_SKILLS';
export const RECEIVE_SKILLS = 'RECEIVE_SKILLS';
export const ADD_SKILL = 'ADD_SKILL';
export const EDIT_SKILL = 'EDIT_SKILL';
export const SKILLS_ERROR = 'SKILLS_ERROR';

export const REQUEST_COURSE_MANAGEMENT_CREDIT_STATUS =
  'REQUEST_COURSE_MANAGEMENT_CREDIT_STATUS';
export const RECEIVE_COURSE_MANAGEMENT_CREDIT_STATUS =
  'RECEIVE_COURSE_MANAGEMENT_CREDIT_STATUS';
export const COURSE_MANAGEMENT_CREDIT_STATUS_ERROR =
  'COURSE_MANAGEMENT_CREDIT_STATUS_ERROR';

export const REQUEST_COURSE_MANAGEMENT_COURSE_TRANSFER_TYPE =
  'REQUEST_COURSE_MANAGEMENT_COURSE_TRANSFER_TYPE';
export const RECEIVE_COURSE_MANAGEMENT_COURSE_TRANSFER_TYPE =
  'RECEIVE_COURSE_MANAGEMENT_COURSE_TRANSFER_TYPE';
export const COURSE_MANAGEMENT_COURSE_TRANSFER_TYPE_ERROR =
  'COURSE_MANAGEMENT_COURSE_TRANSFER_TYPE_ERROR';

export const REQUEST_COURSE_MANAGEMENT_COURSE_INSTRUCTION_TYPE =
  'REQUEST_COURSE_MANAGEMENT_COURSE_INSTRUCTION_TYPE';
export const RECEIVE_COURSE_MANAGEMENT_COURSE_INSTRUCTION_TYPE =
  'RECEIVE_COURSE_MANAGEMENT_COURSE_INSTRUCTION_TYPE';
export const COURSE_MANAGEMENT_COURSE_INSTRUCTION_TYPE_ERROR =
  'COURSE_MANAGEMENT_COURSE_INSTRUCTION_TYPE_ERROR';

export const RECEIVE_CATALOG = 'RECEIVE_CATALOG';
export const REQUEST_CATALOG = 'REQUEST_CATALOG';
export const CATALOG_ERROR = 'CATALOG_ERROR';
