import * as TYPES from 'redux/types';

import {API} from 'config/srm.config';

const initialState = {request: true};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_INSTITUTION_FACULTY_TYPE:
      return {
        ...state,
        ...action.payload,
      };
    case TYPES.RECEIVE_INSTITUTION_FACULTY_TYPE:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.REQUEST_INSTITUTION_FACULTY_TYPE_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

const receiveInstitutionFacultyType = json => ({
  type: TYPES.RECEIVE_INSTITUTION_FACULTY_TYPE,
  payload: json,
});
const requestInstitutionFacultyTypeError = err => ({
  type: TYPES.REQUEST_INSTITUTION_FACULTY_TYPE_ERROR,
  err,
});
const requestInstitutionFaculty = json => ({
  type: TYPES.REQUEST_INSTITUTION_FACULTY_TYPE,
  payload: json,
});

export const fetchInstitutionFacultyType = () => ({
  type: TYPES.APIREQUEST,
  payload: {
    endpoint: `${API.institution.faculty_type}`,
    method: 'GET',
    server: 'institution',
    onsuccess: receiveInstitutionFacultyType,
    onerror: requestInstitutionFacultyTypeError,
    onRequestStart: requestInstitutionFaculty,
  },
});
export const fetchInstitution = () => ({
  type: TYPES.APIREQUEST,
  payload: {
    endpoint: `${API.institution.faculty_type}`,
    method: 'GET',
    server: 'institution',
    onsuccess: receiveInstitutionFacultyType,
    onerror: requestInstitutionFacultyTypeError,
    onRequestStart: requestInstitutionFaculty,
  },
});

export const institutionFacultyTypeSelector = ({facultyType: {data}}) => data;
export const institutionFacultyTypeRequest = ({facultyType: {request}}) =>
  request;
export const institutionFacultyTypeRequestError = ({facultyType: {error}}) =>
  error;
