import * as TYPES from './constants';
import {
  requestStudentsMetrics,
  receiveStudentsMetrics,
  handleStudentsMetricsError,
} from './actions';

import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_STUDENTS_METRICS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_STUDENTS_METRICS:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.STUDENTS_METRICS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchStudentsMetrics = (filterData = {}) => (
  dispatch,
  getState
) => {
  const {
    age = 'All',
    gender = 'All',
    institution = 'All',
    program = 'All',
    race = 'All',
    time = 'All',
    swf_insights_filter = 'All',
  } = filterData;

  const {auth: {access: {token = null} = {}} = {}} = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestStudentsMetrics());
  try {
    return fetch(
      `${
        API.strong_workforce.student_data
      }?college=${institution}&program=${program}&gender=${gender}&race=${race}&age_group=${age}&swf_insights_filter=${swf_insights_filter}`,
      config
    )
      .then(response => response.json())
      .then(json => {
        dispatch(receiveStudentsMetrics(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleStudentsMetricsError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleStudentsMetricsError(err));
  }
};
