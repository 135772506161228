import * as TYPES from './constants';

// Institution
export const requestInstitutions = () => ({
  type: TYPES.REQUEST_CONSORTIUM_INSTITUTIONS,
});

export const receiveInstitutions = json => ({
  type: TYPES.RECEIVE_CONSORTIUM_INSTITUTIONS,
  payload: json,
});
export const receiveInstitutionsListForFilter = json => ({
  type: TYPES.RECEIVE_CONSORTIUM_INSTITUTIONS_LIST_FOR_FILTER,
  payload: json,
});

export const handleInstitutionsError = err => ({
  type: TYPES.CONSORTIUM_INSTITUTIONS_ERROR,
  err,
});

// Consortium Profile
export const receiveConsortiumProfile = json => {
  return {
    type: TYPES.REQUEST_CONSORTIUM_USER,
    payload: json,
  };
};

export const handleReceiveProfileError = err => ({
  type: TYPES.REQUEST_CONSORTIUM_USER_ERROR,
  err,
});

export const setToggleConsortiumProfile = data => ({
  type: TYPES.TOGGLE_CONSORTIUM_PROFILE,
  payload: data,
});

// Institution Types
export const requestInstitutionTypes = () => ({
  type: TYPES.REQUEST_CONSORTIUM_INSTITUTION_TYPES,
});

export const receiveInstitutionTypes = json => ({
  type: TYPES.RECEIVE_CONSORTIUM_INSTITUTION_TYPES,
  payload: json,
});

export const handleInstitutionTypesError = err => ({
  type: TYPES.CONSORTIUM_INSTITUTION_TYPES_ERROR,
  err,
});

//SRM Consortium Institute Details
export const receiveConsortiumInstituteDetails = json => ({
  type: TYPES.RECEIVE_CONSORTIUM_INSTITUTE_DETAILS,
  payload: json,
});

export const requestConsortiumInstituteDetails = () => ({
  type: TYPES.REQUEST_CONSORTIUM_INSTITUTE_DETAILS,
});

export const handleConsortiumInstituteDetailsError = err => ({
  type: TYPES.CONSORTIUM_INSTITUTE_DETAILS_ERROR,
  err,
});

export const clearConsortiumInstituteDetails = () => ({
  type: TYPES.CLEAR_CONSORTIUM_INSTITUTE_DETAILS,
});
