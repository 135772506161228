import * as React from 'react';
import {styled} from '@mui/material/styles';
import {Add as AddIcon, Remove as RemoveIcon} from '@mui/icons-material';
import {Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import PropTypes from 'prop-types';

const StyledAccordionSummary = styled(
  ({isExpanded, collpsable, ...restProps}) => (
    <AccordionSummary
      expandIcon={isExpanded ? <RemoveIcon /> : <AddIcon />}
      {...restProps}
    />
  )
)(({collpsable}) => ({
  cursor: collpsable ? 'pointer' : 'default',
  '& .MuiAccordionSummary-content': {
    cursor: collpsable ? 'pointer' : 'default',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    display: collpsable ? 'block' : 'none',
  },
}));

const CustomizedAccordions = props => {
  const {
    id,
    title,
    children,
    elevation = 0,
    collpsable = false,
    defaultExpanded = false,
  } = props;
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  const handleChange = () => {
    collpsable && setExpanded(!expanded);
  };

  return (
    <Accordion
      elevation={elevation}
      disableGutters
      expanded={expanded}
      onChange={handleChange}
      {...props}>
      <StyledAccordionSummary
        isExpanded={expanded}
        collpsable={collpsable}
        id={`panel${id}-header`}
        aria-controls={`panel${id}-content`}>
        {title}
      </StyledAccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

CustomizedAccordions.propTypes = {
  ...Accordion.propTypes,
  id: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  collpsable: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
};

export default CustomizedAccordions;
