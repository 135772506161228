import * as TYPES from './constants';

// SRM HOME DASHBOARD
export const receiveDashboardData = json => ({
  type: TYPES.RECEIVE_DASHBOARD_DATA,
  payload: json,
});

export const requestDashboardData = () => ({
  type: TYPES.REQUEST_DASHBOARD_DATA,
});

export const requestUploadProfileData = () => ({
  type: TYPES.REQUEST_UPLOAD_PROFILE_DATA,
});

export const handleProfileDataSuccessError = () => ({
  type: TYPES.UPLOAD_PROFILE_DATA_SUCCESS_ERROR,
});

export const handleDashboardDataError = err => ({
  type: TYPES.DASHBOARD_DATA_ERROR,
  err,
});

export const onChangeProgramListFilter = programListFilter => ({
  type: TYPES.DASHBOARD_PROGRAM_LIST_FILTER,
  programListFilter,
});

export const receiveDashboardProgramEnrollmentData = json => ({
  type: TYPES.RECEIVE_DASHBOARD_PROGRAM_ENROLLMENT_DATA,
  payload: json,
});

export const requestDashboardProgramEnrollmentData = () => ({
  type: TYPES.REQUEST_DASHBOARD_PROGRAM_ENROLLMENT_DATA,
});

export const handleDashboardProgramEnrollmentDataError = err => ({
  type: TYPES.DASHBOARD_PROGRAM_ENROLLMENT_DATA_ERROR,
  err,
});
