export const CONSORTIUM_ROUTES = {
  HOME: {DASHBOARD: '/'},
  ADMINISTRATION: {
    USER_MANAGEMENT: {USERS: '/users'},
    INSTITUTION_MANAGEMENT: {
      INSTITUTIONS: '/institutions',
      CREATE: '/create/institution',
      EDIT_INSTITUTE: '/edit/institution',
    },
  },
  OUTREACH: {
    CALENDER: '/events/calendar',
    EVENTS: '/events',
    ADD_EVENT: '/addevent',
    EDIT_EVENT: '/editevent',
  },
  PARTNERS: {
    DASHBOARD: '/partners/dashboard',
    PARTNERS_LIST: '/partners/partners-list/partners',
  },
  SEND: {EMAIL: '/send/message'},
  CONSORTIUM: {CREATE: '/consortium/profile', EDIT: '/edit'},
  REPORTS: {DASHBOARD: '/reports', CREATE_REPORTS: 'reports/create'},
};
