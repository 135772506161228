export const REQUEST_SUPER_USER_LICENSE = 'REQUEST_SUPER_USER_LICENSE';
export const RECEIVE_SUPER_USER_LICENSE = 'RECEIVE_SUPER_USER_LICENSE';
export const SUPER_USER_LICENSE_ERROR = 'SUPER_USER_LICENSE_ERROR';
export const REQUEST_SUPER_USER_QUIKFYND_DATA =
  'REQUEST_SUPER_USER_QUIKFYND_DATA';
export const RECEIVE_SUPER_USER_QUIKFYND_DATA =
  'RECEIVE_SUPER_USER_QUIKFYND_DATA';
export const SUPER_USER_QUIKFYND_DATA_ERROR = 'SUPER_USER_QUIKFYND_DATA_ERROR';
export const REQUEST_SUPER_USER_INSTITUTION_TYPES =
  'REQUEST_SUPER_USER_INSTITUTION_TYPES';
export const RECEIVE_SUPER_USER_INSTITUTION_TYPES =
  'RECEIVE_SUPER_USER_INSTITUTION_TYPES';
export const SUPER_USER_INSTITUTION_TYPES_ERROR =
  'SUPER_USER_INSTITUTION_TYPES_ERROR';

export const REQUEST_SUPER_USER_INSTITUTIONS =
  'REQUEST_SUPER_USER_INSTITUTIONS';
export const RECEIVE_SUPER_USER_INSTITUTIONS =
  'RECEIVE_SUPER_USER_INSTITUTIONS';
export const SUPER_USER_INSTITUTIONS_ERROR = 'SUPER_USER_INSTITUTIONS_ERROR';
export const REQUEST_SUPER_USER_INSTITUTE_DETAILS =
  'REQUEST_SUPER_USER_INSTITUTE_DETAILS';
export const RECEIVE_SUPER_USER_INSTITUTE_DETAILS =
  'RECEIVE_SUPER_USER_INSTITUTE_DETAILS';
export const SUPER_USER_INSTITUTE_DETAILS_ERROR =
  'SUPER_USER_INSTITUTE_DETAILS_ERROR';
export const CLEAR_SUPER_USER_INSTITUTE_DETAILS =
  'CLEAR_SUPER_USER_INSTITUTE_DETAILS';

export const REQUEST_SUPER_USER_INSTITUTES_TIMELINE =
  'REQUEST_SUPER_USER_INSTITUTES_TIMELINE';
export const RECEIVE_SUPER_USER_INSTITUTES_TIMELINE =
  'RECEIVE_SUPER_USER_INSTITUTES_TIMELINE';
export const SUPER_USER_INSTITUTES_TIMELINE_ERROR =
  'SUPER_USER_INSTITUTES_TIMELINE_ERROR';

// Partners Requests and Responses
export const REQUEST_PARTNERS_REQUESTS = 'REQUEST_PARTNERS_REQUESTS';
export const RECEIVE_ALL_PARTNERS_REQUESTS = 'RECEIVE_ALL_PARTNERS_REQUESTS';
export const RECEIVE_PARTNERS_REQUESTS = 'RECEIVE_PARTNERS_REQUESTS';
export const PARTNERS_REQUESTS_ERROR = 'PARTNERS_REQUESTS_ERROR';
export const CLEAR_PARTNERS_REQUESTS = 'CLEAR_PARTNERS_REQUESTS';

// Banner Instances
export const REQUEST_BANNER_INSTANCES = 'REQUEST_BANNER_INSTANCES';
export const RECEIVE_BANNER_INSTANCES = 'RECEIVE_BANNER_INSTANCES';
export const BANNER_INSTANCES_ERROR = 'BANNER_INSTANCES_ERROR';

// Institute Types
export const REQUEST_SUPER_USER_INSTITUTE_TYPES =
  'REQUEST_SUPER_USER_INSTITUTE_TYPES';
export const RECEIVE_SUPER_USER_INSTITUTE_TYPES =
  'RECEIVE_SUPER_USER_INSTITUTE_TYPES';
export const SUPER_USER_INSTITUTE_TYPES_ERROR =
  'SUPER_USER_INSTITUTE_TYPES_ERROR';
export const SUPER_USER_INSTITUTE_TYPES_CLEAR =
  'SUPER_USER_INSTITUTE_TYPES_CLEAR';

// MSA Locations
export const MSA_LOCATIONS_ERROR = 'MSA_LOCATIONS_ERROR';
export const REQUEST_MSA_LOCATIONS = 'REQUEST_MSA_LOCATIONS';
export const RECEIVE_MSA_LOCATIONS = 'RECEIVE_MSA_LOCATIONS';

// Unique membership code
export const UNIQUE_MEMBERSHIP_CODE_ERROR = 'UNIQUE_MEMBERSHIP_CODE_ERROR';
export const REQUEST_UNIQUE_MEMBERSHIP_CODE = 'REQUEST_UNIQUE_MEMBERSHIP_CODE';
export const RECEIVE_UNIQUE_MEMBERSHIP_CODE = 'RECEIVE_UNIQUE_MEMBERSHIP_CODE';
