import {createSelector} from 'reselect';
import {
  isEmpty,
  calculateQuaterlyMonthsData,
  getSortedUniqueYears,
  prepareTimelineLables,
  prepareTimeLineDatasets,
} from 'core';
import {monthsShortNames} from 'config/srm.config';
import _ from 'lodash';

// Static Data for TimeLine Manipulations
const staticTimeLineData = {
  labels: [],
  datasets: [
    {
      key: 'Program',
      label: 'Programs',
      data: [],
      borderColor: 'rgb(250, 180, 10)',
      backgroundColor: 'rgb(250, 180, 10)',
    },
    {
      key: 'Jobs',
      label: 'Jobs',
      data: [],
      borderColor: 'rgb(26, 178, 102)',
      backgroundColor: 'rgb(26, 178, 102)',
    },
    {
      key: 'Event',
      label: 'Events',
      data: [],
      borderColor: 'rgb(139, 87, 42)',
      backgroundColor: 'rgb(139, 87, 42)',
    },
  ],
};

export const getAllBpUsersSelector = state =>
  state && state.businessPartnerAdmin && state.businessPartnerAdmin.users.users;
export const getBpUsersErrorSelector = state =>
  state && state.businessPartnerAdmin && state.businessPartnerAdmin.users.error;
export const getBpUsersRequestSelector = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.users.request;

export const getAllBpUsers = createSelector(
  [getBpUsersRequestSelector, getAllBpUsersSelector, getBpUsersErrorSelector],
  (
    getBpUsersRequestSelector,
    getAllBpUsersSelector,
    getBpUsersErrorSelector
  ) => ({
    request: getBpUsersRequestSelector,
    error: getBpUsersErrorSelector,
    data: getAllBpUsersSelector,
  })
);

export const getOccupationsSelector = state =>
  state && state.businessPartnerAdmin && state.businessPartnerAdmin.occupations;

export const getCompanyProfileSelector = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.companyProfile;

export const getAchievementOptionsData = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.studentAchievementsOptions;

export const getBusinessPartnerId = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.users.business_partner_id;

export const getCurrentStep = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.companyProfile.currentStep;

export const getJobsSelector = state =>
  state && state.businessPartnerAdmin && state.businessPartnerAdmin.jobs;

export const getProgramsSelector = state =>
  state && state.businessPartnerAdmin && state.businessPartnerAdmin.programs;

export const getOpportunityDetailsSelector = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.opportunityDetails;

export const getGraphDataSelector = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.dashboard &&
  state.businessPartnerAdmin.dashboard.data;

export const getGraphDataErrorSelector = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.dashboard &&
  state.businessPartnerAdmin.dashboard.error;

export const getGraphDataRequestSelector = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.dashboard &&
  state.businessPartnerAdmin.dashboard.request;

export const getIndustryDataSelector = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.industry &&
  state.businessPartnerAdmin.industry.data;

export const getStudentsProfileDataSelector = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.studentProfiles;

export const getStudentProfileDataSelector = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.studentProfile;

export const getStudentsProfileFilterStatusSelector = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.studentProfiles &&
  state.businessPartnerAdmin.studentProfiles.applyFilter;

const monthsDataSelector = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.dashboard &&
  state.businessPartnerAdmin.dashboard.monthsFilter;

// calculate graph data :::
export const getDashboardGraphData = createSelector(
  [getGraphDataSelector, monthsDataSelector],
  (getGraphDataSelector, monthsDataSelector) => {
    if (getGraphDataSelector) {
      const timeLineData = {};
      const data = getGraphDataSelector.graph_data;
      if (data) {
        const uniqYears = getSortedUniqueYears(data);
        const labels = prepareTimelineLables(data, uniqYears);
        const datasets = prepareTimeLineDatasets(
          staticTimeLineData,
          data,
          uniqYears,
          monthsDataSelector
        );
        timeLineData.labels = labels;
        timeLineData.datasets = datasets;
        if (monthsDataSelector !== 'all') {
          timeLineData.labels = calculateQuaterlyMonthsData(
            monthsDataSelector,
            timeLineData.labels,
            uniqYears
          );
        }
      }
      return timeLineData;
    }
  }
);

export const getDashboardStats = createSelector(
  [
    getGraphDataRequestSelector,
    getGraphDataSelector,
    getGraphDataErrorSelector,
  ],
  (
    getGraphDataRequestSelector,
    getGraphDataSelector,
    getGraphDataErrorSelector
  ) => ({
    request: getGraphDataRequestSelector,
    data: getGraphDataSelector,
    error: getGraphDataErrorSelector,
  })
);

export const studentSkillsSelector = state =>
  state.businessPartnerAdmin.studentSkills;

export const skillsDataSelector = state => state.businessPartnerAdmin.skills;

export const studentProfileData = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.studentProfileData;

export const studentAwardTypes = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.studentAwardTypes;

export const studentProfile = state =>
  state && state.businessPartnerAdmin && state.businessPartnerAdmin.profile;

export const studentExchangePrograms = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.studentExchangePrograms;

export const studentExchangeOccupations = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.studentExchangeOccupations;

export const getQualifyingPrograms = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.qualifyingPrograms;

export const getAllOpportunities = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.opportunities;

export const getCareerOneStopCompaniesList = state =>
  state &&
  state.businessPartnerAdmin &&
  state.businessPartnerAdmin.careerOneStopCompanies;
