import React from 'react';
import {Button, Form, Row, Col, Input, Select, AutoComplete} from 'antd';
import {GPS_DOMAIN} from 'config/srm.config';
import {
  FileUploader,
  Editor,
  PreviewImageUploader,
  Phone,
  onZipCodeChange,
  UrlInput,
  PATTERN,
  isArray,
  getCloudinaryImage,
  FieldWithCount,
  TinyEditor,
  numberInUSFormat,
} from 'core';

const FormItem = Form.Item;
const {Option} = Select;

const CreateProfile = props => {
  const {
    form,
    form: {getFieldDecorator, resetFields},
    onSave,
    initialData,
    banner_image,
    deleteLogo,
    getLogo,
    logoData,
    getBannerData,
    deleteBannerHandler,
    states,
    institutionType,
    tuitionLinkOptions,
  } = props;

  const {
    physical_address_details: {
      address_line1,
      address_line2,
      zipcode,
      state,
      city,
    } = {},
    institution_super_type_name,
    institution_type_name,
    overview_contents,
    banner_cloudinary,
    institution_link,
    logo_cloudinary,
    phone_number,
    default_msa: {city: msa_city = '', state_code = ''} = {},
    subdomain,
    email_id,
    web_url,
    tagline,
    name,
  } = initialData || {};

  let defZip = zipcode;
  if (
    zipcode &&
    typeof zipcode === 'string' &&
    zipcode.length > 5 &&
    !zipcode.includes('-')
  ) {
    defZip = zipcode.slice(0, 5) + '-' + zipcode.slice(5);
  }

  const imageData =
    banner_image ||
    ((banner_cloudinary && getCloudinaryImage(banner_cloudinary)) || '');
  const companyLogo =
    logoData ||
    ((logo_cloudinary && getCloudinaryImage(logo_cloudinary, 'logo')) || '');

  const webUrl =
    (web_url &&
      `${web_url.includes('http') ? web_url : 'https://' + web_url}`) ||
    '';

  const getTuitionAmount = (npt4_pub, npt4_prog, npt4_priv, npt4_other) => {
    const getValue = npt4_pub || npt4_prog || npt4_priv || npt4_other;
    if (!getValue) {
      return getValue;
    } else {
      const formattedAmount = numberInUSFormat(getValue);
      return `$${formattedAmount}`;
    }
  };

  const isInValidTuitionLink = linkId => {
    return (
      tuitionLinkOptions?.findIndex(
        linkObj => linkObj.id === parseInt(linkId)
      ) === -1
    );
  };

  return (
    <div className="steps-content bp-company-profile">
      <Form onSubmit={e => onSave(e, form)}>
        <div className="table-form bp-cprofile-form border-bot">
          <div className="content-pad border-bot">
            <FormItem>
              <div className="file-upload banner-full-view banner-upload-img">
                <PreviewImageUploader
                  title="Upload Banner Image"
                  onChange={getBannerData}
                  onDelete={deleteBannerHandler}
                  icon={<span className="icon-upload-img" />}
                  dragImage={true}
                  readOnly={false}
                  allowedFormat="image"
                  data={imageData}
                  changeImageLabel="Change Banner Image"
                  forceUpdate
                />
              </div>
            </FormItem>
            <Row type="flex" justify="space-between">
              <Col span={12}>
                <div className=" ">
                  <FormItem label="Institution Name">
                    {getFieldDecorator('name', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter Institution name',
                        },
                      ],
                      initialValue: name || '',
                    })(<Input placeholder="Enter Institution Name" />)}
                  </FormItem>
                </div>
                <div className=" ">
                  <FormItem label="Upload Logo" className="upload-logo ">
                    {!companyLogo &&
                      getFieldDecorator('logo', {
                        rules: [
                          {required: true, message: 'Please Upload a logo'},
                        ],
                      })(<Input style={{display: 'none'}} />)}
                    <FileUploader
                      title="Upload logo"
                      imgSrc={companyLogo}
                      onChange={getLogo}
                      type="imagePreview"
                      multipleUpload={false}
                      deleteImage={() => deleteLogo('logo')}
                    />
                  </FormItem>
                </div>
                <div className="  counter_no">
                  <FormItem label="Tagline">
                    {getFieldDecorator('tagline', {
                      initialValue: tagline || '',
                    })(<FieldWithCount showcount maxLength={40} />)}
                  </FormItem>
                </div>
                {!institution_super_type_name?.type ===
                  'Criminal Justice Organization' && (
                  <div className=" ">
                    <FormItem label="Tuition Link">
                      {getFieldDecorator('institution_link', {
                        initialValue: institution_link
                          ? `${institution_link}`
                          : null,
                      })(
                        <AutoComplete
                          onBlur={value => {
                            if (value !== '' && isInValidTuitionLink(value)) {
                              resetFields('institution_link');
                            }
                          }}
                          size="large"
                          dropdownMatchSelectWidth={true}
                          className="certain-category-search"
                          dropdownClassName="certain-category-search-dropdown"
                          dataSource={
                            tuitionLinkOptions &&
                            tuitionLinkOptions.length &&
                            tuitionLinkOptions.map(
                              ({
                                institute_name,
                                id,
                                city,
                                state_code,
                                npt4_pub,
                                npt4_prog,
                                npt4_priv,
                                npt4_other,
                              }) => (
                                <AutoComplete.Option
                                  key={id}
                                  value={`${id}`}
                                  title={`${institute_name} - ${city}, ${state_code}`}>
                                  {`${institute_name} - ${city}, ${state_code} - ${getTuitionAmount(
                                    npt4_pub,
                                    npt4_prog,
                                    npt4_priv,
                                    npt4_other
                                  )}`}
                                </AutoComplete.Option>
                              )
                            )
                          }
                          placeholder="Please enter the tuition link"
                          filterOption={(inputValue, option) =>
                            option.props.title
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }>
                          <Input />
                        </AutoComplete>
                      )}
                    </FormItem>
                  </div>
                )}
              </Col>
              <Col span={12}>
                <div className=" ">
                  <FormItem label="Institution Type">
                    {getFieldDecorator('institution_type_name.name', {
                      rules: [
                        {
                          required: true,
                          message: 'Please select institution type',
                        },
                      ],
                      initialValue:
                        (institution_type_name && institution_type_name.name) ||
                        '',
                    })(
                      <Select disabled placeholder="Choose Institution Type">
                        {institutionType &&
                          institutionType.length &&
                          institutionType.map(({name, institution_type_id}) => (
                            <Option
                              key={institution_type_id}
                              value={name}
                              title={name}>
                              {name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </FormItem>
                </div>
                <div className=" ">
                  <FormItem label="Website">
                    {getFieldDecorator('web_url', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter a website link',
                        },
                        {
                          pattern: PATTERN.Website,
                          message: 'Please enter a valid website url',
                        },
                      ],
                      initialValue: webUrl,
                    })(<UrlInput />)}
                  </FormItem>
                </div>
                <div className=" url_web">
                  <FormItem label="Profile Link URL">
                    {getFieldDecorator('subdomain', {
                      rules: [
                        {
                          required: false,
                          message: 'Please enter a Profile link',
                        },
                      ],
                      initialValue: subdomain || '',
                    })(<Input disabled />)}
                    <span className="subdomain-domain">
                      <strong>&nbsp;&nbsp;&nbsp;.{GPS_DOMAIN}</strong>
                    </span>
                  </FormItem>
                </div>

                {msa_city && state_code && (
                  <div className="msa_link">
                    <FormItem label="MSA Link">
                      <Select
                        disabled
                        mode="tags"
                        placeholder="MSA Location"
                        defaultValue={`${msa_city}, ${state_code}`}
                      />
                    </FormItem>
                  </div>
                )}
              </Col>
            </Row>
          </div>
          <div className="custom-collapse">
            <div className="custom-collapse-header">Contact Details</div>
          </div>
          <Row type="flex">
            <Col className="table-form-2-col content-pad">
              <FormItem label="Address Line 1">
                {getFieldDecorator(
                  'physical_address_details["address_line1"]',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter address line 1',
                      },
                    ],
                    initialValue: address_line1 || '',
                  }
                )(<Input placeholder="Address Line 1" />)}
              </FormItem>
              <Phone
                getFieldDecorator={getFieldDecorator}
                label="Phone"
                data={phone_number}
                fieldName="phone_number"
              />
              <FormItem label="Address Line 2">
                {getFieldDecorator(
                  'physical_address_details["address_line2"]',
                  {
                    rules: [],
                    initialValue: address_line2 || '',
                  }
                )(<Input placeholder="Address Line 2" />)}
              </FormItem>
              <FormItem label="Email">
                {getFieldDecorator('email_id', {
                  rules: [
                    {
                      type: 'email',
                      whitespace: true,
                      message: 'Please enter a valid email',
                    },
                    {
                      required: false,
                      message: 'Please enter Institution email',
                    },
                  ],
                  initialValue: email_id || '',
                })(<Input placeholder="Institution Email" />)}
              </FormItem>
              <FormItem label="City">
                {getFieldDecorator('physical_address_details["city"]', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter city',
                    },
                    {
                      pattern: PATTERN.Name_With_Space,
                      message: 'Please enter a valid city',
                    },
                  ],
                  initialValue: city || '',
                })(<Input placeholder="City" />)}
              </FormItem>
              <FormItem />
              <FormItem label="State">
                {getFieldDecorator('physical_address_details["state"]', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter state',
                    },
                    {
                      pattern: PATTERN.Name_With_Space,
                      message: 'Please enter a valid state',
                    },
                  ],
                  initialValue: state || '',
                })(
                  <Select placeholder="State">
                    {states &&
                      isArray(states) &&
                      states.map((state, index) => (
                        <Option
                          key={`${state.uuid}-${index}`}
                          value={state.name}>
                          {state.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>
              <FormItem />
              <FormItem label="Zipcode">
                {getFieldDecorator('physical_address_details["zipcode"]', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter zip code',
                    },
                    {
                      whitespace: true,
                      pattern: PATTERN.Zip_Code,
                      message: 'Please enter a valid 5 or 9 digit zip code',
                    },
                  ],
                  initialValue: defZip || '',
                  getValueFromEvent: onZipCodeChange,
                })(<Input placeholder="Zip Code" />)}
              </FormItem>
            </Col>
          </Row>
        </div>
        <div className="bp-overview-form">
          <div className="custom-collapse">
            <div className="custom-collapse-header">Overview</div>
          </div>
          {/* <FormItem label="About Institution">
            <div className="clearfix">
              {getFieldDecorator(`overview_contents[0][value][0]`, {
                rules: [
                  {
                    // required: true,
                    whitespace: true,
                    message: 'Please enter Institution description',
                  },
                ],
                initialValue:
                  (initialData &&
                    initialData.overview_contents &&
                    initialData.overview_contents[0] &&
                    initialData.overview_contents[0].value[0] &&
                    initialData.overview_contents[0].value[0]) ||
                  '',
              })(<Editor maxLen={8000} />)}
            </div>
          </FormItem> */}
          <FormItem label="About Institution">
            <div className="clearfix">
              {getFieldDecorator(`overview_contents[0][value][0]`, {
                //validateFirst: true,
                rules: [
                  {
                    required: false,
                    //whitespace: true,
                    message: 'Please enter valid description.',
                  },
                  // {
                  //   max: 8000,
                  //   message:
                  //     'Description can not exceeds max length of 8000 characters',
                  // },
                ],
                initialValue:
                  (overview_contents &&
                    overview_contents[0] &&
                    overview_contents[0].value[0] &&
                    overview_contents[0].value[0]) ||
                  '',
              })(<TinyEditor maxLen={8000} />)}
            </div>
          </FormItem>
        </div>
        <div className="steps-action">
          <Button type="primary" htmlType="submit">
            Next Step
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Form.create()(CreateProfile);
