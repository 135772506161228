import React from 'react';
import {Stats as MUIStats} from 'mui-core';
import {StyledContainer, StatsLabel} from './styles';

const UserMetrics = ({data}) => {
  const prepareUsersStatsData = () => {
    if (data) {
      const {total_users} = data;
      const statsData = [
        {
          title: 'Total Users',
          count: total_users,
          color: '#6434CD',
        },
      ];
      return statsData;
    }
    return [];
  };
  const usersData = prepareUsersStatsData();
  return (
    <StyledContainer>
      <StatsLabel>User Metrics</StatsLabel>
      <MUIStats data={usersData} />
    </StyledContainer>
  );
};

export default UserMetrics;
