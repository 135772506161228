export const APIREQUEST = 'APIREQUEST';

// auth types
export const APP_STATUS = 'APP_STATUS';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const REFRESH_ACCESS_TOKEN = 'REFRESH_ACCESS_TOKEN';
export const SIGNOUT = 'SIGNOUT';
export const TOKEN_REQUEST = 'TOKEN_REQUEST';
export const TOKEN_RECEIVED = 'TOKEN_RECEIVED';
export const TOKEN_FAILURE = 'TOKEN_FAILURE';
export const REQUEST_MODULE_LIST_ERROR = 'REQUEST_MODULE_LIST_ERROR';
export const RECEIVE_MODULE_LIST = 'RECEIVE_MODULE_LIST';
export const RECEIVE_USER_WITH_ROLES_LIST = 'RECEIVE_USER_WITH_ROLES_LIST';
export const UPDATE_USER_WITH_ROLES_LIST = 'UPDATE_USER_WITH_ROLES_LIST';
export const RECEIVE_LOGO = 'RECEIVE_LOGO';
export const RECEIVE_USER_PERMISSIONS = 'RECEIVE_USER_PERMISSIONS';
export const RECEIVE_USER_TYPE = 'RECEIVE_USER_TYPE';
export const REQUEST_USER_TYPE_ERROR = 'REQUEST_USER_TYPE_ERROR';

// programs types
export const REQUEST_ACHIEVEMENT = 'REQUEST_ACHIEVEMENT';
export const RECEIVE_ACHIEVEMENT = 'RECEIVE_ACHIEVEMENT';
export const REQUEST_ACHIEVEMENT_ERROR = 'ACHIEVEMENT_ERROR';
export const REQUEST_SERVICE = 'REQUEST_SERVICE';
export const RECEIVE_SERVICE = 'RECEIVE_SERVICE';
export const REQUEST_SERVICE_ERROR = 'SERVICE_ERROR';
export const REQUEST_STUDENTPROGRAM = 'REQUEST_STUDENTPROGRAM';
export const RECEIVE_STUDENTPROGRAM = 'RECEIVE_STUDENTPROGRAM';
export const REQUEST_STUDENTPROGRAM_ERROR = 'STUDENTPROGRAM_ERROR';

// Administration types
export const DELETE_FACULTY = 'DELETE_FACULTY';
export const UPDATE_FACULTY_STATUS = 'UPDATE_FACULTY_STATUS';
export const REQUEST_FACULTY = 'REQUEST_FACULTY';
export const RECEIVE_FACULTY = 'RECEIVE_FACULTY';
export const REQUEST_FACULTY_ERROR = 'REQUEST_FACULTY_ERROR';
export const REQUEST_DEPARTMENT = 'REQUEST_DEPARTMENT';
export const RECEIVE_DEPARTMENT = 'RECEIVE_DEPARTMENT';
export const REQUEST_DEPARTMENT_ERROR = 'REQUEST_DEPARTMENT_ERROR';
export const REQUEST_ACCOUNTTYPE = 'REQUEST_ACCOUNTTYPE';
export const RECEIVE_ACCOUNTTYPE = 'RECEIVE_ACCOUNTTYPE';
export const REQUEST_ACCOUNTTYPE_ERROR = 'REQUEST_ACCOUNTTYPE_ERROR';

//notifications
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const NOTIFICATION_SETTINGS_ERROR = 'NOTIFICATION_SETTINGS_ERROR';
export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';
export const RECEIVE_NOTIFICATION_SETTINGS = 'RECEIVE_NOTIFICATION_SETTINGS';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const REQUEST_NOTIFICATIONS_ERROR = 'REQUEST_NOTIFICATIONS_ERROR';
export const REQUEST_NOTIFICATION_SETTINGS = 'REQUEST_NOTIFICATION_SETTINGS';

// System Management Institution types
export const REQUEST_INSTITUTION_FACULTY = 'REQUEST_INSTITUTION_FACULTY';
export const RECEIVE_INSTITUTION_FACULTY = 'RECEIVE_INSTITUTION_FACULTY';
export const REQUEST_INSTITUTION_FACULTY_ERROR =
  'REQUEST_INSTITUTION_FACULTY_ERROR';
export const REQUEST_INSTITUTION_FACULTY_TYPE =
  'REQUEST_INSTITUTION_FACULTY_TYPE';
export const RECEIVE_INSTITUTION_FACULTY_TYPE =
  'RECEIVE_INSTITUTION_FACULTY_TYPE';
export const REQUEST_INSTITUTION_FACULTY_TYPE_ERROR =
  'REQUEST_INSTITUTION_FACULTY_TYPE_ERROR';
export const REQUEST_INSTITUTION = 'REQUEST_INSTITUTION';
export const RECEIVE_INSTITUTION = 'RECEIVE_INSTITUTION';
export const REQUEST_INSTITUTION_ERROR = 'REQUEST_INSTITUTION_ERROR';
export const REQUEST_INSTITUTION_TYPE = 'REQUEST_INSTITUTION_TYPE';
export const RECEIVE_INSTITUTION_TYPE = 'RECEIVE_INSTITUTION_TYPE';
export const REQUEST_INSTITUTION_TYPE_ERROR = 'REQUEST_INSTITUTION_TYPE_ERROR';
export const REQUEST_INSTITUTION_DROPDOWN_ERROR =
  'REQUEST_INSTITUTION_DROPDOWN_ERROR';
export const RECEIVE_INSTITUTION_DROPDOWN = 'RECEIVE_INSTITUTION_DROPDOWN';
export const RECEIVE_INSTITUTION_FILTER = 'RECEIVE_INSTITUTION_FILTER';
export const REQUEST_INSTITUTION_FILTER_ERROR =
  'REQUEST_INSTITUTION_FILTER_ERROR';
export const RECEIVE_INSTITUTION_LIST = 'RECEIVE_INSTITUTION_LIST';
export const REQUEST_INSTITUTION_LIST_ERROR = 'REQUEST_INSTITUTION_LIST_ERROR';

export const REQUEST_INSTITUTION_USER = 'REQUEST_INSTITUTE_USER';
export const RECEIVE_INSTITUTION_USER = 'RECEIVE_INSTITUTE_USER';
export const REQUEST_INSTITUTION_USER_ERROR = 'REQUEST_INSTITUTION_USER_ERROR';
export const TOGGLE_INSTITUTION_PROFILE = 'TOGGLE_INSTITUTION_PROFILE';

// INSTANCE
export const INSTANCE_REQUEST = 'INSTANCE_REQUEST';
export const INSTANCE_SUCCESS = 'INSTANCE_SUCCESS';
export const INSTANCE_ERROR = 'INSTANCE_ERROR';
export const CLEAR_INSTANCE = 'CLEAR_INSTANCE';
