import React from 'react';
import {isArray, isObject, isEmpty} from 'lodash';
import {Icon} from 'antd';

const defaultStyle = {
  padding: 20,
  display: 'block',
  padding: '20px',
  margin: '0px auto',
};

const AccordionLoader = ({
  txt = 'Loading...',
  errorMsg = 'Something went wrong.',
  emptyMsg = 'No Data Found',
  body = {},
}) => {
  const {request, error, data} = body;
  if (error) {
    return <div className="text-center error">{errorMsg}</div>;
  }
  if (
    !request &&
    !error &&
    data &&
    (isArray(data) || isObject(data)) &&
    isEmpty(data)
  ) {
    return <div className="text-center error">{emptyMsg}</div>;
  }

  return (
    request && (
      <span className="text-center">
        <span className="loader" style={defaultStyle}>
          <Icon type="loading" spin />
          &nbsp;&nbsp;&nbsp;
          {txt}
        </span>
      </span>
    )
  );
};

export default AccordionLoader;
