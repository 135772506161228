import * as TYPES from 'redux/types';
import {JSON_HEADER, AUTH_HEADER, JWT_HEADER} from 'config/srm.config';

export default ({dispatch, getState}) => next => async action => {
  const {auth} = getState();
  if (action.type !== TYPES.APIREQUEST) {
    return next(action);
  }

  const {
    method,
    headers,
    server,
    endpoint,
    onsuccess,
    onerror,
    body,
    onRequestStart,
  } = action.payload;
  try {
    const config = {
      method,
      headers,
    };
    // if (!headers && server) {
    //   config.headers = Object.assign({}, AUTH_HEADER(auth[server]), JSON_HEADER);
    // }
    if (!headers) {
      config.headers = Object.assign(
        {},
        JWT_HEADER(auth['access']['token']),
        JSON_HEADER
      );
    }
    if (method === 'POST') {
      config.body = body;
    }
    if (onRequestStart) {
      dispatch(onRequestStart({request: true}));
    }

    if (!navigator.onLine) {
      dispatch(
        onerror(
          'Network Error : Please check your internet connection and try again!'
        )
      );
      return;
    }

    const response = await (await fetch(endpoint, config)).json();

    if (response.error) {
      dispatch(onerror(response.error));
    } else if (response.non_field_errors) {
      dispatch(onerror(response.non_field_errors[0]));
    } else if (response.detail) {
      dispatch(onerror(response.detail));
    } else {
      dispatch(onsuccess(response, body));
    }
  } catch ({message}) {
    if (
      (message === 'Failed to fetch' ||
        message === 'Unexpected token < in JSON at position 0') &&
      endpoint.includes('token/')
    ) {
      dispatch(
        onerror("At this moment login doesn't work. Please try after sometime!")
      );
      return;
    }
    dispatch(onerror(message));
  }
};

export const request = async data => {
  const {endpoint, body, token, method, headers} = data;
  const config = {
    method: method,
    headers: headers
      ? headers
      : Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    body,
  };
  try {
    return await (await fetch(`${endpoint}`, config)).json();
  } catch (err) {
    throw err;
  }
};
