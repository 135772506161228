import * as TYPES from './constants';
import {API as EMP_API} from 'config/employer.config';
import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import {
  requestUserDetail,
  receiveUserDetails,
  handleUserError,
} from './actions';

const initialState = {
  request: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_USER_DETAILS:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECEIVE_USER_DETAILS:
      if (action.payload) {
        return {
          ...state,
          show: action.payload.Username,
          request: false,
          error: false,
          [action.payload.Username]: action.payload,
        };
      } else {
        return {
          ...state,
          request: false,
          error: false,
        };
      }

    case TYPES.REQUEST_USER_DETAILS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };

    default:
      return state;
  }
};

const fetchSrmUser = (uuid, auth) => {
  const {
    access: {token},
    user_portal,
  } = auth;
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
  };
  let endpoint = `${API.auth.user}?user__uuid=${uuid}`;
  if (user_portal === 'business_partner') {
    endpoint = `${EMP_API.administration.employer_user}?user__uuid=${uuid}`;
  }
  try {
    return fetch(endpoint, config)
      .then(response => {
        if (!response.ok) {
          throw new Error('Something went wrong', response);
        }
        return response.json();
      })
      .then(json => {
        if (json && json.detail) {
        } else if (json) {
          return json;
        }
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
  }
};

export const allUsers = async (
  {auth, institutionFaculty: {data} = {}},
  srmid
) => {
  let eventOwner;
  if (data) {
    data &&
      data.filter(user => {
        if (
          user.uuid === srmid ||
          (user &&
            user.user_details &&
            user.user_details.cognito_username === srmid)
        ) {
          eventOwner = user;
        }
      });
  } else {
    eventOwner = await fetchSrmUser(srmid, auth);
  }
  return eventOwner;
};
export const eventOwnerBySrmId = state => srmid => allUsers(state, srmid);

export const userDetails = srm_user__uuid => (dispatch, getState) => {
  const {
    auth: {access: {token = null} = {}, loggedinUserId, user_portal} = {},
  } = getState();
  const srmuuid = srm_user__uuid || loggedinUserId;
  // if (user[srmuuid]) {
  //   return dispatch(receiveUserDetails([user[srmuuid]]));
  // }

  let endpoint = `${API.auth.user}?user__uuid=${srmuuid}`;

  if (user_portal === 'business_partner') {
    endpoint = `${EMP_API.administration.employer_user}?user__uuid=${srmuuid}`;
  }

  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestUserDetail());
  try {
    return fetch(endpoint, config)
      .then(response => response.json())
      .then(json => dispatch(receiveUserDetails(json)));
  } catch (err) {
    dispatch(handleUserError(err));
  }
};
