import {combineReducers} from 'redux';

import reportObjects from './reportObjects';
import reportRunReducer from './reportRun';
import reportsReducer from './reports';
import reportReducer from './report';

export const srmReportsReducers = combineReducers({
  reportObjects,
  reportRunReducer,
  reportsReducer,
  reportReducer,
});
