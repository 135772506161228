import {combineReducers} from 'redux';

import studentManagementReducer from './student';
import studentEventsReducer from './studentEvents';
import studentPathsReducer from './studentPaths';
import studentProfileReducer from './studentProfile';
import studentAssessmentReducer from './studentAssessment';
import studentProfileData from './studentProfileData';
import skills from './skills';
import studentSkills from './studentSkills';
import studentAwardTypesReducer from './/studentAwardTypes';
import studentWorkforce from './studentWorkforce';
import studentSavedPathReducer from './studentSavedPaths';
import studentComparedPathReducer from './studentComparedPaths';
import clusterTypesReducer from './clusters';
import privacySettingsReducer from './privacySettings';
import studentResumeListReducer from './studentResumeList';

export const studentManagementReducers = combineReducers({
  students: studentManagementReducer,
  studentEvents: studentEventsReducer,
  studentPaths: studentPathsReducer,
  studentProfile: studentProfileReducer,
  studentAssessment: studentAssessmentReducer,
  studentProfileData: studentProfileData,
  skills: skills,
  studentSkills: studentSkills,
  studentAwardTypes: studentAwardTypesReducer,
  studentWorkforce: studentWorkforce,
  studentSavedPaths: studentSavedPathReducer,
  studentComparedPaths: studentComparedPathReducer,
  clusterTypes: clusterTypesReducer,
  privacySettings: privacySettingsReducer,
  studentResumeList: studentResumeListReducer,
});
