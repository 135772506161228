import React from 'react';
import {Modal, Button} from 'antd';

const Welcome = ({visible, handleSubmit, userPortal}) => {
  const isReporter = userPortal && userPortal.toLowerCase() === 'reporter';
  const isInstitute = userPortal && userPortal.toLowerCase() === 'institution';
  const isCriminalJustice =
    userPortal && userPortal.toLowerCase() === 'criminal_justice';
  return (
    <Modal
      title={null}
      maskClosable={false}
      closable={false}
      visible={visible}
      footer={[null, null]}
      width={1200}
      className="company-profile-welcome-modal">
      <div className="steps-content">
        <div className="select-path">
          <div className="sp-border-dotted">
            <div className="select-path-type">
              <div className="select-header">
                <span className="icon-start header-icon text-green margin-b10" />
                <h3 className="main-head margin-b10">
                  Welcome GoEducate{' '}
                  <span style={{textTransform: 'capitalize'}}>
                    {isInstitute || isCriminalJustice
                      ? 'Educator'
                      : userPortal.replace('_', ' ')}
                  </span>{' '}
                  Partners!
                </h3>
                <p className="para-small-txt margin-muted">
                  To Put you on the Map, we need to know a little bit about
                  {!isReporter
                    ? ` your ${userPortal.replace('_', ' ')}`
                    : ` you`}
                  .
                </p>
                <p className="para-small-txt">
                  Click on the button below to get started.
                </p>
              </div>
              <Button
                type="primary"
                className="border-rad-100 btn-pad-more-lr"
                onClick={handleSubmit}>
                <span
                  style={{
                    textTransform: 'capitalize',
                  }}>{`Add ${
                  isCriminalJustice ? 'Institution' : userPortal
                } Profile`}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Welcome;
