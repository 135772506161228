import {combineReducers} from 'redux';

import studentsMetrics from './studentsMetrics';
import SWFSurvey from './SWFSurvey';
import programs from './programs';

export const srmStrongWorkforceReducers = combineReducers({
  strongWorkforceMetrics: studentsMetrics,
  strongWorkforceSurvey: SWFSurvey,
  programs: programs,
});
