import React from 'react';
import {Spin, Icon} from 'antd';

const antIcon = <Icon type="loading" spin />;

const Loading = ({showSpinLoader, title}) => {
  if (showSpinLoader) {
    return (
      <div className="initial-loading">
        <span className="small-request-loader">
          <Spin indicator={antIcon} />
          <span className="loader-text">{title || 'Please Wait...'}</span>
        </span>
      </div>
    );
  } else {
    return <div className="initial-loading">Loading...</div>;
  }
};

export default Loading;
