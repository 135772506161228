import React from 'react';
import './NotifierStyle.css';

const Notifier = () => (
  <div className="notifier">
    <p>No internet connection found. App is running in "offline mode". </p>
  </div>
);

export default Notifier;
