import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import * as TYPES from './constants';
import {
  requestClusterTypes,
  receiveClusterTypes,
  handleClusterTypesError,
} from './actions';
const initialState = {
  request: null,
  data: [],
  error: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.STUDENT_CLUSTER_TYPES_LOAD:
      return {
        ...state,
        request: true,
      };
    case TYPES.STUDENT_CLUSTER_TYPES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        request: false,
      };
    case TYPES.STUDENT_CLUSTER_TYPES_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchClusterTypes = () => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestClusterTypes());
  try {
    return fetch(
      `${API.student.cluster_types}?cluster_type=1&path=true`,
      config
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Api Fails');
      })
      .then(json => {
        dispatch(receiveClusterTypes(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleClusterTypesError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleClusterTypesError(err));
  }
};
