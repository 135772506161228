import {
  Box,
  Chip,
  Divider,
  IconButton,
  Stack,
  TextField as MUITextField,
  Tooltip,
  Typography,
  styled,
  alpha,
  InputBase,
  FormHelperText,
} from '@mui/material';
import Button from 'mui-core/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useDispatch, useSelector} from 'react-redux';
import {sendMessageClose} from 'redux/modules/general';
import {getSendMail} from 'redux/modules/general/selectors';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CropFreeRoundedIcon from '@mui/icons-material/CropFreeRounded';
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded';
import React, {useState} from 'react';
import Slide from '@mui/material/Slide';
import {Form, useFormik} from 'formik';
import * as Yup from 'yup';
import {JSON_HEADER, JWT_HEADER, API} from 'config/srm.config';
import {useSnackbar} from 'notistack';
import {useAuth} from 'mui-core/hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TextField = styled(InputBase)(({theme, color}) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& textarea': {
    paddingLeft: '0px !important',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: 'transparent',
    fontSize: 16,
    padding: '10px 12px',
    '&:focus': {
      boxShadow: 'none',
      borderColor: 'transparent',
    },
  },
}));

const userss = [
  {name: 'Vinay', email: 'vinay@gmail.com'},
  {name: 'yadav', email: 'vinay@gmail.com'},
  {name: 'dd', email: 'vinay@gmail.com'},
  {name: 'dd', email: 'vinay@gmail.com'},
  {name: 'dd', email: 'vinay@gmail.com'},
  {name: 'dd', email: 'vinay@gmail.com'},
  {name: 'dd', email: 'vinay@gmail.com'},
  {name: 'dd', email: 'vinay@gmail.com'},
  {name: 'dd', email: 'vinay@gmail.com'},
];

const SendMessage = () => {
  const {token} = useAuth();
  const [fullScreen, setFullScree] = useState(false);
  const [loading, setLoading] = useState(false);
  const mail = useSelector(getSendMail);
  const {recipients} = mail;
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const formik = useFormik({
    initialValues: {
      title: '',
      text: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .min(5, 'Please enter atleast 5 characters')
        .max(70, 'Subject can takem only 2500 characters limit')
        .required('Title is required'),
      text: Yup.string()
        .min(5, 'Please enter atleast 5 characters')
        .max(2500, 'Message can takem only 2500 characters limit')
        .required('Message is required'),
    }),
    onSubmit: values => {
      postData(values);
    },
  });
  const postData = async formData => {
    let requestdata = {
      ...formData,
      recipients: recipients.map(recipient => recipient.user_account),
    };
    setLoading(true);
    try {
      const config = {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(requestdata),
        headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
      };
      const response = await (await fetch(
        API.institution.message_staff,
        config
      )).json();
      if (response.Success || response.success) {
        enqueueSnackbar('Message successfully sent.', {
          variant: 'success',
        });
        close();
        setLoading(false);
      } else {
        throw Error('Something went wrong with api, Try again later');
      }
    } catch (err) {
      if (err) {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
        close();
        setLoading(false);
      }
    }
  };
  const close = () => {
    dispatch(sendMessageClose());
    setTimeout(() => {
      setFullScree(false);
      formik.resetForm();
      formik.submitCount = 0;
    }, 400);
  };

  return (
    <form>
      <Dialog
        open={mail.open}
        maxWidth={'md'}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h6" color={'text.primary'}>
            {'New Message'}
          </Typography>
        </DialogTitle>
        <Tooltip title={fullScreen ? 'Minimize' : 'Maximize'}>
          <IconButton
            aria-label="close"
            onClick={() => setFullScree(!fullScreen)}
            sx={{
              position: 'absolute',
              right: 49,
              top: 8,
              color: theme => theme.palette.grey[600],
            }}>
            {fullScreen ? (
              <CloseFullscreenRoundedIcon />
            ) : (
              <CropFreeRoundedIcon />
            )}
          </IconButton>
        </Tooltip>

        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[600],
          }}>
          <CloseRoundedIcon />
        </IconButton>
        <DialogContent sx={{minWidth: '600px', padding: 0}}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            mx={3}
            spacing={2}
            py={1}>
            <Typography sx={{minWidth: '57px'}}>To:</Typography>
            <Stack direction={'row'} flexWrap={'wrap'}>
              {recipients.map(user => (
                <Chip
                  label={user.email}
                  sx={{marginTop: '5px', marginRight: '5px'}}
                />
              ))}
            </Stack>
          </Stack>
          <Divider />
          <Stack
            direction={'row'}
            alignItems={'center'}
            mx={3}
            spacing={2}
            py={1}>
            <Typography>Subject:</Typography>
            <Box flexGrow={1} position={'relative'}>
              <TextField
                name="title"
                fullWidth
                size="small"
                onChange={formik.handleChange}
                value={formik.values.title}
                placeholder="Enter Subject"
              />
              {formik?.errors?.title && formik.submitCount > 0 && (
                <FormHelperText
                  error
                  sx={{position: 'absolute', bottom: '-9px', left: '13px'}}>
                  {formik?.errors?.title}
                </FormHelperText>
              )}
            </Box>
          </Stack>
          <Divider />
          <Stack
            direction={'row'}
            alignItems={'center'}
            mx={3}
            spacing={2}
            py={1}>
            <Box flexGrow={1} position={'relative'}>
              <TextField
                multiline
                name="text"
                fullWidth
                minRows={7}
                onChange={formik.handleChange}
                value={formik.values.text}
                maxRows={fullScreen ? 30 : 15}
                size="small"
                placeholder="Write something.."
              />
              {formik?.errors?.text && formik.submitCount > 0 && (
                <FormHelperText
                  error
                  sx={{position: 'absolute', bottom: '-9px'}}>
                  {formik?.errors?.text}
                </FormHelperText>
              )}
            </Box>
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions sx={{justifyContent: 'center'}}>
          <Button
            onClick={formik.handleSubmit}
            variant="contained"
            disabled={loading}
            color="primary">
            {loading ? 'Sending' : 'Send'}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
export default SendMessage;
