import * as TYPES from './constants';
import {
  requestPartnerUsers,
  receivePartnerUsers,
  handlePartnersUserError,
} from './actions';

import {JSON_HEADER, JWT_HEADER} from 'config/srm.config';

import {API as SU_API} from 'config/superuser.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_PARTNER_USER:
      return {
        ...state,
        request: true,
        data: [],
      };
    case TYPES.RECEIVE_PARTNER_USERS:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.PARTNER_USER_ERROR:
      return {
        ...state,
        error: true,
        data: [],
        request: false,
      };
    default:
      return state;
  }
};

export const fetchpartnersUsers = (id = null) => (dispatch, getState) => {
  const {
    auth: {access = {}, access: {token} = {}},
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestPartnerUsers());
  try {
    let endPoint = `${SU_API.administration.institution_users}${id}/`;

    //let endPoint = `${API.partners.partner_list}`;
    return fetch(endPoint, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receivePartnerUsers(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handlePartnersUserError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handlePartnersUserError(err));
  }
};
