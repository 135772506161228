import * as TYPES from './constants';
import {API} from 'config/employer.config';
import {getLoggedInstituteUUID} from 'redux/modules/auth';
import {JWT_HEADER, JSON_HEADER} from 'config/srm.config';
import {
  toggleEmployerProfile,
  requestEmployerProfile,
  receiveEmployerProfile,
  handleEmployerProfileError,
} from './actions';
import {Services} from 'core';

const initialState = {
  data: null,
  error: null,
  request: null,
  createEmployerProfile: undefined,
};
const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_EMPLOYER_PROFILE:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_EMPLOYER_PROFILE: {
      const employerData = action?.payload || {};
      const {
        name,
        industry_sector,
        logo_cloudinary,
        overview_contents,
        banner_cloudinary,
        consortium_list = [],
        physical_address_details,
      } = employerData || {};
      const {city, state: empState, zipcode} = physical_address_details || {};
      const isConsortiumListAvailable =
        consortium_list &&
        Array.isArray(consortium_list) &&
        consortium_list?.length > 0;
      let isDescAvailable = false;
      overview_contents?.forEach(item => {
        if (item?.value) {
          isDescAvailable = true;
        }
      });
      return {
        ...state,
        data: employerData,
        createEmployerProfile: !(
          name &&
          city &&
          zipcode &&
          empState &&
          industry_sector &&
          logo_cloudinary &&
          isDescAvailable &&
          banner_cloudinary &&
          isConsortiumListAvailable
        )
          ? true
          : false,
        error: false,
        request: false,
      };
    }
    case TYPES.EMPLOYER_PROFILE_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.TOGGLE_EMPLOYER_PROFILE:
      return {
        ...state,
        createEmployerProfile: action.payload,
      };
    default:
      return state;
  }
};

export const fetchEmployerProfile = companyProfile => (dispatch, getState) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();
  const uuid = getLoggedInstituteUUID(getState());
  const config = {
    mode: 'cors',
    method: 'GET',
    cache: 'default',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
  };
  dispatch(requestEmployerProfile());
  try {
    if (uuid) {
      return fetch(`${API.administration.institution}${uuid}/`, config)
        .then(response => response.json())
        .then(json => {
          dispatch(receiveEmployerProfile(json));
          if (companyProfile) dispatch(toggleEmployerProfile(companyProfile));
          return json;
        })
        .catch(err => {
          console.log(err);
          dispatch(handleEmployerProfileError(err));
          return err;
        });
    }
  } catch (err) {
    console.log(err);
    dispatch(handleEmployerProfileError(err));
    return err;
  }
};

export const updateEmployerProfile = (updatedData, employeerId, callback) => (
  dispatch,
  getState
) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();
  dispatch(requestEmployerProfile());
  try {
    const endpoint = `${API.administration.institution}${employeerId}/`;
    services
      .createUpdateRecord(token, endpoint, updatedData, 'PUT')
      .then(async empRes => {
        if (empRes) {
          await dispatch(receiveEmployerProfile(empRes));
          if (callback) callback(empRes);
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(handleEmployerProfileError(err));
      });
  } catch (err) {
    console.log(err.message);
    dispatch(handleEmployerProfileError(err));
  }
};
