import React from 'react';
import {View, Text} from '@react-pdf/renderer';

import Divider from './Divider';
import List, {Item} from './List';
export default ({styles, voluntary_exp}) => {
  return (
    <>
      <View style={styles.section}>
        <Text style={styles.label}>Volunteer Experience</Text>
        {voluntary_exp &&
        Array.isArray(voluntary_exp) &&
        voluntary_exp.length > 0 ? (
          voluntary_exp.map((exp, idx) => {
            const Responsibilities =
              exp.roles_responsibilities &&
              Array.isArray(exp.roles_responsibilities) &&
              exp.roles_responsibilities.length > 0 &&
              exp.roles_responsibilities.filter(
                role => typeof role.roles_responsibilities === 'string'
              );
            return (
              // <View key={'experience-profile-' + idx} style={styles.inline}>
              <View key={'experience-profile-' + idx}>
                {exp.name && (
                  <Text wrap style={styles.bold}>
                    Experience: <Text style={styles.value}>{exp.name}</Text>
                  </Text>
                )}
                {exp.hours && (
                  <Text wrap style={styles.bold}>
                    Hours: <Text style={styles.value}>{exp.hours}</Text>
                  </Text>
                )}
                {Responsibilities &&
                  Array.isArray(Responsibilities) &&
                  Responsibilities.length > 0 && (
                    <>
                      <Text style={styles.bold}>
                        Roles and Responsibilities:
                      </Text>
                      <List>
                        {Responsibilities.map((role, id) => (
                          <Item key={id}>{role.roles_responsibilities}</Item>
                        ))}
                      </List>
                    </>
                  )}
                {idx + 1 !== voluntary_exp.length && <Divider />}
              </View>
            );
          })
        ) : (
          <Text style={styles.value}>N/A</Text>
        )}
      </View>
    </>
  );
};
