export const getReportObjects = state =>
  state &&
  state.reports &&
  state.reports.reportObjects &&
  state.reports.reportObjects.data;

export const getReportRun = state =>
  state &&
  state.reports &&
  state.reports.reportRunReducer &&
  state.reports.reportRunReducer.data;

export const getReportRunLoading = state =>
  state &&
  state.reports &&
  state.reports.reportRunReducer &&
  state.reports.reportRunReducer.request;

export const getReports = state =>
  state && state.reports && state.reports.reportsReducer;

export const getReport = state =>
  state && state.reports && state.reports.reportReducer;
