import * as TYPES from './constants';

//CareerPaths----
export const requestCareerPaths = () => ({
  type: TYPES.REQUEST_CAREER_PATHS,
});

export const handleCareerPathsError = err => ({
  type: TYPES.REQUEST_CAREER_PATHS_ERROR,
  err,
});

export const receiveCareerPaths = json => ({
  type: TYPES.RECEIVE_CAREER_PATHS,
  payload: json,
});

export const deleteCareerPath = uuid => ({
  type: TYPES.DELETE_CAREER_PATH,
  payload: uuid,
});

export const pathCounts = pathsCounter => ({
  type: TYPES.COUNT_PATHS,
  payload: pathsCounter,
});

//Search Paths:::
export const searchPaths = term => {
  return {
    type: TYPES.SEARCH_CAREER_PATH,
    payload: term,
  };
};

export const clearPaths = () => {
  return {
    type: TYPES.CLEAR_CAREER_PATH,
  };
};

//Filter Paths
export const requestFilterPaths = () => ({
  type: TYPES.REQUEST_FILTER_PATHS,
});

export const handleFilterPathsError = err => ({
  type: TYPES.REQUEST_FILTER_PATHS_ERROR,
  err,
});

export const receiveFilterPaths = json => ({
  type: TYPES.RECEIVE_FILTER_PATHS,
  payload: json,
});

export const removeFilterPaths = () => ({
  type: TYPES.REMOVE_FILTER_PATHS,
});

export const clearFilterPaths = () => (dispatch, getState) => {
  dispatch(removeFilterPaths());
};

export const filterGraphQuaterly = months => ({
  type: TYPES.PATHWAYS_MONTHS_FILTER,
  months,
});

//Search Courses
export const requestSearchCourse = () => ({
  type: TYPES.REQUEST_SEARCH_COURSE,
});

export const handleSearchCourseError = err => ({
  type: TYPES.REQUEST_SEARCH_COURSE_ERROR,
  err,
});

export const receiveSearchCourse = json => {
  if (json.courses && json.courses.length > 0) {
    return {
      type: TYPES.RECEIVE_SEARCH_COURSE,
      payload: json.courses[0],
    };
  } else if (json.courses && json.courses.length <= 0) {
    return {
      type: TYPES.RECEIVE_SEARCH_COURSE,
      payload: {errorMsg: 'Course id not found.'},
    };
  } else {
    return {
      type: TYPES.RECEIVE_SEARCH_COURSE,
      payload: {errorMsg: json},
    };
  }
};

const removeSearchCourse = () => ({
  type: TYPES.REMOVE_SEARCH_COURSE,
});

export const clearSearchCourse = () => (dispatch, getState) => {
  dispatch(removeSearchCourse());
};

// Search Paths
export const requestPathDetails = () => ({
  type: TYPES.REQUEST_PATH_DETAILS,
});

export const handlePathDetailsError = err => ({
  type: TYPES.REQUEST_PATH_DETAILS_ERROR,
  err,
});

export const receivePathDetails = json => ({
  type: TYPES.RECEIVE_PATH_DETAILS,
  payload: json,
});

const removePathDetails = () => ({
  type: TYPES.REMOVE_PATH_DETAILS,
});

export const clearPathDetails = () => (dispatch, getState) => {
  dispatch(removePathDetails());
};

//PathWays :::

export const requestClusterTypes = () => ({
  type: TYPES.REQUEST_CLUSTER_TYPE,
});

export const handleClusterTypesError = err => ({
  type: TYPES.REQUEST_CLUSTER_TYPE_ERROR,
  err,
});

export const receiveClusterTypes = json => ({
  type: TYPES.RECEIVE_CLUSTER_TYPE,
  payload: json,
});
export const requestPathNames = () => ({
  type: TYPES.REQUEST_PATH_TYPE_NAME,
});

export const handlePathNamesError = err => ({
  type: TYPES.REQUEST_PATH_TYPE_NAME_ERROR,
  err,
});

export const receivePathNames = json => ({
  type: TYPES.RECEIVE_PATH_TYPE_NAME,
  payload: json,
});

export const requestClusters = () => ({
  type: TYPES.REQUEST_CLUSTERS,
});

export const handleClustersError = err => ({
  type: TYPES.REQUEST_CLUSTERS_ERROR,
  err,
});

export const receiveClusters = json => ({
  type: TYPES.RECEIVE_CLUSTERS,
  payload: json,
});

export const requestProgramDuration = () => ({
  type: TYPES.REQUEST_PROGRAM_DURATION,
});

export const handleProgramDurationError = err => ({
  type: TYPES.REQUEST_PROGRAM_DURATION_ERROR,
  err,
});

export const receiveProgramDuration = json => ({
  type: TYPES.RECEIVE_PROGRAM_DURATION,
  payload: json,
});

export const requestAchivements = () => ({
  type: TYPES.REQUEST_ACHIVEMENTS,
});

export const handleAchivementsError = err => ({
  type: TYPES.REQUEST_ACHIVEMENTS_ERROR,
  err,
});

export const receiveAchivements = json => ({
  type: TYPES.RECEIVE_ACHIVEMENTS,
  payload: json,
});

export const requestCourseTypes = () => ({
  type: TYPES.REQUEST_COURSE_TYPES,
});

export const handleCourseTypesError = err => ({
  type: TYPES.REQUEST_COURSE_TYPES_ERROR,
  err,
});

export const receiveCourseTypes = json => ({
  type: TYPES.RECEIVE_COURSE_TYPES,
  payload: json,
});

export const requestMilestoneTypes = () => ({
  type: TYPES.REQUEST_MILESTONE_TYPES,
});

export const handleMilestoneTypesError = err => ({
  type: TYPES.REQUEST_MILESTONE_TYPES_ERROR,
  err,
});

export const receiveMilestoneTypes = json => ({
  type: TYPES.RECEIVE_MILESTONE_TYPES,
  payload: json,
});

//Onboarding paths
export const requestOnboardingPaths = () => ({
  type: TYPES.REQUEST_ONBOARDING_PATHS,
});

export const handleOnboardingPathsError = err => ({
  type: TYPES.REQUEST_ONBOARDING_PATHS_ERROR,
  err,
});

export const receiveOnboardingPathsTypes = json => ({
  type: TYPES.RECEIVE_ONBOARDING_PATHS_TYPES,
  payload: json,
});
export const requestOnboardingPathsTypes = () => ({
  type: TYPES.REQUEST_ONBOARDING_PATHS_TYPES,
});

export const handleOnboardingPathsTypesError = err => ({
  type: TYPES.REQUEST_ONBOARDING_PATHS_TYPES_ERROR,
  err,
});

export const requestSaveOnboardingPathsTypes = () => ({
  type: TYPES.REQUEST_SAVE_ONBOARDING_PATHS_TYPES,
});

export const handleSaveOnboardingPathsTypesError = err => ({
  type: TYPES.REQUEST_SAVE_ONBOARDING_PATHS_TYPES_ERROR,
  err,
});

export const receiveOnboardingPaths = json => ({
  type: TYPES.RECEIVE_ONBOARDING_PATHS,
  payload: json,
});

// Save Onboarding Path

export const onSaveOnboardingPaths = json => ({
  type: TYPES.REQUEST_ONSAVE_ONBOARDING_PATHS,
  payload: json,
});

export const requestSaveOnboardingPaths = () => ({
  type: TYPES.REQUEST_SAVE_ONBOARDING_PATHS,
});

export const handleSaveOnboardingPathsError = err => ({
  type: TYPES.REQUEST_SAVE_ONBOARDING_PATHS_ERROR,
});

// serch programs
export const requestPrograms = () => ({
  type: TYPES.REQUEST_PROGRAMS,
});

export const handleProgramsError = err => ({
  type: TYPES.PROGRAMS_ERROR,
  err,
});

export const receivePrograms = json => ({
  type: TYPES.RECEIVE_PROGRAMS,
  payload: json,
});

// GENED PATHW
export const requestGenedPaths = () => ({
  type: TYPES.REQUEST_GENED_PATHS,
});

export const handleGenedPathsError = err => ({
  type: TYPES.GENNED_PATHS_ERROR,
  err,
});

export const receiveGenedPaths = json => ({
  type: TYPES.RECIEVE_GENED_PATHS,
  payload: json,
});

// onaboarding path
export const requestOnboardPaths = () => ({
  type: TYPES.REQUEST_ONBOARD_PATHS,
});

export const handletOnboardPathsError = err => ({
  type: TYPES.ONBOARD_PATHS_ERROR,
  err,
});

export const receiveOnboardPaths = json => ({
  type: TYPES.RECIEVE_ONBOARD_PATHS,
  payload: json,
});

// Overview AI Generator
export const requestAIGeneratedOverviewContent = () => ({
  type: TYPES.REQUEST_AI_GENERATED_OVERVIEW_CONTENT,
});

export const receiveAIGeneratedOverviewContent = json => ({
  type: TYPES.RECIEVE_AI_GENERATED_OVERVIEW_CONTENT,
  payload: json,
});

export const handleAIGeneratedOverviewContentError = err => ({
  type: TYPES.AI_GENERATED_OVERVIEW_CONTENT_ERROR,
  err,
});
