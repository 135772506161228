import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import {Notifier, isNumber} from 'core';

const DynamicTitle = HocComponent =>
  class extends Component {
    state = {};
    // We Have to calculate token expiry & fetch new token to make the Applicaction alive

    // Make the Expiry Time before 5 mins to make the Applicaction alive via fetching new token
    tokenExpiryTimeBeforeFiveMin = ({isAuthticated: {exp = null} = {}}) =>
      exp && 1000 * exp - new Date().getTime() - 300000;

    isAccessTokenExpired = obj => this.tokenExpiryTimeBeforeFiveMin(obj) < 0;

    refreshToken = () => {
      const {fetchNewAuthToken} = this.props;
      //console.log("IS TOKEN EXPIRED::", this.isAccessTokenExpired(this.props));
      if (this.isAccessTokenExpired(this.props)) {
        fetchNewAuthToken(this.removeTokenInterval);
      }
    };

    setTokenInterval = () => {
      const tokenExpiryTime = this.tokenExpiryTimeBeforeFiveMin(this.props);
      if (tokenExpiryTime && isNumber(tokenExpiryTime)) {
        const intervalTime = this.isAccessTokenExpired(this.props)
          ? 60000
          : tokenExpiryTime;
        // console.log('TOKEN EXPIRY TIME:::', intervalTime);
        // console.log(
        //   'IS TOKEN EXPIRED:::',
        //   this.isAccessTokenExpired(this.props)
        // );
        this.tokenExpiry = setInterval(() => this.refreshToken(), intervalTime);
      }
    };

    removeTokenInterval = () => clearInterval(this.tokenExpiry);

    componentDidMount() {
      this.setTokenInterval();
      const {appStatus} = this.props;
      appStatus(navigator.onLine);
      window.addEventListener('online', () =>
        this.setState({isOnline: true}, () => appStatus(true))
      );
      window.addEventListener('offline', () =>
        this.setState({isOnline: false}, () => appStatus(false))
      );
    }

    componentDidUpdate(prevProps, prevState) {
      const {isAuthticated: {exp = null} = {}} = this.props;
      const onLineStatus = navigator.onLine;
      this.props.appStatus(onLineStatus);
      if (
        onLineStatus &&
        (prevProps.isAuthticated && prevProps.isAuthticated.exp) != exp
      ) {
        this.setTokenInterval();
      }
    }

    componentWillUnmount() {
      this.removeTokenInterval();
    }

    modifyPathName = () => {
      const {
        location: {pathname},
        user_portal,
      } = this.props;

      const isUserPortal = user_portal && user_portal.toLowerCase();

      if (pathname.includes('/survey/')) {
        return 'GoEducate Survey';
      }
      const PATH_NAME = pathname
        .replace(/\//g, ' ')
        .toLowerCase()
        .split(' ')
        .map(s => `${s.charAt(0).toUpperCase()}${s.substring(1)}`)
        .join(' ');

      if (
        isUserPortal &&
        (isUserPortal === 'srm' ||
          isUserPortal === 'school' ||
          isUserPortal === 'business_partner' ||
          isUserPortal === 'institution' ||
          isUserPortal === 'reporter' ||
          isUserPortal === 'consortium')
      ) {
        return `Partners ${PATH_NAME}`;
      } else if (isUserPortal && isUserPortal === 'super_user') {
        return `Superuser ${PATH_NAME}`;
      } else {
        return 'Welcome to GoEducate';
      }
    };

    render() {
      const {isOnline} = this.props;
      const PATH_NAME = this.modifyPathName();
      return (
        <>
          <Helmet>
            <title>GoEducate for {PATH_NAME}</title>
            {PATH_NAME === 'GoEducate Survey' && (
              <link
                rel="shortcut icon"
                type="image/png"
                href="/favicon_gps.ico"
              />
            )}
          </Helmet>
          <HocComponent {...this.props} />
          {!isOnline && <Notifier />}
        </>
      );
    }
  };

export default DynamicTitle;
