import * as TYPES from './constants';
import {
  requestDashboardData,
  receiveDashboardData,
  handleDashboardDataError,
} from './actions';

import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {
  data: {},
  request: false,
  error: false,
  // programListFilter: 'all',
  // showPofileUploadSpinner: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_DASHBOARD_DATA:
      return {
        ...state,
        request: true,
      };
    // case TYPES.REQUEST_UPLOAD_PROFILE_DATA:
    //   return {
    //     ...state,
    //     showPofileUploadSpinner: true,
    //   };
    // case TYPES.UPLOAD_PROFILE_DATA_SUCCESS_ERROR:
    //   return {
    //     ...state,
    //     showPofileUploadSpinner: false,
    //   };
    case TYPES.RECEIVE_DASHBOARD_DATA:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    // case TYPES.DASHBOARD_PROGRAM_LIST_FILTER:
    //   return {
    //     ...state,
    //     programListFilter: action.programListFilter,
    //   };
    // case TYPES.DASHBOARD_PROGRAM_ENROLLMENT_FILTER:
    //   return {
    //     ...state,
    //     programEnrollmentFilter: action.programEnrollmentFilter,
    //   };
    case TYPES.DASHBOARD_DATA_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchDashboardGridData = reqParams => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const {
    page = 1,
    pageSize = 10,
    status = 'all',
    sortBy = '',
    sortField = '',
    programList = [],
  } = reqParams || {};
  const reqBody = {
    page: page + 1,
    status: status,
    page_size: pageSize,
    program_uuid_list: programList,
  };
  if (sortBy) reqBody['sort_by'] = sortBy;
  if (sortField) reqBody['sort_field'] = sortField;

  const config = {
    mode: 'cors',
    method: 'POST',
    body: JSON.stringify(reqBody),
    cache: 'default',
    headers: token
      ? Object.assign({}, JWT_HEADER(token), JSON_HEADER)
      : Object.assign({}, JSON_HEADER),
  };
  dispatch(requestDashboardData());
  try {
    return fetch(`${API.institution.dashboard}`, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveDashboardData(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleDashboardDataError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleDashboardDataError(err));
  }
};
