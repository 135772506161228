import * as TYPES from './constants';
import {
  requestSuperUserIstitutions,
  receiveSuperUserIstitutions,
  handleSuperUserIstitutionsError,
} from './actions';

import {Services} from 'core';

import {API} from 'config/superuser.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_SUPER_USER_INSTITUTIONS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_SUPER_USER_INSTITUTIONS:
      return {
        ...state,
        data: action.payload || null,
        request: false,
        error: false,
      };
    case TYPES.SUPER_USER_INSTITUTIONS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchSuperUserIstitutions = (page, pageSize, filters) => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();

  let updatedFilters = [];
  if (filters?.length) {
    updatedFilters = filters.map(newItem => {
      if (newItem.value === 'ROP') {
        newItem.value = 'Consortium';
        return newItem;
      }
      return newItem;
    });
  }
  let body = {
    page: page,
    page_size: pageSize,
    filters: updatedFilters,
  };
  dispatch(requestSuperUserIstitutions());
  const endpoint = API.administration.institution;
  // const filtersQuery = filterBy ? 'filter=' + filterBy : '';
  // const searchQuery = search ? '&search=' + search.toLowerCase() : '';
  // const paginationQuery = `&page_size=${pageSize}&page=${page || 1}`;
  // let endpoint = `${
  //   API.administration.institution
  // }?${filtersQuery}${searchQuery}${paginationQuery}`;
  try {
    return services
      .createUpdateRecord(token, endpoint, body, 'POST')
      .then(res => {
        dispatch(receiveSuperUserIstitutions(res));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleSuperUserIstitutionsError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleSuperUserIstitutionsError(err));
  }
};
