import * as TYPES from './constants';
import {requestStates, receiveStates, handleStatesError} from './actions';

import {API} from 'config/srm.config';

import {Services} from 'core';

const services = new Services();
const initialState = {
  data: null,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_STATES:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_STATES:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.STATES_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchStates = () => (dispatch, getState) => {
  const {
    auth: {access: {token = null} = {}},
  } = getState();

  dispatch(requestStates());
  try {
    return services
      .fetchRecords(`${API.auth.state}`, token)
      .then(json => {
        dispatch(receiveStates(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleStatesError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleStatesError(err));
  }
};
