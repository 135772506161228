import * as TYPES from './constants';
import {Services} from 'core';
import {
  requestBusinessPartnerStudentProfile,
  receiveBusinessPartnerStudentProfile,
  handleBusinessPartnerStudentProfileError,
} from './actions';

import {JWT_HEADER, JSON_HEADER} from 'config/srm.config';
import {API} from 'config/businesspartner.config';

const services = new Services();

const initialState = {
  data: null,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_BUSINESS_PARTNER_STUDENT_PROFILE:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_BUSINESS_PARTNER_STUDENT_PROFILE:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.BUSINESS_PARTNER_STUDENT_PROFILE_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.CLEAR_BUSINESS_PARTNER_STUDENT_PROFILE:
      return {
        ...state,
        data: null,
        error: false,
        request: false,
      };
    default:
      return state;
  }
};

const fetchStudentActivePath = (token, studentID) => {
  const URI = `${
    API.administration.student_pathDetails
  }?student_uuid=${studentID}`;
  const bodyData = {
    method: 'retrieve',
  };
  return services.createUpdateRecord(token, URI, bodyData, 'POST');
};

export const fetchBusinessPartnerStudentProfile = studentID => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();

  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };

  dispatch(requestBusinessPartnerStudentProfile());
  const URI = `${API.administration.student_profile}${studentID}`;

  try {
    return fetch(URI, config)
      .then(response => response.json())
      .then(student_profile => {
        return fetchStudentActivePath(token, studentID).then(pathDetails => {
          if (pathDetails.status == 'Success' && pathDetails.Data) {
            return {
              activePathDetails: pathDetails.Data,
              student_profile,
            };
          }
        });
      })
      .then(json => {
        dispatch(receiveBusinessPartnerStudentProfile(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleBusinessPartnerStudentProfileError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleBusinessPartnerStudentProfileError(err));
  }
};
