import {createTheme} from '@mui/material/styles';

const AccordionTheme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          margin: '20px 0px',
          border: `1px solid #dcdcdc`,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '16px 32px',
          backgroundColor: '#F2F4FA',
        },
        content: {
          margin: '0px',
          fontSize: '18px',
          fontWeight: '600',
          textTransform: 'uppercase',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0px 32px',
        },
      },
    },
  },
});

export {AccordionTheme};
