import React, {Component} from 'react';
import {Table, Icon, Tooltip, Typography, Button} from 'antd';
import {FilterHeader, containsObject, isObject, isString} from 'core';

const loadingIcon = <Icon type="loading" style={{fontSize: 24}} spin />;

export default class DynamicTable extends Component {
  state = {
    selectedUsers: {},
    //xScroll: 550,
    scroll: false,
    tableColumns: [],
  };

  getIndicatorClassName = val => {
    if (val === 'completed' || val === 't' || val === 'y' || val === 'true') {
      return 'completed';
    } else if (val === 'yettostart') {
      return 'yettostart';
    } else if (val === 'progress') {
      return 'progress';
    } else if (
      val === 'dropped' ||
      val === 'n' ||
      val === 'f' ||
      val === 'false'
    ) {
      return 'dropped';
    }
  };

  // function to create table banner
  getStudentSelectionBanner = (
    {enableSelectAllRecords, onSelectAllButtonClick, title},
    total,
    size
  ) => {
    const text = enableSelectAllRecords
      ? `All ${total || ''} ${title} are selected.`
      : `${size || ''} ${title} on this page are selected.`;

    const btnText = enableSelectAllRecords
      ? `Select only ${size} ${title} on this page`
      : `Select all ${total || ''} ${title}`;

    return (
      <div className="all-student-selection">
        <Typography.Paragraph>{text}&nbsp;&nbsp;</Typography.Paragraph>
        <Button type={'link'} shape="round" onClick={onSelectAllButtonClick}>
          {btnText}
        </Button>
      </div>
    );
  };

  prepareTableColumns = () => {
    const {
      data,
      columns,
      avoidKeysCheck,
      showStatus,
      onlyShowStatus,
      combineColumns,
      specialCase,
    } = this.props;
    if (data) {
      const customColumns = [];
      data.map(({header}) => {
        header &&
          header.map(thead => {
            if (
              isObject(thead) &&
              containsObject('key', thead['key'], customColumns) &&
              !avoidKeysCheck.includes(thead)
            ) {
              customColumns.push(thead);
            }
            if (
              isString(thead) &&
              !customColumns.includes(thead) &&
              !avoidKeysCheck.includes(thead)
            ) {
              customColumns.push(thead);
            }
          });
      });

      if (customColumns && customColumns.length) {
        const leftFixedColumns = columns.filter(({fixed}) => fixed === 'left');
        const rightFixedColumns = columns.filter(
          ({fixed}) => fixed === 'right'
        );

        const modifyCustomColumns =
          customColumns &&
          customColumns.map((columnName, idx) => {
            let title = columnName.replace('_', ' ');
            if (title.includes(':id-')) {
              title = title.slice(0, title.indexOf(':id-'));
            }
            const shortTitle = `${
              title.length > 14 ? title.slice(0, 13) + '...' : title
            }`;

            return {
              title: (
                <Tooltip title={`${title.length > 14 ? title : ''}`}>
                  {shortTitle}
                </Tooltip>
              ),
              dataIndex: columnName,
              key: `${columnName}-${idx}`,
              className: 'courses-list',
              //width: 100,
              render: value => {
                // convert toString if value typeof boolean
                let lowerCaseValue = value && value.toString().toLowerCase();
                const indicatorClassName = this.getIndicatorClassName(
                  lowerCaseValue
                );
                if (
                  showStatus ||
                  (onlyShowStatus && onlyShowStatus.includes(columnName))
                ) {
                  if (specialCase === 'status_count') {
                    lowerCaseValue = parseInt(lowerCaseValue);
                    if (lowerCaseValue == 0 || lowerCaseValue == 1) {
                      return (
                        <span className="col-indicator">
                          <span className="inprogress-status-circle" />
                        </span>
                      );
                    } else if (lowerCaseValue >= 2) {
                      return (
                        <span className="col-indicator">
                          <Icon type="check-circle" />
                        </span>
                      );
                    } else {
                      return (
                        <span className="col-indicator">
                          <span className="nothing-status-circle" />
                        </span>
                      );
                    }
                  }
                  return (
                    <span className={'col-indicator ' + indicatorClassName}>
                      {(lowerCaseValue === 'completed' ||
                        lowerCaseValue === 'true' ||
                        lowerCaseValue === 't' ||
                        lowerCaseValue === 'y') && <Icon type="check-circle" />}
                      {(lowerCaseValue === 'dropped' ||
                        lowerCaseValue === 'n' ||
                        lowerCaseValue === 'false' ||
                        lowerCaseValue === 'f') && <Icon type="minus-circle" />}
                    </span>
                  );
                } else {
                  return value;
                }
              },
            };
          });

        if (modifyCustomColumns && modifyCustomColumns.length) {
          const xScrollUpdate =
            leftFixedColumns.length * 50 +
            modifyCustomColumns.length * 100 +
            550;

          this.setState({
            xScroll: xScrollUpdate,
            scroll: true,
            tableColumns: [
              ...leftFixedColumns,
              ...modifyCustomColumns,
              ...rightFixedColumns,
            ],
          });
        }
      } else {
        const updatedColumns =
          columns &&
          columns.map(Obj => {
            const {title, dataIndex, key, align, render, className} = Obj;
            return {
              title,
              dataIndex,
              key,
              align,
              render,
              className,
            };
          });
        this.setState({
          tableColumns: updatedColumns,
        });
      }
    }
  };

  componentDidMount() {
    this.prepareTableColumns();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.prepareTableColumns();
    }
  }

  render() {
    const {tableColumns, scroll, xScroll} = this.state;
    const {
      searchPlaceHolder,
      printHeading,
      data,
      onSearch,
      onFilter,
      filterOptions,
      noDefaultFilterOption,
      defaultOptionValue,
      emailSendHandler,
      onRowSelection,
      onSelectAll,
      selectedRows = {},
      itemsPerPage,
      selectedRowKeys = [],
      className = '',
      normal,
      headerChildren,
      loading,
      pagination,
      hideFilter,
      hideSelection,
      noHeaderCount,
      filterLabel,
      serverSidePageChange,
      total,
      selectedFilter,
      noData,
      currentPage,
      pageSize,
      selectionBannerData,
    } = this.props;

    const count = (selectedRows && Object.keys(selectedRows).length) || 0;
    const rowSelection = {
      selectedRows: selectedRows,
      selectedRowKeys: selectedRowKeys,
      onChange: onRowSelection,
      hideDefaultSelections: true,
      selections: onSelectAll
        ? [
            {
              key: 'all-data',
              text: 'Select All',
              onSelect: onSelectAll,
            },
            {
              key: 'all-data',
              text: 'Unselect All',
              onSelect: () => onSelectAll('deSelectAll'),
            },
          ]
        : [],
    };
    return (
      <>
        <div className={`studentList ${className}`}>
          {!hideFilter && (
            <div className="search-filter-row sf-row-pad">
              {!hideSelection && !noHeaderCount && (
                <div className="text-muted">
                  <p className="print-head float-left margin-r20">
                    {printHeading}
                  </p>
                  <span> {count} selected</span>
                </div>
              )}
              <FilterHeader
                filterLabel={filterLabel}
                normal={normal}
                placeholder={searchPlaceHolder}
                count={count}
                noDefaultFilterOption={noDefaultFilterOption}
                defaultOptionValue={defaultOptionValue}
                filterOptions={filterOptions}
                onFilter={onFilter}
                emailSendHandler={emailSendHandler}
                data={data}
                onSearch={onSearch}
                headerChildren={headerChildren}
                hideSelection={hideSelection}
                selectedFilter={selectedFilter}
              />
            </div>
          )}
          {selectionBannerData &&
            this.getStudentSelectionBanner(
              selectionBannerData,
              total,
              pageSize
            )}
          <Table
            loading={{
              indicator: loadingIcon,
              tip: 'Loading...',
              spinning: loading ? loading : false,
            }}
            pagination={
              pagination
                ? serverSidePageChange && !isNaN(total)
                  ? {
                      total,
                      defaultCurrent: 1,
                      showSizeChanger: false,
                      defaultPageSize: 10,
                      pageSize: itemsPerPage,
                      onChange: serverSidePageChange,
                      ...(currentPage && {current: currentPage}),
                    }
                  : {
                      defaultCurrent: 1,
                      showSizeChanger: false,
                      defaultPageSize: 10,
                      pageSize: itemsPerPage,
                    }
                : false
            }
            rowSelection={hideSelection ? null : rowSelection}
            columns={tableColumns}
            dataSource={data}
            scroll={scroll ? {x: xScroll, y: 400} : {}}
            locale={noData}
            // rowKey={record => {
            //   if (!record.key) {
            //     return record.uuid || record.student_uuid;
            //   }
            // }}
          />
        </div>
      </>
    );
  }
}
