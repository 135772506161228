import React from 'react';
import {Button} from 'antd';

import {Search, Filter} from 'core';

const FilterHeader = ({
  normal,
  count,
  filterOptions,
  noDefaultFilterOption,
  defaultOptionValue,
  emailSendHandler,
  onFilter,
  onSearch,
  selectedFilter,
  filterLabel,
  placeholder,
  headerChildren,
  headerBeforeChildren,
  overlayClassName,
  customClassName,
}) => (
  <div className="search-filter-row">
    {headerBeforeChildren && headerBeforeChildren()}
    <div className={`search-filter-row ${customClassName}`}>
      <Search
        placeholder={placeholder}
        classname="search-field"
        onSearch={onSearch}
        selectedFilter={selectedFilter}
      />
      <Filter
        normal={normal}
        className="filter-field"
        noDefaultFilterOption={noDefaultFilterOption}
        defaultOptionValue={defaultOptionValue}
        filterOptions={filterOptions}
        onFilter={onFilter}
        filterLabel={filterLabel}
        overlayClassName={overlayClassName}
      />
      {emailSendHandler && (
        <Button
          type="primary"
          shape="round"
          disabled={count < 0 || !count ? true : false}
          onClick={emailSendHandler}>
          SEND MESSAGE
        </Button>
      )}

      {headerChildren && headerChildren()}
    </div>
  </div>
);

export default FilterHeader;
