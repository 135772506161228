import React, {useRef} from 'react';
import {Radar} from 'react-chartjs-2';
import './style.css';

const RadarChart = props => {
  const {data, options} = props;
  const chartRef = useRef(null);

  return (
    <div className="radarChart">
      <Radar data={data} ref={chartRef} options={options} />
    </div>
  );
};

export default RadarChart;
