//ref:docs:for more configuration:  https://react-slick.neostack.com/docs/get-started
import React, {Fragment} from 'react';
import {Carousel, Icon} from 'antd';

import './Carousel.css';

const Next = ({onClick, className}) => (
  <Icon
    type="right-circle"
    onClick={onClick}
    className={className.includes('slick-disabled') ? 'disabled' : ''}
  />
);

const Prev = ({onClick, className}) => (
  <Icon
    type="left-circle"
    onClick={onClick}
    className={className.includes('slick-disabled') ? 'disabled' : ''}
  />
);

const settings = {
  autoplay: false,
  arrows: true,
  dots: false,
  infinite: false,
  lazyLoad: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <Next />,
  prevArrow: <Prev />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const CarouselSlider = ({data = [], config = {}, className = ''}) => {
  if (config && config.slidesToShow > data.length) {
    config.slidesToShow = data.length;
  }

  const sliderConfig = Object.assign({}, settings, config);

  if (sliderConfig && sliderConfig.slidesToShow <= 0) {
    return <></>;
  }

  return (
    data &&
    data.length && (
      <Carousel className={`gopathways_slider ${className}`} {...sliderConfig}>
        {data.map((slide, idx) => (
          <Fragment key={`slide-${idx}`}>{slide}</Fragment>
        ))}
      </Carousel>
    )
  );
};

export default CarouselSlider;

{
  /* Try with following example. */
}
{
  /* <Carousel
  data={[
    <div>1</div>,
    <div>2</div>,
    <div>3</div>,
    <div>4</div>,
    <div>5</div>,
  ]}
  config={{ slidesToShow: 3 }}
/> */
}
