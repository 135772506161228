import React, {Component} from 'react';

const PanelHOC = ChildComponent =>
  class extends Component {
    state = {};

    collapsePanel = key => {
      this.setState({
        activeKey: key ? key : 'no-key',
      });
    };

    render() {
      return (
        <ChildComponent
          switchPanel={this.collapsePanel}
          activeKey={this.state.activeKey}
          {...this.props}
        />
      );
    }
  };

export default PanelHOC;
