import React, {useEffect, useState} from 'react';
import {Col, Row, Form, Icon, Select, Tag, Spin} from 'antd';
import {debounce} from 'lodash';
import {API} from 'config/businesspartner.config';
import {Services} from '../../services';
import './style.css';

const services = new Services();
const defaultMinimumCharacter = 2;

const data = [
  {label: 'akash'},
  {label: 'acchu'},
  {label: 'akrhu'},
  {label: 'avinash'},
  {label: 'akara'},
  {label: 'akarka'},
  {label: 'adarhsa'},
];

const endpoint = `${API.administration.skills}`;

const FormItem = Form.Item;

const SkillsSelector = props => {
  const {onChange, minimumCharacter, token, value = []} = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    if (value && Array.isArray(value));
    setSkills(value);
  }, []);

  const isSkillselected = label => {
    const exists = skills.filter(d => d.name === label)[0];
    return exists ? true : false;
  };
  const onSkillsRemove = label => {
    const removeSkill = skills.filter(loc => loc.name !== label);
    setSkills(removeSkill);
    onSkillsChange(removeSkill);
  };

  const onSelect = v => {
    const skillsFiltered = data.filter(i => i.name === v)[0];
    if (skillsFiltered) {
      if (!isSkillselected(v)) {
        const newSkillsSelect = [...skills, skillsFiltered];
        setSkills(newSkillsSelect);
        onSkillsChange(newSkillsSelect);
      } else {
        onSkillsRemove(v);
      }
    }
  };

  const onSearch = debounce(term => {
    if (term && term.length >= (minimumCharacter || defaultMinimumCharacter)) {
      setLoading(true);
      services.fetchRecords(`${endpoint}?search=${term}`, token).then(res => {
        setLoading(false);
        const result = res && res;
        if (result && Array.isArray(result)) {
          const data = result;

          setData(data);
        }
      });
    }
  }, 1300);

  const onSkillsChange = newSkills => {
    if (onChange) onChange(newSkills || skills);
  };

  const options =
    data &&
    Array.isArray(data) &&
    data.map(skillDetail => {
      const isSelected = isSkillselected(
        skillDetail.institution_id
          ? skillDetail.institution_id
          : skillDetail.name
      );

      return (
        <Select.Option
          key={
            skillDetail.institution_id
              ? skillDetail.institution_id
              : skillDetail.name
          }
          value={
            skillDetail.institution_id
              ? skillDetail.institution_id
              : skillDetail.name
          }
          className={
            'option-selected-check' + (isSelected ? ' selected ' : '')
          }>
          {skillDetail.name}
          {isSelected && <Icon type="check" />}
        </Select.Option>
      );
    });

  return (
    <div className="skills-search filter-section">
      <div className="heading-text text-center">Filter by Skills</div>
      <Row type="flex" gutter={42} align="middle">
        <Col sm={20} className="searchOccupation" style={{margin: '0 auto'}}>
          <FormItem label="Search by Skills">
            <>
              <Icon
                type="search"
                style={{
                  position: 'absolute',
                  zIndex: '1',
                  top: '4px',
                  left: '5px',
                }}
              />
              <Select
                showSearch
                loading={loading}
                showArrow={false}
                filterOption={false}
                defaultActiveFirstOption={false}
                allowClear={!loading}
                value={null}
                onSelect={onSelect}
                onSearch={onSearch}
                placeholder="Search"
                notFoundContent={
                  loading ? (
                    <Spin size="small" />
                  ) : (
                    'no skill match for search query'
                  )
                }
                style={{paddingLeft: '11px'}}>
                {options}
              </Select>
            </>
          </FormItem>
        </Col>
      </Row>

      <div className="program-study-selector filter-section">
        <div className="selected-section">
          <div className="lbl-s">Selected Skills(s):</div>
          <div className="tags-s">
            {(skills &&
              Array.isArray(skills) &&
              skills.length > 0 &&
              skills.map((skillSelect, index) => (
                <Tag
                  key={
                    'pgm-tag' +
                    (skillSelect.institution_id
                      ? skillSelect.institution_id
                      : skillSelect.name)
                  }
                  onClose={() =>
                    onSkillsRemove(
                      skillSelect.institution_id
                        ? skillSelect.institution_id
                        : skillSelect.name
                    )
                  }
                  closable
                  className="occ-tag skills-tag">
                  {skillSelect.name}
                </Tag>
              ))) || (
              <span
                style={{
                  position: 'relative',
                  top: '3px',
                }}>
                -
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillsSelector;
