import {combineReducers} from 'redux';

import institutionTypes from './../../superUser/modules/administration/institution-type';
import instituteDashboard from './institution_metrics';
import reporterUsers from './institution-users';
import profile from './profile';
import descendentInstitution from './institute-descendent';
import loggedInInstitute from './loggedin_institute';
import instituteDetails from './institute_details';
import {consortiumInstitutions} from './consortiumInstitutions';
import consortiums from './consortiums';

export const reporterAppReducer = combineReducers({
  loggedInInstitute,
  instituteDashboard,
  institutionTypes,
  reporterUsers,
  profile,
  descendentInstitution,
  instituteDetails,
  consortiumInstitutions,
  consortiums,
});
