import * as TYPES from './constants';
import {
  requestClusterTypes,
  receiveClusterTypes,
  handleClusterTypesError,
  requestPathNames,
  receivePathNames,
  handlePathNamesError,
  requestClusters,
  receiveClusters,
  handleClustersError,
  requestProgramDuration,
  receiveProgramDuration,
  handleProgramDurationError,
  requestAchivements,
  receiveAchivements,
  handleAchivementsError,
  requestCourseTypes,
  receiveCourseTypes,
  handleCourseTypesError,
  requestMilestoneTypes,
  receiveMilestoneTypes,
  handleMilestoneTypesError,
  requestOnboardingPathsTypes,
  receiveOnboardingPathsTypes,
  handleOnboardingPathsTypesError,
  requestSaveOnboardingPathsTypes,
  handleSaveOnboardingPathsTypesError,
  onSaveOnboardingPaths,
  requestSaveOnboardingPaths,
  handleSaveOnboardingPathsError,
} from './actions';

import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_CLUSTER_TYPE:
    case TYPES.REQUEST_PATH_TYPE_NAME:
    case TYPES.REQUEST_CLUSTERS:
    case TYPES.REQUEST_PROGRAM_DURATION:
    case TYPES.REQUEST_ACHIVEMENTS:
    case TYPES.REQUEST_COURSE_TYPES:
    case TYPES.REQUEST_MILESTONE_TYPES:
    case TYPES.REQUEST_ONBOARDING_PATHS:
    case TYPES.REQUEST_ONBOARDING_PATHS_TYPES:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_CLUSTER_TYPE:
      return {
        ...state,
        clusterTypes: action.payload,
        request: false,
      };
    case TYPES.RECEIVE_PATH_TYPE_NAME:
      return {
        ...state,
        pathNames: action.payload,
        request: false,
      };
    case TYPES.RECEIVE_CLUSTERS:
      return {
        ...state,
        clusters: action.payload,
        request: false,
      };
    case TYPES.RECEIVE_PROGRAM_DURATION:
      return {
        ...state,
        programDuration: action.payload,
        request: false,
      };
    case TYPES.RECEIVE_ACHIVEMENTS:
      return {
        ...state,
        achivements: action.payload,
        request: false,
      };
    case TYPES.RECEIVE_COURSE_TYPES:
      return {
        ...state,
        courseTypes: action.payload,
        request: false,
      };
    case TYPES.RECEIVE_MILESTONE_TYPES:
      return {
        ...state,
        milestoneTypes: action.payload,
        request: false,
      };
    case TYPES.REQUEST_CLUSTER_TYPE_ERROR:
    case TYPES.REQUEST_PATH_TYPE_NAME_ERROR:
    case TYPES.REQUEST_CLUSTERS_ERROR:
    case TYPES.REQUEST_PROGRAM_DURATION_ERROR:
    case TYPES.REQUEST_ACHIVEMENTS_ERROR:
    case TYPES.REQUEST_COURSE_TYPES_ERROR:
    case TYPES.REQUEST_MILESTONE_TYPES_ERROR:
    case TYPES.REQUEST_ONBOARDING_PATHS_ERROR:
    case TYPES.REQUEST_ONBOARDING_PATHS_TYPES_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };

    case TYPES.RECEIVE_ONBOARDING_PATHS:
      return {
        ...state,
        onboarding_paths: action.payload,
        request: false,
      };
    case TYPES.RECEIVE_ONBOARDING_PATHS_TYPES:
      return {
        ...state,
        onboarding_paths_types: action.payload,
        request: false,
      };
    case TYPES.REQUEST_ONSAVE_ONBOARDING_PATHS:
      return {
        ...state,
        onboarding_path: action.payload,
        request: false,
      };
    case TYPES.REQUEST_SAVE_ONBOARDING_PATHS:
      return {
        ...state,
        request: true,
      };
    case TYPES.REQUEST_SAVE_ONBOARDING_PATHS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchclusterTypes = () => (dispatch, getState) => {
  const state = getState();
  let accessToken = null;
  if (state && state.auth && state.auth.access) {
    const {
      auth: {
        access: {token},
      },
    } = state;
    accessToken = token;
  }

  if (accessToken) {
    const config = {
      method: 'GET',
      mode: 'cors',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
      cache: 'default',
    };
    dispatch(requestClusterTypes());
    try {
      return fetch(`${API.pathway.cluster_type}`, config)
        .then(response => response.json())
        .then(json => {
          dispatch(receiveClusterTypes(json));
        })
        .catch(err => {
          console.log(err);
          dispatch(handleClusterTypesError(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(handleClusterTypesError(err));
    }
  }
};

export const fetchpathNames = () => (dispatch, getState) => {
  const state = getState();
  let accessToken = null;
  if (state && state.auth && state.auth.access) {
    const {
      auth: {
        access: {token},
      },
    } = state;
    accessToken = token;
  }

  if (accessToken) {
    const config = {
      method: 'GET',
      mode: 'cors',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
      cache: 'default',
    };
    dispatch(requestPathNames());
    try {
      return fetch(`${API.pathway.path_name}`, config)
        .then(response => response.json())
        .then(json => {
          dispatch(receivePathNames(json));
        })
        .catch(err => {
          console.log(err);
          dispatch(handlePathNamesError(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(handlePathNamesError(err));
    }
  }
};

export const fetchclusters = () => (dispatch, getState) => {
  const state = getState();
  let accessToken = null;
  if (state && state.auth && state.auth.access) {
    const {
      auth: {
        access: {token},
      },
    } = state;
    accessToken = token;
  }

  if (accessToken) {
    const config = {
      method: 'GET',
      mode: 'cors',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
      cache: 'default',
    };
    dispatch(requestClusters());
    try {
      return fetch(`${API.pathway.cluster}`, config)
        .then(response => response.json())
        .then(json => {
          const updatedCluster =
            json &&
            json.map(cluster => {
              let obj = {};
              obj.icon = cluster.cluster_banner_url;
              obj.thumbnail = cluster.cluster_card_url;
              obj.cluster_type = cluster.cluster_type;
              obj.name = cluster.cluster_name;
              obj.uuid = cluster.cluster_uuid;
              if (cluster.program) {
                obj.programs = cluster.program.map(program => {
                  let pObj = {};
                  pObj.cluster_id = cluster.cluster_uuid;
                  pObj.cluster_name = cluster.cluster_name;
                  pObj.uuid = program.program_uuid;
                  pObj.name = program.program_name;
                  return pObj;
                });
              }
              return obj;
            });
          dispatch(receiveClusters(updatedCluster));
        })
        .catch(err => {
          console.log(err);
          dispatch(handleClustersError(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(handleClustersError(err));
    }
  }
};

export const fetchProgramDuration = () => (dispatch, getState) => {
  const state = getState();
  let accessToken = null;
  if (state && state.auth) {
    const {
      auth: {
        access: {token},
      },
    } = state;
    accessToken = token;
  }

  if (accessToken) {
    const config = {
      method: 'GET',
      mode: 'cors',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
      cache: 'default',
    };
    dispatch(requestProgramDuration());
    try {
      return fetch(`${API.pathway.program_duration}`, config)
        .then(response => response.json())
        .then(json => {
          dispatch(receiveProgramDuration(json));
        })
        .catch(err => {
          console.log(err);
          dispatch(handleProgramDurationError(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(handleProgramDurationError(err));
    }
  }
};

export const fetchAchivements = () => (dispatch, getState) => {
  const state = getState();
  let accessToken = null;
  if (state && state.auth) {
    const {
      auth: {access: {token} = {}},
    } = state;
    accessToken = token;
  }

  if (accessToken) {
    const config = {
      method: 'GET',
      mode: 'cors',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
      cache: 'default',
    };
    dispatch(requestAchivements());
    try {
      return fetch(`${API.pathway.acheivement_options}`, config)
        .then(response => response.json())
        .then(json => {
          dispatch(receiveAchivements(json));
        })
        .catch(err => {
          console.log(err);
          dispatch(handleAchivementsError(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(handleAchivementsError(err));
    }
  }
};

export const fetchCourseTypes = () => (dispatch, getState) => {
  const state = getState();
  let accessToken = null;
  if (state && state.auth) {
    const {
      auth: {
        access: {token},
      },
    } = state;
    accessToken = token;
  }

  if (accessToken) {
    const config = {
      method: 'GET',
      mode: 'cors',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
      cache: 'default',
    };
    dispatch(requestCourseTypes());
    try {
      return fetch(`${API.pathway.course_type}`, config)
        .then(response => response.json())
        .then(json => {
          dispatch(receiveCourseTypes(json));
        })
        .catch(err => {
          console.log(err);
          dispatch(handleCourseTypesError(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(handleCourseTypesError(err));
    }
  }
};

export const fetchMilestoneTypes = () => (dispatch, getState) => {
  const state = getState();
  let accessToken = null;
  if (state && state.auth) {
    const {
      auth: {
        access: {token},
      },
    } = state;
    accessToken = token;
  }

  if (accessToken) {
    const config = {
      method: 'GET',
      mode: 'cors',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
      cache: 'default',
    };
    dispatch(requestMilestoneTypes());
    try {
      return fetch(`${API.pathway.milestone_type}`, config)
        .then(response => response.json())
        .then(json => {
          dispatch(receiveMilestoneTypes(json));
        })
        .catch(err => {
          console.log(err);
          dispatch(handleMilestoneTypesError(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(handleMilestoneTypesError(err));
    }
  }
};

export const fetchOnboardingPathsTypes = () => (dispatch, getState) => {
  const state = getState();
  let accessToken = null;
  if (state && state.auth) {
    const {
      auth: {access: {token} = {}},
    } = state;
    accessToken = token;
  }

  if (accessToken) {
    const config = {
      method: 'GET',
      mode: 'cors',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
      cache: 'default',
    };
    dispatch(requestOnboardingPathsTypes());
    try {
      return fetch(`${API.pathway.onboarding_type}`, config)
        .then(response => response.json())
        .then(json => {
          dispatch(receiveOnboardingPathsTypes(json));
        })
        .catch(err => {
          console.log(err);
          dispatch(handleOnboardingPathsTypesError(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(handleOnboardingPathsTypesError(err));
    }
  }
};
export const fetchSaveOnboardingPathsTypes = data => (dispatch, getState) => {
  const state = getState();
  let accessToken = null;
  if (state && state.auth) {
    const {
      auth: {access: {token} = {}},
    } = state;
    accessToken = token;
  }

  if (accessToken) {
    const config = {
      method: 'POST',
      mode: 'cors',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
      cache: 'default',
      body: JSON.stringify(data),
    };
    dispatch(requestSaveOnboardingPathsTypes());
    try {
      return fetch(`${API.pathway.onboarding_type}`, config)
        .then(response => response.json())
        .then(json => {
          dispatch(receiveOnboardingPathsTypes(json));
        })
        .catch(err => {
          console.log(err);
          dispatch(handleSaveOnboardingPathsTypesError(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(handleSaveOnboardingPathsTypesError(err));
    }
  }
};

export const fetchSaveOnboardingPaths = pathData => (dispatch, getState) => {
  const state = getState();
  let accessToken = null;
  if (state && state.auth) {
    const {
      auth: {
        access: {token},
      },
    } = state;
    accessToken = token;
  }

  if (accessToken) {
    const config = {
      method: 'POST',
      mode: 'cors',
      headers: Object.assign({}, JWT_HEADER(accessToken), JSON_HEADER),
      cache: 'default',
      body: JSON.stringify(pathData),
    };
    dispatch(requestSaveOnboardingPaths());
    try {
      return fetch(`${API.pathway.onboarding_path}`, config)
        .then(response => response.json())
        .then(json => {
          dispatch(onSaveOnboardingPaths(json));
        })
        .catch(err => {
          console.log(err);
          dispatch(handleSaveOnboardingPathsError(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(handleSaveOnboardingPathsError(err));
    }
  }
};
