import React from 'react';
import {Divider, Col, Row} from 'antd';
import {formatViewDate} from 'core/utils';

const MyEducation = ({resultEducationInfo, getAwardType}) => {
  return (
    <div className="profile-myeducation">
      <div className="myeducation-header">My Education</div>
      <div className="myeducation-content">
        {resultEducationInfo &&
          resultEducationInfo.length > 0 &&
          resultEducationInfo.map((ed, idx) => {
            return (
              <div className="_list" key={'edu-profile-' + idx}>
                {ed.org_name && (
                  <Row className="_item as-list">
                    <Col
                      md={6}
                      lg={6}
                      sm={24}
                      xs={24}
                      className="text-right pr-3">
                      <strong>
                        <div className="_k">School:</div>
                      </strong>
                    </Col>
                    <Col md={18} lg={18} sm={24} xs={24} className="text-left">
                      <span className="_v">{ed.org_name}</span>
                    </Col>
                  </Row>
                )}
                {ed &&
                  ed.school_start_date &&
                  (ed.school_end_date || ed.is_present) && (
                    <Row className="_item as-list">
                      <Col
                        md={6}
                        lg={6}
                        sm={24}
                        xs={24}
                        className="text-right pr-3">
                        <strong>
                          <span className="_k">Attended:</span>
                        </strong>
                      </Col>
                      <Col
                        md={18}
                        lg={18}
                        sm={24}
                        xs={24}
                        className="text-left">
                        {formatViewDate(ed.school_start_date)} -{' '}
                        {ed.is_present
                          ? 'Present'
                          : `${ed.school_end_date &&
                              formatViewDate(ed.school_end_date)}`}
                      </Col>
                    </Row>
                  )}
                {ed.award_type && (
                  <Row className="_item as-list">
                    <Col
                      md={6}
                      lg={6}
                      sm={24}
                      xs={24}
                      className="text-right pr-3">
                      <strong>
                        <div className="_k">Degree & Award:</div>
                      </strong>
                    </Col>
                    <Col md={18} lg={18} sm={24} xs={24} className="text-left">
                      <span className="_v">{getAwardType(ed.award_type)}</span>
                    </Col>
                  </Row>
                )}
                {ed.area_of_focus && (
                  <Row className="_item as-list">
                    <Col
                      md={6}
                      lg={6}
                      sm={24}
                      xs={24}
                      className="text-right pr-3">
                      <strong>
                        <div className="_k">Area of Focus/ Major:</div>
                      </strong>
                    </Col>
                    <Col md={18} lg={18} sm={24} xs={24} className="text-left">
                      <span className="_v">{ed.area_of_focus}</span>
                    </Col>
                  </Row>
                )}
                {ed.comment && (
                  <Row className="_item as-list">
                    <Col
                      md={6}
                      lg={6}
                      sm={24}
                      xs={24}
                      className="text-right pr-3">
                      <strong>
                        <div className="_k">Comments:</div>
                      </strong>
                    </Col>
                    <Col md={18} lg={18} sm={24} xs={24} className="text-left">
                      <span className="_v">{ed.comment}</span>
                    </Col>
                  </Row>
                )}
                {ed.skills.length !== 0 && (
                  <Row className="_item as-list">
                    <Col
                      md={6}
                      lg={6}
                      sm={24}
                      xs={24}
                      className="text-right pr-3">
                      <strong>
                        <div className="_k">Skills Learned:</div>
                      </strong>
                    </Col>
                    <Col xs={24} sm={24} md={18} lg={18}>
                      <div className="skills-section">
                        <ul className="skills-container">
                          {ed.skills.length !== 0 &&
                            ed.skills.map((skill, skillIndex) => (
                              <li key={skillIndex}>{skill}</li>
                            ))}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                )}
                {idx + 1 !== resultEducationInfo.length && <Divider />}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MyEducation;
