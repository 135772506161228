import {combineReducers} from 'redux';

import admin from './system-management';
import institutionTypes from './institution-type';
import quikFyndData from './quik-fynd';
import institutions from './institutions';
import instituteDetails from './instituteDetails';
import instituteTimeline from './instituteTimeline';
import {consortiumInstitutions} from './consortiumInstitutions';
import partnersRequests from './partnerManagement';
import bannerInstances from './bannerInstances';
import msaLocations from './msaLocations';
// import licenseTypes from './licenseTypes';
import instituteTypes from './instituteTypes';

export const superUserAdmin = combineReducers({
  admin,
  institutionTypes,
  quikFyndData,
  institutions,
  instituteDetails,
  instituteTimeline,
  consortiumInstitutions,
  partnersRequests,
  bannerInstances,
  msaLocations,
  // licenseTypes,
  instituteTypes,
});
