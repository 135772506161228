import * as TYPES from './constants';
import {
  requestSuperUserIstitutionTypes,
  receiveSuperUserIstitutionTypes,
  handleSuperUserIstitutionTypesError,
} from './actions';

import {Services, isArray} from 'core';

import {API} from 'config/superuser.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

const services = new Services();
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_SUPER_USER_INSTITUTION_TYPES:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_SUPER_USER_INSTITUTION_TYPES:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.SUPER_USER_INSTITUTION_TYPES_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchSuperUserIstitutionTypes = () => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestSuperUserIstitutionTypes());
  let endpoint = `${API.administration.institution_type}`;
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        if (
          res?.super_type_info &&
          isArray(res?.super_type_info) &&
          res?.super_type_info.length
        ) {
          return Promise.all(
            res?.super_type_info.map(({type, uuid}) => {
              let uri = `${
                API.administration.institution_sub_type
              }?super_type__uuid=${uuid}&is_licenseable=true`;
              return services.fetchRecords(uri, token).then(sub_types => {
                if (type === 'Consortium') {
                  let endpoint = `${
                    API.administration.institution
                  }?filter=${uuid}`;
                  return services
                    .fetchRecords(endpoint, token)
                    .then(({license = []}) => ({
                      uuid,
                      type,
                      sub_types,
                      consortiumList: license,
                    }));
                }
                return {
                  uuid,
                  type,
                  sub_types,
                };
              });
            })
          );
        }
      })
      .then(result => {
        dispatch(receiveSuperUserIstitutionTypes(result));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleSuperUserIstitutionTypesError(err));
  }
};
