import React, {useState, useEffect} from 'react';
import {Form, Input, Row, Col, Select} from 'antd';
import {
  isEmpty,
  isArray,
  PATTERN,
  isObject,
  isNumber,
  UrlInput,
  StepAction,
  FileUploader,
  PhoneExtInput,
  FieldWithCount,
  onZipCodeChange,
  getCloudinaryImage,
  PreviewImageUploader,
  AddOverviewWithOutForm,
  preparePostOverviewData,
} from 'core';
import Welcome from './Welcome';

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = (left, right) => ({
  labelCol: {
    xs: {span: left},
    sm: {span: left},
  },
  wrapperCol: {
    offset: 1,
    xs: {span: right},
    sm: {span: right},
  },
});

function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

const CreateProfile = props => {
  const {
    form: {getFieldDecorator, validateFieldsAndScroll},
    handelCancel,
    statesData,
    userPortal,
    editData,
    editMode,
    form,
    save,
  } = props;

  const {
    logo,
    web_url,
    tagline,
    banner_image,
    logo_cloudinary,
    nearby_msa = [],
    banner_cloudinary,
    primary_contact_1,
    overview_contents,
    physical_address_details: {
      city,
      state,
      zipcode,
      address_line1,
      address_line2,
    },
    default_msa: {city: msa_city = '', state_code = ''} = {},
  } = editData || {};

  let defZip = zipcode;
  if (
    zipcode &&
    typeof zipcode === 'string' &&
    zipcode.length > 5 &&
    !zipcode.includes('-')
  ) {
    defZip = zipcode.slice(0, 5) + '-' + zipcode.slice(5);
  }
  const [logoData, setLogo] = useState(null);
  const [overviews, setOverview] = useState([]);
  const [bannerImage, setImageData] = useState(null);
  const [visible, setVisibility] = useState(!editMode);

  useEffect(() => {
    if (overview_contents) {
      setOverview(overview_contents);
    }
    if (logo_cloudinary || logo) {
      setLogo(getCloudinaryImage(logo_cloudinary, 'logo') || logo);
    }
    if (banner_cloudinary || banner_image) {
      setImageData(getCloudinaryImage(banner_cloudinary) || banner_image);
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.overview_contents = preparePostOverviewData(
          values.overview_contents
        );
        values.primary_contact_1.phone_extension =
          values.primary_contact_1.phone &&
          values.primary_contact_1.phone.phone_extension;
        values.primary_contact_1.phone =
          values.primary_contact_1.phone &&
          values.primary_contact_1.phone.phone_no;
        values.primary_contact_1.primary_contact_type =
          primary_contact_1.primary_contact_type;
        if (!values.physical_address_details.state) {
          values.physical_address_details.state = '';
        }
        if (banner_image && bannerImage === null) values.banner_image = null;
        save(values, logoData, bannerImage);
      }
    });
  };
  const isReporter = userPortal && userPortal.toLowerCase() === 'reporter';
  const isConsortium = userPortal && userPortal.toLowerCase() === 'consortium';

  return (
    <>
      <Welcome
        {...props}
        visible={visible}
        handleSubmit={() => setVisibility(false)}
      />
      <Form onSubmit={handleSubmit}>
        <div className="clearfix page-header">
          {isReporter ? (
            <div className="profile-header text-center">
              <span style={{textTransform: 'capitalize'}}>
                {userPortal} Profile Info
              </span>
            </div>
          ) : (
            <FormItem>
              <div className="file-upload banner-full-view banner-upload-img">
                <PreviewImageUploader
                  checkSize={10}
                  dragImage={true}
                  readOnly={false}
                  data={bannerImage}
                  allowedFormat="image"
                  subTitle="(Up to 10 mb)"
                  title="Upload Banner Image"
                  onChange={e => setImageData(e)}
                  onDelete={() => setImageData(null)}
                  changeImageLabel="Change Banner Image"
                  icon={<span className="icon-upload-img" />}
                />
              </div>
            </FormItem>
          )}
          <Row type="flex">
            <Col xs={24} sm={24} md={12} lg={12}>
              <FormItem label="Website" {...formItemLayout(6, 10)}>
                {getFieldDecorator('web_url', {
                  rules: [
                    {
                      required: !(isReporter || isConsortium),
                      message: 'website can not be empty',
                    },
                    {
                      pattern: !(isReporter || isConsortium) && PATTERN.Website,
                      message: 'Please enter a valid website url',
                    },
                  ],
                  initialValue: web_url,
                })(<UrlInput placeholder="www.example.com" />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <FormItem
                className="upload-logo"
                {...formItemLayout(6, 10)}
                label={`${capitalizeFirstLetter(userPortal)} Logo (max 5mb)`}>
                {!logoData &&
                  getFieldDecorator('logo', {
                    rules: [
                      {
                        required: true,
                        message: 'Please Upload a logo upto 5MB',
                      },
                    ],
                  })(<Input style={{display: 'none'}} />)}
                <FileUploader
                  form={form}
                  checkSize={5}
                  nameKey="logo"
                  imgSrc={logoData}
                  title="Upload logo"
                  type="imagePreview"
                  multipleUpload={false}
                  onChange={e => setLogo(e)}
                  deleteImage={() => setLogo(null)}
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <FormItem
                label="Tagline"
                className="tagline"
                {...formItemLayout(3, 15)}>
                {getFieldDecorator('tagline', {
                  initialValue: tagline,
                })(<FieldWithCount showcount maxLength={40} />)}
              </FormItem>
            </Col>
            {isConsortium && (
              <>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <FormItem label="Default MSA" {...formItemLayout(6, 10)}>
                    <Select
                      disabled
                      mode="tags"
                      placeholder="Default MSA"
                      defaultValue={
                        (msa_city &&
                          state_code &&
                          `${msa_city}, ${state_code}`) ||
                        '-'
                      }
                    />
                  </FormItem>
                </Col>
                {nearby_msa &&
                  Array.isArray(nearby_msa) &&
                  nearby_msa.length > 0 && (
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <FormItem
                        label="Selected MSAs"
                        className="connectedStates-form-item"
                        {...formItemLayout(4, 18)}>
                        <Row>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <Select
                              disabled
                              mode="multiple"
                              placeholder="Selected MSAs"
                              defaultValue={nearby_msa.map(
                                loc => `${loc.city}, ${loc.state_code}`
                              )}
                            />
                          </Col>
                        </Row>
                      </FormItem>
                    </Col>
                  )}
              </>
            )}
          </Row>
          {/* {!isReporter && editData.created_by_super_user && (
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <FormItem
                  label="Tagline"
                  colon={false}
                  {...formItemLayout(3, 15)}>
                  {getFieldDecorator('tagline', {
                    rules: [
                      {
                        required: true,
                        message: 'Tagline can not be empty',
                      },
                    ],
                    initialValue: tagline,
                  })(
                    <FieldWithCount
                      showcount
                      maxLength={80}
                      isRight
                      placeholder="Enter Tagline"
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          )} */}
          <Row>
            <Col xs={24} sm={24} md={24} lg={12}>
              <div className="profile-header">
                <span style={{textTransform: 'capitalize'}}>
                  {userPortal} Contact Details
                </span>
              </div>
              <FormItem
                label="Address 1"
                {...formItemLayout(6, 14)}
                style={{marginBottom: '5px'}}>
                {getFieldDecorator('physical_address_details.address_line1', {
                  rules: [
                    {
                      required: isConsortium ? false : true,
                      message: 'Address field cannot be empty',
                    },
                  ],
                  initialValue: address_line1 || '',
                })(<Input />)}
              </FormItem>
              <FormItem
                label="Address 2"
                {...formItemLayout(6, 14)}
                style={{marginBottom: '5px'}}>
                {getFieldDecorator('physical_address_details.address_line2', {
                  initialValue: address_line2 || '',
                })(<Input />)}
              </FormItem>
              <FormItem
                label="City"
                {...formItemLayout(6, 14)}
                style={{marginBottom: '5px'}}>
                {getFieldDecorator('physical_address_details.city', {
                  rules: [
                    {
                      required: isConsortium ? false : true,
                      message: 'City field cannot be empty',
                    },
                    {
                      pattern: PATTERN.Name_With_Space,
                      message: 'Please enter a valid city',
                    },
                  ],
                  initialValue: city || '',
                })(<Input />)}
              </FormItem>
              <FormItem
                label="State"
                {...formItemLayout(6, 14)}
                style={{marginBottom: '5px'}}>
                {getFieldDecorator('physical_address_details.state', {
                  rules: [
                    {
                      required: isConsortium ? false : true,
                      message: 'State field cannot be empty',
                    },
                    {
                      pattern: PATTERN.Name_With_Space,
                      message: 'Please enter a valid state',
                    },
                  ],
                  initialValue: state || '',
                })(
                  <Select allowClear={true}>
                    {statesData &&
                      statesData.data &&
                      isArray(statesData.data) &&
                      statesData.data.map((state, index) => (
                        <Option key={index} value={state.name}>
                          {state.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>
              <FormItem
                label="Zipcode"
                {...formItemLayout(6, 14)}
                style={{marginBottom: '5px'}}>
                {getFieldDecorator('physical_address_details.zipcode', {
                  rules: [
                    {
                      required: isConsortium ? false : true,
                      message: 'Zipcode field cannot be empty',
                    },
                    {
                      whitespace: true,
                      pattern: PATTERN.Zip_Code,
                      message: 'Please enter a valid 5 or 9 digit zip code',
                    },
                  ],
                  getValueFromEvent: onZipCodeChange,
                  initialValue: defZip || '',
                })(<Input />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <div className="profile-header">
                <span>Primary Contact Info</span>
              </div>
              <FormItem
                label="First Name"
                {...formItemLayout(6, 14)}
                style={{marginBottom: '5px'}}>
                <div>
                  {getFieldDecorator('primary_contact_1.first_name', {
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                        message: 'Please enter first name',
                      },
                      {
                        pattern: PATTERN.Name_With_Space,
                        message: 'Please enter a valid name',
                      },
                    ],
                    initialValue:
                      primary_contact_1 && primary_contact_1.first_name,
                  })(<Input />)}
                </div>
              </FormItem>
              <FormItem
                label="Last Name"
                {...formItemLayout(6, 14)}
                style={{marginBottom: '5px'}}>
                <div>
                  {getFieldDecorator('primary_contact_1.last_name', {
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                        message: 'Please enter last name',
                      },
                      {
                        pattern: PATTERN.Name_With_Space,
                        message: 'Please enter a valid name',
                      },
                    ],
                    initialValue:
                      primary_contact_1 && primary_contact_1.last_name,
                  })(<Input />)}
                </div>
              </FormItem>
              <FormItem
                label="Email"
                {...formItemLayout(6, 14)}
                style={{marginBottom: '10px'}}>
                <div>
                  {getFieldDecorator('primary_contact_1.email', {
                    rules: [
                      {
                        type: 'email',
                        whitespace: true,
                        message: 'Please enter a valid email',
                      },
                      {
                        required: false,
                        message: 'Please enter email',
                      },
                    ],
                    initialValue: primary_contact_1 && primary_contact_1.email,
                  })(<Input />)}
                </div>
              </FormItem>
              <FormItem label="Phone" {...formItemLayout(6, 14)}>
                {getFieldDecorator('primary_contact_1.phone', {
                  rules: [
                    {
                      required: false,
                      message: 'Phone cannot be empty',
                    },
                    {
                      validator(rule, value, callback) {
                        if (value && isObject(value) && !isEmpty(value)) {
                          let phoneValue;
                          if (value.phone_no.startsWith('+1')) {
                            phoneValue =
                              parseInt(value.phone_no.substr(2)) || null;
                          } else {
                            phoneValue = parseInt(value.phone_no) || null;
                          }
                          let phone_extension = value.phone_extension || null;
                          if (phoneValue && isNumber(phoneValue)) {
                            if (PATTERN.Phone.test(Number(phoneValue))) {
                              if (phone_extension && phone_extension !== '') {
                                if (PATTERN.Ext.test(String(phone_extension))) {
                                  callback();
                                  return;
                                } else {
                                  callback('Enter a valid ext.');
                                  return;
                                }
                              }
                              callback();
                              return;
                            } else {
                              callback('Please enter a valid phone no.');
                              return;
                            }
                          } else {
                            callback();
                          }
                        }
                        callback();
                      },
                    },
                  ],
                  initialValue: {
                    phone_no:
                      (primary_contact_1 && primary_contact_1.phone) || '',
                    phone_extension:
                      (primary_contact_1 &&
                        primary_contact_1.phone_extension) ||
                      '',
                  },
                })(<PhoneExtInput />)}
              </FormItem>
            </Col>
          </Row>
          {!isReporter && (
            <div>
              <div className="profile-header mt-50 text-center">
                <span>OVERVIEW</span>
              </div>
              <div className="content-pad">
                <AddOverviewWithOutForm
                  noForm
                  form={form}
                  data={overviews}
                  nameKey="overview_contents"
                />
              </div>
            </div>
          )}
          <StepAction
            currentStep={0}
            totalSteps={1}
            mode={editMode ? 'edit' : 'create'}
            onSwitchMode={handelCancel}
            saveText="Create"
          />
        </div>
      </Form>
    </>
  );
};
export default Form.create()(CreateProfile);
