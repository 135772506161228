import React from 'react';
import {Divider, Col, Row} from 'antd';
import {formatViewDate} from 'core/utils';

const LicenseAndCertifications = ({
  resultCertificationInfo,
  getAwardType,
  SettingsMonths,
}) => {
  return (
    <div className="profile-myeducation">
      <div className="myeducation-header">License And Certifications</div>
      <div className="myeducation-content">
        {resultCertificationInfo &&
          resultCertificationInfo.length > 0 &&
          resultCertificationInfo.map((ed, idx) => {
            return (
              <div className="_list" key={'edu-profile-' + idx}>
                {ed.program_name && (
                  <Row className="_item as-list">
                    <Col
                      md={6}
                      lg={6}
                      sm={24}
                      xs={24}
                      className="text-right pr-3">
                      <strong>
                        <div className="_k">License Name:</div>
                      </strong>
                    </Col>
                    <Col md={18} lg={18} sm={24} xs={24} className="text-left">
                      <span className="_v">{ed.program_name}</span>
                    </Col>
                  </Row>
                )}
                {ed.org_name && (
                  <Row className="_item as-list">
                    <Col
                      md={6}
                      lg={6}
                      sm={24}
                      xs={24}
                      className="text-right pr-3">
                      <strong>
                        <div className="_k">School:</div>
                      </strong>
                    </Col>
                    <Col md={18} lg={18} sm={24} xs={24} className="text-left">
                      <span className="_v">{ed.org_name}</span>
                    </Col>
                  </Row>
                )}
                {ed &&
                  ed.school_start_date &&
                  (ed.school_end_date || ed.is_present) && (
                    <Row className="_item as-list">
                      <Col
                        md={6}
                        lg={6}
                        sm={24}
                        xs={24}
                        className="text-right pr-3">
                        <strong>
                          <span className="_k">Date Issued:</span>
                        </strong>
                      </Col>
                      <Col
                        md={18}
                        lg={18}
                        sm={24}
                        xs={24}
                        className="text-left">
                        <span className="val">
                          {' '}
                          {ed.date_completed_month && ed.date_completed_year
                            ? `${SettingsMonths[ed.date_completed_month - 1]} ${
                                ed.date_completed_year
                              }`
                            : '-'}
                        </span>
                      </Col>
                    </Row>
                  )}
                {ed.award_type && (
                  <Row className="_item as-list">
                    <Col
                      md={6}
                      lg={6}
                      sm={24}
                      xs={24}
                      className="text-right pr-3">
                      <strong>
                        <div className="_k">Award Type:</div>
                      </strong>
                    </Col>
                    <Col md={18} lg={18} sm={24} xs={24} className="text-left">
                      <span className="award_v">
                        {getAwardType(ed.award_type)}
                      </span>
                    </Col>
                  </Row>
                )}
                {ed.skills.length !== 0 && (
                  <Row className="_item as-list">
                    <Col
                      md={6}
                      lg={6}
                      sm={24}
                      xs={24}
                      className="text-right pr-3">
                      <strong>
                        <div className="_k">Skills Learned:</div>
                      </strong>
                    </Col>
                    <Col className="text-left" xs={24} sm={24} md={18} lg={18}>
                      <div className="skills-section">
                        <ul className="skills-container">
                          {ed.skills.length !== 0 &&
                            ed.skills.map((skill, skillIndex) => (
                              <li key={skillIndex}>{skill}</li>
                            ))}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                )}
                {idx + 1 !== resultCertificationInfo.length && <Divider />}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default LicenseAndCertifications;
