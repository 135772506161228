import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import {getLoggedInstituteUUID} from 'redux/modules/auth';
import * as TYPES from './constants';
import {
  receiveStudentOnboardStats,
  requestStudentOnboardStats,
  handleStudentOnboardStatsError,
  requestStudentOnboardCourseMap,
  handleStudentOnboardCourseMapError,
  receiveStudentOnboardCourseMap,
  resetStudentOnboardCourseMap,
  // requestStudentAssessments,
  // receiveStudentAssessments,
  // handleStudentAssessmentsError,
  // requestStudentProfile,
  // receiveStudentProfile,
  // handleStudentProfileError,
} from './actions';
const initialState = {
  request: null,
  data: null,
  error: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_STUDENT_ONBOARD_STATS:
    case TYPES.REQUEST_STUDENT_ONBOARD_COURSE_MAP:
      //case TYPES.REQUEST_STUDENT_ASSESSMENTS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_STUDENT_ONBOARD_STATS:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };

    case TYPES.RECEIVE_STUDENT_ONBOARD_COURSE_MAP:
      return {
        ...state,
        // studentOnboardCourseMap: action.payload,
        data: action.payload,
        request: false,
      };
    // case TYPES.RECEIVE_STUDENT_ASSESSMENTS:
    //   return {
    //     ...state,
    //     studentAssessments: action.payload,
    //     request: false,
    //   };
    // case TYPES.RECEIVE_STUDENT_PROFILE:
    //   return {
    //     ...state,
    //     studentProfile: action.payload,
    //     request: false,
    //   };
    case TYPES.STUDENT_ONBOARD_STATS_ERROR:
    case TYPES.STUDENT_ONBOARD_COURSE_MAP_ERROR:
      //case TYPES.STUDENT_ASSESSMENTS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.STUDENT_ONBOARD_COURSE_MAP_RESET:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

export const fetchStudentOnboardStats = (
  filterBy = 'all',
  page = 1,
  searchQuery = null,
  showStudentMetrics
) => (dispatch, getState) => {
  const {
    auth: {
      access,
      access: {token = null},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestStudentOnboardStats());
  try {
    const institute_uuid = getLoggedInstituteUUID(getState());
    const queryParams = showStudentMetrics
      ? '&student_metrics=true'
      : `&page_size=10&page=${page}&filter=${filterBy}${
          searchQuery ? `&search=${searchQuery}` : ''
        }`;
    return fetch(
      `${
        API.student.get_students
      }?institute_uuid=${institute_uuid}${queryParams}`,
      config
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Api Fails');
      })
      .then(json => {
        dispatch(receiveStudentOnboardStats(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleStudentOnboardStatsError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleStudentOnboardStatsError(err));
  }
};

// export const fetchStudentProfile = (student_uuid) => (dispatch, getState) => {
//   const {
//     auth: {
//       access: {token},
//     },
//   } = getState();
//   const config = {
//     method: 'GET',
//     mode: 'cors',
//     headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
//     cache: 'default',
//   };
//   dispatch(requestStudentOnboardEvents());
//   try {
//     return fetch(`${API.student.student_onboard_events}?student_uuid=${student_uuid}`, config)
//       .then(response => response.json())
//       .then(json => {
//         dispatch(receiveStudentOnboardEvents(json));
//       })
//       .catch(err => {
//         console.log(err);
//         dispatch(handleStudentOnboardEventsError(err));
//       });
//   } catch (err) {
//     console.log(err);
//     dispatch(handleStudentOnboardEventsError(err));
//   }
// };

export const fetchCourseMap = (s_uuid, p_uuid) => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestStudentOnboardCourseMap());
  try {
    return fetch(
      `${API.student.course_map}?s_uuid=${s_uuid}&p_uuid=${p_uuid}`,
      config
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Api Fails');
      })
      .then(json => {
        dispatch(receiveStudentOnboardCourseMap(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleStudentOnboardCourseMapError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleStudentOnboardCourseMapError(err));
  }
};

export const fetchStudentCourseMap = (student_uuid, path_uuid) => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestStudentOnboardCourseMap());
  try {
    return fetch(
      `${
        API.student.pathway_planner
      }?path_uuid=${path_uuid}&student_uuid=${student_uuid}`,
      config
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Api Fails');
      })
      .then(json => {
        dispatch(receiveStudentOnboardCourseMap(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleStudentOnboardCourseMapError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleStudentOnboardCourseMapError(err));
  }
};

export const resetStudentCourseMap = () => dispatch => {
  dispatch(resetStudentOnboardCourseMap());
};
