import {useDispatch, useSelector} from 'react-redux';
import {
  getAppTheme,
  toggleAppDrawer,
  changeAppThemeMode,
} from 'redux/modules/general';

export const useThemeMode = () => {
  const dispatch = useDispatch();
  const {mode = 'light'} = useSelector(getAppTheme) || {};
  const setMode = value => dispatch(changeAppThemeMode(value));
  return [mode, setMode];
};

export const useDrawer = () => {
  const dispatch = useDispatch();
  const {openMenuBar = true} = useSelector(getAppTheme) || {};
  const toggleMenuBar = value => dispatch(toggleAppDrawer(value));
  return [openMenuBar, toggleMenuBar];
};
