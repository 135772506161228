import * as TYPES from './constants';
import {
  receivePartnersRequests,
  receiveAllPartnersRequests,
  requestPartnersRequests,
  handlePartnersRequestsErrors,
  clearPartnersRequests,
} from './actions';

import {Services} from 'core';

import {API} from 'config/superuser.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

const services = new Services();
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_PARTNERS_REQUESTS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_PARTNERS_REQUESTS:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.RECEIVE_ALL_PARTNERS_REQUESTS:
      let data = action.payload;
      if (state.data && state.data.data) {
        data.data = [...state.data.data, ...data.data];
      }
      return {
        ...state,
        request: false,
        error: false,
        data,
      };
    case TYPES.PARTNERS_REQUESTS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.CLEAR_PARTNERS_REQUESTS:
      return {
        ...state,
        initialState,
      };
    default:
      return state;
  }
};

export const fetchPartnersRequests = (
  filter,
  status,
  sort,
  term,
  page = 1,
  uuid,
  statusValue
) => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
    superUserAdmin: {partnersRequests = {}},
  } = getState();

  if (uuid && statusValue && false) {
    //removed this block by adding condition to false for refresh count issue
    dispatch(requestPartnersRequests());
    const {data: {data, overview, page_details} = {}} = partnersRequests;
    const updatedData = data.map(item => {
      if (item.institution_id === uuid) {
        return {
          ...item,
          approval_status:
            statusValue.toLowerCase() === 'decline' ? 'Declined' : 'Approved',
        };
      }
      return item;
    });
    const updatedOverview = {
      ...overview,
    };
    if (statusValue.toLowerCase() === 'decline') {
      updatedOverview.declined_employer_partners =
        overview.declined_employer_partners + 1;
      updatedOverview.pending_approvals = overview.pending_approvals - 1;
    } else {
      updatedOverview.approved_employer_partners =
        overview.approved_employer_partners + 1;
      updatedOverview.pending_approvals = overview.pending_approvals - 1;
    }
    dispatch(
      receivePartnersRequests({
        data: updatedData,
        overview: updatedOverview,
        page_details,
      })
    );
  } else {
    dispatch(requestPartnersRequests());
    let endpoint = `${
      API.administration.partners
    }?page_size=10&page_num=${page}`;
    if (filter) {
      endpoint += `&filter_by=${filter}`;
    }
    if (status) {
      endpoint += `&status=${status}`;
    }
    if (sort) {
      endpoint += `&sort_by=${sort}`;
    }
    if (term) {
      endpoint += `&name=${term}`;
    }
    try {
      return services
        .fetchRecords(endpoint, token)
        .then(res => {
          if (page > 1) {
            dispatch(receiveAllPartnersRequests(res));
          } else {
            dispatch(receivePartnersRequests(res));
          }
        })
        .catch(err => {
          console.log(err);
          dispatch(handlePartnersRequestsErrors(err));
        });
    } catch (err) {
      console.log(err);
      dispatch(handlePartnersRequestsErrors(err));
    }
  }
};
