import React, {Component} from 'react';
import {RequestErrorLoaderHandler, PageHeader} from 'core';

const DynamicHeader = HocComponent =>
  class extends Component {
    render() {
      const {pageHeader, loaderData} = this.props;
      return (
        <>
          {pageHeader && <PageHeader {...pageHeader} />}
          {loaderData && <RequestErrorLoaderHandler {...loaderData} />}
          {/*.......... bind this into error boundary......*/}
          <HocComponent {...this.props} />
        </>
      );
    }
  };

export default DynamicHeader;
