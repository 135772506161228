import React from 'react';
import {Divider} from 'antd';
import {NoSearchResults} from 'core';

const VolunteerExperience = ({student_Info}) => {
  const {voluntary_exp = []} = student_Info || {};

  return (
    <div className="profile-volunteerexp">
      <div className="volunteerexp-header">Volunteer Experience</div>
      <div className="volunteerexp-content">
        {voluntary_exp && voluntary_exp.length > 0 ? (
          voluntary_exp.map((exp, idx) => {
            const Responsibilities =
              exp &&
              exp.roles_responsibilities &&
              Array.isArray(exp.roles_responsibilities) &&
              exp.roles_responsibilities.length > 0 &&
              exp.roles_responsibilities.filter(
                role => typeof role.roles_responsibilities === 'string'
              );
            return (
              <div className="_list" key={'experience-profile-' + idx}>
                {exp && exp.name && (
                  <div className="_item">
                    <span className="_k">Experience: </span>
                    <span className="_v">{exp.name}</span>
                  </div>
                )}
                {exp && exp.hours && (
                  <div className="_item">
                    <span className="_k">Hours: </span>
                    <span className="_v">{exp.hours}</span>
                  </div>
                )}
                {Responsibilities && Responsibilities.length > 0 && (
                  <div className="_item as-list">
                    <div className="_k">Roles and Responsibilities: </div>
                    <ul className="list_blueDot">
                      {Responsibilities.map(
                        (role, id) =>
                          role &&
                          (role.roles_responsibilities ? (
                            <li
                              key={`responsibility-${id}`}
                              className="preWrapText">
                              {role.roles_responsibilities}
                            </li>
                          ) : (
                            <span>-</span>
                          ))
                      )}
                    </ul>
                  </div>
                )}
                {idx + 1 !== voluntary_exp.length && <Divider />}
              </div>
            );
          })
        ) : (
          <NoSearchResults className="no-results-text" message="N/A" />
        )}
      </div>
    </div>
  );
};

export default VolunteerExperience;
