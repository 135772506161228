import React, {lazy, Suspense} from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Loading} from 'core';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import ThemeContext from './ThemeContext';
import SendMessage from 'mui-core/SendMessage';
import {loadTokens} from 'redux/localStorage';
import {getBaseURL} from 'mui-core/domain-services';

const App = lazy(() => import('./App'));
const auth = loadTokens();
const baseURL = getBaseURL();
const routerParams = {};
if (baseURL) {
  routerParams['basename'] = baseURL;
}
console.log('baseURL', baseURL, 'end');
// if(base === 'admin')

const Root = ({store}) => (
  <Provider store={store}>
    <ThemeContext>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Router {...routerParams}>
          {/* <React.StrictMode> */}
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route path="/" component={App} />
            </Switch>
            {/* </React.StrictMode> */}
          </Suspense>
        </Router>
        <>
          {/* common UI Elements for hooks */}
          <SendMessage />
        </>
      </LocalizationProvider>
    </ThemeContext>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
