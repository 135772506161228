import React from 'react';
import {Text, View, StyleSheet, Font, Image} from '@react-pdf/renderer';
import {getCloudinaryImage} from '../../../utils';
// import {getLogo} from 'core/utils';
// import fontFile from '../../../../assets/fonts/Nunito-Medium.ttf';

// Font.register({
//   family: 'Nunito-Bold',
//   src: fontFile,
// });

const styles = StyleSheet.create({
  block: {
    position: 'relative',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingTop: 24,
    paddingBottom: 24,
    textAlign: 'center',
    borderStyle: 'solid',
    borderColor: '#4abaed',
    flexDirection: 'column',
    marginBottom: '5%',
  },
  label: {
    fontSize: 20,
    color: '#000',
    marginBottom: 10,
    // fontFamily: 'Nunito-Bold',
    // fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  value: {
    fontSize: 10,
    color: '#000',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  qrCode: {
    position: 'absolute',
    top: '45%',
    right: '10px',
  },
  image: {
    width: '50px',
    height: '50px',
  },
});

export default ({studentProfile, pathInfo}) => {
  const {first_name = '', last_name = '', qr_code = ''} =
    studentProfile.student_details || {};
  const student_name = `${first_name} ${last_name}`;
  const college_name =
    pathInfo.path_details &&
    pathInfo.path_details.institute_details &&
    pathInfo.path_details.institute_details.name;
  const qrCodeImage = qr_code && getCloudinaryImage(qr_code);

  return (
    <View style={styles.block}>
      <View style={styles.label}>
        <Text>{student_name}</Text>
      </View>
      <View style={styles.value}>
        <Text>{college_name}</Text>
      </View>
      <View style={styles.qrCode}>
        {qrCodeImage && (
          <Image
            style={styles.image}
            source={{
              uri: qrCodeImage,
              method: 'GET',
              header: {
                'Access-Control-Allow-Origin': '*',
                'Cache-Control': 'no-cache',
              },
            }}
          />
        )}
      </View>
    </View>
  );
};
