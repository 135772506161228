import React, {useState, useEffect} from 'react';
import {Row, Col} from 'antd';
import {SpiderChart, SkillsTable} from './SpiderChart';

const Skills = ({data}) => {
  const [skillsData, setSkillsData] = useState([]);

  useEffect(
    () => {
      if (data && data.skills) {
        setSkillsData([...data.skills]);
      }
    },
    [data]
  );

  const sortedSkills =
    skillsData && Array.isArray(skillsData)
      ? skillsData.sort((a, b) => b.experience - a.experience)
      : [];

  return (
    <>
      <div className="skills">
        <div className="skills-header">My Declared Skills</div>
        <div className="skills-content">
          <Row>
            <Col span={24}>
              <SpiderChart data={sortedSkills} />
            </Col>
            <Col span={24}>
              <SkillsTable viewOnly data={sortedSkills} />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Skills;
