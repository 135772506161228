import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {Chip, Stack, Typography} from '@mui/material';
import {default as MuiTab} from '@mui/material/Tab';

const CustomTab = styled(MuiTab)(({theme}) => ({
  '& h6:nth-child(2)': {
    borderRadius: '8px',
    padding: '2px 10px',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.darkGray.contrastText
        : 'black',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.darkGray.main
        : theme.palette.grayBorder.main,
  },
}));

const Tab = ({contentCount, label, selected, ...otherProps}) => {
  return (
    <CustomTab
      {...otherProps}
      label={
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="center">
          <Typography
            variant="h6"
            textTransform="capitalize"
            color={selected ? 'primary' : 'text.primary'}>
            {label}
          </Typography>
          {contentCount !== undefined && (
            <Chip
              label={contentCount || 0}
              sx={{
                fontSize: '14px',
                borderRadius: '10px',
                '& span': {p: '6px 10px'},
              }}
            />
          )}
        </Stack>
      }
    />
  );
};

Tab.propTypes = {
  ...MuiTab.propTypes,
  contentCount: PropTypes.number,
};

export default Tab;
