import * as TYPES from './constants';
import {Services} from 'core';
import {
  requestStudentProfileData,
  receiveStudentProfileData,
  handleStudentProfileDataError,
} from './actions';

import {API, JWT_HEADER, JSON_HEADER} from 'config/srm.config';

const services = new Services();

const initialState = {
  data: null,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_STUDENT_PROFILE_DATA:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_STUDENT_PROFILE_DATA:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.STUDENT_PROFILE_DATA_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.CLEAR_STUDENT_PROFILE_DATA:
      return {
        ...state,
        data: null,
        error: false,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchStudentProfileData = studentID => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();

  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };

  dispatch(requestStudentProfileData());
  const URI = `${
    API.student.student_profile_details
  }?student_uuid=${studentID}`;

  try {
    return (
      fetch(URI, config)
        .then(response => response.json())
        // .then(student_profile => {
        //   return student_profile;
        // })
        .then(json => {
          dispatch(receiveStudentProfileData(json));
        })
        .catch(err => {
          console.log(err);
          dispatch(handleStudentProfileDataError(err));
        })
    );
  } catch (err) {
    console.log(err);
    dispatch(handleStudentProfileDataError(err));
  }
};
