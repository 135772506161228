import React, {useState} from 'react';
import {Icon, Avatar, Dropdown, Layout} from 'antd';

import {
  ProfileMenu,
  AboutUs,
  EditProfile,
  NotificationSettings,
  Notifications,
  isObject,
  isEmpty,
  getCloudinaryImage,
} from 'core';
import {GoEducateLogo} from 'sharedResources/assets/images/';

const {Header} = Layout;

const MainHeader = React.forwardRef((props, ref) => {
  const [showMenuItem, setShowMenuItem] = useState(null);
  const [menuVisiblity, setMenuVisiblity] = useState(false);
  const [blockVisiblity, setBlockVisiblity] = useState(false);
  const [notificationVisibility, setNotificationVisibility] = useState(false);
  const [
    notificationControlsVisibility,
    setNotificationControlsVisibility,
  ] = useState({});

  const onNotificationVisibleChange = visible => {
    if (
      isObject(notificationControlsVisibility) &&
      !isEmpty(notificationControlsVisibility) &&
      Object.values(notificationControlsVisibility)[0]
    ) {
      setNotificationVisibility(true);
    } else {
      setNotificationVisibility(visible);
    }
  };

  const onMenuClick = e => {
    const {
      userDetails,
      onEditProfileSave,
      notificationSettingsData,
      saveNotification,
      logout,
    } = props;
    if (e.key === 'edit_profile') {
      setShowMenuItem(
        <EditProfile
          closeHandler={closeHandler}
          onEditProfileSave={onEditProfileSave}
          type="basic"
          userData={userDetails}
        />
      );
      handleMenuBlockVisibility();
    } else if (e.key === 'change_password') {
      setShowMenuItem(
        <EditProfile
          closeHandler={closeHandler}
          onEditProfileSave={onEditProfileSave}
          type="password"
          userData={userDetails}
        />
      );
      handleMenuBlockVisibility();
    } else if (e.key === 'notifications') {
      setShowMenuItem(
        <NotificationSettings
          closeHandler={closeHandler}
          notificationSettings={notificationSettingsData}
          saveNotification={saveNotification}
        />
      );
      handleMenuBlockVisibility();
    } else if (e.key === 'about_us') {
      setShowMenuItem(<AboutUs closeHandler={closeHandler} />);
      handleMenuBlockVisibility();
    } else if (e.key === 'log_out') {
      logout();
    }
  };

  const handleVisibleChange = isVisible => {
    setMenuVisiblity(isVisible);
  };

  const handleMenuBlockVisibility = () => {
    setBlockVisiblity(true);
    setMenuVisiblity(false);
  };
  const closeHandler = () => {
    setMenuVisiblity(true);
    setBlockVisiblity(false);
  };

  ref.current = Object.assign(
    {},
    {
      closeHandler,
    }
  );

  const handleControlsVisiblity = value => {
    setNotificationControlsVisibility(value);
  };

  const {
    Logo,
    onLogoClick,
    userDetails: {picture = null, first_name = null} = {},
    menuData,
    notifications,
    onNotificationClick,
    onClearAllNotifications,
  } = props;
  return (
    <Header className="header">
      {Logo ? (
        <Logo className="logo" onClick={onLogoClick} />
      ) : (
        <GoEducateLogo className="logo" onClick={onLogoClick} />
      )}

      <div className="header-menu-links">
        <Dropdown
          overlay={
            <Notifications
              data={notifications}
              onNotificationClick={onNotificationClick}
              onClearAllNotifications={onClearAllNotifications}
              handleControlsVisiblity={handleControlsVisiblity}
              controlsVisiblity={notificationControlsVisibility}
            />
          }
          trigger={['click']}
          visible={notificationVisibility}
          onVisibleChange={onNotificationVisibleChange}>
          <div className="ant-badge">
            <span className="icon-bell-o icon-bell" />
            {notifications && notifications.length > 0 && (
              <sup
                data-show="true"
                className="ant-scroll-number ant-badge-count"
                title={notifications.length}
              />
            )}
          </div>
        </Dropdown>
        <div className="divider" />
        <div className="user-icon-img">
          {!blockVisiblity ? (
            <Dropdown
              overlay={
                <ProfileMenu onMenuClick={onMenuClick} data={menuData} />
              }
              trigger={['click']}
              onVisibleChange={handleVisibleChange}
              visible={menuVisiblity}>
              <div className="ant-dropdown-link">
                <span className="txt-ellipsis">
                  {first_name || 'Anonymous'}
                </span>
                <Icon type="down" />
                <Avatar src={getCloudinaryImage(picture, 'logo')} icon="user" />
              </div>
            </Dropdown>
          ) : (
            <Dropdown overlay={showMenuItem} visible={blockVisiblity}>
              <div className="ant-dropdown-link">
                <span className="txt-ellipsis">
                  {first_name || 'Anonymous'}
                </span>
                <Icon type="down" />
                <Avatar src={picture} icon="user" />
              </div>
            </Dropdown>
          )}
        </div>
      </div>
    </Header>
  );
});

export default MainHeader;
