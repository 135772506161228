import {Services} from 'core';
import * as TYPES from './constants';
import {API} from 'config/employer.config';
import {
  clearEmployerOpportunityDetails,
  requestEmployerOpportunityDetails,
  receiveEmployerOpportunityDetails,
  handleEmployerOpportunityDetailsError,
} from './actions';

const initialState = {
  data: null,
  error: null,
  request: null,
};
const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_EMPLOYER_OPPORTUNITY_DETAILS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_EMPLOYER_OPPORTUNITY_DETAILS:
      const newData = (action.payload && action.payload[0]) || {};
      return {
        ...state,
        error: false,
        request: false,
        data: newData,
      };
    case TYPES.EMPLOYER_OPPORTUNITY_DETAILS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.CLEAR_EMPLOYER_OPPORTUNITY_DETAILS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const fetchOpportunityDetails = opportunity_id => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestEmployerOpportunityDetails());
  try {
    let endpoint = `${API.administration.opportunities}${opportunity_id}`;
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receiveEmployerOpportunityDetails(res));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleEmployerOpportunityDetailsError(err));
      });
  } catch (error) {
    console.log(error);
    dispatch(handleEmployerOpportunityDetailsError(error));
  }
};

export const clearOpportunityDetails = () => dispatch => {
  dispatch(clearEmployerOpportunityDetails());
};
