import {combineReducers} from 'redux';

import courseManagementReducer from './courses';
import courseManagementCourseInstructionTypeReducer from './coursesInstructionsTypes';
import courseManagementCourseTransferTypeReducer from './coursesTransferTypes';
import courseManagementCourseCreditStatusReducer from './coursesCreditStatus';
import skillsReducer from './skills';
import catalogReducer from './catalog';

export const courseManagementReducers = combineReducers({
  courses: courseManagementReducer,
  courseInstructionsTypes: courseManagementCourseInstructionTypeReducer,
  courseTransferTypes: courseManagementCourseTransferTypeReducer,
  courseCreditStatus: courseManagementCourseCreditStatusReducer,
  skills: skillsReducer,
  catalogs: catalogReducer,
});
