import React from 'react';
import {Text, View} from '@react-pdf/renderer';
import List, {Item} from './List';
import Divider from './Divider';

export default ({styles, awardTypes, studentProfile}) => {
  const {awards_leadership_projects_publications: awards} = studentProfile;

  const getAwardType = id => {
    // const {data: awardTypesData = []} = awardTypes || {};
    if (Array.isArray(awardTypes)) {
      const idx = awardTypes.map(i => i.item_type_uuid).indexOf(id);
      return awardTypes[idx].item_type;
    } else {
      return id;
    }
  };

  return (
    <>
      <View style={styles.header}>
        <Text>Awards, Leadership, Projects & Publications</Text>
      </View>
      <View style={styles.content}>
        {awards &&
          Array.isArray(awards) &&
          awards.length > 0 &&
          awards.map(
            ({item_type = '', item_title = '', comments = []}, idx) => (
              <View key={idx}>
                {item_type && item_title && (
                  <Text style={styles.boldLabel}>
                    {`${getAwardType(item_type)}: ${item_title}`}
                  </Text>
                )}
                <View style={styles.bulletList}>
                  <List>
                    {comments &&
                      Array.isArray(comments) &&
                      comments.length > 0 &&
                      comments.map(
                        ({additional_info = ''}, idx) =>
                          additional_info && (
                            <Item key={idx} style={styles.detailContainer}>
                              {additional_info}
                            </Item>
                          )
                      )}
                  </List>
                </View>
                {idx !== awards.length - 1 ? <Divider /> : null}
              </View>
            )
          )}
        {!awards ||
          !Array.isArray(awards) ||
          (awards.length === 0 && (
            <Text style={styles.value}>No awards added.</Text>
          ))}
      </View>
    </>
  );
};
