import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Input} from 'antd';
import {Counter} from 'core';
const {TextArea} = Input;

const iconRightStyle = {
  position: 'absolute',
  top: 0,
  right: 9,
};
class FieldWithCount extends Component {
  state = {count: 0};

  componentDidUpdate = ({value}) => {
    const {value: newValue} = this.props;
    if (newValue !== value) {
      this.setFieldValue(newValue);
    }
  };

  componentDidMount = () => this.setFieldValue(this.props.value);

  setFieldValue = initialValue =>
    this.setState({
      initialValue,
      count: (initialValue && initialValue.length) || 0,
    });

  countChar = event => {
    const {onChange} = this.props;
    const value = event.target.value;
    const count = value.length;
    if (onChange) {
      this.setState({count}, () => onChange(value));
    }
  };

  render() {
    const {showcount, fieldtype, isRight, ...rest} = this.props;
    const {count, initialValue} = this.state;
    return (
      <>
        {fieldtype === 'TextArea' ? (
          <TextArea onChange={this.countChar} value={initialValue} {...rest} />
        ) : fieldtype === 'Input' ? (
          <Input onChange={this.countChar} value={initialValue} {...rest} />
        ) : (
          'Field Type does not match!'
        )}
        {rest && rest.maxLength && showcount && (
          <Counter
            total={rest.maxLength}
            current={count}
            style={isRight ? iconRightStyle : {}}
          />
        )}
      </>
    );
  }
}

FieldWithCount.propTypes = {
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  showcount: PropTypes.bool,
  fieldtype: PropTypes.string,
};

FieldWithCount.defaultProps = {
  fieldtype: 'Input',
  showcount: false,
};

export default FieldWithCount;
