import * as TYPES from 'redux/types';
import {API} from 'config/srm.config';
import {requestInstance, recieveInstance, errorInstance} from './actions';

import {Services} from 'core';
import {fetchSuperUserLicenseTypes} from '../general';
import {API as API_} from 'config/superuser.config';
import {receiveSuperUserLicenseTypes} from '../general/actions';
const services = new Services();

const initialState = {request: false};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.INSTANCE_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.INSTANCE_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.CLEAR_INSTANCE:
      return {
        request: false,
        error: false,
      };
    case TYPES.INSTANCE_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchInstance = () => (dispatch, _getState) => {
  try {
    dispatch(requestInstance());
    let endPoint = `${API.auth.license}?source=srm`;
    return services.fetchRecords(endPoint, null).then(async res => {
      if (res && !res.error) {
        //to display splash screen little pause..
        // try {
        //   services
        //     .fetchRecords(`${API_.administration.institution_type}`, token)
        //     .then(res => {
        //       dispatch(receiveSuperUserLicenseTypes(res));
        dispatch(recieveInstance(res));
        //     });
        // } catch (e) {
        //   console.log(e);
        // }

        return;
      }
      dispatch(errorInstance(res));
    });
  } catch (err) {
    dispatch(errorInstance(err));
  }
};

export const getInstance = state => state.instance;
