import * as TYPES from './constants';
import {
  requestEmployerDashboardMetrics,
  receiveEmployerDashboardMetrics,
  handleEmployerDashboardMetricsError,
} from './actions';
import {API} from 'config/employer.config';
import {JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_EMPLOYER_DASHBOARD_METRICS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_EMPLOYER_DASHBOARD_METRICS:
      return {
        ...state,
        data: action.payload,
        request: false,
        error: false,
      };
    case TYPES.EMPLOYER_DASHBOARD_ERROR_METRICS:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchEmployerDashboardMetrics = () => (dispatch, getState) => {
  const {auth: {access: {token = null} = {}} = {}} = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestEmployerDashboardMetrics());
  try {
    return fetch(`${API.administration.dashboard}`, config)
      .then(response => response.json())
      .then(json => {
        if (json.workforce_insights) {
          dispatch(receiveEmployerDashboardMetrics(json.workforce_insights));
        } else {
          dispatch(handleEmployerDashboardMetricsError('Something went wrong'));
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(handleEmployerDashboardMetricsError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleEmployerDashboardMetricsError(err));
  }
};
