import * as TYPES from './constants';

// get Apprenticeships List
export const receiveEmployerApprenticeships = json => ({
  type: TYPES.RECEIVE_EMPLOYER_APPRENTICESHIPS,
  payload: json,
});
export const requestEmployerApprenticeships = () => ({
  type: TYPES.REQUEST_EMPLOYER_APPRENTICESHIPS,
});
export const handleEmployerApprenticeshipsError = err => ({
  type: TYPES.EMPLOYER_APPRENTICESHIPS_ERROR,
  err,
});
export const clearApprenticeships = () => ({
  type: TYPES.CLEAR_EMPLOYER_APPRENTICESHIPS,
});
export const employerApprenticeshipStatusUpdate = () => ({
  type: TYPES.EMPLOYER_APPRENTICESHIP_STATUS_UPDATE,
});

// Occupation List
export const requestOccupationList = () => ({
  type: TYPES.REQUEST_OCCUPATIONS_LIST,
});
export const receiveOccupationList = json => ({
  type: TYPES.RECEIVE_OCCUPATIONS_LIST,
  payload: json,
});
export const handleOccupationListError = err => ({
  type: TYPES.OCCUPATIONS_LIST_ERROR,
  err,
});
export const handleOccupationListClear = err => ({
  type: TYPES.OCCUPATIONS_LIST_CLEAR,
});
