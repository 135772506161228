import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {themeColorMode} from 'mui-core/utils';
import {styled, useTheme} from '@mui/material/styles';
import {toggleAppDrawer} from 'redux/modules/general';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {
  Box,
  List,
  Tooltip,
  ListItem,
  Collapse,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  ListItemButton,
} from '@mui/material';

const CustomListItem = styled(ListItem)(({theme, sideBarOpen}) => ({
  '& .MuiListItemButton-root': {
    borderRadius: sideBarOpen ? 8 : 20,
    '&:hover': {
      color: theme.palette.mode === 'light' ? 'black' : 'white',
      '& .MuiListItemIcon-root > svg': {
        transform: 'scale(1.2)',
        transition: 'all 0.4s linear',
      },
    },
  },
  '& .Mui-selected': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? '#00000014 !important'
        : theme.palette.grey.main,
    '& .MuiListItemText-root .MuiListItemText-primary': {
      fontWeight: 600,
    },
    '&:hover': {
      background:
        theme.palette.mode === 'light'
          ? theme.palette.grayBorder.main
          : theme.palette.midGray.main,
      color: theme.palette.mode === 'light' ? 'black' : 'white',
    },
  },
}));

const SideBarMenuItem = ({sideBarOpen, menuItem}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const {title, key, Icon, submenus, path} = menuItem || {};
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuClick = () => {
    setOpenSubMenu(!openSubMenu);
    if (mobile) dispatch(toggleAppDrawer(false));
  };

  return (
    <>
      <CustomListItem
        key={key}
        disablePadding
        sx={{display: 'block'}}
        sideBarOpen={sideBarOpen}>
        <Tooltip title={!sideBarOpen ? title : ''} placement="right">
          {path ? (
            <ListItemButton
              exact
              to={path}
              component={NavLink}
              onClick={handleMenuClick}
              activeClassName="Mui-selected"
              className={isActive => (isActive ? 'Mui-selected' : '')}
              sx={{
                mx: 2,
                px: 2.5,
                height: 40,
                minHeight: 40,
                borderRadius: '5em',
                position: 'relative',
                justifyContent: sideBarOpen ? 'initial' : 'center',
              }}>
              {Icon && (
                <ListItemIcon
                  sx={{
                    zIndex: 2,
                    minWidth: 0,
                    mr: sideBarOpen ? 2 : 'auto',
                  }}>
                  <Icon />
                </ListItemIcon>
              )}
              <ListItemText
                inset={!Icon}
                sx={{
                  zIndex: 2,
                  pl: !Icon ? '40px' : '0px',
                  opacity: sideBarOpen ? 1 : 0,
                  whiteSpace: sideBarOpen ? 'normal' : 'nowrap',
                }}>
                {title}
              </ListItemText>
              {submenus && sideBarOpen && (
                <>{openSubMenu ? <ExpandLess /> : <ExpandMore />}</>
              )}
            </ListItemButton>
          ) : (
            <ListItemButton
              component={Box}
              onClick={handleMenuClick}
              activeClassName="Mui-selected"
              className={isActive => (isActive ? 'Mui-selected' : '')}
              sx={{
                mx: 2,
                px: 2.5,
                height: 40,
                minHeight: 40,
                borderRadius: '5em',
                position: 'relative',
                justifyContent: sideBarOpen ? 'initial' : 'center',
              }}>
              {Icon && (
                <ListItemIcon
                  sx={{
                    zIndex: 2,
                    minWidth: 0,
                    mr: sideBarOpen ? 2 : 'auto',
                  }}>
                  <Icon />
                </ListItemIcon>
              )}
              <ListItemText
                inset={!Icon}
                sx={{
                  zIndex: 2,
                  opacity: sideBarOpen ? 1 : 0,
                  whiteSpace: sideBarOpen ? 'normal' : 'nowrap',
                }}>
                {title}
              </ListItemText>
              {submenus && sideBarOpen && (
                <>{openSubMenu ? <ExpandLess /> : <ExpandMore />}</>
              )}
            </ListItemButton>
          )}
        </Tooltip>
      </CustomListItem>
      {submenus && (
        <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Array.isArray(submenus) &&
              submenus.map((data, index) => (
                <SideBarMenuItem
                  key={index}
                  menuItem={data}
                  sideBarOpen={sideBarOpen}
                />
              ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default SideBarMenuItem;
