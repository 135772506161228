import {combineReducers} from 'redux';
import themeReducer from './theme';
import licenseTypes from './licenseTypes';
import sendMail from './sendMail';

export const generalReducer = combineReducers({
  theme: themeReducer,
  licenseTypes,
  sendMail: sendMail,
});
