import * as React from 'react';
import {Box} from '@mui/material';
import AppSideBar from './AppSideBar';
import FixedAppBar from './FixedAppBar';
import {BackgroundPaper} from 'mui-core';
import {styled, useTheme} from '@mui/material/styles';

const drawerWidth = 280;

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Container = styled(Box)(({theme, contentCenter}) =>
  contentCenter
    ? {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }
    : {}
);

const AppDrawer = ({
  children,
  onSignOut,
  onLogoClick,
  sideBarMenu = [],
  userDetails = {},
  hideSideBar = false,
  instanceDetails = {},
  profileMenuData = [],
  SideBarButtonMenu = null,
}) => {
  const theme = useTheme();

  return (
    <BackgroundPaper
      sx={{display: 'flex', minHeight: '100vh'}}
      background={{
        light: theme.palette.background.main,
        dark: theme.palette.background.paper,
      }}>
      <FixedAppBar
        onSignOut={onSignOut}
        hideSideBar={hideSideBar}
        userDetails={userDetails}
        drawerWidth={drawerWidth}
        profileMenuData={profileMenuData}
      />
      {!hideSideBar && (
        <AppSideBar
          sideBarMenu={sideBarMenu}
          onLogoClick={onLogoClick}
          drawerWidth={drawerWidth}
          DrawerHeader={DrawerHeader}
          instanceDetails={instanceDetails}
          SideBarButtonMenu={SideBarButtonMenu}
        />
      )}
      <Box component="main" sx={{flexGrow: 1, p: 3}}>
        <DrawerHeader />
        <Container contentCenter={hideSideBar}>{children}</Container>
      </Box>
    </BackgroundPaper>
  );
};

export default AppDrawer;
