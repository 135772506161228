import {Services} from 'core';
import * as TYPES from './constants';
import {API} from 'config/employer.config';
import {
  requestEmployerExperienceLevel,
  receiveEmployerExperienceLevel,
  handleEmployerExperienceLevelError,
} from './actions';

const initialState = {
  data: null,
  error: null,
  request: null,
};
const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_EMPLOYER_EXPERIENCE_LEVEL:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_EMPLOYER_EXPERIENCE_LEVEL:
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload || {},
      };
    case TYPES.EMPLOYER_EXPERIENCE_LEVEL_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };

    default:
      return state;
  }
};

export const fetchExperienceLevel = () => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestEmployerExperienceLevel());
  try {
    let endpoint = `${API.administration.experience_level}`;
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receiveEmployerExperienceLevel(res));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleEmployerExperienceLevelError(err));
      });
  } catch (error) {
    console.log(error);
    dispatch(handleEmployerExperienceLevelError(error));
  }
};
