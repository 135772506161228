import React from 'react';
import {Navigation} from './Navigation';
import {Stack, Box, Step, Stepper, StepLabel} from '@mui/material';

export default function HorizontalStepper({
  steps,
  activeStep,
  handleNext,
  handleCancel,
  handleSubmit,
  handlePrevious,
}) {
  return (
    <Box>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(step => (
          <Step key={step.title}>
            <StepLabel>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Stack spacing={3} mt={2}>
        {steps[activeStep].component}
      </Stack>
      <Navigation
        steps={steps}
        activeStep={activeStep}
        handleCancel={handleCancel}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
}
