import {combineReducers} from 'redux';

import careerPathsReducer from './careerPaths';
import filterPathsReducer from './filterPath';
import searchCourseReducer from './searchCourse';
import searchPathReducer from './searchPath';
import pathWaysReducer from './pathWays';
import searchProgramsReducer from './searchPrograms';
import genedPaths from './genedPaths';
import onBoardingPaths from './onBoardingPaths';
import overviewAIGenerator from './overviewAIGenerator';

export const pathwaysReducers = combineReducers({
  careerPaths: careerPathsReducer,
  filterPaths: filterPathsReducer,
  courseSearch: searchCourseReducer,
  pathSearch: searchPathReducer,
  pathways: pathWaysReducer,
  searchPrograms: searchProgramsReducer,
  genedPaths: genedPaths,
  onBoardingPaths: onBoardingPaths,
  overviewAIGenerator: overviewAIGenerator,
});
