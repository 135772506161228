// Employer Profile
export const EMPLOYER_PROFILE_ERROR = 'EMPLOYER_PROFILE_ERROR';
export const TOGGLE_EMPLOYER_PROFILE = 'TOGGLE_EMPLOYER_PROFILE';
export const REQUEST_EMPLOYER_PROFILE = 'REQUEST_EMPLOYER_PROFILE';
export const RECEIVE_EMPLOYER_PROFILE = 'RECEIVE_EMPLOYER_PROFILE';

// External companies i.e careeronestop companies
export const REQUEST_CAREER_ONE_STOP_COMPANIES =
  'REQUEST_CAREER_ONE_STOP_COMPANIES';
export const RECEIVED_CAREER_ONE_STOP_COMPANIES =
  'RECEIVED_CAREER_ONE_STOP_COMPANIES';
export const CAREER_ONE_STOP_COMPANIES_ERROR =
  'CAREER_ONE_STOP_COMPANIES_ERROR';
export const CAREER_ONE_STOP_COMPANIES_CLEAR =
  'CAREER_ONE_STOP_COMPANIES_CLEAR';

//Employer Dashboard
export const REQUEST_EMPLOYER_DASHBOARD_METRICS =
  'REQUEST_EMPLOYER_DASHBOARD_METRICS';
export const RECEIVE_EMPLOYER_DASHBOARD_METRICS =
  'RECEIVE_EMPLOYER_DASHBOARD_METRICS';
export const EMPLOYER_DASHBOARD_ERROR_METRICS =
  'EMPLOYER_DASHBOARD_ERROR_METRICS';
