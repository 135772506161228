import * as TYPES from './constants';
import {
  requestReporterUser,
  receiveReporterUser,
  handleReporterUserError,
} from './actions';

import {Services, isArray} from 'core';

import {API} from 'config/reporter.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_REPORTER_USERS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_REPORTER_USERS:
      return {
        ...state,
        data: (isArray(action.payload) && [...action.payload]) || null,
        request: false,
        error: false,
      };
    case TYPES.REPORTER_USERS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchReporterInstituteUsers = instituteId => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestReporterUser());
  let endpoint = `${API.institution.user}?institution_id=${instituteId}`;
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receiveReporterUser(res));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleReporterUserError(err));
  }
};
